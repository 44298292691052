import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { firestore } from "../../firebase";
import defaultImage from "../../assets/icons/profileImage.jpg";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import classes from "./MutualConnectionsModal.module.css"; // Reusing the same CSS

const MutualMembersModal = ({ onClose, communityData, currentUser }) => {
  const [mutualMembers, setMutualMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [totalMutualMembers, setTotalMutualMembers] = useState(0);

  useEffect(() => {
    const fetchMutualMembers = async () => {
      if (!currentUser?.uid || !communityData?.id || !communityData.ownerId)
        return;

      try {
        // Get current user's connections
        const userConnectionsRef = collection(
          firestore,
          `users/${currentUser.uid}/connections`
        );
        const userConnectionsQuery = query(
          userConnectionsRef,
          where("status", "==", "Connected")
        );
        const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

        // Create a Set of user's connections (excluding dummyId)
        const userConnections = new Set(
          userConnectionsSnapshot.docs
            .filter((doc) => doc.id !== "dummyId")
            .map((doc) => doc.id)
        );

        // Get community members
        const membersRef = collection(
          firestore,
          `users/${communityData.ownerId}/communities/${communityData.id}/members`
        );
        const membersQuery = query(membersRef, where("status", "==", "Member"));
        const membersSnapshot = await getDocs(membersQuery);

        // Find mutual members
        const mutualMembersData = await Promise.all(
          membersSnapshot.docs
            .filter(
              (doc) => userConnections.has(doc.id) && doc.id !== currentUser.uid
            )
            .map(async (memberDoc) => {
              const userDoc = await getDoc(
                doc(firestore, "users", memberDoc.id)
              );
              if (!userDoc.exists()) return null;

              const userData = userDoc.data();
              return {
                id: memberDoc.id,
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || defaultImage,
                bio: userData.bio || "",
                urlSlug: userData.urlSlug || "",
                connectionType: "Direct",
              };
            })
        );

        const validMutualMembers = mutualMembersData.filter(Boolean);
        setMutualMembers(validMutualMembers);
        setTotalMutualMembers(validMutualMembers.length);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchMutualMembers();
  }, [currentUser?.uid, communityData]);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const handleNavigate = (userId) => {
    onClose();
    if (userId === currentUser?.uid) {
      navigate("/profile");
    } else {
      navigate(`/user/${userId}`);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredMutualMembers = useMemo(() => {
    return mutualMembers.filter((member) => {
      const fullName = `${member.firstName} ${member.lastName}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    });
  }, [mutualMembers, searchTerm]);

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Mutual Members ({totalMutualMembers})
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <form className={classes.interactions}>
          <div className={classes.connectionsList}>
            {loading ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              </div>
            ) : filteredMutualMembers.length > 0 ? (
              filteredMutualMembers.map((member) => (
                <div key={member.id} className={classes.connectionItem}>
                  <img
                    onClick={() => handleNavigate(member.id)}
                    src={member.profileImage}
                    alt={`${member.firstName} ${member.lastName}`}
                    className={classes.profileImage}
                  />
                  <div className={classes.connectionInfo}>
                    <h3 className={classes.name}>
                      <div className={classes.nameWrapper}>
                        <span
                          className={classes.nameText}
                          onClick={() => handleNavigate(member.id)}
                        >
                          {member.firstName} {member.lastName}
                        </span>
                        <span className={classes.connectionType}>
                          • {member.connectionType}
                        </span>
                      </div>
                    </h3>
                    <p className={classes.bio}>{member.bio}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.noNotifications}>
                <div className={classes.bold}></div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default MutualMembersModal;
