// contexts/FCMContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import { requestNotificationPermission, onMessageListener } from "../firebase";

const FCMContext = createContext();

export const useFCM = () => useContext(FCMContext);

export const FCMProvider = ({ children }) => {
  const [pushNotification, setPushNotification] = useState(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    const setupFCM = async () => {
      try {
        // Only proceed if we have an authenticated user
        if (currentUser?.uid) {
          // Wait a bit to ensure Firebase Auth is fully initialized
          await new Promise((resolve) => setTimeout(resolve, 1000));

          await requestNotificationPermission();

          // Listen for foreground messages
          onMessageListener()
            .then((payload) => {
              setPushNotification(payload);
            })
            .catch((err) =>
              console.error("Error listening for messages:", err)
            );
        }
      } catch (error) {}
    };

    setupFCM();
  }, [currentUser]);

  const value = {
    pushNotification,
    setPushNotification,
  };

  return <FCMContext.Provider value={value}>{children}</FCMContext.Provider>;
};

export default FCMProvider;
