import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import OnboardingNavbar from "../../../components/Navbars/OnboardingNavbar";
import googleIcon from "../../../assets/images/googleIcon.svg";
import classes from "./LogIn.module.css";
import { getFunctions, httpsCallable } from "firebase/functions";
import { userAnalytics } from "../../../analytics";

const functions = getFunctions(undefined, "europe-west1");

function LogIn() {
  const navigate = useNavigate();
  const { signIn, signInWithGoogle, currentUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(null);

  useEffect(() => {
    if (currentUser) {
      const checkFirestoreFields = async () => {
        try {
          const userDocRef = doc(firestore, "users", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          const userData = userDocSnap.data();

          if (userData && userData.email !== currentUser.email) {
            await updateDoc(userDocRef, {
              email: currentUser.email,
            });

            if (userData.firstName && userData.lastName) {
              try {
                const addContactToSendGrid = httpsCallable(
                  functions,
                  "addContactToSendGrid"
                );
                await addContactToSendGrid({
                  email: currentUser.email,
                  firstName: userData.firstName,
                  lastName: userData.lastName,
                });
              } catch (sendGridError) {}
            }
          }

          if (
            userData &&
            userData.firstName &&
            userData.lastName &&
            userData.country &&
            userData.city
          ) {
            navigate("/home");
          } else {
            navigate("/onboarding");
          }
        } catch (error) {}
      };

      checkFirestoreFields();
    }
  }, [currentUser, navigate]);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.toLowerCase();
    setEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(emailValue));
  };

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    setIsPasswordValid(passwordValue.length >= 6);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmailValid && isPasswordValid) {
      setLoading(true);
      try {
        const userCredential = await signIn(email, password);
        userAnalytics.trackUserActivity(); // Add this line
        const user = userCredential.user;

        const userDocRef = doc(firestore, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.data();

        // Always try to add to SendGrid if profile is complete
        if (userData?.firstName && userData?.lastName) {
          try {
            const addContactToSendGrid = httpsCallable(
              functions,
              "addContactToSendGrid",
              {
                timeout: 60000,
              }
            );

            await addContactToSendGrid({
              email: user.email,
              firstName: userData.firstName,
              lastName: userData.lastName,
            });
          } catch (sendGridError) {}
        } else {
        }

        if (
          !userData?.firstName ||
          !userData?.lastName ||
          !userData?.country ||
          !userData?.city
        ) {
          navigate("/onboarding");
        } else {
          navigate("/home");
        }
      } catch (error) {
        setErrorPopup({
          message: "Incorrect login details.",
          isError: true,
        });
      } finally {
        setLoading(false);
      }
    } else {
      setErrorPopup({
        message: "Incorrect login details.",
        isError: true,
      });
    }
  };

  const handleGoogleSignIn = async () => {
    setGoogleLoading(true);
    try {
      const userCredential = await signInWithGoogle();
      userAnalytics.trackUserActivity(); // Add this line
      const user = userCredential.user;

      const userDocRef = doc(firestore, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.data();

      if (userData) {
        // Always try to add to SendGrid if profile is complete
        if (userData.firstName && userData.lastName) {
          try {
            const addContactToSendGrid = httpsCallable(
              functions,
              "addContactToSendGrid",
              {
                timeout: 60000,
              }
            );

            await addContactToSendGrid({
              email: user.email,
              firstName: userData.firstName,
              lastName: userData.lastName,
            });
          } catch (sendGridError) {}
        } else {
        }

        if (
          userData.firstName &&
          userData.lastName &&
          userData.country &&
          userData.city
        ) {
          navigate("/home");
        } else {
          navigate("/onboarding");
        }
      } else {
        await setDoc(doc(firestore, "users", user.uid), {
          email: user.email,
          firstName: "",
          lastName: "",
          country: "",
          city: "",
        });

        navigate("/onboarding");
      }
    } catch (error) {
    } finally {
      setGoogleLoading(false);
    }
  };

  const ErrorPopup = ({ message, onClose }) => (
    <div className={classes.repostPopup}>
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="red"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
            clipRule="evenodd"
          />
        </svg>
        <p>{message}</p>
      </div>
      <div className={classes.repostCloseButton}>
        <svg
          className={classes.closeIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 24 24"
          onClick={onClose}
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  const isFormValid = isEmailValid && isPasswordValid;

  return (
    <div className={classes.page}>
      <OnboardingNavbar />
      <div className={classes.content}>
        {errorPopup && (
          <ErrorPopup
            message={errorPopup.message}
            onClose={() => setErrorPopup(null)}
          />
        )}
        <div className={classes.formContainer}>
          <div className={classes.formContent}>
            <div className={classes.formAbout}>
              <p className={classes.primaryAbout}>It starts here.</p>
            </div>
            <div className={classes.altSection}>
              <button
                type="button"
                className={classes.altEmail}
                onClick={handleGoogleSignIn}
                disabled={googleLoading}
              >
                <img src={googleIcon} alt="" className={classes.googleIcon} />
                Continue with Google
              </button>
            </div>
            <form className={classes.interactions} onSubmit={handleSubmit}>
              <label htmlFor="email" className={classes.label}>
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Ex: joe@example.com"
                className={classes.email}
                value={email}
                onChange={handleEmailChange}
                disabled={loading}
              />
              <label htmlFor="password" className={classes.label}>
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Enter your password"
                className={classes.email}
                value={password}
                onChange={handlePasswordChange}
                autoComplete="new-password"
                disabled={loading}
              />
              <Link to="/reset-password" className={classes.forgotPassword}>
                Forgot password?
              </Link>
              <button
                type="submit"
                className={`${classes.button} ${
                  loading ? classes.loading : ""
                }`}
                disabled={!isFormValid || loading}
              >
                {loading ? (
                  <span
                    className={`material-symbols-outlined ${classes.loadingIcon}`}
                  >
                    progress_activity
                  </span>
                ) : (
                  "Continue"
                )}
              </button>

              <div className={classes.account}>
                Don't have an account yet?
                <a href="/signup" className={classes.link}>
                  Join now
                </a>
              </div>
              {error && (
                <p className={classes.smallPrint}>
                  <span
                    className={`material-symbols-outlined ${classes.errorIcon}`}
                  >
                    error
                  </span>
                  {error}
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogIn;
