import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./EditCommunityModal.module.css";
import defaultImage from "../../assets/icons/communityImage.jpg";
import { getStorage, ref, deleteObject } from "firebase/storage";
import EditSingleCommunityModal from "./EditSingleCommunityModal";

const EditCommunityModal = ({ onClose, currentUser, onCommunityUpdated }) => {
  const [communities, setCommunities] = useState([]);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingCommunity, setEditingCommunity] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCommunities = communities.filter((community) => {
    return community.communityName
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  });

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const handleCommunityUpdated = (updatedCommunity) => {
    // Update local state
    const updatedCommunities = communities.map((community) =>
      community.id === updatedCommunity.id
        ? {
            ...community,
            ...updatedCommunity,
            membershipType: community.membershipType,
            createdAt: community.createdAt,
          }
        : community
    );

    // Update local state
    setCommunities(updatedCommunities);

    // Pass the single updated community up to Profile.js
    onCommunityUpdated(updatedCommunity);
  };

  useEffect(() => {
    const fetchCommunities = async () => {
      setLoading(true);
      try {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const communitiesCollectionRef = collection(userDocRef, "communities");
        const communitiesSnapshot = await getDocs(communitiesCollectionRef);

        const communitiesList = communitiesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort communities by creation date (newest first)
        communitiesList.sort(
          (a, b) =>
            b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime()
        );

        setCommunities(communitiesList);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchCommunities();
  }, [currentUser.uid]);

  const toggleDropdown = (communityId) => {
    setOpenDropdown(openDropdown === communityId ? null : communityId);
  };

  const handleEditClick = (community) => {
    setEditingCommunity(community);
    setOpenDropdown(null);
  };

  const handleDeleteCommunity = async (communityId) => {
    try {
      // Find the community to get its image URL
      const community = communities.find((c) => c.id === communityId);
      if (!community) return;

      const userDocRef = doc(firestore, "users", currentUser.uid);
      const communityDocRef = doc(userDocRef, "communities", communityId);

      // 1. Get all subcollections
      const membersCollectionRef = collection(communityDocRef, "members");
      const moderatorsCollectionRef = collection(communityDocRef, "moderators");
      const postsCollectionRef = collection(communityDocRef, "posts");

      // Fetch all documents
      const membersSnapshot = await getDocs(membersCollectionRef);
      const moderatorsSnapshot = await getDocs(moderatorsCollectionRef);
      const postsSnapshot = await getDocs(postsCollectionRef);

      // Collect all user IDs that need cleanup, including the community owner
      const userIds = new Set([currentUser.uid]);
      membersSnapshot.forEach((doc) => userIds.add(doc.id));
      moderatorsSnapshot.forEach((doc) => userIds.add(doc.id));

      // 2. Handle posts cleanup
      const postCleanupPromises = postsSnapshot.docs.map(async (postDoc) => {
        const postData = postDoc.data();
        const userId = postData.userId;
        const isStartup = postData.isStartup;
        const startupId = postData.startupId;

        // First, get and delete all comments for this post
        const commentsCollectionRef = collection(postDoc.ref, "comments");
        try {
          const commentsSnapshot = await getDocs(commentsCollectionRef);
          // Delete all comments in parallel
          await Promise.all(
            commentsSnapshot.docs.map((commentDoc) => deleteDoc(commentDoc.ref))
          );
        } catch (error) {}

        if (isStartup && startupId) {
          // Delete from startup's communityPosts
          const startupRef = doc(firestore, "startups", startupId);
          const startupPostRef = doc(
            collection(startupRef, "communityPosts"),
            postDoc.id
          );
          try {
            await deleteDoc(startupPostRef);
          } catch (error) {}
        } else if (userId) {
          // Delete from user's communityPosts
          const userRef = doc(firestore, "users", userId);
          const userPostRef = doc(
            collection(userRef, "communityPosts"),
            postDoc.id
          );
          try {
            await deleteDoc(userPostRef);
          } catch (error) {}
        }

        // Delete the post document itself
        await deleteDoc(postDoc.ref);
      });

      // 3. Delete related data for each user
      const deletePromises = Array.from(userIds).map(async (userId) => {
        const userRef = doc(firestore, "users", userId);

        if (userId !== currentUser.uid) {
          // Delete from communitiesMemberOf
          const memberOfRef = collection(userRef, "communitiesMemberOf");
          const memberOfDoc = doc(memberOfRef, communityId);
          try {
            await deleteDoc(memberOfDoc);
          } catch (error) {}

          // Delete from communitiesModerating
          const moderatingRef = collection(userRef, "communitiesModerating");
          const moderatingDoc = doc(moderatingRef, communityId);
          try {
            await deleteDoc(moderatingDoc);
          } catch (error) {}
        }

        // Delete all notifications related to the community or its posts
        const notificationsRef = collection(userRef, "notifications");
        const notificationsQuery = query(
          notificationsRef,
          where("communityId", "==", communityId)
        );

        try {
          const notificationsSnapshot = await getDocs(notificationsQuery);
          const notificationDeletions = notificationsSnapshot.docs.map((doc) =>
            deleteDoc(doc.ref)
          );
          await Promise.all(notificationDeletions);

          // Also delete notifications related to community posts
          const postNotificationsQuery = query(
            notificationsRef,
            where("type", "in", [
              "community_post",
              "community_comment",
              "community_reaction",
            ])
          );
          const postNotificationsSnapshot = await getDocs(
            postNotificationsQuery
          );
          const postNotificationDeletions = postNotificationsSnapshot.docs
            .filter((doc) => {
              const data = doc.data();
              return data.communityId === communityId;
            })
            .map((doc) => deleteDoc(doc.ref));
          await Promise.all(postNotificationDeletions);
        } catch (error) {}
      });

      // 4. Delete the community image
      if (
        community.communityImage &&
        !community.communityImage.includes("startupImage.jpg")
      ) {
        try {
          const storage = getStorage();
          const imageUrl = new URL(community.communityImage);
          const imagePath = decodeURIComponent(
            imageUrl.pathname.split("/o/")[1].split("?")[0]
          );
          const imageRef = ref(storage, imagePath);
          await deleteObject(imageRef);
        } catch (error) {}
      }

      // 5. Execute all deletions in parallel
      await Promise.all([
        ...deletePromises,
        ...postCleanupPromises,
        ...membersSnapshot.docs.map((doc) => deleteDoc(doc.ref)),
        ...moderatorsSnapshot.docs.map((doc) => deleteDoc(doc.ref)),
      ]);

      // 6. Finally delete the community document itself
      await deleteDoc(communityDocRef);

      // 7. Update local state and UI
      const updatedCommunities = communities.filter(
        (community) => community.id !== communityId
      );
      setCommunities(updatedCommunities);

      onCommunityUpdated({
        id: communityId,
        deleted: true,
      });

      setOpenDropdown(null);

      if (updatedCommunities.length === 0) {
        onClose();
      }
    } catch (error) {}
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose();
    }
    setMouseDownOnBackdrop(false);
  };

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  if (editingCommunity) {
    return (
      <EditSingleCommunityModal
        community={editingCommunity}
        onClose={() => setEditingCommunity(null)}
        onUpdate={handleCommunityUpdated}
        currentUser={currentUser}
      />
    );
  }

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Communities ({communities.length})
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className={classes.startupsCardAbout}>
          {loading ? (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          ) : communities.length === 0 ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No communities found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          ) : (
            filteredCommunities.map((community) => (
              <div key={community.id} className={classes.startupContainer}>
                <div className={classes.startupContent}>
                  <div className={classes.startupImageContainer}>
                    {community.communityImage ? (
                      <img
                        src={community.communityImage}
                        alt={community.communityName}
                        className={classes.startupImage}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                    ) : (
                      <div className={classes.defaultImage}>
                        <img
                          src={defaultImage}
                          alt="Default community"
                          className={classes.startupImage}
                        />
                      </div>
                    )}
                  </div>
                  <div className={classes.startupInfo}>
                    <h3 className={classes.startupName}>
                      <div className={classes.nameWrapper}>
                        <span className={classes.nameText}>
                          {community.communityName}
                        </span>
                        <span className={classes.connectionType}>
                          • Community
                        </span>
                      </div>
                    </h3>

                    {community.tagline && (
                      <p className={classes.role}>{community.tagline}</p>
                    )}
                    {community.link && (
                      <a
                        href={community.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.userLink}
                      >
                        <span>{community.linkText || community.link}</span>
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </a>
                    )}
                  </div>
                  <div className={classes.buttonContainer}>
                    <div className={classes.iconContainer}>
                      <svg
                        className={`${classes.optionsIcon} ${
                          openDropdown === community.id
                            ? classes.optionsIconActive
                            : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={() => toggleDropdown(community.id)}
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                      {openDropdown === community.id && (
                        <div className={classes.dropdown}>
                          <div
                            className={`${classes.dropdownItem} ${classes.dropdownItemFirst}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditClick(community);
                            }}
                          >
                            <svg
                              class="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                              />
                            </svg>
                            <span>Edit</span>
                          </div>
                          <div
                            className={`${classes.dropdownItem} ${classes.dropdownItemLast}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteCommunity(community.id);
                            }}
                          >
                            <svg
                              class="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                              />
                            </svg>
                            <span>Delete</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default EditCommunityModal;
