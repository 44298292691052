import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OnboardingNavbar from "../../../components/Navbars/OnboardingNavbar";
import { useAuth } from "../../../contexts/AuthContext";
import { firestore, auth } from "../../../firebase";
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import MonthDropdown from "../../../components/Dropdowns/MonthDropdown";
import DayDropdown from "../../../components/Dropdowns/DayDropdown";
import YearDropdown from "../../../components/Dropdowns/YearDropdown";
import classes from "./AgeVerification.module.css";

const AgeVerification = ({ onNext }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(null);

  const calculateAge = (birthMonth, birthDay, birthYear) => {
    const today = new Date();
    const birthDate = new Date(birthYear, birthMonth - 1, birthDay);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const deleteUserAccount = async () => {
    try {
      // Delete user document from Firestore first
      const userDocRef = doc(firestore, "users", currentUser.uid);
      await deleteDoc(userDocRef);

      // If user is signed in with Google
      if (currentUser.providerData[0].providerId === "google.com") {
        // Sign out first
        await signOut(auth);

        // Reauthenticate with Google
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);

        // Now delete the reauthenticated user
        await result.user.delete();
      } else {
        // For email/password users, just delete
        await currentUser.delete();
      }

      // Navigate to signup after successful deletion
      navigate("/signup");
    } catch (error) {
      if (error.code === "auth/requires-recent-login") {
        await signOut(auth);
        navigate("/signup");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedMonth || !selectedDay || !selectedYear) {
      setErrorPopup({
        message: "Please select your date of birth.",
        isError: true,
      });
      return;
    }

    setLoading(true);
    try {
      const age = calculateAge(selectedMonth, selectedDay, selectedYear);

      if (age < 16) {
        // Show error popup first
        setErrorPopup({
          message: "You must be 16 or over.",
          isError: true,
          redirect: true,
        });

        // Wait for 3 seconds before deleting account
        setTimeout(deleteUserAccount, 3000);
      } else {
        // Save birth date to user document and proceed
        const userDocRef = doc(firestore, "users", currentUser.uid);
        await updateDoc(userDocRef, {
          birthDate: {
            month: selectedMonth,
            day: selectedDay,
            year: selectedYear,
          },
        });
        onNext();
      }
    } catch (error) {
      setErrorPopup({
        message: "An error occurred. Please try again.",
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const ErrorPopup = ({ message, onClose }) => {
    useEffect(() => {
      if (errorPopup?.redirect) {
        const timer = setTimeout(() => {
          navigate("/signup");
        }, 3000);
        return () => clearTimeout(timer);
      }
    }, []);

    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.page}>
      <OnboardingNavbar />
      <div className={classes.content}>
        {errorPopup && (
          <ErrorPopup
            message={errorPopup.message}
            onClose={() => setErrorPopup(null)}
          />
        )}
        <div className={classes.formContainer}>
          <div className={classes.formContent}>
            <div className={classes.formAbout}>
              <p className={classes.primaryAbout}>What's your age?</p>
            </div>

            <form className={classes.interactions} onSubmit={handleSubmit}>
              <label htmlFor="country" className={classes.label}>
                Month
              </label>
              <MonthDropdown
                selectedMonth={selectedMonth}
                onMonthChange={setSelectedMonth}
                disabled={loading}
                placeholder="Ex: October"
              />
              <label htmlFor="country" className={classes.label}>
                Day
              </label>
              <DayDropdown
                selectedDay={selectedDay}
                onDayChange={setSelectedDay}
                disabled={loading}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                placeholder="Ex: 17"
              />
              <label htmlFor="country" className={classes.label}>
                Year
              </label>
              <YearDropdown
                selectedYear={selectedYear}
                onYearChange={setSelectedYear}
                disabled={loading}
                minYear={1900}
                maxYear={new Date().getFullYear()}
                placeholder="Ex: 1997"
              />

              <button
                type="submit"
                className={`${classes.button} ${
                  loading ? classes.loading : ""
                }`}
                disabled={
                  !selectedMonth || !selectedDay || !selectedYear || loading
                }
              >
                {loading ? (
                  <span
                    className={`material-symbols-outlined ${classes.loadingIcon}`}
                  >
                    progress_activity
                  </span>
                ) : (
                  "Continue"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgeVerification;
