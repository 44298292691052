import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import MainNavbar from "../../components/Navbars/MainNavbar";
import MobileNavbar from "../../components/Navbars/MobileNavbar";
import classes from "./StartupsAndCommunities.module.css";
import TermsAndConditions from "../../components/Cards/TermsAndConditions";
import { firestore, auth } from "../../firebase";
import {
  getDocs,
  collection,
  query,
  where,
  orderBy,
  startAfter,
  limit,
  getDoc,
  doc,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
// import profileImage from "../../assets/icons/defaultImage.jpg";
import startupImage from "../../assets/images/startupImage.jpg";
import CustomDropdown from "../../components/Dropdowns/CustomDropdown";
import algoliasearch from "algoliasearch/lite";
import TagsDropdown from "../../components/Dropdowns/TagsDropdown";
import communityImage from "../../assets/icons/communityImage.jpg";
const searchClient = algoliasearch(
  "X6JFTXGTHU",
  "334296000267001b1430be651a4794d9"
);

const startupsIndex = searchClient.initIndex("startups_index");
const communitiesIndex = searchClient.initIndex("communities_index");

function StartupsAndCommunities() {
  const [activeTab, setActiveTab] = useState("startups");
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    profileImage: "",
  });
  const [tags, setTags] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [nameInput, setNameInput] = useState("");
  const [isLocationMenuOpen, setIsLocationMenuOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryInputValue, setCountryInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const [initialResults, setInitialResults] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [categoryInput, setCategoryInput] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [lastSearchCriteria, setLastSearchCriteria] = useState({
    nameInput: "",
    tags: [],
    selectedCountry: null,
    selectedCity: null,
  });
  const toggleTab = (tab) => {
    setActiveTab(tab);
    setNameInput("");
    setSelectedCountry(null);
    setSelectedCity(null);
    setCategoryInput("");
    setSelectedCategory(null);
    setCountryInputValue("");
    setCityInputValue("");
    setIsChanged(false);
    setHasSearched(false);
    setSearchResults([]);
    if (tab === "startups") {
      fetchInitialStartups();
    } else {
      fetchInitialCommunities();
    }
  };

  const config = {
    cUrl: "https://api.countrystatecity.in/v1",
    ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
  };

  const cityCache = React.useMemo(() => ({}), []);

  const loadCountries = async () => {
    try {
      const response = await fetch(`${config.cUrl}/countries`, {
        headers: { "X-CSCAPI-KEY": config.ckey },
      });
      const data = await response.json();
      setCountries(Array.isArray(data) ? data : []);
    } catch (error) {
      setCountries([]);
    }
  };

  const loadCities = useCallback(async () => {
    if (!selectedCountry) return;
    try {
      if (cityCache[selectedCountry.iso2]) {
        setCities(cityCache[selectedCountry.iso2]);
      } else {
        const response = await fetch(
          `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
          {
            headers: { "X-CSCAPI-KEY": config.ckey },
          }
        );
        const data = await response.json();
        cityCache[selectedCountry.iso2] = Array.isArray(data) ? data : [];
        setCities(cityCache[selectedCountry.iso2]);
      }
    } catch (error) {
      setCities([]);
    }
  }, [selectedCountry, cityCache]);

  useEffect(() => {
    loadCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      loadCities();
    }
  }, [selectedCountry, loadCities]);

  const toggleLocationMenu = () => {
    setIsLocationMenuOpen(!isLocationMenuOpen);
  };

  const isUserBlocked = async (currentUserId, targetUserId) => {
    try {
      // Check if current user has blocked target user
      const currentUserBlockedRef = collection(
        firestore,
        `users/${currentUserId}/blockedUsers`
      );
      const currentUserBlockedSnapshot = await getDocs(currentUserBlockedRef);
      const isBlockedByCurrentUser = currentUserBlockedSnapshot.docs.some(
        (doc) => doc.data().userId === targetUserId
      );

      // Check if target user has blocked current user
      const targetUserBlockedRef = collection(
        firestore,
        `users/${targetUserId}/blockedUsers`
      );
      const targetUserBlockedSnapshot = await getDocs(targetUserBlockedRef);
      const isBlockedByTargetUser = targetUserBlockedSnapshot.docs.some(
        (doc) => doc.data().userId === currentUserId
      );

      return isBlockedByCurrentUser || isBlockedByTargetUser;
    } catch (error) {
      return false;
    }
  };

  const fetchInitialStartups = useCallback(async () => {
    if (!currentUser) return;
    setIsInitialLoading(true);

    try {
      const startupsToExclude = new Set();
      const seenStartupIds = new Set();
      let startups = [];

      const [ownedStartups, followingStartups, teamMemberStartups] =
        await Promise.all([
          getDocs(collection(firestore, `users/${currentUser.uid}/startups`)),
          getDocs(
            collection(firestore, `users/${currentUser.uid}/startupsFollowing`)
          ),
          getDocs(collection(firestore, `users/${currentUser.uid}/team`)),
        ]);

      ownedStartups.docs.forEach((doc) => startupsToExclude.add(doc.id));
      followingStartups.docs.forEach((doc) => startupsToExclude.add(doc.id));
      teamMemberStartups.docs.forEach((doc) => startupsToExclude.add(doc.id));

      const addStartupIfValid = async (startup, id, ownerId) => {
        const isBlocked = await isUserBlocked(currentUser.uid, ownerId);
        if (
          !startupsToExclude.has(id) &&
          !seenStartupIds.has(id) &&
          !isBlocked &&
          startup.startupName?.trim() // Check for non-empty startup name
        ) {
          seenStartupIds.add(id);
          startups.push({
            id,
            ownerId,
            ...startup,
          });
        }
      };

      const connectionsSnap = await getDocs(
        query(
          collection(firestore, `users/${currentUser.uid}/connections`),
          where("status", "==", "Connected")
        )
      );

      await Promise.all(
        connectionsSnap.docs.map(async (connection) => {
          const [connOwnedStartups, connFollowingStartups] = await Promise.all([
            getDocs(collection(firestore, `users/${connection.id}/startups`)),
            getDocs(
              collection(firestore, `users/${connection.id}/startupsFollowing`)
            ),
          ]);

          connOwnedStartups.docs.forEach(async (doc) => {
            await addStartupIfValid(doc.data(), doc.id, connection.id);
          });

          connFollowingStartups.docs.forEach(async (doc) => {
            await addStartupIfValid(doc.data(), doc.id, doc.data().ownerId);
          });
        })
      );

      if (startups.length < 36) {
        let lastDoc = null;
        let attempts = 0;
        const MAX_ATTEMPTS = 10;

        while (startups.length < 36 && attempts < MAX_ATTEMPTS) {
          const usersQuery = lastDoc
            ? query(
                collection(firestore, "users"),
                where("urlSlug", "!=", ""),
                startAfter(lastDoc),
                limit(50)
              )
            : query(
                collection(firestore, "users"),
                where("urlSlug", "!=", ""),
                limit(50)
              );

          const usersSnap = await getDocs(usersQuery);
          if (usersSnap.empty) break;

          lastDoc = usersSnap.docs[usersSnap.docs.length - 1];

          await Promise.all(
            usersSnap.docs
              .filter((doc) => doc.id !== currentUser.uid)
              .map(async (userDoc) => {
                const [userStartups, userFollowing] = await Promise.all([
                  getDocs(
                    collection(firestore, `users/${userDoc.id}/startups`)
                  ),
                  getDocs(
                    collection(
                      firestore,
                      `users/${userDoc.id}/startupsFollowing`
                    )
                  ),
                ]);

                await Promise.all([
                  ...userStartups.docs.map((doc) =>
                    addStartupIfValid(doc.data(), doc.id, userDoc.id)
                  ),
                  ...userFollowing.docs.map((doc) =>
                    addStartupIfValid(doc.data(), doc.id, doc.data().ownerId)
                  ),
                ]);
              })
          );

          attempts++;
        }
      }

      const sortedStartups = startups
        .sort((a, b) => (b.followingCount || 0) - (a.followingCount || 0))
        .slice(0, 36);

      setInitialResults(sortedStartups);
    } catch (error) {
      setInitialResults([]);
    } finally {
      setIsInitialLoading(false);
    }
  }, [currentUser]);

  const fetchInitialCommunities = useCallback(async () => {
    if (!currentUser) return;
    setIsInitialLoading(true);

    try {
      const communitiesToExclude = new Set();
      const seenCommunityIds = new Set();
      let communities = [];

      const [ownedCommunities, memberCommunities, moderatingCommunities] =
        await Promise.all([
          getDocs(
            collection(firestore, `users/${currentUser.uid}/communities`)
          ),
          getDocs(
            collection(
              firestore,
              `users/${currentUser.uid}/communitiesMemberOf`
            )
          ),
          getDocs(
            collection(
              firestore,
              `users/${currentUser.uid}/communitiesModerating`
            )
          ),
        ]);

      ownedCommunities.docs.forEach((doc) => communitiesToExclude.add(doc.id));
      memberCommunities.docs.forEach((doc) => communitiesToExclude.add(doc.id));
      moderatingCommunities.docs.forEach((doc) =>
        communitiesToExclude.add(doc.id)
      );

      const addCommunityIfValid = async (community, id, ownerId) => {
        const isBlocked = await isUserBlocked(currentUser.uid, ownerId);
        if (
          !communitiesToExclude.has(id) &&
          !seenCommunityIds.has(id) &&
          !isBlocked &&
          community.communityName?.trim() // Check for non-empty community name
        ) {
          seenCommunityIds.add(id);
          const membersSnap = await getDocs(
            collection(firestore, `users/${ownerId}/communities/${id}/members`)
          );
          communities.push({
            id,
            ownerId,
            membersCount: membersSnap.size,
            ...community,
          });
        }
      };

      const connectionsSnap = await getDocs(
        query(
          collection(firestore, `users/${currentUser.uid}/connections`),
          where("status", "==", "Connected")
        )
      );

      await Promise.all(
        connectionsSnap.docs.map(async (connection) => {
          const [connOwnedCommunities, connMemberCommunities] =
            await Promise.all([
              getDocs(
                collection(firestore, `users/${connection.id}/communities`)
              ),
              getDocs(
                collection(
                  firestore,
                  `users/${connection.id}/communitiesMemberOf`
                )
              ),
            ]);

          await Promise.all([
            ...connOwnedCommunities.docs.map((doc) =>
              addCommunityIfValid(doc.data(), doc.id, connection.id)
            ),
            ...connMemberCommunities.docs.map((doc) =>
              addCommunityIfValid(doc.data(), doc.id, doc.data().ownerId)
            ),
          ]);
        })
      );

      if (communities.length < 36) {
        let lastDoc = null;
        let attempts = 0;
        const MAX_ATTEMPTS = 10;

        while (communities.length < 36 && attempts < MAX_ATTEMPTS) {
          const usersQuery = lastDoc
            ? query(
                collection(firestore, "users"),
                where("urlSlug", "!=", ""),
                startAfter(lastDoc),
                limit(50)
              )
            : query(
                collection(firestore, "users"),
                where("urlSlug", "!=", ""),
                limit(50)
              );

          const usersSnap = await getDocs(usersQuery);
          if (usersSnap.empty) break;

          lastDoc = usersSnap.docs[usersSnap.docs.length - 1];

          await Promise.all(
            usersSnap.docs
              .filter((doc) => doc.id !== currentUser.uid)
              .map(async (userDoc) => {
                const [userCommunities, userMemberOf] = await Promise.all([
                  getDocs(
                    collection(firestore, `users/${userDoc.id}/communities`)
                  ),
                  getDocs(
                    collection(
                      firestore,
                      `users/${userDoc.id}/communitiesMemberOf`
                    )
                  ),
                ]);

                await Promise.all([
                  ...userCommunities.docs.map((doc) =>
                    addCommunityIfValid(doc.data(), doc.id, userDoc.id)
                  ),
                  ...userMemberOf.docs.map((doc) =>
                    addCommunityIfValid(doc.data(), doc.id, doc.data().ownerId)
                  ),
                ]);
              })
          );

          attempts++;
        }
      }

      const sortedCommunities = communities
        .sort((a, b) => (b.membersCount || 0) - (a.membersCount || 0))
        .slice(0, 36);

      setInitialResults(sortedCommunities);
    } catch (error) {
      setInitialResults([]);
    } finally {
      setIsInitialLoading(false);
    }
  }, [currentUser]);

  const checkIfAnyInputHasValue = useCallback(() => {
    if (activeTab === "startups") {
      return (
        nameInput.trim() !== "" ||
        tags.length > 0 ||
        selectedCountry !== null ||
        selectedCity !== null
      );
    } else {
      return nameInput.trim() !== "" || tags.length > 0;
    }
  }, [nameInput, tags, selectedCountry, selectedCity, activeTab]);

  const handleTagsChange = (newTags) => {
    setTags(newTags);
    setIsChanged(
      !!newTags.length ||
        !!nameInput.trim() ||
        !!selectedCountry ||
        !!selectedCity
    );
  };

  const handleInputChange = (inputValue, type) => {
    if (type === "tags") {
      if (inputValue.trim() === "") {
        setTags([]);
        setIsChanged(!!selectedCountry || !!selectedCity || !!nameInput.trim());
      } else {
        setTags([{ name: inputValue, id: inputValue }]);
        setIsChanged(true);
      }
    }
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCityInputValue("");
    setIsChanged(!!value || !!nameInput.trim() || tags.length > 0);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
    setIsChanged(
      !!value || !!selectedCountry || !!nameInput.trim() || tags.length > 0
    );
  };

  const handleNameInputChange = (e) => {
    const value = e.target.value;
    setNameInput(value);
    setIsChanged(
      value.trim() !== "" ||
        tags.length > 0 ||
        !!selectedCountry ||
        !!selectedCity
    );
  };

  const scoreMatch = (item, searchTags, searchCountry, searchCity) => {
    let matches = 0;

    // Name matching
    if (nameInput) {
      if (activeTab === "startups") {
        if (item.startupName?.toLowerCase().includes(nameInput.toLowerCase())) {
          matches++;
        }
      } else {
        if (
          item.communityName?.toLowerCase().includes(nameInput.toLowerCase())
        ) {
          matches++;
        }
      }
    }

    // Location matching (for startups)
    if (activeTab === "startups") {
      // Country is its own match
      if (
        searchCountry &&
        item.country?.toLowerCase() === searchCountry.name.toLowerCase()
      ) {
        matches++;
      }
      // City is a separate match
      if (
        searchCity &&
        item.city?.toLowerCase() === searchCity.name.toLowerCase()
      ) {
        matches++;
      }
    }

    // Tags matching
    if (searchTags.length > 0) {
      const itemTags = item._tags || [];
      searchTags.forEach((tag) => {
        const searchTagName = tag.name.toLowerCase().trim();
        if (
          itemTags.some(
            (itemTag) => itemTag.toLowerCase().trim() === searchTagName
          )
        ) {
          matches++;
        }
      });
    }

    return matches;
  };

  const shouldShowMatchTally = () => {
    const hasSearchCriteria =
      lastSearchCriteria.tags.length > 0 ||
      (activeTab === "startups" &&
        (lastSearchCriteria.selectedCountry ||
          lastSearchCriteria.selectedCity));

    return hasSearched && !isSearching && hasSearchCriteria;
  };

  const handleSearch = useCallback(async () => {
    if (!isChanged) return;
    setIsSearching(true);
    setHasSearched(true);

    setLastSearchCriteria({
      nameInput,
      tags,
      selectedCountry,
      selectedCity,
    });

    try {
      const searchParams = {
        hitsPerPage: 1000,
      };

      if (nameInput) {
        searchParams.query = nameInput;
      }

      let filters = [];

      if (activeTab === "startups") {
        if (selectedCountry) {
          filters.push(`country:${selectedCountry.name}`);
        }

        if (selectedCity) {
          filters.push(`city:${selectedCity.name}`);
        }
      }

      if (tags.length > 0) {
        tags.forEach((tag) => {
          filters.push(`_tags:${tag.name}`);
        });
      }

      if (filters.length > 0) {
        searchParams.facetFilters = [filters];
      }

      const { hits } = await (activeTab === "startups"
        ? startupsIndex
        : communitiesIndex
      ).search(searchParams.query || "", searchParams);

      const validHits = await Promise.all(
        hits.map(async (item) => {
          if (!item.ownerId) return null;
          if (activeTab === "startups" && !item.startupName?.trim())
            return null;
          if (activeTab === "communities" && !item.communityName?.trim())
            return null;
          const isBlocked = await isUserBlocked(currentUser.uid, item.ownerId);
          return isBlocked ? null : item;
        })
      );

      const filteredHits = validHits.filter((hit) => hit !== null);

      const scoredResults = filteredHits.map((item) => ({
        ...item,
        matches: scoreMatch(item, tags, selectedCountry, selectedCity),
      }));

      const sortedResults = scoredResults
        .filter((item) => item.matches > 0)
        .sort((a, b) => {
          if (b.matches !== a.matches) {
            return b.matches - a.matches;
          }
          return activeTab === "startups"
            ? (b.followingCount || 0) - (a.followingCount || 0)
            : (b.membersCount || 0) - (a.membersCount || 0);
        });

      setSearchResults(sortedResults);
    } catch (error) {
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  }, [
    nameInput,
    tags,
    selectedCountry,
    selectedCity,
    isChanged,
    activeTab,
    currentUser?.uid,
    scoreMatch,
  ]);

  useEffect(() => {
    if (currentUser && initialResults.length === 0) {
      if (activeTab === "startups") {
        fetchInitialStartups();
      } else {
        fetchInitialCommunities();
      }
    }
  }, [
    currentUser,
    activeTab,
    fetchInitialStartups,
    fetchInitialCommunities,
    initialResults.length,
  ]);

  useEffect(() => {
    if (isSearching) {
      const timer = setTimeout(() => {
        handleSearch();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isSearching, handleSearch]);

  const triggerSearch = () => {
    if (isChanged) {
      setIsSearching(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        fetchUserData(user.uid);
      } else {
        setCurrentUser(null);
        setUserData({
          firstName: "",
          lastName: "",
          city: "",
          country: "",
          bio: "",
          profileImage: "",
        });
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async (uid) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", uid));
      if (userDoc.exists()) {
        setUserData(userDoc.data());
      }
    } catch (error) {}
  };

  const handleItemClick = (item) => {
    if (activeTab === "startups") {
      navigate(`/startup/${item.startupUrlSlug}`);
    } else {
      navigate(`/community/${item.communityUrlSlug}`);
    }
  };

  const toggleFilters = () => {
    setShowFilters((prevState) => !prevState);
  };

  const handleRefresh = () => {
    setNameInput("");
    setSelectedCountry(null);
    setSelectedCity(null);
    setCountryInputValue("");
    setCityInputValue("");
    setTags([]);
    setIsChanged(false);
    setHasSearched(false);
    setSearchResults([]);
    if (activeTab === "startups") {
      fetchInitialStartups();
    } else {
      fetchInitialCommunities();
    }
  };

  return (
    <div className={classes.page}>
      {isMobile ? (
        <MobileNavbar userImage={userData.profileImage} />
      ) : (
        <MainNavbar userImage={userData.profileImage} />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.card}>
            <div className={classes.cardTitle}>
              {activeTab === "startups" ? "Startups" : "Communities"}
              <svg
                className={`${classes.closeIcon} ${
                  showFilters ? classes.closeIconActive : ""
                }`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="none"
                viewBox="0 0 24 24"
                onClick={toggleFilters}
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="M20 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6h-2m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4"
                />
              </svg>
            </div>
            <div className={classes.toggleButtons}>
              <button
                className={
                  activeTab === "startups"
                    ? classes.activeButton
                    : classes.refreshButton
                }
                onClick={() => toggleTab("startups")}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.535 7.677c.313-.98.687-2.023.926-2.677H17.46c.253.63.646 1.64.977 2.61.166.487.312.953.416 1.347.11.42.148.675.148.779 0 .18-.032.355-.09.515-.06.161-.144.3-.243.412-.1.111-.21.192-.324.245a.809.809 0 0 1-.686 0 1.004 1.004 0 0 1-.324-.245c-.1-.112-.183-.25-.242-.412a1.473 1.473 0 0 1-.091-.515 1 1 0 1 0-2 0 1.4 1.4 0 0 1-.333.927.896.896 0 0 1-.667.323.896.896 0 0 1-.667-.323A1.401 1.401 0 0 1 13 9.736a1 1 0 1 0-2 0 1.4 1.4 0 0 1-.333.927.896.896 0 0 1-.667.323.896.896 0 0 1-.667-.323A1.4 1.4 0 0 1 9 9.74v-.008a1 1 0 0 0-2 .003v.008a1.504 1.504 0 0 1-.18.712 1.22 1.22 0 0 1-.146.209l-.007.007a1.01 1.01 0 0 1-.325.248.82.82 0 0 1-.316.08.973.973 0 0 1-.563-.256 1.224 1.224 0 0 1-.102-.103A1.518 1.518 0 0 1 5 9.724v-.006a2.543 2.543 0 0 1 .029-.207c.024-.132.06-.296.11-.49.098-.385.237-.85.395-1.344ZM4 12.112a3.521 3.521 0 0 1-1-2.376c0-.349.098-.8.202-1.208.112-.441.264-.95.428-1.46.327-1.024.715-2.104.958-2.767A1.985 1.985 0 0 1 6.456 3h11.01c.803 0 1.539.481 1.844 1.243.258.641.67 1.697 1.019 2.72a22.3 22.3 0 0 1 .457 1.487c.114.433.214.903.214 1.286 0 .412-.072.821-.214 1.207A3.288 3.288 0 0 1 20 12.16V19a2 2 0 0 1-2 2h-6a1 1 0 0 1-1-1v-4H8v4a1 1 0 0 1-1 1H6a2 2 0 0 1-2-2v-6.888ZM13 15a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Startups
              </button>
              <button
                className={
                  activeTab === "communities"
                    ? classes.activeButton
                    : classes.refreshButton
                }
                onClick={() => toggleTab("communities")}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 6a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm-1.5 8a4 4 0 0 0-4 4 2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-3Zm6.82-3.096a5.51 5.51 0 0 0-2.797-6.293 3.5 3.5 0 1 1 2.796 6.292ZM19.5 18h.5a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-1.1a5.503 5.503 0 0 1-.471.762A5.998 5.998 0 0 1 19.5 18ZM4 7.5a3.5 3.5 0 0 1 5.477-2.889 5.5 5.5 0 0 0-2.796 6.293A3.501 3.501 0 0 1 4 7.5ZM7.1 12H6a4 4 0 0 0-4 4 2 2 0 0 0 2 2h.5a5.998 5.998 0 0 1 3.071-5.238A5.505 5.505 0 0 1 7.1 12Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Communities
              </button>
            </div>
            <div className={classes.cardAbout}>
              <label htmlFor="name" className={classes.label}>
                Name
              </label>
              <div className={classes.inputWrapper}>
                <input
                  type="text"
                  placeholder={
                    activeTab === "startups"
                      ? "Ex: DoorDash"
                      : "Ex: Technical Co-founders Hub"
                  }
                  value={nameInput}
                  onChange={handleNameInputChange}
                  className={classes.userInput}
                />
                <svg
                  className={classes.searchIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>

              {showFilters && (
                <div className={classes.filters}>
                  {activeTab === "startups" && (
                    <div className={classes.bottom}>
                      <div htmlFor="country" className={classes.filterLabel}>
                        Country
                      </div>
                      <CustomDropdown
                        options={countries}
                        selectedValue={selectedCountry}
                        onChange={handleCountryChange}
                        inputValue={countryInputValue}
                        setInputValue={setCountryInputValue}
                        disabled={loading}
                        placeholder="Ex: United States"
                      />
                      <div
                        htmlFor="city"
                        className={`${classes.filterLabel} ${
                          !selectedCountry || loading ? classes.disabled : ""
                        }`}
                      >
                        City
                      </div>
                      <CustomDropdown
                        options={cities}
                        selectedValue={selectedCity}
                        onChange={handleCityChange}
                        inputValue={cityInputValue}
                        setInputValue={setCityInputValue}
                        disabled={!selectedCountry || loading}
                        placeholder="Ex: San Francisco"
                      />
                    </div>
                  )}
                  <div className={classes.section}>
                    <div className={classes.filterLabel}>Tags</div>
                    <TagsDropdown
                      selectedTags={tags}
                      onTagsChange={handleTagsChange}
                      onInputChange={(value) =>
                        handleInputChange(value, "tags")
                      }
                      disabled={loading}
                      placeholder={
                        activeTab === "startups"
                          ? "Ex: AI, Biotech, SaaS"
                          : "Ex: Founder Stories, Fundraising, Startup Legal"
                      }
                      customClassName={classes.skillsInput}
                    />
                    <div className={classes.smallerPrint}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <p>Use commas to separate tags</p>
                    </div>
                  </div>
                </div>
              )}

              <div className={classes.buttons}>
                <button
                  type="button"
                  className={`${classes.button} ${
                    isSearching ? classes.loading : ""
                  }`}
                  disabled={!isChanged || isSearching}
                  onClick={triggerSearch}
                >
                  {isSearching ? (
                    <>
                      <span
                        className={`material-symbols-outlined ${classes.loadingIcon}`}
                      >
                        progress_activity
                      </span>
                      <span className={classes.searching}>Searching...</span>
                    </>
                  ) : (
                    <>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeWidth="2"
                          d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                        />
                      </svg>
                      Search
                    </>
                  )}
                </button>
                <button
                  className={classes.refreshButton}
                  onClick={handleRefresh}
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 9H8a5 5 0 0 0 0 10h9m4-10-4-4m4 4-4 4"
                    />
                  </svg>
                  Reset
                </button>
              </div>
            </div>
          </div>

          {isInitialLoading ? (
            <div className={classes.noResults}>
              <span
                className={`material-symbols-outlined ${classes.pageLoader}`}
              >
                progress_activity
              </span>
            </div>
          ) : hasSearched ? (
            isSearching ? (
              <div className={classes.noResults}>
                {/* Empty div to maintain layout during search */}
              </div>
            ) : searchResults.length > 0 ? (
              searchResults.map((item) => (
                <div
                  key={item.objectID}
                  className={classes.profileCard}
                  onClick={() => handleItemClick(item)}
                >
                  <div className={classes.profileHeader}>
                    <div className={classes.profileImage}>
                      <img
                        src={
                          activeTab === "startups"
                            ? item.startupImage || startupImage
                            : item.communityImage || communityImage
                        }
                        alt=""
                        className={`${
                          activeTab === "communities"
                            ? classes.communityImage
                            : ""
                        }`}
                      />
                    </div>
                    {shouldShowMatchTally() && hasSearched && !isSearching && (
                      <div className={classes.matchTally}>
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className={classes.matchCount}>
                          {item.matches}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className={classes.profileTitle}>
                    <span>
                      {activeTab === "startups"
                        ? item.startupName
                        : item.communityName}
                    </span>
                    <span className={classes.connectionType}>
                      • {activeTab === "startups" ? "Startup" : "Community"}
                    </span>
                  </div>
                  {((activeTab === "startups" ? item.bio : item.description) ||
                    item.link) && (
                    <div className={classes.userDetails}>
                      <div className={classes.bio}>
                        {activeTab === "startups" ? item.bio : item.description}
                      </div>

                      {item.link && (
                        <div className={classes.link}>
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {item.linkText || item.link}
                          </a>
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangeRed"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.75"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  )}
                  <button
                    className={classes.viewProfileButton}
                    onClick={() => handleItemClick(item)}
                  >
                    {activeTab === "startups" ? "See startup" : "See community"}
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 12H5m14 0-4 4m4-4-4-4"
                      />
                    </svg>
                  </button>
                </div>
              ))
            ) : (
              <div className={classes.noNotifications}>
                <div className={classes.bold}>No results found</div>
                <div className={classes.text}>
                  Try searching for something else.
                </div>
                <div className={classes.homeButton} onClick={handleRefresh}>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                    />
                  </svg>
                  Refresh
                </div>
              </div>
            )
          ) : initialResults.length > 0 ? (
            initialResults.map((item) => (
              <div
                key={item.objectID}
                className={classes.profileCard}
                onClick={() => handleItemClick(item)}
              >
                <div className={classes.reactionHeader}>Suggested</div>
                <div className={classes.profileHeader}>
                  <div className={classes.profileImage}>
                    <img
                      src={
                        activeTab === "startups"
                          ? item.startupImage || startupImage
                          : item.communityImage || communityImage
                      }
                      alt=""
                      className={`${
                        activeTab === "communities"
                          ? classes.communityImage
                          : ""
                      }`}
                    />
                  </div>
                </div>
                <div className={classes.profileTitle}>
                  <span>
                    {activeTab === "startups"
                      ? item.startupName
                      : item.communityName}
                  </span>
                  <span className={classes.connectionType}>
                    • {activeTab === "startups" ? "Startup" : "Community"}
                  </span>
                </div>
                {((activeTab === "startups" ? item.bio : item.description) ||
                  item.link) && (
                  <div className={classes.userDetails}>
                    <div className={classes.bio}>
                      {activeTab === "startups" ? item.bio : item.description}
                    </div>

                    {item.link && (
                      <div className={classes.link}>
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {item.linkText || item.link}
                        </a>
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="orangeRed"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.75"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                )}
                <button
                  className={classes.viewProfileButton}
                  onClick={() => handleItemClick(item)}
                >
                  {activeTab === "startups" ? "See startup" : "See community"}
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 12H5m14 0-4 4m4-4-4-4"
                    />
                  </svg>
                </button>
              </div>
            ))
          ) : (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No suggestions found</div>
              <div className={classes.text}>Try checking Home for updates.</div>
              <div
                className={classes.homeButton}
                onClick={() => navigate("/home")}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z"
                    clipRule="evenodd"
                  />
                </svg>
                Home
              </div>
            </div>
          )}
        </div>

        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default StartupsAndCommunities;
