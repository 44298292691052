import React, { useState, useRef, useEffect } from "react";
import {
  doc,
  updateDoc,
  writeBatch,
  getDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import MonthDropdown from "../Dropdowns/MonthDropdown";
import DayDropdown from "../Dropdowns/DayDropdown";
import YearDropdown from "../Dropdowns/YearDropdown";
import { firestore } from "../../firebase";
import classes from "./EditSingleEventModal.module.css";
import defaultImage from "../../assets/icons/eventImage.jpg";
import imageCompression from "browser-image-compression";
import Cropper from "react-easy-crop";
import CustomDropdown from "../Dropdowns/CustomDropdown";
import TimeDropdown from "../Dropdowns/TimeDropdown";
import TimezoneDropdown from "../Dropdowns/TimezoneDropdown";
import { generateEventUrlSlug } from "../../utils/eventUrlSlugUtils";

const config = {
  cUrl: "https://api.countrystatecity.in/v1",
  ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
};

const EditSingleEventModal = ({
  event,
  onClose,
  onUpdate,
  currentUser,
  isFromEventPage = false,
}) => {
  const formatExistingTimezone = (timezoneName, timezoneOffset) => {
    if (!timezoneName) return null;

    try {
      const now = new Date();
      const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone: timezoneName,
        timeZoneName: "long",
        hour12: false,
        hour: "numeric",
      });

      const offset = -new Date(
        now.toLocaleString("en-US", { timeZone: timezoneName })
      ).getTimezoneOffset();

      const offsetHours = Math.abs(Math.floor(offset / 60));
      const offsetMinutes = Math.abs(offset % 60);
      const offsetStr = `${offset >= 0 ? "+" : "-"}${String(
        offsetHours
      ).padStart(2, "0")}:${String(offsetMinutes).padStart(2, "0")}`;

      return {
        name: timezoneName,
        display: `(UTC${offsetStr}) ${timezoneName.replace(/_/g, " ")}`,
        offset: offset,
      };
    } catch (e) {
      return null;
    }
  };

  const initialState = {
    eventName: event.eventName || "",
    tagline: event.tagline || "",
    isOnline: event.isOnline || false,
    country: event.country || null,
    city: event.city || null,
    address: event.address || "",
    startDate: event.startDate || "",
    endDate: event.endDate || "",
    startTime: event.startTime || "",
    endTime: event.endTime || "",
    link: event.link || "",
    linkText: event.linkText || "",
    eventImage: event.eventImage || "",
    timezone: formatExistingTimezone(event.timezone, event.timezoneOffset),
  };

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const [startMonth, setStartMonth] = useState(null);
  const [startDay, setStartDay] = useState(null);
  const [startYear, setStartYear] = useState(null);
  const [endMonth, setEndMonth] = useState(null);
  const [endDay, setEndDay] = useState(null);
  const [endYear, setEndYear] = useState(null);
  const [eventName, setEventName] = useState(initialState.eventName);
  const [tagline, setTagline] = useState(initialState.tagline);
  const [isOnline, setIsOnline] = useState(initialState.isOnline);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryInputValue, setCountryInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [address, setAddress] = useState(initialState.address);
  const [startDate, setStartDate] = useState(initialState.startDate);
  const [endDate, setEndDate] = useState(initialState.endDate);
  const [startTime, setStartTime] = useState(initialState.startTime);
  const [endTime, setEndTime] = useState(initialState.endTime);
  const [timezone, setTimezone] = useState(initialState.timezone);
  const [link, setLink] = useState(initialState.link);
  const [linkText, setLinkText] = useState(initialState.linkText);
  const [error, setError] = useState("");
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countriesLoaded, setCountriesLoaded] = useState(false);
  const [citiesLoaded, setCitiesLoaded] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(!!initialState.link);
  const [eventImage, setEventImage] = useState(null);
  const [currentEventImageUrl, setCurrentEventImageUrl] = useState(
    initialState.eventImage
  );
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  useEffect(() => {
    if (initialState.startDate) {
      const [day, month, year] = initialState.startDate
        .split("/")
        .map((num) => parseInt(num));
      setStartDay(day);
      setStartMonth(month);
      setStartYear(year);
    }
    if (initialState.endDate) {
      const [day, month, year] = initialState.endDate
        .split("/")
        .map((num) => parseInt(num));
      setEndDay(day);
      setEndMonth(month);
      setEndYear(year);
    }
  }, [initialState.startDate, initialState.endDate]);

  // Format date from dropdowns
  const formatDateFromDropdowns = (day, month, year) => {
    if (!day || !month || !year) return "";
    return `${String(day).padStart(2, "0")}/${String(month).padStart(
      2,
      "0"
    )}/${year}`;
  };

  const isLoading = isLoadingInitialData || isSubmitting;
  const formRef = useRef(null);
  const cityCache = {};

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setIsLoadingInitialData(true);

        const response = await fetch(`${config.cUrl}/countries`, {
          headers: { "X-CSCAPI-KEY": config.ckey },
        });
        const data = await response.json();
        setCountries(Array.isArray(data) ? data : []);
        setCountriesLoaded(true);

        if (initialState.country) {
          const country = data.find((c) => c.name === initialState.country);
          if (country) {
            setSelectedCountry(country);
            setCountryInputValue(country.name);

            const citiesResponse = await fetch(
              `${config.cUrl}/countries/${country.iso2}/cities`,
              {
                headers: { "X-CSCAPI-KEY": config.ckey },
              }
            );
            const citiesData = await citiesResponse.json();
            setCities(Array.isArray(citiesData) ? citiesData : []);

            if (initialState.city) {
              const city = citiesData.find((c) => c.name === initialState.city);
              setSelectedCity(city);
              setCityInputValue(city?.name || "");
            }
            setCitiesLoaded(true);
          }
        }
      } catch (error) {
        setCountries([]);
        setCities([]);
      } finally {
        setIsLoadingInitialData(false);
      }
    };

    loadInitialData();
  }, [initialState.country, initialState.city]);

  useEffect(() => {
    if (selectedCountry) {
      loadCities();
    }
  }, [selectedCountry]);

  useEffect(() => {
    const isAllFieldsFilled = isOnline
      ? eventName.trim() !== "" &&
        startDay &&
        startMonth &&
        startYear &&
        endDay &&
        endMonth &&
        endYear &&
        startTime &&
        endTime &&
        timezone
      : eventName.trim() !== "" &&
        selectedCountry &&
        selectedCity &&
        startDay &&
        startMonth &&
        startYear &&
        endDay &&
        endMonth &&
        endYear &&
        startTime &&
        endTime &&
        timezone;
    setIsFormValid(isAllFieldsFilled);
  }, [
    eventName,
    isOnline,
    selectedCountry,
    selectedCity,
    startDay,
    startMonth,
    startYear,
    endDay,
    endMonth,
    endYear,
    startTime,
    endTime,
    timezone,
  ]);

  useEffect(() => {
    const formattedStartDate = formatDateFromDropdowns(
      startDay,
      startMonth,
      startYear
    );
    const formattedEndDate = formatDateFromDropdowns(endDay, endMonth, endYear);

    const hasChanged =
      eventName !== initialState.eventName ||
      tagline !== initialState.tagline ||
      isOnline !== initialState.isOnline ||
      (!isOnline &&
        (selectedCountry?.name !== initialState.country ||
          selectedCity?.name !== initialState.city ||
          address !== initialState.address)) ||
      formattedStartDate !== initialState.startDate ||
      formattedEndDate !== initialState.endDate ||
      startTime !== initialState.startTime ||
      endTime !== initialState.endTime ||
      link !== initialState.link ||
      linkText !== initialState.linkText ||
      eventImage !== null ||
      timezone?.name !== initialState.timezone?.name ||
      timezone?.offset !== initialState.timezone?.offset;

    setIsChanged(hasChanged);
  }, [
    eventName,
    tagline,
    isOnline,
    selectedCountry,
    selectedCity,
    address,
    startDay,
    startMonth,
    startYear,
    endDay,
    endMonth,
    endYear,
    startTime,
    endTime,
    link,
    linkText,
    eventImage,
    timezone,
    initialState,
  ]);

  const loadCities = async () => {
    if (!selectedCountry) return;
    try {
      if (cityCache[selectedCountry.iso2]) {
        setCities(cityCache[selectedCountry.iso2]);
        if (initialState.city) {
          const city = cityCache[selectedCountry.iso2].find(
            (c) => c.name === initialState.city
          );
          setSelectedCity(city);
          setCityInputValue(city?.name || "");
        }
        return;
      }

      const response = await fetch(
        `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
        {
          headers: { "X-CSCAPI-KEY": config.ckey },
        }
      );
      const data = await response.json();
      cityCache[selectedCountry.iso2] = Array.isArray(data) ? data : [];
      setCities(cityCache[selectedCountry.iso2]);

      if (initialState.city) {
        const city = data.find((c) => c.name === initialState.city);
        setSelectedCity(city);
        setCityInputValue(city?.name || "");
      }
    } catch (error) {
      setCities([]);
    }
  };

  const handleEventNameChange = (e) => {
    if (e.target.value.length <= 50) {
      setEventName(e.target.value);
    }
  };

  const handleTaglineChange = (e) => {
    if (e.target.value.length <= 250) {
      setTagline(e.target.value);
    }
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCityInputValue("");
    setCities([]);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
  };

  const validateUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleLinkChange = (e) => {
    const newLink = e.target.value;
    if (newLink.length <= 250) {
      setLink(newLink);
      setIsValidUrl(validateUrl(newLink));
      if (!newLink) {
        setLinkText("");
      }
    }
  };

  const handleLinkTextChange = (e) => {
    if (e.target.value.length <= 50) {
      setLinkText(e.target.value);
    }
  };

  const validateDate = (dateStr) => {
    const [day, month, year] = dateStr.split("/").map((num) => parseInt(num));

    if (isNaN(day) || isNaN(month) || isNaN(year)) return false;
    if (month < 1 || month > 12) return false;
    if (day < 1 || day > 31) return false;

    if ([4, 6, 9, 11].includes(month) && day > 30) return false;
    if (month === 2) {
      const isLeapYear =
        (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
      if (day > (isLeapYear ? 29 : 28)) return false;
    }

    return true;
  };

  const formatDateInput = (value) => {
    const digits = value.replace(/\D/g, "");
    if (!digits) return "";

    let formattedDate = "";
    if (digits.length <= 2) {
      formattedDate = digits;
    } else if (digits.length <= 4) {
      formattedDate = `${digits.slice(0, 2)}/${digits.slice(2)}`;
    } else {
      formattedDate = `${digits.slice(0, 2)}/${digits.slice(
        2,
        4
      )}/${digits.slice(4, 8)}`;
    }

    return formattedDate;
  };

  const handleStartDateChange = (e) => {
    const formattedDate = formatDateInput(e.target.value);
    setStartDate(formattedDate);

    if (endDate) {
      const [startDay, startMonth, startYear] = formattedDate
        .split("/")
        .map((num) => parseInt(num));
      const [endDay, endMonth, endYear] = endDate
        .split("/")
        .map((num) => parseInt(num));

      if (
        new Date(startYear, startMonth - 1, startDay) >
        new Date(endYear, endMonth - 1, endDay)
      ) {
        setEndDate("");
        setEndTime("");
      }
    }
  };

  const handleEndDateChange = (e) => {
    const formattedDate = formatDateInput(e.target.value);
    setEndDate(formattedDate);

    if (!startDate || formattedDate.length < 10) return;

    if (formattedDate.length === 10 && startDate.length === 10) {
      const [startDay, startMonth, startYear] = startDate
        .split("/")
        .map((num) => parseInt(num));
      const [endDay, endMonth, endYear] = formattedDate
        .split("/")
        .map((num) => parseInt(num));

      if (
        new Date(startYear, startMonth - 1, startDay) >
        new Date(endYear, endMonth - 1, endDay)
      ) {
        setEndDate("");
      }
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleEventImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 8,
          maxWidthOrHeight: 1000,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onload = () => {
          setCurrentEventImageUrl(reader.result);
          setIsCropping(true);
        };
        reader.readAsDataURL(compressedFile);
        setEventImage(compressedFile);
        e.target.value = "";
      } catch (error) {
        setError("Failed to process the image. Please try again.");
      }
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setError("Please fill in all required fields.");
      return;
    }

    const formattedStartDate = formatDateFromDropdowns(
      startDay,
      startMonth,
      startYear
    );
    const formattedEndDate = formatDateFromDropdowns(endDay, endMonth, endYear);

    if (!validateDate(formattedStartDate) || !validateDate(formattedEndDate)) {
      setError("Please enter valid dates.");
      return;
    }

    // Close the modal immediately
    onClose(true);

    setIsSubmitting(true);
    setError("");

    try {
      let newEventImageUrl = currentEventImageUrl;

      if (eventImage && croppedAreaPixels) {
        if (
          initialState.eventImage &&
          !initialState.eventImage.includes("startupImage.jpg")
        ) {
          try {
            const storage = getStorage();
            const imageUrl = new URL(initialState.eventImage);
            const imagePath = decodeURIComponent(
              imageUrl.pathname.split("/o/")[1].split("?")[0]
            );
            const oldImageRef = ref(storage, imagePath);
            await deleteObject(oldImageRef);
          } catch (error) {}
        }

        const croppedImage = await getCroppedImg(
          currentEventImageUrl,
          croppedAreaPixels
        );
        const response = await fetch(croppedImage);
        const blob = await response.blob();

        const storage = getStorage();
        const eventImageRef = ref(
          storage,
          `eventImages/${currentUser.uid}/${event.id}/profile/${Date.now()}.jpg`
        );
        await uploadBytes(eventImageRef, blob);
        newEventImageUrl = await getDownloadURL(eventImageRef);
      }

      const eventDocRef = doc(
        firestore,
        `users/${currentUser.uid}/events/${event.id}`
      );

      let eventUrlSlug = event.eventUrlSlug;
      if (eventName !== initialState.eventName) {
        const newSlug = await generateEventUrlSlug(eventName);
        if (newSlug) {
          eventUrlSlug = newSlug;
        }
      }

      // Get the current event document to ensure we have the correct createdAt timestamp
      const currentEventDoc = await getDoc(eventDocRef);
      const currentEventData = currentEventDoc.data();

      const updatedEvent = {
        eventName: eventName.trim(),
        tagline: tagline.trim(),
        isOnline,
        country: isOnline ? null : selectedCountry?.name,
        countryISO2: isOnline ? null : selectedCountry?.iso2,
        city: isOnline ? null : selectedCity?.name,
        address: isOnline ? null : address.trim() || null,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        startTime,
        endTime,
        timezone: timezone.name,
        timezoneOffset: timezone.offset,
        link: link.trim(),
        linkText: link.trim() ? linkText.trim() : "",
        eventImage: newEventImageUrl,
        eventUrlSlug,
        membershipType: event.membershipType || "owner",
        ownerId: event.ownerId || currentUser.uid,
        ownerData: event.ownerData,
        attendeeCount: event.attendeeCount || 0,
        followingCount: event.followingCount || 0,
        createdAt: currentEventData.createdAt,
        hosts: event.hosts || {},
      };

      const batch = writeBatch(firestore);

      // Only include fields that are not undefined
      const cleanedEventData = Object.fromEntries(
        Object.entries(updatedEvent).filter(([_, value]) => value !== undefined)
      );

      batch.update(eventDocRef, cleanedEventData);

      const attendeesRef = collection(
        firestore,
        `users/${currentUser.uid}/events/${event.id}/attendees`
      );
      const attendeesSnapshot = await getDocs(attendeesRef);

      attendeesSnapshot.docs.forEach((attendeeDoc) => {
        const attendeeEventRef = doc(
          firestore,
          `users/${attendeeDoc.id}/eventsAttending/${event.id}`
        );

        batch.update(attendeeEventRef, {
          endDate: updatedEvent.endDate,
          endTime: updatedEvent.endTime,
          timezone: updatedEvent.timezone,
          eventName: updatedEvent.eventName,
          eventImage: updatedEvent.eventImage,
          eventUrlSlug: updatedEvent.eventUrlSlug,
          isOnline: updatedEvent.isOnline,
          city: updatedEvent.city,
          country: updatedEvent.country,
        });
      });

      await batch.commit();

      if (isFromEventPage) {
        onUpdate(cleanedEventData);
      } else {
        onUpdate({
          id: event.id,
          ...cleanedEventData,
          membershipType: "owner",
        });
      }
    } catch (error) {
      setError("Failed to update event. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.modalBackdrop}>
      <div className={classes.modalContent}>
        <div className={classes.cardHeader}>
          {isFromEventPage ? (
            <div className={classes.cardTitle}>
              Event
              <svg
                className={classes.closeIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                fill="none"
                viewBox="0 0 24 24"
                onClick={() => onClose(false)}
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18 17.94 6M18 18 6.06 6"
                />
              </svg>
            </div>
          ) : (
            <div className={classes.goBackHeader}>
              <svg
                className={classes.closeIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="none"
                viewBox="0 0 24 24"
                onClick={onClose}
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 12h14M5 12l4-4m-4 4 4 4"
                />
              </svg>
              <span className={classes.goBack}>Go back</span>
            </div>
          )}
        </div>

        <form
          className={classes.interactions}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.modalLoadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : (
            <>
              <div className={classes.imageContainer}>
                <div className={classes.profileImage}>
                  {currentEventImageUrl && isCropping ? (
                    <div className={classes.cropContainer}>
                      <Cropper
                        image={currentEventImageUrl}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        showGrid={false}
                        cropSize={{ width: 192, height: 128 }}
                        objectFit="cover"
                      />
                    </div>
                  ) : (
                    <img
                      src={currentEventImageUrl || defaultImage}
                      alt="Event"
                      className={classes.startupImage}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = defaultImage;
                      }}
                    />
                  )}
                </div>
                <button
                  type="button"
                  className={classes.uploadButton}
                  onClick={() => document.getElementById("eventImage").click()}
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="gray"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.5 4.586A2 2 0 0 1 8.914 4h6.172a2 2 0 0 1 1.414.586L17.914 6H19a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h1.086L7.5 4.586ZM10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <input
                type="file"
                id="eventImage"
                accept="image/*"
                onChange={handleEventImageChange}
                className={classes.profileButton}
              />

              <label htmlFor="eventName" className={classes.label}>
                Name*
              </label>
              <input
                id="eventName"
                type="text"
                placeholder="Ex: YC Startup School Europe"
                className={classes.userInput}
                value={eventName}
                onChange={handleEventNameChange}
                disabled={isLoading}
              />
              {/* <div className={classes.charCount}>{eventName.length}/50</div> */}
              <div className={classes.border}></div>

              <label htmlFor="tagline" className={classes.label}>
                Tagline
              </label>
              <textarea
                id="tagline"
                value={tagline}
                onChange={handleTaglineChange}
                className={classes.textArea}
                placeholder="Ex: If you're a student or recent grad who has been thinking about starting a startup one day (or are in the very earliest stages of building one), we hope to meet you there!"
              />
              {/* <div className={classes.charCount}>{tagline.length}/250</div> */}
              <div className={classes.border}></div>

              <div className={classes.checkboxContainer}>
                <input
                  type="checkbox"
                  id="isOnline"
                  className={classes.customCheckbox}
                  checked={isOnline}
                  onChange={(e) => setIsOnline(e.target.checked)}
                />
                <label htmlFor="isOnline">This event is hosted virtually</label>
              </div>

              {!isOnline && (
                <>
                  <div className={classes.border}></div>

                  <label htmlFor="country" className={classes.label}>
                    Country*
                  </label>
                  <CustomDropdown
                    id="country"
                    options={countries}
                    selectedValue={selectedCountry}
                    onChange={handleCountryChange}
                    inputValue={countryInputValue}
                    setInputValue={setCountryInputValue}
                    disabled={isLoading}
                    placeholder="Ex: United Kingdom"
                  />
                  <label
                    htmlFor="city"
                    className={`${classes.label} ${
                      !selectedCountry || isLoading ? classes.disabled : ""
                    }`}
                  >
                    City*
                  </label>
                  <CustomDropdown
                    id="city"
                    options={cities}
                    selectedValue={selectedCity}
                    onChange={handleCityChange}
                    inputValue={cityInputValue}
                    setInputValue={setCityInputValue}
                    disabled={!selectedCountry || isLoading}
                    placeholder="Ex: London"
                  />
                  <label htmlFor="address" className={classes.label}>
                    Address
                  </label>
                  <textarea
                    id="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className={classes.textArea}
                    placeholder="Ex: Storey's Gate, London, SW1H 9NH"
                    disabled={isLoading}
                  />
                </>
              )}
              <div className={classes.border}></div>

              <TimezoneDropdown
                selectedTimezone={timezone}
                onTimezoneChange={setTimezone}
                disabled={isLoading}
              />
              <div className={classes.dateBorder}></div>

              <label htmlFor="startDate" className={classes.label}>
                Start date*
              </label>
              <div className={classes.dateContainer}>
                <MonthDropdown
                  selectedMonth={startMonth}
                  onMonthChange={setStartMonth}
                  disabled={isLoading}
                  maxMonth={startYear === currentYear ? currentMonth : 12}
                  placeholder="Month"
                  isEndDate={false}
                  startYear={startYear}
                  currentYear={currentYear}
                  currentMonth={currentMonth}
                />
                <DayDropdown
                  label=""
                  selectedDay={startDay}
                  onDayChange={setStartDay}
                  disabled={isLoading}
                  selectedMonth={startMonth}
                  selectedYear={startYear}
                  placeholder="Day"
                />
                <YearDropdown
                  selectedYear={startYear}
                  onYearChange={setStartYear}
                  disabled={isLoading}
                  maxYear={currentYear + 10}
                  placeholder="Year"
                />
              </div>

              <TimeDropdown
                selectedTime={startTime}
                onTimeChange={setStartTime}
                disabled={isLoading}
                placeholder="Time"
              />

              <div className={classes.border}></div>

              <label
                htmlFor="endDate"
                className={`${classes.label} ${
                  !startDay || isLoading ? classes.disabled : ""
                }`}
              >
                End date*
              </label>
              <div className={classes.dateContainer}>
                <MonthDropdown
                  selectedMonth={endMonth}
                  onMonthChange={setEndMonth}
                  disabled={isLoading || !startDay}
                  maxMonth={endYear === currentYear ? currentMonth : 12}
                  placeholder="Month"
                  isEndDate={true}
                  startYear={endYear}
                  currentYear={currentYear}
                  currentMonth={currentMonth}
                />
                <DayDropdown
                  label=""
                  selectedDay={endDay}
                  onDayChange={setEndDay}
                  disabled={isLoading || !startDay}
                  selectedMonth={endMonth}
                  selectedYear={endYear}
                  placeholder="Day"
                />
                <YearDropdown
                  selectedYear={endYear}
                  onYearChange={setEndYear}
                  disabled={isLoading || !startDay}
                  maxYear={currentYear + 10}
                  placeholder="Year"
                />
              </div>

              <TimeDropdown
                selectedTime={endTime}
                onTimeChange={setEndTime}
                disabled={isLoading || !startDay || !endDay}
                placeholder="Time"
                minTime={
                  formatDateFromDropdowns(startDay, startMonth, startYear) ===
                  formatDateFromDropdowns(endDay, endMonth, endYear)
                    ? startTime
                    : undefined
                }
              />

              <div className={classes.border}></div>

              <label htmlFor="link" className={classes.label}>
                Link
              </label>
              <input
                id="link"
                type="url"
                placeholder="Ex: https://example.com"
                className={classes.userInput}
                value={link}
                onChange={handleLinkChange}
                disabled={isLoading}
              />
              {/* <div className={classes.charCount}>{link.length}/250</div> */}

              <label
                htmlFor="linkText"
                className={`${classes.linkTextLabel} ${
                  !isValidUrl ? classes.disabledInput : ""
                }`}
              >
                Link text
              </label>
              <input
                id="linkText"
                type="text"
                placeholder="Ex: Register here"
                className={`${classes.userInput} ${
                  !isValidUrl ? classes.disabledInput : ""
                }`}
                value={linkText}
                onChange={handleLinkTextChange}
                disabled={isLoading || !isValidUrl}
                style={{ opacity: isValidUrl ? 1 : 0.5 }}
              />
              <div
                className={`${classes.smallPrint} ${
                  !isValidUrl ? classes.disabledInput : ""
                }`}
              >
                <p className={classes.custom}>
                  Customize how your link will appear (optional)
                </p>
                {/* <span className={classes.charCount}>{linkText.length}/50</span> */}
              </div>
            </>
          )}
        </form>

        {error && <p className={classes.error}>{error}</p>}

        <div className={classes.cardFooter}>
          <div className={classes.smallPrint}>
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                clip-rule="evenodd"
              />
            </svg>
            *Required information
          </div>
          <button
            type="button"
            className={classes.button}
            disabled={!isFormValid || !isChanged}
            onClick={() => {
              if (formRef.current) {
                formRef.current.dispatchEvent(
                  new Event("submit", { bubbles: true, cancelable: true })
                );
              }
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSingleEventModal;
