import React, { useState, useEffect, useCallback } from "react";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./StartupInteractionToggle.module.css";
import StartupInteractionModal from "../Modals/StartupInteractionModal.js";
import { useNavigate } from "react-router-dom";
import defaultStartupImage from "../../assets/images/startupImage.jpg";
const ToggleContent = React.memo(
  ({ selectedStartup, currentUserProfileImage, userStartups = [] }) => (
    <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
      <img
        src={
          selectedStartup
            ? selectedStartup.startupImage || defaultStartupImage
            : currentUserProfileImage
        }
        alt="Active profile"
        style={{
          borderRadius: selectedStartup ? 0 : "50%",
        }}
      />
      {/* Only render chevron if there are startups */}
      {(userStartups?.length || 0) > 0 && (
        <svg
          style={{
            marginLeft: "-0rem",
            marginRight: "-0.375rem",
          }}
          className="w-6 h-6 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m8 10 4 4 4-4"
          />
        </svg>
      )}
    </div>
  )
);

const StartupInteractionToggle = React.memo(
  ({
    currentUser,
    onStartupSelect,
    selectedStartup,
    currentUserProfileImage,
    onModalOpen,
    onModalClose,
    className,
    userStartups = [], // Add this prop
  }) => {
    // const [showModal, setShowModal] = useState(false);
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
      const fetchUserData = async () => {
        if (!currentUser?.uid) return;
        try {
          const userRef = doc(firestore, "users", currentUser.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            setUserData(userSnap.data());
          }
        } catch (error) {}
      };

      fetchUserData();
    }, [currentUser?.uid]);

    const handleToggleModal = useCallback(
      (e) => {
        e.stopPropagation();
        onModalOpen?.(); // Just call onModalOpen, don't toggle internal state
      },
      [onModalOpen]
    );

    // If no startups, return toggle content without click handler
    if ((userStartups?.length || 0) === 0) {
      return (
        <div
          className={classes.noInteractionToggle}
          onClick={() => navigate(`/${userData?.urlSlug}`)}
        >
          <ToggleContent
            selectedStartup={selectedStartup}
            currentUserProfileImage={currentUserProfileImage}
            userStartups={userStartups}
          />
        </div>
      );
    }

    return (
      <>
        <div
          role="button"
          tabIndex={0}
          onClick={handleToggleModal}
          onKeyDown={(e) => e.key === "Enter" && handleToggleModal(e)}
          className={
            (userStartups?.length || 0) > 0
              ? className
              : classes.noInteractionToggle
          }
        >
          <ToggleContent
            selectedStartup={selectedStartup}
            currentUserProfileImage={currentUserProfileImage}
            userStartups={userStartups}
          />
        </div>

        {/* {showModal && (
          <StartupInteractionModal
            onClose={() => {
              setShowModal(false);
              onModalClose?.();
            }}
            currentUser={{
              ...currentUser,
              firstName: userData?.firstName,
              lastName: userData?.lastName,
              bio: userData?.bio,
            }}
            onStartupSelect={(startup) => {
              onStartupSelect(startup);
              setShowModal(false);
              onModalClose?.();
            }}
            selectedStartup={selectedStartup}
            currentUserProfileImage={currentUserProfileImage}
          />
        )} */}
      </>
    );
  }
);

export default StartupInteractionToggle;
