import React, { useState, useRef, useEffect } from "react";
import { firestore } from "../../firebase";
import { useChat } from "../../contexts/ChatContext";
import { useNavigate } from "react-router-dom";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  collection,
  writeBatch,
  serverTimestamp,
  query,
  onSnapshot,
  where,
  orderBy,
  getDocs,
  arrayUnion,
} from "firebase/firestore";
import defaultImage from "../../assets/icons/profileImage.jpg";
import imageCompression from "browser-image-compression";
import classes from "./ChatModal.module.css";
import { convertUrlsToLinks } from "../../utils/textUtils";

const ChatModal = ({
  onClose,
  selectedChat,
  currentUser,
  messages,
  userProfiles,
  onDeleteChat,
  onMessageSent,
}) => {
  const [newMessage, setNewMessage] = useState("");
  const videoRef = useRef(null);
  const { markChatAsRead } = useChat();
  const [isBlocked, setIsBlocked] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [multipleImages, setMultipleImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [videoVolumes, setVideoVolumes] = useState({});

  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const fileInputRef = useRef(null);
  const messageInputRef = useRef(null);

  const optionsButtonRef = useRef(null);
  const dropdownRef = useRef(null);

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  useEffect(() => {
    if (selectedChat?.id) {
      // Mark chat as read only if there are messages
      if (messages.length > 0) {
        markChatAsRead(selectedChat.id);
      }
      // Always set loading to false once we have the chat data
      setIsLoading(false);
    }
  }, [selectedChat?.id, messages]);

  useEffect(() => {
    const checkBlockedStatus = async () => {
      if (!selectedChat?.otherUser?.id || !currentUser?.uid) return;

      try {
        // Check if current user has blocked the other user
        const currentUserBlockedRef = collection(
          firestore,
          `users/${currentUser.uid}/blockedUsers`
        );
        const currentUserBlockedQuery = query(
          currentUserBlockedRef,
          where("userId", "==", selectedChat.otherUser.id)
        );
        const currentUserBlockedSnap = await getDocs(currentUserBlockedQuery);

        // Check if other user has blocked current user
        const otherUserBlockedRef = collection(
          firestore,
          `users/${selectedChat.otherUser.id}/blockedUsers`
        );
        const otherUserBlockedQuery = query(
          otherUserBlockedRef,
          where("userId", "==", currentUser.uid)
        );
        const otherUserBlockedSnap = await getDocs(otherUserBlockedQuery);

        setIsBlocked(
          currentUserBlockedSnap.size > 0 || otherUserBlockedSnap.size > 0
        );
      } catch (error) {}
    };

    checkBlockedStatus();
  }, [selectedChat?.otherUser?.id, currentUser?.uid]);

  useEffect(() => {
    if (!selectedChat?.id || !currentUser) return;

    // Mark this user as actively viewing the chat
    const chatRef = doc(firestore, `chats/${selectedChat.id}`);
    updateDoc(chatRef, {
      [`activeViewers.${currentUser.uid}`]: true,
    });

    // Cleanup when closing chat
    return () => {
      updateDoc(chatRef, {
        [`activeViewers.${currentUser.uid}`]: false,
      });
    };
  }, [selectedChat?.id, currentUser]);

  const handleRemoveFile = () => {
    setFile(null);
    setFileType(null);
    setFilePreview(null);
    setFileName(null);
    setVideoThumbnail(null);
    setMultipleImages([]);
    resetFileInput();
  };

  const isSendDisabled = () => {
    if (isSending || isBlocked) return true;
    return !newMessage.trim() && !file;
  };

  const ImageIcon = ({ isHovered }) => (
    <svg
      className={classes.imageIcon}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="gray"
      viewBox="0 0 24 24"
    >
      {isHovered ? (
        <path
          fillRule="evenodd"
          d="M13 10a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H14a1 1 0 0 1-1-1Z"
          clipRule="evenodd"
        />
      ) : (
        <path
          stroke="gray"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 3v4a1 1 0 0 1-1 1H5m14-4v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1Z"
        />
      )}
    </svg>
  );

  const VideoIcon = ({ isHovered }) => (
    <svg
      className={classes.videoIcon}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      {isHovered ? (
        <path
          fill="gray"
          fillRule="evenodd"
          d="M14 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7Zm2 9.387l4.684 1.562A1 1 0 0 0 22 17V7a1 1 0 0 0-1.316-.949L16 7.613v8.774Z"
          clipRule="evenodd"
        />
      ) : (
        <path
          stroke="gray"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M14 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7Zm2 9.387l4.684 1.562A1 1 0 0 0 22 17V7a1 1 0 0 0-1.316-.949L16 7.613v8.774Z"
        />
      )}
    </svg>
  );

  const FileIcon = ({ isHovered }) => (
    <svg
      className={classes.fileIcon}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="gray"
      viewBox="0 0 24 24"
    >
      {isHovered ? (
        <path
          fillRule="evenodd"
          d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22Z"
          clipRule="evenodd"
        />
      ) : (
        <path
          stroke="gray"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22Z"
        />
      )}
    </svg>
  );

  const MessagesContainer = React.memo(() => (
    <div className={classes.messagesContainer}>
      {isLoading ? (
        <div className={classes.loadingIndicator}>
          <span className={`material-symbols-outlined ${classes.loadingIcon}`}>
            progress_activity
          </span>
        </div>
      ) : (
        messages.map((message) => (
          <ChatMessage
            key={message.id}
            message={message}
            currentUser={currentUser}
            userProfiles={userProfiles}
            handleImageChange={setCurrentImageIndex}
            currentImageIndex={currentImageIndex}
            selectedChat={selectedChat}
            firestore={firestore}
          />
        ))
      )}
    </div>
  ));

  const ChatHeader = React.memo(() => {
    const navigate = useNavigate();

    const handleUserClick = async () => {
      try {
        const userDoc = await getDoc(
          doc(firestore, "users", selectedChat?.otherUser?.id)
        );
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.urlSlug) {
            navigate(`/${userData.urlSlug}`);
            onClose();
          }
        }
      } catch (error) {}
    };

    return (
      <div className={classes.chatHeader}>
        <img
          src={selectedChat?.otherUser?.image || defaultImage}
          alt={selectedChat?.otherUser?.name || "User"}
          className={classes.userImage}
          onClick={handleUserClick}
          style={{ cursor: "pointer" }}
        />
        <div className={classes.headerInfo}>
          <div className={classes.nameContainer}>
            <div
              className={classes.userName}
              onClick={handleUserClick}
              style={{ cursor: "pointer" }}
            >
              {selectedChat?.otherUser?.name}
            </div>
            <span className={classes.userConnectionType}>
              •{" "}
              {isBlocked ? "Blocked" : selectedChat?.otherUser?.connectionType}
            </span>
          </div>
          <div className={classes.textWrapper}>
            {selectedChat?.otherUser?.bio && (
              <div className={classes.userBio}>
                {selectedChat.otherUser.bio}
              </div>
            )}
            {selectedChat?.otherUser?.link && (
              <a
                href={selectedChat.otherUser.link}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.userLink}
              >
                <span>
                  {selectedChat.otherUser.linkText ||
                    selectedChat.otherUser.link}
                </span>
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                  />
                </svg>
              </a>
            )}
          </div>
        </div>
        <div className={classes.cardTitle}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  });

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      window.scrollTo(0, scrollY);
    };
  }, []);

  // const FileUploadButtons = ({ onFileSelect }) => {
  //   const [isImageHovered, setIsImageHovered] = useState(false);
  //   const [isVideoHovered, setIsVideoHovered] = useState(false);
  //   const [isFileHovered, setIsFileHovered] = useState(false);

  //   return (
  //     <div className={classes.buttons}>
  //       <div
  //         onMouseEnter={() => setIsImageHovered(true)}
  //         onMouseLeave={() => setIsImageHovered(false)}
  //         onClick={() => onFileSelect("image")}
  //       >
  //         {isImageHovered ? (
  //           <svg
  //             className={classes.imageIcon}
  //             aria-hidden="true"
  //             xmlns="http://www.w3.org/2000/svg"
  //             width="24"
  //             height="24"
  //             fill="gray"
  //             viewBox="0 0 24 24"
  //           >
  //             <path
  //               fillRule="evenodd"
  //               d="M7.5 4.586A2 2 0 0 1 8.914 4h6.172a2 2 0 0 1 1.414.586L17.914 6H19a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h1.086L7.5 4.586ZM10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
  //               clipRule="evenodd"
  //             />
  //           </svg>
  //         ) : (
  //           <svg
  //             className={classes.imageIcon}
  //             aria-hidden="true"
  //             xmlns="http://www.w3.org/2000/svg"
  //             width="24"
  //             height="24"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //           >
  //             <path
  //               stroke="gray"
  //               strokeLinejoin="round"
  //               strokeWidth="2"
  //               d="M4 18V8a1 1 0 0 1 1-1h1.5l1.707-1.707A1 1 0 0 1 8.914 5h6.172a1 1 0 0 1 .707.293L17.5 7H19a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1Z"
  //             />
  //             <path
  //               stroke="gray"
  //               strokeLinejoin="round"
  //               strokeWidth="2"
  //               d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
  //             />
  //           </svg>
  //         )}
  //       </div>
  //       <div
  //         onMouseEnter={() => setIsVideoHovered(true)}
  //         onMouseLeave={() => setIsVideoHovered(false)}
  //         onClick={() => onFileSelect("video")}
  //       >
  //         {isVideoHovered ? (
  //           <svg
  //             className={classes.videoIcon}
  //             aria-hidden="true"
  //             xmlns="http://www.w3.org/2000/svg"
  //             width="24"
  //             height="24"
  //             fill="gray"
  //             viewBox="0 0 24 24"
  //           >
  //             <path
  //               fillRule="evenodd"
  //               d="M14 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7Zm2 9.387l4.684 1.562A1 1 0 0 0 22 17V7a1 1 0 0 0-1.316-.949L16 7.613v8.774Z"
  //               clipRule="evenodd"
  //             />
  //           </svg>
  //         ) : (
  //           <svg
  //             className={classes.videoIcon}
  //             aria-hidden="true"
  //             xmlns="http://www.w3.org/2000/svg"
  //             width="24"
  //             height="24"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //           >
  //             <path
  //               stroke="gray"
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth="2"
  //               d="M14 6H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Zm7 11-6-2V9l6-2v10Z"
  //             />
  //           </svg>
  //         )}
  //       </div>
  //       <div
  //         onMouseEnter={() => setIsFileHovered(true)}
  //         onMouseLeave={() => setIsFileHovered(false)}
  //         onClick={() => onFileSelect("pdf")}
  //       >
  //         {isFileHovered ? (
  //           <svg
  //             className={classes.fileIcon}
  //             aria-hidden="true"
  //             xmlns="http://www.w3.org/2000/svg"
  //             width="24"
  //             height="24"
  //             fill="gray"
  //             viewBox="0 0 24 24"
  //           >
  //             <path
  //               fillRule="evenodd"
  //               d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
  //               clipRule="evenodd"
  //             />
  //           </svg>
  //         ) : (
  //           <svg
  //             className={classes.fileIcon}
  //             aria-hidden="true"
  //             xmlns="http://www.w3.org/2000/svg"
  //             width="24"
  //             height="24"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //           >
  //             <path
  //               stroke="gray"
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth="2"
  //               d="M5 17v-5h1.5a1.5 1.5 0 1 1 0 3H5m12 2v-5h2m-2 3h2M5 10V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1v6M5 19v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1M10 3v4a1 1 0 0 1-1 1H5m6 4v5h1.375A1.627 1.627 0 0 0 14 15.375v-1.75A1.627 1.627 0 0 0 12.375 12H11Z"
  //             />
  //           </svg>
  //         )}
  //       </div>
  //     </div>
  //   );
  // };

  const removeFileExtension = (fileName) => {
    if (typeof fileName !== "string") return fileName;
    return fileName.replace(/\.[^/.]+$/, "");
  };

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      return await imageCompression(file, options);
    } catch (error) {
      return file;
    }
  };

  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.playsInline = true;
      video.muted = true;

      video.onloadedmetadata = () => {
        video.currentTime = 1;
      };

      video.onseeked = () => {
        const canvas = document.createElement("canvas");
        const aspectRatio = video.videoWidth / video.videoHeight;
        let width = 320;
        let height = 320 / aspectRatio;

        if (height > 240) {
          height = 240;
          width = 240 * aspectRatio;
        }

        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(video, 0, 0, width, height);
        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          0.7
        );
      };

      video.onerror = () => {
        resolve(null);
      };

      video.src = URL.createObjectURL(file);
    });
  };

  const ChatInput = React.memo(
    ({
      onSend,
      isProcessing,
      isSending,
      fileInputRef,
      isBlocked,
      handleFileSelect,
      handleFileUpload,
      handleRemoveFile,
      file,
      fileType,
      filePreview,
      fileName,
      isUploading,
      multipleImages,
      currentImageIndex,
      setCurrentImageIndex,
      videoThumbnail,
    }) => {
      const [newMessage, setNewMessage] = useState("");
      const messageInputRef = useRef(null);

      const handleTextAreaInput = (e) => {
        e.target.style.height = "auto";
        e.target.style.height = `${e.target.scrollHeight}px`;
        setNewMessage(e.target.value);
      };

      const handleSend = () => {
        if (newMessage.trim() || file) {
          onSend(newMessage);
          setNewMessage("");
          if (messageInputRef.current) {
            messageInputRef.current.style.height = "auto";
          }
        }
      };

      const isDisabled = !newMessage.trim() && !file;

      return (
        <div className={classes.chatInput}>
          <textarea
            ref={messageInputRef}
            value={newMessage}
            onChange={handleTextAreaInput}
            placeholder={isBlocked ? "Say something..." : "Say something..."}
            className={`${classes.messageInput} ${
              isBlocked ? classes.disabledInput : ""
            }`}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.target.style.height = "auto";
                e.target.style.height = `${e.target.scrollHeight}px`;
              }
            }}
            disabled={isBlocked}
          />
          <div className={classes.messageInputWrapper}>
            <div
              onClick={!isSending && !isBlocked ? handleSend : undefined}
              className={`${classes.button} ${
                isDisabled || isSending || isBlocked ? classes.disabled : ""
              }`}
              style={{
                opacity: isDisabled || isSending || isBlocked ? 0.5 : 1,
                cursor:
                  isDisabled || isSending || isBlocked
                    ? "not-allowed"
                    : "pointer",
              }}
            >
              {isSending ? (
                <span
                  className={`material-symbols-outlined ${classes.buttonIcon}`}
                >
                  progress_activity
                </span>
              ) : (
                "Send"
              )}
            </div>
          </div>
        </div>
      );
    }
  );

  const handleSendMessage = async (messageText) => {
    if (isSending || (!messageText.trim() && !file)) return;

    setIsSending(true);
    const chatId = selectedChat.id;
    const chatRef = doc(firestore, `chats/${chatId}`);

    try {
      const chatDoc = await getDoc(chatRef);
      const chatData = chatDoc.exists() ? chatDoc.data() : null;

      let fileData = null;
      if (file) {
        fileData = await uploadFile(file, chatId);
      }

      const messageData = {
        text: messageText.trim(),
        senderId: currentUser.uid,
        createdAt: serverTimestamp(),
        read: false,
        ...(fileData && { content: fileData }),
      };

      const batch = writeBatch(firestore);
      const currentUnreadCount = chatData?.unreadCount || {};

      const updatedUnreadCount = {
        ...currentUnreadCount,
        [selectedChat.otherUser.id]:
          (currentUnreadCount[selectedChat.otherUser.id] || 0) + 1,
        [currentUser.uid]: 0,
      };

      let updatedDeletedBy = chatData?.deletedBy || [];
      if (updatedDeletedBy.includes(selectedChat.otherUser.id)) {
        updatedDeletedBy = updatedDeletedBy.filter(
          (id) => id !== selectedChat.otherUser.id
        );
      }

      batch.update(chatRef, {
        participants: [currentUser.uid, selectedChat.otherUser.id],
        hasMessages: true,
        lastMessage: {
          text: messageData.text,
          senderId: currentUser.uid,
          ...(fileData && { content: fileData }),
        },
        lastMessageTime: serverTimestamp(),
        unreadCount: updatedUnreadCount,
        deletedBy: updatedDeletedBy,
      });

      const messagesRef = collection(chatRef, "messages");
      const newMessageRef = doc(messagesRef);
      batch.set(newMessageRef, messageData);

      await batch.commit();
      setNewMessage("");
      handleRemoveFile();

      if (onMessageSent) {
        onMessageSent();
      }
    } catch (error) {
    } finally {
      setIsSending(false);
    }
  };

  const ChatMessage = React.memo(
    ({
      message,
      currentUser,
      userProfiles,
      handleImageChange,
      currentImageIndex,
      selectedChat,
      firestore,
    }) => {
      const [showDeleteMenu, setShowDeleteMenu] = useState(false);
      const [isDeleting, setIsDeleting] = useState(false);
      const [videoVolume, setVideoVolume] = useState(false);
      const videoRef = useRef(null);
      const navigate = useNavigate();

      const handleUserClick = async (userId) => {
        try {
          const userDoc = await getDoc(doc(firestore, "users", userId));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            if (userData.urlSlug) {
              navigate(`/${userData.urlSlug}`);
            }
          }
        } catch (error) {}
      };

      useEffect(() => {
        if (!videoRef.current || message.content?.type !== "video") return;

        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              const video = entry.target;
              if (entry.isIntersecting) {
                video
                  .play()
                  .catch((error) => console.log("Autoplay prevented:", error));
              } else {
                video.pause();
              }
            });
          },
          { threshold: 0.5 }
        );

        observer.observe(videoRef.current);

        return () => {
          if (videoRef.current) {
            observer.unobserve(videoRef.current);
          }
        };
      }, [message.content]);

      const handleDelete = async () => {
        if (!currentUser || isDeleting) return;

        setIsDeleting(true);
        try {
          const messageRef = doc(
            firestore,
            `chats/${selectedChat.id}/messages/${message.id}`
          );
          const chatRef = doc(firestore, `chats/${selectedChat.id}`);

          if (message.senderId === currentUser.uid) {
            const storage = getStorage();
            const deletePromises = [];

            if (message.content) {
              if (message.content.type === "image" && message.content.urls) {
                message.content.urls.forEach((url) => {
                  const fileRef = ref(storage, url);
                  deletePromises.push(deleteObject(fileRef));
                });
              } else if (message.content.url) {
                const fileRef = ref(storage, message.content.url);
                deletePromises.push(deleteObject(fileRef));

                if (message.content.thumbnail) {
                  const thumbnailRef = ref(storage, message.content.thumbnail);
                  deletePromises.push(deleteObject(thumbnailRef));
                }
              }
            }

            const messagesRef = collection(
              firestore,
              `chats/${selectedChat.id}/messages`
            );
            const q = query(messagesRef, orderBy("createdAt", "desc"));
            const messagesSnapshot = await getDocs(q);

            let previousMessage = null;
            for (const doc of messagesSnapshot.docs) {
              if (doc.id !== message.id) {
                const data = doc.data();
                previousMessage = {
                  text: data.text || "",
                  senderId: data.senderId,
                  createdAt: data.createdAt,
                  ...(data.content && { content: data.content }),
                };
                break;
              }
            }

            const batch = writeBatch(firestore);
            batch.delete(messageRef);

            batch.update(chatRef, {
              lastMessage: previousMessage || {
                text: "",
                senderId: "",
                createdAt: serverTimestamp(),
              },
              unreadCount: {
                [currentUser.uid]: 0,
                [selectedChat.otherUser.id]: 0,
              },
            });

            deletePromises.push(batch.commit());
            await Promise.all(deletePromises);
          } else {
            await updateDoc(messageRef, {
              hiddenFor: arrayUnion(currentUser.uid),
            });

            const messagesRef = collection(
              firestore,
              `chats/${selectedChat.id}/messages`
            );
            const q = query(messagesRef, orderBy("createdAt", "desc"));
            const messagesSnapshot = await getDocs(q);

            let visibleMessage = null;
            for (const doc of messagesSnapshot.docs) {
              const data = doc.data();
              if (!data.hiddenFor?.includes(currentUser.uid)) {
                visibleMessage = {
                  text: data.text || "",
                  senderId: data.senderId,
                  createdAt: data.createdAt,
                  ...(data.content && { content: data.content }),
                };
                break;
              }
            }

            if (message.id === messages[0]?.id) {
              await updateDoc(chatRef, {
                [`lastMessage.${currentUser.uid}`]: visibleMessage || {
                  text: "",
                  senderId: "",
                  createdAt: serverTimestamp(),
                },
              });
            }
          }

          setShowDeleteMenu(false);
        } catch (error) {
        } finally {
          setIsDeleting(false);
        }
      };

      if (message.hiddenFor?.includes(currentUser.uid)) {
        return null;
      }

      const handleVolumeClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setVideoVolume(!videoVolume);
      };

      const handleVideoClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const videos = document.querySelectorAll("video");
        videos.forEach((video) => {
          if (video !== videoRef.current) {
            video.pause();
          }
        });

        if (videoRef.current.paused) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      };

      const renderMessageContent = (content) => {
        switch (content.type) {
          case "image":
            if (Array.isArray(content.urls)) {
              return (
                <div className={classes.imageCarousel}>
                  {content.urls.map((url, index) => (
                    <img
                      key={index}
                      src={url}
                      alt=""
                      className={`${classes.messageImage} ${
                        index === currentImageIndex ? classes.activeImage : ""
                      }`}
                    />
                  ))}
                  {content.urls.length > 1 && (
                    <div className={classes.dotNavigation}>
                      {content.urls.map((_, index) => (
                        <span
                          key={index}
                          className={`${classes.dot} ${
                            index === currentImageIndex ? classes.activeDot : ""
                          }`}
                          onClick={() => handleImageChange(index)}
                        />
                      ))}
                    </div>
                  )}
                </div>
              );
            }
            return (
              <img src={content.url} alt="" className={classes.messageImage} />
            );

          case "video":
            return (
              <div className={classes.videoContainer}>
                <video
                  ref={videoRef}
                  className={classes.postContentVideo}
                  playsInline
                  muted={!videoVolume}
                  loop
                  autoPlay
                  preload="metadata"
                  poster={content.thumbnail || ""}
                  onClick={handleVideoClick}
                >
                  <source src={content.url} type="video/mp4" />
                </video>
                <div className={classes.videoControls}>
                  <button
                    className={classes.volumeControl}
                    onClick={handleVolumeClick}
                  >
                    {!videoVolume ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="white"
                        width="24px"
                        height="24px"
                      >
                        <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="white"
                        width="24px"
                        height="24px"
                      >
                        <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            );

          case "pdf":
            return (
              <a
                href={content.url}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.pdfView}
              >
                <div className={classes.pdfBox}>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className={classes.fileContainer}>
                  <div className={classes.fileAbout}>
                    <div className={classes.fileName}>
                      {content.fileName
                        ? content.fileName.replace(/\.pdf$/i, "")
                        : "PDF Document"}
                    </div>
                    <div className={classes.openLink}>
                      Open PDF
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </a>
            );

          default:
            return null;
        }
      };

      return (
        <div className={classes.messageContainer}>
          <img
            src={userProfiles[message.senderId]?.image || defaultImage}
            alt={userProfiles[message.senderId]?.name}
            className={classes.messageUserImage}
            onClick={() => handleUserClick(message.senderId)}
            style={{ cursor: "pointer" }}
          />
          <div className={classes.messageContent}>
            <div className={classes.userNameContainer}>
              <div>
                <span
                  className={classes.userName}
                  onClick={() => handleUserClick(message.senderId)}
                  style={{ cursor: "pointer" }}
                >
                  {userProfiles[message.senderId]?.name}
                </span>
              </div>

              <div className={classes.optionsContainer}>
                <svg
                  className={`${classes.optionsIcon} ${
                    showDeleteMenu ? classes.optionsIconActive : ""
                  }`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowDeleteMenu(!showDeleteMenu);
                  }}
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeWidth="3"
                    d="M6 12h.01m6 0h.01m5.99 0h.01"
                  />
                </svg>
                {showDeleteMenu && (
                  <div className={classes.optionsDropdown}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDelete();
                      }}
                      disabled={isDeleting}
                    >
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                        />
                      </svg>
                      Delete
                    </button>
                  </div>
                )}
              </div>
            </div>
            <span className={classes.connectionType}>
              {message.createdAt
                ?.toDate()
                .toLocaleTimeString([], {
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
                })
                .replace(/(am|pm)/i, (match) => match.toUpperCase())}
            </span>
            {message.text && (
              <div className={classes.messageText}>
                <div
                  className={classes.preserveFormatting}
                  dangerouslySetInnerHTML={{
                    __html: convertUrlsToLinks(message.text),
                  }}
                />
              </div>
            )}
            {message.content && renderMessageContent(message.content)}
          </div>
        </div>
      );
    }
  );

  const handleDeleteChat = async () => {
    if (!selectedChat || !currentUser) return;

    try {
      const chatRef = doc(firestore, `chats/${selectedChat.id}`);
      const chatDoc = await getDoc(chatRef);

      if (!chatDoc.exists()) return;

      const chatData = chatDoc.data();
      const deletedBy = chatData.deletedBy || [];

      // If the other user has already deleted the chat
      if (deletedBy.includes(selectedChat.otherUser.id)) {
        const storage = getStorage();
        const batch = writeBatch(firestore);

        // Get all messages to find media content
        const messagesRef = collection(chatRef, "messages");
        const messagesSnapshot = await getDocs(messagesRef);

        // Create array of promises for storage deletion
        const storageDeletePromises = [];

        messagesSnapshot.forEach((doc) => {
          const messageData = doc.data();
          // Delete the message document
          batch.delete(doc.ref);

          // If message has content (image, video, or PDF), delete from storage
          if (messageData.content) {
            if (
              messageData.content.type === "image" &&
              messageData.content.urls
            ) {
              // Handle multiple images
              messageData.content.urls.forEach((url) => {
                const fileRef = ref(storage, url);
                storageDeletePromises.push(deleteObject(fileRef));
              });
            } else if (messageData.content.url) {
              // Handle single file (video, PDF, or single image)
              const fileRef = ref(storage, messageData.content.url);
              storageDeletePromises.push(deleteObject(fileRef));

              // If it's a video, also delete its thumbnail
              if (messageData.content.thumbnail) {
                const thumbnailRef = ref(
                  storage,
                  messageData.content.thumbnail
                );
                storageDeletePromises.push(deleteObject(thumbnailRef));
              }
            }
          }
        });

        // Delete the chat document
        batch.delete(chatRef);

        // Execute all deletions
        await Promise.all([batch.commit(), ...storageDeletePromises]);
      } else {
        // Just mark as deleted for current user and store deletion timestamp
        await updateDoc(chatRef, {
          deletedBy: arrayUnion(currentUser.uid),
          [`deletedAt.${currentUser.uid}`]: serverTimestamp(),
        });
      }

      // Call the onDeleteChat callback from props
      if (onDeleteChat) {
        onDeleteChat();
      }

      // Close the modal
      onClose();
    } catch (error) {}
  };

  // File handling functions
  const handleFileSelect = (type) => {
    if (fileInputRef.current) {
      resetFileInput();
      if (type === "image") {
        fileInputRef.current.setAttribute("accept", "image/*");
        fileInputRef.current.removeAttribute("multiple");
      } else if (type === "pdf") {
        fileInputRef.current.setAttribute("accept", ".pdf");
        fileInputRef.current.removeAttribute("multiple");
      } else if (type === "video") {
        fileInputRef.current.setAttribute("accept", "video/*");
        fileInputRef.current.removeAttribute("multiple");
      }
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 0) {
      setIsProcessing(true);
      setFile(null);
      setFileType(null);
      setFilePreview(null);

      try {
        if (selectedFiles[0].type.startsWith("image/")) {
          const compressedImage = await compressImage(selectedFiles[0]);
          setFile([compressedImage]);
          setFileType("image");
          setFileName([compressedImage.name]);

          const reader = new FileReader();
          reader.onloadend = () => {
            setMultipleImages([reader.result]);
            setFilePreview(reader.result);
          };
          reader.readAsDataURL(compressedImage);
        } else if (selectedFiles[0].type.startsWith("video/")) {
          setFile(selectedFiles[0]);
          setFileType("video");
          setFileName(removeFileExtension(selectedFiles[0].name));

          const reader = new FileReader();
          reader.onloadend = () => setFilePreview(reader.result);
          reader.readAsDataURL(selectedFiles[0]);

          const thumbnailBlob = await generateVideoThumbnail(selectedFiles[0]);
          if (thumbnailBlob) {
            const thumbnailUrl = URL.createObjectURL(thumbnailBlob);
            setVideoThumbnail(thumbnailUrl);
          }
        } else if (selectedFiles[0].type === "application/pdf") {
          setFile(selectedFiles[0]);
          setFileType("pdf");
          setFileName(removeFileExtension(selectedFiles[0].name));
          setFilePreview(URL.createObjectURL(selectedFiles[0]));
        }
      } catch (error) {
        resetFileInput();
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const handleTextAreaInput = (e) => {
    // Reset height to auto first to handle text deletion
    e.target.style.height = "auto";
    // Set new height based on scrollHeight
    e.target.style.height = `${e.target.scrollHeight}px`;
    setNewMessage(e.target.value);
  };

  const uploadFile = async (file, chatId) => {
    const storage = getStorage();

    if (Array.isArray(file)) {
      const uploadPromises = file.map(async (f, index) => {
        const fileName = `image_${index}_${Date.now()}.jpg`;
        const uploadPath = `messageContent/${chatId}/${fileName}`;
        const fileRef = ref(storage, uploadPath);
        const uploadSnapshot = await uploadBytesResumable(fileRef, f);
        return getDownloadURL(uploadSnapshot.ref);
      });

      const downloadURLs = await Promise.all(uploadPromises);
      return {
        type: "image",
        urls: downloadURLs,
        fileNames: file.map((f) => f.name),
      };
    } else {
      const timestamp = Date.now();
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const fileName = `${fileType}_${timestamp}.${fileExtension}`;
      const uploadPath = `messageContent/${chatId}/${fileName}`;
      const fileRef = ref(storage, uploadPath);

      const uploadSnapshot = await uploadBytesResumable(fileRef, file);
      const downloadURL = await getDownloadURL(uploadSnapshot.ref);

      let additionalData = {};
      if (fileType === "video" && videoThumbnail) {
        const thumbnailFileName = `thumbnail_${timestamp}.jpg`;
        const thumbnailPath = `messageContent/${chatId}/${thumbnailFileName}`;
        const thumbnailRef = ref(storage, thumbnailPath);
        const thumbnailBlob = await fetch(videoThumbnail).then((r) => r.blob());
        const thumbnailSnapshot = await uploadBytesResumable(
          thumbnailRef,
          thumbnailBlob
        );
        const thumbnailURL = await getDownloadURL(thumbnailSnapshot.ref);
        additionalData.thumbnail = thumbnailURL;
      }

      return {
        type: fileType,
        url: downloadURL,
        fileName: file.name,
        ...additionalData,
      };
    }
  };

  const sendMessage = async () => {
    if (isSending || (!newMessage.trim() && !file) || !selectedChat) return;

    setIsSending(true);
    const chatId = selectedChat.id;
    const chatRef = doc(firestore, `chats/${chatId}`);

    try {
      const chatDoc = await getDoc(chatRef);
      const chatData = chatDoc.exists() ? chatDoc.data() : null;
      const activeViewers = chatData?.activeViewers || {};

      let fileData = null;
      if (file) {
        fileData = await uploadFile(file, chatId);
      }

      const messageData = {
        text: newMessage.trim(),
        senderId: currentUser.uid,
        createdAt: serverTimestamp(),
        read: false,
        ...(fileData && { content: fileData }),
      };

      const batch = writeBatch(firestore);
      const currentUnreadCount = chatData?.unreadCount || {};

      // Only increment unread count if recipient is not actively viewing the chat
      const updatedUnreadCount = {
        ...currentUnreadCount,
        [selectedChat.otherUser.id]: !activeViewers[selectedChat.otherUser.id]
          ? (currentUnreadCount[selectedChat.otherUser.id] || 0) + 1
          : 0,
        [currentUser.uid]: 0, // Always 0 for sender
      };

      let updatedDeletedBy = chatData?.deletedBy || [];
      if (updatedDeletedBy.includes(selectedChat.otherUser.id)) {
        updatedDeletedBy = updatedDeletedBy.filter(
          (id) => id !== selectedChat.otherUser.id
        );
      }

      batch.update(chatRef, {
        participants: [currentUser.uid, selectedChat.otherUser.id],
        hasMessages: true,
        lastMessage: {
          text: messageData.text,
          senderId: currentUser.uid,
          ...(fileData && { content: fileData }),
        },
        lastMessageTime: serverTimestamp(),
        unreadCount: updatedUnreadCount,
        deletedBy: updatedDeletedBy,
      });

      const messagesRef = collection(chatRef, "messages");
      const newMessageRef = doc(messagesRef);
      batch.set(newMessageRef, messageData);

      await batch.commit();
      setNewMessage("");
      handleRemoveFile();

      if (onMessageSent) {
        onMessageSent();
      }
    } catch (error) {
    } finally {
      setIsSending(false);
    }
  };

  // Add other utility functions (compressImage, generateVideoThumbnail, etc.)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        optionsButtonRef.current &&
        !optionsButtonRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <ChatHeader />
        <MessagesContainer />
        <ChatInput
          onSend={handleSendMessage}
          isProcessing={isProcessing}
          isSending={isSending}
          fileInputRef={fileInputRef}
          handleFileSelect={handleFileSelect}
          handleFileUpload={handleFileUpload}
          handleRemoveFile={handleRemoveFile}
          file={file}
          fileType={fileType}
          filePreview={filePreview}
          fileName={fileName}
          isUploading={isUploading}
          multipleImages={multipleImages}
          currentImageIndex={currentImageIndex}
          setCurrentImageIndex={setCurrentImageIndex}
          videoThumbnail={videoThumbnail}
          isBlocked={isBlocked} // Add this prop
        />
      </div>
    </div>
  );
};

// Helper components
const FilePreview = ({
  fileType,
  filePreview,
  fileName,
  isUploading,
  multipleImages,
  currentImageIndex,
  handleImageChange,
  videoThumbnail,
  onRemove,
}) => {
  if (isUploading) {
    return (
      <div className={classes.uploadingPreview}>
        <span className={`material-symbols-outlined ${classes.loadingIcon}`}>
          progress_activity
        </span>
      </div>
    );
  }

  switch (fileType) {
    case "image":
      return (
        <div className={classes.pdfPreview}>
          <div className={classes.pdfBox}>
            <img
              src={multipleImages[currentImageIndex]}
              alt=""
              className={classes.previewImage}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
          <div className={classes.fileContainer}>
            <div className={classes.fileAbout}>
              <div className={classes.fileName}>
                {Array.isArray(fileName)
                  ? fileName[currentImageIndex]
                  : fileName}
              </div>
              <div className={classes.open}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                Image ready to send
              </div>
            </div>
            <svg
              className={classes.closeIcon}
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onRemove}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
      );
    case "video":
      return (
        <div className={classes.pdfPreview}>
          <div className={classes.pdfBox}>
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M14 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7Zm2 9.387 4.684 1.562A1 1 0 0 0 22 17V7a1 1 0 0 0-1.316-.949L16 7.613v8.774Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div className={classes.fileContainer}>
            <div className={classes.fileAbout}>
              <div className={classes.fileName}>{fileName}</div>
              <div className={classes.open}>
                <svg width="14" height="14" fill="none" viewBox="0 0 24 24">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                Video ready to send
              </div>
            </div>
            <svg
              className={classes.closeIcon}
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onRemove}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
      );
    case "pdf":
      return (
        <div className={classes.pdfPreview}>
          <div className={classes.pdfBox}>
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div className={classes.fileContainer}>
            <div className={classes.fileAbout}>
              <div className={classes.fileName}>{fileName}</div>
              <div className={classes.open}>
                <svg width="14" height="14" fill="none" viewBox="0 0 24 24">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                PDF ready to send
              </div>
            </div>
            <svg
              className={classes.closeIcon}
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onRemove}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default ChatModal;
