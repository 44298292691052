import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  memo,
  useMemo,
} from "react";
import MutualMembersModal from "../../../components/Modals/MutualMembersModal";
import AddModeratorsModal from "../../../components/Modals/AddModeratorsModal";
import EditModeratorsModal from "../../../components/Modals/EditModeratorsModal";
import EditMembersModal from "../../../components/Modals/EditMembersModal";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import defaultImage from "../../../assets/icons/communityImage.jpg";
import defaultStartupImage from "../../../assets/images/startupImage.jpg";
import defaultProfileImage from "../../../assets/icons/profileImage.jpg";
import StartupInteractionModal from "../../../components/Modals/StartupInteractionModal";
import greyProfileImage from "../../../assets/icons/greyProfileImage.jpg";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./Community.module.css";
import { firestore } from "../../../firebase";
import ConnectionsModal from "../../../components/Modals/ConnectionsModal";
import TagsModal from "../../../components/Modals/TagsModal";
import MembersModal from "../../../components/Modals/MembersModal";
import { useUpload } from "../../../contexts/UploadContext";
import EmojiPicker from "../../../components/Dropdowns/EmojiPicker";
import StartupInteractionToggle from "../../../components/Dropdowns/StartupInteractionToggle";
import PostModal from "../../../components/Modals/PostModal";
import EditSingleCommunityModal from "../../../components/Modals/EditSingleCommunityModal";
import IntroModal from "../../../components/Modals/IntroModal";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import AddMembersModal from "../../../components/Modals/AddMembersModal";
import ReactionsModal from "../../../components/Modals/ReactionsModal";
import {
  doc,
  getDoc,
  writeBatch,
  deleteField,
  collection,
  getDocs,
  setDoc,
  query,
  increment,
  where,
  orderBy,
  serverTimestamp,
  addDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../firebase";

const MemoizedToggle = React.memo(
  ({ currentUser, selectedStartup, onModalOpen, onModalClose, ...props }) => (
    <StartupInteractionToggle
      currentUser={currentUser}
      selectedStartup={selectedStartup}
      onModalOpen={onModalOpen}
      onModalClose={onModalClose}
      imgProps={{ "data-is-startup": !!selectedStartup }}
      {...props}
    />
  )
);

const Community = () => {
  const [isBlocked, setIsBlocked] = useState(false);
  const [hasUserBlockedThem, setHasUserBlockedThem] = useState(false);
  const [mutualMembersCount, setMutualMembersCount] = useState(0);
  const [showMutualMembersModal, setShowMutualMembersModal] = useState(false);
  const [isMutualMembersModalOpen, setIsMutualMembersModalOpen] =
    useState(false);
  const [
    mutualMembersModalScrollPosition,
    setMutualMembersModalScrollPosition,
  ] = useState(0);
  const [addMembersModalScrollPosition, setAddMembersModalScrollPosition] =
    useState(0);
  const [deletingPostMessage, setDeletingPostMessage] = useState(null);
  const [reportMessage, setReportMessage] = useState(null);
  const [deletingCommentMessage, setDeletingCommentMessage] = useState(null);
  const [loadingComments, setLoadingComments] = useState({});
  const [activeStartupToggle, setActiveStartupToggle] = useState(null);
  const [connectionsCount, setConnectionsCount] = useState(0);
  const [removeMessage, setRemoveMessage] = useState(null);
  const { communityUrlSlug } = useParams();
  const navigate = useNavigate();
  const [actionMessage, setActionMessage] = useState(null);
  const [highlightedPostId, setHighlightedPostId] = useState(null);
  const [shouldScrollToPost, setShouldScrollToPost] = useState(false);
  const location = useLocation();
  const params = useParams();
  const postRefs = useRef({});
  const [shareStates, setShareStates] = useState({});
  const [isSafari, setIsSafari] = useState(false);
  const [errorPopup, setErrorPopup] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [communityData, setCommunityData] = useState({
    communityName: "",
    bio: "",
    city: "",
    country: "",
    link: "",
    linkText: "",
    communityImage: "",
    intro: "",
    introHtml: "",
    tags: [],
  });

  const optionsButtonRef = useRef(null);
  const dropdownRef = useRef(null);
  const [showEditMembersModal, setShowEditMembersModal] = useState(false);
  const [isEditMembersModalOpen, setIsEditMembersModalOpen] = useState(false);
  const [editMembersModalScrollPosition, setEditMembersModalScrollPosition] =
    useState(0);
  const [connectionTypes, setConnectionTypes] = useState({});
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [addMemberModalScrollPosition, setAddMemberModalScrollPosition] =
    useState(0);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [membersModalScrollPosition, setMembersModalScrollPosition] =
    useState(0);
  const [visiblePostsCount, setVisiblePostsCount] = useState(3);
  const [hasReachedExpandedView, setHasReachedExpandedView] = useState(false);
  const [isMembersModalOpen, setIsMembersModalOpen] = useState(false);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);
  const [tagsModalScrollPosition, setTagsModalScrollPosition] = useState(0);
  const [editCommentText, setEditCommentText] = useState("");
  const editTextareaRef = useRef(null);
  const [startupModalScrollPosition, setStartupModalScrollPosition] =
    useState(0);
  const [hasAvailableConnections, setHasAvailableConnections] = useState(false);
  const [showAddModeratorModal, setShowAddModeratorModal] = useState(false);
  const [showEditModeratorModal, setShowEditModeratorModal] = useState(false);
  const [isAddModeratorModalOpen, setIsAddModeratorModalOpen] = useState(false);
  const [isEditModeratorModalOpen, setIsEditModeratorModalOpen] =
    useState(false);
  const [inviteCount, setInviteCount] = useState(1);
  const [removeCount, setRemoveCount] = useState(1);
  const [currentModalType, setCurrentModalType] = useState(null);
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [hasAvailableMemberConnections, setHasAvailableMemberConnections] =
    useState(false);
  const [posts, setPosts] = useState([]);
  const profileImage = defaultProfileImage; // Make sure defaultProfileImage is imported
  const [addModeratorModalScrollPosition, setAddModeratorModalScrollPosition] =
    useState(0);
  const [
    editModeratorModalScrollPosition,
    setEditModeratorModalScrollPosition,
  ] = useState(0);

  const [visibleModeratorsCount, setVisibleModeratorsCount] = useState(2);
  const [visibleTagsCount, setVisibleTagsCount] = useState(9);
  const [isStartupModalOpen, setIsStartupModalOpen] = useState(false);
  const [deleteTimeouts, setDeleteTimeouts] = useState({});
  const [editingPost, setEditingPost] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);
  const [activePostReposts, setActivePostReposts] = useState(null);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [showEditModeratorsModal, setShowEditModeratorsModal] = useState(false);
  const [
    editModeratorsModalScrollPosition,
    setEditModeratorsModalScrollPosition,
  ] = useState(0);
  const [isEditModeratorsModalOpen, setIsEditModeratorsModalOpen] =
    useState(false);
  const [moderators, setModerators] = useState([]);
  const [repostMessage, setRepostMessage] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [communityNotFound, setcommunityNotFound] = useState(false);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [isIntroModalOpen, setIsIntroModalOpen] = useState(false);
  const [introModalScrollPosition, setIntroModalScrollPosition] = useState(0);
  const [showEditSingleCommunityModal, setShowEditSingleCommunityModal] =
    useState(false);
  const [userStartups, setUserStartups] = useState([]);
  const [showStartupModal, setShowStartupModal] = useState(false);

  const [isEditSingleCommunityModalOpen, setIsEditSingleCommunityModalOpen] =
    useState(false);
  const [connectionsModalScrollPosition, setConnectionsModalScrollPosition] =
    useState(0);
  const [isConnectionsModalOpen, setIsConnectionsModalOpen] = useState(false);
  const [
    editSingleCommunityModalScrollPosition,
    setEditSingleCommunityModalScrollPosition,
  ] = useState(0);
  const [visibleMembersCount, setVisibleMembersCount] = useState(3);
  const [members, setMembers] = useState([]);
  const [hasMoreMembers, setHasMoreMembers] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
  const [emojiPickerScrollPosition, setEmojiPickerScrollPosition] = useState(0);
  const [memberCount, setMemberCount] = useState(0);
  const [membershipStatus, setMembershipStatus] = useState("Join");
  const [hasReceivedInvite, setHasReceivedInvite] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);
  const [isLoading, setIsLoading] = useState(true);
  const [showFullIntro, setShowFullIntro] = useState(false);
  const [showAllKeywords, setShowAllKeywords] = useState(false);
  const [shareButtonText, setShareButtonText] = useState("Share");
  const [showPostModal, setShowPostModal] = useState(false);
  const [communityPosts, setCommunityPosts] = useState([]);
  const [postModalScrollPosition, setPostModalScrollPosition] = useState(0);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [expandedPosts, setExpandedPosts] = useState({});
  const [comments, setComments] = useState({});
  const [activeCommentPostId, setActiveCommentPostId] = useState(null);
  const [newComment, setNewComment] = useState({});
  const commentInputRef = useRef({});
  const [currentImageIndex, setCurrentImageIndex] = useState({});
  const [videoVolumes, setVideoVolumes] = useState({});
  const videoRefs = useRef({});
  const [replyComments, setReplyComments] = useState({});
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [isCommunityMember, setIsCommunityMember] = useState(false);
  const [expandedComments, setExpandedComments] = useState({});
  const [expandedReplies, setExpandedReplies] = useState({});
  const [isIOS, setIsIOS] = useState(false);
  const [visibleComments, setVisibleComments] = useState({});
  useEffect(() => {}, [visibleComments]);
  const [openCommentDropdown, setOpenCommentDropdown] = useState(null);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [activeCommentId, setActiveCommentId] = useState({});
  const replyInputRef = useRef(Object.create(null));
  const { state, updateUploadProgress, removeUploadingPost, cancelUpload } =
    useUpload();
  const [showConnectionsModal, setShowConnectionsModal] = useState(false);
  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const [showComments, setShowComments] = useState({});

  // Add this helper function
  const isUserOrStartupsBlocked = async (currentUserId, otherUserId) => {
    try {
      // Get all startups for both users
      const getUserStartups = async (userId) => {
        const startupsRef = collection(firestore, `users/${userId}/startups`);
        const startupsSnapshot = await getDocs(startupsRef);
        return startupsSnapshot.docs.map((doc) => doc.id);
      };

      const [currentUserStartups, otherUserStartups] = await Promise.all([
        getUserStartups(currentUserId),
        getUserStartups(otherUserId),
      ]);

      // Check current user's blocked users
      const currentUserBlockedRef = collection(
        firestore,
        `users/${currentUserId}/blockedUsers`
      );
      const currentUserBlockedSnapshot = await getDocs(currentUserBlockedRef);
      const currentUserBlockedIds = new Set(
        currentUserBlockedSnapshot.docs.map((doc) => doc.data().userId)
      );

      // Check if other user or their startups are blocked by current user
      if (currentUserBlockedIds.has(otherUserId)) return true;
      for (const startupId of otherUserStartups) {
        if (currentUserBlockedIds.has(`startup_${startupId}`)) return true;
      }

      // Check other user's blocked users
      const otherUserBlockedRef = collection(
        firestore,
        `users/${otherUserId}/blockedUsers`
      );
      const otherUserBlockedSnapshot = await getDocs(otherUserBlockedRef);
      const otherUserBlockedIds = new Set(
        otherUserBlockedSnapshot.docs.map((doc) => doc.data().userId)
      );

      // Check if current user or their startups are blocked by other user
      if (otherUserBlockedIds.has(currentUserId)) return true;
      for (const startupId of currentUserStartups) {
        if (otherUserBlockedIds.has(`startup_${startupId}`)) return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  const checkIfBlocked = async (currentUserId, communityOwnerId) => {
    if (!currentUserId || !communityOwnerId) return;

    try {
      // Check if current user has blocked the community owner
      const currentUserBlockedRef = collection(
        firestore,
        `users/${currentUserId}/blockedUsers`
      );
      const currentUserBlockedQuery = query(
        currentUserBlockedRef,
        where("userId", "==", communityOwnerId)
      );
      const currentUserBlockedSnap = await getDocs(currentUserBlockedQuery);

      // Check if community owner has blocked the current user
      const ownerBlockedRef = collection(
        firestore,
        `users/${communityOwnerId}/blockedUsers`
      );
      const ownerBlockedQuery = query(
        ownerBlockedRef,
        where("userId", "==", currentUserId)
      );
      const ownerBlockedSnap = await getDocs(ownerBlockedQuery);

      setHasUserBlockedThem(currentUserBlockedSnap.size > 0);
      setIsBlocked(
        currentUserBlockedSnap.size > 0 || ownerBlockedSnap.size > 0
      );
    } catch (error) {}
  };

  useEffect(() => {
    if (currentUser?.uid && communityData?.ownerId) {
      checkIfBlocked(currentUser.uid, communityData.ownerId);
    }
  }, [currentUser?.uid, communityData?.ownerId]);

  const fetchMutualMembersCount = async () => {
    if (!currentUser?.uid || !communityData?.id || !communityData.ownerId)
      return;

    try {
      // Get current user's connections
      const userConnectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const userConnectionsQuery = query(
        userConnectionsRef,
        where("status", "==", "Connected")
      );
      const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

      // Create a Set of user's connections (excluding dummyId)
      const userConnections = new Set(
        userConnectionsSnapshot.docs
          .filter((doc) => doc.id !== "dummyId")
          .map((doc) => doc.id)
      );

      // Get community members
      const membersRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/members`
      );
      const membersQuery = query(membersRef, where("status", "==", "Member"));
      const membersSnapshot = await getDocs(membersQuery);

      // Count mutual members
      const mutualCount = membersSnapshot.docs.filter(
        (doc) => userConnections.has(doc.id) && doc.id !== currentUser.uid
      ).length;

      setMutualMembersCount(mutualCount);
    } catch (error) {
      setMutualMembersCount(0);
    }
  };

  useEffect(() => {
    if (currentUser?.uid && communityData?.id) {
      fetchMutualMembersCount();
    }
  }, [currentUser?.uid, communityData?.id]);

  const toggleMutualMembersModal = () => {
    if (!showMutualMembersModal) {
      setMutualMembersModalScrollPosition(window.scrollY);
      setShowMutualMembersModal(true);
      setIsMutualMembersModalOpen(true);
    } else {
      setShowMutualMembersModal(false);
      setIsMutualMembersModalOpen(false);
      setTimeout(() => {
        window.scrollTo(0, mutualMembersModalScrollPosition);
      }, 0);
    }
  };

  useEffect(() => {
    const handlePostHighlighting = async () => {
      const targetPostId = location.state?.highlightPostId;
      if (!targetPostId || !communityData?.id || !communityData?.ownerId)
        return;

      setHighlightedPostId(targetPostId);
      setShouldScrollToPost(true);

      // Clean up the location state
      window.history.replaceState({}, document.title);

      // Force a refetch of community posts to ensure we have the highlighted post
      await fetchCommunityPosts();
    };

    handlePostHighlighting();
  }, [location, communityData]);

  const formatTime = (timestamp) => {
    if (!timestamp) return "";

    let date;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (typeof timestamp.toDate === "function") {
      date = timestamp.toDate();
    } else {
      return "";
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) return "now";
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)}d`;
    if (diffInSeconds < 2592000)
      return `${Math.floor(diffInSeconds / 604800)}w`;
    if (diffInSeconds < 31536000)
      return `${Math.floor(diffInSeconds / 2592000)}mo`;
    return `${Math.floor(diffInSeconds / 31536000)}y`;
  };

  const handleStartupSelect = useCallback((startup) => {
    setSelectedStartup(startup);
  }, []);

  const handleModalOpen = useCallback((toggleId) => {
    setStartupModalScrollPosition(window.scrollY);
    setShowStartupModal(true);
    setActiveStartupToggle(toggleId); // Add this line
  }, []);

  const handleModalClose = useCallback(() => {
    setShowStartupModal(false);
    setTimeout(() => {
      window.scrollTo(0, startupModalScrollPosition);
    }, 0);
    setActiveStartupToggle(null); // Add this line
  }, [startupModalScrollPosition]);

  const handleReport = () => {
    setShowDropdown(false);
    setActionMessage({
      text: "Report submitted.",
    });
  };

  useEffect(() => {
    if (showProfileDropdown) {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setShowProfileDropdown(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showProfileDropdown]);

  const positionDropdown = () => {
    if (optionsButtonRef.current && dropdownRef.current) {
      const buttonRect = optionsButtonRef.current.getBoundingClientRect();
      const dropdownRect = dropdownRef.current.getBoundingClientRect();

      dropdownRef.current.style.top = `${buttonRect.bottom + window.scrollY}px`;
      dropdownRef.current.style.right = `${
        window.innerWidth - buttonRect.right
      }px`;
    }
  };

  useEffect(() => {
    if (showDropdown) {
      positionDropdown();
      window.addEventListener("resize", positionDropdown);
      window.addEventListener("scroll", positionDropdown);
    }
    return () => {
      window.removeEventListener("resize", positionDropdown);
      window.removeEventListener("scroll", positionDropdown);
    };
  }, [showDropdown]);

  // Add this effect to fetch user startups
  useEffect(() => {
    const fetchUserStartups = async () => {
      if (!currentUser?.uid) return;
      try {
        const startupsRef = collection(
          firestore,
          `users/${currentUser.uid}/startups`
        );
        const startupsSnapshot = await getDocs(startupsRef);
        const startupsData = startupsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserStartups(startupsData);
      } catch (error) {}
    };

    fetchUserStartups();
  }, [currentUser?.uid]);

  const getTotalCommentsCount = (postId) => {
    const post = communityPosts.find((p) => p.id === postId);
    if (!comments[postId]) {
      // While comments aren't loaded, use the total from Firebase
      return post?.commentCount || 0;
    }

    // Once comments are loaded, use the actual count of all comments and replies
    return comments[postId].length;
  };

  const handleInteraction = (e, type, post) => {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target;
    const isStartupToggle = target.closest(".interactionToggle");

    if (!isStartupToggle) {
      switch (type) {
        case "boost":
          handleEmojiSelect("👍", post.id);
          break;
        case "comment":
          toggleCommentInput(post.id);
          break;
        case "share":
          handlePostShare(post.id);
          break;
      }
    }
  };

  const [cancelMessage, setCancelMessage] = useState(null);
  const [successPostMessage, setSuccessPostMessage] = useState(null);

  const [
    commentEmojiPickerScrollPosition,
    setCommentEmojiPickerScrollPosition,
  ] = useState(0);
  const [visibleReplies, setVisibleReplies] = useState({});

  const getPostRef = (post) => {
    if (!post) return null;

    return doc(
      firestore,
      `users/${post.communityData.ownerId}/communities/${post.communityData.id}/posts/${post.communityPostId}`
    );
  };

  const fetchConnectionsCount = async () => {
    if (!currentUser?.uid) return;

    try {
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const q = query(connectionsRef, where("status", "==", "Connected"));
      const snapshot = await getDocs(q);

      // Filter out dummyId and then count remaining connections
      const validConnections = snapshot.docs.filter(
        (doc) => doc.id !== "dummyId"
      ).length;
      setConnectionsCount(validConnections);
    } catch (error) {
      setConnectionsCount(0);
    }
  };

  useEffect(() => {
    if (currentUser?.uid) {
      fetchConnectionsCount();
    }
  }, [currentUser?.uid]);

  const toggleAddMemberModal = () => {
    if (!showAddMemberModal) {
      setAddMembersModalScrollPosition(window.scrollY);
      setShowAddMemberModal(true);
      setIsAddMemberModalOpen(true);
    } else {
      setShowAddMemberModal(false);
      setIsAddMemberModalOpen(false);
      setTimeout(() => {
        window.scrollTo(0, addMembersModalScrollPosition);
      }, 0);
    }
  };

  const toggleEditModeratorsModal = () => {
    if (!showEditModeratorsModal) {
      setEditModeratorsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editModeratorsModalScrollPosition);
      }, 0);
    }
    setShowEditModeratorsModal(!showEditModeratorsModal);
    setIsEditModeratorsModalOpen(!isEditModeratorsModalOpen);
  };

  const toggleTagsModal = () => {
    if (!showTagsModal) {
      setTagsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, tagsModalScrollPosition);
      }, 0);
    }
    setShowTagsModal(!showTagsModal);
    setIsTagsModalOpen(!isTagsModalOpen);
  };

  const toggleConnectionsModal = () => {
    if (!showConnectionsModal) {
      setConnectionsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, connectionsModalScrollPosition);
      }, 0);
    }
    setShowConnectionsModal(!showConnectionsModal);
    setIsConnectionsModalOpen(!isConnectionsModalOpen);
  };

  const checkAvailableMemberConnections = useCallback(async () => {
    if (!currentUser?.uid || !communityData?.id || !communityData.ownerId)
      return;

    try {
      // Get all user's connections
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const q = query(connectionsRef, where("status", "==", "Connected"));
      const querySnapshot = await getDocs(q);

      // Get only ACTIVE moderators
      const moderatorsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/moderators`
      );
      const moderatorsQuery = query(
        moderatorsRef,
        where("status", "==", "active")
      );
      const moderatorsSnapshot = await getDocs(moderatorsQuery);

      // Get only actual members (not invited ones)
      const membersRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/members`
      );
      const membersQuery = query(membersRef, where("status", "==", "Member"));
      const membersSnapshot = await getDocs(membersQuery);

      // Create a Set of excluded IDs (active moderators, actual members, and owner)
      const excludedIds = new Set([
        ...moderatorsSnapshot.docs.map((doc) => doc.id),
        ...membersSnapshot.docs.map((doc) => doc.id),
        communityData.ownerId,
      ]);

      // Check if there are any connections that aren't moderators, members, or owner
      const hasAvailable = querySnapshot.docs.some(
        (doc) => !excludedIds.has(doc.id)
      );

      setHasAvailableMemberConnections(hasAvailable);
    } catch (error) {}
  }, [currentUser?.uid, communityData?.id, communityData?.ownerId]);
  useEffect(() => {
    checkAvailableMemberConnections();
  }, [checkAvailableMemberConnections]);

  const checkAvailableConnections = useCallback(async () => {
    if (!currentUser?.uid || !communityData?.id || !communityData.ownerId)
      return;

    try {
      // Get all user's connections
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const q = query(connectionsRef, where("status", "==", "Connected"));
      const querySnapshot = await getDocs(q);

      // Get ONLY ACTIVE moderators
      const moderatorsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/moderators`
      );
      const moderatorsQuery = query(
        moderatorsRef,
        where("status", "==", "active")
      );
      const moderatorsSnapshot = await getDocs(moderatorsQuery);

      // Create a Set of only active moderators
      const activeModeratorIds = new Set(
        moderatorsSnapshot.docs.map((doc) => doc.id)
      );

      // Check if there are any connections that aren't active moderators
      // (pending invites don't count - we still want to show the addIcon)
      const hasAvailable = querySnapshot.docs.some(
        (doc) => !activeModeratorIds.has(doc.id)
      );

      setHasAvailableConnections(hasAvailable);
    } catch (error) {}
  }, [currentUser?.uid, communityData?.id, communityData?.ownerId]);

  const RemovePopup = ({ onClose, count = 1, type = "moderator", message }) => (
    <div className={classes.repostPopup}>
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="orangered"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
            clipRule="evenodd"
          />
        </svg>
        <p>
          {message ||
            `${
              count > 1
                ? `${count} ${type}s removed.`
                : `${type.charAt(0).toUpperCase() + type.slice(1)} removed.`
            }`}
        </p>
      </div>
      <div onClick={onClose} className={classes.repostCloseButton}>
        <svg
          className={classes.repostCloseIcon}
          width="32"
          height="32"
          viewBox="0 0 24 24"
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  useEffect(() => {
    checkAvailableConnections();
  }, [
    currentUser?.uid,
    communityData?.id,
    communityData?.ownerId,
    checkAvailableConnections,
  ]);

  // Add this component for the invite popup if you don't already have it
  const InvitePopup = ({ onClose, count = 1 }) => (
    <div className={classes.repostPopup}>
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="orangered"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
            clipRule="evenodd"
          />
        </svg>
        <p>{count > 1 ? "Invites sent." : "Invite sent."}</p>
      </div>
      <div onClick={onClose} className={classes.repostCloseButton}>
        <svg
          className={classes.repostCloseIcon}
          width="32"
          height="32"
          viewBox="0 0 24 24"
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  // Add these modal toggle functions
  const toggleAddModeratorModal = () => {
    if (!showAddModeratorModal) {
      setAddModeratorModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addModeratorModalScrollPosition);
      }, 0);
    }
    setShowAddModeratorModal(!showAddModeratorModal);
    setIsAddModeratorModalOpen(!isAddModeratorModalOpen);
  };

  const toggleEditModeratorModal = () => {
    if (!showEditModeratorModal) {
      setEditModeratorModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editModeratorModalScrollPosition);
      }, 0);
    }
    setShowEditModeratorModal(!showEditModeratorModal);
    setIsEditModeratorModalOpen(!isEditModeratorModalOpen);
  };

  const fetchMembers = async () => {
    if (!communityData?.id || !communityData.ownerId) return;

    try {
      const membersRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/members`
      );
      const membersQuery = query(membersRef, where("status", "==", "Member"));
      const membersSnapshot = await getDocs(membersQuery);

      const membersData = await Promise.all(
        membersSnapshot.docs.map(async (memberDoc) => {
          const userUUID = memberDoc.id;
          const memberData = memberDoc.data();

          // Check if user is blocked
          const isBlocked = await isUserOrStartupsBlocked(
            currentUser.uid,
            userUUID
          );

          const userDoc = await getDoc(doc(firestore, "users", userUUID));
          if (userDoc.exists()) {
            const userData = userDoc.data();

            let connectionType = "Extended";

            if (isBlocked) {
              connectionType = "Blocked";
            } else if (userUUID === currentUser.uid) {
              connectionType = "You";
            } else {
              const directConnectionRef = doc(
                firestore,
                `users/${currentUser.uid}/connections/${userUUID}`
              );
              const directConnectionDoc = await getDoc(directConnectionRef);

              if (
                directConnectionDoc.exists() &&
                directConnectionDoc.data().status === "Connected"
              ) {
                connectionType = "Direct";
              } else {
                const userConnectionsRef = collection(
                  firestore,
                  `users/${currentUser.uid}/connections`
                );
                const userConnectionsQuery = query(
                  userConnectionsRef,
                  where("status", "==", "Connected")
                );
                const userConnectionsSnapshot = await getDocs(
                  userConnectionsQuery
                );

                let isIndirect = false;
                for (const conn of userConnectionsSnapshot.docs) {
                  const indirectConnectionRef = doc(
                    firestore,
                    `users/${conn.id}/connections/${userUUID}`
                  );
                  const indirectConnectionDoc = await getDoc(
                    indirectConnectionRef
                  );
                  if (
                    indirectConnectionDoc.exists() &&
                    indirectConnectionDoc.data().status === "Connected"
                  ) {
                    isIndirect = true;
                    break;
                  }
                }
                connectionType = isIndirect ? "Indirect" : "Extended";
              }
            }

            return {
              id: memberDoc.id,
              userUUID: userUUID,
              joinedAt: memberData.joinedAt,
              status: memberData.status,
              userData: {
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || "",
                bio: userData.bio || "",
                link: userData.link || "",
                linkText: userData.linkText || "",
                city: userData.city || "",
                country: userData.country || "",
                urlSlug: userData.urlSlug || "",
                connectionType: connectionType, // Add connection type to userData
              },
            };
          }
          return null;
        })
      );

      const validMembers = membersData
        .filter((member) => member !== null)
        .sort((a, b) => b.joinedAt - a.joinedAt);

      setMembers(validMembers);
      setHasMoreMembers(validMembers.length > 3);
    } catch (error) {}
  };

  const handleRemoveModerator = async () => {
    if (!currentUser || !communityData.id || !communityData.ownerId) return;

    try {
      const batch = writeBatch(firestore);

      // Remove from moderators collection
      const moderatorRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/moderators/${currentUser.uid}`
      );
      batch.delete(moderatorRef);

      // Remove from user's communitiesModerating collection
      const communityModeratingRef = doc(
        firestore,
        `users/${currentUser.uid}/communitiesModerating/${communityData.id}`
      );
      batch.delete(communityModeratingRef);

      await batch.commit();

      // Update local state
      setMembershipStatus("Join");
      setModerators((prev) => prev.filter((mod) => mod.id !== currentUser.uid));
    } catch (error) {
      setErrorPopup({
        message: "An error occurred while leaving as moderator.",
        isError: true,
      });
    }
  };

  const handleShowMoreMembers = () => {
    if (visibleMembersCount > 3) {
      // Show less
      setVisibleMembersCount(3);
    } else {
      // Show more (2x previous amount)
      const nextCount = (visibleMembersCount - 3) * 2 + 6;
      setVisibleMembersCount(nextCount);
    }
  };

  useEffect(() => {
    if (communityData?.id) {
      fetchMembers();
    }
  }, [communityData?.id]);

  const fetchModerators = async () => {
    if (!communityData.id || !communityData.ownerId) return;

    try {
      const moderatorsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/moderators`
      );
      const moderatorsQuery = query(
        moderatorsRef,
        where("status", "==", "active")
      );
      const moderatorsSnapshot = await getDocs(moderatorsQuery);

      const moderatorsData = await Promise.all(
        moderatorsSnapshot.docs.map(async (moderatorDoc) => {
          const userDoc = await getDoc(
            doc(firestore, "users", moderatorDoc.id)
          );
          if (userDoc.exists()) {
            const userData = userDoc.data();
            return {
              id: moderatorDoc.id,
              userUUID: moderatorDoc.id,
              acceptedAt: moderatorDoc.data().acceptedAt, // Make sure we get the acceptedAt
              userData: {
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || "",
                bio: userData.bio || "",
                link: userData.link || "",
                linkText: userData.linkText || "",
                city: userData.city || "",
                country: userData.country || "",
                urlSlug: userData.urlSlug || "",
              },
            };
          }
          return null;
        })
      );

      const validModerators = moderatorsData.filter(Boolean).sort((a, b) => {
        // Convert timestamps to dates for comparison
        const dateA = a.acceptedAt?.toDate?.() || new Date(a.acceptedAt);
        const dateB = b.acceptedAt?.toDate?.() || new Date(b.acceptedAt);
        return dateA - dateB; // Oldest to newest
      });

      setModerators(validModerators);
    } catch (error) {}
  };

  useEffect(() => {
    if (communityData?.id) {
      fetchModerators();
    }
  }, [communityData?.id]);

  const UploadingPost = React.memo(
    ({ postId, progress, status, onCancelUpload }) => {
      const handleCancelUpload = useCallback(() => {
        onCancelUpload(postId);
      }, [onCancelUpload, postId]);

      const roundedProgress = Math.round(progress);

      if (status === "cancelling") {
        return null;
      }

      return (
        <div className={classes.uploadingPost}>
          <div className={classes.uploadingHeader}>
            <span>Posting...</span>
            <div onClick={handleCancelUpload} className={classes.cancelButton}>
              Cancel
            </div>
          </div>
          <div className={classes.barAndText}>
            <div className={classes.progressBarContainer}>
              <div
                className={classes.progressBarFill}
                style={{ width: `${roundedProgress}%` }}
              ></div>
            </div>
            <div className={classes.progressText}>{roundedProgress}%</div>
          </div>
          <div className={classes.header}>
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="gray"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                clip-rule="evenodd"
              />
            </svg>
            <span className={classes.smallText}>
              Do not leave this page while posting.
            </span>
          </div>
        </div>
      );
    },
    (prevProps, nextProps) => {
      return (
        prevProps.status === nextProps.status &&
        Math.floor(prevProps.progress) === Math.floor(nextProps.progress) &&
        prevProps.postId === nextProps.postId
      );
    }
  );

  // Add this before the return statement in Community.js
  const fetchMemberCount = async (ownerId, communityId) => {
    try {
      const membersRef = collection(
        firestore,
        `users/${ownerId}/communities/${communityId}/members`
      );
      const q = query(membersRef, where("status", "==", "Member"));
      const querySnapshot = await getDocs(q);
      setMemberCount(querySnapshot.size);
    } catch (error) {
      setMemberCount(0);
    }
  };

  const checkMembershipStatus = async (
    currentUserId,
    profileCommunityId,
    communityOwnerId
  ) => {
    if (currentUserId === communityOwnerId) {
      setMembershipStatus("Owner");
      return;
    }

    try {
      // First check if they're a moderator
      const moderatorRef = doc(
        firestore,
        `users/${communityOwnerId}/communities/${profileCommunityId}/moderators/${currentUserId}`
      );
      const moderatorDoc = await getDoc(moderatorRef);

      if (moderatorDoc.exists() && moderatorDoc.data().status === "active") {
        setMembershipStatus("Moderator");
        return;
      }

      // If not a moderator, check member status
      const membershipRef = doc(
        firestore,
        `users/${communityOwnerId}/communities/${profileCommunityId}/members/${currentUserId}`
      );
      const membershipDoc = await getDoc(membershipRef);

      if (membershipDoc.exists()) {
        const status = membershipDoc.data().status;
        if (status === "Member") {
          setMembershipStatus("Member");
        } else if (status === "request_pending") {
          setMembershipStatus("Pending");
        } else {
          // For invite_pending or any other status, show Join
          setMembershipStatus("Join");
        }
      } else {
        setMembershipStatus("Join");
      }
    } catch (error) {
      setMembershipStatus("Join");
    }
  };

  const handleJoin = async () => {
    if (!currentUser || !communityData.id) return;

    const batch = writeBatch(firestore);
    const membershipRef = doc(
      firestore,
      `users/${communityData.ownerId}/communities/${communityData.id}/members/${currentUser.uid}`
    );

    try {
      // If user is canceling their pending request
      if (membershipStatus === "Pending") {
        // Delete the pending membership request
        batch.delete(membershipRef);

        // Delete any related notifications
        const notificationsRef = collection(
          firestore,
          `users/${communityData.ownerId}/notifications`
        );
        const notificationsQuery = query(
          notificationsRef,
          where("type", "==", "join_request"),
          where("from", "==", currentUser.uid),
          where("communityId", "==", communityData.id)
        );

        const notificationsSnapshot = await getDocs(notificationsQuery);
        notificationsSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit();
        setMembershipStatus("Join");
        return;
      }

      // Handle new join request
      if (membershipStatus === "Join") {
        const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
        const userData = userDoc.data();

        if (communityData.isPrivate) {
          batch.set(membershipRef, {
            status: "request_pending",
            requestedAt: serverTimestamp(),
          });

          const notificationRef = doc(
            collection(
              firestore,
              `users/${communityData.ownerId}/notifications`
            )
          );

          batch.set(notificationRef, {
            type: "join_request",
            from: currentUser.uid,
            fromUserName: `${userData.firstName} ${userData.lastName}`,
            fromUserImage: userData.profileImage || defaultImage,
            communityId: communityData.id,
            communityName: communityData.communityName,
            communityImage: communityData.communityImage || defaultImage,
            communityUrlSlug: communityData.communityUrlSlug,
            createdAt: new Date(),
            isNew: true,
          });

          await batch.commit();
          setMembershipStatus("Pending");
        } else {
          const now = new Date();
          batch.set(membershipRef, {
            status: "Member",
            joinedAt: now,
          });

          const userCommunityRef = doc(
            firestore,
            `users/${currentUser.uid}/communitiesMemberOf/${communityData.id}`
          );

          batch.set(userCommunityRef, {
            communityId: communityData.id,
            communityName: communityData.communityName,
            communityImage: communityData.communityImage,
            communityUrlSlug: communityData.communityUrlSlug,
            ownerId: communityData.ownerId,
            joinedAt: now,
            status: "Member",
          });

          const notificationRef = doc(
            collection(
              firestore,
              `users/${communityData.ownerId}/notifications`
            )
          );

          batch.set(notificationRef, {
            type: "member_added",
            from: currentUser.uid,
            fromUserName: `${userData.firstName} ${userData.lastName}`,
            fromUserImage: userData.profileImage || defaultImage,
            communityId: communityData.id,
            communityName: communityData.communityName,
            communityImage: communityData.communityImage || defaultImage,
            communityUrlSlug: communityData.communityUrlSlug,
            message: `${userData.firstName} ${userData.lastName} is now a member.`,
            createdAt: now,
            isNew: true,
          });

          const userNotificationsRef = collection(
            firestore,
            `users/${currentUser.uid}/notifications`
          );

          const memberInvitesQuery = query(
            userNotificationsRef,
            where("type", "==", "member_invite"),
            where("communityId", "==", communityData.id)
          );

          const memberInvitesSnapshot = await getDocs(memberInvitesQuery);
          memberInvitesSnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });

          const memberRef = doc(
            firestore,
            `users/${communityData.ownerId}/communities/${communityData.id}/members/${currentUser.uid}`
          );
          const memberDoc = await getDoc(memberRef);
          if (memberDoc.exists() && memberDoc.data().status === "Pending") {
            batch.delete(memberRef);
          }

          await batch.commit();

          setMembershipStatus("Member");
          setMemberCount((prev) => prev + 1);
          setIsCommunityMember(true);

          const newMember = {
            id: currentUser.uid,
            userUUID: currentUser.uid,
            joinedAt: now,
            status: "Member",
            userData: {
              firstName: userData.firstName || "",
              lastName: userData.lastName || "",
              profileImage: userData.profileImage || "",
              bio: userData.bio || "",
              link: userData.link || "",
              linkText: userData.linkText || "",
              city: userData.city || "",
              country: userData.country || "",
              urlSlug: userData.urlSlug || "",
            },
          };

          setMembers((prevMembers) => [newMember, ...prevMembers]);
          setHasMoreMembers((prevState) => {
            const newTotalMembers = members.length + 1;
            return newTotalMembers > 3;
          });
        }
      }
    } catch (error) {}
  };

  // Update the useEffect where you fetch community data to include these:
  useEffect(() => {
    if (communityData.id && communityData.ownerId) {
      fetchMemberCount(communityData.ownerId, communityData.id);
    }
  }, [communityData.id, communityData.ownerId]);

  useEffect(() => {
    if (currentUser && communityData.id && communityData.ownerId) {
      checkMembershipStatus(
        currentUser.uid,
        communityData.id,
        communityData.ownerId
      );
    }
  }, [currentUser, communityData.id, communityData.ownerId]);

  const handleRemoveMember = async () => {
    if (!currentUser || !communityData.id || !communityData.ownerId) return;

    try {
      const batch = writeBatch(firestore);

      const memberRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/members/${currentUser.uid}`
      );
      const userCommunityRef = doc(
        firestore,
        `users/${currentUser.uid}/communitiesMemberOf/${communityData.id}`
      );

      batch.delete(memberRef);
      batch.delete(userCommunityRef);

      const userNotificationsRef = collection(
        firestore,
        `users/${currentUser.uid}/notifications`
      );
      const ownerNotificationsRef = collection(
        firestore,
        `users/${communityData.ownerId}/notifications`
      );

      // Only delete member-related notifications
      const memberNotificationsQuery = query(
        userNotificationsRef,
        where("communityId", "==", communityData.id),
        where("type", "in", [
          "join_request",
          "join_accepted",
          "member_added",
          "join_declined",
          "member_invite",
          "member_invite_response",
        ])
      );
      const memberNotificationsSnapshot = await getDocs(
        memberNotificationsQuery
      );

      memberNotificationsSnapshot.forEach((doc) => {
        if (doc.exists()) {
          batch.delete(doc.ref);
        }
      });

      // Similarly, only delete member-related notifications from owner
      const ownerNotificationsQuery = query(
        ownerNotificationsRef,
        where("from", "==", currentUser.uid),
        where("communityId", "==", communityData.id),
        where("type", "in", [
          "join_request",
          "join_accepted",
          "member_added",
          "join_declined",
        ])
      );
      const ownerNotificationsSnapshot = await getDocs(ownerNotificationsQuery);

      ownerNotificationsSnapshot.forEach((doc) => {
        if (doc.exists()) {
          batch.delete(doc.ref);
        }
      });

      await batch.commit();

      // Update local states
      setMemberCount((prev) => Math.max(0, prev - 1));
      setMembershipStatus("Join");
      setIsCommunityMember(false);
      // Filter out the current user from the members list
      setMembers((prevMembers) =>
        prevMembers.filter((member) => member.userUUID !== currentUser.uid)
      );
    } catch (error) {
      setErrorPopup({
        message: "An error occurred while leaving the community.",
        isError: true,
      });
    }
  };

  const handleAddComment = useCallback(
    async (postId, commentText, parentCommentId = null) => {
      if (!currentUser || !commentText?.trim()) return;

      try {
        const targetPost = communityPosts.find((p) => p.id === postId);
        if (!targetPost) return;

        const commentsRef = collection(
          firestore,
          `users/${communityData.ownerId}/communities/${communityData.id}/posts/${targetPost.communityPostId}/comments`
        );

        // Get commenter info (user or startup)
        let commenterInfo;
        if (selectedStartup) {
          const startupRef = doc(
            firestore,
            `users/${currentUser.uid}/startups/${selectedStartup.id}`
          );
          const startupDoc = await getDoc(startupRef);
          const startupData = startupDoc.data();

          commenterInfo = {
            id: `startup_${selectedStartup.id}`,
            name: selectedStartup.startupName,
            image: selectedStartup.startupImage || defaultStartupImage,
            bio: startupData.bio || "",
            link: startupData.link || null,
            linkText: startupData.linkText || null,
            connectionType: "Startup",
            isStartup: true,
            startupId: selectedStartup.id,
            ownerId: currentUser.uid,
            startupUrlSlug: startupData.startupUrlSlug,
          };
        } else {
          const userDoc = await getDoc(
            doc(firestore, "users", currentUser.uid)
          );
          const userData = userDoc.data();

          commenterInfo = {
            id: currentUser.uid,
            name: `${userData.firstName} ${userData.lastName}`,
            image: userData.profileImage || profileImage,
            bio: userData.bio || "",
            link: userData.link || null,
            linkText: userData.linkText || null,
            connectionType: "You",
            isStartup: false,
            urlSlug: userData.urlSlug,
          };
        }

        const now = new Date();
        const processedCommentText = convertUrlsToLinks(commentText);

        let rootCommentId = null;
        if (parentCommentId) {
          const parentCommentDoc = await getDoc(
            doc(commentsRef, parentCommentId)
          );
          if (parentCommentDoc.exists()) {
            const parentCommentData = parentCommentDoc.data();
            rootCommentId = parentCommentData.isRootComment
              ? parentCommentId
              : parentCommentData.rootCommentId;
          }
        }

        const batch = writeBatch(firestore);

        const commentData = {
          text: processedCommentText,
          userId: commenterInfo.id,
          userName: commenterInfo.name,
          userImage: commenterInfo.image,
          createdAt: serverTimestamp(),
          isRootComment: !parentCommentId,
          parentCommentId,
          rootCommentId,
          bio: commenterInfo.bio,
          link: commenterInfo.link,
          linkText: commenterInfo.linkText,
          connectionType: commenterInfo.connectionType,
          postUserId: targetPost.user.id,
          ...(commenterInfo.isStartup && {
            isStartup: true,
            startupId: commenterInfo.startupId,
            startupOwnerId: commenterInfo.ownerId,
            startupUrlSlug: commenterInfo.startupUrlSlug,
          }),
        };

        const newCommentRef = await addDoc(commentsRef, commentData);

        batch.update(
          doc(
            firestore,
            `users/${communityData.ownerId}/communities/${communityData.id}/posts/${targetPost.communityPostId}`
          ),
          {
            [`comments.${commenterInfo.id}`]: serverTimestamp(),
            ...(parentCommentId ? {} : { commentCount: increment(1) }),
          }
        );

        // Handle notifications based on comment type
        if (!parentCommentId) {
          // For top-level comments, notify post owner (if it's not their own comment)
          const shouldNotifyPostOwner =
            targetPost.user.id !== currentUser.uid &&
            (!targetPost.user.isStartup ||
              (targetPost.user.isStartup &&
                targetPost.user.startupOwnerId !== currentUser.uid));

          if (shouldNotifyPostOwner) {
            const notificationRef = doc(
              collection(
                firestore,
                `users/${
                  targetPost.user.isStartup
                    ? targetPost.user.startupOwnerId
                    : targetPost.user.id
                }/notifications`
              )
            );

            const notificationData = {
              type: "comment",
              from: currentUser.uid,
              postId: targetPost.id,
              commentId: newCommentRef.id,
              communityId: communityData.id,
              communityOwnerId: communityData.ownerId,
              communityName: communityData.communityName,
              commentText: commentText,
              fromUserName: commenterInfo.name, // This is the key field we need to fix
              fromUserImage: commenterInfo.image,
              createdAt: serverTimestamp(),
              isNew: true,
              postPreview: {
                text: targetPost.text || "",
                mediaType: targetPost.content?.type || null,
                mediaUrl: targetPost.content?.url || null,
                fileName: targetPost.content?.fileName || null,
                ...(targetPost.content?.type === "video" && {
                  thumbnail: targetPost.content.thumbnail,
                }),
              },
              // Add these fields when it's a startup commenting
              ...(commenterInfo.isStartup && {
                isStartup: true,
                startupId: selectedStartup.id,
                startupOwnerId: currentUser.uid,
                startupName: selectedStartup.startupName, // Add this line
                startupImage: commenterInfo.image,
                startupUrlSlug: commenterInfo.startupUrlSlug,
              }),
            };

            batch.set(notificationRef, notificationData);
          }
        } else {
          // For replies, notify the parent comment owner
          const parentCommentRef = doc(commentsRef, parentCommentId);
          const parentCommentSnap = await getDoc(parentCommentRef);
          const parentCommentData = parentCommentSnap.exists()
            ? parentCommentSnap.data()
            : null;

          const shouldNotifyParentCommenter =
            parentCommentData &&
            ((parentCommentData.isStartup &&
              currentUser.uid !== parentCommentData.startupOwnerId &&
              (!commenterInfo.isStartup ||
                commenterInfo.ownerId !== parentCommentData.startupOwnerId)) ||
              (!parentCommentData.isStartup &&
                currentUser.uid !== parentCommentData.userId &&
                (!commenterInfo.isStartup ||
                  commenterInfo.ownerId !== parentCommentData.userId)));

          if (shouldNotifyParentCommenter) {
            const replyNotificationRef = doc(
              firestore,
              `users/${
                parentCommentData.isStartup
                  ? parentCommentData.startupOwnerId
                  : parentCommentData.userId
              }/notifications`,
              `reply_${newCommentRef.id}`
            );

            const replyNotificationData = {
              type: "reply",
              postId: postId,
              commentId: newCommentRef.id,
              parentCommentId: parentCommentId,
              replierId: commenterInfo.id,
              replierName: commenterInfo.name,
              replierImage: commenterInfo.image,
              replyText: commentText,
              createdAt: serverTimestamp(),
              isNew: true,
              communityId: communityData.id,
              communityOwnerId: communityData.ownerId,
              communityName: communityData.communityName,
              parentCommentPreview: {
                text: parentCommentData.text || "",
              },
              postPreview: {
                text: targetPost.text || "",
                mediaType: targetPost.content?.type || null,
                mediaUrl: targetPost.content?.url || null,
                fileName: targetPost.content?.fileName || null,
                ...(targetPost.content?.type === "video" && {
                  thumbnail: targetPost.content.thumbnail,
                }),
              },
              ...(commenterInfo.isStartup && {
                isStartup: true,
                startupId: selectedStartup.id,
                startupOwnerId: currentUser.uid,
                startupImage:
                  selectedStartup.startupImage || defaultStartupImage,
              }),
            };

            batch.set(replyNotificationRef, replyNotificationData);
          }
        }

        await batch.commit();

        const newCommentObj = {
          id: newCommentRef.id,
          ...commentData,
          createdAt: now,
          postId,
        };

        setComments((prev) => ({
          ...prev,
          [postId]: [...(prev[postId] || []), newCommentObj],
        }));

        if (!parentCommentId) {
          setNewComment((prev) => ({ ...prev, [postId]: "" }));
        }

        setCommunityPosts((prevPosts) =>
          prevPosts.map((p) => {
            if (p.id === postId) {
              return {
                ...p,
                commentCount: parentCommentId
                  ? p.commentCount
                  : (p.commentCount || 0) + 1,
                comments: {
                  ...(p.comments || {}),
                  [commenterInfo.id]: now,
                },
              };
            }
            return p;
          })
        );

        return newCommentObj;
      } catch (error) {
        setErrorPopup({
          message: "An error occurred while adding the comment.",
          isError: true,
        });
      }
    },
    [currentUser, selectedStartup, communityData, communityPosts]
  );

  const showInteractionToggle = userStartups && userStartups.length > 0;

  const checkConnectionType = async (userId, currentUserId) => {
    if (userId === currentUserId) return "You";

    try {
      // Check for direct connection
      const connectionRef = doc(
        firestore,
        `users/${currentUserId}/connections/${userId}`
      );
      const connectionDoc = await getDoc(connectionRef);

      if (
        connectionDoc.exists() &&
        connectionDoc.data().status === "Connected"
      ) {
        return "Direct";
      }

      // Check for indirect connections
      const userConnectionsRef = collection(
        firestore,
        `users/${currentUserId}/connections`
      );
      const userConnectionsQuery = query(
        userConnectionsRef,
        where("status", "==", "Connected")
      );
      const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

      for (const conn of userConnectionsSnapshot.docs) {
        const indirectConnectionRef = doc(
          firestore,
          `users/${conn.id}/connections/${userId}`
        );
        const indirectConnectionDoc = await getDoc(indirectConnectionRef);
        if (
          indirectConnectionDoc.exists() &&
          indirectConnectionDoc.data().status === "Connected"
        ) {
          return "Indirect";
        }
      }

      return "Extended";
    } catch (error) {
      return "Extended";
    }
  };

  useEffect(() => {
    const fetchConnectionTypes = async () => {
      if (!currentUser?.uid) return;

      const types = {};

      // Add owner
      if (communityData?.ownerId) {
        const isBlocked = await isUserOrStartupsBlocked(
          currentUser.uid,
          communityData.ownerId
        );
        types[communityData.ownerId] = isBlocked
          ? "Blocked"
          : await checkConnectionType(communityData.ownerId, currentUser.uid);
      }

      // Add moderators
      for (const moderator of moderators) {
        if (!types[moderator.userUUID]) {
          const isBlocked = await isUserOrStartupsBlocked(
            currentUser.uid,
            moderator.userUUID
          );
          types[moderator.userUUID] = isBlocked
            ? "Blocked"
            : await checkConnectionType(moderator.userUUID, currentUser.uid);
        }
      }

      // Add members
      for (const member of members) {
        if (!types[member.userUUID]) {
          const isBlocked = await isUserOrStartupsBlocked(
            currentUser.uid,
            member.userUUID
          );
          types[member.userUUID] = isBlocked
            ? "Blocked"
            : await checkConnectionType(member.userUUID, currentUser.uid);
        }
      }

      // Add post authors
      for (const post of communityPosts) {
        if (!types[post.user.id]) {
          const isBlocked = await isUserOrStartupsBlocked(
            currentUser.uid,
            post.user.id
          );
          types[post.user.id] = isBlocked
            ? "Blocked"
            : await checkConnectionType(post.user.id, currentUser.uid);
        }
      }

      setConnectionTypes(types);
    };

    fetchConnectionTypes();
  }, [
    currentUser?.uid,
    communityData?.ownerId,
    moderators,
    members,
    communityPosts,
  ]);

  // Update fetchComments to use the correct path:

  const fetchComments = async (postId) => {
    if (!communityData?.id || !postId || !currentUser?.uid) return;

    setLoadingComments((prev) => ({ ...prev, [postId]: true }));

    try {
      const targetPost = communityPosts.find((p) => p.id === postId);
      if (!targetPost) return;

      const commentsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${targetPost.communityPostId}/comments`
      );

      const q = query(commentsRef, orderBy("createdAt", "asc"));
      const snapshot = await getDocs(q);

      const fetchedComments = await Promise.all(
        snapshot.docs.map(async (docSnapshot) => {
          const commentData = docSnapshot.data();

          if (commentData.isStartup) {
            const isBlocked = await isUserOrStartupsBlocked(
              currentUser.uid,
              commentData.startupOwnerId
            );
            if (isBlocked) return null;

            const startupRef = doc(
              firestore,
              `users/${commentData.startupOwnerId}/startups/${commentData.startupId}`
            );
            const startupDoc = await getDoc(startupRef);

            if (!startupDoc.exists()) return null;

            const startupData = startupDoc.data();
            return {
              id: docSnapshot.id,
              ...commentData,
              userName: startupData.startupName,
              userImage: startupData.startupImage || defaultStartupImage,
              connectionType:
                commentData.startupOwnerId === currentUser.uid
                  ? "You"
                  : "Startup",
              bio: startupData.bio || "",
              link: startupData.link || null,
              linkText: startupData.linkText || null,
              postId,
              postUserId: targetPost.user.id,
              rootCommentId: commentData.rootCommentId || docSnapshot.id,
              isRootComment: !commentData.parentCommentId,
              startupUrlSlug: startupData.startupUrlSlug,
            };
          } else {
            const isBlocked = await isUserOrStartupsBlocked(
              currentUser.uid,
              commentData.userId
            );
            if (isBlocked) return null;

            const userDoc = await getDoc(
              doc(firestore, "users", commentData.userId)
            );
            if (!userDoc.exists()) return null;

            const userData = userDoc.data();
            let connectionType;

            if (commentData.userId === currentUser.uid) {
              connectionType = "You";
            } else {
              const directConnectionRef = doc(
                firestore,
                `users/${currentUser.uid}/connections/${commentData.userId}`
              );
              const directConnectionDoc = await getDoc(directConnectionRef);

              if (
                directConnectionDoc.exists() &&
                directConnectionDoc.data().status === "Connected"
              ) {
                connectionType = "Direct";
              } else {
                const userConnectionsRef = collection(
                  firestore,
                  `users/${currentUser.uid}/connections`
                );
                const userConnectionsQuery = query(
                  userConnectionsRef,
                  where("status", "==", "Connected")
                );
                const userConnectionsSnapshot = await getDocs(
                  userConnectionsQuery
                );

                let isIndirect = false;
                for (const conn of userConnectionsSnapshot.docs) {
                  const indirectConnectionRef = doc(
                    firestore,
                    `users/${conn.id}/connections/${commentData.userId}`
                  );
                  const indirectConnectionDoc = await getDoc(
                    indirectConnectionRef
                  );
                  if (
                    indirectConnectionDoc.exists() &&
                    indirectConnectionDoc.data().status === "Connected"
                  ) {
                    isIndirect = true;
                    break;
                  }
                }
                connectionType = isIndirect ? "Indirect" : "Extended";
              }
            }

            return {
              id: docSnapshot.id,
              ...commentData,
              userName: `${userData.firstName} ${userData.lastName}`,
              userImage: userData.profileImage || defaultProfileImage,
              connectionType,
              bio: userData.bio || "",
              link: userData.link || null,
              linkText: userData.linkText || null,
              postId,
              postUserId: targetPost.user.id,
              rootCommentId: commentData.rootCommentId || docSnapshot.id,
              isRootComment: !commentData.parentCommentId,
              urlSlug: userData.urlSlug,
            };
          }
        })
      );

      const validComments = fetchedComments.filter(Boolean);

      setComments((prev) => ({
        ...prev,
        [postId]: validComments,
      }));

      setVisibleComments((prev) => ({
        ...prev,
        [postId]: 3,
      }));

      setCommunityPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId
            ? {
                ...p,
                commentCount: validComments.filter(
                  (comment) => comment.isRootComment
                ).length,
              }
            : p
        )
      );
    } catch (error) {
      setErrorPopup({
        message: "Error loading comments.",
        isError: true,
      });
    } finally {
      setLoadingComments((prev) => ({ ...prev, [postId]: false }));
    }
  };

  // Add this function to handle edit comment

  // Add helper function to strip HTML tags
  const stripHtmlTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  useEffect(() => {
    if (activeCommentPostId && !comments[activeCommentPostId]) {
      fetchComments(activeCommentPostId);
    }
  }, [activeCommentPostId, comments, fetchComments]);

  // In your fetchCommunityPosts function, modify where you process the post data:

  // Function to fetch latest user/startup data for post headers
  const fetchLatestPostHeaderData = async (post, firestore) => {
    try {
      if (!post || !post.user) return null;

      let updatedUserData;

      if (post.user.isStartup) {
        // For startup posts, fetch both startup and owner data
        const [startupDoc, ownerDoc] = await Promise.all([
          getDoc(
            doc(
              firestore,
              `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
            )
          ),
          getDoc(doc(firestore, "users", post.user.startupOwnerId)),
        ]);

        if (!startupDoc.exists() || !ownerDoc.exists()) {
          return null;
        }

        const startupData = startupDoc.data();
        updatedUserData = {
          id: `startup_${post.user.startupId}`,
          startupId: post.user.startupId,
          startupOwnerId: post.user.startupOwnerId,
          firstName: startupData.startupName,
          lastName: "",
          profileImage: startupData.startupImage || defaultStartupImage,
          bio: startupData.bio || "",
          link: startupData.link || null,
          linkText: startupData.linkText || null,
          isStartup: true,
          connectionType: "Startup",
          startupUrlSlug: startupData.startupUrlSlug,
        };
      } else {
        // For regular user posts
        const userDoc = await getDoc(doc(firestore, "users", post.user.id));

        if (!userDoc.exists()) {
          return null;
        }

        const userData = userDoc.data();
        updatedUserData = {
          id: post.user.id,
          firstName: userData.firstName || "",
          lastName: userData.lastName || "",
          profileImage: userData.profileImage || defaultProfileImage,
          bio: userData.bio || "",
          link: userData.link || null,
          linkText: userData.linkText || null,
          isStartup: false,
          connectionType: post.user.connectionType || "Extended",
          urlSlug: userData.urlSlug,
        };
      }

      return {
        ...post,
        user: {
          ...post.user,
          ...updatedUserData,
        },
      };
    } catch (error) {
      return null;
    }
  };

  // Function to update all post headers
  const updateAllPostHeaders = async (posts, firestore) => {
    try {
      const updatedPosts = await Promise.all(
        posts.map((post) => fetchLatestPostHeaderData(post, firestore))
      );

      // Filter out any null results from failed updates
      return updatedPosts.filter((post) => post !== null);
    } catch (error) {
      return posts; // Return original posts if update fails
    }
  };

  const fetchCommunityPosts = async () => {
    if (!communityUrlSlug || !communityData?.id || !currentUser?.uid) return;

    try {
      const communityPostsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts`
      );

      const q = query(communityPostsRef, orderBy("createdAt", "desc"));
      const postsSnapshot = await getDocs(q);

      const fullPosts = await Promise.all(
        postsSnapshot.docs.map(async (postDoc) => {
          const postData = postDoc.data();

          const basePath = postData.isStartup
            ? `users/${postData.userId}/startups/${postData.startupId}/communityPosts`
            : `users/${postData.userId}/communityPosts`;

          try {
            let isBlocked = false;
            if (postData.isStartup) {
              isBlocked = await isUserOrStartupsBlocked(
                currentUser.uid,
                postData.userId
              );
            } else {
              isBlocked = await isUserOrStartupsBlocked(
                currentUser.uid,
                postData.userId
              );
            }

            if (isBlocked) return null;

            const fullPostRef = doc(
              firestore,
              `${basePath}/${postData.postId}`
            );
            const fullPostSnap = await getDoc(fullPostRef);

            if (!fullPostSnap.exists()) return null;

            const commentsRef = collection(
              firestore,
              `users/${communityData.ownerId}/communities/${communityData.id}/posts/${postDoc.id}/comments`
            );
            const commentsSnapshot = await getDocs(commentsRef);

            const communityPostRef = doc(
              firestore,
              `users/${communityData.ownerId}/communities/${communityData.id}/posts/${postDoc.id}`
            );
            const communityPostSnap = await getDoc(communityPostRef);
            const communityPostData = communityPostSnap.data();

            let updatedUserData;
            let connectionType;

            if (postData.isStartup) {
              const [startupDoc, ownerDoc] = await Promise.all([
                getDoc(
                  doc(
                    firestore,
                    `users/${postData.userId}/startups/${postData.startupId}`
                  )
                ),
                getDoc(doc(firestore, "users", postData.userId)),
              ]);

              if (!startupDoc.exists() || !ownerDoc.exists()) return null;

              const startupData = startupDoc.data();
              connectionType =
                postData.userId === currentUser.uid ? "You" : "Startup";

              updatedUserData = {
                id: `startup_${postData.startupId}`,
                startupId: postData.startupId,
                startupOwnerId: postData.userId,
                firstName: startupData.startupName,
                lastName: "",
                profileImage: startupData.startupImage || defaultStartupImage,
                bio: startupData.bio || "",
                link: startupData.link || null,
                linkText: startupData.linkText || null,
                isStartup: true,
                connectionType,
                startupUrlSlug: startupData.startupUrlSlug,
              };
            } else {
              const userDoc = await getDoc(
                doc(firestore, "users", postData.userId)
              );
              if (!userDoc.exists()) return null;

              const userData = userDoc.data();

              if (postData.userId === currentUser.uid) {
                connectionType = "You";
              } else {
                const directConnectionRef = doc(
                  firestore,
                  `users/${currentUser.uid}/connections/${postData.userId}`
                );
                const directConnectionDoc = await getDoc(directConnectionRef);

                if (
                  directConnectionDoc.exists() &&
                  directConnectionDoc.data().status === "Connected"
                ) {
                  connectionType = "Direct";
                } else {
                  const userConnectionsRef = collection(
                    firestore,
                    `users/${currentUser.uid}/connections`
                  );
                  const userConnectionsQuery = query(
                    userConnectionsRef,
                    where("status", "==", "Connected")
                  );
                  const userConnectionsSnapshot = await getDocs(
                    userConnectionsQuery
                  );

                  let isIndirect = false;
                  for (const conn of userConnectionsSnapshot.docs) {
                    const indirectConnectionRef = doc(
                      firestore,
                      `users/${conn.id}/connections/${postData.userId}`
                    );
                    const indirectConnectionDoc = await getDoc(
                      indirectConnectionRef
                    );
                    if (
                      indirectConnectionDoc.exists() &&
                      indirectConnectionDoc.data().status === "Connected"
                    ) {
                      isIndirect = true;
                      break;
                    }
                  }
                  connectionType = isIndirect ? "Indirect" : "Extended";
                }
              }

              updatedUserData = {
                id: postData.userId,
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || defaultProfileImage,
                bio: userData.bio || "",
                link: userData.link || null,
                linkText: userData.linkText || null,
                isStartup: false,
                connectionType,
                urlSlug: userData.urlSlug,
              };
            }

            return {
              id: postData.postId,
              ...fullPostSnap.data(),
              _path: {
                isStartup: postData.isStartup,
                userId: postData.userId,
                startupId: postData.startupId,
              },
              user: updatedUserData,
              communityReactions: communityPostData?.communityReactions || {},
              comments: communityPostData?.comments || {},
              commentCount: commentsSnapshot.size,
              communityPostId: postDoc.id,
            };
          } catch (error) {
            return null;
          }
        })
      );

      const validPosts = fullPosts.filter(Boolean);
      setCommunityPosts(validPosts);
    } catch (error) {}
  };

  const getSortedPosts = useCallback(() => {
    if (!highlightedPostId || !communityPosts.length) return communityPosts;

    // Find the highlighted post
    const highlightedPost = communityPosts.find(
      (post) =>
        post.id === highlightedPostId ||
        post.communityPostId === highlightedPostId
    );
    if (!highlightedPost) return communityPosts;

    // Remove the highlighted post from the array and put it at the beginning
    const otherPosts = communityPosts.filter(
      (post) =>
        post.id !== highlightedPostId &&
        post.communityPostId !== highlightedPostId
    );
    return [highlightedPost, ...otherPosts];
  }, [highlightedPostId, communityPosts]);

  useEffect(() => {
    if (shouldScrollToPost && highlightedPostId) {
      // Use requestAnimationFrame to ensure DOM is ready
      const scrollToPost = () => {
        const postElement = postRefs.current[highlightedPostId];
        if (!postElement) {
          // If element isn't ready yet, try again in next frame
          requestAnimationFrame(scrollToPost);
          return;
        }

        // Calculate scroll position
        const headerHeight = 0; // Adjust based on your header height
        const windowHeight = window.innerHeight;
        const postHeight = postElement.offsetHeight;
        const postTop = postElement.offsetTop;

        // Calculate ideal scroll position to center post
        const scrollPosition = postTop - (windowHeight - postHeight) / 2;

        // Scroll with smooth behavior
        window.scrollTo({
          top: Math.max(0, scrollPosition - headerHeight),
          behavior: "smooth",
        });

        // Add highlight animation
        postElement.classList.add(classes.highlightedPost);
        setTimeout(() => {
          postElement.classList.remove(classes.highlightedPost);
        }, 2000);

        setShouldScrollToPost(false);
      };

      // Start the scrolling process after a short delay to ensure content is rendered
      const timer = setTimeout(() => {
        requestAnimationFrame(scrollToPost);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [shouldScrollToPost, highlightedPostId]);

  useEffect(() => {
    if (communityData?.id) {
      fetchCommunityPosts().then(() => {});
    }
  }, [communityData?.id]);

  const hasInteractions = (post) => {
    if (!post) return false;
    const hasReactions =
      post.communityReactions &&
      Object.keys(post.communityReactions).length > 0;
    const hasComments = post.commentCount && post.commentCount > 0;
    const hasReposts = post.reposts && Object.keys(post.reposts).length > 0;
    return hasReactions || hasComments || hasReposts;
  };

  const toggleDropdown = (postId) => {
    setShowDropdown((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  useEffect(() => {
    if (communityData?.id) {
      fetchCommunityPosts();
    }
  }, [communityData?.id]);

  // Update the PostModal close handler
  const handleClosePostModal = useCallback(
    (success, newPostId, isUploading, progress) => {
      setShowPostModal(false);
      setIsPostModalOpen(false);

      if (success && newPostId) {
        if (isUploading) {
          updateUploadProgress(newPostId, progress, "uploading");
        } else {
          removeUploadingPost(newPostId);
          fetchCommunityPosts();
        }
      }

      setTimeout(() => {
        window.scrollTo(0, postModalScrollPosition);
      }, 0);
    },
    [
      updateUploadProgress,
      removeUploadingPost,
      fetchCommunityPosts,
      postModalScrollPosition,
    ]
  );

  const handleCancelUpload = useCallback(
    async (postId) => {
      cancelUpload(postId);
      updateUploadProgress(postId, 0, "cancelling");

      try {
        // Delete from Firestore - need to handle both user/startup paths
        let postRef;
        if (selectedStartup) {
          postRef = doc(
            firestore,
            `users/${currentUser?.uid}/startups/${selectedStartup.id}/communityPosts/${postId}`
          );
        } else {
          postRef = doc(
            firestore,
            `users/${currentUser?.uid}/communityPosts/${postId}`
          );
        }
        await deleteDoc(postRef);

        // Also delete from community's posts collection
        if (communityData?.id && communityData?.ownerId) {
          const communityPostRef = doc(
            firestore,
            `users/${communityData.ownerId}/communities/${communityData.id}/posts/${postId}`
          );
          await deleteDoc(communityPostRef);
        }

        // Delete from Storage - note the communityContent path
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `communityContent/${communityData.id}/${currentUser?.uid}/${postId}`
        );

        try {
          // Delete all files in the directory
          const items = await listAll(storageRef);
          await Promise.all(
            items.items.map((itemRef) => deleteObject(itemRef))
          );
        } catch (error) {
          // Handle case where directory might not exist yet
        }

        // setCancelMessage("Post cancelled.");
      } catch (error) {
      } finally {
        removeUploadingPost(postId);
      }
    },
    [
      currentUser,
      selectedStartup,
      communityData?.id,
      communityData?.ownerId,
      cancelUpload,
      updateUploadProgress,
      removeUploadingPost,
    ]
  );

  const handlePostComplete = useCallback(
    (postId) => {
      removeUploadingPost(postId);
      fetchCommunityPosts(); // Your existing fetch function
    },
    [removeUploadingPost, fetchCommunityPosts]
  );

  const handleEditTextareaChange = (e) => {
    const textarea = e.target;
    setEditCommentText(textarea.value);
    adjustTextareaHeight(textarea);
  };

  const handleEditTextareaFocus = (e) => {
    const textarea = e.target;
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const findPostById = useCallback(
    (postId) => {
      const post = communityPosts.find((p) => p.id === postId);

      if (!post) return null;

      // Return post with the structure needed for commenting
      return {
        id: post.id,
        user: {
          id: post.userId,
          ...post.user,
          startupOwnerId: post.user?.startupOwnerId || currentUser?.uid,
          startupId: post._path?.startupId,
          isStartup: post._path?.isStartup || false,
        },
        ...post,
        _path: {
          isStartup: post._path?.isStartup || false,
          userId: post.userId,
          startupId: post._path?.startupId,
          communityId: post.communityData.id,
          communityOwnerId: post.communityData.ownerId,
        },
      };
    },
    [communityPosts, currentUser]
  );

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const renderEditButtons = (postId, commentId, isReply = false) => (
    <div className={classes.editCommentButtons}>
      <div
        className={`${classes.saveChangesButton} ${
          editCommentText.trim() === "" ? classes.disabledButton : ""
        }`}
        onClick={() =>
          editCommentText.trim() !== "" &&
          handleSaveCommentChanges(postId, commentId, isReply)
        }
      >
        Save changes
      </div>
      <div className={classes.postDot}> • </div>
      <div
        className={classes.cancelEditButton}
        onClick={() => handleCancelEdit()}
      >
        Cancel
      </div>
    </div>
  );

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditCommentText("");
  };

  // Add this useEffect to handle textarea height adjustments
  useEffect(() => {
    if (editingCommentId && editTextareaRef.current) {
      adjustTextareaHeight(editTextareaRef.current);
    }
  }, [editingCommentId]);

  const renderReplyText = (postId, reply) => {
    if (!reply || typeof reply.text !== "string") return null;

    if (editingCommentId === reply.id) {
      return (
        <EditInput
          postId={postId}
          commentId={reply.id}
          initialText={stripHtmlTags(reply.text)}
          isReply={true}
          onSave={handleSaveCommentChanges}
          onCancel={handleCancelEdit}
        />
      );
    }

    const lines = reply.text.split("\n");
    const isLongReply = lines.length > 3 || reply.text.length > 125;
    const displayText =
      isLongReply && !expandedReplies[postId]?.[reply.id]
        ? reply.text.slice(0, 125)
        : reply.text;

    return (
      <div className={classes.replyTextContainer}>
        <div
          className={`${classes.replyText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongReply && !expandedReplies[postId]?.[reply.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleReplyExpansion(postId, reply.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const SuccessPostPopup = ({ message, onClose, postId }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
          <Link to={`/post/${postId}`} className={classes.viewPostLink}>
            View post
          </Link>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.repostCloseIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const CancelPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.repostCloseIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.repostCloseIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  // Add this function to handle edit comment
  const handleEditComment = (commentId, isReply = false) => {
    const commentToEdit = Object.values(comments)
      .flat()
      .find((c) => c.id === commentId);

    if (commentToEdit && commentToEdit.postId) {
      const post = communityPosts.find((p) => p.id === commentToEdit.postId);
      if (!post) {
        return;
      }

      setEditingCommentId(commentId);
      const strippedText = stripHtmlTags(commentToEdit.text);
      setEditCommentText(strippedText);
      setOpenCommentDropdown(null);

      setTimeout(() => {
        if (editTextareaRef.current) {
          editTextareaRef.current.focus();
          adjustTextareaHeight(editTextareaRef.current);
          const length = editTextareaRef.current.value.length;
          editTextareaRef.current.setSelectionRange(length, length);
        }
      }, 0);
    } else {
    }
  };

  // Add this function to handle saving edited comments
  const handleSaveCommentChanges = async (postId, commentId, updatedText) => {
    if (!currentUser) return;

    const trimmedText =
      typeof updatedText === "string"
        ? updatedText.trim()
        : editCommentText.trim();
    if (trimmedText === "") {
      setErrorPopup({
        message: "Comment cannot be empty.",
        isError: true,
      });
      return;
    }

    try {
      const post = communityPosts.find((p) => p.id === postId);
      if (!post) throw new Error("Post not found");

      // Use the community's path structure
      const commentRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${post.communityPostId}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();

      let canEdit = false;
      if (commentData.isStartup) {
        canEdit = commentData.startupOwnerId === currentUser.uid;
      } else {
        canEdit = commentData.userId === currentUser.uid;
      }

      if (!canEdit) {
        throw new Error("Not authorized to edit this comment");
      }

      const processedText = convertUrlsToLinks(trimmedText);
      await updateDoc(commentRef, {
        text: processedText,
        edited: true,
        updatedAt: serverTimestamp(),
      });

      // Update local state
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              text: processedText,
              edited: true,
            };
          }
          return comment;
        }),
      }));

      setEditingCommentId(null);
      setEditCommentText("");
    } catch (error) {
      setErrorPopup({
        message:
          error.message === "Comment not found"
            ? "This comment has been deleted."
            : error.message === "Not authorized to edit this comment"
            ? "You don't have permission to edit this comment."
            : "An error occurred while updating the comment.",
        isError: true,
      });
    }
  };

  const convertUrlsToLinks = (text) => {
    const urlRegex =
      /(\b(?:https?:\/\/)?(?:www\.)?[\w-]+(?:\.[\w-]+)+\b(?:\/[\w-./?%&=]*)?)/gi;
    return text.replace(urlRegex, (url) => {
      const fullUrl = url.startsWith("http") ? url : `https://${url}`;
      return `<a href="${fullUrl}" target="_blank" rel="noopener noreferrer" style="color: orangered; font-weight: 900;">${url}</a>`;
    });
  };

  const isReplyButtonDisabled = (postId, replyId) => {
    const replyText = replyComments[postId]?.[replyId];
    return !replyText || !replyText.trim();
  };

  const handleViewMoreReplies = (rootCommentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [rootCommentId]: (prev[rootCommentId] || 0) + 3,
    }));
  };

  const EditInput = React.memo(
    ({ postId, commentId, initialText, isReply, onSave, onCancel }) => {
      const [inputValue, setInputValue] = useState(initialText);
      const [originalText] = useState(initialText);
      const textareaRef = useRef(null);

      useEffect(() => {
        if (textareaRef.current) {
          textareaRef.current.focus();
          const length = textareaRef.current.value.length;
          textareaRef.current.setSelectionRange(length, length);
          textareaRef.current.style.height = "auto";
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
      }, []);

      const handleChange = (e) => {
        const textarea = e.target;
        setInputValue(textarea.value);
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      };

      const isTextChanged = inputValue.trim() !== originalText.trim();
      const canSave = inputValue.trim() && isTextChanged;

      const handleSave = () => {
        if (canSave) {
          onSave(postId, commentId, inputValue);
        }
      };

      return (
        <div className={classes.editCommentSection}>
          <textarea
            ref={textareaRef}
            value={inputValue}
            onChange={handleChange}
            className={isReply ? classes.editReplyInput : classes.replyInput}
          />
          <div className={classes.editCommentButtons}>
            <div
              className={`${classes.saveChangesButton} ${
                !canSave ? classes.disabledButton : ""
              }`}
              onClick={handleSave}
            >
              Save changes
            </div>
            <div className={classes.dot}> • </div>
            <div className={classes.cancelEditButton} onClick={onCancel}>
              Cancel
            </div>
          </div>
        </div>
      );
    }
  );

  const toggleReplyExpansion = (postId, replyId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [replyId]: !prev[postId]?.[replyId],
      },
    }));
  };

  // Add this useEffect to detect iOS devices
  useEffect(() => {
    const isIOSDevice =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(isIOSDevice);
  }, []);

  const CommentInputSection = ({
    currentUser,
    newComment,
    onCommentChange,
    onSubmit,
    commentInputRef,
  }) => {
    return (
      <div className={classes.commentInputSection}>
        <div className={classes.commentInputWrapper}>
          <div>
            <img
              src={currentUser?.profileImage || defaultProfileImage}
              alt="Your profile"
              className={classes.commentUserImage}
            />
          </div>
          <textarea
            ref={commentInputRef}
            value={newComment}
            onChange={onCommentChange}
            placeholder="Add a comment..."
            className={classes.commentInput}
          />
        </div>
        <div
          onClick={onSubmit}
          className={`${classes.addCommentButton} ${
            !newComment?.trim() ? classes.disabled : ""
          }`}
        >
          Comment
        </div>
      </div>
    );
  };

  const ReplySection = ({
    comment,
    currentUser,
    replyComments,
    onReplyChange,
    onSubmit,
    replyInputRef,
  }) => {
    return (
      <div className={classes.replyCommentInputSection}>
        <div className={classes.replyInputWrapper}>
          <div>
            <img
              src={currentUser?.profileImage || defaultProfileImage}
              alt="Your profile"
              className={classes.replyUserImage}
            />
          </div>
          <textarea
            ref={(el) => {
              if (replyInputRef && replyInputRef.current) {
                replyInputRef.current[comment.id] = el;
              }
            }}
            value={replyComments[comment.id] || ""}
            onChange={(e) => onReplyChange(e, comment.id)}
            placeholder="Add a reply..."
            className={classes.replyInput}
          />
        </div>
        <div
          onClick={() => onSubmit(comment.id)}
          className={`${classes.addReplyButton} ${
            !replyComments[comment.id]?.trim() ? classes.disabled : ""
          }`}
        >
          Reply
        </div>
      </div>
    );
  };

  const toggleCommentExpansion = (commentId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const handleEditPost = (post) => {
    const postToEdit = {
      ...post,
      communityPostId: post.communityPostId,
      _path: {
        userId: post.user.startupOwnerId || post.user.id,
        startupId: post.user.startupId,
        isStartup: post.user.isStartup,
        communityId: communityData.id,
        communityOwnerId: communityData.ownerId,
      },
      user: {
        ...post.user,
        startupOwnerId: post.user.startupOwnerId || post.user.id,
      },
    };

    setEditingPost(postToEdit);
    setShowEditModal(true);
    setPostModalScrollPosition(window.scrollY);
    setIsPostModalOpen(true);
  };

  const handleEditComplete = async (updatedText, isEdited) => {
    try {
      if (!editingPost) return;

      // Determine the correct path based on whether it's a startup post
      let postRef;
      if (editingPost.user.isStartup) {
        postRef = doc(
          firestore,
          `users/${editingPost.user.startupOwnerId}/startups/${editingPost.user.startupId}/communityPosts/${editingPost.id}`
        );
      } else {
        postRef = doc(
          firestore,
          `users/${editingPost.user.id}/communityPosts/${editingPost.id}`
        );
      }

      // Also update the post in the community's posts collection
      const communityPostRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${editingPost.communityPostId}`
      );

      const updateData = {
        text: updatedText,
        isEdited: isEdited,
        latestActivity: serverTimestamp(),
      };

      // Update both the user's/startup's post and the community post
      await Promise.all([
        updateDoc(postRef, updateData),
        updateDoc(communityPostRef, {
          lastUpdated: serverTimestamp(),
        }),
      ]);

      // Update local state
      setCommunityPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === editingPost.id
            ? { ...post, text: updatedText, isEdited: isEdited }
            : post
        )
      );

      setShowEditModal(false);
      setEditingPost(null);
      setIsPostModalOpen(false);
    } catch (error) {
      setErrorPopup({
        message: "Error updating post.",
        isError: true,
      });
    }
  };

  const handleDeletePost = async (postId) => {
    // Initialize sets to track affected users and startups for notification cleanup
    const affectedUserIds = new Set();
    const affectedStartupIds = new Set();

    // Show deletion in progress message
    setDeletingPostMessage({
      text: "Deleting post...",
      isLoading: true,
    });

    // Basic validation checks
    if (!currentUser || !communityData?.id || !communityData?.ownerId) {
      return;
    }

    // Check if user has moderator permissions
    const isModerator = moderators.some((mod) => mod.id === currentUser.uid);
    const isCommunityOwner = currentUser.uid === communityData.ownerId;

    try {
      // Find the target post in our local state
      const targetPost = communityPosts.find((p) => p.id === postId);
      if (!targetPost) {
        setErrorPopup({
          message: "This post has been deleted or is no longer available.",
          isError: true,
        });
        return;
      }

      // Check if user has permission to delete
      const isPostOwner = targetPost.user.isStartup
        ? targetPost.user.startupOwnerId === currentUser.uid
        : targetPost.user.id === currentUser.uid;

      const canDelete = isPostOwner || isCommunityOwner || isModerator;

      if (!canDelete) {
        setErrorPopup({
          message: "You don't have permission to delete this post.",
          isError: true,
        });
        return;
      }

      // Get reference to the post in the community's posts collection
      const communityPostRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${targetPost.communityPostId}`
      );

      const communityPostSnap = await getDoc(communityPostRef);

      if (!communityPostSnap.exists()) {
        setErrorPopup({
          message: "This post has already been deleted.",
          isError: true,
        });
        return;
      }

      const postData = communityPostSnap.data();

      let batch = writeBatch(firestore);
      let operationCount = 0;

      // Function to commit batch when it gets too large
      const commitBatchIfNeeded = async () => {
        if (operationCount >= 450) {
          await batch.commit();
          batch = writeBatch(firestore);
          operationCount = 0;
        }
      };

      // Delete all comments
      const commentsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${targetPost.communityPostId}/comments`
      );
      const commentsSnapshot = await getDocs(commentsRef);

      for (const commentDoc of commentsSnapshot.docs) {
        const commentData = commentDoc.data();

        // Track affected users/startups for later notification cleanup
        if (commentData.userId.startsWith("startup_")) {
          const startupId = commentData.userId.replace("startup_", "");
          affectedStartupIds.add(startupId);
          affectedUserIds.add(commentData.startupOwnerId);
        } else {
          affectedUserIds.add(commentData.userId);
        }

        batch.delete(commentDoc.ref);
        operationCount++;
        await commitBatchIfNeeded();
      }

      // Delete all notifications related to this post
      const usersSnapshot = await getDocs(collection(firestore, "users"));
      for (const userDoc of usersSnapshot.docs) {
        const notificationsRef = collection(
          firestore,
          `users/${userDoc.id}/notifications`
        );
        const postNotificationsQuery = query(
          notificationsRef,
          where("postId", "==", postId)
        );
        const notificationsSnapshot = await getDocs(postNotificationsQuery);

        notificationsSnapshot.docs.forEach((doc) => {
          batch.delete(doc.ref);
          operationCount++;
        });
        await commitBatchIfNeeded();
      }

      // Storage cleanup section

      try {
        const storage = getStorage();

        // Always construct the storage path, regardless of content type
        const storageBasePath = targetPost.user.isStartup
          ? `communityContent/${communityData.id}/${targetPost.user.startupOwnerId}/${targetPost.user.startupId}/${targetPost.id}`
          : `communityContent/${communityData.id}/${targetPost.user.id}/${targetPost.id}`;

        // Move folder deletion OUTSIDE the postData.content check

        try {
          const folderRef = ref(storage, storageBasePath);
          const folderContents = await listAll(folderRef);

          // Delete all direct files
          for (const item of folderContents.items) {
            try {
              await deleteObject(item);
            } catch (error) {}
          }

          // Delete files in subfolders
          for (const prefix of folderContents.prefixes) {
            try {
              const subFolderContents = await listAll(prefix);

              for (const item of subFolderContents.items) {
                try {
                  await deleteObject(item);
                } catch (error) {}
              }
            } catch (error) {}
          }
        } catch (error) {}

        // Handle specific content if it exists - this is now secondary to folder deletion
        if (postData.content) {
          // Handle content deletion based on type
          if (postData.content.type === "image") {
            if (Array.isArray(postData.content.url)) {
              for (const imageUrl of postData.content.url) {
                try {
                  const imageRef = ref(storage, imageUrl);
                  await deleteObject(imageRef);
                } catch (error) {}
              }
            } else {
              try {
                const fileRef = ref(storage, postData.content.url);
                await deleteObject(fileRef);
              } catch (error) {}
            }
          } else if (["video", "pdf"].includes(postData.content.type)) {
            try {
              const fileRef = ref(storage, postData.content.url);
              await deleteObject(fileRef);

              if (
                postData.content.type === "video" &&
                postData.content.thumbnail
              ) {
                const thumbnailRef = ref(storage, postData.content.thumbnail);
                await deleteObject(thumbnailRef);
              }
            } catch (error) {}
          }

          // Always attempt to delete the entire folder structure

          try {
            const folderRef = ref(storage, storageBasePath);
            const folderContents = await listAll(folderRef);

            // Delete all direct files
            for (const item of folderContents.items) {
              try {
                await deleteObject(item);
              } catch (error) {}
            }

            // Delete files in subfolders
            for (const prefix of folderContents.prefixes) {
              try {
                const subFolderContents = await listAll(prefix);

                for (const item of subFolderContents.items) {
                  try {
                    await deleteObject(item);
                  } catch (error) {}
                }
              } catch (error) {}
            }
          } catch (error) {}
        }
      } catch (error) {}

      // Delete the post documents
      batch.delete(communityPostRef);

      if (targetPost.user.isStartup) {
        const originalPostRef = doc(
          firestore,
          `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}/communityPosts/${targetPost.id}`
        );
        batch.delete(originalPostRef);
      } else {
        const originalPostRef = doc(
          firestore,
          `users/${targetPost.user.id}/communityPosts/${targetPost.id}`
        );
        batch.delete(originalPostRef);
      }

      operationCount += 2;
      await batch.commit();

      // Update local state
      setCommunityPosts((prevPosts) =>
        prevPosts.filter((p) => p.id !== postId)
      );

      setComments((prevComments) => {
        const newComments = { ...prevComments };
        delete newComments[postId];
        return newComments;
      });

      setShowDropdown((prev) => {
        const newState = { ...prev };
        delete newState[postId];
        return newState;
      });

      setDeletingPostMessage({
        text: "Post deleted",
        isLoading: false,
      });
    } catch (error) {
      setErrorPopup({
        message: "Failed to delete post.",
        isError: true,
      });
    } finally {
      // If there was an error, clear the "Deleting..." message
      if (deletingPostMessage?.isLoading) {
        setDeletingPostMessage(null);
      }
    }
  };

  const handleReportPost = (postId) => {
    setShowDropdown((prev) => ({ ...prev, [postId]: false }));
    setReportMessage({
      text: "Report submitted.",
    });
  };

  const handleReportComment = (commentId) => {
    setOpenCommentDropdown(null);
    setReportMessage({
      text: "Report submitted.",
    });
  };

  const handleReactionsClick = (postId, communityReactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions: communityReactions });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  const handleCommentsCountClick = (postId) => {
    toggleCommentInput(postId);
    setVisibleComments((prev) => ({
      ...prev,
      [postId]: 10,
    }));
  };

  const handleRepostsClick = (postId, reposts) => {
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({ postId, reposts });
    setShowRepostsModal(true);
    setIsRepostsModalOpen(true);
  };

  const [currentUserProfileImage, setCurrentUserProfileImage] = useState(
    currentUser?.profileImage || defaultImage
  );

  const handleEmojiSelect = useCallback(
    async (emoji, postId) => {
      if (!currentUser) return;

      // Store current scroll position before any async operations
      const currentScrollPosition = window.scrollY;

      try {
        const post = findPostById(postId);
        if (!post) {
          throw new Error("Post not found");
        }

        const postRef = doc(
          firestore,
          `users/${communityData.ownerId}/communities/${communityData.id}/posts/${post.communityPostId}`
        );

        const reactorId = selectedStartup
          ? `startup_${selectedStartup.id}`
          : currentUser.uid;

        // Only update the post's reactions
        let updatedReactions = { ...(post.communityReactions || {}) };
        const isRemovingReaction = updatedReactions[reactorId] === emoji;

        if (isRemovingReaction) {
          // Remove reaction
          delete updatedReactions[reactorId];
          await updateDoc(postRef, {
            [`communityReactions.${reactorId}`]: deleteField(),
          });

          const notificationRef = doc(
            firestore,
            `users/${post.user.startupOwnerId || post.user.id}/notifications`,
            `community_reaction_${postId}_${reactorId}`
          );
          await deleteDoc(notificationRef);
        } else {
          // Add reaction
          updatedReactions[reactorId] = emoji;
          await updateDoc(postRef, {
            [`communityReactions.${reactorId}`]: emoji,
          });

          const shouldNotify = (() => {
            // If reacting as a startup
            if (selectedStartup) {
              // If target is a startup post
              if (post.user.isStartup) {
                return post.user.startupOwnerId !== currentUser.uid;
              }
              // If target is a regular user post
              return post.user.id !== currentUser.uid;
            }
            // If reacting as regular user
            else {
              // If target is a startup post
              if (post.user.isStartup) {
                return post.user.startupOwnerId !== currentUser.uid;
              }
              // If target is a regular user post
              return post.user.id !== currentUser.uid;
            }
          })();

          if (shouldNotify) {
            const notificationRef = doc(
              firestore,
              `users/${post.user.startupOwnerId || post.user.id}/notifications`,
              `community_reaction_${postId}_${reactorId}`
            );

            const notificationData = {
              type: "reaction",
              postId: postId,
              communityId: communityData.id,
              communityOwnerId: communityData.ownerId,
              communityName: communityData.communityName,
              reactorId: reactorId,
              reactorName: selectedStartup
                ? selectedStartup.startupName
                : `${currentUser.firstName} ${currentUser.lastName}`,
              reactorImage: selectedStartup
                ? selectedStartup.startupImage || defaultStartupImage
                : currentUserProfileImage || defaultImage,
              emoji: emoji,
              createdAt: serverTimestamp(),
              isNew: true,
              postPreview: {
                text: post.text || "",
                mediaType: post.content?.type || null,
                mediaUrl: post.content?.url || null,
                fileName: post.content?.fileName || null,
                ...(post.content?.type === "video" && {
                  thumbnail: post.content.thumbnail,
                }),
              },
              originalPosterId: post.user.id || post.userId,
              userId: post.user.id || post.userId,
              reactionCount: Object.keys(updatedReactions).length,
              commentCount: post.commentCount || 0,
              ...(selectedStartup && {
                isStartup: true,
                startupId: selectedStartup.id,
                startupOwnerId: currentUser.uid,
                startupImage:
                  selectedStartup.startupImage || defaultStartupImage,
              }),
            };

            await setDoc(notificationRef, notificationData);
          }
        }

        // Update local state
        setCommunityPosts((prevPosts) =>
          prevPosts.map((p) =>
            p.id === postId ? { ...p, communityReactions: updatedReactions } : p
          )
        );

        // Close emoji picker if it's open
        if (activeEmojiPicker) {
          setActiveEmojiPicker(null);
        }

        // Restore scroll position after all operations
        requestAnimationFrame(() => {
          window.scrollTo(0, currentScrollPosition);
        });
      } catch (error) {
        if (error.message === "Post not found") {
          setErrorPopup({
            message: "This post has been deleted or is no longer available.",
            isError: true,
          });
        } else {
          setErrorPopup({
            message: "An error occurred while updating the reaction.",
            isError: true,
          });
        }
        // Restore scroll position even if there's an error
        requestAnimationFrame(() => {
          window.scrollTo(0, currentScrollPosition);
        });
      }
    },
    [
      currentUser,
      selectedStartup,
      communityData,
      findPostById,
      activeEmojiPicker,
      currentUserProfileImage,
    ]
  );

  // Update handleReactionClick to receive postId and access post data correctly
  const handleReactionClick = async (postId) => {
    const post = findPostById(postId);
    if (!post) return;

    if (post.communityReactions?.[currentUser?.uid]) {
      handleEmojiSelect(post.communityReactions[currentUser.uid], postId);
    } else {
      setEmojiPickerScrollPosition(window.scrollY);
      setActiveEmojiPicker(
        activeEmojiPicker === `post_${postId}` ? null : `post_${postId}`
      );
    }
  };

  const handleRepost = async (postId) => {
    if (!currentUser) return;

    try {
      const post = findPostById(postId);
      if (!post) {
        throw new Error("Post not found");
      }

      const postRef = getPostRef(post);
      const reposterId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;
      const path = selectedStartup
        ? `users/${currentUser.uid}/startups/${selectedStartup.id}`
        : `users/${currentUser.uid}`;
      const repostedPostRef = doc(firestore, `${path}/reposts/${postId}`);

      let updatedReposts = { ...(post.reposts || {}) };
      const now = serverTimestamp();

      if (updatedReposts[reposterId]) {
        await updateDoc(postRef, {
          [`reposts.${reposterId}`]: deleteField(),
        });
        delete updatedReposts[reposterId];
        await deleteDoc(repostedPostRef);

        setRepostMessage({
          text: "Repost removed.",
          postId,
          isRepost: false,
        });
      } else {
        await updateDoc(postRef, {
          [`reposts.${reposterId}`]: now,
        });
        updatedReposts[reposterId] = now;

        await setDoc(repostedPostRef, {
          originalPosterId: post.user.id,
          postId: postId,
          repostedAt: now,
        });

        setRepostMessage({
          text: "Repost successful.",
          postId,
          isRepost: true,
        });
      }

      setCommunityPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId ? { ...p, reposts: updatedReposts } : p
        )
      );
    } catch (error) {
      setErrorPopup({
        message: "An error occurred while updating the repost.",
        isError: true,
      });
    }
  };

  const fetchCommunityMembershipStatus = async () => {
    if (!currentUser || !communityData?.id) return;
    try {
      const membershipRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/members/${currentUser.uid}`
      );
      const membershipDoc = await getDoc(membershipRef);
      setIsCommunityMember(membershipDoc.exists());
    } catch (error) {}
  };

  useEffect(() => {
    fetchCommunityMembershipStatus();
  }, [currentUser, communityData?.id]);

  const renderPostText = (post) => {
    if (!post || !post.text) return null;

    const lines = post.text.split("\n");
    const isLongPost = lines.length > 3 || post.text.length > 125;
    const hasNoInteractions =
      !post.content &&
      (!post.reactions || Object.keys(post.reactions).length === 0) &&
      (!post.reposts || Object.keys(post.reposts).length === 0) &&
      (!post.commentCount || post.commentCount === 0);

    const hasPdfAttachment = post.content && post.content.type === "pdf";

    const displayText = expandedPosts[post.id]
      ? post.text
      : lines.slice(0, 3).join("\n").slice(0, 125);

    // Convert URLs to links before displaying
    const textWithLinks = convertUrlsToLinks(displayText);

    return (
      <div
        className={`${classes.postTextContainer} ${
          hasNoInteractions || hasPdfAttachment
            ? classes.postTextContainerNoInteractions
            : ""
        }`}
      >
        <div
          className={`${classes.postText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: textWithLinks }}
          onClick={handleLinkClick}
        />
        {!expandedPosts[post.id] && isLongPost && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              togglePostExpansion(post.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const video = entry.target;
          video.play().catch((error) => console.log("Autoplay was prevented"));
        } else {
          entry.target.pause();
        }
      });
    }, options);

    // Cleanup previous observations
    const cleanup = () => {
      Object.values(videoRefs.current).forEach((video) => {
        if (video) {
          observer.unobserve(video);
        }
      });
    };

    // Observe all current video elements
    Object.values(videoRefs.current).forEach((video) => {
      if (video) {
        observer.observe(video);
      }
    });

    // Cleanup on unmount or when posts/visiblePostsCount changes
    return cleanup;
  }, [communityPosts, visiblePostsCount]); // Add visiblePostsCount as a dependency

  const VideoPlayer = memo(({ content, classes }) => {
    const [isMuted, setIsMuted] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [timeLeft, setTimeLeft] = useState(0);
    const videoRef = useRef(null);
    const observerRef = useRef(null);

    const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    };

    useEffect(() => {
      const video = videoRef.current;
      if (!video) return;

      const handleTimeUpdate = () => {
        setTimeLeft(video.duration - video.currentTime);
      };

      const handleLoadedMetadata = () => {
        setDuration(video.duration);
        setTimeLeft(video.duration);
      };

      const handlePlay = () => setIsPlaying(true);
      const handlePause = () => setIsPlaying(false);

      const options = {
        root: null,
        rootMargin: "50px",
        threshold: 0.5,
      };

      observerRef.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            video.play().catch(() => {
              video.addEventListener(
                "click",
                () => {
                  video.play().catch(() => {});
                },
                { once: true }
              );
            });
            setIsPlaying(true);
          } else {
            video.pause();
            setIsPlaying(false);
          }
        });
      }, options);

      video.addEventListener("timeupdate", handleTimeUpdate);
      video.addEventListener("loadedmetadata", handleLoadedMetadata);
      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);

      observerRef.current.observe(video);

      return () => {
        video.removeEventListener("timeupdate", handleTimeUpdate);
        video.removeEventListener("loadedmetadata", handleLoadedMetadata);
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      };
    }, []);

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.muted = isMuted;
      }
    }, [isMuted]);

    const handleVolumeToggle = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsMuted(!isMuted);
    };

    const handlePlayPause = (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (videoRef.current) {
        if (videoRef.current.paused) {
          videoRef.current.play().catch(() => {});
        } else {
          videoRef.current.pause();
        }
      }
    };

    return (
      <div className={classes.videoContainer}>
        <div className={classes.videoTimeCounter}>
          <div className={classes.videoTimeCounterInner}>
            {formatTime(timeLeft)}
          </div>
        </div>
        <video
          ref={videoRef}
          className={classes.postContentVideo}
          playsInline
          muted={isMuted}
          loop
          controls={false}
          preload="auto"
          poster={content.thumbnail || ""}
        >
          <source src={content.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className={classes.videoControlsLeft}>
          <button
            type="button"
            className={classes.volumeControl}
            onClick={handlePlayPause}
          >
            {isPlaying ? (
              <svg width="24" height="24" fill="white" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M8 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H8Zm7 0a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1Z"
                />
              </svg>
            ) : (
              <svg width="24" height="24" fill="white" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M8.6 5.2A1 1 0 0 0 7 6v12a1 1 0 0 0 1.6.8l8-6a1 1 0 0 0 0-1.6l-8-6Z"
                />
              </svg>
            )}
          </button>
        </div>
        <div className={classes.videoControlsRight}>
          <button
            type="button"
            className={classes.volumeControl}
            onClick={handleVolumeToggle}
          >
            {!isMuted ? (
              <svg viewBox="0 0 24 24" fill="white" width="24" height="24">
                <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
              </svg>
            ) : (
              <svg viewBox="0 0 24 24" fill="white" width="24" height="24">
                <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
              </svg>
            )}
          </button>
        </div>
      </div>
    );
  });

  const PDFPreview = React.memo(
    ({ content, hasInteractions, classes }) => {
      const getFileName = React.useCallback(() => {
        if (!content?.fileName) return "Unnamed PDF";
        const rawFileName = Array.isArray(content.fileName)
          ? content.fileName[0] || "Unnamed PDF"
          : content.fileName;
        return rawFileName.replace(/\.pdf$/i, "");
      }, [content?.fileName]);

      if (!content || !content.url) return null;

      return (
        <div className={classes.postContentWrapper}>
          <a
            href={content.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`${classes.pdfPreview} ${
              !hasInteractions ? classes.pdfPreviewNoInteractions : ""
            }`}
          >
            <div className={classes.pdfBox}>
              <div className={classes.fileContainer}>
                <div className={classes.fileAbout}>
                  <div className={classes.fileName}>{getFileName()}</div>
                </div>
                <div className={classes.open}>
                  Open PDF
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>
      );
    },
    (prevProps, nextProps) => {
      return (
        prevProps.content?.url === nextProps.content?.url &&
        prevProps.content?.fileName === nextProps.content?.fileName &&
        prevProps.hasInteractions === nextProps.hasInteractions
      );
    }
  );

  const CommentInput = React.memo(({ postId, onSubmit }) => {
    const [inputValue, setInputValue] = useState("");
    const textareaRef = useRef(null);

    const handleChange = (e) => {
      const textarea = e.target;
      setInputValue(textarea.value);
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleSubmit = () => {
      if (inputValue.trim()) {
        onSubmit(postId, inputValue);
        setInputValue("");
        if (textareaRef.current) {
          textareaRef.current.style.height = "auto";
        }
      }
    };

    // Add this handler for image clicks
    const handleImageClick = () => {
      if (selectedStartup) {
        // Navigate directly to startup profile using correct format
        navigate(`/startup/${selectedStartup.startupUrlSlug}`);
      } else if (currentUser?.urlSlug) {
        // Navigate directly to user profile using urlSlug
        navigate(`/${currentUser.urlSlug}`);
      }
    };

    return (
      <div className={classes.commentInputSection}>
        <div className={classes.commentInputWrapper}>
          <div>
            <img
              src={
                selectedStartup
                  ? selectedStartup.startupImage || defaultStartupImage
                  : currentUserProfileImage || defaultImage
              }
              alt={
                selectedStartup ? selectedStartup.startupName : "Your profile"
              }
              className={classes.commentUserImage}
              onClick={handleImageClick}
              style={{
                borderRadius: selectedStartup ? 0 : "50%",
                cursor: "pointer",
              }}
            />
          </div>
          <textarea
            ref={textareaRef}
            value={inputValue}
            onChange={handleChange}
            placeholder="Add a comment..."
            className={classes.commentInput}
          />
        </div>
        <div
          onClick={handleSubmit}
          className={`${classes.addCommentButton} ${
            !inputValue.trim() ? classes.disabled : ""
          }`}
        >
          Comment
        </div>
      </div>
    );
  });

  const ReplyInput = React.memo(({ postId, commentId, onSubmit }) => {
    const [inputValue, setInputValue] = useState("");
    const textareaRef = useRef(null);
    const containerRef = useRef(null);

    const handleImageClick = () => {
      if (selectedStartup) {
        // Navigate directly to startup profile using correct format
        navigate(`/startup/${selectedStartup.startupUrlSlug}`);
      } else if (currentUser?.urlSlug) {
        // Navigate directly to user profile using urlSlug
        navigate(`/${currentUser.urlSlug}`);
      }
    };

    useEffect(() => {
      if (textareaRef.current && containerRef.current) {
        containerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        textareaRef.current.focus();
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

        if (isIOS) {
          forceKeyboardOpen(textareaRef.current);
        } else {
          openMobileKeyboard(textareaRef.current);
        }
      }
    }, []);

    const handleChange = (e) => {
      const textarea = e.target;
      setInputValue(textarea.value);
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleSubmit = () => {
      if (inputValue.trim()) {
        onSubmit(postId, commentId, inputValue);
        setInputValue("");
        if (textareaRef.current) {
          textareaRef.current.style.height = "auto";
        }
      }
    };

    return (
      <div ref={containerRef} className={classes.replyCommentInputSection}>
        <div className={classes.replyInputWrapper}>
          <div>
            <img
              src={
                selectedStartup
                  ? selectedStartup.startupImage || defaultStartupImage
                  : currentUserProfileImage || profileImage
              }
              alt={
                selectedStartup ? selectedStartup.startupName : "Your profile"
              }
              className={classes.replyUserImage}
              onClick={handleImageClick}
              style={{
                borderRadius: selectedStartup ? 0 : "50%",
                cursor: "pointer",
              }}
            />
          </div>
          <textarea
            ref={textareaRef}
            value={inputValue}
            onChange={handleChange}
            placeholder="Add a reply..."
            className={classes.replyInput}
          />
        </div>
        <div
          onClick={handleSubmit}
          className={`${classes.addReplyButton} ${
            !inputValue.trim() ? classes.disabled : ""
          }`}
        >
          Reply
        </div>
      </div>
    );
  });

  const logCommentsState = (postId, message = "Current comments state") => {};

  const handleAddReply = useCallback(
    async (postId, commentId, replyText) => {
      if (!currentUser) return;

      try {
        // Call handleAddComment but DON'T update the state here - let handleAddComment do it
        const newReply = await handleAddComment(postId, replyText, commentId);

        // Clear reply input and state
        setReplyComments((prev) => ({
          ...prev,
          [commentId]: "",
        }));

        setActiveCommentId((prev) => ({
          ...prev,
          [postId]: null,
        }));
      } catch (error) {
        setErrorPopup({
          message: "An error occurred while adding the reply.",
          isError: true,
        });
      }
    },
    [currentUser, handleAddComment]
  );

  const renderPostContent = (post) => {
    if (!post?.content) return null;

    switch (post.content.type) {
      case "image":
        return (
          <div className={classes.imageCarousel}>
            {Array.isArray(post.content.url) ? (
              <div style={{ position: "relative" }}>
                {post.content.url.length > 1 && (
                  <div className={classes.imageCounter}>
                    <div className={classes.imageCounterInner}>
                      {`${(currentImageIndex[post.id] || 0) + 1}/${
                        post.content.url.length
                      }`}
                    </div>
                  </div>
                )}

                <img
                  key={currentImageIndex[post.id] || 0}
                  src={post.content.url[currentImageIndex[post.id] || 0]}
                  alt=""
                  className={classes.postContentImage}
                  loading="eager"
                  decoding="sync"
                />

                {/* Navigation buttons - only show if there are multiple images */}
                {post.content.url.length > 1 && (
                  <div className={classes.navigationButtons}>
                    <div
                      className={classes.navButton}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const currentIndex = currentImageIndex[post.id] || 0;
                        const totalImages = post.content.url.length;
                        const newIndex =
                          (currentIndex - 1 + totalImages) % totalImages;
                        setCurrentImageIndex((prev) => ({
                          ...prev,
                          [post.id]: newIndex,
                        }));
                      }}
                      aria-label="Previous image"
                    >
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m14 8-4 4 4 4"
                        />
                      </svg>
                    </div>
                    <div
                      className={classes.navButton}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const currentIndex = currentImageIndex[post.id] || 0;
                        const newIndex =
                          (currentIndex + 1) % post.content.url.length;
                        setCurrentImageIndex((prev) => ({
                          ...prev,
                          [post.id]: newIndex,
                        }));
                      }}
                      aria-label="Next image"
                    >
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m10 16 4-4-4-4"
                        />
                      </svg>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <img
                src={post.content.url}
                alt=""
                className={classes.postContentImage}
              />
            )}
          </div>
        );
      case "video":
        return <VideoPlayer content={post.content} classes={classes} />;
      case "pdf":
        return (
          <PDFPreview
            content={post.content}
            classes={classes}
            hasInteractions={hasInteractions(post)}
          />
        );
      default:
        return null;
    }
  };

  const togglePostExpansion = (postId) => {
    setExpandedPosts((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  const toggleVolume = (postId) => {
    setVideoVolumes((prev) => {
      const newVolumes = { ...prev };
      newVolumes[postId] = !prev[postId];
      return newVolumes;
    });

    const video = videoRefs.current[postId];
    if (video) {
      video.muted = !video.muted;
    }
  };

  const handleNavigate = async (
    userId,
    isStartup = false,
    startupUrlSlug = null
  ) => {
    if (!userId) return;

    // If it's a startup and we have the urlSlug, navigate directly
    if (isStartup && startupUrlSlug) {
      navigate(`/startup/${startupUrlSlug}`);
      return;
    }

    // If it's a startup ID without urlSlug
    if (userId.startsWith("startup_")) {
      const startupId = userId.replace("startup_", "");
      try {
        // We need to find the startup doc across all users to get the urlSlug
        const usersSnapshot = await getDocs(collection(firestore, "users"));
        for (const userDoc of usersSnapshot.docs) {
          const startupDoc = await getDoc(
            doc(firestore, `users/${userDoc.id}/startups/${startupId}`)
          );
          if (startupDoc.exists()) {
            const startupData = startupDoc.data();
            navigate(`/startup/${startupData.startupUrlSlug}`);
            return;
          }
        }
      } catch (error) {
        setErrorPopup({
          message: "Error loading startup profile.",
          isError: true,
        });
      }
      return;
    }

    // For regular users
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        navigate(`/${userData.urlSlug}`);
      } else {
        setErrorPopup({
          message: "User not found.",
          isError: true,
        });
      }
    } catch (error) {
      setErrorPopup({
        message: "Error loading user profile.",
        isError: true,
      });
    }
  };

  // Add new handlers for post interactions
  // In Community.js, modify the handleAddPost function
  const handleAddPost = () => {
    if (!currentUser) return;

    // Clear any existing timeouts for delete messages
    Object.values(deleteTimeouts).forEach(clearTimeout);
    setDeleteTimeouts({});

    // Set the scroll position before opening modal
    setPostModalScrollPosition(window.scrollY);
    setShowPostModal(true);
    setIsPostModalOpen(true);
  };

  const toggleCommentInput = useCallback(
    (postId) => {
      if (!postId) return;

      setShowComments((prev) => ({
        ...prev,
        [postId]: !prev[postId],
      }));

      // Initialize visible comments when opening
      setVisibleComments((prev) => ({
        ...prev,
        [postId]: 3,
      }));

      if (!comments[postId]) {
        setActiveCommentPostId(postId);
        fetchComments(postId);
      }
    },
    [comments, fetchComments]
  );

  const handleCommentInputChange = (e, postId) => {
    const textarea = e.target;
    setNewComment((prev) => ({
      ...prev,
      [postId]: textarea.value,
    }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const getRootCommentId = async (post, postId, commentId) => {
    try {
      if (!post || !commentId || !communityData?.id) {
        throw new Error("Missing required data for getRootCommentId");
      }

      // Use the community owner's path, not the current user's path
      const basePath = `users/${communityData.ownerId}/communities/${communityData.id}`;
      const commentsPath = `${basePath}/posts/${post.communityPostId}/comments/${commentId}`;

      const commentRef = doc(firestore, commentsPath);
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        // Debug: Get all comments in this post to see what's available
        const allCommentsRef = collection(
          firestore,
          `${basePath}/posts/${post.communityPostId}/comments`
        );
        const allCommentsSnap = await getDocs(allCommentsRef);

        throw new Error(`Comment not found at path: ${commentsPath}`);
      }

      const commentData = commentSnap.data();

      if (commentData.isRootComment || !commentData.parentCommentId) {
        return commentId;
      }

      return getRootCommentId(
        post,
        post.communityPostId,
        commentData.parentCommentId
      );
    } catch (error) {
      throw error;
    }
  };

  const handleReaction = async (postId, emoji) => {
    if (!currentUser) return;

    try {
      const post = communityPosts.find((p) => p.id === postId);
      if (!post) throw new Error("Post not found");

      const basePath = post.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${post.user.id}`;

      const postRef = doc(firestore, `${basePath}/communityPosts/${postId}`);
      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      await updateDoc(postRef, {
        [`communityReactions.${reactorId}`]: emoji,
      });

      // Update local state
      setCommunityPosts((prev) =>
        prev.map((p) =>
          p.id === postId
            ? {
                ...p,
                communityReactions: {
                  ...p.communityReactions,
                  [reactorId]: emoji,
                },
              }
            : p
        )
      );
    } catch (error) {}
  };

  const NoContentCard = ({ onRefresh }) => (
    <div className={classes.noNotifications}>
      <div className={classes.bold}>Page not found</div>
      <div className={classes.text}>Please check your internet connection.</div>
      <div className={classes.homeButton} onClick={onRefresh}>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
          />
        </svg>
        Refresh
      </div>
    </div>
  );

  const toggleEditSingleCommunityModal = () => {
    if (!showEditSingleCommunityModal) {
      setEditSingleCommunityModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editSingleCommunityModalScrollPosition);
      }, 0);
    }
    setShowEditSingleCommunityModal(!showEditSingleCommunityModal);
    setIsEditSingleCommunityModalOpen(!isEditSingleCommunityModalOpen);
  };

  const handleCommunityUpdate = (updatedCommunity) => {
    setCommunityData((prevData) => ({
      ...prevData,
      ...updatedCommunity,
    }));
  };

  const toggleIntroModal = () => {
    if (!showIntroModal) {
      setIntroModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, introModalScrollPosition);
      }, 0);
    }
    setShowIntroModal(!showIntroModal);
    setIsIntroModalOpen(!isIntroModalOpen);
  };

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const renderIntroText = () => {
    const introText = communityData.introHtml || "No description available.";
    const maxLength = 250;

    if (introText.length <= maxLength || showFullIntro) {
      return {
        text: (
          <span
            dangerouslySetInnerHTML={{ __html: introText }}
            onClick={handleLinkClick}
          />
        ),
        showMore: false,
      };
    } else {
      const truncatedText = introText.slice(0, maxLength).trim();
      return {
        text: (
          <span
            dangerouslySetInnerHTML={{ __html: truncatedText }}
            onClick={handleLinkClick}
          />
        ),
        showMore: true,
      };
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatJoinDate = (date) => {
    if (!date) return "";
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    return `Created ${month} ${year}`;
  };

  const checkOwnership = useCallback(
    async (communityData) => {
      if (!currentUser) {
        setIsOwner(false);
        return;
      }

      try {
        const userCommunitiesRef = collection(
          firestore,
          `users/${currentUser.uid}/communities`
        );
        const communitiesQuery = query(
          userCommunitiesRef,
          where("communityUrlSlug", "==", communityUrlSlug)
        );
        const communitiesSnapshot = await getDocs(communitiesQuery);

        setIsOwner(!communitiesSnapshot.empty);
      } catch (error) {
        setIsOwner(false);
      }
    },
    [currentUser, communityUrlSlug]
  );

  const fetchCommunityData = async (urlSlug) => {
    try {
      setIsLoading(true);
      const usersRef = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersRef);
      let communityFound = false;

      for (const userDoc of usersSnapshot.docs) {
        const communitiesRef = collection(
          firestore,
          `users/${userDoc.id}/communities`
        );
        const communitiesQuery = query(
          communitiesRef,
          where("communityUrlSlug", "==", urlSlug)
        );
        const communitiesSnapshot = await getDocs(communitiesQuery);

        if (!communitiesSnapshot.empty) {
          communityFound = true;
          const communityDoc = communitiesSnapshot.docs[0];
          const data = communityDoc.data();

          if (currentUser) {
            const ownerBlockedRef = collection(
              firestore,
              `users/${userDoc.id}/blockedUsers`
            );
            const ownerBlockedQuery = query(
              ownerBlockedRef,
              where("userId", "==", currentUser.uid)
            );
            const ownerBlockedSnap = await getDocs(ownerBlockedQuery);

            const currentUserBlockedRef = collection(
              firestore,
              `users/${currentUser.uid}/blockedUsers`
            );
            const currentUserBlockedQuery = query(
              currentUserBlockedRef,
              where("userId", "==", userDoc.id)
            );
            const currentUserBlockedSnap = await getDocs(
              currentUserBlockedQuery
            );

            setHasUserBlockedThem(currentUserBlockedSnap.size > 0);
            setIsBlocked(
              currentUserBlockedSnap.size > 0 || ownerBlockedSnap.size > 0
            );
          }

          const ownerDoc = await getDoc(doc(firestore, "users", userDoc.id));
          const ownerData = ownerDoc.data();

          setCommunityData({
            id: communityDoc.id,
            ownerId: userDoc.id,
            communityName: data.communityName || "",
            tagline: data.tagline || "",
            link: data.link || "",
            linkText: data.linkText || "",
            communityImage: data.communityImage || "",
            industry: data.industry || "",
            intro: data.intro || "",
            introHtml: data.introHtml || "",
            tags: data.tags || [],
            communityUrlSlug: data.communityUrlSlug || "",
            isPrivate: data.isPrivate || false,
            createdAt: data.createdAt,
            ownerData: {
              id: userDoc.id,
              firstName: ownerData.firstName || "",
              lastName: ownerData.lastName || "",
              profileImage: ownerData.profileImage || "",
              bio: ownerData.bio || "",
              link: ownerData.link || "",
              linkText: ownerData.linkText || "",
              city: ownerData.city || "",
              country: ownerData.country || "",
              urlSlug: ownerData.urlSlug || "",
            },
          });
          break;
        }
      }

      if (!communityFound) {
        setcommunityNotFound(true);
      }
    } catch (error) {
      setcommunityNotFound(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let unsubscribe;
    let isMounted = true;

    const initializeApp = async () => {
      try {
        unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (!isMounted) return;

          if (user) {
            // Fetch full user data including profile image and urlSlug
            const userDoc = await getDoc(doc(firestore, "users", user.uid));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              setCurrentUser({
                ...user,
                firstName: userData.firstName,
                lastName: userData.lastName,
                profileImage: userData.profileImage || defaultProfileImage,
                urlSlug: userData.urlSlug, // Add this line to capture urlSlug
              });
              setCurrentUserProfileImage(
                userData.profileImage || defaultProfileImage
              );
            } else {
              setCurrentUser(user);
              setCurrentUserProfileImage(defaultImage);
            }
          } else {
            setCurrentUser(null);
            setCurrentUserProfileImage(null);
            setIsOwner(false);
          }

          if (communityUrlSlug) {
            await fetchCommunityData(communityUrlSlug);
          }
        });
      } catch (error) {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    initializeApp();

    return () => {
      isMounted = false;
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [communityUrlSlug]);

  useEffect(() => {
    const checkCommunityOwnership = async () => {
      if (currentUser && communityUrlSlug) {
        await checkOwnership();
      }
    };

    checkCommunityOwnership();
  }, [currentUser, communityUrlSlug, checkOwnership]);

  const renderDescriptionCard = () => {
    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>
          About
          {isOwner && (
            <div onClick={toggleIntroModal}>
              <svg
                className={classes.closeIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                />
              </svg>
            </div>
          )}
        </div>
        <div className={classes.introText}>
          {communityData.introHtml ? (
            <>
              <pre>
                {renderIntroText().text}
                {renderIntroText().showMore && (
                  <span
                    className={classes.moreButton}
                    onClick={() => setShowFullIntro(true)}
                  >
                    ...more
                  </span>
                )}
              </pre>
              <div className={classes.noPostBorder}></div>
            </>
          ) : (
            isOwner && (
              <>
                <span className={classes.emptyIntroText}>
                  Tell everyone about your community. What brings people here?
                  What can they expect? Share your community's story.
                </span>
                <div className={classes.noPostBorder}></div>
              </>
            )
          )}
        </div>

        <div className={classes.cardAbout}>
          {mutualMembersCount > 0 && (
            <div
              className={classes.skillContainer}
              onClick={toggleMutualMembersModal}
              style={{ cursor: "pointer" }}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                  clipRule="evenodd"
                />
              </svg>
              <span className={classes.skillText}>
                {mutualMembersCount} Mutual{" "}
                {mutualMembersCount === 1 ? "Member" : "Members"}
              </span>
            </div>
          )}
          {/* Privacy Status */}
          <div className={classes.skillContainer}>
            {communityData.isPrivate ? (
              <>
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="m4 15.6 3.055-3.056A4.913 4.913 0 0 1 7 12.012a5.006 5.006 0 0 1 5-5c.178.009.356.027.532.054l1.744-1.744A8.973 8.973 0 0 0 12 5.012c-5.388 0-10 5.336-10 7A6.49 6.49 0 0 0 4 15.6Z" />
                  <path d="m14.7 10.726 4.995-5.007A.998.998 0 0 0 18.99 4a1 1 0 0 0-.71.305l-4.995 5.007a2.98 2.98 0 0 0-.588-.21l-.035-.01a2.981 2.981 0 0 0-3.584 3.583c0 .012.008.022.01.033.05.204.12.402.211.59l-4.995 4.983a1 1 0 1 0 1.414 1.414l4.995-4.983c.189.091.386.162.59.211.011 0 .021.007.033.01a2.982 2.982 0 0 0 3.584-3.584c0-.012-.008-.023-.011-.035a3.05 3.05 0 0 0-.21-.588Z" />
                  <path d="m19.821 8.605-2.857 2.857a4.952 4.952 0 0 1-5.514 5.514l-1.785 1.785c.767.166 1.55.25 2.335.251 6.453 0 10-5.258 10-7 0-1.166-1.637-2.874-2.179-3.407Z" />
                </svg>
                <span className={classes.skillText}>Private</span>
              </>
            ) : (
              <>
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.998 7.78C6.729 6.345 9.198 5 12 5c2.802 0 5.27 1.345 7.002 2.78a12.713 12.713 0 0 1 2.096 2.183c.253.344.465.682.618.997.14.286.284.658.284 1.04s-.145.754-.284 1.04a6.6 6.6 0 0 1-.618.997 12.712 12.712 0 0 1-2.096 2.183C17.271 17.655 14.802 19 12 19c-2.802 0-5.27-1.345-7.002-2.78a12.712 12.712 0 0 1-2.096-2.183 6.6 6.6 0 0 1-.618-.997C2.144 12.754 2 12.382 2 12s.145-.754.284-1.04c.153-.315.365-.653.618-.997A12.714 12.714 0 0 1 4.998 7.78ZM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className={classes.skillText}>Public</span>
              </>
            )}
          </div>

          {/* Created Date */}
          {communityData.createdAt && (
            <div className={classes.skillContainer}>
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                  clip-rule="evenodd"
                />
              </svg>

              <span className={classes.skillText}>
                {formatJoinDate(
                  communityData.createdAt?.toDate?.() ||
                    new Date(communityData.createdAt)
                )}
              </span>
            </div>
          )}

          {/* Community URL */}
          {communityData.communityUrlSlug && (
            <div className={classes.skillContainer}>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
                />
              </svg>
              <span className={classes.skillText}>
                {`foundry.bz/community/${communityData.communityUrlSlug}`}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const toggleShowAllKeywords = () => setShowAllKeywords(true);

  const handleNextImage = (postId, totalImages) => {
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: ((prev[postId] || 0) + 1) % totalImages,
    }));
  };

  const renderCommentOptions = (postId, comment) => {
    // First determine if user can edit/delete this comment based on the ORIGINAL comment data
    const isOwnComment = comment.isStartup
      ? comment.startupOwnerId === currentUser?.uid // Check startup owner ID
      : comment.userId === currentUser?.uid; // Check direct user ID

    // Get the target post using the stored ID, not the user data
    const targetPost = communityPosts.find((p) => p.id === postId);

    // Check if user is post owner (based on original IDs, not displayed data)
    const isPostOwner = targetPost?.user?.isStartup
      ? targetPost.user.startupOwnerId === currentUser?.uid
      : targetPost?.user?.id === currentUser?.uid;

    // Check if user is community owner or moderator (these roles are current)
    const isCommunityOwner = currentUser?.uid === communityData.ownerId;
    const isModerator = moderators.some((mod) => mod.id === currentUser?.uid);

    // Determine if this comment is from the community owner (based on original ID)
    const isCommentFromOwner = comment.userId === communityData.ownerId;

    // Can delete if:
    // 1. It's own comment (based on original IDs)
    // 2. User is post owner (including startup posts they own)
    // 3. User is community owner
    // 4. User is moderator (but can't delete owner's comments)
    const canDelete =
      isOwnComment ||
      isPostOwner ||
      isCommunityOwner ||
      (isModerator && !isCommentFromOwner);

    return (
      <div className={classes.commentOptionsDropdown}>
        {isOwnComment && (
          <button
            className={classes.editCommentButton}
            onClick={() => handleEditComment(comment.id)}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
              />
            </svg>
            Edit
          </button>
        )}

        {/* Show Report button for non-owned comments */}
        {!isOwnComment && (
          <button
            className={classes.reportCommentButton}
            onClick={() => handleReportComment(comment.id)}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            Report
          </button>
        )}

        {/* Show Delete button if user has permission */}
        {canDelete && (
          <button
            className={classes.deleteCommentButton}
            onClick={() => handleDeleteComment(postId, comment.id)}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
              />
            </svg>
            Delete
          </button>
        )}
      </div>
    );
  };

  const renderReplies = (postId, rootCommentId) => {
    if (!comments[postId]) return null;

    const replies = comments[postId].filter(
      (comment) =>
        comment.rootCommentId === rootCommentId && !comment.isRootComment
    );

    if (replies.length === 0) return null;

    const visibleRepliesCount = visibleReplies[rootCommentId] || 0;
    const visibleRepliesList = replies.slice(0, visibleRepliesCount);

    return (
      <>
        <div className={classes.repliesContainer}>
          {visibleRepliesCount === 0 ? (
            <div
              className={classes.viewMoreRepliesButton}
              onClick={() => handleViewMoreReplies(rootCommentId)}
            >
              Show replies ({replies.length})
            </div>
          ) : (
            <>
              {visibleRepliesList.map((reply) => (
                <div key={reply.id} className={classes.replyItem}>
                  <div className={classes.replyHeader}>
                    <div className={classes.replyUserInfo}>
                      <img
                        src={reply.userImage || profileImage}
                        alt={reply.userName}
                        className={classes.replyUserImage}
                        onClick={() =>
                          handleNavigate(
                            reply.userId,
                            reply.isStartup,
                            reply.startupUrlSlug
                          )
                        }
                        style={{
                          borderRadius: reply.isStartup ? 0 : "50%",
                          cursor: "pointer",
                        }}
                      />
                      <div className={classes.replyUserDetails}>
                        <div className={classes.replyUserNameContainer}>
                          <span
                            className={classes.replyUserName}
                            onClick={() =>
                              handleNavigate(
                                reply.userId,
                                reply.isStartup,
                                reply.startupUrlSlug
                              )
                            }
                          >
                            {reply.userName}
                          </span>
                          <span className={classes.replyConnectionType}>
                            • {reply.connectionType}
                          </span>
                        </div>
                        {reply.bio && (
                          <p className={classes.replyUserBio}>{reply.bio}</p>
                        )}
                        {reply.link && (
                          <div className={classes.replyUserLinkContainer}>
                            <a
                              href={reply.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                              className={classes.replyUserLink}
                            >
                              <span className={classes.replyUserLinkText}>
                                {reply.linkText || reply.link}
                              </span>
                              <svg
                                className={classes.replyUserLinkIcon}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="orangered"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                />
                              </svg>
                            </a>
                          </div>
                        )}
                        <div className={classes.commentTimeAndEditDetails}>
                          <p className={classes.commentTimestamp}>
                            {formatTime(reply.createdAt)}
                          </p>
                          {reply.edited && (
                            <div className={classes.commentEditDot}>
                              •{" "}
                              <span className={classes.commentEditText}>
                                Edited
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={classes.replyOptions}>
                      <svg
                        className={`${classes.replyOptionsIcon} ${
                          openCommentDropdown === `${postId}_${reply.id}`
                            ? classes.replyOptionsIconActive
                            : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={() => toggleCommentDropdown(postId, reply.id)}
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                      {openCommentDropdown === `${postId}_${reply.id}` &&
                        renderCommentOptions(postId, reply)}
                    </div>
                  </div>
                  <div
                    className={classes.replyContent}
                    onClick={handleLinkClick}
                  >
                    {renderReplyText(postId, reply)}
                  </div>
                  {editingCommentId !== reply.id && (
                    <div className={classes.replyActions}>
                      <div
                        className={`${classes.reactButton} ${
                          (selectedStartup &&
                            reply.reactions?.[
                              `startup_${selectedStartup.id}`
                            ]) ||
                          (!selectedStartup &&
                            reply.reactions?.[currentUser?.uid])
                            ? classes.activeReaction
                            : ""
                        }`}
                        onClick={() =>
                          handleCommentReactionClick(postId, reply.id)
                        }
                      >
                        Boost{" "}
                        {reply.reactions &&
                          Object.keys(reply.reactions).length > 0 &&
                          `(${Object.keys(reply.reactions).length})`}
                      </div>

                      <div className={classes.dot}> • </div>
                      <button
                        className={classes.replyButton}
                        onClick={() => handleReplyButton(postId, reply.id)}
                      >
                        <span className={classes.replyText}>
                          Reply{" "}
                          {comments[postId]?.filter(
                            (r) => r.parentCommentId === reply.id
                          ).length > 0 &&
                            `(${
                              comments[postId].filter(
                                (r) => r.parentCommentId === reply.id
                              ).length
                            })`}
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              ))}

              {replies.length > visibleRepliesCount && (
                <div
                  className={classes.viewMoreRepliesButton}
                  onClick={() => handleViewMoreReplies(rootCommentId)}
                >
                  Show more replies ({replies.length - visibleRepliesCount})
                </div>
              )}
            </>
          )}
        </div>
        {activeCommentId[postId] &&
          replies.some((reply) => reply.id === activeCommentId[postId]) && (
            <ReplyInput
              postId={postId}
              commentId={activeCommentId[postId]}
              onSubmit={handleAddReply}
            />
          )}
      </>
    );
  };

  const RepostPopup = ({ message, onClose, isLoading }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          {isLoading ? (
            // Clock icon for "Deleting..." state
            <svg
              className={classes.tickIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="orangered"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            // Regular checkmark icon for other states
            <svg
              className={classes.tickIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="orangered"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                clipRule="evenodd"
              />
            </svg>
          )}
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton} onClick={onClose}>
          <svg
            className={classes.repostCloseIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const getTopThreeEmojis = (reactions) => {
    if (!reactions) return [];
    const emojiCounts = Object.values(reactions).reduce((acc, emoji) => {
      acc[emoji] = (acc[emoji] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(emojiCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([emoji]) => emoji);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    let date;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (typeof timestamp.toDate === "function") {
      date = timestamp.toDate();
    } else {
      return "";
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) {
      return "now";
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes}m`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours}h`;
    } else if (diffInSeconds < 604800) {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days}d`;
    } else if (diffInSeconds < 2592000) {
      const weeks = Math.floor(diffInSeconds / 604800);
      return `${weeks}w`;
    } else if (diffInSeconds < 31536000) {
      const months = Math.floor(diffInSeconds / 2592000);
      return `${months}mo`;
    } else {
      const years = Math.floor(diffInSeconds / 31536000);
      return `${years}y`;
    }
  };

  const toggleCommentDropdown = (postId, commentId) => {
    const dropdownKey = `${postId}_${commentId}`;
    setOpenCommentDropdown((prev) =>
      prev === dropdownKey ? null : dropdownKey
    );
  };

  // Add this console log in your render to debug:
  useEffect(() => {}, [openCommentDropdown]);

  const handleDeleteComment = async (postId, commentId) => {
    if (!currentUser || !communityData?.id || !communityData?.ownerId) return;

    setDeletingCommentMessage({
      text: "Deleting comment...",
      isLoading: true,
    });

    try {
      const targetPost = communityPosts.find((p) => p.id === postId);
      if (!targetPost) {
        setErrorPopup({
          message: "The post containing this comment has been deleted.",
          isError: true,
        });
        return;
      }

      const commentsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${targetPost.communityPostId}/comments`
      );
      const commentToDeleteRef = doc(commentsRef, commentId);
      const commentSnap = await getDoc(commentToDeleteRef);

      if (!commentSnap.exists()) {
        setErrorPopup({
          message: "This comment has already been deleted.",
          isError: true,
        });
        return;
      }

      const commentData = commentSnap.data();
      const canDelete = (() => {
        // Check if it's the commenter's own comment
        if (commentData.userId.startsWith("startup_")) {
          if (commentData.startupOwnerId === currentUser.uid) {
            return true;
          }
        } else if (commentData.userId === currentUser.uid) {
          return true;
        }

        // Check if user is the post owner
        if (targetPost.user.isStartup) {
          // For startup posts
          if (targetPost.user.startupOwnerId === currentUser.uid) {
            return true;
          }
        } else {
          // For regular user posts
          if (targetPost.user.id === currentUser.uid) {
            return true;
          }
        }

        // Check if user is community owner or moderator
        if (
          currentUser.uid === communityData.ownerId ||
          moderators.some((mod) => mod.id === currentUser.uid)
        ) {
          return true;
        }

        return false;
      })();

      if (!canDelete) {
        setErrorPopup({
          message: "You don't have permission to delete this comment.",
          isError: true,
        });
        return;
      }

      let deletedCount = 0;
      const deletedCommentIds = new Set();
      const affectedUserIds = new Set();
      const affectedStartupIds = new Set();

      const batch = writeBatch(firestore);

      // Function to process a comment deletion
      const processCommentDeletion = async (comment) => {
        deletedCommentIds.add(comment.id);
        deletedCount++;

        // Track affected users/startups for the comments map cleanup
        if (comment.data().userId.startsWith("startup_")) {
          const startupId = comment.data().userId.replace("startup_", "");
          affectedStartupIds.add(startupId);
          affectedUserIds.add(comment.data().startupOwnerId);
        } else {
          affectedUserIds.add(comment.data().userId);
        }

        // Delete the comment document
        batch.delete(comment.ref);
      };

      // Delete the main comment
      await processCommentDeletion({
        id: commentId,
        ref: commentToDeleteRef,
        data: () => commentData,
      });

      // If this is a root comment, find and delete all replies
      if (commentData.isRootComment) {
        const repliesQuery = query(
          commentsRef,
          where("rootCommentId", "==", commentId)
        );
        const repliesSnapshot = await getDocs(repliesQuery);

        for (const replyDoc of repliesSnapshot.docs) {
          await processCommentDeletion(replyDoc);
        }
      }

      // Delete notifications for all deleted comments
      // Get all users to check their notifications
      const usersSnapshot = await getDocs(collection(firestore, "users"));

      // For each user, check and delete relevant notifications
      for (const userDoc of usersSnapshot.docs) {
        const notificationsRef = collection(
          firestore,
          `users/${userDoc.id}/notifications`
        );

        // Get all notifications that might be related to these comments
        const notificationsQuery = query(
          notificationsRef,
          where("type", "in", ["comment", "reply", "comment_reaction"])
        );

        const notificationsSnapshot = await getDocs(notificationsQuery);

        // Check each notification
        for (const notificationDoc of notificationsSnapshot.docs) {
          const notificationData = notificationDoc.data();
          const shouldDelete =
            // Delete if it's a notification about the deleted comment
            deletedCommentIds.has(notificationData.commentId) ||
            // Delete if it's a reply notification where the parent comment was deleted
            (notificationData.type === "reply" &&
              deletedCommentIds.has(notificationData.parentCommentId)) ||
            // Delete if it's a reaction notification for the deleted comment
            (notificationData.type === "comment_reaction" &&
              deletedCommentIds.has(notificationData.commentId));

          if (shouldDelete) {
            batch.delete(notificationDoc.ref);
          }
        }

        // Also check all startups owned by this user for any related notifications
        const userStartupsSnapshot = await getDocs(
          collection(firestore, `users/${userDoc.id}/startups`)
        );

        for (const startupDoc of userStartupsSnapshot.docs) {
          const startupNotificationsRef = collection(
            firestore,
            `users/${userDoc.id}/notifications`
          );

          const startupNotificationsQuery = query(
            startupNotificationsRef,
            where("type", "in", ["comment", "reply", "comment_reaction"]),
            where("startupId", "==", startupDoc.id)
          );

          const startupNotificationsSnapshot = await getDocs(
            startupNotificationsQuery
          );

          for (const notificationDoc of startupNotificationsSnapshot.docs) {
            const notificationData = notificationDoc.data();
            const shouldDelete =
              deletedCommentIds.has(notificationData.commentId) ||
              (notificationData.type === "reply" &&
                deletedCommentIds.has(notificationData.parentCommentId)) ||
              (notificationData.type === "comment_reaction" &&
                deletedCommentIds.has(notificationData.commentId));

            if (shouldDelete) {
              batch.delete(notificationDoc.ref);
            }
          }
        }
      }

      // We no longer need this section since we're adding notification deletes
      // to the batch directly when we find them

      // Update the post's comments map
      // Remove entries for all affected users and startups
      const postRef = doc(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${targetPost.communityPostId}`
      );

      const updates = {
        commentCount: increment(-deletedCount),
      };

      // Remove from comments map for each affected user/startup
      affectedUserIds.forEach((userId) => {
        updates[`comments.${userId}`] = deleteField();
      });
      affectedStartupIds.forEach((startupId) => {
        updates[`comments.startup_${startupId}`] = deleteField();
      });

      batch.update(postRef, updates);

      await batch.commit();

      // Update local state
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].filter(
          (comment) => !deletedCommentIds.has(comment.id)
        ),
      }));

      setCommunityPosts((prevPosts) =>
        prevPosts.map((p) => {
          if (p.id === postId) {
            const updatedComments = { ...p.comments };
            // Remove comments entries for affected users and startups
            affectedUserIds.forEach((userId) => {
              delete updatedComments[userId];
            });
            affectedStartupIds.forEach((startupId) => {
              delete updatedComments[`startup_${startupId}`];
            });
            return {
              ...p,
              comments: updatedComments,
              commentCount: Math.max((p.commentCount || 0) - deletedCount, 0),
            };
          }
          return p;
        })
      );

      setOpenCommentDropdown(null);
      setDeletingCommentMessage({
        text: "Comment deleted",
        isLoading: false,
      });
    } catch (error) {
      setErrorPopup({
        message: "An error occurred while deleting the comment.",
        isError: true,
      });
    } finally {
      // If there was an error, clear the "Deleting..." message
      if (deletingCommentMessage?.isLoading) {
        setDeletingCommentMessage(null);
      }
    }
  };

  const renderCommentText = (comment) => {
    if (!comment || !comment.text) return null;

    if (editingCommentId === comment.id) {
      return (
        <EditInput
          postId={comment.postId}
          commentId={comment.id}
          initialText={stripHtmlTags(comment.text)}
          isReply={false}
          onSave={handleSaveCommentChanges}
          onCancel={handleCancelEdit}
        />
      );
    }

    const text = comment.text;
    const isLongComment = text.length > 125;
    const displayText = expandedComments[comment.id]
      ? text
      : text.slice(0, 125);

    return (
      <div className={classes.commentTextContainer}>
        <div
          className={`${classes.commentText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongComment && !expandedComments[comment.id] && (
          <button
            className={classes.showMoreButton}
            onClick={() => toggleCommentExpansion(comment.id)}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  // Update handleCommentReactionClick to directly apply the boost
  const handleCommentReactionClick = async (postId, commentId) => {
    if (!currentUser || !commentId) return;

    try {
      const targetPost = communityPosts.find((p) => p.id === postId);
      if (!targetPost) {
        throw new Error("Post not found");
      }

      const commentsRef = collection(
        firestore,
        `users/${communityData.ownerId}/communities/${communityData.id}/posts/${targetPost.communityPostId}/comments`
      );
      const commentRef = doc(commentsRef, commentId);
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();
      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      let updatedReactions = { ...(commentData.reactions || {}) };

      if (updatedReactions[reactorId] === "👍") {
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });
        delete updatedReactions[reactorId];

        const notificationRef = doc(
          firestore,
          `users/${
            commentData.isStartup
              ? commentData.startupOwnerId
              : commentData.userId
          }/notifications`,
          `comment_reaction_${commentId}_${reactorId}`
        );
        await deleteDoc(notificationRef);
      } else {
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: "👍",
        });
        updatedReactions[reactorId] = "👍";

        const shouldNotify =
          (selectedStartup &&
            !(
              commentData.userId === currentUser.uid ||
              (commentData.isStartup &&
                commentData.startupOwnerId === currentUser.uid)
            )) ||
          (!selectedStartup &&
            (commentData.isStartup
              ? commentData.startupOwnerId !== currentUser.uid
              : commentData.userId !== currentUser.uid));

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${
              commentData.isStartup
                ? commentData.startupOwnerId
                : commentData.userId
            }/notifications`,
            `comment_reaction_${commentId}_${reactorId}`
          );

          const notificationData = {
            type: "comment_reaction",
            communityId: communityData.id,
            communityOwnerId: communityData.ownerId,
            postId: targetPost.id,
            commentId: commentId,
            reactorId: reactorId,
            reactorName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reactorImage: selectedStartup
              ? selectedStartup.startupImage || defaultStartupImage
              : currentUserProfileImage,
            emoji: "👍",
            createdAt: serverTimestamp(),
            isNew: true,
            commentText: commentData.text,
            isReply: !commentData.isRootComment,
            postPreview: {
              text: targetPost.text || "",
              mediaType: targetPost.content?.type || null,
              mediaUrl: targetPost.content?.url || null,
              fileName: targetPost.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage || defaultStartupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) => {
          if (comment.id === commentId) {
            return { ...comment, reactions: updatedReactions };
          }
          return comment;
        }),
      }));
    } catch (error) {
      setErrorPopup({
        message:
          error.message === "Comment not found"
            ? "This comment has been deleted."
            : "An error occurred while updating the reaction.",
        isError: true,
      });
    }
  };

  const getCommenterInfo = async (currentUser, selectedStartup) => {
    try {
      if (selectedStartup) {
        // Get the full startup data
        const startupRef = doc(
          firestore,
          `users/${currentUser.uid}/startups/${selectedStartup.id}`
        );
        const startupDoc = await getDoc(startupRef);
        const startupData = startupDoc.data();

        return {
          id: `startup_${selectedStartup.id}`,
          name: selectedStartup.startupName,
          image: selectedStartup.startupImage || defaultStartupImage,
          bio: startupData.bio || "",
          link: startupData.link || null,
          linkText: startupData.linkText || null,
          connectionType: "Startup",
          isStartup: true,
          startupId: selectedStartup.id,
          ownerId: currentUser.uid,
          startupUrlSlug: startupData.startupUrlSlug,
        };
      }

      // Handle regular user info
      return {
        id: currentUser.uid,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        image: currentUser.profileImage || defaultProfileImage,
        bio: currentUser.bio || "",
        link: currentUser.link || null,
        linkText: currentUser.linkText || null,
        connectionType: "You",
        isStartup: false,
        urlSlug: currentUser.urlSlug,
      };
    } catch (error) {
      throw error;
    }
  };

  const handleShare = () => {
    const communityUrl = `${window.location.origin}/community/${communityData.communityUrlSlug}`;
    navigator.clipboard.writeText(communityUrl).then(() => {
      setShareButtonText("Copied");
      setTimeout(() => {
        setShareButtonText("Share");
      }, 5000); // Reset back to "Share" after 5 seconds
    });
  };

  const handlePostShare = useCallback(
    (postId) => {
      const communityUrl = `${window.location.origin}/community/${communityData.communityUrlSlug}`;

      navigator.clipboard.writeText(communityUrl).then(() => {
        setShareStates((prev) => ({ ...prev, [postId]: "Copied" }));
        setTimeout(() => {
          setShareStates((prev) => ({ ...prev, [postId]: null }));
        }, 5000);
      });
    },
    [communityData?.communityUrlSlug]
  );

  const handleReplyButton = useCallback((postId, commentId) => {
    setActiveCommentId((prev) => ({
      ...prev,
      [postId]: prev[postId] === commentId ? null : commentId,
    }));
  }, []);

  const handleLoadMoreComments = (postId) => {
    setVisibleComments((prev) => {
      // Get current number of visible comments
      const currentVisible = prev[postId] || 3;

      // Get only root comments (not replies)
      const rootComments =
        comments[postId]?.filter((comment) => comment.isRootComment) || [];
      const rootCommentsCount = rootComments.length;

      // Calculate new visible count
      const increment = currentVisible === 3 ? 6 : (currentVisible - 3) * 2;
      return {
        ...prev,
        [postId]: Math.min(currentVisible + increment, rootCommentsCount),
      };
    });
  };

  const handleReplyInputChange = (e, postId, commentId) => {
    const textarea = e.target;
    setReplyComments((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [commentId]: textarea.value,
      },
    }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleTouchStart = (e) => {
    const textarea = e.target;
    textarea.focus();
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const toggleEditMembersModal = () => {
    if (!showEditMembersModal) {
      setEditMembersModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editMembersModalScrollPosition);
      }, 0);
    }
    setShowEditMembersModal(!showEditMembersModal);
    setIsEditMembersModalOpen(!isEditMembersModalOpen);
  };

  const forceKeyboardOpen = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      inputElement.click();
      inputElement.setSelectionRange(0, 0);
      setTimeout(() => {
        inputElement.setSelectionRange(
          inputElement.value.length,
          inputElement.value.length
        );
      }, 50);
    }
  };

  useEffect(() => {
    const checkSafari = () => {
      const isSafari =
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        /apple/i.test(navigator.vendor);
      setIsSafari(isSafari);
      document.documentElement.style.setProperty(
        "--emoji-font-size",
        isSafari ? "0.75rem" : "0.875rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-left",
        isSafari ? "-0.25rem" : "-0.25rem"
      );
      document.documentElement.style.setProperty(
        "--count-margin-left",
        isSafari ? "0.25rem" : "0.25rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-text-size",
        isSafari ? "0.75rem" : "0.875rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-bottom",
        isSafari ? "0rem" : "0rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-top",
        isSafari ? "0rem" : "0rem"
      );
    };

    checkSafari();
  }, []);

  const openMobileKeyboard = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      if (typeof inputElement.scrollIntoView === "function") {
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      if (typeof inputElement.click === "function") {
        inputElement.click();
      }
      if (typeof inputElement.setSelectionRange === "function") {
        const length = inputElement.value.length;
        inputElement.setSelectionRange(length, length);
      }
    }
  };

  const shouldShowContent = (contentType) => {
    const isModerator = moderators.some((mod) => mod.id === currentUser?.uid);
    const isMember = membershipStatus === "Member"; // Only true if they are actually a member

    switch (contentType) {
      case "topCard":
        return isOwner || isModerator || isMember;
      case "posts":
        return isOwner || isModerator || isMember;
      case "tags":
        return (
          isOwner ||
          (!isOwner && communityData.tags && communityData.tags.length > 0)
        );
      case "intro":
        return true;
      case "editButtons":
        return isOwner;
      default:
        return false;
    }
  };

  const renderPostDropdown = (post) => {
    const isOwnPost = post.user.isStartup
      ? post.user.startupOwnerId === currentUser?.uid
      : post.user.id === currentUser?.uid;

    const canDelete =
      isOwnPost ||
      currentUser?.uid === communityData.ownerId ||
      moderators.some((mod) => mod.id === currentUser?.uid);

    return (
      <div className={classes.optionsDropdown}>
        {isOwnPost && (
          <button
            onClick={() => handleEditPost(post)}
            className={classes.editPost}
          >
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
              />
            </svg>
            Edit
          </button>
        )}

        {/* Show Report button for non-owned posts, regardless of delete permission */}
        {!isOwnPost && (
          <button
            onClick={() => handleReportPost(post.id)}
            className={classes.reportCommentButton}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            Report
          </button>
        )}

        {/* Show Delete button if user has permission */}
        {canDelete && (
          <button
            onClick={() => handleDeletePost(post.id)}
            className={classes.deletePost}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
              />
            </svg>
            Delete
          </button>
        )}
      </div>
    );
  };

  const handleRemovePost = async (post) => {
    try {
      // Create a document in the removedPosts subcollection
      const removedPostRef = doc(
        firestore,
        `users/${currentUser.uid}/removedPosts/${post.globalPostId}`
      );

      await setDoc(removedPostRef, {
        removedAt: serverTimestamp(),
        postId: post.id,
        originalPosterId: post.user.isStartup
          ? `startup_${post.user.startupId}`
          : post.user.id,
        startupOwnerId: post.user.startupOwnerId || null,
      });

      // Remove the post from the local state
      setCommunityPosts((prevPosts) =>
        prevPosts.filter((p) => p.globalPostId !== post.globalPostId)
      );
      setShowDropdown((prev) => ({ ...prev, [post.id]: false }));

      // Show success message
      setErrorPopup({
        message: "Post removed.",
        isError: false,
      });
    } catch (error) {
      setErrorPopup({
        message: "Error removing post.",
        isError: true,
      });
    }
  };

  const getMediaClass = (post) => {
    if (!post || !post.content) return "";
    if (post.content.type === "video") return classes.hasVideo;
    if (post.content.type === "image") {
      return Array.isArray(post.content.url)
        ? classes.hasMultipleImages
        : classes.hasImage;
    }
    return "";
  };

  const BadgesCard = ({
    isOwner,
    isProfileComplete,
    moderatorsCount = 0,
    membersCount = 0,
    postsCount = 0,
    tagsCount = 0,
    classes,
  }) => {
    const [displayedBadges, setDisplayedBadges] = useState(9);

    const allBadges = [
      {
        id: "complete-curator",
        name: "Complete Curator",
        earned: isProfileComplete,
      },
      {
        id: "safe-space",
        name: "Safe Space",
        earned: moderatorsCount >= 3,
      },
      {
        id: "network-effect",
        name: "Network Effects",
        earned: membersCount >= 500,
      },
      {
        id: "knowledge-hub",
        name: "Knowledge Hub",
        earned: postsCount >= 250,
      },
      {
        id: "maximum-exposure",
        name: "Maximum Exposure",
        earned: tagsCount >= 25,
      },
    ];

    const earnedBadges = allBadges.filter((badge) => badge.earned);
    const hasBadges = earnedBadges.length > 0;

    // If not owner and no badges, return null (don't show card)
    if (!isOwner && !hasBadges) {
      return null;
    }

    // For visitors, only show earned badges
    const badgesToShow = isOwner ? allBadges : earnedBadges;

    return (
      <div className={classes.card}>
        <div
          className={`${classes.cardTitle} ${
            !hasBadges ? classes.emptyCardTitle : ""
          }`}
        >
          Badges
          <a
            href="/badges"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.userLink}
          >
            <svg
              style={{ cursor: "pointer" }}
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </a>
        </div>

        <div className={classes.badges}>
          {isOwner && (
            <div className={classes.badgesText}>
              Complete challenges and contribute to the community to earn
              badges. Each badge tells a story of your community's journey.
            </div>
          )}

          <div className={classes.badgesContainer}>
            {badgesToShow.slice(0, displayedBadges).map((badge) =>
              badge.earned ? (
                <div key={badge.id} className={classes.skillContainer}>
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    style={{ color: "orangered" }}
                  >
                    <path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
                    <path
                      fillRule="evenodd"
                      d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                      clipRule="evenodd"
                    />
                    <path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z" />
                  </svg>
                  <span
                    className={classes.skillText}
                    style={{ color: "orangered", fontWeight: "900" }}
                  >
                    {badge.name}
                  </span>
                </div>
              ) : (
                isOwner && (
                  <div key={badge.id} className={classes.emptySkillContainer}>
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      style={{ color: "gray" }}
                    >
                      <path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
                      <path
                        fillRule="evenodd"
                        d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                        clipRule="evenodd"
                      />
                      <path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z" />
                    </svg>
                    <span className={classes.skillText}>{badge.name}</span>
                  </div>
                )
              )
            )}
          </div>
        </div>

        {badgesToShow.length > 9 && (
          <div
            className={classes.showAllExperiences}
            onClick={() => {
              if (displayedBadges >= badgesToShow.length) {
                setDisplayedBadges(9);
              } else {
                const additionalItems = displayedBadges * 2;
                setDisplayedBadges(
                  Math.min(
                    displayedBadges + additionalItems,
                    badgesToShow.length
                  )
                );
              }
            }}
          >
            {displayedBadges >= badgesToShow.length ? "Show less" : "Show more"}
            {displayedBadges >= badgesToShow.length ? (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m5 15 7-7 7 7"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 9-7 7-7-7"
                />
              </svg>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderBadgesCard = () => {
    const badgeProps = {
      isOwner,
      isProfileComplete:
        !!communityData.intro &&
        !!communityData.communityImage &&
        !communityData.communityImage.includes("communityImage.jpg") &&
        !communityData.communityImage.includes("communityImage.jpeg") &&
        !communityData.communityImage.includes(
          "communityImages/default/default/default.jpg"
        ) &&
        communityData.tags?.length > 0,
      moderatorsCount: moderators.length + 1, // +1 to include owner
      membersCount: memberCount,
      postsCount: communityPosts.length,
      tagsCount: communityData.tags?.length || 0,
      classes: classes, // Pass the classes from your module
    };

    return <BadgesCard {...badgeProps} />;
  };

  const CommunityChecklist = ({ communityData, isOwner, classes }) => {
    const [isChecklistOpen, setIsChecklistOpen] = useState(true);
    const [completionStatus, setCompletionStatus] = useState({
      profilePicture: false,
      about: false,
      tags: false,
    });

    useEffect(() => {
      const hasCustomProfileImage =
        communityData.communityImage &&
        !communityData.communityImage.includes("communityImage.jpg") &&
        !communityData.communityImage.includes("communityImage.jpeg") &&
        !communityData.communityImage.includes(
          "communityImages/default/default/default.jpg"
        );

      setCompletionStatus({
        profilePicture: hasCustomProfileImage,
        about: !!communityData.intro,
        tags:
          Array.isArray(communityData.tags) && communityData.tags.length > 0,
      });
    }, [communityData]);

    const CompletedIcon = () => (
      <svg
        className={classes.checkIcon}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="orangered"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
          clipRule="evenodd"
        />
      </svg>
    );

    const IncompleteIcon = () => (
      <svg
        className={classes.checkIcon}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="orangered"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
    );

    const checklistItems = [
      { id: "profilePicture", text: "Add a logo" },
      { id: "about", text: "Write an about" },
      { id: "tags", text: "Add your tags" },
    ];

    const isProfileComplete = Object.values(completionStatus).every(
      (status) => status === true
    );

    if (!isProfileComplete) {
      return (
        <div className={classes.card}>
          <div
            className={`${classes.checklist} ${
              isChecklistOpen ? classes.openOpen : ""
            }`}
            onClick={() => setIsChecklistOpen(!isChecklistOpen)}
            style={{ cursor: "pointer" }}
          >
            Checklist
            <div>
              {isChecklistOpen ? (
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m16 14-4-4-4 4"
                  />
                </svg>
              ) : (
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m8 10 4 4 4-4"
                  />
                </svg>
              )}
            </div>
          </div>

          <div
            className={`${classes.checklistContent} ${
              isChecklistOpen ? classes.openOpen : ""
            }`}
          >
            <div
              style={{
                color: "gray",
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              Get started by taking these essential steps:
            </div>

            <div
              style={{
                backgroundColor: "rgba(255, 69, 0, 0.1)",
                padding: "1rem",
                borderRadius: "0.5rem",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <div style={{ textAlign: "left" }}>
                {checklistItems.map((item, index) => (
                  <div
                    key={item.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom:
                        index !== checklistItems.length - 1 ? "0.5rem" : 0,
                    }}
                  >
                    {completionStatus[item.id] ? (
                      <CompletedIcon />
                    ) : (
                      <IncompleteIcon />
                    )}
                    <span
                      style={{
                        color: "orangered",
                        fontWeight: "900",
                      }}
                    >
                      {item.text}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  if (isLoading) {
    return (
      <div className={classes.page}>
        {isMobileView ? (
          <MobileNavbar currentUser={currentUser} />
        ) : (
          <MainNavbar currentUser={currentUser} />
        )}

        <div className={classes.content}>
          <div className={classes.centreCards}>
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  if (communityNotFound) {
    return (
      <div className={classes.page}>
        {isMobileView ? (
          <MobileNavbar currentUser={currentUser} />
        ) : (
          <MainNavbar currentUser={currentUser} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <NoContentCard onRefresh={() => window.location.reload()} />
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${classes.page} ${
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isEditModeratorModalOpen ||
        isAddModeratorModalOpen ||
        isMembersModalOpen ||
        isEditMembersModalOpen ||
        isAddMemberModalOpen ||
        isTagsModalOpen ||
        isEditSingleCommunityModalOpen ||
        isIntroModalOpen ||
        isPostModalOpen ||
        showStartupModal ||
        isMutualMembersModalOpen // Add this line
          ? classes.noScroll
          : ""
      }`}
      style={
        isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isEditModeratorModalOpen
          ? { top: `-${editModeratorModalScrollPosition}px` }
          : isAddModeratorModalOpen
          ? { top: `-${addModeratorModalScrollPosition}px` }
          : isMembersModalOpen
          ? { top: `-${membersModalScrollPosition}px` }
          : isEditMembersModalOpen
          ? { top: `-${editMembersModalScrollPosition}px` }
          : isAddMemberModalOpen
          ? { top: `-${addMembersModalScrollPosition}px` }
          : isTagsModalOpen
          ? { top: `-${tagsModalScrollPosition}px` }
          : isEditSingleCommunityModalOpen
          ? { top: `-${editSingleCommunityModalScrollPosition}px` }
          : isIntroModalOpen
          ? { top: `-${introModalScrollPosition}px` }
          : isPostModalOpen
          ? { top: `-${postModalScrollPosition}px` }
          : isMutualMembersModalOpen
          ? { top: `-${mutualMembersModalScrollPosition}px` }
          : showStartupModal
          ? { top: `-${startupModalScrollPosition}px` }
          : {}
      }
    >
      {isMobileView ? (
        <MobileNavbar currentUser={currentUser} />
      ) : (
        <MainNavbar currentUser={currentUser} />
      )}
      {showMutualMembersModal && (
        <MutualMembersModal
          onClose={() => {
            setShowMutualMembersModal(false);
            setIsMutualMembersModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, mutualMembersModalScrollPosition);
            }, 0);
          }}
          communityData={communityData}
          currentUser={currentUser}
        />
      )}
      {actionMessage && (
        <div className={classes.repostPopup}>
          <div className={classes.repostPopupContent}>
            <svg
              className={classes.tickIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="orangered"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                clipRule="evenodd"
              />
            </svg>
            <p>{actionMessage.text}</p>
          </div>
          <div className={classes.repostCloseButton}>
            <svg
              className={classes.repostCloseIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
              viewBox="0 0 24 24"
              onClick={() => setActionMessage(null)}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
      )}
      {deletingPostMessage && (
        <RepostPopup
          message={deletingPostMessage.text}
          onClose={() => setDeletingPostMessage(null)}
          isLoading={deletingPostMessage.isLoading}
        />
      )}

      {deletingCommentMessage && (
        <RepostPopup
          message={deletingCommentMessage.text}
          onClose={() => setDeletingCommentMessage(null)}
          isLoading={deletingCommentMessage.isLoading}
        />
      )}

      {reportMessage && (
        <RepostPopup
          message={reportMessage.text}
          onClose={() => setReportMessage(null)}
        />
      )}
      {showStartupModal && (
        <StartupInteractionModal
          onClose={() => {
            setShowStartupModal(false);
            handleModalClose();
            setActiveStartupToggle(null);
          }}
          currentUser={currentUser}
          onStartupSelect={(startup) => {
            handleStartupSelect(startup);
            setShowStartupModal(false);
            handleModalClose();
          }}
          selectedStartup={selectedStartup}
          currentUserProfileImage={currentUserProfileImage}
        />
      )}
      {showReactionsModal && activePostReactions && (
        <ReactionsModal
          onClose={() => {
            setShowReactionsModal(false);
            setIsReactionsModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, reactionsModalScrollPosition);
            }, 0);
          }}
          postId={activePostReactions.postId}
          reactions={activePostReactions.reactions || {}}
          currentUserId={currentUser?.uid}
        />
      )}
      {showAddMemberModal && (
        <AddMembersModal
          onClose={toggleAddMemberModal}
          currentUser={currentUser}
          communityData={communityData}
          onMemberInviteSent={(count) => {
            setInviteCount(count);
            setShowInvitePopup(true);
          }}
        />
      )}
      {showRemovePopup && currentModalType === "moderator" && (
        <RemovePopup
          onClose={() => {
            setShowRemovePopup(false);
            setRemoveMessage(null); // Clear the message when closing
          }}
          count={removeCount}
          type="moderator"
          message={removeMessage?.text}
        />
      )}

      {showInvitePopup && (
        <InvitePopup
          onClose={() => setShowInvitePopup(false)}
          count={inviteCount}
        />
      )}
      {showConnectionsModal && (
        <ConnectionsModal
          onClose={toggleConnectionsModal}
          currentUserId={currentUser?.uid}
          loggedInUserId={currentUser?.uid}
          isOwnProfile={true}
          isModeratorSelection={true} // Enable moderator selection mode
          isEditModerators={false} // We're adding new moderators
          communityData={communityData}
          moderators={moderators}
          onModeratorInviteSent={() => setShowInvitePopup(true)} // Show popup when invite sent
          onModeratorRemoved={() => {
            fetchModerators();
          }}
        />
      )}

      {showEditModeratorsModal && (
        <ConnectionsModal
          onClose={toggleEditModeratorsModal}
          currentUserId={currentUser?.uid}
          loggedInUserId={currentUser?.uid}
          isOwnProfile={true}
          isModeratorSelection={false} // Disable selection mode
          isEditModerators={true} // Enable edit mode
          communityData={communityData}
          moderators={moderators}
          onModeratorRemoved={() => {
            fetchModerators();
          }}
        />
      )}
      {showTagsModal && (
        <TagsModal
          onClose={toggleTagsModal}
          currentUser={currentUser}
          onTagsUpdate={handleCommunityUpdate}
          communityId={communityData.id}
          communityOwnerId={communityData.ownerId}
        />
      )}
      {successPostMessage && (
        <SuccessPostPopup
          message={successPostMessage.text}
          postId={successPostMessage.postId}
          onClose={() => setSuccessPostMessage(null)}
        />
      )}
      {showEditMembersModal && (
        <EditMembersModal
          onClose={() => {
            toggleEditMembersModal();
          }}
          communityData={communityData}
          currentUser={currentUser}
          onMemberRemoved={(updatedMembers, removedCount) => {
            setMembers(updatedMembers);
            setMemberCount(memberCount - removedCount);
            checkAvailableMemberConnections(); // Add this line
          }}
        />
      )}
      {cancelMessage && (
        <CancelPopup
          message={cancelMessage}
          onClose={() => setCancelMessage(null)}
        />
      )}
      {errorPopup && (
        <ErrorPopup
          message={errorPopup.message}
          onClose={() => setErrorPopup(null)}
        />
      )}
      {showEditSingleCommunityModal && (
        <EditSingleCommunityModal
          community={communityData}
          onClose={toggleEditSingleCommunityModal}
          onUpdate={handleCommunityUpdate}
          currentUser={currentUser}
          isFromCommunityPage={true} // Add this prop
        />
      )}

      {showIntroModal && (
        <IntroModal
          onClose={toggleIntroModal}
          currentUser={currentUser}
          isCommunity={true}
          communityData={communityData}
          communityOwnerId={communityData.ownerId}
          onProfileUpdate={(updatedData) => {
            setCommunityData((prev) => ({
              ...prev,
              intro: updatedData.intro,
              introHtml: updatedData.introHtml,
            }));
            toggleIntroModal();
          }}
        />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.profileCard}>
            <div className={classes.profileHeader}>
              <div
                className={classes.profileImage}
                onClick={
                  isBlocked
                    ? undefined
                    : shouldShowContent("editButtons")
                    ? toggleEditSingleCommunityModal
                    : undefined
                }
                style={{
                  cursor: isBlocked
                    ? "default"
                    : shouldShowContent("editButtons")
                    ? "pointer"
                    : "default",
                }}
              >
                <img
                  src={communityData.communityImage || defaultImage}
                  alt="Community"
                />
              </div>
              {shouldShowContent("editButtons") ? (
                <div onClick={toggleEditSingleCommunityModal}>
                  <svg
                    className={classes.closeIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                    />
                  </svg>
                </div>
              ) : (
                // Remove the !isBlocked check here to always show the report button
                <div
                  onClick={() => setShowProfileDropdown(!showProfileDropdown)}
                  className={classes.headerButton}
                  ref={optionsButtonRef}
                >
                  <div
                    className={`${classes.headerIcon} ${
                      showDropdown ? classes.headerIconActive : ""
                    }`}
                  >
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeWidth="3"
                        d="M6 12h.01m6 0h.01m5.99 0h.01"
                      />
                    </svg>
                  </div>
                  {showProfileDropdown && (
                    <div ref={dropdownRef} className={classes.headerDropdown}>
                      <button
                        className={classes.headerButton}
                        onClick={handleReport}
                      >
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                        Report
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className={classes.profileTitle}>
              {communityData.communityName}{" "}
              <span className={classes.connectionType}>• Community</span>
            </div>

            {(communityData.tagline || communityData.link) && (
              <div className={classes.userDetails}>
                {communityData.tagline && (
                  <div className={classes.bio}>{communityData.tagline}</div>
                )}
                {communityData.link && (
                  <div className={classes.link}>
                    <a
                      href={communityData.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {communityData.linkText || communityData.link}
                    </a>
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="orangeRed"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                      />
                    </svg>
                  </div>
                )}
              </div>
            )}

            <div className={classes.buttons}>
              {!isBlocked && !isOwner && (
                <button
                  className={
                    membershipStatus === "Member" ||
                    membershipStatus === "Pending" ||
                    membershipStatus === "Moderator"
                      ? classes.following
                      : classes.headlineButton
                  }
                  onClick={() => {
                    if (membershipStatus === "Member") {
                      handleRemoveMember();
                    } else if (membershipStatus === "Moderator") {
                      handleRemoveModerator();
                    } else {
                      handleJoin();
                    }
                  }}
                >
                  <span>
                    {membershipStatus === "Member" ? (
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : membershipStatus === "Moderator" ? (
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : membershipStatus === "Pending" ? (
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                          clipRule="evenodd"
                        />
                        <path
                          fillRule="evenodd"
                          d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </span>
                  {membershipStatus}
                </button>
              )}

              {!isBlocked &&
                shouldShowContent("editButtons") &&
                !communityData.tagline && (
                  <div
                    className={classes.headlineButton}
                    onClick={toggleEditSingleCommunityModal}
                  >
                    <svg
                      className=""
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 12h14m-7 7V5"
                      />
                    </svg>
                    Add a headline
                  </div>
                )}

              {!isBlocked &&
                shouldShowContent("editButtons") &&
                !communityData.link && (
                  <div
                    className={classes.button}
                    onClick={toggleEditSingleCommunityModal}
                  >
                    <svg
                      className=""
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 12h14m-7 7V5"
                      />
                    </svg>
                    Add a link
                  </div>
                )}

              <div
                className={`${
                  shareButtonText === "Copied"
                    ? classes.following
                    : classes.button
                }`}
                onClick={handleShare}
              >
                {shareButtonText === "Copied" ? (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                  </svg>
                )}
                {shareButtonText}
              </div>
            </div>
          </div>
          {isOwner && (
            <CommunityChecklist
              communityData={communityData}
              isOwner={isOwner}
              classes={classes}
            />
          )}

          {!isBlocked && (
            <>
              {shouldShowContent("intro") && renderDescriptionCard()}
              <div className={classes.card}>
                <div className={classes.cardTitle}>
                  Moderators
                  {isOwner && (
                    <div className={classes.iconContainer}>
                      {connectionsCount > 0 && hasAvailableConnections && (
                        <div onClick={toggleAddModeratorModal}>
                          <svg
                            className={classes.addIcon}
                            width="36"
                            height="36"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="gray"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 12h14m-7 7V5"
                            />
                          </svg>
                        </div>
                      )}
                      {moderators.length > 0 && (
                        <div onClick={toggleEditModeratorModal}>
                          <svg
                            className={classes.experienceIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="gray"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {/* Add the modals */}
                {showAddModeratorModal && (
                  <AddModeratorsModal
                    onClose={(result) => {
                      if (result === "success") {
                        fetchModerators();
                        checkAvailableConnections();
                      }
                      toggleAddModeratorModal();
                    }}
                    communityData={communityData}
                    currentUser={currentUser}
                    onModeratorInviteSent={(count) => {
                      setInviteCount(count);
                      setShowInvitePopup(true);
                    }}
                  />
                )}

                {showEditModeratorModal && (
                  <EditModeratorsModal
                    onClose={(result) => {
                      toggleEditModeratorModal();
                      if (result === "success") {
                        checkAvailableConnections();
                      }
                    }}
                    communityData={communityData}
                    currentUser={currentUser}
                    onModeratorRemoved={(
                      updatedModerators,
                      removedCount,
                      isSelfRemoval
                    ) => {
                      setModerators(updatedModerators);
                      setRemoveCount(removedCount);
                      setCurrentModalType("moderator");
                      setShowRemovePopup(true);

                      if (isSelfRemoval) {
                        setShowEditModeratorModal(false);
                        setIsEditModeratorModalOpen(false);
                        setRemoveMessage({
                          text: "You're no longer a moderator.",
                          isSuccess: true,
                        });

                        // Immediately check membership status and fetch posts if needed
                        checkMembershipStatus(
                          currentUser.uid,
                          communityData.id,
                          communityData.ownerId
                        );

                        // Only fetch posts if they're still a member
                        if (!isCommunityMember) {
                          setCommunityPosts([]); // Clear posts if they're not a member
                        } else {
                          fetchCommunityPosts(); // Refresh posts if they are a member
                        }
                      } else if (updatedModerators.length === 0) {
                        // When owner removes all moderators
                        setShowEditModeratorModal(false);
                        setIsEditModeratorModalOpen(false);
                        checkAvailableConnections();
                      }

                      if (updatedModerators.length === 0) {
                        checkAvailableConnections();
                      }
                    }}
                  />
                )}

                <div className={classes.userContainer}>
                  <img
                    src={
                      communityData.ownerData?.profileImage ||
                      defaultProfileImage
                    }
                    alt={
                      communityData.ownerData
                        ? `${communityData.ownerData.firstName} ${communityData.ownerData.lastName}`
                        : "Owner"
                    }
                    className={classes.userImage}
                    onClick={() =>
                      navigate(`/${communityData.ownerData?.urlSlug}`)
                    }
                    style={{ cursor: "pointer" }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultProfileImage;
                    }}
                  />
                  <div className={classes.userInfo}>
                    <div className={classes.userNameContainer}>
                      <div
                        className={classes.userName}
                        onClick={() =>
                          navigate(`/${communityData.ownerData?.urlSlug}`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {communityData.ownerData?.firstName}{" "}
                        {communityData.ownerData?.lastName}
                      </div>
                      <span className={classes.userConnectionType}>
                        • {connectionTypes[communityData.ownerId] || "Extended"}
                      </span>
                    </div>
                    {communityData.ownerData?.bio && (
                      <div className={classes.userBio}>
                        {communityData.ownerData.bio}
                      </div>
                    )}
                    {communityData.ownerData?.link && (
                      <a
                        href={communityData.ownerData.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.userLink}
                      >
                        <span>
                          {communityData.ownerData.linkText ||
                            communityData.ownerData.link}
                        </span>
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </a>
                    )}
                    <div className={classes.userBadge}>
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12 2c-.791 0-1.55.314-2.11.874l-.893.893a.985.985 0 0 1-.696.288H7.04A2.984 2.984 0 0 0 4.055 7.04v1.262a.986.986 0 0 1-.288.696l-.893.893a2.984 2.984 0 0 0 0 4.22l.893.893a.985.985 0 0 1 .288.696v1.262a2.984 2.984 0 0 0 2.984 2.984h1.262c.261 0 .512.104.696.288l.893.893a2.984 2.984 0 0 0 4.22 0l.893-.893a.985.985 0 0 1 .696-.288h1.262a2.984 2.984 0 0 0 2.984-2.984V15.7c0-.261.104-.512.288-.696l.893-.893a2.984 2.984 0 0 0 0-4.22l-.893-.893a.985.985 0 0 1-.288-.696V7.04a2.984 2.984 0 0 0-2.984-2.984h-1.262a.985.985 0 0 1-.696-.288l-.893-.893A2.984 2.984 0 0 0 12 2Zm3.683 7.73a1 1 0 1 0-1.414-1.413l-4.253 4.253-1.277-1.277a1 1 0 0 0-1.415 1.414l1.985 1.984a1 1 0 0 0 1.414 0l4.96-4.96Z"
                          clip-rule="evenodd"
                        />
                      </svg>

                      <span className={classes.skillText}>Owner</span>
                    </div>
                  </div>
                </div>

                {moderators
                  .slice(0, visibleModeratorsCount)
                  .map((moderator, index) => (
                    <div key={moderator.id} className={classes.userContainer}>
                      <img
                        src={
                          moderator.userData.profileImage || defaultProfileImage
                        }
                        alt={`${moderator.userData.firstName} ${moderator.userData.lastName}`}
                        className={classes.userImage}
                        onClick={() =>
                          navigate(`/${moderator.userData.urlSlug}`)
                        }
                        style={{ cursor: "pointer" }}
                      />
                      <div className={classes.userInfo}>
                        <div className={classes.userNameContainer}>
                          <div
                            className={classes.userName}
                            onClick={() =>
                              navigate(`/${moderator.userData.urlSlug}`)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {moderator.userData.firstName}{" "}
                            {moderator.userData.lastName}
                          </div>
                          <span className={classes.userConnectionType}>
                            •{" "}
                            {connectionTypes[moderator.userUUID] || "Extended"}
                          </span>
                        </div>
                        {moderator.userData.bio && (
                          <div className={classes.userBio}>
                            {moderator.userData.bio}
                          </div>
                        )}
                        {moderator.userData.link && (
                          <a
                            href={moderator.userData.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.userLink}
                          >
                            <span>
                              {moderator.userData.linkText ||
                                moderator.userData.link}
                            </span>
                            <svg
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                              />
                            </svg>
                          </a>
                        )}
                      </div>
                    </div>
                  ))}

                {moderators.length > 3 && (
                  <div
                    className={classes.showAllPosts}
                    onClick={() => {
                      if (visibleModeratorsCount >= moderators.length) {
                        // Reset to initial count of 3
                        setVisibleModeratorsCount(3);
                      } else {
                        // Take what's visible beyond the initial 3, double it, and add to total
                        const beyondInitial = visibleModeratorsCount - 3;
                        setVisibleModeratorsCount(
                          Math.min(
                            visibleModeratorsCount + (beyondInitial || 3) * 2,
                            moderators.length
                          )
                        );
                      }
                    }}
                  >
                    {visibleModeratorsCount >= moderators.length
                      ? "Show less"
                      : "Show more"}
                    {visibleModeratorsCount >= moderators.length ? (
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m5 15 7-7 7 7"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 9-7 7-7-7"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </div>
              {(members.length > 0 ||
                isOwner ||
                moderators.some((mod) => mod.id === currentUser?.uid)) && (
                <div className={classes.card}>
                  <div
                    className={`${classes.cardTitle} ${
                      members.length === 0 ? classes.emptyCardTitle : ""
                    }`}
                  >
                    Members
                    {(isOwner ||
                      moderators.some(
                        (mod) => mod.id === currentUser?.uid
                      )) && (
                      <div>
                        {/* Only show add icon if they have connections that could be members */}
                        {connectionsCount > 0 &&
                          hasAvailableMemberConnections && (
                            <div onClick={toggleAddMemberModal}>
                              <svg
                                className={classes.addIcon}
                                width="36"
                                height="36"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="gray"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 12h14m-7 7V5"
                                />
                              </svg>
                            </div>
                          )}

                        {/* Keep showing edit icon when there are members */}
                        {(isOwner ||
                          moderators.some(
                            (mod) => mod.id === currentUser?.uid
                          )) &&
                          members.length > 0 && (
                            <div onClick={toggleEditMembersModal}>
                              <svg
                                className={classes.experienceIcon}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="gray"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                                />
                              </svg>
                            </div>
                          )}
                      </div>
                    )}
                  </div>

                  {members.length === 0 ? (
                    <>
                      <div>
                        <p className={classes.defaultText}>
                          {connectionsCount === 0
                            ? "You can only invite your connections to become members of this community. Connect with like-minded people in the startup community and build meaningful relationships."
                            : "Spread the word about your community and invite your connections to be members. Help grow your community and make ideas happen."}
                        </p>
                      </div>
                      {connectionsCount === 0 && (
                        <div className={classes.people}>
                          <div
                            className={classes.button}
                            style={{ width: "fit-content" }}
                            onClick={() => navigate("/people")}
                          >
                            <svg
                              className="w-6 h-6"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                                clipRule="evenodd"
                              />
                            </svg>
                            People
                          </div>
                        </div>
                      )}
                      <div className={classes.noPostBorder}></div>
                      {/* Preview of what a member looks like */}
                      <div className={classes.userContainer}>
                        <img
                          src={greyProfileImage}
                          alt="Preview member"
                          className={classes.userImage}
                        />
                        <div className={classes.userInfo}>
                          <div className={classes.userNameContainer}>
                            <div className={classes.noStartupRole}>
                              Username
                            </div>
                            <span className={classes.userConnectionType}>
                              • Direct
                            </span>
                          </div>
                          <div className={classes.noStartupName}>Headline</div>
                          <div className={classes.noUserLink}>
                            Link
                            <svg
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {members
                        .slice(0, visibleMembersCount)
                        .map((member, index) => (
                          <div
                            key={member.id}
                            className={classes.userContainer}
                          >
                            <img
                              src={
                                member.userData.profileImage ||
                                defaultProfileImage
                              }
                              alt={`${member.userData.firstName} ${member.userData.lastName}`}
                              className={classes.userImage}
                              onClick={() =>
                                navigate(`/${member.userData.urlSlug}`)
                              }
                              style={{ cursor: "pointer" }}
                            />
                            <div className={classes.userInfo}>
                              <div className={classes.userNameContainer}>
                                <div
                                  className={classes.userName}
                                  onClick={() =>
                                    navigate(`/${member.userData.urlSlug}`)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {member.userData.firstName}{" "}
                                  {member.userData.lastName}
                                </div>
                                <span className={classes.userConnectionType}>
                                  •{" "}
                                  {connectionTypes[member.userUUID] ||
                                    "Extended"}
                                </span>
                              </div>
                              {member.userData.bio && (
                                <div className={classes.userBio}>
                                  {member.userData.bio}
                                </div>
                              )}
                              {member.userData.link && (
                                <a
                                  href={member.userData.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={classes.userLink}
                                >
                                  <span>
                                    {member.userData.linkText ||
                                      member.userData.link}
                                  </span>
                                  <svg
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                    />
                                  </svg>
                                </a>
                              )}
                            </div>
                          </div>
                        ))}

                      {members.length > 3 && (
                        <div
                          className={classes.showAllPosts}
                          onClick={() => {
                            if (visibleMembersCount >= members.length) {
                              // Reset to initial count of 3
                              setVisibleMembersCount(3);
                            } else {
                              // Take what's visible beyond the initial 3, double it, and add to total
                              const beyondInitial = visibleMembersCount - 3;
                              setVisibleMembersCount(
                                Math.min(
                                  visibleMembersCount +
                                    (beyondInitial || 3) * 2,
                                  members.length
                                )
                              );
                            }
                          }}
                        >
                          {visibleMembersCount >= members.length
                            ? "Show less"
                            : "Show more"}
                          {visibleMembersCount >= members.length ? (
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m5 15 7-7 7 7"
                              />
                            </svg>
                          ) : (
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m19 9-7 7-7-7"
                              />
                            </svg>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              {/* Add Post Card */}
              {/* Replace your existing topCard div with this one */}
              {shouldShowContent("topCard") && (
                <div className={classes.topCard}>
                  <div className={classes.postContent}>
                    {showInteractionToggle ? (
                      <MemoizedToggle
                        currentUser={currentUser}
                        selectedStartup={selectedStartup}
                        onStartupSelect={handleStartupSelect}
                        currentUserProfileImage={currentUserProfileImage}
                        onModalOpen={() => handleModalOpen("top")}
                        onModalClose={handleModalClose}
                        className={classes.interactionToggle}
                        userStartups={userStartups}
                        toggleId="top"
                      />
                    ) : (
                      <img
                        src={currentUserProfileImage || defaultImage}
                        alt="Profile"
                        className={classes.topCardUserImage}
                        onClick={() =>
                          currentUser?.urlSlug &&
                          navigate(`/${currentUser.urlSlug}`)
                        }
                        style={{
                          cursor: currentUser?.urlSlug ? "pointer" : "default",
                        }}
                      />
                    )}
                    <div className={classes.postButton} onClick={handleAddPost}>
                      Share something...
                    </div>
                  </div>
                </div>
              )}
              {showPostModal && (
                <PostModal
                  onClose={handleClosePostModal}
                  onManualClose={() => {
                    setShowPostModal(false);
                    setIsPostModalOpen(false);
                    setTimeout(() => {
                      window.scrollTo(0, postModalScrollPosition);
                    }, 0);
                  }}
                  currentUser={currentUser}
                  selectedStartup={selectedStartup}
                  communityId={communityData.id}
                  communityOwnerId={communityData.ownerId}
                  isCommunityPost={true} // Add this prop
                />
              )}
              {/* This is for editing existing posts */}
              {showEditModal && editingPost && (
                <PostModal
                  onClose={() => {
                    setShowEditModal(false);
                    setEditingPost(null);
                    setIsPostModalOpen(false);
                    setTimeout(() => {
                      window.scrollTo(0, postModalScrollPosition);
                    }, 0);
                  }}
                  currentUser={currentUser}
                  initialPost={editingPost}
                  onEditComplete={handleEditComplete}
                  selectedStartup={selectedStartup}
                  communityId={communityData.id}
                  communityOwnerId={communityData.ownerId}
                />
              )}
              {Object.entries(state.uploadingPosts).map(
                ([postId, postData]) =>
                  !state.cancelledPosts[postId] && (
                    <UploadingPost
                      key={postId}
                      postId={postId}
                      progress={postData.progress}
                      status={postData.status}
                      onCancelUpload={handleCancelUpload}
                    />
                  )
              )}
              {/* Posts List */}
              {shouldShowContent("posts") ? (
                <>
                  {communityPosts && communityPosts.length > 0 ? (
                    <>
                      {getSortedPosts()
                        .slice(0, visiblePostsCount)
                        .map((post) => {
                          if (!post || !post.user) return null;
                          const isHighlighted =
                            post.id === highlightedPostId ||
                            post.communityPostId === highlightedPostId;
                          return (
                            <div
                              key={post.id}
                              ref={(el) => {
                                postRefs.current[post.id] = el;
                                if (post.communityPostId) {
                                  postRefs.current[post.communityPostId] = el;
                                }
                              }}
                              className={`${classes.postCard} ${
                                isHighlighted ? classes.highlightedPost : ""
                              } ${getMediaClass(post)}`}
                            >
                              <div className={classes.postHeader}>
                                <div className={classes.postUserInfo}>
                                  <img
                                    className={classes.postImage}
                                    src={
                                      post.user?.profileImage ||
                                      defaultProfileImage
                                    }
                                    alt=""
                                    onClick={() =>
                                      handleNavigate(
                                        post.user.id,
                                        post.user.isStartup,
                                        post.user.startupUrlSlug
                                      )
                                    }
                                    style={{
                                      borderRadius: post.user.isStartup
                                        ? 0
                                        : "50%",
                                      cursor: "pointer",
                                    }}
                                  />
                                  <div className={classes.postUserDetails}>
                                    <div
                                      className={classes.postUserNameContainer}
                                    >
                                      <p
                                        className={classes.postUserName}
                                        onClick={() =>
                                          handleNavigate(
                                            post.user.id,
                                            post.user.isStartup,
                                            post.user.startupUrlSlug
                                          )
                                        }
                                      >
                                        <span className={classes.nameText}>
                                          {post.user.firstName}{" "}
                                          {post.user.lastName}
                                        </span>
                                      </p>
                                      <span
                                        className={classes.postConnectionType}
                                      >
                                        •{" "}
                                        {post.user.isStartup
                                          ? "Startup"
                                          : post.user.connectionType}
                                      </span>
                                    </div>
                                    {post.user.bio && (
                                      <p className={classes.postBio}>
                                        {post.user.bio}
                                      </p>
                                    )}
                                    {post.user.link && (
                                      <div
                                        className={classes.userLinkContainer}
                                      >
                                        <a
                                          href={post.user.link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          onClick={(e) => e.stopPropagation()}
                                          className={classes.postUserLink}
                                        >
                                          <span
                                            className={classes.postUserLinkText}
                                          >
                                            {post.user.linkText ||
                                              post.user.link}
                                          </span>
                                          <svg
                                            className={classes.postUserLinkIcon}
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              stroke="orangered"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                            />
                                          </svg>
                                        </a>
                                      </div>
                                    )}
                                    <div className={classes.timeAndEditDetails}>
                                      {post.createdAt && (
                                        <p className={classes.time}>
                                          {formatTimestamp(post.createdAt)}
                                        </p>
                                      )}
                                      {post.isEdited && (
                                        <div className={classes.editDot}>
                                          •
                                          <span
                                            className={classes.editedPostText}
                                          >
                                            Edited
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className={classes.postOptions}>
                                  <svg
                                    className={`${classes.optionsIcon} ${
                                      showDropdown[post.id]
                                        ? classes.optionsIconActive
                                        : ""
                                    }`}
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    onClick={() =>
                                      setShowDropdown((prev) => ({
                                        ...prev,
                                        [post.id]: !prev[post.id],
                                      }))
                                    }
                                  >
                                    <path
                                      stroke="gray"
                                      strokeLinecap="round"
                                      strokeWidth="3"
                                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                                    />
                                  </svg>
                                  {showDropdown[post.id] &&
                                    renderPostDropdown(post)}
                                </div>
                              </div>

                              {post.text && renderPostText(post)}
                              {renderPostContent(post)}
                              {post.communityReactions &&
                                Object.keys(post.communityReactions).length >
                                  0 && (
                                  <div
                                    className={`${
                                      classes.postInteractionsInfo
                                    } ${
                                      post.content?.type === "video" ||
                                      post.content?.type === "image" ||
                                      (post.content?.type === "image" &&
                                        Array.isArray(post.content.url))
                                        ? classes.hasMediaContent
                                        : ""
                                    }`}
                                    onClick={() => {
                                      if (post && post.communityReactions) {
                                        setReactionsModalScrollPosition(
                                          window.scrollY
                                        );
                                        setActivePostReactions({
                                          postId: post.id,
                                          reactions: post.communityReactions,
                                        });
                                        setShowReactionsModal(true);
                                        setIsReactionsModalOpen(true);
                                      }
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    See who boosted{" "}
                                    <svg
                                      className="w-6 h-6 text-gray-800 dark:text-white"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M8 20V7m0 13-4-4m4 4 4-4m4-12v13m0-13 4 4m-4-4-4 4"
                                      />
                                    </svg>
                                  </div>
                                )}
                              {/* Replace the existing postInteractions div with this updated version */}
                              <div className={classes.postInteractions}>
                                {showInteractionToggle ? (
                                  <MemoizedToggle
                                    currentUser={currentUser}
                                    selectedStartup={selectedStartup}
                                    onStartupSelect={handleStartupSelect}
                                    currentUserProfileImage={
                                      currentUserProfileImage
                                    }
                                    onModalOpen={() => handleModalOpen("post")}
                                    onModalClose={handleModalClose}
                                    className={classes.interactionToggle}
                                    userStartups={userStartups}
                                    toggleId="post"
                                  />
                                ) : (
                                  <img
                                    src={
                                      currentUserProfileImage || defaultImage
                                    }
                                    alt="Profile"
                                    className={classes.postInteractionUserImage}
                                    onClick={() =>
                                      currentUser?.urlSlug &&
                                      navigate(`/${currentUser.urlSlug}`)
                                    }
                                    style={{
                                      cursor: currentUser?.urlSlug
                                        ? "pointer"
                                        : "default",
                                    }}
                                  />
                                )}

                                <button
                                  type="button"
                                  className={`${classes.interactionButton} ${
                                    (selectedStartup &&
                                      post.communityReactions?.[
                                        `startup_${selectedStartup.id}`
                                      ]) ||
                                    (!selectedStartup &&
                                      post.communityReactions?.[
                                        currentUser?.uid
                                      ])
                                      ? classes.activeInteraction
                                      : ""
                                  }`}
                                  onClick={(e) =>
                                    handleInteraction(e, "boost", post)
                                  }
                                >
                                  <svg
                                    className="w-6 h-6 text-gray-800 dark:text-white"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  Boost{" "}
                                  {post.communityReactions &&
                                    Object.keys(post.communityReactions)
                                      .length > 0 &&
                                    `(${
                                      Object.keys(post.communityReactions)
                                        .length
                                    })`}
                                </button>

                                <button
                                  className={`${classes.interactionButton} ${
                                    (selectedStartup &&
                                      post.comments?.[
                                        `startup_${selectedStartup.id}`
                                      ]) ||
                                    (!selectedStartup &&
                                      post.comments?.[currentUser?.uid])
                                      ? classes.activeInteraction
                                      : ""
                                  }`}
                                  onClick={(e) =>
                                    handleInteraction(e, "comment", post)
                                  }
                                >
                                  <svg
                                    className="w-6 h-6 text-gray-800 dark:text-white"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-6.616l-2.88 2.592C8.537 20.461 7 19.776 7 18.477V17H5a2 2 0 0 1-2-2V6Zm4 2a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2H7Zm8 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Zm-8 3a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H7Zm5 0a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  Comment
                                  {getTotalCommentsCount(post.id) > 0
                                    ? ` (${getTotalCommentsCount(post.id)})`
                                    : ""}
                                </button>

                                <button
                                  className={classes.interactionButton}
                                  onClick={(e) =>
                                    handleInteraction(e, "share", post)
                                  }
                                >
                                  {shareStates[post.id] === "Copied" ? (
                                    <svg
                                      className="w-6 h-6"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                                        clipRule="evenodd"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      className="w-6 h-6 text-gray-800 dark:text-white"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                                    </svg>
                                  )}
                                  {shareStates[post.id] || "Share"}
                                </button>
                              </div>

                              {showComments[post.id] && (
                                <div className={classes.commentsSection}>
                                  <CommentInput
                                    postId={post.id}
                                    onSubmit={handleAddComment}
                                    currentUser={currentUser}
                                    selectedStartup={selectedStartup}
                                    currentUserProfileImage={
                                      currentUserProfileImage
                                    }
                                  />
                                  <div className={classes.commentsList}>
                                    {comments[post.id]
                                      ?.filter(
                                        (comment) => comment.isRootComment
                                      )
                                      ?.slice(0, visibleComments[post.id] || 3)
                                      .map((comment) => (
                                        <div
                                          key={comment.id}
                                          className={classes.commentItem}
                                        >
                                          <div
                                            className={classes.commentHeader}
                                          >
                                            <div
                                              className={
                                                classes.commentUserInfo
                                              }
                                            >
                                              <img
                                                src={
                                                  comment.userImage ||
                                                  defaultProfileImage
                                                }
                                                alt={comment.userName}
                                                className={
                                                  classes.commentUserImage
                                                }
                                                onClick={() =>
                                                  handleNavigate(
                                                    comment.userId,
                                                    comment.isStartup,
                                                    comment.startupUrlSlug
                                                  )
                                                }
                                                style={{
                                                  borderRadius:
                                                    comment.isStartup
                                                      ? 0
                                                      : "50%",
                                                  cursor: "pointer",
                                                }}
                                              />
                                              <div
                                                className={
                                                  classes.commentUserDetails
                                                }
                                              >
                                                <div
                                                  className={
                                                    classes.commentUserNameContainer
                                                  }
                                                >
                                                  <span
                                                    className={
                                                      classes.commentUserName
                                                    }
                                                    onClick={() =>
                                                      handleNavigate(
                                                        comment.userId,
                                                        comment.isStartup,
                                                        comment.startupUrlSlug
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {comment.userName}
                                                  </span>
                                                  <span
                                                    className={
                                                      classes.commentConnectionType
                                                    }
                                                  >
                                                    • {comment.connectionType}
                                                  </span>
                                                </div>
                                                {comment.bio && (
                                                  <p
                                                    className={
                                                      classes.commentUserBio
                                                    }
                                                  >
                                                    {comment.bio}
                                                  </p>
                                                )}
                                                {comment.link && (
                                                  <div
                                                    className={
                                                      classes.commentUserLinkContainer
                                                    }
                                                  >
                                                    <a
                                                      href={comment.link}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      onClick={(e) =>
                                                        e.stopPropagation()
                                                      }
                                                      className={
                                                        classes.commentUserLink
                                                      }
                                                    >
                                                      <span
                                                        className={
                                                          classes.commentUserLinkText
                                                        }
                                                      >
                                                        {comment.linkText ||
                                                          comment.link}
                                                      </span>
                                                      <svg
                                                        className={
                                                          classes.commentUserLinkIcon
                                                        }
                                                        aria-hidden="true"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                      >
                                                        <path
                                                          stroke="orangered"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2"
                                                          d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                                        />
                                                      </svg>
                                                    </a>
                                                  </div>
                                                )}
                                                <div
                                                  className={
                                                    classes.commentTimeAndEditDetails
                                                  }
                                                >
                                                  <p
                                                    className={
                                                      classes.commentTimestamp
                                                    }
                                                  >
                                                    {formatTimestamp(
                                                      comment.createdAt
                                                    )}
                                                  </p>
                                                  {comment.edited && (
                                                    <div
                                                      className={
                                                        classes.commentEditDot
                                                      }
                                                    >
                                                      •{" "}
                                                      <span
                                                        className={
                                                          classes.commentEditText
                                                        }
                                                      >
                                                        Edited
                                                      </span>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className={classes.commentOptions}
                                            >
                                              <svg
                                                className={`${
                                                  classes.commentOptionsIcon
                                                } ${
                                                  openCommentDropdown ===
                                                  `${post.id}_${comment.id}`
                                                    ? classes.commentOptionsIconActive
                                                    : ""
                                                }`}
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="40"
                                                height="40"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                onClick={() =>
                                                  toggleCommentDropdown(
                                                    post.id,
                                                    comment.id
                                                  )
                                                }
                                              >
                                                <path
                                                  stroke="gray"
                                                  strokeLinecap="round"
                                                  strokeWidth="3"
                                                  d="M6 12h.01m6 0h.01m5.99 0h.01"
                                                />
                                              </svg>
                                              {openCommentDropdown ===
                                                `${post.id}_${comment.id}` &&
                                                renderCommentOptions(
                                                  post.id,
                                                  comment
                                                )}
                                            </div>
                                          </div>
                                          <div
                                            className={classes.commentContent}
                                            onClick={handleLinkClick}
                                          >
                                            {renderCommentText(comment)}{" "}
                                          </div>
                                          {!editingCommentId && (
                                            <div
                                              className={classes.commentActions}
                                            >
                                              <div
                                                className={`${
                                                  classes.reactButton
                                                } ${
                                                  (selectedStartup &&
                                                    comment.reactions?.[
                                                      `startup_${selectedStartup.id}`
                                                    ]) ||
                                                  (!selectedStartup &&
                                                    comment.reactions?.[
                                                      currentUser?.uid
                                                    ])
                                                    ? classes.activeReaction
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  handleCommentReactionClick(
                                                    post.id,
                                                    comment.id
                                                  )
                                                }
                                              >
                                                Boost{" "}
                                                {comment.reactions &&
                                                  Object.keys(comment.reactions)
                                                    .length > 0 &&
                                                  `(${
                                                    Object.keys(
                                                      comment.reactions
                                                    ).length
                                                  })`}
                                              </div>

                                              <div className={classes.dot}>
                                                {" "}
                                                •{" "}
                                              </div>
                                              <button
                                                className={classes.replyButton}
                                                onClick={() =>
                                                  handleReplyButton(
                                                    post.id,
                                                    comment.id
                                                  )
                                                }
                                              >
                                                <span
                                                  className={classes.replyText}
                                                >
                                                  Reply{" "}
                                                  {comments[post.id]?.filter(
                                                    (reply) =>
                                                      reply.rootCommentId ===
                                                        comment.id &&
                                                      !reply.isRootComment
                                                  ).length > 0 &&
                                                    `(${
                                                      comments[post.id].filter(
                                                        (reply) =>
                                                          reply.rootCommentId ===
                                                            comment.id &&
                                                          !reply.isRootComment
                                                      ).length
                                                    })`}
                                                </span>
                                              </button>
                                            </div>
                                          )}

                                          {renderReplies(post.id, comment.id)}
                                          {activeCommentId[post.id] ===
                                            comment.id && (
                                            <ReplyInput
                                              postId={post.id}
                                              commentId={comment.id}
                                              onSubmit={handleAddReply}
                                            />
                                          )}
                                        </div>
                                      ))}
                                    {comments[post.id] &&
                                      comments[post.id].filter(
                                        (comment) => comment.isRootComment
                                      ).length >
                                        (visibleComments[post.id] || 3) && (
                                        <div
                                          className={
                                            classes.loadMoreCommentsButton
                                          }
                                          onClick={() =>
                                            handleLoadMoreComments(post.id)
                                          }
                                        >
                                          Show more comments (
                                          {comments[post.id].filter(
                                            (comment) => comment.isRootComment
                                          ).length -
                                            (visibleComments[post.id] || 3)}
                                          )
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}

                      {communityPosts.length > 3 && (
                        <button
                          className={`${classes.miscButton} ${classes.miscButton}`}
                          onClick={() => {
                            if (visiblePostsCount >= communityPosts.length) {
                              // Reset to initial state
                              setVisiblePostsCount(3);
                              setHasReachedExpandedView(false);
                            } else if (!hasReachedExpandedView) {
                              // First click: Show 9 posts (initial 3 + 6 more)
                              if (visiblePostsCount === 3) {
                                setVisiblePostsCount(9);
                              }
                              // Second click: Show 27 posts (previous 9 + 18 more)
                              else if (visiblePostsCount === 9) {
                                setVisiblePostsCount(27);
                                setHasReachedExpandedView(true);
                              }
                            } else {
                              // After reaching expanded view, consistently add 18 more
                              setVisiblePostsCount(
                                Math.min(
                                  visiblePostsCount + 18,
                                  communityPosts.length
                                )
                              );
                            }
                          }}
                        >
                          {visiblePostsCount >= communityPosts.length ? (
                            <>
                              Show less
                              <svg
                                className="w-6 h-6 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="m5 15 7-7 7 7"
                                />
                              </svg>
                            </>
                          ) : (
                            <>
                              Show more
                              <svg
                                className="w-6 h-6 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="m19 9-7 7-7-7"
                                />
                              </svg>
                            </>
                          )}
                        </button>
                      )}
                    </>
                  ) : null}
                </>
              ) : (
                <div className={classes.profileCard}>
                  <div
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "900",
                      marginBottom: "1rem",
                    }}
                  >
                    Members-Only Content
                  </div>

                  <div
                    style={{
                      color: "gray",
                      marginBottom: "1rem",
                    }}
                  >
                    To access this community's content and engage with other
                    members, you'll need to join first. As a member, you can:
                  </div>

                  <div
                    style={{
                      backgroundColor: "rgba(255, 69, 0, 0.1)",

                      padding: "1rem",

                      borderRadius: "0.5rem",

                      width: "100%",

                      boxSizing: "border-box",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      {[
                        "View and engage with community posts",

                        "Share your own updates and insights",

                        "Connect with community members",
                      ].map((benefit, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",

                            alignItems: "center",

                            marginBottom: index !== 2 ? "0.5rem" : 0,
                          }}
                        >
                          <svg
                            className={classes.check}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="orangered"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                              clip-rule="evenodd"
                            />
                          </svg>

                          <span
                            style={{ color: "orangered", fontWeight: "900" }}
                          >
                            {benefit}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {!isBlocked && renderBadgesCard()}
        </div>

        {/* Right Cards */}
        <div className={classes.rightCards}>
          {!isBlocked && (
            <>
              {shouldShowContent("tags") && (
                <div className={classes.card}>
                  <div
                    className={`${classes.cardTitle} ${
                      !communityData.tags || communityData.tags.length === 0
                        ? classes.emptyCardTitle
                        : ""
                    }`}
                  >
                    Tags
                    {isOwner && (
                      <div onClick={toggleTagsModal}>
                        <svg
                          className={classes.closeIcon}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="gray"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className={classes.cardAbout}>
                    {communityData.tags && communityData.tags.length > 0
                      ? communityData.tags
                          .slice(0, visibleTagsCount)
                          .map((tag, index) => (
                            <div key={index} className={classes.skillContainer}>
                              <svg
                                className="w-6 h-6 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                              </svg>
                              <span className={classes.skillText}>{tag}</span>
                            </div>
                          ))
                      : isOwner && (
                          <div className={classes.emptyContainer}>
                            <div className={classes.defaultText}>
                              Add tags to help people find and understand your
                              community.
                            </div>
                            <div className={classes.defaultContainer}>
                              <div className={classes.emptySkillContainer}>
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                                </svg>
                                <span className={classes.skillText}>Tag</span>
                              </div>
                            </div>
                          </div>
                        )}
                  </div>
                  {communityData.tags && communityData.tags.length > 9 && (
                    <div
                      className={classes.showAllPosts}
                      onClick={() => {
                        if (visibleTagsCount >= communityData.tags.length) {
                          // Reset to initial count of 9
                          setVisibleTagsCount(9);
                        } else {
                          // Take what's visible beyond the initial 9, double it, and add to total
                          const beyondInitial = visibleTagsCount - 9;
                          setVisibleTagsCount(
                            Math.min(
                              visibleTagsCount + (beyondInitial || 9) * 2,
                              communityData.tags.length
                            )
                          );
                        }
                      }}
                    >
                      {visibleTagsCount >= communityData.tags.length
                        ? "Show less"
                        : "Show more"}
                      {visibleTagsCount >= communityData.tags.length ? (
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m5 15 7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 9-7 7-7-7"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
};

export default Community;
