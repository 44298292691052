import React, { useState, useEffect, useRef } from "react";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./SkillsModal.module.css";
import SkillsDropdown from "../Dropdowns/SkillsDropdown";

const SkillsModal = ({ onClose, currentUser, onProfileUpdate }) => {
  const [skills, setSkills] = useState([]);
  const [initialSkills, setInitialSkills] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isChanged, setIsChanged] = useState(false);
  const formRef = useRef(null);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);

  useEffect(() => {
    const fetchUserSkills = async () => {
      setInitialLoading(true);
      try {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && userDoc.data().skills) {
          const skillsData = userDoc
            .data()
            .skills.map((skill) => ({ name: skill, id: skill }));
          setSkills(skillsData);
          setInitialSkills(skillsData);
        }
      } catch (error) {
      } finally {
        setInitialLoading(false);
      }
    };

    fetchUserSkills();
  }, [currentUser.uid]);

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      // Don't scroll here, let Profile.js handle it
    };
  }, []);

  useEffect(() => {
    const dataChanged =
      JSON.stringify(skills) !== JSON.stringify(initialSkills);
    setIsChanged(dataChanged);
  }, [skills, initialSkills]);

  const handleSkillsChange = (newSkills) => {
    setSkills(newSkills);
    setIsChanged(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const userDocRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.data();

      const updatedUserData = {
        ...userData,
        skills: skills.map((skill) => skill.name),
      };

      await updateDoc(userDocRef, updatedUserData);
      onProfileUpdate(updatedUserData);
      // Don't call onClose here
    } catch (error) {
      setError("Failed to update skills. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseTouch = (e, isSave = false) => {
    e.preventDefault();
    onClose(isSave);
  };

  const handleSaveButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true })
      );
      // Only close the modal if there are no errors
      if (!error) {
        onClose(true);
      }
    }
  };

  const handleInputChange = (inputValue) => {
    const currentSkills = inputValue
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s);
    const newSkills = currentSkills.map((name) => ({ name, id: name }));
    setIsChanged(JSON.stringify(newSkills) !== JSON.stringify(initialSkills));
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose(false); // Pass false for regular close
    }
    setMouseDownOnBackdrop(false);
  };

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Skills ({skills.length})
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
        <div className={classes.scrollableContent}>
          {initialLoading ? (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          ) : (
            <form
              className={classes.interactions}
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <div
                className={classes.skillsDropdownWrapper}
                style={{ opacity: loading ? 0.5 : 1 }}
              >
                <SkillsDropdown
                  selectedSkills={skills}
                  onSkillsChange={handleSkillsChange}
                  onInputChange={handleInputChange}
                  disabled={loading}
                  placeholder="Ex: Python, Machine Learning, UX Research"
                  customClassName={classes.maxHeight}
                />
              </div>
            </form>
          )}
          {error && <p className={classes.error}>{error}</p>}
        </div>

        {windowHeight <= 991 && (
          <div className={classes.cardFooter}>
            <div className={classes.smallPrint}>
              <svg
                className={classes.infoIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                  clip-rule="evenodd"
                />
              </svg>

              <p>Use commas to seperate skills</p>
            </div>
            <button
              type="button"
              className={`${classes.button} ${loading ? classes.loading : ""}`}
              disabled={!isChanged || loading || initialLoading}
              onClick={() => {
                handleSaveButtonClick();
                // Only close the modal if there are no errors
                if (!error) {
                  onClose(true);
                }
              }}
            >
              {loading ? (
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              ) : (
                "Save"
              )}
            </button>
          </div>
        )}

        {windowHeight > 991 && (
          <div className={classes.cardFooter}>
            <button
              type="button"
              className={`${classes.button} ${loading ? classes.loading : ""}`}
              disabled={!isChanged || loading || initialLoading}
              onClick={() => {
                handleSaveButtonClick();
                // Only close the modal if there are no errors
                if (!error) {
                  onClose(true);
                }
              }}
            >
              {loading ? (
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              ) : (
                "Save"
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SkillsModal;
