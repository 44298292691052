import React, { useState, useEffect } from "react";
import { firestore } from "../../firebase";
import defaultImage from "../../assets/icons/profileImage.jpg";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  writeBatch,
  deleteDoc,
} from "firebase/firestore";
import classes from "./EditModeratorsModal.module.css";

const EditModeratorsModal = ({
  onClose,
  communityData,
  currentUser,
  onModeratorRemoved,
}) => {
  const [loading, setLoading] = useState(true);
  const [moderators, setModerators] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [removingUsers, setRemovingUsers] = useState(false);
  const [totalModerators, setTotalModerators] = useState(0);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    const scrollY = window.scrollY;

    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      window.scrollTo(0, scrollY);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const fetchModerators = async () => {
      if (!communityData.id || !communityData.ownerId) return;

      try {
        setLoading(true);
        const moderatorsRef = collection(
          firestore,
          `users/${communityData.ownerId}/communities/${communityData.id}/moderators`
        );
        const q = query(moderatorsRef, where("status", "==", "active"));
        const querySnapshot = await getDocs(q);

        const moderatorsData = await Promise.all(
          querySnapshot.docs.map(async (moderatorDoc) => {
            // Only fetch full data if this is the owner OR if this is the current user's moderator doc
            if (
              currentUser.uid === communityData.ownerId ||
              moderatorDoc.id === currentUser.uid
            ) {
              const userDoc = await getDoc(
                doc(firestore, "users", moderatorDoc.id)
              );
              if (userDoc.exists()) {
                const userData = userDoc.data();
                const connectionType = await checkConnectionType(
                  currentUser.uid,
                  moderatorDoc.id
                );

                // Make sure we return the full user data structure
                return {
                  id: moderatorDoc.id,
                  firstName: userData.firstName || "",
                  lastName: userData.lastName || "",
                  profileImage: userData.profileImage || "",
                  bio: userData.bio || "",
                  urlSlug: userData.urlSlug || "",
                  link: userData.link || null,
                  linkText: userData.linkText || null,
                  connectionType:
                    moderatorDoc.id === currentUser.uid
                      ? "You"
                      : connectionType,
                  acceptedAt: moderatorDoc.data().acceptedAt,
                  userUUID: moderatorDoc.id,
                };
              }
            }
            return null;
          })
        );

        const validModerators = moderatorsData
          .filter(Boolean)
          .sort((a, b) => b.acceptedAt - a.acceptedAt);

        // Only show all moderators to owner, otherwise just show the current user
        const visibleModerators =
          currentUser.uid === communityData.ownerId
            ? validModerators
            : validModerators.filter((mod) => mod.id === currentUser.uid);

        setModerators(visibleModerators);
        setTotalModerators(visibleModerators.length);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchModerators();
  }, [communityData.id, communityData.ownerId, currentUser.uid]);

  const checkConnectionType = async (currentUserId, targetUserId) => {
    if (!currentUserId || !targetUserId) return "Extended";
    if (currentUserId === targetUserId) return "You";

    const userConnectionRef = doc(
      firestore,
      `users/${currentUserId}/connections/${targetUserId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    const userConnectionsRef = collection(
      firestore,
      `users/${currentUserId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${targetUserId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredModerators = moderators.filter((moderator) => {
    const fullName =
      `${moderator.firstName} ${moderator.lastName}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  const handleToggleUser = (userId) => {
    setSelectedUsers((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(userId)) {
        newSet.delete(userId);
      } else {
        newSet.add(userId);
      }
      return newSet;
    });
  };

  const handleRemoveModerators = async () => {
    setRemovingUsers(true);
    try {
      const batch = writeBatch(firestore);

      for (const userId of selectedUsers) {
        // Only allow removal if user is owner or is removing themselves
        if (
          currentUser.uid === communityData.ownerId ||
          userId === currentUser.uid
        ) {
          const moderatorRef = doc(
            firestore,
            `users/${communityData.ownerId}/communities/${communityData.id}/moderators/${userId}`
          );
          batch.delete(moderatorRef);

          // Delete notifications
          const ownerNotificationsRef = collection(
            firestore,
            `users/${communityData.ownerId}/notifications`
          );
          const ownerNotifQuery = query(
            ownerNotificationsRef,
            where("type", "==", "moderator_invite_accepted"),
            where("from", "==", userId),
            where("communityId", "==", communityData.id)
          );
          const ownerNotifSnapshot = await getDocs(ownerNotifQuery);
          ownerNotifSnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });

          const moderatorNotificationsRef = collection(
            firestore,
            `users/${userId}/notifications`
          );
          const moderatorNotifQuery = query(
            moderatorNotificationsRef,
            where("type", "==", "moderator_invite_response"),
            where("communityId", "==", communityData.id)
          );
          const moderatorNotifSnapshot = await getDocs(moderatorNotifQuery);
          moderatorNotifSnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });
        }
      }

      await batch.commit();

      const isModeratorRemovingSelf =
        currentUser.uid !== communityData.ownerId &&
        selectedUsers.has(currentUser.uid);

      if (isModeratorRemovingSelf) {
        // Close modal immediately and show self-removal message
        onModeratorRemoved([], 1, true);
        onClose("success");
      } else {
        // Owner removing moderators
        const remainingModerators = moderators.filter(
          (mod) => !selectedUsers.has(mod.id)
        );

        // If owner removed all moderators, close modal
        if (remainingModerators.length === 0) {
          onModeratorRemoved([], selectedUsers.size, false);
          onClose("success");
        } else {
          setModerators(remainingModerators);
          setTotalModerators(remainingModerators.length);
          onModeratorRemoved(remainingModerators, selectedUsers.size, false);
          setSelectedUsers(new Set());
        }
      }
    } catch (error) {
    } finally {
      setRemovingUsers(false);
    }
  };

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Moderators ({moderators.length})
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>

        <form className={classes.interactions}>
          <div className={classes.connectionsList}>
            {loading ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              </div>
            ) : filteredModerators.length > 0 ? (
              filteredModerators.map((moderator) => (
                <div key={moderator.id} className={classes.connectionItem}>
                  <img
                    src={moderator.profileImage || defaultImage}
                    alt={`${moderator.firstName} ${moderator.lastName}`}
                    className={classes.profileImage}
                  />
                  <div className={classes.connectionInfo}>
                    <h3 className={classes.name}>
                      <div className={classes.nameWrapper}>
                        <span className={classes.nameText}>
                          {moderator.firstName} {moderator.lastName}
                        </span>
                        <span className={classes.connectionType}>
                          • {moderator.connectionType}
                        </span>
                      </div>
                    </h3>
                    {moderator.bio && (
                      <p className={classes.bio}>{moderator.bio}</p>
                    )}
                    {moderator.link && (
                      <div className={classes.userLinkContainer}>
                        <a
                          href={moderator.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.userLink}
                        >
                          <span className={classes.userLinkText}>
                            {moderator.linkText || moderator.link}
                          </span>
                          <svg
                            className={classes.userLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                  </div>
                  <div className={classes.checkbox}>
                    <input
                      type="checkbox"
                      checked={selectedUsers.has(moderator.id)}
                      onChange={() => handleToggleUser(moderator.id)}
                    />
                    <div>
                      <svg
                        className={classes.checkmark}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4"
                          d="M5 11.917 9.724 16.5 19 7.5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.noConnections}></div>
            )}
          </div>
        </form>

        <div className={classes.cardFooter}>
          <button
            className={classes.button}
            disabled={selectedUsers.size === 0 || removingUsers}
            onClick={handleRemoveModerators}
          >
            {removingUsers ? (
              <span
                className={`material-symbols-outlined ${classes.buttonIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Remove"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditModeratorsModal;
