import React, { useState, useEffect } from "react";
import { firestore } from "../../firebase";
import defaultImage from "../../assets/icons/profileImage.jpg";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  writeBatch,
  deleteDoc,
} from "firebase/firestore";
import classes from "./AddTeamMemberModal.module.css"; // Reuse the same CSS

const EditTeamMembersModal = ({
  onClose,
  startupData,
  currentUser,
  onTeamMemberRemoved,
}) => {
  const [loading, setLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [removingUsers, setRemovingUsers] = useState(false);
  const [totalTeamMembers, setTotalTeamMembers] = useState(0);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    const scrollY = window.scrollY;

    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      window.scrollTo(0, scrollY);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      if (!startupData.id || !startupData.ownerId) return;

      try {
        setLoading(true);
        const teamMembersRef = collection(
          firestore,
          `users/${startupData.ownerId}/startups/${startupData.id}/team`
        );
        const q = query(teamMembersRef, where("status", "==", "active"));
        const querySnapshot = await getDocs(q);

        const teamMembersData = await Promise.all(
          querySnapshot.docs.map(async (teamMemberDoc) => {
            // Only fetch full data if this is the owner OR if this is the current user's member doc
            if (
              currentUser.uid === startupData.ownerId ||
              teamMemberDoc.id === currentUser.uid
            ) {
              const userDoc = await getDoc(
                doc(firestore, "users", teamMemberDoc.id)
              );
              if (userDoc.exists()) {
                const userData = userDoc.data();
                const connectionType = await checkConnectionType(
                  currentUser.uid,
                  teamMemberDoc.id
                );

                return {
                  id: teamMemberDoc.id,
                  firstName: userData.firstName || "",
                  lastName: userData.lastName || "",
                  profileImage: userData.profileImage || "",
                  bio: userData.bio || "",
                  urlSlug: userData.urlSlug || "",
                  link: userData.link || null,
                  linkText: userData.linkText || null,
                  connectionType:
                    teamMemberDoc.id === currentUser.uid
                      ? "You"
                      : connectionType,
                  acceptedAt: teamMemberDoc.data().acceptedAt,
                  userUUID: teamMemberDoc.id,
                };
              }
            }
            return null;
          })
        );

        const validTeamMembers = teamMembersData
          .filter(Boolean)
          .sort((a, b) => b.acceptedAt - a.acceptedAt);

        // Only show all team members to owner, otherwise just show the current user
        const visibleTeamMembers =
          currentUser.uid === startupData.ownerId
            ? validTeamMembers
            : validTeamMembers.filter(
                (member) => member.id === currentUser.uid
              );

        setTeamMembers(visibleTeamMembers);
        setTotalTeamMembers(visibleTeamMembers.length);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchTeamMembers();
  }, [startupData.id, startupData.ownerId, currentUser.uid]);

  const checkConnectionType = async (currentUserId, targetUserId) => {
    if (!currentUserId || !targetUserId) return "Extended";
    if (currentUserId === targetUserId) return "You";

    const userConnectionRef = doc(
      firestore,
      `users/${currentUserId}/connections/${targetUserId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    const userConnectionsRef = collection(
      firestore,
      `users/${currentUserId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${targetUserId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTeamMembers = teamMembers.filter((member) => {
    const fullName = `${member.firstName} ${member.lastName}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  const handleToggleUser = (userId) => {
    setSelectedUsers((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(userId)) {
        newSet.delete(userId);
      } else {
        newSet.add(userId);
      }
      return newSet;
    });
  };

  const handleRemoveTeamMembers = async () => {
    setRemovingUsers(true);
    try {
      const batch = writeBatch(firestore);

      for (const userId of selectedUsers) {
        // Only allow removal if user is owner or is removing themselves
        if (
          currentUser.uid === startupData.ownerId ||
          userId === currentUser.uid
        ) {
          const teamMemberRef = doc(
            firestore,
            `users/${startupData.ownerId}/startups/${startupData.id}/team/${userId}`
          );
          batch.delete(teamMemberRef);

          // Delete notifications
          const ownerNotificationsRef = collection(
            firestore,
            `users/${startupData.ownerId}/notifications`
          );
          const ownerNotifQuery = query(
            ownerNotificationsRef,
            where("type", "==", "team_invite_accepted"),
            where("from", "==", userId),
            where("startupId", "==", startupData.id)
          );
          const ownerNotifSnapshot = await getDocs(ownerNotifQuery);
          ownerNotifSnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });

          const memberNotificationsRef = collection(
            firestore,
            `users/${userId}/notifications`
          );
          const memberNotifQuery = query(
            memberNotificationsRef,
            where("type", "==", "team_invite_response"),
            where("startupId", "==", startupData.id)
          );
          const memberNotifSnapshot = await getDocs(memberNotifQuery);
          memberNotifSnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });
        }
      }

      await batch.commit();

      const remainingMembers = teamMembers.filter(
        (member) => !selectedUsers.has(member.id)
      );

      if (currentUser.uid !== startupData.ownerId) {
        onTeamMemberRemoved([], 1, true, "You removed yourself.");
        onClose();
      } else {
        const message =
          selectedUsers.size === 1
            ? "Team member removed."
            : "Team members removed.";
        if (remainingMembers.length === 0) {
          onTeamMemberRemoved([], selectedUsers.size, false, message);
          onClose();
        } else {
          setTeamMembers(remainingMembers);
          setTotalTeamMembers(remainingMembers.length);
          onTeamMemberRemoved(
            remainingMembers,
            selectedUsers.size,
            false,
            message
          );
          setSelectedUsers(new Set());
        }
      }
    } catch (error) {
    } finally {
      setRemovingUsers(false);
    }
  };

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Team ({teamMembers.length})
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>

        <form className={classes.interactions}>
          <div className={classes.connectionsList}>
            {loading ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              </div>
            ) : filteredTeamMembers.length > 0 ? (
              filteredTeamMembers.map((member) => (
                <div key={member.id} className={classes.connectionItem}>
                  <img
                    src={member.profileImage || defaultImage}
                    alt={`${member.firstName} ${member.lastName}`}
                    className={classes.profileImage}
                  />
                  <div className={classes.connectionInfo}>
                    <h3 className={classes.name}>
                      <div className={classes.nameWrapper}>
                        <span className={classes.nameText}>
                          {member.firstName} {member.lastName}
                        </span>
                        <span className={classes.connectionType}>
                          • {member.connectionType}
                        </span>
                      </div>
                    </h3>
                    {member.bio && <p className={classes.bio}>{member.bio}</p>}
                    {member.link && (
                      <div className={classes.userLinkContainer}>
                        <a
                          href={member.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.userLink}
                        >
                          <span className={classes.userLinkText}>
                            {member.linkText || member.link}
                          </span>
                          <svg
                            className={classes.userLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                  </div>
                  <div className={classes.checkbox}>
                    <input
                      type="checkbox"
                      checked={selectedUsers.has(member.id)}
                      onChange={() => handleToggleUser(member.id)}
                    />
                    <div>
                      <svg
                        className={classes.checkmark}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4"
                          d="M5 11.917 9.724 16.5 19 7.5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.noConnections}></div>
            )}
          </div>
        </form>

        <div className={classes.cardFooter}>
          <button
            className={classes.button}
            disabled={selectedUsers.size === 0 || removingUsers}
            onClick={handleRemoveTeamMembers}
          >
            {removingUsers ? (
              <span
                className={`material-symbols-outlined ${classes.buttonIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Remove"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTeamMembersModal;
