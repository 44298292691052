import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import { firestore } from "../../firebase";
import defaultImage from "../../assets/icons/profileImage.jpg";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import classes from "./MutualConnectionsModal.module.css";

const MutualConnectionsModal = ({ onClose, currentUserId, loggedInUserId }) => {
  const [mutualConnections, setMutualConnections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [totalMutualConnections, setTotalMutualConnections] = useState(0);

  useEffect(() => {
    const fetchMutualConnections = async () => {
      try {
        const currentUserConnectionsRef = collection(
          firestore,
          `users/${loggedInUserId}/connections`
        );
        const profileUserConnectionsRef = collection(
          firestore,
          `users/${currentUserId}/connections`
        );

        const [currentUserConnectionsSnapshot, profileUserConnectionsSnapshot] =
          await Promise.all([
            getDocs(
              query(
                currentUserConnectionsRef,
                where("status", "==", "Connected")
              )
            ),
            getDocs(
              query(
                profileUserConnectionsRef,
                where("status", "==", "Connected")
              )
            ),
          ]);

        // Filter out dummyId from both connection sets
        const currentUserConnections = new Set(
          currentUserConnectionsSnapshot.docs
            .filter((doc) => doc.id !== "dummyId")
            .map((doc) => doc.id)
        );
        const profileUserConnections = new Set(
          profileUserConnectionsSnapshot.docs
            .filter((doc) => doc.id !== "dummyId")
            .map((doc) => doc.id)
        );

        const mutualConnectionIds = [...currentUserConnections].filter((id) =>
          profileUserConnections.has(id)
        );

        const mutualConnectionsData = await Promise.all(
          mutualConnectionIds.map(async (userId) => {
            const userDoc = await getDoc(doc(firestore, "users", userId));
            return {
              id: userId,
              ...userDoc.data(),
              connectionType: "Direct",
            };
          })
        );

        setMutualConnections(mutualConnectionsData);
        setTotalMutualConnections(mutualConnectionsData.length);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchMutualConnections();
  }, [currentUserId, loggedInUserId]);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const handleNavigate = async (userId) => {
    onClose();
    if (userId === loggedInUserId) {
      navigate("/profile");
    } else {
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          navigate(`/${userData.urlSlug}`);
        } else {
        }
      } catch (error) {}
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredMutualConnections = useMemo(() => {
    return mutualConnections.filter((connection) => {
      const fullName =
        `${connection.firstName} ${connection.lastName}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    });
  }, [mutualConnections, searchTerm]);

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Mutual Connections ({totalMutualConnections})
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <form className={classes.interactions}>
          <div className={classes.connectionsList}>
            {loading ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              </div>
            ) : filteredMutualConnections.length > 0 ? (
              filteredMutualConnections.map((connection) => (
                <div key={connection.id} className={classes.connectionItem}>
                  <img
                    onClick={() => handleNavigate(connection.id)}
                    src={connection.profileImage || defaultImage}
                    alt={`${connection.firstName} ${connection.lastName}`}
                    className={classes.profileImage}
                  />
                  <div className={classes.connectionInfo}>
                    <h3 className={classes.name}>
                      <div className={classes.nameWrapper}>
                        <span
                          className={classes.nameText}
                          onClick={() => handleNavigate(connection.id)}
                        >
                          {connection.firstName} {connection.lastName}
                        </span>
                        <span className={classes.connectionType}>
                          • {connection.connectionType}
                        </span>
                      </div>
                    </h3>

                    <p className={classes.bio}>{connection.bio}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.noNotifications}>
                {/* <div className={classes.bold}>No mutual connections found</div>
                <div className={classes.text}>
                  Try searching for like-minded people.
                </div>
                <Link to="/people" className={classes.homeButton}>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  People
                </Link> */}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default MutualConnectionsModal;
