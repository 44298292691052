import { formatInTimeZone, toDate } from "date-fns-tz";

export const convertTime = (time, date, fromTimezone, toTimezone) => {
  if (!time || !date || !fromTimezone || !toTimezone) {
    return time;
  }

  try {
    const timeMatch = time.match(/(\d+):(\d+)\s*(AM|PM)/i);
    if (!timeMatch) {
      throw new Error("Invalid time format");
    }

    let [_, hours, minutes, period] = timeMatch;
    hours = parseInt(hours);
    minutes = parseInt(minutes);

    if (period.toUpperCase() === "PM" && hours !== 12) {
      hours += 12;
    } else if (period.toUpperCase() === "AM" && hours === 12) {
      hours = 0;
    }

    const [day, month, year] = date.split("/").map((num) => parseInt(num));

    const dateString = `${year}-${String(month).padStart(2, "0")}-${String(
      day
    ).padStart(2, "0")}T${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}:00.000${getTimezoneOffset(fromTimezone)}`;

    const sourceDate = toDate(dateString);

    const convertedTime = formatInTimeZone(sourceDate, toTimezone, "h:mm a");

    return convertedTime;
  } catch (error) {
    return time;
  }
};

const getTimezoneOffset = (timezone) => {
  const date = new Date();
  const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
  const tzDate = new Date(date.toLocaleString("en-US", { timeZone: timezone }));
  const offset = (tzDate - utcDate) / 60000;

  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  return `${offset >= 0 ? "+" : "-"}${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}`;
};

export const formatEventTime = (
  time,
  date,
  eventTimezone,
  userTimezone,
  endTime = null
) => {
  if (!time || !date || !eventTimezone || !userTimezone) {
    return time;
  }

  const localTime = convertTime(time, date, eventTimezone, userTimezone);
  const eventCity =
    eventTimezone.split("/")[1]?.replace(/_/g, " ") || eventTimezone;
  const userCity =
    userTimezone.split("/")[1]?.replace(/_/g, " ") || userTimezone;

  // If endTime is provided but matches startTime, ignore it
  if (endTime && endTime !== time) {
    const localEndTime = convertTime(
      endTime,
      date,
      eventTimezone,
      userTimezone
    );
    if (eventTimezone !== userTimezone && localTime !== time) {
      return `${time} - ${endTime} (${eventCity}) / ${localTime} - ${localEndTime} (${userCity})`;
    }
    return `${time} - ${endTime} (${eventCity})`;
  }

  if (eventTimezone !== userTimezone && localTime !== time) {
    return `${time} (${eventCity}) / ${localTime} (${userCity})`;
  }

  return `${time} (${eventCity})`;
};

export const formatEventTimeRange = (
  startTime,
  endTime,
  date,
  eventTimezone,
  userTimezone
) => {
  if (!startTime || !endTime || !date || !eventTimezone || !userTimezone) {
    return `${startTime} - ${endTime}`;
  }

  const localStartTime = convertTime(
    startTime,
    date,
    eventTimezone,
    userTimezone
  );
  const localEndTime = convertTime(endTime, date, eventTimezone, userTimezone);
  const eventCity =
    eventTimezone.split("/")[1]?.replace(/_/g, " ") || eventTimezone;
  const userCity =
    userTimezone.split("/")[1]?.replace(/_/g, " ") || userTimezone;

  if (eventTimezone !== userTimezone && localStartTime !== startTime) {
    return `${startTime} - ${endTime} (${eventCity}) / ${localStartTime} - ${localEndTime} (${userCity})`;
  }

  return `${startTime} - ${endTime} (${eventCity})`;
};

export const getUserTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (error) {
    return "UTC";
  }
};

// Add this to timeUtils.js
export const isEventClosed = (eventData) => {
  if (!eventData.endDate || !eventData.endTime || !eventData.timezone) {
    return false;
  }

  const userTimezone = getUserTimezone();
  const localEndTime = convertTime(
    eventData.endTime,
    eventData.endDate,
    eventData.timezone,
    userTimezone
  );

  // Parse the local end time and handle AM/PM
  const timeMatch = localEndTime.match(/(\d+):(\d+)\s*(AM|PM)/i);
  if (!timeMatch) return false;

  let [_, hours, minutes, period] = timeMatch;
  hours = parseInt(hours);
  minutes = parseInt(minutes);

  // Convert to 24-hour format
  if (period.toUpperCase() === "PM" && hours !== 12) {
    hours += 12;
  } else if (period.toUpperCase() === "AM" && hours === 12) {
    hours = 0;
  }

  const [day, month, year] = eventData.endDate
    .split("/")
    .map((num) => parseInt(num));

  // Create date object in user's local timezone with exact time
  const eventEndDate = new Date(year, month - 1, day, hours, minutes);
  const now = new Date();

  return now > eventEndDate;
};
