import React, { useState, useRef, useEffect } from "react";
import classes from "./SkillsDropdown.module.css";

const SkillsDropdown = ({
  label,
  selectedSkills,
  onSkillsChange,
  onInputChange,
  disabled,
  placeholder,
  customClassName,
}) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    setInputValue(selectedSkills.map((skill) => skill.name).join(", "));
  }, [selectedSkills]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onInputChange(newValue);
  };

  const handleInputBlur = () => {
    // Always process the input, even if empty
    const values = inputValue
      ? inputValue
          .split(",")
          .map((s) => s.trim())
          .filter((s) => s)
          .map((name) => ({ name, id: name }))
      : [];

    // Always call the change handler with the values (even if empty array)
    onSkillsChange(values); // or onInterestsChange or onTagsChange depending on the component
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [inputValue]);

  return (
    <div className={classes.customDropdown}>
      <label className={classes.label}>{label}</label>
      <div className={classes.dropdownContainer}>
        <textarea
          ref={inputRef}
          className={`${classes.dropdownHeader} ${classes.textArea} ${
            disabled ? classes.disabled : ""
          } ${customClassName}`} // Add this line
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default SkillsDropdown;
