import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import AddFollowersModal from "../../../components/Modals/AddFollowersModal";
import TagsModal from "../../../components/Modals/TagsModal";
import { useNavigate, useParams } from "react-router-dom";
import { functions, httpsCallable, auth } from "../../../firebase"; // Updated this line
import defaultImage from "../../../assets/icons/profileImage.jpg";
import greyProfileImage from "../../../assets/icons/greyProfileImage.jpg";
import startupImage from "../../../assets/images/startupImage.jpg";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./Startup.module.css";
import { getTopThreeEmojis } from "../../../utils/emojiUtils";
import { firestore } from "../../../firebase";
import MutualFollowersModal from "../../../components/Modals/MutualFollowersModal";
import greyPostImage from "../../../assets/icons/greyPostImage.jpg";
import ConnectionsModal from "../../../components/Modals/ConnectionsModal";
import EditSingleStartupModal from "../../../components/Modals/EditSingleStartupModal";
import IntroModal from "../../../components/Modals/IntroModal";
import ActivityButtons from "../../../components/Buttons/ActivityButtons";
import FollowModal from "../../../components/Modals/FollowModal";
import DonationVisibilityToggle from "../../../components/Toggles/DonationVisibilityToggle";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import AddTeamMemberModal from "../../../components/Modals/AddTeamMemberModal";
import EditTeamMembersModal from "../../../components/Modals/EditTeamMembersModal";
import EditFollowersModal from "../../../components/Modals/EditFollowersModal";
import {
  doc,
  getDoc,
  collection,
  writeBatch,
  increment,
  getDocs,
  query,
  updateDoc,
  documentId,
  orderBy,
  limit,
  where,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import ReactionsModal from "../../../components/Modals/ReactionsModal";
import RepostsModal from "../../../components/Modals/RepostsModal";
import DonationModal from "../../../components/Modals/DonationModal";

function Startup() {
  const [isBlocked, setIsBlocked] = useState(false);
  const [hasUserBlockedThem, setHasUserBlockedThem] = useState(false);
  const [mutualFollowersCount, setMutualFollowersCount] = useState(0);
  const [showMutualFollowersModal, setShowMutualFollowersModal] =
    useState(false);
  const [isMutualFollowersModalOpen, setIsMutualFollowersModalOpen] =
    useState(false);
  const [
    mutualFollowersModalScrollPosition,
    setMutualFollowersModalScrollPosition,
  ] = useState(0);
  const [hasAvailableTeamConnections, setHasAvailableTeamConnections] =
    useState(false);
  const [addFollowerModalScrollPosition, setAddFollowerModalScrollPosition] =
    useState(0);
  const [isAddFollowerModalOpen, setIsAddFollowerModalOpen] = useState(false);
  const [
    editTeamMembersModalScrollPosition,
    setEditTeamMembersModalScrollPosition,
  ] = useState(0);
  const [isEditTeamMembersModalOpen, setIsEditTeamMembersModalOpen] =
    useState(false);
  const [showEditFollowersModal, setShowEditFollowersModal] = useState(false);
  const [isEditFollowersModalOpen, setIsEditFollowersModalOpen] =
    useState(false);
  const [hasActivityTypeInitialized, setHasActivityTypeInitialized] =
    useState(false);
  const [
    editFollowersModalScrollPosition,
    setEditFollowersModalScrollPosition,
  ] = useState(0);
  const [connectionsCount, setConnectionsCount] = useState(0);
  const [hasAvailableFollowers, setHasAvailableFollowers] = useState(false);
  const [donationVisibility, setDonationVisibility] = useState({});
  const [showDonationCancelledPopup, setShowDonationCancelledPopup] =
    useState(false);
  const [visibleTagsCount, setVisibleTagsCount] = useState(9);
  const [shareButtonText, setShareButtonText] = useState("Share");
  const [expandedDonations, setExpandedDonations] = useState({});
  const [donations, setDonations] = useState([]);
  const [loadingDonations, setLoadingDonations] = useState(false);
  const [displayedDonations, setDisplayedDonations] = useState(3);
  const [showDonationModal, setShowDonationModal] = useState(false);
  const [stripeStatus, setStripeStatus] = useState(null);
  const [isSettingUpPayment, setIsSettingUpPayment] = useState(false);
  const { startupUrlSlug } = useParams();
  const navigate = useNavigate();
  const [followers, setFollowers] = useState([]);
  const [displayedFollowers, setDisplayedFollowers] = useState(3);
  const [currentUser, setCurrentUser] = useState(null);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const optionsButtonRef = useRef(null);
  const dropdownRef = useRef(null);
  const [showRemovalPopup, setShowRemovalPopup] = useState(false);
  const [removalPopupMessage, setRemovalPopupMessage] = useState("");
  const [reportMessage, setReportMessage] = useState(null);
  const [tagsModalScrollPosition, setTagsModalScrollPosition] = useState(0);
  const [startupData, setStartupData] = useState({
    startupName: "",
    bio: "",
    city: "",
    country: "",
    link: "",
    linkText: "",
    startupImage: "",
    industry: "",
    role: "",
    intro: "",
    companyType: "",
  });
  const [showAddFollowerModal, setShowAddFollowerModal] = useState(false);

  const [showAddTeamMember, setShowAddTeamMember] = useState(false);
  const [isAddTeamMemberOpen, setIsAddTeamMemberOpen] = useState(false);
  const [addTeamMemberScrollPosition, setAddTeamMemberScrollPosition] =
    useState(0);
  const [displayedTeamMembers, setDisplayedTeamMembers] = useState(3);
  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const [isRefreshingStripeSetup, setIsRefreshingStripeSetup] = useState(false);
  const [showStripeSuccess, setShowStripeSuccess] = useState(false);
  const [startupNotFound, setStartupNotFound] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [connectionsModalScrollPosition, setConnectionsModalScrollPosition] =
    useState(0);
  const [isConnectionsModalOpen, setIsConnectionsModalOpen] = useState(false);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [isIntroModalOpen, setIsIntroModalOpen] = useState(false);
  const [introModalScrollPosition, setIntroModalScrollPosition] = useState(0);
  const [showEditSingleStartupModal, setShowEditSingleStartupModal] =
    useState(false);
  const [isEditSingleStartupModalOpen, setIsEditSingleStartupModalOpen] =
    useState(false);
  const [
    editSingleStartupModalScrollPosition,
    setEditSingleStartupModalScrollPosition,
  ] = useState(0);
  const [donationModalScrollPosition, setDonationModalScrollPosition] =
    useState(0);
  const [isDonationModalOpen, setIsDonationModalOpen] = useState(false);
  const [showEditTeamModal, setShowEditTeamModal] = useState(false);
  const [isEditTeamModalOpen, setIsEditTeamModalOpen] = useState(false);
  const [editTeamModalScrollPosition, setEditTeamModalScrollPosition] =
    useState(0);

  const [loadingPosts, setLoadingPosts] = useState(false);
  const [loadingReactions, setLoadingReactions] = useState(false);
  const [loadingReposts, setLoadingReposts] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [followStatus, setFollowStatus] = useState("Follow");
  const [posts, setPosts] = useState([]);
  const [reactions, setReactions] = useState([]);
  const [reposts, setReposts] = useState([]);
  const [commentedPosts, setCommentedPosts] = useState([]);
  const [showConnectionsModal, setShowConnectionsModal] = useState(false);

  const [displayedReactions, setDisplayedReactions] = useState(3);
  const [displayedReposts, setDisplayedReposts] = useState(3);
  const [displayedComments, setDisplayedComments] = useState(3);

  const [isOwner, setIsOwner] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);
  const [isLoading, setIsLoading] = useState(true);
  const [activityType, setActivityType] = useState("Posts");
  const [showFullIntro, setShowFullIntro] = useState(false);
  const [displayedPosts, setDisplayedPosts] = useState(3);
  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const [isStripeSetup, setIsStripeSetup] = useState(false);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [showEditTeamMembersModal, setShowEditTeamMembersModal] =
    useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);
  const [activePostReposts, setActivePostReposts] = useState(null);
  const [showFollowersModal, setShowFollowersModal] = useState(false);
  const [followersModalScrollPosition, setFollowersModalScrollPosition] =
    useState(0);
  const [isFollowersModalOpen, setIsFollowersModalOpen] = useState(false);

  const toggleAddFollowerModal = () => {
    if (!showAddFollowerModal) {
      setAddFollowerModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addFollowerModalScrollPosition);
      }, 0);
    }
    setShowAddFollowerModal(!showAddFollowerModal);
    setIsAddFollowerModalOpen(!isAddFollowerModalOpen);
  };

  const toggleAddTeamMember = () => {
    if (!showAddTeamMember) {
      setAddTeamMemberScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addTeamMemberScrollPosition);
      }, 0);
    }
    setShowAddTeamMember(!showAddTeamMember);
    setIsAddTeamMemberOpen(!isAddTeamMemberOpen);
  };

  const countMutualFollowers = async () => {
    if (!currentUser?.uid || !startupData?.id || !startupData?.ownerId) return;

    try {
      // Get current user's connections
      const userConnectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const userConnectionsQuery = query(
        userConnectionsRef,
        where("status", "==", "Connected")
      );
      const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

      // Create set of connected user IDs (excluding dummyId)
      const userConnections = new Set(
        userConnectionsSnapshot.docs
          .filter((doc) => doc.id !== "dummyId")
          .map((doc) => doc.id)
      );

      // Get startup's followers
      const followersRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/followers`
      );
      const followersSnapshot = await getDocs(followersRef);
      const startupFollowers = new Set(
        followersSnapshot.docs.map((doc) => doc.id)
      );

      // Count mutual followers (intersection)
      const mutualCount = [...userConnections].filter((id) =>
        startupFollowers.has(id)
      ).length;
      setMutualFollowersCount(mutualCount);
    } catch (error) {
      setMutualFollowersCount(0);
    }
  };

  useEffect(() => {
    if (currentUser?.uid && startupData?.id && startupData?.ownerId) {
      countMutualFollowers();
    }
  }, [currentUser?.uid, startupData?.id, startupData?.ownerId]);

  const toggleMutualFollowersModal = () => {
    if (!showMutualFollowersModal) {
      setMutualFollowersModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, mutualFollowersModalScrollPosition);
      }, 0);
    }
    setShowMutualFollowersModal(!showMutualFollowersModal);
    setIsMutualFollowersModalOpen(!isMutualFollowersModalOpen);
  };

  const RemovalPopup = ({ message, onClose }) => (
    <div className={classes.repostPopup}>
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="orangered"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
            clipRule="evenodd"
          />
        </svg>
        <p>{message}</p>
      </div>
      <div onClick={onClose} className={classes.repostCloseButton}>
        <svg
          className={classes.repostCloseIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  const toggleEditFollowersModal = () => {
    if (!showEditFollowersModal) {
      setEditFollowersModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editFollowersModalScrollPosition);
      }, 0);
    }
    setShowEditFollowersModal(!showEditFollowersModal);
    setIsEditFollowersModalOpen(!isEditFollowersModalOpen);
  };

  const checkAvailableTeamConnections = useCallback(async () => {
    if (!currentUser?.uid || !startupData?.id || !startupData.ownerId) return;

    try {
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const q = query(
        connectionsRef,
        where("status", "==", "Connected"),
        where(documentId(), "!=", "dummyId")
      );
      const querySnapshot = await getDocs(q);

      // Get current team members
      const teamRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/team`
      );
      const teamSnapshot = await getDocs(teamRef);

      const excludedIds = new Set([
        ...teamSnapshot.docs.map((doc) => doc.id),
        "dummyId",
      ]);

      const hasAvailable = querySnapshot.docs.some(
        (doc) => !excludedIds.has(doc.id)
      );
      setHasAvailableTeamConnections(hasAvailable);
    } catch (error) {}
  }, [currentUser?.uid, startupData?.id, startupData?.ownerId]);

  // Add useEffect to call it
  useEffect(() => {
    if (currentUser?.uid && startupData?.id && startupData?.ownerId) {
      checkAvailableTeamConnections();
    }
  }, [currentUser?.uid, startupData?.id, startupData?.ownerId, teamMembers]);

  const checkAvailableFollowers = useCallback(async () => {
    if (!currentUser?.uid || !startupData?.id || !startupData.ownerId) return;

    try {
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const q = query(
        connectionsRef,
        where("status", "==", "Connected"),
        where(documentId(), "!=", "dummyId") // Exclude dummyId
      );
      const querySnapshot = await getDocs(q);

      const followersRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/followers`
      );
      const followersSnapshot = await getDocs(followersRef);

      const excludedIds = new Set([
        ...followersSnapshot.docs.map((doc) => doc.id),
        startupData.ownerId,
        "dummyId", // Also exclude dummyId from potential followers
      ]);

      const hasAvailable = querySnapshot.docs.some(
        (doc) => !excludedIds.has(doc.id)
      );

      setHasAvailableFollowers(hasAvailable);
    } catch (error) {}
  }, [currentUser?.uid, startupData?.id, startupData?.ownerId]);

  useEffect(() => {
    if (showDropdown) {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target) &&
          optionsButtonRef.current &&
          !optionsButtonRef.current.contains(event.target)
        ) {
          setShowDropdown(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showDropdown]);

  useEffect(() => {
    if (showDropdown) {
      const positionDropdown = () => {
        if (optionsButtonRef.current && dropdownRef.current) {
          const buttonRect = optionsButtonRef.current.getBoundingClientRect();
          const dropdownRect = dropdownRef.current.getBoundingClientRect();

          dropdownRef.current.style.top = `${
            buttonRect.bottom + window.scrollY
          }px`;
          dropdownRef.current.style.right = `${
            window.innerWidth - buttonRect.right
          }px`;
        }
      };

      positionDropdown();
      window.addEventListener("resize", positionDropdown);
      window.addEventListener("scroll", positionDropdown);

      return () => {
        window.removeEventListener("resize", positionDropdown);
        window.removeEventListener("scroll", positionDropdown);
      };
    }
  }, [showDropdown]);

  const handleReport = () => {
    setShowDropdown(false);
    setReportMessage({
      text: "Report submitted.",
    });
  };

  useEffect(() => {
    if (currentUser?.uid && startupData?.id && startupData?.ownerId) {
      checkAvailableFollowers();
    }
  }, [currentUser?.uid, startupData?.id, startupData?.ownerId, followers]); // Add followers as dependency

  const toggleEditTeamMembersModal = () => {
    if (!showEditTeamMembersModal) {
      setEditTeamMembersModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editTeamMembersModalScrollPosition);
      }, 0);
    }
    setShowEditTeamMembersModal(!showEditTeamMembersModal);
    setIsEditTeamMembersModalOpen(!isEditTeamMembersModalOpen);
  };

  const fetchConnectionsCount = async () => {
    if (!currentUser?.uid) return;

    try {
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const q = query(
        connectionsRef,
        where("status", "==", "Connected"),
        where(documentId(), "!=", "dummyId") // Exclude dummyId
      );
      const snapshot = await getDocs(q);
      setConnectionsCount(snapshot.size);
    } catch (error) {
      setConnectionsCount(0);
    }
  };

  const PostInteractionCount = ({
    postId,
    reposts,
    commentCount,
    onClick,
    onCommentClick,
  }) => {
    const repostCount = Object.keys(reposts || {}).length;

    // If there are no interactions at all, return null early
    if (repostCount === 0 && commentCount === 0) return null;

    return (
      <>
        {commentCount > 0 && (
          <span>
            {commentCount} comment{commentCount !== 1 ? "s" : ""}
          </span>
        )}
      </>
    );
  };

  const renderFilePreview = (postPreview) => {
    if (postPreview.mediaType === "image") {
      // Handle single image
      if (typeof postPreview.mediaUrl === "string") {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl} alt="Post preview" />
          </div>
        );
      }
      // Handle multiple images
      else if (
        Array.isArray(postPreview.mediaUrl) &&
        postPreview.mediaUrl.length > 0
      ) {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl[0]} alt="Post preview" />
            {postPreview.mediaUrl.length > 1 && (
              <div className={classes.multipleImagesIndicator}>
                +{postPreview.mediaUrl.length - 1}
              </div>
            )}
          </div>
        );
      }
    }

    if (postPreview.mediaType === "video") {
      return (
        <div className={classes.mediaPreview}>
          <video
            src={postPreview.mediaUrl}
            preload="metadata"
            style={{ display: "none" }}
            muted
            playsInline
            onLoadedMetadata={(e) => {
              const video = e.target;
              const canvas = document.createElement("canvas");
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              video.currentTime = 0.1; // Set to 0.1s to avoid black frame

              video.addEventListener(
                "seeked",
                () => {
                  try {
                    // Draw the video frame to canvas
                    canvas
                      .getContext("2d")
                      .drawImage(video, 0, 0, canvas.width, canvas.height);

                    // Create and insert the static image
                    const img = new Image();
                    img.src = canvas.toDataURL();
                    img.style.width = "100%";
                    img.style.height = "100%";
                    img.style.objectFit = "cover";

                    // Find the container and insert the image
                    const container = video.parentNode;
                    container.insertBefore(img, container.firstChild);

                    // Cleanup
                    video.remove();
                  } catch (err) {
                    // If canvas operations fail, show the first frame of video as fallback
                    video.style.display = "block";
                    video.controls = false;
                    video.autoPlay = false;
                    video.style.pointerEvents = "none";
                  }
                },
                { once: true }
              );
            }}
          />
          <div className={classes.playButton}></div>
        </div>
      );
    }

    if (postPreview.mediaType === "pdf" || postPreview.fileName) {
      return (
        <div className={classes.filePreview}>
          <svg
            className="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      );
    }

    return null;
  };

  // Add this useEffect
  useEffect(() => {
    if (currentUser?.uid) {
      fetchConnectionsCount();
    }
  }, [currentUser?.uid]);

  const fetchFollowers = async () => {
    if (!startupData.id || !startupData.ownerId) return;

    try {
      const followersRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/followers`
      );
      const followersSnapshot = await getDocs(followersRef);

      const followersData = await Promise.all(
        followersSnapshot.docs.map(async (followerDoc) => {
          const userRef = doc(firestore, "users", followerDoc.id);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();

            let connectionType = "Extended";
            if (currentUser?.uid) {
              const isBlocked = await isUserOrStartupsBlocked(
                currentUser.uid,
                followerDoc.id
              );
              if (isBlocked) {
                connectionType = "Blocked";
              } else if (followerDoc.id === currentUser.uid) {
                connectionType = "You";
              } else {
                const directConnectionRef = doc(
                  firestore,
                  `users/${currentUser.uid}/connections/${followerDoc.id}`
                );
                const directConnectionDoc = await getDoc(directConnectionRef);

                if (
                  directConnectionDoc.exists() &&
                  directConnectionDoc.data().status === "Connected"
                ) {
                  connectionType = "Direct";
                } else {
                  const userConnectionsRef = collection(
                    firestore,
                    `users/${currentUser.uid}/connections`
                  );
                  const userConnectionsQuery = query(
                    userConnectionsRef,
                    where("status", "==", "Connected")
                  );
                  const userConnectionsSnapshot = await getDocs(
                    userConnectionsQuery
                  );

                  let isIndirect = false;
                  for (const conn of userConnectionsSnapshot.docs) {
                    const indirectConnectionRef = doc(
                      firestore,
                      `users/${conn.id}/connections/${followerDoc.id}`
                    );
                    const indirectConnectionDoc = await getDoc(
                      indirectConnectionRef
                    );
                    if (
                      indirectConnectionDoc.exists() &&
                      indirectConnectionDoc.data().status === "Connected"
                    ) {
                      isIndirect = true;
                      break;
                    }
                  }
                  connectionType = isIndirect ? "Indirect" : "Extended";
                }
              }
            }

            return {
              id: followerDoc.id,
              followedAt: followerDoc.data().followedAt,
              connectionType,
              userData: {
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || "",
                bio: userData.bio || "",
                link: userData.link || "",
                linkText: userData.linkText || "",
                city: userData.city || "",
                country: userData.country || "",
                urlSlug: userData.urlSlug || "",
              },
            };
          }
          return null;
        })
      );

      const validFollowers = followersData
        .filter(Boolean)
        .sort((a, b) => b.followedAt - a.followedAt);

      setFollowers(validFollowers);
    } catch (error) {}
  };

  // Add this useEffect to fetch followers
  useEffect(() => {
    if (startupData.id && startupData.ownerId) {
      fetchFollowers();
    }
  }, [startupData.id, startupData.ownerId]);

  const toggleTagsModal = () => {
    if (!showTagsModal) {
      setTagsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, tagsModalScrollPosition);
      }, 0);
    }
    setShowTagsModal(!showTagsModal);
    setIsTagsModalOpen(!isTagsModalOpen);
  };

  // Update the ReactionsSummary component in Startup.js
  const ReactionsSummary = ({ reactions, onClick, post }) => {
    const reactionCount = Object.keys(reactions).length;
    const topEmojis = useMemo(() => getTopThreeEmojis(reactions), [reactions]);

    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
      const checkSafari = () => {
        const isSafari =
          /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
          /apple/i.test(navigator.vendor);
        setIsSafari(isSafari);
        document.documentElement.style.setProperty(
          "--emoji-font-size",
          isSafari ? "0.75rem" : "0.875rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-margin-left",
          isSafari ? "-0.25rem" : "-0.25rem"
        );
        document.documentElement.style.setProperty(
          "--count-margin-left",
          isSafari ? "0.25rem" : "0.25rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-text-size",
          isSafari ? "0.75rem" : "0.875rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-margin-bottom",
          isSafari ? "0rem" : "0rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-margin-top",
          isSafari ? "0rem" : "0rem"
        );
      };

      checkSafari();
    }, []);

    return (
      <div>
        <div>
          {reactionCount} boost{reactionCount !== 1 ? "s" : ""}
          {(post?.commentCount > 0 ||
            Object.keys(post?.reposts || {}).length > 0) && (
            <span className={classes.dot}>•</span>
          )}
        </div>
      </div>
    );
  };

  const handleShowFollowersModal = () => {
    setFollowersModalScrollPosition(window.scrollY);
    setShowFollowersModal(true);
    setIsFollowersModalOpen(true);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        // Optionally refresh the token
        user.getIdToken(true);
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleVisibilityToggle = async (
    donationId,
    isVisible,
    isDonorAction
  ) => {
    try {
      const donationRef = doc(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/donations/${donationId}`
      );

      const updateField = isDonorAction ? "hiddenByDonor" : "hiddenByStartup";

      await updateDoc(donationRef, {
        [`visibility.${updateField}`]: !isVisible,
      });

      // Update local state
      setDonationVisibility((prev) => ({
        ...prev,
        [donationId]: {
          ...prev[donationId],
          [updateField]: !isVisible,
        },
      }));
    } catch (error) {}
  };

  const handleShare = () => {
    const startupUrl = `${window.location.origin}/startup/${startupData.startupUrlSlug}`;
    navigator.clipboard.writeText(startupUrl).then(() => {
      setShareButtonText("Copied");
      setTimeout(() => {
        setShareButtonText("Share");
      }, 5000);
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const donationStatus = params.get("donation");

    if (donationStatus === "cancelled") {
      setShowDonationCancelledPopup(true);
      // Clean up the URL
      window.history.replaceState({}, "", window.location.pathname);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const isSuccess = params.get("success") === "true";
    const isRefresh = params.get("refresh") === "true";

    if (
      (isSuccess && stripeStatus?.status === "active") ||
      (stripeStatus?.status === "active" &&
        startupData.stripeAccountStatus !== "active")
    ) {
      setShowStripeSuccess(true);
      if (isSuccess || isRefresh) {
        window.history.replaceState({}, "", window.location.pathname);
      }
    } else if (isRefresh) {
      setIsRefreshingStripeSetup(true);
      window.history.replaceState({}, "", window.location.pathname);
    }
  }, [stripeStatus?.status, startupData.stripeAccountStatus]);

  useEffect(() => {
    if (!startupData?.id || !startupData?.ownerId) {
      return; // Exit if startup data isn't loaded yet
    }

    const params = new URLSearchParams(window.location.search);
    const isSuccess = params.get("success") === "true";

    const checkStatus = async () => {
      try {
        if (startupData.stripeAccountId) {
          await checkStripeStatus();

          // Only show success message if returning from Stripe with success=true
          // AND the account is active
          if (isSuccess && startupData.stripeAccountStatus === "active") {
            setShowStripeSuccess(true);
            window.history.replaceState({}, "", window.location.pathname);
          }
        }
      } catch (error) {}
    };

    checkStatus();
  }, [startupData?.id, startupData?.ownerId, startupData?.stripeAccountId]);

  useEffect(() => {
    if (startupData.stripeAccountId) {
      checkStripeStatus().then(() => {
        // Show success message if URL indicates success and account is active
        const params = new URLSearchParams(window.location.search);
        const isSuccess = params.get("success") === "true";
        if (isSuccess && startupData.stripeAccountStatus === "active") {
          setShowStripeSuccess(true);
          window.history.replaceState({}, "", window.location.pathname);
        }
      });
    }
  }, [startupData.stripeAccountId]);

  const handleCloseFollowersModal = () => {
    setShowFollowersModal(false);
    setIsFollowersModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, followersModalScrollPosition);
    }, 0);
  };

  const [activityCounts, setActivityCounts] = useState({
    posts: 0,
    boosts: 0,
    reposts: 0,
    comments: 0,
  });

  const DonationCancelledPopup = ({ onClose }) => (
    <div className={classes.repostPopup}>
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="orangered"
          viewBox="0 0 24 24"
        >
          <path
            fill-rule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
            clip-rule="evenodd"
          />
        </svg>

        <p>Donation cancelled.</p>
      </div>
      <div onClick={onClose} className={classes.repostCloseButton}>
        <svg
          className={classes.repostCloseIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  const InvitePopup = ({ onClose, isModalOpen }) => (
    <div
      className={`${classes.repostPopup} ${
        isModalOpen ? classes.modalOpen : classes.modalClosed
      }`}
    >
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="orangered"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
            clipRule="evenodd"
          />
        </svg>
        <p>Invite sent.</p>
      </div>
      <div onClick={onClose} className={classes.repostCloseButton}>
        <svg
          className={classes.repostCloseIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  const toggleDonationModal = () => {
    if (!showDonationModal) {
      setDonationModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, donationModalScrollPosition);
      }, 0);
    }
    setShowDonationModal(!showDonationModal);
    setIsDonationModalOpen(!isDonationModalOpen);
  };

  const toggleEditTeamModal = () => {
    if (!showEditTeamModal) {
      setEditTeamModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editTeamModalScrollPosition);
      }, 0);
    }
    setShowEditTeamModal(!showEditTeamModal);
    setIsEditTeamModalOpen(!isEditTeamModalOpen);
  };

  const [loadingStates, setLoadingStates] = useState({
    initial: true,
    stripeStatus: false,
    teamMembers: false,
    donations: false,
    posts: false,
    reactions: false,
    reposts: false,
    comments: false,
    followStatus: false,
  });

  const updateLoadingState = (key, value) => {
    setLoadingStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const isFullyLoaded = () => {
    // Check if we're still in initial loading
    if (loadingStates.initial) return false;

    // If startup not found, we can show the error state immediately
    if (startupNotFound) return true;

    // For owners, we need to wait for stripe status
    if (isOwner && loadingStates.stripeStatus) return false;

    // Always wait for team members
    if (loadingStates.teamMembers) return false;

    // Wait for donations if stripe is active
    if (stripeStatus?.status === "active" && loadingStates.donations)
      return false;

    // Wait for the current activity type's data
    switch (activityType) {
      case "Posts":
        if (loadingStates.posts) return false;
        break;
      case "Boosts":
        if (loadingStates.reactions) return false;
        break;
      case "Reposts":
        if (loadingStates.reposts) return false;
        break;
      case "Comments":
        if (loadingStates.comments) return false;
        break;
    }

    // Wait for follow status if not owner
    if (!isOwner && loadingStates.followStatus) return false;

    return true;
  };

  const isUserOrStartupsBlocked = async (currentUserId, otherUserId) => {
    try {
      // Get all startups for both users
      const getUserStartups = async (userId) => {
        const startupsRef = collection(firestore, `users/${userId}/startups`);
        const startupsSnapshot = await getDocs(startupsRef);
        return startupsSnapshot.docs.map((doc) => doc.id);
      };

      const [currentUserStartups, otherUserStartups] = await Promise.all([
        getUserStartups(currentUserId),
        getUserStartups(otherUserId),
      ]);

      // Check current user's blocked users
      const currentUserBlockedRef = collection(
        firestore,
        `users/${currentUserId}/blockedUsers`
      );
      const currentUserBlockedSnapshot = await getDocs(currentUserBlockedRef);
      const currentUserBlockedIds = new Set(
        currentUserBlockedSnapshot.docs.map((doc) => doc.data().userId)
      );

      // Check if other user or their startups are blocked by current user
      if (currentUserBlockedIds.has(otherUserId)) return true;
      for (const startupId of otherUserStartups) {
        if (currentUserBlockedIds.has(`startup_${startupId}`)) return true;
      }

      // Check other user's blocked users
      const otherUserBlockedRef = collection(
        firestore,
        `users/${otherUserId}/blockedUsers`
      );
      const otherUserBlockedSnapshot = await getDocs(otherUserBlockedRef);
      const otherUserBlockedIds = new Set(
        otherUserBlockedSnapshot.docs.map((doc) => doc.data().userId)
      );

      // Check if current user or their startups are blocked by other user
      if (otherUserBlockedIds.has(currentUserId)) return true;
      for (const startupId of currentUserStartups) {
        if (otherUserBlockedIds.has(`startup_${startupId}`)) return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  const fetchTeamMembers = async () => {
    if (!startupData.id || !startupData.ownerId) return;

    try {
      // First check for blocked status with owner
      let ownerConnectionType = "Extended";
      if (currentUser?.uid) {
        const isBlocked = await isUserOrStartupsBlocked(
          currentUser.uid,
          startupData.ownerId
        );
        if (isBlocked) {
          ownerConnectionType = "Blocked";
        } else if (startupData.ownerId === currentUser.uid) {
          ownerConnectionType = "You";
        } else {
          const connectionRef = doc(
            firestore,
            `users/${currentUser.uid}/connections/${startupData.ownerId}`
          );
          const connectionDoc = await getDoc(connectionRef);
          if (
            connectionDoc.exists() &&
            connectionDoc.data().status === "Connected"
          ) {
            ownerConnectionType = "Direct";
          } else {
            const userConnectionsRef = collection(
              firestore,
              `users/${currentUser.uid}/connections`
            );
            const userConnectionsQuery = query(
              userConnectionsRef,
              where("status", "==", "Connected"),
              where(documentId(), "!=", "dummyId") // Add this line to exclude dummyId
            );
            const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

            for (const conn of userConnectionsSnapshot.docs) {
              const indirectConnectionRef = doc(
                firestore,
                `users/${conn.id}/connections/${startupData.ownerId}`
              );
              const indirectConnectionDoc = await getDoc(indirectConnectionRef);
              if (
                indirectConnectionDoc.exists() &&
                indirectConnectionDoc.data().status === "Connected"
              ) {
                ownerConnectionType = "Indirect";
                break;
              }
            }
          }
        }
      }

      setStartupData((prev) => ({
        ...prev,
        ownerConnectionType,
      }));

      const teamRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/team`
      );
      const teamQuery = query(teamRef, where("status", "==", "active"));
      const teamSnapshot = await getDocs(teamQuery);

      const teamMembersPromises = teamSnapshot.docs.map(async (teamDoc) => {
        const userUUID = teamDoc.id;
        const teamMemberData = teamDoc.data();

        if (userUUID === "dummyId") return null;

        const userRef = doc(firestore, "users", userUUID);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();

          let connectionType = "Extended";
          if (currentUser?.uid) {
            // Check for blocked status first
            const isBlocked = await isUserOrStartupsBlocked(
              currentUser.uid,
              userUUID
            );
            if (isBlocked) {
              connectionType = "Blocked";
            } else if (userUUID === currentUser.uid) {
              connectionType = "You";
            } else {
              const connectionRef = doc(
                firestore,
                `users/${currentUser.uid}/connections/${userUUID}`
              );
              const connectionDoc = await getDoc(connectionRef);
              if (
                connectionDoc.exists() &&
                connectionDoc.data().status === "Connected"
              ) {
                connectionType = "Direct";
              } else {
                const userConnectionsRef = collection(
                  firestore,
                  `users/${currentUser.uid}/connections`
                );
                const userConnectionsQuery = query(
                  userConnectionsRef,
                  where("status", "==", "Connected"),
                  where(documentId(), "!=", "dummyId") // Add this line to exclude dummyId
                );
                const userConnectionsSnapshot = await getDocs(
                  userConnectionsQuery
                );

                for (const conn of userConnectionsSnapshot.docs) {
                  const indirectConnectionRef = doc(
                    firestore,
                    `users/${conn.id}/connections/${userUUID}`
                  );
                  const indirectConnectionDoc = await getDoc(
                    indirectConnectionRef
                  );
                  if (
                    indirectConnectionDoc.exists() &&
                    indirectConnectionDoc.data().status === "Connected"
                  ) {
                    connectionType = "Indirect";
                    break;
                  }
                }
              }
            }
          }

          return {
            id: teamDoc.id,
            userUUID: userUUID,
            role: teamMemberData.role || "",
            isCoFounder: teamMemberData.isCoFounder || false,
            acceptedAt: teamMemberData.acceptedAt,
            createdAt: teamMemberData.createdAt,
            status: teamMemberData.status,
            connectionType,
            userData: {
              firstName: userData.firstName || "",
              lastName: userData.lastName || "",
              profileImage: userData.profileImage || "",
              bio: userData.bio || "",
              link: userData.link || "",
              linkText: userData.linkText || "",
              city: userData.city || "",
              country: userData.country || "",
              urlSlug: userData.urlSlug || "",
            },
          };
        }
        return null;
      });

      const resolvedTeamMembers = (await Promise.all(teamMembersPromises))
        .filter((member) => member !== null)
        .sort((a, b) => b.createdAt - a.createdAt);

      setTeamMembers(resolvedTeamMembers);
    } catch (error) {}
  };

  const NoContentCard = ({ onRefresh }) => (
    <div className={classes.noNotifications}>
      <div className={classes.bold}>Page not found</div>
      <div className={classes.text}>Please check your internet connection.</div>
      <div className={classes.homeButton} onClick={onRefresh}>
        <svg
          className="w-6 h-6 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
          />
        </svg>
        Refresh
      </div>
    </div>
  );

  const toggleConnectionsModal = () => {
    if (!showConnectionsModal) {
      setConnectionsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, connectionsModalScrollPosition);
      }, 0);
    }
    setShowConnectionsModal(!showConnectionsModal);
    setIsConnectionsModalOpen(!isConnectionsModalOpen);
  };

  const toggleEditSingleStartupModal = (isSave = false) => {
    if (!showEditSingleStartupModal) {
      // Store current scroll position when opening modal
      setEditSingleStartupModalScrollPosition(window.scrollY);
      // Add no-scroll class to body when opening modal
      document.body.classList.add(classes.bodyNoScroll);
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
    } else {
      // Remove no-scroll class when closing modal
      document.body.classList.remove(classes.bodyNoScroll);
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      // Restore scroll position after modal closes
      setTimeout(() => {
        window.scrollTo(0, editSingleStartupModalScrollPosition);
      }, 0);
    }
    setShowEditSingleStartupModal(!showEditSingleStartupModal);
    setIsEditSingleStartupModalOpen(!isEditSingleStartupModalOpen);
  };

  // Add this handler function
  const handleStartupUpdate = (updatedStartup) => {
    setStartupData((prevData) => ({
      ...prevData,
      ...updatedStartup,
    }));
  };

  const fetchStartupPosts = useCallback(async () => {
    if (!startupData.id || !startupData.ownerId) return;
    setLoadingPosts(true);
    try {
      const postsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/posts`
      );
      const postsSnapshot = await getDocs(
        query(postsRef, orderBy("createdAt", "desc"), limit(20))
      );

      const postsData = postsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          createdAt: data.createdAt?.toDate() || new Date(),
          postPreview: {
            mediaType: data.content?.type || null,
            mediaUrl: data.content?.url || null,
            text: data.text,
            thumbnail: data.content?.thumbnail || null,
          },
          reactions: data.reactions || {},
          reposts: data.reposts || {},
          commentCount: data.commentCount || 0,
        };
      });

      setPosts(postsData);
    } catch (error) {
    } finally {
      setLoadingPosts(false);
    }
  }, [startupData.id, startupData.ownerId]);

  const fetchStartupReactions = useCallback(async () => {
    if (!startupData.id || !startupData.ownerId) return;
    setLoadingReactions(true);
    try {
      // Get all reactions
      const reactionsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/reactedPosts`
      );
      const reactionsQuery = query(
        reactionsRef,
        orderBy("reactedAt", "desc"),
        limit(20)
      );
      const reactionsSnapshot = await getDocs(reactionsQuery);

      const reactionsData = await Promise.all(
        reactionsSnapshot.docs.map(async (docSnapshot) => {
          const reactionData = docSnapshot.data();

          // Get reference to original post
          const originalPostRef = await getOriginalPostRef(
            reactionData.originalPosterId,
            reactionData.postId
          );

          if (!originalPostRef) {
            return null;
          }

          // Get the post data
          const originalPostSnap = await getDoc(originalPostRef);
          if (!originalPostSnap.exists()) {
            return null;
          }

          const originalPostData = originalPostSnap.data();

          // For startup posts, get the startup info
          let startupInfo = null;
          if (reactionData.originalPosterId.startsWith("startup_")) {
            const startupId = reactionData.originalPosterId.replace(
              "startup_",
              ""
            );
            // Get startup info from the path of the post reference
            const pathParts = originalPostRef.path.split("/");
            const userId = pathParts[1]; // users/{userId}/startups/{startupId}/posts/{postId}
            const startupRef = doc(
              firestore,
              `users/${userId}/startups/${startupId}`
            );
            const startupDoc = await getDoc(startupRef);
            if (startupDoc.exists()) {
              startupInfo = startupDoc.data();
            }
          }

          return {
            id: docSnapshot.id,
            ...reactionData,
            originalPost: {
              id: reactionData.postId,
              ...originalPostData,
              postPreview: {
                mediaType: originalPostData.content?.type || null,
                mediaUrl: originalPostData.content?.url || null,
                text: originalPostData.text || "",
                thumbnail: originalPostData.content?.thumbnail || null,
              },
              isStartupPost:
                reactionData.originalPosterId.startsWith("startup_"),
              startupInfo: startupInfo,
            },
            reactedAt: reactionData.reactedAt.toDate(),
            emoji: reactionData.emoji,
          };
        })
      );

      const validReactions = reactionsData.filter(
        (reaction) => reaction !== null
      );

      setReactions(validReactions);
    } catch (error) {
    } finally {
      setLoadingReactions(false);
    }
  }, [startupData.id, startupData.ownerId]);

  // Similar updates for comments and reposts
  const fetchStartupComments = useCallback(async () => {
    if (!startupData.id || !startupData.ownerId) return;
    setLoadingComments(true);
    try {
      const commentsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/commentedPosts`
      );
      const commentsQuery = query(
        commentsRef,
        orderBy("lastCommentedAt", "desc"),
        limit(20)
      );
      const commentsSnapshot = await getDocs(commentsQuery);

      const commentsData = await Promise.all(
        commentsSnapshot.docs.map(async (docSnapshot) => {
          const commentData = docSnapshot.data();
          const originalPostRef = await getOriginalPostRef(
            commentData.originalPosterId,
            commentData.postId
          );

          if (!originalPostRef) return null;

          const originalPostSnap = await getDoc(originalPostRef);
          if (!originalPostSnap.exists()) return null;

          const postData = originalPostSnap.data();

          // Get startup info if it's a startup post
          let startupInfo = null;
          if (commentData.originalPosterId.startsWith("startup_")) {
            const startupId = commentData.originalPosterId.replace(
              "startup_",
              ""
            );
            // Get startup info from the path of the post reference
            const pathParts = originalPostRef.path.split("/");
            const userId = pathParts[1]; // users/{userId}/startups/{startupId}/posts/{postId}
            const startupRef = doc(
              firestore,
              `users/${userId}/startups/${startupId}`
            );
            const startupDoc = await getDoc(startupRef);
            if (startupDoc.exists()) {
              startupInfo = startupDoc.data();
            }
          }

          return {
            id: docSnapshot.id,
            ...commentData,
            originalPost: {
              id: commentData.postId,
              ...postData,
              postPreview: {
                mediaType: postData.content?.type || null,
                mediaUrl: postData.content?.url || null,
                text: postData.text || "",
                thumbnail: postData.content?.thumbnail || null,
              },
              isStartupPost:
                commentData.originalPosterId.startsWith("startup_"),
              startupInfo: startupInfo,
            },
            lastCommentedAt: commentData.lastCommentedAt.toDate(),
          };
        })
      );

      const validComments = commentsData.filter((comment) => comment !== null);
      setCommentedPosts(validComments);
    } catch (error) {
    } finally {
      setLoadingComments(false);
    }
  }, [startupData.id, startupData.ownerId]);

  const fetchStartupReposts = useCallback(async () => {
    if (!startupData.id || !startupData.ownerId) return;
    setLoadingReposts(true);
    try {
      const repostsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/reposts`
      );
      const repostsQuery = query(
        repostsRef,
        orderBy("repostedAt", "desc"),
        limit(20)
      );
      const repostsSnapshot = await getDocs(repostsQuery);

      const repostsData = await Promise.all(
        repostsSnapshot.docs.map(async (docSnapshot) => {
          const repostData = docSnapshot.data();
          const originalPostRef = await getOriginalPostRef(
            repostData.originalPosterId,
            repostData.postId
          );

          if (!originalPostRef) return null;

          const originalPostSnap = await getDoc(originalPostRef);
          if (!originalPostSnap.exists()) return null;

          const originalPostData = originalPostSnap.data();

          // Get startup info if it's a startup post
          let startupInfo = null;
          if (repostData.originalPosterId.startsWith("startup_")) {
            const startupId = repostData.originalPosterId.replace(
              "startup_",
              ""
            );
            // Get startup info from the path of the post reference
            const pathParts = originalPostRef.path.split("/");
            const userId = pathParts[1]; // users/{userId}/startups/{startupId}/posts/{postId}
            const startupRef = doc(
              firestore,
              `users/${userId}/startups/${startupId}`
            );
            const startupDoc = await getDoc(startupRef);
            if (startupDoc.exists()) {
              startupInfo = startupDoc.data();
            }
          }

          return {
            id: docSnapshot.id,
            ...repostData,
            originalPost: {
              id: repostData.postId,
              ...originalPostData,
              postPreview: {
                mediaType: originalPostData.content?.type || null,
                mediaUrl: originalPostData.content?.url || null,
                text: originalPostData.text || "",
                thumbnail: originalPostData.content?.thumbnail || null,
              },
              isStartupPost: repostData.originalPosterId.startsWith("startup_"),
              startupInfo: startupInfo,
            },
            repostedAt: repostData.repostedAt.toDate(),
          };
        })
      );

      const validReposts = repostsData.filter((repost) => repost !== null);
      setReposts(validReposts);
    } catch (error) {
    } finally {
      setLoadingReposts(false);
    }
  }, [startupData.id, startupData.ownerId]);

  const getOriginalPostRef = async (originalPosterId, postId) => {
    try {
      // For startup posts (originalPosterId starts with "startup_")
      if (originalPosterId.startsWith("startup_")) {
        const startupId = originalPosterId.replace("startup_", "");

        // Find the startup owner by searching through all users
        const usersRef = collection(firestore, "users");
        const usersSnapshot = await getDocs(usersRef);

        for (const userDoc of usersSnapshot.docs) {
          const startupRef = doc(
            firestore,
            `users/${userDoc.id}/startups/${startupId}`
          );
          const startupSnap = await getDoc(startupRef);

          if (startupSnap.exists()) {
            // Found the startup, get its post
            const postRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/posts/${postId}`
            );
            const postSnap = await getDoc(postRef);

            if (postSnap.exists()) {
              return postRef;
            }
          }
        }
      } else {
        // For user posts (check both personal posts and startup posts)

        // First check user's startups
        const startupsRef = collection(
          firestore,
          `users/${originalPosterId}/startups`
        );
        const startupsSnapshot = await getDocs(startupsRef);

        // Check each startup's posts
        for (const startupDoc of startupsSnapshot.docs) {
          const postRef = doc(
            firestore,
            `users/${originalPosterId}/startups/${startupDoc.id}/posts/${postId}`
          );
          const postSnap = await getDoc(postRef);

          if (postSnap.exists()) {
            return postRef;
          }
        }

        // If not found in startups, check user's personal posts
        const userPostRef = doc(
          firestore,
          `users/${originalPosterId}/posts/${postId}`
        );
        const userPostSnap = await getDoc(userPostRef);

        if (userPostSnap.exists()) {
          return userPostRef;
        }
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  const fetchStartupActivityCounts = useCallback(async () => {
    if (!startupData.id || !startupData.ownerId) return;
    try {
      // Updated paths to include startups subcollection
      const postsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/posts`
      );
      const postsSnapshot = await getDocs(query(postsRef, limit(1)));
      const postsCount = postsSnapshot.size;

      const reactionsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/reactedPosts`
      );
      const reactionsSnapshot = await getDocs(query(reactionsRef, limit(1)));
      const reactionsCount = reactionsSnapshot.size;

      const repostsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/reposts`
      );
      const repostsSnapshot = await getDocs(query(repostsRef, limit(1)));
      const repostsCount = repostsSnapshot.size;

      const commentsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/commentedPosts`
      );
      const commentsSnapshot = await getDocs(query(commentsRef, limit(1)));
      const commentsCount = commentsSnapshot.size;

      setActivityCounts({
        Posts: postsCount,
        Boosts: reactionsCount,
        Reposts: repostsCount,
        Comments: commentsCount,
      });
    } catch (error) {}
  }, [startupData.id, startupData.ownerId]);

  // Add this with your other helper functions
  const formatJoinDate = (date) => {
    if (!date) return "";
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    // const getOrdinal = (n) => {
    //   const s = ["th", "st", "nd", "rd"];
    //   const v = n % 100;
    //   return s[(v - 20) % 10] || s[v] || s[0];
    // };

    return `${month}, ${year}`;
  };

  useEffect(() => {
    if (startupData.id && startupData.ownerId) {
      if (activityType === "Posts") {
        fetchStartupPosts();
      } else if (activityType === "Boosts") {
        fetchStartupReactions();
      } else if (activityType === "Comments") {
        fetchStartupComments();
      } else if (activityType === "Reposts") {
        fetchStartupReposts();
      }
    }
  }, [
    startupData.id,
    startupData.ownerId,
    activityType,
    fetchStartupPosts,
    fetchStartupReactions,
    fetchStartupComments,
    fetchStartupReposts,
  ]);

  useEffect(() => {
    if (startupData?.id && startupData?.ownerId) {
      const initializeActivityCounts = async () => {
        await fetchStartupActivityCounts();
      };
      initializeActivityCounts();
    }
  }, [startupData?.id, startupData?.ownerId]);

  const toggleIntroModal = () => {
    if (!showIntroModal) {
      setIntroModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, introModalScrollPosition);
      }, 0);
    }
    setShowIntroModal(!showIntroModal);
    setIsIntroModalOpen(!isIntroModalOpen);
  };

  const formatTimestamp = (date) => {
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) return "now";
    if (minutes < 60) return `${minutes}m`;
    if (hours < 24) return `${hours}h`;
    if (days < 7) return `${days}d`;
    if (weeks < 4) return `${weeks}w`;
    if (months < 12) return `${months}mo`;
    return `${years}y`;
  };

  const truncateText = (text, lines) => {
    const words = text.split(" ");
    const truncated = words.slice(0, lines * 10).join(" ");
    return truncated.length < text.length ? `${truncated}...` : truncated;
  };

  const handleReactionsClick = (postId, reactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  const handleRepostsClick = (postId, reposts) => {
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({ postId, reposts });
    setShowRepostsModal(true);
    setIsRepostsModalOpen(true);
  };

  const handlePostClick = async (postId, fromComments = false) => {
    let navigationPath;
    let state = {
      fromStartupReactions: true,
      startupData: {
        startupName: startupData.startupName,
        startupImage: startupData.startupImage,
        startupId: startupData.id,
        startupOwnerId: startupData.ownerId,
        startupUrlSlug: startupData.startupUrlSlug,
      },
    };

    try {
      switch (activityType) {
        case "Boosts":
          // Look up the reaction document
          const reactedPostsRef = collection(
            firestore,
            `users/${startupData.ownerId}/startups/${startupData.id}/reactedPosts`
          );
          const q = query(reactedPostsRef, where("postId", "==", postId));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const reactionDoc = querySnapshot.docs[0];
            navigationPath = `/reaction/${reactionDoc.id}`;
          }
          break;
        case "Comments":
          navigationPath = `/comment/${postId}`;
          state.fromStartupComments = true;
          break;
        case "Reposts":
          navigationPath = `/repost/${postId}`;
          break;
        default:
          navigationPath = `/post/${postId}`;
      }

      navigate(navigationPath, { state });
    } catch (error) {}
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const checkOwnership = useCallback(
    async (startupData) => {
      if (!currentUser) {
        setIsOwner(false);
        return;
      }

      try {
        // Directly check the current user's startups subcollection
        const userStartupsRef = collection(
          firestore,
          `users/${currentUser.uid}/startups`
        );
        const startupsQuery = query(
          userStartupsRef,
          where("startupUrlSlug", "==", startupUrlSlug)
        );
        const startupsSnapshot = await getDocs(startupsQuery);

        setIsOwner(!startupsSnapshot.empty);
      } catch (error) {
        setIsOwner(false);
      }
    },
    [currentUser, startupUrlSlug]
  );

  const checkIfBlocked = async (currentUserId, startupOwnerId) => {
    if (!currentUserId || !startupOwnerId) return;

    try {
      // Check if current user has blocked the startup owner
      const currentUserBlockedRef = collection(
        firestore,
        `users/${currentUserId}/blockedUsers`
      );
      const currentUserBlockedQuery = query(
        currentUserBlockedRef,
        where("userId", "==", startupOwnerId)
      );
      const currentUserBlockedSnap = await getDocs(currentUserBlockedQuery);

      // Check if startup owner has blocked the current user
      const ownerBlockedRef = collection(
        firestore,
        `users/${startupOwnerId}/blockedUsers`
      );
      const ownerBlockedQuery = query(
        ownerBlockedRef,
        where("userId", "==", currentUserId)
      );
      const ownerBlockedSnap = await getDocs(ownerBlockedQuery);

      setHasUserBlockedThem(currentUserBlockedSnap.size > 0);
      setIsBlocked(
        currentUserBlockedSnap.size > 0 || ownerBlockedSnap.size > 0
      );
    } catch (error) {}
  };

  // Use this in useEffect when component mounts or when startup data changes
  useEffect(() => {
    if (currentUser?.uid && startupData?.ownerId) {
      checkIfBlocked(currentUser.uid, startupData.ownerId);
    }
  }, [currentUser?.uid, startupData?.ownerId]);

  const fetchStartupData = async (urlSlug) => {
    updateLoadingState("initial", true);
    try {
      setIsLoading(true);
      const usersRef = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersRef);
      let startupFound = false;

      for (const userDoc of usersSnapshot.docs) {
        const startupsRef = collection(
          firestore,
          `users/${userDoc.id}/startups`
        );
        const startupsQuery = query(
          startupsRef,
          where("startupUrlSlug", "==", urlSlug)
        );
        const startupsSnapshot = await getDocs(startupsQuery);

        if (!startupsSnapshot.empty) {
          startupFound = true;
          const startupDoc = startupsSnapshot.docs[0];
          const data = startupDoc.data();
          const ownerDoc = await getDoc(doc(firestore, "users", userDoc.id));
          const ownerData = ownerDoc.data();

          if (currentUser) {
            const ownerBlockedRef = collection(
              firestore,
              `users/${userDoc.id}/blockedUsers`
            );
            const ownerBlockedQuery = query(
              ownerBlockedRef,
              where("userId", "==", currentUser.uid)
            );
            const ownerBlockedSnap = await getDocs(ownerBlockedQuery);

            const currentUserBlockedRef = collection(
              firestore,
              `users/${currentUser.uid}/blockedUsers`
            );
            const currentUserBlockedQuery = query(
              currentUserBlockedRef,
              where("userId", "==", userDoc.id)
            );
            const currentUserBlockedSnap = await getDocs(
              currentUserBlockedQuery
            );

            setHasUserBlockedThem(currentUserBlockedSnap.size > 0);
            setIsBlocked(
              currentUserBlockedSnap.size > 0 || ownerBlockedSnap.size > 0
            );
          }

          setStartupData({
            id: startupDoc.id,
            ownerId: userDoc.id,
            startupName: data.startupName || "",
            bio: data.bio || "",
            city: data.city || "",
            country: data.country || "",
            countryISO2: data.countryISO2 || "",
            link: data.link || "",
            linkText: data.linkText || "",
            startupImage: data.startupImage || "",
            industry: data.industry || "",
            role: data.role || "",
            intro: data.intro || "",
            introHtml: data.introHtml || "",
            companyType: data.companyType || "",
            followingCount: data.followingCount || 0,
            monthFounded: data.monthFounded || "",
            dayFounded: data.dayFounded || "",
            yearFounded: data.yearFounded || "",
            startupUrlSlug: data.startupUrlSlug || "",
            stripeAccountId: data.stripeAccountId || "",
            stripeAccountStatus: data.stripeAccountStatus || "",
            stripeAccountLink: data.stripeAccountLink || "",
            stripeOnboardingComplete: data.stripeOnboardingComplete || false,
            stripeAccountCreatedAt: data.stripeAccountCreatedAt || null,
            createdAt: data.createdAt || null,
            tags: data.tags || [],
            ownerData: {
              id: userDoc.id,
              firstName: ownerData.firstName || "",
              lastName: ownerData.lastName || "",
              profileImage: ownerData.profileImage || "",
              bio: ownerData.bio || "",
              link: ownerData.link || "",
              linkText: ownerData.linkText || "",
              city: ownerData.city || "",
              country: ownerData.country || "",
              urlSlug: ownerData.urlSlug || "",
            },
          });
          break;
        }
      }

      if (!startupFound) {
        setStartupNotFound(true);
      }
    } catch (error) {
      setStartupNotFound(true);
    } finally {
      updateLoadingState("initial", false);
    }
  };

  const fetchUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setCurrentUser((prevUser) => ({
          ...prevUser,
          ...userData,
        }));
      }
    } catch (error) {}
  };

  useEffect(() => {
    let unsubscribe;
    let isMounted = true;

    const initializeApp = async () => {
      try {
        // Set up auth listener
        unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (!isMounted) return;

          if (user) {
            setCurrentUser(user);
            await fetchUserData(user.uid);
          } else {
            setCurrentUser(null);
            setIsOwner(false);
          }

          // Fetch startup data after auth state is determined
          if (startupUrlSlug) {
            await fetchStartupData(startupUrlSlug);
          }
        });
      } catch (error) {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    initializeApp();

    // Cleanup function
    return () => {
      isMounted = false;
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [startupUrlSlug]); // Remove checkOwnership from dependencies

  const CompletedIcon = () => (
    <svg
      className={classes.checkIcon}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="orangered"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
        clipRule="evenodd"
      />
    </svg>
  );

  const IncompleteIcon = () => (
    <svg
      className={classes.checkIcon}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="orangered"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  );

  const StartupChecklist = ({ startupData, isStripeSetup }) => {
    const [isChecklistOpen, setIsChecklistOpen] = useState(true);
    const [completionStatus, setCompletionStatus] = useState({
      profilePicture: false,
      about: false,
      payments: false,
      tags: false,
    });

    useEffect(() => {
      const hasCustomProfileImage =
        startupData.startupImage &&
        !startupData.startupImage.includes("defaultStartupImage.jpg") &&
        !startupData.startupImage.includes("defaultStartupImage.jpeg") &&
        !startupData.startupImage.includes(
          "startupImages/default/default/default.jpg"
        );

      // Update the Stripe check to look for proper setup
      const hasStripeSetup = !!(
        startupData.stripeAccountId &&
        startupData.stripeAccountStatus === "active" &&
        startupData.stripeOnboardingComplete === true
      );

      setCompletionStatus({
        profilePicture: hasCustomProfileImage,
        about: !!startupData.intro,
        payments: hasStripeSetup, // Use our new check here
        tags: Array.isArray(startupData.tags) && startupData.tags.length > 0,
      });
    }, [startupData]);

    const checklistItems = [
      { id: "profilePicture", text: "Add a logo" },
      { id: "about", text: "Write an about" },
      { id: "tags", text: "Add your tags" },
      { id: "payments", text: "Create a Stripe account" },
    ];

    const isProfileComplete = Object.values(completionStatus).every(
      (status) => status === true
    );

    if (!isProfileComplete) {
      return (
        <div className={classes.card}>
          <div
            className={`${classes.checklist} ${
              isChecklistOpen ? classes.openOpen : ""
            }`}
            onClick={() => setIsChecklistOpen(!isChecklistOpen)}
            style={{ cursor: "pointer" }}
          >
            Checklist
            <div>
              {isChecklistOpen ? (
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m16 14-4-4-4 4"
                  />
                </svg>
              ) : (
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m8 10 4 4 4-4"
                  />
                </svg>
              )}
            </div>
          </div>

          <div
            className={`${classes.checklistContent} ${
              isChecklistOpen ? classes.openOpen : ""
            }`}
          >
            <div
              style={{
                color: "gray",
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              Get started by taking these essential steps:
            </div>

            <div
              style={{
                backgroundColor: "rgba(255, 69, 0, 0.1)",
                padding: "1rem",
                borderRadius: "0.5rem",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <div style={{ textAlign: "left" }}>
                {checklistItems.map((item, index) => (
                  <div
                    key={item.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom:
                        index !== checklistItems.length - 1 ? "0.5rem" : 0,
                    }}
                  >
                    {completionStatus[item.id] ? (
                      <CompletedIcon />
                    ) : (
                      <IncompleteIcon />
                    )}
                    <span
                      style={{
                        color: "orangered",
                        fontWeight: "900",
                      }}
                    >
                      {item.text}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    const checkStartupOwnership = async () => {
      if (currentUser && startupUrlSlug) {
        await checkOwnership();
      }
    };

    checkStartupOwnership();
  }, [currentUser, startupUrlSlug, checkOwnership]);

  const renderDescriptionCard = () => {
    return (
      <div className={classes.card}>
        <div className={classes.aboutCardTitle}>
          About
          {isOwner && (
            <div onClick={toggleIntroModal}>
              <svg
                className={classes.closeIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                />
              </svg>
            </div>
          )}
        </div>
        <div className={classes.introText}>
          {startupData.introHtml ? (
            <>
              <pre>
                {renderIntroText().text}
                {renderIntroText().showMore && (
                  <span
                    className={classes.moreButton}
                    onClick={() => setShowFullIntro(true)}
                  >
                    ...more
                  </span>
                )}
              </pre>
              <div className={classes.noPostBorder}></div>
            </>
          ) : (
            isOwner && (
              <>
                <span className={classes.emptyIntroText}>
                  Tell the world about your startup. What problem are you
                  solving? What's your mission? Share your story and inspire
                  others.
                </span>
                <div className={classes.noPostBorder}></div>
              </>
            )
          )}
        </div>

        <div className={classes.cardAbout}>
          {mutualFollowersCount > 0 && (
            <div
              className={classes.skillContainer}
              onClick={toggleMutualFollowersModal}
              style={{ cursor: "pointer" }}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                  clipRule="evenodd"
                />
              </svg>
              <span className={classes.skillText}>
                {mutualFollowersCount} Mutual{" "}
                {mutualFollowersCount === 1 ? "Follower" : "Followers"}
              </span>
            </div>
          )}
          {/* Location */}
          {(startupData.city || startupData.country) && (
            <div className={classes.skillContainer}>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2a7.865 7.865 0 0 0-8 7.737c0 4.467 4.69 9.831 7.47 12.059a.805.805 0 0 0 1.06 0c2.78-2.228 7.47-7.592 7.47-12.06A7.865 7.865 0 0 0 12 2Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
              </svg>
              <span className={classes.skillText}>
                {startupData.city && startupData.country
                  ? `${startupData.city}, ${startupData.country}`
                  : startupData.city || startupData.country}
              </span>
            </div>
          )}
          {(startupData.monthFounded || startupData.yearFounded) && (
            <div className={classes.skillContainer}>
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span className={classes.skillText}>
                Founded{" "}
                {startupData.monthFounded && startupData.yearFounded
                  ? `${startupData.monthFounded} ${startupData.yearFounded}`
                  : startupData.monthFounded || startupData.yearFounded}
              </span>
            </div>
          )}
          {/* Startup URL */}
          {startupData.startupUrlSlug && (
            <div className={classes.skillContainer}>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
                />
              </svg>
              <span className={classes.skillText}>
                {`foundry.bz/startup/${startupData.startupUrlSlug}`}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderActivityCard = () => {
    const hasAnyActivity = Object.values(activityCounts).some(
      (count) => count > 0
    );

    // For non-owners, only show card if there's activity
    if (!hasAnyActivity && !isOwner) {
      return null;
    }

    return (
      <div className={classes.activityCard}>
        <div
          className={`${classes.activityCardTitle} ${
            !hasAnyActivity && isOwner ? classes.emptyCardTitle : ""
          }`}
        >
          Activity
        </div>

        <ActivityButtons
          selectedActivity={activityType}
          onSelectionChange={handleActivityChange}
          activityCounts={activityCounts}
          showAllButtons={isOwner}
        />

        {activityType === "Posts" && renderPosts()}
        {activityType === "Boosts" && renderReactions()}
        {activityType === "Comments" && renderComments()}
        {activityType === "Reposts" && renderReposts()}
      </div>
    );
  };

  // Inside renderPosts(), update the post container structure:
  {
    posts.slice(0, displayedPosts).map((post, index) => (
      <React.Fragment key={post.id}>
        {index !== 0 && <div className={classes.postBorder}></div>}
        <div className={classes.postContainer}>
          <div className={classes.postHeader}>
            <span className={classes.postAuthor}>
              {isOwner ? "You" : startupData.startupName}
            </span>
            <span className={classes.postMessage}> posted this </span>
            <span className={classes.dot}>•</span>
            <span>{formatTimestamp(post.createdAt)}</span>
          </div>
          <div
            className={`${classes.post} ${
              !post.postPreview.mediaType && post.postPreview.text
                ? classes.textOnlyPost
                : ""
            }`}
            onClick={() => handlePostClick(post.id)}
            style={{ cursor: "pointer" }}
          >
            <div className={classes.postContent}>
              {post.postPreview?.mediaType &&
                renderFilePreview(post.postPreview)}
              {post.postPreview?.text && (
                <p className={classes.textPreview}>
                  {truncateText(post.postPreview.text, 3)}
                </p>
              )}
            </div>
          </div>
          {((post.reactions && Object.keys(post.reactions).length > 0) ||
            (post.reposts && Object.keys(post.reposts).length > 0) ||
            post.commentCount > 0) && (
            <div className={classes.postInteractions}>
              {post.reactions && Object.keys(post.reactions).length > 0 && (
                <ReactionsSummary
                  reactions={post.reactions}
                  post={post}
                  onClick={() => handleReactionsClick(post.id, post.reactions)}
                />
              )}
              <div className={classes.rightInteractions}>
                <PostInteractionCount
                  postId={post.id}
                  reposts={post.reposts}
                  commentCount={post.commentCount}
                  onClick={handleRepostsClick}
                  onCommentClick={() => handlePostClick(post.id)}
                />
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    ));
  }

  useEffect(() => {
    if (activityCounts && !hasActivityTypeInitialized) {
      if (isOwner) {
        // For owners, always default to Posts tab
        setActivityType("Posts");
        setHasActivityTypeInitialized(true);
      } else {
        // For non-owners, find first tab with content
        const activityTypes = ["Posts", "Boosts", "Comments", "Reposts"];
        const firstActiveType = activityTypes.find(
          (type) => activityCounts[type] > 0
        );

        if (firstActiveType) {
          setActivityType(firstActiveType);
          setHasActivityTypeInitialized(true);
        }
      }
    }
  }, [activityCounts, hasActivityTypeInitialized, isOwner]);

  useEffect(() => {
    if (
      !startupData?.id ||
      !startupData?.ownerId ||
      !hasActivityTypeInitialized
    )
      return;

    const fetchInitialActivityData = async () => {
      switch (activityType) {
        case "Posts":
          await fetchStartupPosts();
          break;
        case "Boosts":
          await fetchStartupReactions();
          break;
        case "Comments":
          await fetchStartupComments();
          break;
        case "Reposts":
          await fetchStartupReposts();
          break;
      }
    };

    fetchInitialActivityData();
  }, [
    startupData?.id,
    startupData?.ownerId,
    hasActivityTypeInitialized,
    activityType,
  ]);

  const renderEmptyPosts = () => (
    <div>
      <p className={classes.defaultTextBold}>You haven't posted yet</p>
      <p className={classes.defaultText}>
        Posts you create will be displayed here.
      </p>
      <div className={classes.noPostBorder}></div>
      <div className={classes.postHeader}>
        <span className={classes.postAuthor}>You</span>
        <span className={classes.postMessage}> posted this </span>
        <span className={classes.dot}>•</span>
        <span>now</span>
      </div>
      <div className={classes.noPost}>
        <div className={classes.postContent}>
          <img
            src={greyPostImage}
            alt="Default startup"
            className={classes.noPostImage}
          />
          <p className={classes.noTextPreview}>Post</p>
        </div>
      </div>
      <div className={classes.noPostInteractions}>
        <div>100 boosts</div>
        <span className={classes.dot}>•</span>
        <div>50 comments</div>
      </div>
    </div>
  );

  const renderPosts = () => {
    if (loadingPosts) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    // Ensure posts array exists
    const postsToRender = posts || [];

    return (
      <>
        <div className={classes.posts}>
          {posts.length === 0 ? (
            isOwner ? (
              <div>
                <p className={classes.defaultTextBold}>
                  You haven't posted yet
                </p>
                <p className={classes.defaultText}>
                  Posts you create will be displayed here.
                </p>
                <div className={classes.noPostBorder}></div>
                <div className={classes.postHeader}>
                  <span className={classes.postAuthor}>You</span>
                  <span className={classes.postMessage}> posted this </span>
                  <span className={classes.dot}>•</span>
                  <span>now</span>
                </div>
                <div className={classes.noPost}>
                  <div className={classes.postContent}>
                    <img
                      src={greyPostImage}
                      alt="Default post"
                      className={classes.noPostImage}
                    />
                    <p className={classes.noTextPreview}>Post</p>
                  </div>
                </div>
                <div className={classes.noPostInteractions}>
                  <div>100 boosts</div>
                  <span className={classes.dot}>•</span>
                  <div>50 comments</div>
                </div>
              </div>
            ) : (
              <div>No posts available</div>
            )
          ) : (
            <>
              {posts.slice(0, displayedPosts).map((post, index) => (
                <React.Fragment key={post.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>
                        {isOwner ? "You" : startupData.startupName}
                      </span>
                      <span className={classes.postMessage}> posted this </span>
                      <span className={classes.dot}>•</span>
                      <span>{formatTimestamp(post.createdAt)}</span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !post.postPreview.mediaType && post.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() => handlePostClick(post.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {post.postPreview?.mediaType &&
                          renderFilePreview(post.postPreview)}
                        {post.postPreview?.text && (
                          <p className={classes.textPreview}>
                            {truncateText(post.postPreview.text, 3)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {((post.reactions &&
                    Object.keys(post.reactions).length > 0) ||
                    (post.reposts && Object.keys(post.reposts).length > 0) ||
                    post.commentCount > 0) && (
                    <div className={classes.postInteractions}>
                      {post.reactions &&
                        Object.keys(post.reactions).length > 0 && (
                          <ReactionsSummary
                            reactions={post.reactions}
                            post={post}
                            onClick={() =>
                              handleReactionsClick(post.id, post.reactions)
                            }
                          />
                        )}
                      <div className={classes.rightInteractions}>
                        <PostInteractionCount
                          postId={post.id}
                          reposts={post.reposts}
                          commentCount={post.commentCount}
                          onClick={handleRepostsClick}
                          onCommentClick={() => handlePostClick(post.id)}
                        />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
          {posts.length > 3 && displayedPosts < posts.length && (
            <div
              className={classes.showAllPosts}
              onClick={() =>
                navigate(`/all-posts/startup/${startupData.id}`, {
                  state: {
                    startupView: true,
                    startupId: startupData.id,
                    ownerId: startupData.ownerId,
                  },
                })
              }
            >
              Show all posts
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 12H5m14 0-4 4m4-4-4-4"
                />
              </svg>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderReactions = () => {
    if (loadingReactions) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {reactions.length === 0 ? (
            isOwner ? (
              <div>
                <p className={classes.defaultTextBold}>
                  You haven't boosted yet
                </p>
                <p className={classes.defaultText}>
                  Posts you boost will be displayed here.
                </p>
                <div className={classes.noPostBorder}></div>
                <div className={classes.postHeader}>
                  <span className={classes.postAuthor}>You</span>
                  <span className={classes.postMessage}>boosted this</span>
                  <span className={classes.dot}>•</span>
                  <span>now</span>
                </div>
                <div className={classes.noPost}>
                  <div className={classes.postContent}>
                    <img
                      src={greyPostImage}
                      alt="Default startup"
                      className={classes.noPostImage}
                    />
                    <p className={classes.noTextPreview}>Post</p>
                  </div>
                </div>
                <div className={classes.noPostInteractions}>
                  <div>100 boosts</div>
                  <span className={classes.dot}>•</span>
                  <div>50 comments</div>
                </div>
              </div>
            ) : (
              <div>No reactions available</div>
            )
          ) : (
            <>
              {reactions.slice(0, displayedReactions).map((reaction, index) => (
                <React.Fragment key={reaction.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>
                        {isOwner ? "You" : startupData.startupName}
                      </span>
                      <span className={classes.postMessage}> boosted this</span>
                      <span className={classes.dot}>•</span>
                      <span>{formatTimestamp(reaction.reactedAt)}</span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !reaction.originalPost?.postPreview?.mediaType &&
                        reaction.originalPost?.postPreview?.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() => handlePostClick(reaction.originalPost.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {reaction.originalPost?.postPreview?.mediaType &&
                          renderFilePreview(reaction.originalPost.postPreview)}
                        {reaction.originalPost?.postPreview?.text && (
                          <p className={classes.textPreview}>
                            {truncateText(
                              reaction.originalPost.postPreview.text,
                              3
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {((reaction.originalPost?.reactions &&
                    Object.keys(reaction.originalPost.reactions).length > 0) ||
                    (reaction.originalPost?.reposts &&
                      Object.keys(reaction.originalPost.reposts).length > 0) ||
                    (reaction.originalPost?.commentCount &&
                      reaction.originalPost.commentCount > 0)) && (
                    <div className={classes.postInteractions}>
                      {reaction.originalPost?.reactions &&
                        Object.keys(reaction.originalPost.reactions).length >
                          0 && (
                          <ReactionsSummary
                            reactions={reaction.originalPost.reactions}
                            post={reaction.originalPost}
                            onClick={() =>
                              handleReactionsClick(
                                reaction.originalPost.id,
                                reaction.originalPost.reactions
                              )
                            }
                          />
                        )}
                      <div className={classes.rightInteractions}>
                        <PostInteractionCount
                          postId={reaction.originalPost?.id}
                          reposts={reaction.originalPost?.reposts}
                          commentCount={reaction.originalPost?.commentCount}
                          onClick={handleRepostsClick}
                          onCommentClick={() =>
                            handlePostClick(reaction.originalPost?.id)
                          }
                        />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
          {reactions.length > 3 && displayedReactions < reactions.length && (
            <div
              className={classes.showAllPosts}
              onClick={() =>
                navigate(`/all-reactions/startup/${startupData.id}`, {
                  state: {
                    fromStartupReactions: true,
                    startupData: {
                      startupId: startupData.id,
                      startupName: startupData.startupName,
                      startupImage: startupData.startupImage,
                      startupOwnerId: startupData.ownerId,
                      startupUrlSlug: startupData.startupUrlSlug,
                    },
                  },
                })
              }
            >
              Show all boosts
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 12H5m14 0-4 4m4-4-4-4"
                />
              </svg>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderComments = () => {
    if (loadingComments) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {commentedPosts.length === 0 ? (
            isOwner ? (
              <div>
                <p className={classes.defaultTextBold}>
                  You haven't commented yet
                </p>
                <p className={classes.defaultText}>
                  Posts you comment on will be displayed here.
                </p>
                <div className={classes.noPostBorder}></div>
                <div className={classes.postHeader}>
                  <span className={classes.postAuthor}>You</span>
                  <span className={classes.postMessage}>
                    {" "}
                    commented on this{" "}
                  </span>
                  <span className={classes.dot}>•</span>
                  <span>now</span>
                </div>
                <div className={classes.noPost}>
                  <div className={classes.postContent}>
                    <img
                      src={greyPostImage}
                      alt="Default startup"
                      className={classes.noPostImage}
                    />
                    <p className={classes.noTextPreview}>Post</p>
                  </div>
                </div>
                <div className={classes.noPostInteractions}>
                  <div>100 boosts</div>
                  <span className={classes.dot}>•</span>
                  <div>50 comments</div>
                </div>
              </div>
            ) : (
              <div>No comments available</div>
            )
          ) : (
            <>
              {commentedPosts
                .slice(0, displayedComments)
                .map((comment, index) => (
                  <React.Fragment key={comment.id}>
                    {index !== 0 && <div className={classes.postBorder}></div>}
                    <div>
                      <div className={classes.postHeader}>
                        <span className={classes.postAuthor}>
                          {isOwner ? "You" : startupData.startupName}
                        </span>
                        <span className={classes.postMessage}>
                          {" "}
                          commented on this{" "}
                        </span>
                        <span className={classes.dot}>•</span>
                        <span>{formatTimestamp(comment.lastCommentedAt)}</span>
                      </div>
                      <div
                        className={`${classes.post} ${
                          !comment.originalPost?.postPreview?.mediaType &&
                          comment.originalPost?.postPreview?.text
                            ? classes.textOnlyPost
                            : ""
                        }`}
                        onClick={() =>
                          handlePostClick(comment.originalPost.id, true)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className={classes.postContent}>
                          {comment.originalPost?.postPreview?.mediaType &&
                            renderFilePreview(comment.originalPost.postPreview)}
                          {comment.originalPost?.postPreview?.text && (
                            <p className={classes.textPreview}>
                              {truncateText(
                                comment.originalPost.postPreview.text,
                                3
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {((comment.originalPost?.reactions &&
                      Object.keys(comment.originalPost.reactions).length > 0) ||
                      (comment.originalPost?.reposts &&
                        Object.keys(comment.originalPost.reposts).length > 0) ||
                      (comment.originalPost?.commentCount &&
                        comment.originalPost.commentCount > 0)) && (
                      <div className={classes.postInteractions}>
                        {comment.originalPost?.reactions &&
                          Object.keys(comment.originalPost.reactions).length >
                            0 && (
                            <ReactionsSummary
                              reactions={comment.originalPost.reactions}
                              post={comment.originalPost}
                              onClick={() =>
                                handleReactionsClick(
                                  comment.originalPost.id,
                                  comment.originalPost.reactions
                                )
                              }
                            />
                          )}
                        <div className={classes.rightInteractions}>
                          <PostInteractionCount
                            postId={comment.originalPost?.id}
                            reposts={comment.originalPost?.reposts}
                            commentCount={comment.originalPost?.commentCount}
                            onClick={handleRepostsClick}
                            onCommentClick={() =>
                              handlePostClick(comment.originalPost?.id)
                            }
                          />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </>
          )}
          {commentedPosts.length > 3 &&
            displayedComments < commentedPosts.length && (
              <div
                className={classes.showAllPosts}
                onClick={() =>
                  navigate(`/all-comments/startup/${startupData.id}`, {
                    state: {
                      fromStartupComments: true,
                      startupData: {
                        startupName: startupData.startupName,
                        startupImage: startupData.startupImage,
                        startupId: startupData.id,
                        startupOwnerId: startupData.ownerId,
                        startupUrlSlug: startupData.startupUrlSlug,
                      },
                    },
                  })
                }
              >
                Show all comments
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 12H5m14 0-4 4m4-4-4-4"
                  />
                </svg>
              </div>
            )}
        </div>
      </>
    );
  };

  const renderReposts = () => {
    if (loadingReposts) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {reposts.length === 0 ? (
            isOwner ? (
              <div>
                <p className={classes.defaultTextBold}>
                  You haven't reposted yet
                </p>
                <p className={classes.defaultText}>
                  Posts you repost will be displayed here.
                </p>
                <div className={classes.noPostBorder}></div>
                <div className={classes.postHeader}>
                  <span className={classes.postAuthor}>You</span>
                  <span className={classes.postMessage}> reposted this </span>
                  <span className={classes.dot}>•</span>
                  <span>now</span>
                </div>
                <div className={classes.noPost}>
                  <div className={classes.postContent}>
                    <img
                      src={greyPostImage}
                      alt="Default startup"
                      className={classes.noPostImage}
                    />
                    <p className={classes.noTextPreview}>Post</p>
                  </div>
                </div>
                <div className={classes.noPostInteractions}>
                  <div>100 boosts</div>
                  <span className={classes.dot}>•</span>
                  <div>50 comments</div>
                </div>
              </div>
            ) : (
              <div>No reposts available</div>
            )
          ) : (
            <>
              {reposts.slice(0, displayedReposts).map((repost, index) => (
                <React.Fragment key={repost.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>
                        {isOwner ? "You" : startupData.startupName}
                      </span>
                      <span className={classes.postMessage}>
                        {" "}
                        reposted this{" "}
                      </span>
                      <span className={classes.dot}>•</span>
                      <span>{formatTimestamp(repost.repostedAt)}</span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !repost.originalPost?.postPreview?.mediaType &&
                        repost.originalPost?.postPreview?.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() => handleRepostClick(repost.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {repost.originalPost?.postPreview?.mediaType &&
                          renderFilePreview(repost.originalPost.postPreview)}
                        {repost.originalPost?.postPreview?.text && (
                          <p className={classes.textPreview}>
                            {truncateText(
                              repost.originalPost.postPreview.text,
                              3
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {((repost.originalPost?.reactions &&
                    Object.keys(repost.originalPost.reactions).length > 0) ||
                    (repost.originalPost?.reposts &&
                      Object.keys(repost.originalPost.reposts).length > 0) ||
                    (repost.originalPost?.commentCount &&
                      repost.originalPost.commentCount > 0)) && (
                    <div className={classes.postInteractions}>
                      {repost.originalPost?.reactions &&
                        Object.keys(repost.originalPost.reactions).length >
                          0 && (
                          <ReactionsSummary
                            reactions={repost.originalPost.reactions}
                            post={repost.originalPost}
                            onClick={() =>
                              handleReactionsClick(
                                repost.originalPost.id,
                                repost.originalPost.reactions
                              )
                            }
                          />
                        )}
                      <div className={classes.rightInteractions}>
                        <PostInteractionCount
                          postId={repost.originalPost?.id}
                          reposts={repost.originalPost?.reposts}
                          commentCount={repost.originalPost?.commentCount}
                          onClick={handleRepostsClick}
                          onCommentClick={() =>
                            handlePostClick(repost.originalPost?.id)
                          }
                        />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </div>
        {commentedPosts.length > 3 &&
          displayedComments < commentedPosts.length && (
            <div
              className={classes.showAllPosts}
              onClick={() =>
                navigate(`/all-comments/startup/${startupData.id}`, {
                  state: {
                    fromStartupComments: true,
                    startupData: {
                      startupName: startupData.startupName,
                      startupImage: startupData.startupImage,
                      startupId: startupData.id,
                      startupOwnerId: startupData.ownerId,
                      startupUrlSlug: startupData.startupUrlSlug,
                      bio: startupData.bio || "",
                      link: startupData.link || "",
                      linkText: startupData.linkText || "",
                    },
                  },
                })
              }
            >
              Show all comments
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 12H5m14 0-4 4m4-4-4-4"
                />
              </svg>
            </div>
          )}
      </>
    );
  };

  const handleActivityChange = (selectedActivity) => {
    setActivityType(selectedActivity);
  };

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const renderIntroText = () => {
    const introText = startupData.introHtml || "No description available.";
    const maxLength = 250;

    if (introText.length <= maxLength || showFullIntro) {
      return {
        text: (
          <span
            dangerouslySetInnerHTML={{ __html: introText }}
            onClick={handleLinkClick}
          />
        ),
        showMore: false,
      };
    } else {
      const truncatedText = introText.slice(0, maxLength).trim();
      return {
        text: (
          <span
            dangerouslySetInnerHTML={{ __html: truncatedText }}
            onClick={handleLinkClick}
          />
        ),
        showMore: true,
      };
    }
  };

  const handleReactionClick = (reactionId, emoji) => {
    navigate(`/reaction/${reactionId}`, {
      state: {
        fromStartupReactions: true,
        startupData: {
          startupName: startupData.startupName,
          startupImage: startupData.startupImage,
          startupId: startupData.id,
          startupOwnerId: startupData.ownerId,
          startupUrlSlug: startupData.startupUrlSlug,
        },
        reactionEmoji: emoji,
      },
    });
  };

  const handleRepostClick = (repostId) => {
    navigate(`/repost/${repostId}`, {
      state: {
        fromStartupRepost: true, // Changed from fromStartup to fromStartupRepost
        startupData: {
          startupName: startupData.startupName,
          startupImage: startupData.startupImage,
          startupId: startupData.id,
          startupOwnerId: startupData.ownerId,
          startupUrlSlug: startupData.startupUrlSlug,
        },
      },
    });
  };

  // Add these new functions to handle follow functionality
  const checkFollowStatus = useCallback(async () => {
    if (!currentUser || !startupData.id) return;

    try {
      const followRef = doc(
        firestore,
        `users/${currentUser.uid}/startupsFollowing/${startupData.id}`
      );
      const followDoc = await getDoc(followRef);

      if (followDoc.exists()) {
        setFollowStatus("Following");
      } else {
        setFollowStatus("Follow");
      }
    } catch (error) {
      setFollowStatus("Follow");
    }
  }, [currentUser, startupData.id]);

  const handleFollow = async () => {
    if (!currentUser || !startupData.id || !startupData.ownerId) return;

    const batch = writeBatch(firestore);

    const followRef = doc(
      firestore,
      `users/${currentUser.uid}/startupsFollowing/${startupData.id}`
    );

    const startupRef = doc(
      firestore,
      `users/${startupData.ownerId}/startups/${startupData.id}`
    );

    const userRef = doc(firestore, "users", currentUser.uid);

    const followerRef = doc(
      firestore,
      `users/${startupData.ownerId}/startups/${startupData.id}/followers/${currentUser.uid}`
    );

    try {
      if (followStatus === "Follow") {
        // Add follower data
        const newFollowerData = {
          followedAt: new Date(),
        };

        batch.set(followRef, {
          ownerId: startupData.ownerId,
          followedAt: new Date(),
        });

        batch.set(followerRef, newFollowerData);

        batch.update(startupRef, {
          followingCount: increment(1),
        });

        batch.update(userRef, {
          followingCount: increment(1),
        });

        const notificationRef = doc(
          collection(firestore, `users/${startupData.ownerId}/notifications`)
        );
        batch.set(notificationRef, {
          type: "startup_follow",
          from: currentUser.uid,
          startupId: startupData.id,
          startupName: startupData.startupName,
          startupUrlSlug: startupData.startupUrlSlug,
          startupOwnerId: startupData.ownerId,
          createdAt: new Date(),
          isNew: true,
        });

        await batch.commit();

        // Update local state
        setFollowStatus("Following");
        setStartupData((prev) => ({
          ...prev,
          followingCount: (prev.followingCount || 0) + 1,
        }));

        // Add new follower to the local followers list
        const newFollower = {
          id: currentUser.uid,
          followedAt: new Date(),
          connectionType: "You",
          userData: {
            firstName: currentUser.firstName || "",
            lastName: currentUser.lastName || "",
            profileImage: currentUser.profileImage || "",
            bio: currentUser.bio || "",
            link: currentUser.link || "",
            linkText: currentUser.linkText || "",
            city: currentUser.city || "",
            country: currentUser.country || "",
            urlSlug: currentUser.urlSlug || "",
          },
        };

        // Add to beginning of followers list since it's the newest
        setFollowers((prevFollowers) => [newFollower, ...prevFollowers]);
      } else {
        batch.delete(followRef);
        batch.delete(followerRef);

        batch.update(startupRef, {
          followingCount: increment(-1),
        });

        batch.update(userRef, {
          followingCount: increment(-1),
        });

        const notificationsRef = collection(
          firestore,
          `users/${startupData.ownerId}/notifications`
        );
        const q = query(
          notificationsRef,
          where("type", "==", "startup_follow"),
          where("from", "==", currentUser.uid),
          where("startupId", "==", startupData.id)
        );

        const notificationsSnapshot = await getDocs(q);
        notificationsSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit();

        // Update local state
        setFollowStatus("Follow");
        setStartupData((prev) => ({
          ...prev,
          followingCount: Math.max((prev.followingCount || 0) - 1, 0),
        }));

        // Remove the current user from the followers list
        setFollowers((prevFollowers) =>
          prevFollowers.filter((follower) => follower.id !== currentUser.uid)
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (startupData.id && startupData.ownerId) {
      fetchTeamMembers();
    }
  }, [startupData.id, startupData.ownerId]);

  const isVisibilityToggleDisabled = (donation) => {
    const visibility = donationVisibility[donation.id] || {
      hiddenByStartup: false,
      hiddenByDonor: false,
    };

    // If user is startup owner, disable toggle if donor has hidden it
    if (isOwner) return visibility.hiddenByDonor;

    // If user is donor, disable toggle if startup has hidden it
    if (currentUser?.uid === donation.donorId)
      return visibility.hiddenByStartup;

    return false;
  };

  // Add this useEffect to check follow status when component mounts
  useEffect(() => {
    if (currentUser && startupData.id) {
      checkFollowStatus();
    }
  }, [currentUser, startupData.id, checkFollowStatus]);

  const fetchDonations = async () => {
    if (!startupData.id || !startupData.ownerId) return;

    setLoadingDonations(true);
    try {
      const donationsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/donations`
      );
      const donationsQuery = query(donationsRef, orderBy("createdAt", "desc"));
      const donationsSnapshot = await getDocs(donationsQuery);

      const donationsData = await Promise.all(
        donationsSnapshot.docs.map(async (docSnapshot) => {
          const donationData = docSnapshot.data();

          const userRef = doc(firestore, "users", donationData.donorId);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();

            let connectionType = "Extended";
            if (currentUser?.uid) {
              const isBlocked = await isUserOrStartupsBlocked(
                currentUser.uid,
                donationData.donorId
              );
              if (isBlocked) {
                connectionType = "Blocked";
              } else if (donationData.donorId === currentUser.uid) {
                connectionType = "You";
              } else {
                const directConnectionRef = doc(
                  firestore,
                  `users/${currentUser.uid}/connections/${donationData.donorId}`
                );
                const directConnectionDoc = await getDoc(directConnectionRef);

                if (
                  directConnectionDoc.exists() &&
                  directConnectionDoc.data().status === "Connected"
                ) {
                  connectionType = "Direct";
                } else {
                  const userConnectionsRef = collection(
                    firestore,
                    `users/${currentUser.uid}/connections`
                  );
                  const userConnectionsQuery = query(
                    userConnectionsRef,
                    where("status", "==", "Connected")
                  );
                  const userConnectionsSnapshot = await getDocs(
                    userConnectionsQuery
                  );

                  let isIndirect = false;
                  for (const conn of userConnectionsSnapshot.docs) {
                    const indirectConnectionRef = doc(
                      firestore,
                      `users/${conn.id}/connections/${donationData.donorId}`
                    );
                    const indirectConnectionDoc = await getDoc(
                      indirectConnectionRef
                    );
                    if (
                      indirectConnectionDoc.exists() &&
                      indirectConnectionDoc.data().status === "Connected"
                    ) {
                      isIndirect = true;
                      break;
                    }
                  }
                  connectionType = isIndirect ? "Indirect" : "Extended";
                }
              }
            }

            return {
              id: docSnapshot.id,
              ...donationData,
              connectionType,
              createdAt: donationData.createdAt?.toDate() || new Date(),
              donorName: `${userData.firstName} ${userData.lastName}`,
              donorImage: userData.profileImage || "",
              donorBio: userData.bio || "",
              donorLink: userData.link || "",
              donorLinkText: userData.linkText || "",
              donorUrlSlug: userData.urlSlug || "",
            };
          }
          return null;
        })
      );

      const validDonations = donationsData
        .filter(Boolean)
        .sort((a, b) => b.createdAt - a.createdAt);

      setDonations(validDonations);
    } catch (error) {
    } finally {
      setLoadingDonations(false);
    }
  };

  const [expandedTexts, setExpandedTexts] = useState({});

  const toggleExpandText = useCallback((id) => {
    setExpandedTexts((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  }, []);

  const isDonationVisible = (donation) => {
    const visibility = donationVisibility[donation.id] || {
      hiddenByStartup: false,
      hiddenByDonor: false,
    };

    // Show all donations while in development
    return true;

    // Original logic - we'll uncomment this later
    // // Startup owner can always see all donations
    // if (isOwner) return true;
    //
    // // Donor can always see their own donation
    // if (currentUser?.uid === donation.donorId) return true;
    //
    // // For all other users, hide if either startup or donor has hidden it
    // return !visibility.hiddenByStartup && !visibility.hiddenByDonor;
  };

  // Helper function to determine if donation should appear faded
  const isDonationFaded = (donation) => {
    const visibility = donationVisibility[donation.id] || {
      hiddenByStartup: false,
      hiddenByDonor: false,
    };

    const canToggleVisibility =
      (isOwner && !visibility.hiddenByDonor) ||
      (currentUser?.uid === donation.donorId && !visibility.hiddenByStartup);

    return (
      (visibility.hiddenByStartup || visibility.hiddenByDonor) &&
      !canToggleVisibility
    );
  };

  // Add this useEffect to fetch donations when component mounts
  useEffect(() => {
    if (startupData.id && startupData.ownerId) {
      fetchDonations();
    }
  }, [startupData.id, startupData.ownerId]);

  // Update your formatCurrency helper

  // Replace your existing donations card content with this:
  const renderDonationsCard = () => {
    if (donations.length === 0 && !isOwner) {
      return null;
    }

    return (
      <div className={classes.card}>
        <div
          className={`${classes.cardTitle} ${
            donations.length === 0 ? classes.emptyCardTitle : ""
          }`}
        >
          Supporters
          <img
            className={classes.stripeLogo}
            src="https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/Powered%20by%20Stripe%20-%20blurple.svg?alt=media&token=82bd3fd9-c233-4d10-8220-d7366b037548"
            alt=""
          ></img>
        </div>

        {loadingDonations ? (
          <div className={classes.loadingIndicator}>
            <span
              className={`${classes.loadingIcon} material-symbols-outlined`}
            >
              progress_activity
            </span>
          </div>
        ) : (
          <>
            {/* Stripe Setup Text for Owners */}
            {isOwner &&
              !stripeStatus?.detailsSubmitted &&
              startupData.stripeAccountStatus !== "active" && (
                <div className={classes.supportText}>
                  Set up your payment details to start receiving support from
                  the community.
                </div>
              )}

            {isOwner && renderStripeStatus()}

            {/* Stripe Dashboard Link for Owners */}
            {isOwner &&
              (stripeStatus?.status === "active" ||
                startupData.stripeAccountStatus === "active") && (
                <div className={classes.supportText}>
                  Track and manage donations from supporters in the{" "}
                  <a
                    href="#"
                    onClick={handleDashboardAccess}
                    className={classes.link}
                  >
                    Stripe Dashboard
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="orangeRed"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                      />
                    </svg>
                  </a>
                </div>
              )}

            {/* Empty State Layout */}
            {donations.length === 0 && isOwner && (
              <>
                <div className={classes.noPostBorder}></div>
                <div className={classes.noUserBorder}></div>
                <div className={classes.noUserContainer}>
                  <img
                    src={greyProfileImage}
                    alt="Preview supporter"
                    className={classes.userImage}
                  />
                  <div className={classes.userInfo}>
                    <div className={classes.userNameContainer}>
                      <div className={classes.noStartupRole}>Username</div>
                      <span className={classes.userConnectionType}>
                        • Direct
                      </span>
                    </div>
                    <div className={classes.noStartupName}>Headline</div>
                    <div className={classes.noUserLink}>
                      Link
                      <svg
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                        />
                      </svg>
                    </div>
                    <div className={classes.noMatchTally}>
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H4Zm0 6h16v6H4v-6Z"
                          clipRule="evenodd"
                        />
                        <path
                          fillRule="evenodd"
                          d="M5 14a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Zm5 0a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      US$100.00
                    </div>
                    <div className={classes.noDescription}>Message</div>
                  </div>
                </div>
              </>
            )}

            {donations.length > 0 && (
              <>
                {donations
                  .slice(0, displayedDonations)
                  .map((donation, index) => {
                    const visibility = donationVisibility[donation.id] || {
                      hiddenByStartup: false,
                      hiddenByDonor: false,
                    };

                    if (!isDonationVisible(donation)) return null;

                    const isHidden =
                      visibility.hiddenByStartup || visibility.hiddenByDonor;

                    return (
                      <div
                        key={donation.id}
                        className={`${classes.userContainer} ${
                          index === 0 && isOwner
                            ? classes.ownerFirstDonation
                            : ""
                        }`}
                      >
                        <img
                          src={donation.donorImage || defaultImage}
                          alt={donation.donorName}
                          className={classes.userImage}
                          onClick={() =>
                            !isHidden && navigate(`/${donation.donorUrlSlug}`)
                          }
                          style={{
                            cursor: isHidden ? "default" : "pointer",
                            opacity: isHidden ? 0.5 : 1,
                            pointerEvents: isHidden ? "none" : "auto",
                          }}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = defaultImage;
                          }}
                        />
                        <div className={classes.userInfo}>
                          <div className={classes.userNameContainer}>
                            <div
                              className={classes.userName}
                              onClick={() =>
                                !isHidden &&
                                navigate(`/${donation.donorUrlSlug}`)
                              }
                              style={{
                                cursor: isHidden ? "default" : "pointer",
                                opacity: isHidden ? 0.5 : 1,
                                pointerEvents: isHidden ? "none" : "auto",
                              }}
                            >
                              {donation.donorName}
                            </div>
                            <span className={classes.userConnectionType}>
                              • {donation.connectionType}
                            </span>
                          </div>

                          {donation.donorBio && (
                            <div
                              className={classes.userBio}
                              style={{ opacity: isHidden ? 0.5 : 1 }}
                            >
                              {donation.donorBio}
                            </div>
                          )}

                          {donation.donorLink && (
                            <a
                              href={donation.donorLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={classes.userLink}
                              onClick={(e) => {
                                if (isHidden) {
                                  e.preventDefault();
                                }
                              }}
                              style={{
                                pointerEvents: isHidden ? "none" : "auto",
                                opacity: isHidden ? 0.5 : 1,
                                textDecoration: isHidden ? "none" : "none",
                                cursor: isHidden ? "default" : "pointer",
                              }}
                            >
                              <span>
                                {donation.donorLinkText || donation.donorLink}
                              </span>
                              <svg
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                fill="none"
                                viewBox="0 0 24 24"
                                style={{
                                  opacity: isHidden ? 0.5 : 1,
                                }}
                              >
                                <path
                                  stroke="orangeRed"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                />
                              </svg>
                            </a>
                          )}
                          {/* <div
                            className={classes.dates}
                            style={{ opacity: isHidden ? 0.5 : 1 }}
                          >
                            {formatTimestamp(donation.createdAt)}
                          </div> */}
                          <div
                            className={classes.matchTally}
                            style={{
                              backgroundColor: isHidden
                                ? "rgba(255, 69, 0, 0.1)"
                                : "rgba(255, 69, 0, 0.1)",
                              color: isHidden ? "orangered" : "orangered",
                              opacity: isHidden ? 0.5 : 1,
                            }}
                          >
                            <svg
                              className="w-6 h-6"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H4Zm0 6h16v6H4v-6Z"
                                clipRule="evenodd"
                              />
                              <path
                                fillRule="evenodd"
                                d="M5 14a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Zm5 0a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1Z"
                                clipRule="evenodd"
                              />
                            </svg>
                            {formatCurrency(donation.amount, donation.currency)}
                          </div>
                          {donation.message && (
                            <div className={classes.description}>
                              {expandedDonations[donation.id] ? (
                                <span style={{ opacity: isHidden ? 0.5 : 1 }}>
                                  {donation.message}
                                </span>
                              ) : donation.message.length > 125 ? (
                                <span style={{ opacity: isHidden ? 0.5 : 1 }}>
                                  {donation.message.slice(0, 125).trim()}
                                  <span
                                    className={classes.moreButton}
                                    onClick={(e) => {
                                      if (isHidden) return;
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setExpandedDonations((prev) => ({
                                        ...prev,
                                        [donation.id]: true,
                                      }));
                                    }}
                                    style={{
                                      pointerEvents: isHidden ? "none" : "auto",
                                      cursor: isHidden ? "default" : "pointer",
                                    }}
                                  >
                                    ...more
                                  </span>
                                </span>
                              ) : (
                                <span style={{ opacity: isHidden ? 0.5 : 1 }}>
                                  {donation.message}
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                        {/* <DonationVisibilityToggle
                          isVisible={
                            !(isOwner
                              ? visibility.hiddenByStartup
                              : visibility.hiddenByDonor)
                          }
                          isOwner={isOwner}
                          isDonor={currentUser?.uid === donation.donorId}
                          isDisabled={isVisibilityToggleDisabled(donation)}
                          visibility={visibility}
                          onToggle={(visible) =>
                            handleVisibilityToggle(
                              donation.id,
                              visible,
                              currentUser?.uid === donation.donorId
                            )
                          }
                        /> */}
                      </div>
                    );
                  })}

                {donations.length > 3 &&
                  displayedDonations < donations.length && (
                    <div
                      className={classes.showMoreDonations}
                      onClick={() => {
                        if (displayedDonations >= donations.length) {
                          setDisplayedDonations(3);
                        } else {
                          setDisplayedDonations(
                            Math.min(
                              displayedDonations + displayedDonations * 2,
                              donations.length
                            )
                          );
                        }
                      }}
                    >
                      {displayedDonations >= donations.length
                        ? "Show less"
                        : "Show more"}
                      {displayedDonations >= donations.length ? (
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m5 15 7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 9-7 7-7-7"
                          />
                        </svg>
                      )}
                    </div>
                  )}
              </>
            )}
          </>
        )}
      </div>
    );
  };

  // Add this helper function to format currency
  const formatCurrency = (amount, currency = "USD") => {
    try {
      // Use the currency's locale for proper formatting
      const currencyLocales = {
        // Oceania
        AUD: "en-AU", // Australia
        NZD: "en-NZ", // New Zealand

        // North America
        USD: "en-US", // United States
        CAD: "en-CA", // Canada
        MXN: "es-MX", // Mexico

        // Europe
        GBP: "en-GB", // United Kingdom
        EUR: "en-IE", // Ireland (and other Euro countries)
        CHF: "de-CH", // Switzerland
        NOK: "nb-NO", // Norway
        SEK: "sv-SE", // Sweden
        DKK: "da-DK", // Denmark
        PLN: "pl-PL", // Poland
        CZK: "cs-CZ", // Czech Republic
        RON: "ro-RO", // Romania
        BGN: "bg-BG", // Bulgaria
        HRK: "hr-HR", // Croatia
        HUF: "hu-HU", // Hungary

        // Asia
        JPY: "ja-JP", // Japan
        SGD: "en-SG", // Singapore
        HKD: "zh-HK", // Hong Kong
        THB: "th-TH", // Thailand
        MYR: "ms-MY", // Malaysia
        IDR: "id-ID", // Indonesia
        INR: "en-IN", // India
        AED: "ar-AE", // United Arab Emirates

        // Africa
        ZAR: "en-ZA", // South Africa
        NGN: "en-NG", // Nigeria
        GHS: "en-GH", // Ghana
        KES: "en-KE", // Kenya

        // South America
        BRL: "pt-BR", // Brazil
      };

      const formatted = new Intl.NumberFormat(
        currencyLocales[currency] || undefined,
        {
          style: "currency",
          currency: currency,
          currencyDisplay: "symbol",
        }
      ).format(amount);

      // Remove any spaces between currency symbol and number for all currencies
      return formatted.replace(/^([^\d\s]+)\s+/, "$1");
    } catch (error) {
      return `${currency}${amount}`;
    }
  };

  const BadgesCard = ({
    isOwner,
    isProfileComplete,
    postsCount = 0,
    boostsCount = 0,
    commentsCount = 0,
    teamMembersCount = 0,
    supportersCount = 0,
    followersCount = 0,
    tagsCount = 0,
    classes, // Add classes prop
  }) => {
    const [displayedBadges, setDisplayedBadges] = useState(9);

    const allBadges = [
      {
        id: "complete-curator",
        name: "Complete Curator",
        earned: isProfileComplete,
      },
      {
        id: "top-contributor",
        name: "Top Contributor",
        earned: postsCount >= 250,
      },
      {
        id: "growth-hacker",
        name: "Growth Hacker",
        earned: boostsCount >= 500,
      },
      {
        id: "conversation-starter",
        name: "Conversation Starter",
        earned: commentsCount >= 500,
      },
      {
        id: "all-stars",
        name: "All Stars",
        earned: teamMembersCount >= 10,
      },
      {
        id: "market-validator",
        name: "Market Validator",
        earned: supportersCount >= 50,
      },
      {
        id: "mass-movement",
        name: "Mass Movement",
        earned: followersCount >= 500,
      },
      {
        id: "maximum-exposure",
        name: "Maximum Exposure",
        earned: tagsCount >= 25,
      },
    ];

    const earnedBadges = allBadges.filter((badge) => badge.earned);
    const hasBadges = earnedBadges.length > 0;

    // If not owner and no badges, return null (don't show card)
    if (!isOwner && !hasBadges) {
      return null;
    }

    // For visitors, only show earned badges
    const badgesToShow = isOwner ? allBadges : earnedBadges;

    return (
      <div className={classes.card}>
        <div
          className={`${classes.cardTitle} ${
            !hasBadges ? classes.emptyCardTitle : ""
          }`}
        >
          Badges
          <a
            href="/badges"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.userLink}
          >
            <svg
              style={{ cursor: "pointer" }}
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </a>
        </div>

        <div className={classes.badges}>
          {isOwner && (
            <div className={classes.badgesText}>
              Complete challenges and contribute to the community to earn
              badges. Each badge tells a story of your startup's journey.
            </div>
          )}

          <div className={classes.badgesContainer}>
            {badgesToShow.slice(0, displayedBadges).map((badge) =>
              badge.earned ? (
                <div key={badge.id} className={classes.skillContainer}>
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    style={{ color: "orangered" }}
                  >
                    <path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
                    <path
                      fillRule="evenodd"
                      d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                      clipRule="evenodd"
                    />
                    <path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z" />
                  </svg>
                  <span
                    className={classes.skillText}
                    style={{ color: "orangered", fontWeight: "900" }}
                  >
                    {badge.name}
                  </span>
                </div>
              ) : (
                isOwner && (
                  <div key={badge.id} className={classes.emptySkillContainer}>
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      style={{ color: "gray" }}
                    >
                      <path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
                      <path
                        fillRule="evenodd"
                        d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                        clipRule="evenodd"
                      />
                      <path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z" />
                    </svg>
                    <span className={classes.skillText}>{badge.name}</span>
                  </div>
                )
              )
            )}
          </div>
        </div>

        {badgesToShow.length > 9 && (
          <div
            className={classes.showAllExperiences}
            onClick={() => {
              if (displayedBadges >= badgesToShow.length) {
                setDisplayedBadges(9);
              } else {
                const additionalItems = displayedBadges * 2;
                setDisplayedBadges(
                  Math.min(
                    displayedBadges + additionalItems,
                    badgesToShow.length
                  )
                );
              }
            }}
          >
            {displayedBadges >= badgesToShow.length ? "Show less" : "Show more"}
            {displayedBadges >= badgesToShow.length ? (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m5 15 7-7 7 7"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 9-7 7-7-7"
                />
              </svg>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderBadgesCard = () => {
    const badgeProps = {
      isOwner,
      isProfileComplete:
        !!startupData.intro &&
        !!startupData.startupImage &&
        startupData.tags?.length > 0,
      postsCount: activityCounts.posts || 0,
      boostsCount: activityCounts.reactions || 0,
      commentsCount: activityCounts.comments || 0,
      teamMembersCount: teamMembers.length,
      supportersCount: donations.length,
      followersCount: followers.length,
      tagsCount: startupData.tags?.length || 0,
      classes: classes, // Pass the classes from your module
    };

    return <BadgesCard {...badgeProps} />;
  };

  if (!isFullyLoaded()) {
    return (
      <div className={classes.page}>
        {isMobileView ? (
          <MobileNavbar currentUser={currentUser} />
        ) : (
          <MainNavbar currentUser={currentUser} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  if (startupNotFound) {
    return (
      <div className={classes.page}>
        {isMobileView ? (
          <MobileNavbar currentUser={currentUser} />
        ) : (
          <MainNavbar currentUser={currentUser} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <NoContentCard onRefresh={() => window.location.reload()} />
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  const handleSetupStripeAccount = async () => {
    if (!auth.currentUser || !startupData.id) {
      alert("You must be logged in to set up payments");
      return;
    }

    setIsSettingUpPayment(true);

    try {
      // Force token refresh
      await auth.currentUser.getIdToken(true);

      const createConnectAccount = httpsCallable(
        functions,
        "createConnectAccount"
      );

      const result = await createConnectAccount({
        startupId: startupData.id,
      });

      if (result.data.success) {
        const createAccountLink = httpsCallable(functions, "createAccountLink");
        const linkResult = await createAccountLink({
          startupId: startupData.id, // Add this line
          accountId: result.data.accountId,
          startupUrlSlug: startupData.startupUrlSlug,
          origin: window.location.origin,
        });

        if (linkResult.data.success) {
          window.location.href = linkResult.data.url;
        } else {
          throw new Error(
            linkResult.data.error || "Failed to create account link"
          );
        }

        setStartupData((prev) => ({
          ...prev,
          stripeAccountId: result.data.accountId,
          stripeAccountStatus: "pending",
        }));
      } else {
        throw new Error(result.data.error || "Failed to create Stripe account");
      }
    } catch (error) {
      if (error.code === "functions/unauthenticated") {
        try {
          // Try forcing a new token and retrying once
          await auth.currentUser.getIdToken(true);

          const createConnectAccount = httpsCallable(
            functions,
            "createConnectAccount"
          );
          const retryResult = await createConnectAccount({
            startupId: startupData.id,
          });

          if (retryResult.data.success) {
            // Handle success after retry
            window.location.href = retryResult.data.url;
          } else {
            throw new Error("Retry failed");
          }
        } catch (retryError) {
          alert("Please sign out and back in to refresh your authentication");
        }
      } else {
        alert(`Failed to set up payment account: ${error.message}`);
      }
    } finally {
      setIsSettingUpPayment(false);
    }
  };

  const handleDashboardAccess = async (e) => {
    if (e) {
      e.preventDefault();
    }
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(
        "https://europe-west1-foundry-2024.cloudfunctions.net/createExpressDashboardLinkHttp",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            startupId: startupData.id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.url) {
        // Try opening in a new window first
        const newWindow = window.open(result.url, "_blank");

        // If popup was blocked or failed, fall back to direct navigation
        if (
          newWindow === null ||
          newWindow.closed ||
          typeof newWindow.closed === "undefined"
        ) {
          // For mobile browsers, use direct navigation
          window.location.href = result.url;
        }
      }
    } catch (error) {
      alert("Failed to access dashboard. Please try again.");
    }
  };

  const checkStripeStatus = async () => {
    updateLoadingState("stripeStatus", true);
    try {
      if (!auth.currentUser) {
        throw new Error("No authenticated user");
      }

      const token = await auth.currentUser.getIdToken(true); // Force token refresh

      const response = await fetch(
        "https://europe-west1-foundry-2024.cloudfunctions.net/checkStripeAccountStatusHttp",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            startupId: startupData.id,
            startupOwnerId: startupData.ownerId,
          }),
        }
      );

      const responseText = await response.text();

      let result;
      try {
        result = JSON.parse(responseText);
      } catch (e) {
        throw new Error("Invalid response format from server");
      }

      if (!response.ok) {
        throw new Error(
          result.error || `HTTP error! status: ${response.status}`
        );
      }

      if (!result.success) {
        return;
      }

      if (
        result.status === "active" &&
        startupData.stripeAccountStatus !== "active"
      ) {
        setShowStripeSuccess(true);
      }

      setStripeStatus(result);
      setStartupData((prev) => ({
        ...prev,
        stripeAccountStatus: result.status,
        stripeAccountId: result.accountId,
      }));
    } catch (error) {
      throw error;
    } finally {
      updateLoadingState("stripeStatus", false);
    }
  };

  // Modify the payment setup button/section
  const renderStripeStatus = () => {
    if (
      startupData.stripeAccountId &&
      startupData.stripeAccountStatus === "restricted"
    ) {
      return (
        <button
          className={classes.button}
          onClick={handleSetupStripeAccount}
          disabled={isSettingUpPayment}
        >
          {isSettingUpPayment ? (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.stripeLoadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
              <span className={classes.loadingText}>Loading...</span>
            </div>
          ) : (
            <>
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M2 7c0-1.10457.89543-2 2-2h16c1.1046 0 2 .89543 2 2v4c0 .5523-.4477 1-1 1s-1-.4477-1-1v-1H4v7h10c.5523 0 1 .4477 1 1s-.4477 1-1 1H4c-1.10457 0-2-.8954-2-2V7Z"
                />
                <path
                  fill="currentColor"
                  d="M5 14c0-.5523.44772-1 1-1h2c.55228 0 1 .4477 1 1s-.44772 1-1 1H6c-.55228 0-1-.4477-1-1Zm5 0c0-.5523.4477-1 1-1h4c.5523 0 1 .4477 1 1s-.4477 1-1 1h-4c-.5523 0-1-.4477-1-1Zm9-1c.5523 0 1 .4477 1 1v1h1c.5523 0 1 .4477 1 1s-.4477 1-1 1h-1v1c0 .5523-.4477 1-1 1s-1-.4477-1-1v-1h-1c-.5523 0-1-.4477-1-1s.4477-1 1-1h1v-1c0-.5523.4477-1 1-1Z"
                />
              </svg>
              Create Stripe account
            </>
          )}
        </button>
      );
    }

    if (!startupData.stripeAccountId) {
      return (
        <button
          className={classes.button}
          onClick={handleSetupStripeAccount}
          disabled={isSettingUpPayment}
        >
          {isSettingUpPayment ? (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.stripeLoadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
              <span className={classes.loadingText}>Loading...</span>
            </div>
          ) : (
            <>
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M2 7c0-1.10457.89543-2 2-2h16c1.1046 0 2 .89543 2 2v4c0 .5523-.4477 1-1 1s-1-.4477-1-1v-1H4v7h10c.5523 0 1 .4477 1 1s-.4477 1-1 1H4c-1.10457 0-2-.8954-2-2V7Z"
                />
                <path
                  fill="currentColor"
                  d="M5 14c0-.5523.44772-1 1-1h2c.55228 0 1 .4477 1 1s-.44772 1-1 1H6c-.55228 0-1-.4477-1-1Zm5 0c0-.5523.4477-1 1-1h4c.5523 0 1 .4477 1 1s-.4477 1-1 1h-4c-.5523 0-1-.4477-1-1Zm9-1c.5523 0 1 .4477 1 1v1h1c.5523 0 1 .4477 1 1s-.4477 1-1 1h-1v1c0 .5523-.4477 1-1 1s-1-.4477-1-1v-1h-1c-.5523 0-1-.4477-1-1s.4477-1 1-1h1v-1c0-.5523.4477-1 1-1Z"
                />
              </svg>
              Create Stripe account
            </>
          )}
        </button>
      );
    }

    return null;
  };

  // Add resume setup handler
  const handleResumeStripeSetup = async () => {
    try {
      // Use the imported functions instance directly
      const createResumeLink = httpsCallable(
        functions,
        "createResumeAccountLink"
      );
      const result = await createResumeLink({
        accountId: startupData.stripeAccountId,
        startupUrlSlug: startupData.startupUrlSlug,
        origin: window.location.origin,
      });

      if (result.data.success) {
        window.location.href = result.data.url;
      }
    } catch (error) {
      alert("Failed to resume setup. Please try again.");
    }
  };

  const StripeSuccessMessage = ({ onClose }) => (
    <div className={classes.repostPopup}>
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="orangered"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
            clipRule="evenodd"
          />
        </svg>
        <p>Account successfully created.</p>
      </div>
      <div onClick={onClose} className={classes.repostCloseButton}>
        <svg
          className={classes.repostCloseIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  return (
    <div
      className={`${classes.page} ${
        isEditSingleStartupModalOpen ||
        isAddTeamMemberOpen ||
        isConnectionsModalOpen ||
        isIntroModalOpen ||
        isFollowersModalOpen ||
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isEditTeamModalOpen ||
        isDonationModalOpen ||
        isEditFollowersModalOpen ||
        isAddFollowerModalOpen ||
        isEditTeamMembersModalOpen ||
        isMutualFollowersModalOpen // Add this line
          ? classes.noScroll
          : ""
      }`}
      style={
        isEditSingleStartupModalOpen
          ? { top: `-${editSingleStartupModalScrollPosition}px` }
          : isAddTeamMemberOpen
          ? { top: `-${addTeamMemberScrollPosition}px` }
          : isIntroModalOpen
          ? { top: `-${introModalScrollPosition}px` }
          : isFollowersModalOpen
          ? { top: `-${followersModalScrollPosition}px` }
          : isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isEditTeamModalOpen
          ? { top: `-${editTeamModalScrollPosition}px` }
          : isDonationModalOpen
          ? { top: `-${donationModalScrollPosition}px` }
          : isEditFollowersModalOpen
          ? { top: `-${editFollowersModalScrollPosition}px` }
          : isAddFollowerModalOpen
          ? { top: `-${addFollowerModalScrollPosition}px` }
          : isEditTeamMembersModalOpen
          ? { top: `-${editTeamMembersModalScrollPosition}px` }
          : isMutualFollowersModalOpen // Add this line
          ? { top: `-${mutualFollowersModalScrollPosition}px` } // Add this line
          : {}
      }
    >
      {isMobileView ? (
        <MobileNavbar currentUser={currentUser} />
      ) : (
        <MainNavbar currentUser={currentUser} />
      )}

      {reportMessage && (
        <div className={classes.repostPopup}>
          <div className={classes.repostPopupContent}>
            <svg
              className={classes.tickIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="orangered"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                clipRule="evenodd"
              />
            </svg>
            <p>{reportMessage.text}</p>
          </div>
          <div className={classes.repostCloseButton}>
            <svg
              className={classes.repostCloseIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
              viewBox="0 0 24 24"
              onClick={() => setReportMessage(null)}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
      )}
      {showMutualFollowersModal && (
        <MutualFollowersModal
          onClose={() => {
            setShowMutualFollowersModal(false);
            setIsMutualFollowersModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, mutualFollowersModalScrollPosition);
            }, 0);
          }}
          startupData={startupData}
          currentUser={currentUser}
        />
      )}
      {showRemovalPopup && (
        <RemovalPopup
          message={removalPopupMessage}
          onClose={() => setShowRemovalPopup(false)}
        />
      )}
      {showEditFollowersModal && (
        <EditFollowersModal
          onClose={toggleEditFollowersModal}
          startupData={startupData}
          currentUser={currentUser}
          onFollowerRemoved={(updatedFollowers, removedCount) => {
            setFollowers(updatedFollowers);
            const message =
              removedCount === 1 ? "Follower removed." : "Followers removed.";
            setRemovalPopupMessage(message);
            setShowRemovalPopup(true);
            checkAvailableFollowers();
          }}
        />
      )}
      {showAddFollowerModal && (
        <AddFollowersModal
          onClose={toggleAddFollowerModal}
          startupData={startupData}
          currentUser={currentUser}
          onInviteSent={(count) => {
            setShowInvitePopup(true);
            fetchFollowers(); // Refresh followers list
          }}
        />
      )}
      {showStripeSuccess && (
        <StripeSuccessMessage onClose={() => setShowStripeSuccess(false)} />
      )}
      {showTagsModal && (
        <TagsModal
          onClose={toggleTagsModal} // Remove the fetchStartupData call
          currentUser={currentUser}
          onTagsUpdate={handleStartupUpdate}
          startupId={startupData.id}
          startupOwnerId={startupData.ownerId}
        />
      )}
      {showDonationModal && (
        <DonationModal
          startup={startupData}
          onClose={toggleDonationModal}
          currentUser={currentUser}
          isModalOpen={isDonationModalOpen}
          style={{ top: `-${donationModalScrollPosition}px` }}
        />
      )}
      {showEditSingleStartupModal && (
        <EditSingleStartupModal
          startup={startupData}
          onClose={toggleEditSingleStartupModal}
          onUpdate={handleStartupUpdate}
          currentUser={currentUser}
          isFromStartupPage={true}
        />
      )}
      {showIntroModal && (
        <IntroModal
          onClose={toggleIntroModal}
          currentUser={currentUser}
          isStartup={true}
          startupData={startupData}
          startupOwnerId={startupData.ownerId}
          onProfileUpdate={(updatedData) => {
            setStartupData((prev) => ({
              ...prev,
              intro: updatedData.intro,
              introHtml: updatedData.introHtml,
            }));
            toggleIntroModal();
          }}
        />
      )}
      {showAddTeamMember && (
        <AddTeamMemberModal
          onClose={toggleAddTeamMember}
          startupData={startupData}
          currentUser={currentUser}
          onTeamMemberInviteSent={(count) => {
            setShowInvitePopup(true);
            fetchTeamMembers(); // Refresh the team members list
          }}
        />
      )}
      {showEditTeamMembersModal && (
        <EditTeamMembersModal
          onClose={toggleEditTeamMembersModal}
          startupData={startupData}
          currentUser={currentUser}
          onTeamMemberRemoved={(
            remainingMembers,
            removedCount,
            isSelfRemoval,
            message
          ) => {
            setTeamMembers(remainingMembers);
            setRemovalPopupMessage(message);
            setShowRemovalPopup(true);
          }}
        />
      )}
      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.profileCard}>
            <div className={classes.profileHeader}>
              <div
                className={classes.profileImage}
                onClick={isOwner ? toggleEditSingleStartupModal : undefined}
                style={{ cursor: isOwner ? "pointer" : "default" }}
              >
                <img
                  src={startupData.startupImage || startupImage}
                  alt={startupImage}
                />
              </div>
              {isOwner ? (
                <div onClick={toggleEditSingleStartupModal}>
                  <svg
                    className={classes.closeIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                    />
                  </svg>
                </div>
              ) : (
                <div
                  onClick={() => setShowDropdown(!showDropdown)}
                  className={classes.headerButton}
                  ref={optionsButtonRef}
                >
                  <div
                    className={`${classes.headerIcon} ${
                      showDropdown ? classes.headerIconActive : ""
                    }`}
                  >
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeWidth="3"
                        d="M6 12h.01m6 0h.01m5.99 0h.01"
                      />
                    </svg>
                  </div>
                  {showDropdown && (
                    <div ref={dropdownRef} className={classes.headerDropdown}>
                      <button
                        className={classes.headerButton}
                        onClick={handleReport}
                      >
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                        Report
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className={classes.profileTitle}>
              {startupData.startupName}{" "}
              <span className={classes.connectionType}>• Startup</span>
            </div>

            {(startupData.bio || startupData.link) && (
              <div className={classes.userDetails}>
                {startupData.bio && (
                  <div className={classes.bio}>{startupData.bio}</div>
                )}

                {startupData.link && (
                  <div className={classes.link}>
                    <a
                      href={startupData.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {startupData.linkText || startupData.link}
                    </a>
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="orangeRed"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                      />
                    </svg>
                  </div>
                )}
              </div>
            )}

            <div className={classes.buttons}>
              {!isBlocked && (
                <>
                  {!isOwner && (
                    <button
                      className={
                        followStatus === "Following"
                          ? classes.following
                          : classes.headlineButton
                      }
                      onClick={handleFollow}
                    >
                      {followStatus === "Following" ? (
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                            clipRule="evenodd"
                          />
                          <path
                            fillRule="evenodd"
                            d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                      {followStatus}
                    </button>
                  )}

                  {!isOwner && (
                    <button
                      className={classes.button}
                      onClick={toggleDonationModal}
                      style={{
                        display:
                          startupData.stripeAccountStatus === "active"
                            ? "flex"
                            : "none",
                      }}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7 6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-2v-4a3 3 0 0 0-3-3H7V6Z"
                          clipRule="evenodd"
                        />
                        <path
                          fillRule="evenodd"
                          d="M2 11a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7Zm7.5 1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z"
                          clipRule="evenodd"
                        />
                        <path d="M10.5 14.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
                      </svg>
                      Support
                    </button>
                  )}
                </>
              )}

              {/* Share button always shows, even when blocked */}
              <div
                className={`${
                  shareButtonText === "Copied"
                    ? classes.following
                    : classes.button
                }`}
                onClick={handleShare}
              >
                {shareButtonText === "Copied" ? (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                  </svg>
                )}
                {shareButtonText}
              </div>
            </div>
          </div>
          {isOwner && <StartupChecklist startupData={startupData} />}
          {!isBlocked && (
            <>
              {renderDescriptionCard()}

              {renderActivityCard()}
              <div className={classes.card}>
                <div className={classes.cardTitle}>
                  Team
                  <div className={classes.iconContainer}>
                    {isOwner &&
                      connectionsCount > 0 &&
                      hasAvailableTeamConnections && (
                        <div onClick={toggleAddTeamMember}>
                          <svg
                            className={classes.addIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="gray"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 12h14m-7 7V5"
                            />
                          </svg>
                        </div>
                      )}
                    {teamMembers.length > 0 &&
                      (isOwner ||
                        teamMembers.some(
                          (member) => member.id === currentUser?.uid
                        )) && (
                        <div onClick={toggleEditTeamMembersModal}>
                          <svg
                            className={classes.experienceIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="gray"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                            />
                          </svg>
                        </div>
                      )}
                  </div>
                </div>

                {/* Owner Section */}
                <div className={classes.userContainer}>
                  <img
                    src={startupData.ownerData?.profileImage || defaultImage}
                    alt={
                      startupData.ownerData
                        ? `${startupData.ownerData.firstName} ${startupData.ownerData.lastName}`
                        : "Owner"
                    }
                    className={classes.userImage}
                    onClick={() =>
                      navigate(`/${startupData.ownerData?.urlSlug}`)
                    }
                    style={{ cursor: "pointer" }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultImage;
                    }}
                  />
                  <div className={classes.userInfo}>
                    <div className={classes.userNameContainer}>
                      <div
                        className={classes.userName}
                        onClick={() =>
                          navigate(`/${startupData.ownerData?.urlSlug}`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {startupData.ownerData?.firstName}{" "}
                        {startupData.ownerData?.lastName}
                      </div>
                      <span className={classes.userConnectionType}>
                        •{" "}
                        {currentUser?.uid === startupData.ownerId
                          ? "You"
                          : startupData.ownerConnectionType || "Extended"}
                      </span>
                    </div>

                    {/* Add bio if available */}
                    {startupData.ownerData?.bio && (
                      <div className={classes.userBio}>
                        {startupData.ownerData.bio}
                      </div>
                    )}

                    {/* Add link if available */}
                    {startupData.ownerData?.link && (
                      <a
                        href={startupData.ownerData.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.userLink}
                      >
                        <span>
                          {startupData.ownerData.linkText ||
                            startupData.ownerData.link}
                        </span>
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="orangeRed"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </a>
                    )}

                    <div className={classes.userBadge}>
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12 2c-.791 0-1.55.314-2.11.874l-.893.893a.985.985 0 0 1-.696.288H7.04A2.984 2.984 0 0 0 4.055 7.04v1.262a.986.986 0 0 1-.288.696l-.893.893a2.984 2.984 0 0 0 0 4.22l.893.893a.985.985 0 0 1 .288.696v1.262a2.984 2.984 0 0 0 2.984 2.984h1.262c.261 0 .512.104.696.288l.893.893a2.984 2.984 0 0 0 4.22 0l.893-.893a.985.985 0 0 1 .696-.288h1.262a2.984 2.984 0 0 0 2.984-2.984V15.7c0-.261.104-.512.288-.696l.893-.893a2.984 2.984 0 0 0 0-4.22l-.893-.893a.985.985 0 0 1-.288-.696V7.04a2.984 2.984 0 0 0-2.984-2.984h-1.262a.985.985 0 0 1-.696-.288l-.893-.893A2.984 2.984 0 0 0 12 2Zm3.683 7.73a1 1 0 1 0-1.414-1.413l-4.253 4.253-1.277-1.277a1 1 0 0 0-1.415 1.414l1.985 1.984a1 1 0 0 0 1.414 0l4.96-4.96Z"
                          clip-rule="evenodd"
                        />
                      </svg>

                      <span className={classes.skillText}>Owner</span>
                    </div>
                  </div>
                </div>

                {/* Team Members Section */}
                {teamMembers
                  .slice(0, displayedTeamMembers - 1)
                  .map((member, index) => (
                    <div
                      key={member.id}
                      className={classes.userContainer}
                      style={{
                        borderTop: "0.05rem solid lightgray",
                        marginTop: "1rem",
                        paddingTop: "1rem",
                      }}
                    >
                      <img
                        src={member.userData.profileImage || defaultImage}
                        alt={`${member.userData.firstName} ${member.userData.lastName}`}
                        className={classes.userImage}
                        onClick={() => navigate(`/${member.userData.urlSlug}`)}
                        style={{ cursor: "pointer" }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                      <div className={classes.userInfo}>
                        <div className={classes.userNameContainer}>
                          <div
                            className={classes.userName}
                            onClick={() =>
                              navigate(`/${member.userData.urlSlug}`)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {member.userData.firstName}{" "}
                            {member.userData.lastName}
                          </div>
                          <span className={classes.userConnectionType}>
                            • {member.connectionType}
                          </span>
                        </div>

                        {member.userData.bio && (
                          <div className={classes.userBio}>
                            {member.userData.bio}
                          </div>
                        )}

                        {member.userData.link && (
                          <a
                            href={member.userData.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.userLink}
                          >
                            <span>
                              {member.userData.linkText || member.userData.link}
                            </span>
                            <svg
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="orangeRed"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                              />
                            </svg>
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
                {teamMembers.length > 3 && (
                  <div
                    className={classes.showAllExperiences}
                    onClick={() => {
                      if (displayedTeamMembers >= teamMembers.length) {
                        // Reset to initial count of 3
                        setDisplayedTeamMembers(3);
                      } else {
                        // Double the current visible count
                        setDisplayedTeamMembers(
                          Math.min(
                            displayedTeamMembers + displayedTeamMembers * 2,
                            teamMembers.length
                          )
                        );
                      }
                    }}
                  >
                    {displayedTeamMembers >= teamMembers.length
                      ? "Show less"
                      : "Show more"}
                    {displayedTeamMembers >= teamMembers.length ? (
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m5 15 7-7 7 7"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 9-7 7-7-7"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </div>

              {renderDonationsCard()}
              <div style={{ display: "none" }}>
                Total donations: {donations.length}
                Visible donations:{" "}
                {donations.filter((d) => isDonationVisible(d)).length}
              </div>
              {(followers.length > 0 || isOwner) && (
                <div className={classes.card}>
                  <div
                    className={`${classes.cardTitle} ${
                      followers.length === 0 ? classes.emptyCardTitle : ""
                    }`}
                  >
                    Followers
                    <div className={classes.iconContainer}>
                      {isOwner &&
                        connectionsCount > 0 &&
                        hasAvailableFollowers && (
                          <div onClick={toggleAddFollowerModal}>
                            <svg
                              className={classes.addIcon}
                              width="36"
                              height="36"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 12h14m-7 7V5"
                              />
                            </svg>
                          </div>
                        )}
                      {isOwner && followers.length > 0 && (
                        <div onClick={toggleEditFollowersModal}>
                          <svg
                            className={classes.experienceIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="gray"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>

                  {followers.length === 0 && isOwner ? (
                    <>
                      <div>
                        <p className={classes.defaultText}>
                          Share your startup with the community and build
                          meaningful relationships.
                        </p>
                      </div>
                      <div className={classes.noPostBorder}></div>
                      <div className={classes.noUserContainer}>
                        <img
                          src={greyProfileImage}
                          alt="Preview follower"
                          className={classes.userImage}
                        />
                        <div className={classes.userInfo}>
                          <div className={classes.userNameContainer}>
                            <div className={classes.noStartupRole}>
                              Username
                            </div>
                            <span className={classes.userConnectionType}>
                              • Direct
                            </span>
                          </div>
                          <div className={classes.noStartupName}>Headline</div>
                          <div className={classes.noUserLink}>
                            Link
                            <svg
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {followers
                        .slice(0, displayedFollowers)
                        .map((follower) => (
                          <div
                            key={follower.id}
                            className={classes.userContainer}
                          >
                            <img
                              src={
                                follower.userData.profileImage || defaultImage
                              }
                              alt={`${follower.userData.firstName} ${follower.userData.lastName}`}
                              className={classes.userImage}
                              onClick={() =>
                                navigate(`/${follower.userData.urlSlug}`)
                              }
                              style={{ cursor: "pointer" }}
                            />
                            <div className={classes.userInfo}>
                              <div className={classes.userNameContainer}>
                                <div
                                  className={classes.userName}
                                  onClick={() =>
                                    navigate(`/${follower.userData.urlSlug}`)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {follower.userData.firstName}{" "}
                                  {follower.userData.lastName}
                                </div>
                                <span className={classes.userConnectionType}>
                                  • {follower.connectionType}
                                </span>
                              </div>
                              {follower.userData.bio && (
                                <div className={classes.userBio}>
                                  {follower.userData.bio}
                                </div>
                              )}
                              {follower.userData.link && (
                                <a
                                  href={follower.userData.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={classes.userLink}
                                >
                                  <span>
                                    {follower.userData.linkText ||
                                      follower.userData.link}
                                  </span>
                                  <svg
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      stroke="orangeRed"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                    />
                                  </svg>
                                </a>
                              )}
                            </div>
                          </div>
                        ))}

                      {followers.length > 3 && (
                        <div
                          className={classes.showAllExperiences}
                          onClick={() => {
                            if (displayedFollowers >= followers.length) {
                              setDisplayedFollowers(3);
                            } else {
                              setDisplayedFollowers(
                                Math.min(
                                  displayedFollowers + displayedFollowers * 2,
                                  followers.length
                                )
                              );
                            }
                          }}
                        >
                          {displayedFollowers >= followers.length
                            ? "Show less"
                            : "Show more"}
                          {displayedFollowers >= followers.length ? (
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m5 15 7-7 7 7"
                              />
                            </svg>
                          ) : (
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m19 9-7 7-7-7"
                              />
                            </svg>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          )}
          {!isBlocked && renderBadgesCard()}{" "}
          {/* Put it here, after profile card, before other cards */}
        </div>

        <div className={classes.rightCards}>
          {!isBlocked && (startupData.tags?.length > 0 || isOwner) && (
            <div className={classes.card}>
              <div
                className={`${classes.aboutCardTitle} ${
                  !startupData.tags || startupData.tags.length === 0
                    ? classes.emptyCardTitle
                    : ""
                }`}
              >
                Tags
                {isOwner && (
                  <div onClick={toggleTagsModal}>
                    <svg
                      className={classes.closeIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                      />
                    </svg>
                  </div>
                )}
              </div>
              <div className={classes.cardAbout}>
                {startupData.tags && startupData.tags.length > 0
                  ? startupData.tags
                      .slice(0, visibleTagsCount)
                      .map((tag, index) => (
                        <div key={index} className={classes.skillContainer}>
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                          </svg>
                          <span className={classes.skillText}>{tag}</span>
                        </div>
                      ))
                  : isOwner && (
                      <div className={classes.emptyContainer}>
                        <div className={classes.defaultText}>
                          Add tags to help people find and understand your
                          startup.
                        </div>
                        <div className={classes.defaultContainer}>
                          <div className={classes.emptySkillContainer}>
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                            </svg>
                            <span className={classes.skillText}>Tag</span>
                          </div>
                        </div>
                      </div>
                    )}
              </div>
              {startupData.tags && startupData.tags.length > 9 && (
                <div
                  className={classes.showAllExperiences}
                  onClick={() => {
                    if (visibleTagsCount >= startupData.tags.length) {
                      setVisibleTagsCount(9);
                    } else {
                      setVisibleTagsCount(
                        Math.min(
                          visibleTagsCount + visibleTagsCount * 2,
                          startupData.tags.length
                        )
                      );
                    }
                  }}
                >
                  {visibleTagsCount >= startupData.tags.length
                    ? "Show less"
                    : "Show more"}
                  {visibleTagsCount >= startupData.tags.length ? (
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m5 15 7-7 7 7"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 9-7 7-7-7"
                      />
                    </svg>
                  )}
                </div>
              )}
            </div>
          )}
          <TermsAndConditions />
        </div>

        {showReactionsModal && activePostReactions && (
          <ReactionsModal
            onClose={() => {
              setShowReactionsModal(false);
              setIsReactionsModalOpen(false);
              setTimeout(() => {
                window.scrollTo(0, reactionsModalScrollPosition);
              }, 0);
            }}
            postId={activePostReactions.postId}
            reactions={activePostReactions.reactions}
            currentUserId={currentUser?.uid}
          />
        )}

        {showRepostsModal && activePostReposts && (
          <RepostsModal
            onClose={() => {
              setShowRepostsModal(false);
              setIsRepostsModalOpen(false);
              setTimeout(() => {
                window.scrollTo(0, repostsModalScrollPosition);
              }, 0);
            }}
            postId={activePostReposts.postId}
            reposts={activePostReposts.reposts}
            currentUserId={currentUser?.uid}
          />
        )}
      </div>
      {showInvitePopup && (
        <InvitePopup
          onClose={() => setShowInvitePopup(false)}
          isModalOpen={showConnectionsModal}
        />
      )}
      {showDonationCancelledPopup && (
        <DonationCancelledPopup
          onClose={() => setShowDonationCancelledPopup(false)}
        />
      )}
    </div>
  );
}

export default Startup;
