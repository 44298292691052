import classes from "./Nav.module.css";
import icon from "../../assets/images/fullLogo.png";

function Nav() {
  return (
    <nav className={classes.nav}>
      <a href="/" className={classes.logo}>
        <img src={icon} alt="" className={classes.icon} />
      </a>
      <div className={classes.rightNav}>
        <a href="/login" className={classes.logInButton}>
          Log in
        </a>
        <a href="/signup" className={classes.joinNowButton}>
          Join now
        </a>
      </div>
    </nav>
  );
}

export default Nav;
