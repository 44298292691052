import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { firestore } from "../../firebase";
import defaultImage from "../../assets/icons/profileImage.jpg";
import {
  collection,
  query,
  where,
  getDocs,
  serverTimestamp,
  doc,
  getDoc,
  writeBatch,
} from "firebase/firestore";
import classes from "./AddFollowersModal.module.css";

const AddFollowersModal = ({
  onClose,
  startupData,
  currentUser,
  onInviteSent,
}) => {
  const navigate = useNavigate();
  const [pendingInvites, setPendingInvites] = useState(new Set());
  const [loading, setLoading] = useState(true);
  const [connections, setConnections] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [sendingInvites, setSendingInvites] = useState(false);
  const [totalConnections, setTotalConnections] = useState(0);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    const scrollY = window.scrollY;
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      window.scrollTo(0, scrollY);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const fetchConnections = async () => {
      try {
        setLoading(true);
        const connectionsRef = collection(
          firestore,
          `users/${currentUser.uid}/connections`
        );
        const q = query(connectionsRef, where("status", "==", "Connected"));
        const querySnapshot = await getDocs(q);

        const followersRef = collection(
          firestore,
          `users/${startupData.ownerId}/startups/${startupData.id}/followers`
        );
        const followersSnapshot = await getDocs(followersRef);
        const followerIds = new Set(
          followersSnapshot.docs.map((doc) => doc.id)
        );

        const pendingInviteIds = new Set();
        const checkInvites = await Promise.all(
          querySnapshot.docs.map(async (connectionDoc) => {
            const userNotificationsRef = collection(
              firestore,
              `users/${connectionDoc.id}/notifications`
            );
            const inviteQuery = query(
              userNotificationsRef,
              where("type", "==", "startup_follow_invite"),
              where("startupId", "==", startupData.id)
            );
            const inviteSnapshot = await getDocs(inviteQuery);
            if (!inviteSnapshot.empty) {
              pendingInviteIds.add(connectionDoc.id);
            }
          })
        );
        setPendingInvites(pendingInviteIds);

        const connectionsData = await Promise.all(
          querySnapshot.docs.map(async (connectionDoc) => {
            if (
              connectionDoc.id === startupData.ownerId ||
              followerIds.has(connectionDoc.id)
            ) {
              return null;
            }

            const userDoc = await getDoc(
              doc(firestore, "users", connectionDoc.id)
            );
            if (userDoc.exists()) {
              const userData = userDoc.data();
              return {
                id: connectionDoc.id,
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage,
                bio: userData.bio || "",
                urlSlug: userData.urlSlug || "",
                link: userData.link || null,
                linkText: userData.linkText || null,
                connectionType: "Direct",
                hasPendingInvite: pendingInviteIds.has(connectionDoc.id),
              };
            }
            return null;
          })
        );

        const filteredConnections = connectionsData.filter(Boolean);
        setConnections(filteredConnections);
        setTotalConnections(filteredConnections.length);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchConnections();
  }, [currentUser.uid, startupData.id, startupData.ownerId]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNavigate = (urlSlug) => {
    if (urlSlug) {
      navigate(`/${urlSlug}`);
    }
  };

  const filteredConnections = useMemo(() => {
    return connections.filter((connection) => {
      const fullName =
        `${connection.firstName} ${connection.lastName}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    });
  }, [connections, searchTerm]);

  const handleToggleUser = (userId) => {
    setSelectedUsers((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(userId)) {
        newSet.delete(userId);
      } else {
        newSet.add(userId);
      }
      return newSet;
    });
  };

  const handleSendInvites = async () => {
    setSendingInvites(true);
    try {
      const batch = writeBatch(firestore);

      for (const userId of selectedUsers) {
        const notificationsRef = collection(
          firestore,
          `users/${userId}/notifications`
        );
        const q = query(
          notificationsRef,
          where("type", "==", "startup_follow_invite"),
          where("startupId", "==", startupData.id)
        );
        const existingNotifications = await getDocs(q);

        let notificationRef;
        if (!existingNotifications.empty) {
          notificationRef = existingNotifications.docs[0].ref;
        } else {
          notificationRef = doc(
            collection(firestore, `users/${userId}/notifications`)
          );
        }

        batch.set(notificationRef, {
          type: "startup_follow_invite",
          from: currentUser.uid,
          fromUserName: `${currentUser.firstName} ${currentUser.lastName}`,
          fromUserImage: currentUser.profileImage || defaultImage,
          startupId: startupData.id,
          startupName: startupData.startupName,
          startupOwnerId: startupData.ownerId,
          startupUrlSlug: startupData.startupUrlSlug,
          startupImage: startupData.startupImage,
          message: `${startupData.startupName} wants you to follow.`,
          createdAt: serverTimestamp(),
          isNew: true,
        });

        setPendingInvites((prev) => {
          const newSet = new Set(prev);
          newSet.add(userId);
          return newSet;
        });
      }

      await batch.commit();
      onInviteSent(selectedUsers.size);
      setSelectedUsers(new Set());
    } catch (error) {
    } finally {
      setSendingInvites(false);
    }
  };

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Invite
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>

        <form className={classes.interactions}>
          <div className={classes.connectionsList}>
            {loading ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              </div>
            ) : filteredConnections.length > 0 ? (
              filteredConnections.map((connection) => (
                <div key={connection.id} className={classes.connectionItem}>
                  <img
                    src={connection.profileImage || defaultImage}
                    alt={`${connection.firstName} ${connection.lastName}`}
                    className={classes.profileImage}
                    onClick={() => handleNavigate(connection.urlSlug)}
                  />
                  <div className={classes.connectionInfo}>
                    <h3 className={classes.name}>
                      <div className={classes.nameWrapper}>
                        <span
                          className={classes.nameText}
                          onClick={() => handleNavigate(connection.urlSlug)}
                        >
                          {connection.firstName} {connection.lastName}
                        </span>
                        <span className={classes.connectionType}>
                          • {connection.connectionType}
                        </span>
                      </div>
                    </h3>
                    {connection.bio && (
                      <p className={classes.bio}>{connection.bio}</p>
                    )}
                    {connection.link && (
                      <div className={classes.userLinkContainer}>
                        <a
                          href={connection.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.userLink}
                        >
                          <span className={classes.userLinkText}>
                            {connection.linkText || connection.link}
                          </span>
                          <svg
                            className={classes.userLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                  </div>
                  <div className={classes.checkbox}>
                    <input
                      type="checkbox"
                      checked={
                        selectedUsers.has(connection.id) ||
                        pendingInvites.has(connection.id)
                      }
                      onChange={() =>
                        !pendingInvites.has(connection.id) &&
                        handleToggleUser(connection.id)
                      }
                      disabled={pendingInvites.has(connection.id)}
                      style={{
                        cursor: pendingInvites.has(connection.id)
                          ? "not-allowed"
                          : "pointer",
                      }}
                    />
                    <div>
                      <svg
                        className={classes.checkmark}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke={
                            pendingInvites.has(connection.id) ? "gray" : "white"
                          }
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4"
                          d="M5 11.917 9.724 16.5 19 7.5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.noConnections}></div>
            )}
          </div>
        </form>

        <div className={classes.cardFooter}>
          <button
            className={classes.button}
            disabled={selectedUsers.size === 0 || sendingInvites}
            onClick={handleSendInvites}
          >
            {sendingInvites ? (
              <span
                className={`material-symbols-outlined ${classes.buttonIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Send"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddFollowersModal;
