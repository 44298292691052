import icon from "../../assets/images/foundry.png";

import classes from "./OnboardingNavbar.module.css";
import { Link } from "react-router-dom";

function OnboardingNavbar() {
  return (
    <nav className={classes.nav}>
      <div className={classes.navItems}>
        <Link to="/">
          <img src={icon} alt="" className={classes.icon} />
        </Link>
      </div>
    </nav>
  );
}

export default OnboardingNavbar;
