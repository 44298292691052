import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { firestore } from "../../firebase";
import defaultImage from "../../assets/icons/profileImage.jpg";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import classes from "./MutualFollowersModal.module.css";

const MutualFollowersModal = ({ onClose, startupData, currentUser }) => {
  const [mutualFollowers, setMutualFollowers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [totalMutualFollowers, setTotalMutualFollowers] = useState(0);

  useEffect(() => {
    const fetchMutualFollowers = async () => {
      if (!currentUser?.uid || !startupData?.id || !startupData?.ownerId)
        return;

      try {
        // Get current user's connections
        const userConnectionsRef = collection(
          firestore,
          `users/${currentUser.uid}/connections`
        );
        const userConnectionsQuery = query(
          userConnectionsRef,
          where("status", "==", "Connected")
        );
        const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

        // Create set of connected user IDs (excluding dummyId)
        const userConnections = new Set(
          userConnectionsSnapshot.docs
            .filter((doc) => doc.id !== "dummyId")
            .map((doc) => doc.id)
        );

        // Get startup's followers
        const followersRef = collection(
          firestore,
          `users/${startupData.ownerId}/startups/${startupData.id}/followers`
        );
        const followersSnapshot = await getDocs(followersRef);
        const startupFollowers = new Set(
          followersSnapshot.docs.map((doc) => doc.id)
        );

        // Find mutual followers (intersection of sets)
        const mutualFollowerIds = [...userConnections].filter((id) =>
          startupFollowers.has(id)
        );

        // Fetch detailed data for each mutual follower
        const mutualFollowersData = await Promise.all(
          mutualFollowerIds.map(async (userId) => {
            const userDoc = await getDoc(doc(firestore, "users", userId));

            if (userDoc.exists()) {
              const userData = userDoc.data();

              // Determine connection type
              let connectionType = "Extended";
              if (userId === currentUser.uid) {
                connectionType = "You";
              } else {
                const directConnectionRef = doc(
                  firestore,
                  `users/${currentUser.uid}/connections/${userId}`
                );
                const directConnectionDoc = await getDoc(directConnectionRef);
                if (
                  directConnectionDoc.exists() &&
                  directConnectionDoc.data().status === "Connected"
                ) {
                  connectionType = "Direct";
                }
              }

              return {
                id: userId,
                ...userData,
                connectionType,
              };
            }
            return null;
          })
        );

        const validMutualFollowers = mutualFollowersData.filter(Boolean);
        setMutualFollowers(validMutualFollowers);
        setTotalMutualFollowers(validMutualFollowers.length);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchMutualFollowers();
  }, [currentUser?.uid, startupData?.id, startupData?.ownerId]);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const handleNavigate = async (userId) => {
    onClose();
    if (userId === currentUser?.uid) {
      navigate("/profile");
    } else {
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          navigate(`/${userData.urlSlug}`);
        }
      } catch (error) {}
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredMutualFollowers = useMemo(() => {
    return mutualFollowers.filter((follower) => {
      const fullName =
        `${follower.firstName} ${follower.lastName}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    });
  }, [mutualFollowers, searchTerm]);

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Mutual Followers ({totalMutualFollowers})
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <form className={classes.interactions}>
          <div className={classes.connectionsList}>
            {loading ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              </div>
            ) : filteredMutualFollowers.length > 0 ? (
              filteredMutualFollowers.map((follower) => (
                <div key={follower.id} className={classes.connectionItem}>
                  <img
                    onClick={() => handleNavigate(follower.id)}
                    src={follower.profileImage || defaultImage}
                    alt={`${follower.firstName} ${follower.lastName}`}
                    className={classes.profileImage}
                  />
                  <div className={classes.connectionInfo}>
                    <h3 className={classes.name}>
                      <div className={classes.nameWrapper}>
                        <span
                          className={classes.nameText}
                          onClick={() => handleNavigate(follower.id)}
                        >
                          {follower.firstName} {follower.lastName}
                        </span>
                        <span className={classes.connectionType}>
                          • {follower.connectionType}
                        </span>
                      </div>
                    </h3>
                    <p className={classes.bio}>{follower.bio}</p>
                  </div>
                </div>
              ))
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
};

export default MutualFollowersModal;
