import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./People.module.css";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import { firestore, auth } from "../../../firebase";
import {
  doc,
  getDoc,
  getDocs,
  orderBy,
  startAt,
  collection,
  query,
  where,
  limit,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import profileImage from "../../../assets/icons/profileImage.jpg";
import InterestsDropdown from "../../../components/Dropdowns/InterestsDropdown";
import SkillsDropdown from "../../../components/Dropdowns/SkillsDropdown";
import CompanyDropdown from "../../../components/Dropdowns/CompanyDropdown";
import RoleDropdown from "../../../components/Dropdowns/RoleDropdown";
import SchoolDropdown from "../../../components/Dropdowns/SchoolDropdown";
import DegreeDropdown from "../../../components/Dropdowns/DegreeDropdown";
import SubjectDropdown from "../../../components/Dropdowns/SubjectDropdown";
import CustomDropdown from "../../../components/Dropdowns/CustomDropdown";
import TypeDropdown from "../../../components/Dropdowns/TypeDropdown";
import ConnectionsModal from "../../../components/Modals/ConnectionsModal";
import MutualConnectionsModal from "../../../components/Modals/MutualConnectionsModal";
import FollowModal from "../../../components/Modals/FollowModal";
import algoliasearch from "algoliasearch/lite";

const searchClient = algoliasearch(
  "X6JFTXGTHU",
  "334296000267001b1430be651a4794d9"
);
const index = searchClient.initIndex("users_index");

function People() {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    profileImage: "",
  });

  const [showFollowModal, setShowFollowModal] = useState(false);
  const [selectedUserForFollowing, setSelectedUserForFollowing] =
    useState(null);
  const [followModalScrollPosition, setFollowModalScrollPosition] = useState(0);
  const [isFollowModalOpen, setIsFollowModalOpen] = useState(false);

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [nameInput, setNameInput] = useState("");
  const [isInterestsMenuOpen, setIsInterestsMenuOpen] = useState(false);
  const [interests, setInterests] = useState([]);
  const [isSkillsMenuOpen, setIsSkillsMenuOpen] = useState(false);
  const [skills, setSkills] = useState([]);
  const [isExperienceMenuOpen, setIsExperienceMenuOpen] = useState(false);
  const [company, setCompany] = useState({ object: null, inputValue: "" });
  const [role, setRole] = useState({ object: null, inputValue: "" });
  const [isEducationMenuOpen, setIsEducationMenuOpen] = useState(false);
  const [school, setSchool] = useState({ object: null, inputValue: "" });
  const [degree, setDegree] = useState({ object: null, inputValue: "" });
  const [subject, setSubject] = useState({ object: null, inputValue: "" });
  const [isLocationMenuOpen, setIsLocationMenuOpen] = useState(false);
  const [country, setCountry] = useState({ object: null, inputValue: "" });
  const [city, setCity] = useState({ object: null, inputValue: "" });
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryInputValue, setCountryInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [isTypeMenuOpen, setIsTypeMenuOpen] = useState(false);
  const [connectionType, setConnectionType] = useState("Extended");
  const [hasSearched, setHasSearched] = useState(false);
  const [showConnectionsModal, setShowConnectionsModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [initialResults, setInitialResults] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [connectionsModalScrollPosition, setConnectionsModalScrollPosition] =
    useState(0);
  const [
    mutualConnectionsModalScrollPosition,
    setMutualConnectionsModalScrollPosition,
  ] = useState(0);
  const [isConnectionsModalOpen, setIsConnectionsModalOpen] = useState(false);
  const [isMutualConnectionsModalOpen, setIsMutualConnectionsModalOpen] =
    useState(false);
  const [mutualConnections, setMutualConnections] = useState({});
  const [showMutualConnectionsModal, setShowMutualConnectionsModal] =
    useState(false);
  const userBlockCache = new Map();
  const [blockedUsers, setBlockedUsers] = useState(new Set());

  const [
    selectedUserForMutualConnections,
    setSelectedUserForMutualConnections,
  ] = useState(null);

  useEffect(() => {
    const fetchBlockedUsers = async () => {
      if (!currentUser?.uid) return;

      try {
        const blockedUsersRef = collection(
          firestore,
          `users/${currentUser.uid}/blockedUsers`
        );
        const blockedUsersSnapshot = await getDocs(blockedUsersRef);
        const blockedUserIds = new Set(
          blockedUsersSnapshot.docs.map((doc) => doc.data().userId)
        );
        setBlockedUsers(blockedUserIds);
      } catch (error) {
        setBlockedUsers(new Set());
      }
    };

    fetchBlockedUsers();
  }, [currentUser?.uid]);

  const isUserBlocked = async (currentUserId, targetUserId) => {
    try {
      // Check if current user has blocked target user
      const currentUserBlockedRef = collection(
        firestore,
        `users/${currentUserId}/blockedUsers`
      );
      const currentUserBlockedSnapshot = await getDocs(currentUserBlockedRef);
      const isBlockedByCurrentUser = currentUserBlockedSnapshot.docs.some(
        (doc) => doc.data().userId === targetUserId
      );

      // Check if target user has blocked current user
      const targetUserBlockedRef = collection(
        firestore,
        `users/${targetUserId}/blockedUsers`
      );
      const targetUserBlockedSnapshot = await getDocs(targetUserBlockedRef);
      const isBlockedByTargetUser = targetUserBlockedSnapshot.docs.some(
        (doc) => doc.data().userId === currentUserId
      );

      return isBlockedByCurrentUser || isBlockedByTargetUser;
    } catch (error) {
      return false; // Err on the side of caution - if we can't check, don't show
    }
  };

  const fetchConnectionCount = async (userId) => {
    try {
      const connectionsRef = collection(
        firestore,
        `users/${userId}/connections`
      );
      const q = query(connectionsRef, where("status", "==", "Connected"));
      const querySnapshot = await getDocs(q);
      // Filter out dummyId when counting connections
      return querySnapshot.docs.filter((doc) => doc.id !== "dummyId").length;
    } catch (error) {
      return 0;
    }
  };

  const handleShowFollowingModal = (userId, e) => {
    e.stopPropagation(); // Add this to prevent event bubbling
    setFollowModalScrollPosition(window.scrollY);
    setSelectedUserForFollowing(userId);
    setShowFollowModal(true);
    setIsFollowModalOpen(true);
  };

  const handleCloseFollowModal = () => {
    setShowFollowModal(false);
    setSelectedUserForFollowing(null);
    setIsFollowModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, followModalScrollPosition);
    }, 0);
  };

  const getConnectionTypePriority = (type) => {
    switch (type) {
      case "Direct":
        return 3;
      case "Indirect":
        return 2;
      case "Extended":
        return 1;
      default:
        return 0;
    }
  };

  const toggleFilters = () => {
    setShowFilters((prevState) => !prevState);
  };

  const fetchIndirectConnections = async (userId) => {
    const indirectConnections = [];
    const userConnectionsRef = collection(
      firestore,
      `users/${userId}/connections`
    );
    const userConnectionsSnapshot = await getDocs(
      query(userConnectionsRef, where("status", "==", "Connected"))
    );

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionsRef = collection(
        firestore,
        `users/${conn.id}/connections`
      );
      const indirectConnectionsSnapshot = await getDocs(
        query(indirectConnectionsRef, where("status", "==", "Connected"))
      );

      for (const indirectConn of indirectConnectionsSnapshot.docs) {
        if (
          indirectConn.id !== userId &&
          !indirectConnections.some((user) => user.objectID === indirectConn.id)
        ) {
          const userDoc = await getDoc(
            doc(firestore, "users", indirectConn.id)
          );
          indirectConnections.push({
            objectID: indirectConn.id,
            ...userDoc.data(),
            connectionType: "Indirect",
          });
        }
      }
    }

    return indirectConnections;
  };

  const fetchUsersByCityOrCountry = async (location, type) => {
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(
      query(usersRef, where(type, "==", location))
    );
    return usersSnapshot.docs
      .filter((doc) => doc.id !== currentUser.uid)
      .map((doc) => ({
        objectID: doc.id,
        ...doc.data(),
        connectionType: "Extended",
      }));
  };

  const fetchMutualConnections = async (currentUserId, profileUserId) => {
    try {
      if (!currentUserId || !profileUserId) {
        return [];
      }

      const currentUserConnectionsRef = collection(
        firestore,
        `users/${currentUserId}/connections`
      );
      const profileUserConnectionsRef = collection(
        firestore,
        `users/${profileUserId}/connections`
      );

      const currentUserConnectionsQuery = query(
        currentUserConnectionsRef,
        where("status", "==", "Connected")
      );
      const profileUserConnectionsQuery = query(
        profileUserConnectionsRef,
        where("status", "==", "Connected")
      );

      const [currentUserConnectionsSnapshot, profileUserConnectionsSnapshot] =
        await Promise.all([
          getDocs(currentUserConnectionsQuery),
          getDocs(profileUserConnectionsQuery),
        ]);

      // Filter out dummyId when creating the Sets
      const currentUserConnections = new Set(
        currentUserConnectionsSnapshot.docs
          .map((doc) => doc.id)
          .filter((id) => id !== "dummyId")
      );
      const profileUserConnections = new Set(
        profileUserConnectionsSnapshot.docs
          .map((doc) => doc.id)
          .filter((id) => id !== "dummyId")
      );

      const mutualConnectionIds = [...currentUserConnections].filter((id) =>
        profileUserConnections.has(id)
      );

      const mutualConnectionsData = await Promise.all(
        mutualConnectionIds.map(async (userId) => {
          try {
            const userDoc = await getDoc(doc(firestore, "users", userId));

            if (!userDoc.exists()) {
              return null;
            }

            const userData = userDoc.data();

            return {
              id: userId,
              ...userData,
              firstName: userData.firstName || "",
              lastName: userData.lastName || "",
              profileImage: userData.profileImage || null,
            };
          } catch (error) {
            return null;
          }
        })
      );

      return mutualConnectionsData.filter((connection) => connection !== null);
    } catch (error) {
      return [];
    }
  };

  const fetchAllUsers = async (currentUserId) => {
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(query(usersRef, limit(36)));

    // Get current user's connections
    const currentUserConnections = await getDocs(
      collection(firestore, `users/${currentUserId}/connections`)
    );
    const directConnectionIds = new Set(
      currentUserConnections.docs
        .map((doc) => doc.id)
        .filter((id) => id !== "dummyId")
    );

    // Filter and check blocked status for each user
    const users = [];
    for (const doc of usersSnapshot.docs) {
      if (
        doc.id !== currentUserId &&
        !directConnectionIds.has(doc.id) &&
        doc.id !== "dummyId"
      ) {
        // Check if user is blocked
        const isBlocked = await isUserBlocked(currentUserId, doc.id);

        if (!isBlocked) {
          users.push({
            objectID: doc.id,
            ...doc.data(),
          });
        }
      }
    }

    return users;
  };

  const getConnectionType = async (userId, currentUserId) => {
    const currentUserRef = doc(firestore, `users/${currentUserId}`);
    const connectionRef = doc(currentUserRef, `connections/${userId}`);
    const connectionDoc = await getDoc(connectionRef);

    if (connectionDoc.exists() && connectionDoc.data().status === "Connected") {
      return "Direct";
    }

    // Check for indirect connections
    const currentUserConnections = await getDocs(
      collection(currentUserRef, "connections")
    );
    for (const conn of currentUserConnections.docs) {
      if (conn.data().status === "Connected") {
        const indirectConnectionRef = doc(
          firestore,
          `users/${conn.id}/connections/${userId}`
        );
        const indirectConnectionDoc = await getDoc(indirectConnectionRef);
        if (
          indirectConnectionDoc.exists() &&
          indirectConnectionDoc.data().status === "Connected"
        ) {
          return "Indirect";
        }
      }
    }

    return "Extended";
  };

  const fetchInitialResults = useCallback(async () => {
    if (!currentUser) return;

    setIsInitialLoading(true);
    try {
      // Get current user's connections to exclude them
      const currentUserConnections = await getDocs(
        collection(firestore, `users/${currentUser.uid}/connections`)
      );
      const directConnectionIds = new Set(
        currentUserConnections.docs
          .map((doc) => doc.id)
          .filter((id) => id !== "dummyId")
      );

      const usersQuery = query(
        collection(firestore, "users"),
        where("urlSlug", "!=", ""),
        limit(50)
      );

      const usersSnapshot = await getDocs(usersQuery);

      // Filter out users who are blocked or have blocked us, or don't have names
      const nonBlockedUsers = await Promise.all(
        usersSnapshot.docs
          .filter((doc) => {
            const userData = doc.data();
            return (
              doc.id !== currentUser.uid &&
              !directConnectionIds.has(doc.id) &&
              doc.id !== "dummyId" &&
              userData.firstName?.trim() && // Check for non-empty firstName
              userData.lastName?.trim()
            ); // Check for non-empty lastName
          })
          .map(async (doc) => {
            const isBlocked = await isUserBlocked(currentUser.uid, doc.id);
            if (!isBlocked) {
              return {
                objectID: doc.id,
                ...doc.data(),
              };
            }
            return null;
          })
      );

      const validUsers = nonBlockedUsers
        .filter((user) => user !== null)
        .slice(0, 36);

      // Process the remaining users...
      const processedUsers = await Promise.all(
        validUsers.map(async (user) => {
          const connectionType = await getConnectionType(
            user.objectID,
            currentUser.uid
          );
          const connectionsCount = await fetchConnectionCount(user.objectID);
          const mutualConnectionsData = await fetchMutualConnections(
            currentUser.uid,
            user.objectID
          );

          return {
            ...user,
            connectionType,
            connectionsCount,
            followingCount: user.followingCount || 0,
            mutualConnectionsData,
            mutualConnectionsCount: mutualConnectionsData.length,
          };
        })
      );

      // Sort and set results...
      const sortedResults = processedUsers.sort((a, b) => {
        if (a.mutualConnectionsCount !== b.mutualConnectionsCount) {
          return b.mutualConnectionsCount - a.mutualConnectionsCount;
        }
        return b.connectionsCount - a.connectionsCount;
      });

      setInitialResults(sortedResults);

      // Update mutual connections state...
      const mutualConnectionsObj = {};
      for (const user of sortedResults) {
        mutualConnectionsObj[user.objectID] = user.mutualConnectionsData;
      }
      setMutualConnections(mutualConnectionsObj);
    } catch (error) {
      setInitialResults([]);
    } finally {
      setIsInitialLoading(false);
    }
  }, [
    currentUser,
    getConnectionType,
    fetchConnectionCount,
    fetchMutualConnections,
  ]);

  useEffect(() => {
    if (currentUser?.uid) {
      fetchInitialResults();
    }
  }, [currentUser?.uid]); // Only depend on the user ID changing

  const handleTypeChange = (newType) => {
    setConnectionType(newType);
    setIsChanged(true);
  };

  const toggleTypeMenu = () => {
    setIsTypeMenuOpen(!isTypeMenuOpen);
  };

  const config = {
    cUrl: "https://api.countrystatecity.in/v1",
    ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
  };

  const cityCache = useMemo(() => ({}), []);

  const loadCountries = async () => {
    try {
      const response = await fetch(`${config.cUrl}/countries`, {
        headers: { "X-CSCAPI-KEY": config.ckey },
      });
      const data = await response.json();
      setCountries(Array.isArray(data) ? data : []);
    } catch (error) {
      setCountries([]);
    }
  };

  const loadCities = useCallback(async () => {
    if (!selectedCountry) return;
    try {
      if (cityCache[selectedCountry.iso2]) {
        setCities(cityCache[selectedCountry.iso2]);
      } else {
        const response = await fetch(
          `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
          {
            headers: { "X-CSCAPI-KEY": config.ckey },
          }
        );
        const data = await response.json();
        cityCache[selectedCountry.iso2] = Array.isArray(data) ? data : [];
        setCities(cityCache[selectedCountry.iso2]);
      }
    } catch (error) {
      setCities([]);
    }
  }, [selectedCountry, cityCache]);

  useEffect(() => {
    loadCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      loadCities();
    }
  }, [selectedCountry, loadCities]);

  useEffect(() => {}, [interests]);

  const toggleLocationMenu = () => {
    setIsLocationMenuOpen(!isLocationMenuOpen);
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCityInputValue("");
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
    setIsChanged(checkIfAnyInputHasValue());
  };

  const checkIfAnyInputHasValue = () => {
    return (
      nameInput.trim() !== "" ||
      interests.length > 0 ||
      skills.length > 0 ||
      company.inputValue.trim() !== "" ||
      role.inputValue.trim() !== "" ||
      school.inputValue.trim() !== "" ||
      degree.inputValue.trim() !== "" ||
      subject.inputValue.trim() !== "" ||
      !!selectedCountry ||
      !!selectedCity
    );
  };

  useEffect(() => {
    setIsChanged(checkIfAnyInputHasValue());
  }, [
    nameInput,
    interests,
    skills,
    company,
    role,
    school,
    degree,
    subject,
    selectedCountry,
    selectedCity,
  ]);

  const handleCountryInputChange = (inputValue) => {
    setCountry((prev) => ({ ...prev, inputValue }));
  };

  const handleCityInputChange = (inputValue) => {
    setCity((prev) => ({ ...prev, inputValue }));
  };

  const toggleInterestsMenu = () => {
    setIsInterestsMenuOpen(!isInterestsMenuOpen);
  };

  const handleSkillsChange = (newSkills) => {
    setSkills(newSkills);
    setIsChanged(newSkills.length > 0 || checkIfAnyInputHasValue());
  };

  const handleInterestsChange = (newInterests) => {
    setInterests(newInterests);
    setIsChanged(newInterests.length > 0 || checkIfAnyInputHasValue());
  };

  const toggleSkillsMenu = () => {
    setIsSkillsMenuOpen(!isSkillsMenuOpen);
  };

  const toggleExperienceMenu = () => {
    setIsExperienceMenuOpen(!isExperienceMenuOpen);
  };

  const handleCompanyChange = (newCompany) => {
    setCompany({
      object: newCompany,
      inputValue: newCompany ? newCompany.name.slice(0, 50) : "",
    });
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleRoleChange = (newRole) => {
    setRole({
      object: newRole,
      inputValue: newRole ? newRole.name.slice(0, 50) : "",
    });
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleCompanyInputChange = (inputValue) => {
    setCompany((prev) => ({ ...prev, inputValue: inputValue.slice(0, 50) }));
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleRoleInputChange = (inputValue) => {
    setRole((prev) => ({ ...prev, inputValue: inputValue.slice(0, 50) }));
    setIsChanged(checkIfAnyInputHasValue());
  };

  const toggleEducationMenu = () => {
    setIsEducationMenuOpen(!isEducationMenuOpen);
  };

  const handleSchoolChange = (newSchool) => {
    setSchool({
      object: newSchool,
      inputValue: newSchool ? newSchool.name : "",
    });
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleDegreeChange = (newDegree) => {
    setDegree({
      object: newDegree,
      inputValue: newDegree ? newDegree.name : "",
    });
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleSubjectChange = (newSubject) => {
    setSubject({
      object: newSubject,
      inputValue: newSubject ? newSubject.name : "",
    });
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleSchoolInputChange = (inputValue) => {
    setSchool((prev) => ({ ...prev, inputValue }));
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleDegreeInputChange = (inputValue) => {
    setDegree((prev) => ({ ...prev, inputValue }));
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleSubjectInputChange = (inputValue) => {
    setSubject((prev) => ({ ...prev, inputValue }));
    setIsChanged(checkIfAnyInputHasValue());
  };

  const handleNameInputChange = (e) => {
    const value = e.target.value;
    setNameInput(value);
    setIsChanged(value.trim() !== "");
  };

  const handleUserClick = (user) => {
    // Save the current state to localStorage
    localStorage.setItem(
      "peoplePageState",
      JSON.stringify({
        nameInput,
        interests,
        skills,
        company,
        role,
        school,
        degree,
        subject,
        selectedCountry,
        selectedCity,
        connectionType,
        searchResults,
        hasSearched,
        scrollPosition: window.scrollY,
        isReturning: true,
        mutualConnections,
        // Also save the lastSearchCriteria
        lastSearchCriteria,
      })
    );
    navigate(`/${user.urlSlug}`, {
      state: { userData: { id: user.objectID, ...user } },
    });
  };

  const handleInputChange = (inputValue, type) => {
    if (type === "skills") {
      if (inputValue.trim() === "") {
        setSkills([]);
      } else {
        setSkills([{ name: inputValue, id: inputValue }]);
      }
    } else if (type === "interests") {
      if (inputValue.trim() === "") {
        setInterests([]);
      } else {
        setInterests([{ name: inputValue, id: inputValue }]);
      }
    }
    setIsChanged(checkIfAnyInputHasValue());
  };

  useEffect(() => {
    const testSearch = async () => {
      try {
        const { hits } = await index.search("");
      } catch (error) {}
    };
    testSearch();
  }, []);

  const scoreMatch = (
    user,
    searchInterests,
    searchSkills,
    company,
    role,
    school,
    degree,
    subject,
    searchCountry,
    searchCity
  ) => {
    let matches = 0;

    const nameParts = nameInput.toLowerCase().trim().split(" ");

    if (nameInput) {
      if (user.firstName?.toLowerCase().trim().includes(nameParts[0]))
        matches++;
      if (
        user.lastName
          ?.toLowerCase()
          .trim()
          .includes(nameParts[1] || "")
      )
        matches++;
      if (
        (user.firstName + " " + user.lastName)
          ?.toLowerCase()
          .trim()
          .includes(nameInput.toLowerCase().trim())
      )
        matches++;
    }

    searchInterests.forEach((interest) => {
      if (
        (user.interests || []).some((ui) =>
          ui.toLowerCase().includes(interest.name.toLowerCase())
        )
      ) {
        matches++;
      }
    });

    searchSkills.forEach((skill) => {
      if (
        (user.skills || []).some((us) =>
          us.toLowerCase().includes(skill.name.toLowerCase())
        )
      ) {
        matches++;
      }
    });

    if (
      company.inputValue &&
      user.experiences?.some((exp) =>
        exp.organisation
          ?.toLowerCase()
          .includes(company.inputValue.toLowerCase())
      )
    ) {
      matches++;
    }

    if (
      role.inputValue &&
      user.experiences?.some((exp) =>
        exp.role?.toLowerCase().includes(role.inputValue.toLowerCase())
      )
    ) {
      matches++;
    }

    if (
      school.inputValue &&
      user.education?.some((edu) =>
        edu.school?.toLowerCase().includes(school.inputValue.toLowerCase())
      )
    ) {
      matches++;
    }

    if (
      degree.inputValue &&
      user.education?.some((edu) =>
        edu.degree?.toLowerCase().includes(degree.inputValue.toLowerCase())
      )
    ) {
      matches++;
    }

    if (
      subject.inputValue &&
      user.education?.some((edu) =>
        edu.subject?.toLowerCase().includes(subject.inputValue.toLowerCase())
      )
    ) {
      matches++;
    }

    if (
      searchCountry?.name &&
      user.country?.toLowerCase() === searchCountry.name.toLowerCase()
    )
      matches++;
    if (
      searchCity?.name &&
      user.city?.toLowerCase() === searchCity.name.toLowerCase()
    )
      matches++;

    return matches;
  };

  const [lastSearchCriteria, setLastSearchCriteria] = useState({
    nameInput: "",
    interests: [],
    skills: [],
    company: { object: null, inputValue: "" },
    role: { object: null, inputValue: "" },
    school: { object: null, inputValue: "" },
    degree: { object: null, inputValue: "" },
    subject: { object: null, inputValue: "" },
    selectedCountry: null,
    selectedCity: null,
  });

  const handleSearch = useCallback(async () => {
    if (!isChanged) return;
    setIsSearching(true);
    setHasSearched(true);

    setLastSearchCriteria({
      nameInput,
      interests,
      skills,
      company,
      role,
      school,
      degree,
      subject,
      selectedCountry,
      selectedCity,
    });

    try {
      const searchParams = {
        hitsPerPage: 1000,
        attributesToRetrieve: [
          "firstName",
          "lastName",
          "bio",
          "city",
          "country",
          "profileImage",
          "interests",
          "skills",
          "education",
          "experiences",
          "connectionsCount",
          "urlSlug",
        ],
      };

      const filterConditions = [];
      const orConditions = [];

      // Add name search if provided
      if (nameInput) {
        searchParams.query = nameInput;
      }

      // Add interests filter
      if (interests.length > 0) {
        const interestFilters = interests.map((i) => `interests:"${i.name}"`);
        orConditions.push(`(${interestFilters.join(" OR ")})`);
      }

      // Add skills filter
      if (skills.length > 0) {
        const skillFilters = skills.map((s) => `skills:"${s.name}"`);
        orConditions.push(`(${skillFilters.join(" OR ")})`);
      }

      // Add education filters
      if (school.object?.name) {
        orConditions.push(`education.school:"${school.object.name}"`);
      }
      if (degree.object?.name) {
        orConditions.push(`education.degree:"${degree.object.name}"`);
      }
      if (subject.object?.name) {
        orConditions.push(`education.subject:"${subject.object.name}"`);
      }

      // Add experience filters
      if (company.object?.name) {
        orConditions.push(`experiences.organisation:"${company.object.name}"`);
      }
      if (role.object?.name) {
        orConditions.push(`experiences.role:"${role.object.name}"`);
      }

      // Add location filters
      if (selectedCountry) {
        orConditions.push(`country:"${selectedCountry.name}"`);
      }
      if (selectedCity) {
        orConditions.push(`city:"${selectedCity.name}"`);
      }

      // Combine all filters
      if (orConditions.length > 0) {
        searchParams.filters = orConditions.join(" OR ");
      }

      // Perform the search
      let { hits } = await index.search(searchParams.query || "", searchParams);

      // Filter out the current user and check for blocked status
      const nonBlockedHits = await Promise.all(
        hits.map(async (hit) => {
          // Skip current user
          if (hit.objectID === currentUser.uid) return null;

          // Check if either user has blocked the other
          const isBlocked = await isUserBlocked(currentUser.uid, hit.objectID);
          if (isBlocked) return null;

          return hit;
        })
      );

      // Remove null entries from blocked users
      const filteredHits = nonBlockedHits.filter((hit) => hit !== null);

      // Process the remaining hits
      const updatedHits = await Promise.all(
        filteredHits.map(async (hit) => {
          // Get connection type
          const connectionType = await getConnectionType(
            hit.objectID,
            currentUser.uid
          );

          // Get connections count
          const connectionsCount = await fetchConnectionCount(hit.objectID);

          // Get user document for additional data
          const userDoc = await getDoc(doc(firestore, "users", hit.objectID));
          const userData = userDoc.data();

          // Get mutual connections
          let mutualConnectionsData = [];
          if (connectionType !== "Direct") {
            mutualConnectionsData = await fetchMutualConnections(
              currentUser.uid,
              hit.objectID
            );
          }

          // Calculate match score
          const matches = scoreMatch(
            { ...hit, ...userData },
            lastSearchCriteria.interests,
            lastSearchCriteria.skills,
            lastSearchCriteria.company,
            lastSearchCriteria.role,
            lastSearchCriteria.school,
            lastSearchCriteria.degree,
            lastSearchCriteria.subject,
            lastSearchCriteria.selectedCountry,
            lastSearchCriteria.selectedCity
          );

          return {
            ...hit,
            ...userData,
            connectionType,
            connectionsCount,
            mutualConnectionsData,
            mutualConnectionsCount: mutualConnectionsData.length,
            matches,
          };
        })
      );

      // Sort results based on multiple criteria
      const sortedResults = [...updatedHits].sort((a, b) => {
        // First sort by number of matches
        if (b.matches !== a.matches) return b.matches - a.matches;

        // Then by location (prioritize same country/city as current user)
        if (
          a.country === currentUser.country &&
          b.country === currentUser.country
        ) {
          if (a.city === currentUser.city && b.city === currentUser.city) {
            // If same city, sort by mutual connections
            if (
              a.mutualConnectionsData.length !== b.mutualConnectionsData.length
            ) {
              return (
                b.mutualConnectionsData.length - a.mutualConnectionsData.length
              );
            }
            // If same number of mutual connections, sort by total connections
            return b.connectionsCount - a.connectionsCount;
          } else if (a.city === currentUser.city) return -1;
          else if (b.city === currentUser.city) return 1;
        } else if (a.country === currentUser.country) return -1;
        else if (b.country === currentUser.country) return 1;

        // Finally, sort by total connections
        return b.connectionsCount - a.connectionsCount;
      });

      setSearchResults(sortedResults);
    } catch (error) {
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  }, [
    nameInput,
    interests,
    skills,
    school,
    degree,
    subject,
    company,
    role,
    selectedCountry,
    selectedCity,
    currentUser,
    getConnectionType,
    fetchConnectionCount,
    fetchMutualConnections,
    scoreMatch,
    isChanged,
    lastSearchCriteria,
    isUserBlocked,
  ]);

  useEffect(() => {
    let timeoutId;
    const handleScroll = () => {
      // We use debounce to avoid excessive updates
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const currentState = JSON.parse(
          localStorage.getItem("peoplePageState") || "{}"
        );
        localStorage.setItem(
          "peoplePageState",
          JSON.stringify({
            ...currentState,
            scrollPosition: window.scrollY,
          })
        );
      }, 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (isSearching) {
      const timer = setTimeout(() => {
        handleSearch();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isSearching, handleSearch]);

  const triggerSearch = () => {
    if (isChanged) {
      setIsSearching(true);
      handleSearch();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser({ uid: user.uid });
        fetchUserData(user.uid);
      } else {
        setCurrentUser(null);
        setUserData({
          firstName: "",
          lastName: "",
          city: "",
          country: "",
          bio: "",
          profileImage: "",
        });
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async (uid) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserData(userData);

        // Fetch connections
        const connectionsSnapshot = await getDocs(
          collection(firestore, "users", uid, "connections")
        );
        const connections = connectionsSnapshot.docs.map((doc) => doc.id);
        setCurrentUser({ uid, connections });
      }
    } catch (error) {}
  };

  const userImage = userData.profileImage || profileImage;

  const shouldShowMatchTally = () => {
    const hasSearchCriteria =
      lastSearchCriteria.interests.length > 0 ||
      lastSearchCriteria.skills.length > 0 ||
      lastSearchCriteria.company.object ||
      lastSearchCriteria.role.object ||
      lastSearchCriteria.school.object ||
      lastSearchCriteria.degree.object ||
      lastSearchCriteria.subject.object ||
      lastSearchCriteria.selectedCountry ||
      lastSearchCriteria.selectedCity;

    return hasSearched && !isSearching && hasSearchCriteria;
  };

  const handleOpenConnectionsModal = (userId) => {
    setConnectionsModalScrollPosition(window.scrollY);
    setSelectedUserId(userId);
    setShowConnectionsModal(true);
    setIsConnectionsModalOpen(true);
  };

  const handleCloseConnectionsModal = () => {
    setShowConnectionsModal(false);
    setSelectedUserId(null);
    setIsConnectionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, connectionsModalScrollPosition);
    }, 0);
  };

  const handleOpenMutualConnectionsModal = (userId) => {
    setMutualConnectionsModalScrollPosition(window.scrollY);
    setSelectedUserForMutualConnections(userId);
    setShowMutualConnectionsModal(true);
    setIsMutualConnectionsModalOpen(true);
  };

  const handleCloseMutualConnectionsModal = () => {
    setShowMutualConnectionsModal(false);
    setSelectedUserForMutualConnections(null);
    setIsMutualConnectionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, mutualConnectionsModalScrollPosition);
    }, 0);
  };

  useEffect(() => {
    const savedState = localStorage.getItem("peoplePageState");
    if (savedState) {
      const parsedState = JSON.parse(savedState);

      setNameInput(parsedState.nameInput || "");
      setInterests(parsedState.interests || []);
      setSkills(parsedState.skills || []);
      setCompany(parsedState.company || { object: null, inputValue: "" });
      setRole(parsedState.role || { object: null, inputValue: "" });
      setSchool(parsedState.school || { object: null, inputValue: "" });
      setDegree(parsedState.degree || { object: null, inputValue: "" });
      setSubject(parsedState.subject || { object: null, inputValue: "" });
      setSelectedCountry(parsedState.selectedCountry || null);
      setSelectedCity(parsedState.selectedCity || null);
      setConnectionType(parsedState.connectionType || "Extended");
      setSearchResults(parsedState.searchResults || []);
      setHasSearched(parsedState.hasSearched || false);
      setMutualConnections(parsedState.mutualConnections || {});

      // Also restore the lastSearchCriteria
      setLastSearchCriteria({
        nameInput: parsedState.nameInput || "",
        interests: parsedState.interests || [],
        skills: parsedState.skills || [],
        company: parsedState.company || { object: null, inputValue: "" },
        role: parsedState.role || { object: null, inputValue: "" },
        school: parsedState.school || { object: null, inputValue: "" },
        degree: parsedState.degree || { object: null, inputValue: "" },
        subject: parsedState.subject || { object: null, inputValue: "" },
        selectedCountry: parsedState.selectedCountry || null,
        selectedCity: parsedState.selectedCity || null,
      });

      // Restore the scroll position
      if (parsedState.isReturning && parsedState.scrollPosition) {
        setTimeout(() => {
          window.scrollTo(0, parsedState.scrollPosition);
        }, 0);
      }

      // Clear the saved state
      localStorage.removeItem("peoplePageState");
    } else {
    }
  }, []);

  const handleRefresh = () => {
    setNameInput("");
    setInterests([]);
    setSkills([]);
    setCompany({ object: null, inputValue: "" });
    setRole({ object: null, inputValue: "" });
    setSchool({ object: null, inputValue: "" });
    setDegree({ object: null, inputValue: "" });
    setSubject({ object: null, inputValue: "" });
    setSelectedCountry(null);
    setSelectedCity(null);
    setCountryInputValue("");
    setCityInputValue("");
    setIsChanged(false);
    setHasSearched(false);
    setSearchResults([]);
    fetchInitialResults();
  };

  return (
    <div
      className={`${classes.page} ${
        isConnectionsModalOpen ||
        isMutualConnectionsModalOpen ||
        isFollowModalOpen // Add this line
          ? classes.noScroll
          : ""
      }`}
      style={{
        top: isConnectionsModalOpen
          ? `-${connectionsModalScrollPosition}px`
          : isMutualConnectionsModalOpen
          ? `-${mutualConnectionsModalScrollPosition}px`
          : isFollowModalOpen // Add this line
          ? `-${followModalScrollPosition}px` // Add this line
          : "",
        position:
          isConnectionsModalOpen ||
          isMutualConnectionsModalOpen ||
          isFollowModalOpen // Add this line
            ? "fixed"
            : "",
        width: "100%",
      }}
    >
      {isMobile ? (
        <MobileNavbar userImage={userImage} />
      ) : (
        <MainNavbar userImage={userImage} />
      )}
      {showFollowModal && (
        <FollowModal
          onClose={handleCloseFollowModal}
          currentUserId={selectedUserForFollowing}
          showDropdown={false}
        />
      )}
      {showConnectionsModal && (
        <ConnectionsModal
          onClose={handleCloseConnectionsModal}
          currentUserId={selectedUserId}
          loggedInUserId={currentUser?.uid}
          isOwnProfile={false}
          showConnectionDate={false}
        />
      )}
      {showMutualConnectionsModal && (
        <MutualConnectionsModal
          onClose={handleCloseMutualConnectionsModal}
          currentUserId={selectedUserForMutualConnections}
          loggedInUserId={currentUser?.uid}
        />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.card}>
            <div className={classes.cardTitle}>
              People
              <svg
                className={`${classes.closeIcon} ${
                  showFilters ? classes.closeIconActive : ""
                }`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="none"
                viewBox="0 0 24 24"
                onClick={toggleFilters}
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="M20 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6h-2m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4"
                />
              </svg>
            </div>
            <div className={classes.cardAbout}>
              <label htmlFor="email" className={classes.label}>
                Name
              </label>
              <div className={classes.inputWrapper}>
                <input
                  type="text"
                  placeholder="Ex: Paul Graham"
                  value={nameInput}
                  onChange={handleNameInputChange}
                  className={classes.userInput}
                />
                <svg
                  className={classes.searchIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    stroke-linecap="round"
                    stroke-width="2"
                    d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>

              {showFilters && (
                <>
                  <div className={classes.filters}>
                    <div className={classes.bottom}>
                      <div htmlFor="country" className={classes.filterLabel}>
                        Country
                      </div>
                      <CustomDropdown
                        options={countries}
                        selectedValue={selectedCountry}
                        onChange={handleCountryChange}
                        inputValue={countryInputValue}
                        setInputValue={setCountryInputValue}
                        disabled={loading}
                        placeholder="Ex: United States"
                      />
                      <div
                        htmlFor="city"
                        className={`${classes.filterLabel} ${
                          !selectedCountry || loading ? classes.disabled : ""
                        }`}
                      >
                        City
                      </div>
                      <CustomDropdown
                        options={cities}
                        selectedValue={selectedCity}
                        onChange={handleCityChange}
                        inputValue={cityInputValue}
                        setInputValue={setCityInputValue}
                        disabled={!selectedCountry || loading}
                        placeholder="Ex: San Francisco"
                      />
                    </div>
                    <div className={classes.section}>
                      <div className={classes.filterLabel}>Skills</div>
                      <SkillsDropdown
                        selectedSkills={skills}
                        onSkillsChange={handleSkillsChange}
                        onInputChange={(value) =>
                          handleInputChange(value, "skills")
                        }
                        disabled={loading}
                        placeholder="Ex: Python, Machine Learning, UX Research"
                        customClassName={classes.skillsInput}
                      />
                      <div className={classes.smallerPrint}>
                        <svg
                          className={classes.infoIcon}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <p>Use commas to seperate skills</p>
                      </div>
                    </div>
                    <div className={classes.section}>
                      <div className={classes.filterLabel}>Interests</div>
                      <InterestsDropdown
                        selectedInterests={interests}
                        onInterestsChange={handleInterestsChange}
                        onInputChange={(value) =>
                          handleInputChange(value, "interests")
                        }
                        disabled={loading}
                        placeholder="Ex: Angel Investing, Web3, Emerging Tech"
                        customClassName={classes.skillsInput}
                      />
                      <div className={classes.smallerPrint}>
                        <svg
                          className={classes.infoIcon}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <p>Use commas to seperate interests</p>
                      </div>
                    </div>
                    <div className={classes.section}>
                      <div className={classes.filterLabel}>Organization</div>
                      <CompanyDropdown
                        selectedCompany={company.object}
                        onCompanyChange={handleCompanyChange}
                        onInputChange={handleCompanyInputChange}
                        inputValue={company.inputValue}
                        disabled={loading}
                        placeholder="Ex: Airbnb"
                      />
                      {/* <div className={classes.charCount}>
                        {company.inputValue.length}/50
                      </div> */}
                      <div className={classes.organisationLabel}>Role</div>
                      <RoleDropdown
                        selectedRole={role.object}
                        onRoleChange={handleRoleChange}
                        onInputChange={handleRoleInputChange}
                        inputValue={role.inputValue}
                        disabled={loading}
                        placeholder="Ex: Product Designer"
                      />
                      {/* <div className={classes.roleCharCount}>
                        {role.inputValue.length}/50
                      </div> */}
                    </div>
                    <div className={classes.bottom}>
                      <div className={classes.filterLabel}>School</div>
                      <SchoolDropdown
                        selectedSchool={school.object}
                        onSchoolChange={handleSchoolChange}
                        onInputChange={handleSchoolInputChange}
                        inputValue={school.inputValue}
                        disabled={loading}
                        placeholder="Ex: Harvard University"
                      />
                      <div className={classes.degreeLabel}>Degree</div>
                      <DegreeDropdown
                        selectedDegree={degree.object}
                        onDegreeChange={handleDegreeChange}
                        onInputChange={handleDegreeInputChange}
                        inputValue={degree.inputValue}
                        disabled={loading}
                        placeholder="Ex: Bachelor of Science (B.S.)"
                      />
                      <div className={classes.degreeLabel}>Subject</div>
                      <SubjectDropdown
                        selectedSubject={subject.object}
                        onSubjectChange={handleSubjectChange}
                        onInputChange={handleSubjectInputChange}
                        inputValue={subject.inputValue}
                        disabled={loading}
                        placeholder="Ex: Computer Science"
                      />
                    </div>
                  </div>
                </>
              )}
              <div className={classes.buttons}>
                <button
                  type="button"
                  className={`${classes.button} ${
                    isSearching ? classes.loading : ""
                  }`}
                  disabled={
                    !isChanged ||
                    isSearching ||
                    (interests.length === 0 &&
                      skills.length === 0 &&
                      !checkIfAnyInputHasValue())
                  }
                  onClick={() => {
                    triggerSearch();
                  }}
                >
                  {isSearching ? (
                    <>
                      <span
                        className={`material-symbols-outlined ${classes.loadingIcon}`}
                      >
                        progress_activity
                      </span>
                      <span className={classes.searching}>Searching...</span>
                    </>
                  ) : (
                    <>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeWidth="2"
                          d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                        />
                      </svg>
                      Search
                    </>
                  )}
                </button>
                <button
                  className={classes.refreshButton}
                  onClick={handleRefresh}
                >
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 9H8a5 5 0 0 0 0 10h9m4-10-4-4m4 4-4 4"
                    />
                  </svg>
                  Reset
                </button>
              </div>
            </div>
          </div>

          {isInitialLoading ? (
            <div className={classes.noResults}>
              <span
                className={`material-symbols-outlined ${classes.pageLoader}`}
              >
                progress_activity
              </span>
            </div>
          ) : hasSearched ? (
            isSearching ? (
              <div className={classes.noResults}>
                {/* Empty div to maintain layout during search */}
              </div>
            ) : searchResults.length > 0 ? (
              searchResults.map((user) => (
                <div
                  key={user.objectID}
                  className={classes.profileCard}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUserClick(user);
                  }}
                >
                  <div className={classes.profileHeader}>
                    <div className={classes.profileImage}>
                      <img src={user.profileImage || profileImage} alt="" />
                    </div>
                    {shouldShowMatchTally() && hasSearched && !isSearching && (
                      <div className={classes.matchTally}>
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className={classes.matchCount}>
                          {user.matches}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className={classes.profileTitle}>
                    <span className={classes.spacing}>{user.firstName}</span>
                    <span>{user.lastName}</span>
                    <span className={classes.connectionType}>
                      • {user.connectionType}
                    </span>
                  </div>
                  {(user.bio || user.link) && (
                    <div className={classes.userDetails}>
                      {user.bio && (
                        <div className={classes.bio}>{user.bio}</div>
                      )}

                      {user.link && (
                        <div className={classes.link}>
                          <a
                            href={user.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {user.linkText || user.link}
                          </a>
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangeRed"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.75"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  )}
                  {/* {mutualConnections[user.objectID] &&
                    mutualConnections[user.objectID].length > 0 && (
                      <div
                        className={classes.mutualConnections}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenMutualConnectionsModal(user.objectID);
                        }}
                      >
                        <div
                          className={classes.mutualConnectionsImages}
                          ref={(el) => {
                            if (el) {
                              const containerWidth =
                                el.parentElement.offsetWidth;
                              const imageWidth = 32; // 2rem
                              const imageOverlap = 12; // 0.75rem
                              const countWidth =
                                mutualConnections[user.objectID].length > 1
                                  ? 48
                                  : 0; // Approximate width of "+N"
                              const effectiveImageWidth =
                                imageWidth - imageOverlap;

                              // Calculate how many images can fit
                              const maxImages = Math.floor(
                                (containerWidth - countWidth) /
                                  effectiveImageWidth
                              );

                              // Only show images that fit
                              const imagesToShow = Math.min(
                                maxImages,
                                mutualConnections[user.objectID].length
                              );

                              el.innerHTML = "";
                              mutualConnections[user.objectID]
                                .slice(0, imagesToShow)
                                .forEach((connection, index) => {
                                  const img = document.createElement("img");
                                  img.src =
                                    connection.profileImage || profileImage;
                                  img.className = classes.mutualConnectionImage;
                                  img.alt = "";
                                  el.appendChild(img);
                                });

                              if (
                                mutualConnections[user.objectID].length >
                                imagesToShow
                              ) {
                                const count = document.createElement("span");
                                count.className = classes.mutualConnectionCount;
                                count.textContent = `+${
                                  mutualConnections[user.objectID].length -
                                  imagesToShow
                                }`;
                                el.appendChild(count);
                              }
                            }
                          }}
                        />
                      </div>
                    )} */}
                  <button
                    className={classes.viewProfileButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUserClick(user);
                    }}
                  >
                    See profile
                    <svg
                      class="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 12H5m14 0-4 4m4-4-4-4"
                      />
                    </svg>
                  </button>
                </div>
              ))
            ) : (
              <div className={classes.noNotifications}>
                <div className={classes.bold}>No results found</div>
                <div className={classes.text}>
                  Try searching for something else.
                </div>
                <div className={classes.homeButton} onClick={handleRefresh}>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                    />
                  </svg>
                  Refresh
                </div>
              </div>
            )
          ) : initialResults.length > 0 ? (
            initialResults.map((user) => (
              <div
                key={user.objectID}
                className={classes.profileCard}
                onClick={(e) => {
                  e.stopPropagation();
                  handleUserClick(user);
                }}
              >
                {user.connectionType !== "Direct" &&
                  user.connectionType !== "You" && (
                    <div className={classes.reactionHeader}>Suggested</div>
                  )}
                <div className={classes.profileHeader}>
                  <div className={classes.profileImage}>
                    <img src={user.profileImage || profileImage} alt="" />
                  </div>
                </div>
                <div className={classes.profileTitle}>
                  <span className={classes.spacing}>{user.firstName}</span>
                  <span>{user.lastName}</span>
                  <span className={classes.connectionType}>
                    • {user.connectionType}
                  </span>
                </div>
                {(user.bio || user.link) && (
                  <div className={classes.userDetails}>
                    {user.bio && <div className={classes.bio}>{user.bio}</div>}

                    {user.link && (
                      <div className={classes.link}>
                        <a
                          href={user.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {user.linkText || user.link}
                        </a>
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="orangeRed"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.75"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                )}

                <button
                  className={classes.viewProfileButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUserClick(user);
                  }}
                >
                  See profile
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 12H5m14 0-4 4m4-4-4-4"
                    />
                  </svg>
                </button>
              </div>
            ))
          ) : (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No suggestions found</div>
              <div className={classes.text}>Try checking Home for updates.</div>
              <Link to="/home" className={classes.homeButton}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Home
              </Link>
            </div>
          )}
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default People;
