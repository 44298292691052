import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { firestore, auth } from "../../../firebase";
import { useChat } from "../../../contexts/ChatContext";
import greyProfileImage from "../../../assets/icons/greyProfileImage.jpg";
import EditChatsModal from "../../../components/Modals/EditChatsModal";
import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  doc,
  getDoc,
  writeBatch,
  setDoc,
  serverTimestamp,
  onSnapshot,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import defaultImage from "../../../assets/icons/profileImage.jpg";
import AddChatModal from "../../../components/Modals/AddChatModal";
import ChatModal from "../../../components/Modals/ChatModal";
import classes from "./Chats.module.css";

const Chats = () => {
  const [showEditChatsModal, setShowEditChatsModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [userProfiles, setUserProfiles] = useState({});
  const navigate = useNavigate();
  const [visibleChats, setVisibleChats] = useState(3);
  const [userConnections, setUserConnections] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);
  const [showAddChatModal, setShowAddChatModal] = useState(false);

  const [loadingStates, setLoadingStates] = useState({
    userData: true,
    chats: true,
    messages: false,
    profiles: false,
  });
  // Add these with your other state declarations in Chats.js
  const [addChatModalScrollPosition, setAddChatModalScrollPosition] =
    useState(0);
  const [isAddChatModalOpen, setIsAddChatModalOpen] = useState(false);

  const [editChatsModalScrollPosition, setEditChatsModalScrollPosition] =
    useState(0);
  const [isEditChatsModalOpen, setIsEditChatsModalOpen] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [chatModalScrollPosition, setChatModalScrollPosition] = useState(0);

  const location = useLocation();

  const { markAllChatsRead, isChatUnread } = useChat();

  const handleChatsRemoved = (remainingChats, numRemoved) => {
    setChats(remainingChats);
  };

  const checkConnectionType = useCallback(
    async (currentUserId, targetUserId) => {
      if (!currentUserId || !targetUserId) return "Extended";
      if (currentUserId === targetUserId) return "You";

      try {
        const userConnectionRef = doc(
          firestore,
          `users/${currentUserId}/connections/${targetUserId}`
        );
        const userConnectionDoc = await getDoc(userConnectionRef);

        if (
          userConnectionDoc.exists() &&
          userConnectionDoc.data().status === "Connected"
        ) {
          return "Direct";
        }

        const userConnectionsRef = collection(
          firestore,
          `users/${currentUserId}/connections`
        );
        const userConnectionsQuery = query(
          userConnectionsRef,
          where("status", "==", "Connected")
        );
        const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

        for (const conn of userConnectionsSnapshot.docs) {
          const indirectConnectionRef = doc(
            firestore,
            `users/${conn.id}/connections/${targetUserId}`
          );
          const indirectConnectionDoc = await getDoc(indirectConnectionRef);
          if (
            indirectConnectionDoc.exists() &&
            indirectConnectionDoc.data().status === "Connected"
          ) {
            return "Indirect";
          }
        }

        return "Extended";
      } catch (error) {
        return "Extended";
      }
    },
    []
  );

  const fetchUserConnections = useCallback(async () => {
    if (!currentUser) return;

    try {
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const connectionsQuery = query(
        connectionsRef,
        where("status", "==", "Connected")
      );
      const connectionsSnapshot = await getDocs(connectionsQuery);

      const connections = connectionsSnapshot.docs
        .map((doc) => doc.id)
        .filter((id) => id !== "dummyId"); // Exclude the dummyId connection
      setUserConnections(connections);
    } catch (error) {
      setUserConnections([]);
    }
  }, [currentUser]);

  // Add this to determine if we should show the add icon
  const shouldShowAddIcon = useMemo(() => {
    if (!userConnections.length) return false;

    // Check if user has chats with all their connections
    const chatUserIds = chats.map((chat) => chat.otherUser.id);
    const availableConnections = userConnections.filter(
      (connectionId) => !chatUserIds.includes(connectionId)
    );

    return availableConnections.length > 0;
  }, [userConnections, chats]);

  // Add this effect to fetch connections when component mounts
  useEffect(() => {
    fetchUserConnections();
  }, [fetchUserConnections]);

  const fetchUserProfile = useCallback(
    async (userId) => {
      if (!userId || userProfiles[userId]) return;

      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserProfiles((prev) => ({
            ...prev,
            [userId]: {
              id: userId,
              name: `${userData.firstName} ${userData.lastName}`,
              image: userData.profileImage || defaultImage,
            },
          }));
        }
      } catch (error) {}
    },
    [userProfiles]
  );

  useEffect(() => {
    const messagesListener = () => {
      if (!selectedChat?.id || !currentUser) return;

      setLoadingStates((prev) => ({ ...prev, messages: true }));
      const messagesRef = collection(
        firestore,
        `chats/${selectedChat.id}/messages`
      );
      const q = query(messagesRef, orderBy("createdAt", "desc"));

      return onSnapshot(q, (snapshot) => {
        const messagesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMessages(messagesData);
        setLoadingStates((prev) => ({ ...prev, messages: false }));

        messagesData.forEach((message) => {
          if (!userProfiles[message.senderId]) {
            fetchUserProfile(message.senderId);
          }
        });
      });
    };

    const unsubscribe = messagesListener();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [selectedChat?.id, currentUser, fetchUserProfile, userProfiles]);

  const fetchChats = useCallback(() => {
    if (!currentUser) return () => {};

    if (chats.length === 0) {
      setLoadingStates((prev) => ({ ...prev, chats: true }));
    }

    const chatsRef = collection(firestore, "chats");
    const q = query(
      chatsRef,
      where("participants", "array-contains", currentUser.uid)
    );

    return onSnapshot(q, async (snapshot) => {
      try {
        const chatsData = await Promise.all(
          snapshot.docs.map(async (chatDoc) => {
            const chatData = chatDoc.data();
            if (chatData.deletedBy?.includes(currentUser.uid)) return null;

            // Get all messages to check if there are any visible ones
            const messagesRef = collection(
              firestore,
              `chats/${chatDoc.id}/messages`
            );
            const messagesSnapshot = await getDocs(messagesRef);

            // Check if there are any messages not hidden for current user
            const hasVisibleMessages = messagesSnapshot.docs.some((doc) => {
              const messageData = doc.data();
              return !messageData.hiddenFor?.includes(currentUser.uid);
            });

            // If no visible messages, don't show the chat
            if (!hasVisibleMessages) return null;

            const displayMessage =
              chatData.lastMessage?.[currentUser.uid] || chatData.lastMessage;

            const otherUserId = chatData.participants.find(
              (id) => id !== currentUser.uid
            );
            const userDoc = await getDoc(doc(firestore, "users", otherUserId));

            if (!userDoc.exists()) return null;

            const userData = userDoc.data();
            const isBlocked = await checkIfBlocked(
              currentUser.uid,
              otherUserId
            );
            const connectionType = isBlocked
              ? "Blocked"
              : await checkConnectionType(currentUser.uid, otherUserId);

            return {
              id: chatDoc.id,
              otherUser: {
                id: otherUserId,
                name: `${userData.firstName} ${userData.lastName}`,
                image: userData.profileImage || defaultImage,
                bio: userData.bio || "",
                link: userData.link || "",
                linkText: userData.linkText || "",
                connectionType,
              },
              lastMessage: displayMessage,
              unreadCount: chatData.unreadCount?.[currentUser.uid] || 0,
              lastMessageTime:
                displayMessage?.createdAt || chatData.lastMessageTime,
            };
          })
        );

        const sortedChatsData = chatsData.filter(Boolean).sort((a, b) => {
          if (!a.lastMessageTime || !b.lastMessageTime) return 0;
          return b.lastMessageTime.seconds - a.lastMessageTime.seconds;
        });

        setChats(sortedChatsData);
      } catch (error) {
      } finally {
        setLoadingStates((prev) => ({ ...prev, chats: false }));
      }
    });
  }, [currentUser, checkConnectionType]);

  const handleDeleteChat = async () => {
    if (!selectedChat || !currentUser) return;

    try {
      const chatRef = doc(firestore, `chats/${selectedChat.id}`);
      const chatDoc = await getDoc(chatRef);

      if (!chatDoc.exists()) return;

      const chatData = chatDoc.data();
      const deletedBy = chatData.deletedBy || [];

      if (deletedBy.includes(selectedChat.otherUser.id)) {
        await writeBatch(firestore).delete(chatRef).commit();
      } else {
        await updateDoc(chatRef, {
          deletedBy: arrayUnion(currentUser.uid),
          [`deletedAt.${currentUser.uid}`]: serverTimestamp(),
        });
      }

      setChats((prevChats) =>
        prevChats.filter((chat) => chat.id !== selectedChat.id)
      );
      handleCloseChat();
    } catch (error) {}
  };

  const handleChatClick = (chat) => {
    setChatModalScrollPosition(window.scrollY);
    setSelectedChat(chat);
    setShowChatModal(true);
    setIsChatModalOpen(true);
  };

  const handleCloseChat = () => {
    setShowChatModal(false);
    setIsChatModalOpen(false);
    setSelectedChat(null);
    setMessages([]);
    setTimeout(() => {
      window.scrollTo(0, chatModalScrollPosition);
    }, 0);
  };

  const handleMessageSent = () => {};

  const filteredChats = useMemo(() => {
    return chats.filter((chat) => {
      if (!chat?.otherUser?.name) return false;
      return chat.otherUser.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });
  }, [chats, searchTerm]);

  useEffect(() => {
    const loadInitialData = async () => {
      setLoadingStates((prev) => ({ ...prev, userData: true }));

      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const userDoc = await getDoc(doc(firestore, "users", user.uid));
            if (userDoc.exists()) {
              setCurrentUser({
                ...user,
                ...userDoc.data(),
              });

              if (location.state?.otherUser) {
                const chatId = [user.uid, location.state.otherUser.id]
                  .sort()
                  .join("_");
                const chatData = {
                  id: chatId,
                  participants: [user.uid, location.state.otherUser.id],
                  createdAt: new Date(),
                  otherUser: location.state.otherUser,
                };
                setSelectedChat(chatData);
                setShowChatModal(true);

                const chatRef = doc(firestore, `chats/${chatId}`);
                const chatDoc = await getDoc(chatRef);
                if (!chatDoc.exists()) {
                  await setDoc(chatRef, {
                    participants: [user.uid, location.state.otherUser.id],
                    createdAt: serverTimestamp(),
                    hasMessages: false,
                    lastMessageTime: serverTimestamp(),
                  });
                }
              }
            }
          } catch (error) {}
        } else {
          setCurrentUser(null);
        }
        setLoadingStates((prev) => ({ ...prev, userData: false }));
      });

      return unsubscribe;
    };

    loadInitialData();
  }, [location.state]);

  useEffect(() => {
    const unsubscribe = fetchChats();
    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [fetchChats]);

  const toggleAddChatModal = () => {
    if (!showAddChatModal) {
      setAddChatModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addChatModalScrollPosition);
      }, 0);
    }
    setShowAddChatModal(!showAddChatModal);
    setIsAddChatModalOpen(!isAddChatModalOpen);
  };

  const toggleEditChatsModal = () => {
    if (!showEditChatsModal) {
      setEditChatsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editChatsModalScrollPosition);
      }, 0);
    }
    setShowEditChatsModal(!showEditChatsModal);
    setIsEditChatsModalOpen(!isEditChatsModalOpen);
  };

  const toggleChatModal = (chat = null) => {
    if (!showChatModal) {
      setChatModalScrollPosition(window.scrollY);
      if (chat) {
        setSelectedChat(chat);
      }
    } else {
      setTimeout(() => {
        window.scrollTo(0, chatModalScrollPosition);
      }, 0);
      setSelectedChat(null);
      setMessages([]);
    }
    setShowChatModal(!showChatModal);
    setIsChatModalOpen(!isChatModalOpen);
  };

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth < 648);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const checkIfBlocked = async (currentUserId, targetUserId) => {
    if (!currentUserId || !targetUserId) return false;

    try {
      // Check if current user has blocked the target user
      const currentUserBlockedRef = collection(
        firestore,
        `users/${currentUserId}/blockedUsers`
      );
      const currentUserBlockedQuery = query(
        currentUserBlockedRef,
        where("userId", "==", targetUserId)
      );
      const currentUserBlockedSnap = await getDocs(currentUserBlockedQuery);

      // Check if target user has blocked the current user
      const targetUserBlockedRef = collection(
        firestore,
        `users/${targetUserId}/blockedUsers`
      );
      const targetUserBlockedQuery = query(
        targetUserBlockedRef,
        where("userId", "==", currentUserId)
      );
      const targetUserBlockedSnap = await getDocs(targetUserBlockedQuery);

      return currentUserBlockedSnap.size > 0 || targetUserBlockedSnap.size > 0;
    } catch (error) {
      return false;
    }
  };

  if (loadingStates.userData || (loadingStates.chats && chats.length === 0)) {
    return (
      <div className={classes.page}>
        {isMobileView ? (
          <MobileNavbar currentUser={currentUser} />
        ) : (
          <MainNavbar currentUser={currentUser} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.page}>
      {isMobileView ? (
        <MobileNavbar currentUser={currentUser} />
      ) : (
        <MainNavbar currentUser={currentUser} />
      )}

      {showAddChatModal && (
        <AddChatModal
          onClose={() => toggleAddChatModal()}
          currentUser={currentUser}
        />
      )}
      {showEditChatsModal && (
        <EditChatsModal
          onClose={() => toggleEditChatsModal()}
          currentUser={currentUser}
          chats={chats}
          onChatsRemoved={handleChatsRemoved}
        />
      )}
      {showChatModal && selectedChat && (
        <ChatModal
          onClose={() => toggleChatModal()}
          selectedChat={selectedChat}
          currentUser={currentUser}
          messages={messages}
          userProfiles={userProfiles}
          onDeleteChat={handleDeleteChat}
          onMessageSent={handleMessageSent}
        />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                chats.length === 0 ? classes.emptyCardTitle : ""
              }`}
            >
              Chats
              <div>
                {shouldShowAddIcon && (
                  <div onClick={toggleAddChatModal}>
                    <svg
                      className={classes.addIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 12h14m-7 7V5"
                      />
                    </svg>
                  </div>
                )}
                {chats.length > 0 && (
                  <div
                    className={classes.pencilIcon}
                    onClick={toggleEditChatsModal}
                  >
                    <svg
                      className={classes.experienceIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>

            {chats.length > 0 && (
              <div className={classes.searchContainer}>
                <div className={classes.inputWrapper}>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={classes.userInput}
                  />
                  <svg
                    className={classes.searchIcon}
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="2"
                      d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
              </div>
            )}

            {chats.length === 0 ? (
              <>
                <div>
                  <p className={classes.defaultText}>
                    {userConnections.length === 0
                      ? "Spark up conversations with like-minded people in the startup community. Build meaningful relationships and make ideas happen."
                      : "Start a conversation! Share ideas, ask questions, and collaborate with your connections."}
                  </p>
                </div>
                {userConnections.length === 0 && (
                  <div className={classes.people}>
                    <div
                      className={classes.button}
                      style={{ width: "fit-content" }}
                      onClick={() => navigate("/people")}
                    >
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      People
                    </div>
                  </div>
                )}
                <div className={classes.noPostBorder}></div>
                <div className={classes.userContainer}>
                  <img
                    src={greyProfileImage}
                    alt=""
                    className={classes.noUserImage}
                  />
                  <div className={classes.userInfo}>
                    <div className={classes.userNameContainer}>
                      <div className={classes.noStartupRole}>Username</div>
                      <span className={classes.userConnectionType}>
                        • Direct
                      </span>
                    </div>
                    <div className={classes.noStartupName}>Message</div>
                    <div className={classes.noStartupName}>1:00 PM</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {filteredChats.slice(0, visibleChats).map((chat) => (
                  <div
                    key={chat.id}
                    className={classes.userContainer}
                    onClick={() => toggleChatModal(chat)}
                  >
                    <div className={classes.profileImageContainer}>
                      <img
                        src={chat.otherUser.image || defaultImage}
                        alt={chat.otherUser.name}
                        className={classes.userImage}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                      {isChatUnread(chat.id) && (
                        <div className={classes.newNotificationIndicator}></div>
                      )}
                    </div>
                    <div className={classes.userInfo}>
                      <div className={classes.userNameContainer}>
                        <span className={classes.userName}>
                          {chat.otherUser.name}
                        </span>
                        <span className={classes.userConnectionType}>
                          • {chat.otherUser.connectionType}
                        </span>
                      </div>
                      <div className={classes.userBio}>
                        {chat.lastMessage?.content
                          ? chat.lastMessage.content.type === "image"
                            ? "Sent an image."
                            : chat.lastMessage.content.type === "video"
                            ? "Sent a video."
                            : "Sent a file."
                          : chat.lastMessage?.text || "Say something..."}
                      </div>
                      {chat.lastMessageTime &&
                        typeof chat.lastMessageTime.toDate === "function" && (
                          <div className={classes.lastMessageTime}>
                            {chat.lastMessageTime
                              .toDate()
                              .toLocaleTimeString([], {
                                hour: "numeric",
                                minute: "2-digit",
                                hour12: true,
                              })
                              .toUpperCase()}
                          </div>
                        )}
                    </div>
                  </div>
                ))}
                {filteredChats.length > 3 && (
                  <div
                    className={classes.showAllExperiences}
                    onClick={() => {
                      if (visibleChats >= filteredChats.length) {
                        setVisibleChats(3);
                      } else {
                        setVisibleChats(
                          Math.min(
                            visibleChats + visibleChats * 2,
                            filteredChats.length
                          )
                        );
                      }
                    }}
                  >
                    {visibleChats >= filteredChats.length
                      ? "Show less"
                      : "Show more"}
                    {visibleChats >= filteredChats.length ? (
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m5 15 7-7 7 7"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 9-7 7-7-7"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
};

export default Chats;
