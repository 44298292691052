import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./EditEventModal.module.css";
import defaultImage from "../../assets/icons/eventImage.jpg";
import { getStorage, ref, deleteObject } from "firebase/storage";
import EditSingleEventModal from "./EditSingleEventModal";

const EditEventModal = ({ onClose, currentUser, onEventUpdated }) => {
  const [events, setEvents] = useState([]);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingEvent, setEditingEvent] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredEvents = events.filter((event) => {
    return event.eventName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const handleEventUpdated = (updatedEvent) => {
    // Update local state with the new event data
    const updatedEvents = events.map((event) =>
      event.id === updatedEvent.id ? updatedEvent : event
    );
    setEvents(updatedEvents);

    // Pass the complete updated event to Profile.js
    onEventUpdated(updatedEvent);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const eventsCollectionRef = collection(userDocRef, "events");
        const eventsSnapshot = await getDocs(eventsCollectionRef);

        const eventsList = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort using the same logic as Profile.js
        eventsList.sort((a, b) => {
          const aDate = parseEventDateTime(a);
          const bDate = parseEventDateTime(b);

          const now = new Date();
          const aInFuture = aDate > now;
          const bInFuture = bDate > now;

          if (aInFuture !== bInFuture) {
            return aInFuture ? -1 : 1; // Future events first
          }

          if (aInFuture) {
            return aDate.getTime() - bDate.getTime(); // Ascending for future events
          } else {
            return bDate.getTime() - aDate.getTime(); // Descending for past events
          }
        });

        setEvents(eventsList);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [currentUser.uid]);

  const toggleDropdown = (eventId) => {
    setOpenDropdown(openDropdown === eventId ? null : eventId);
  };

  const parseEventDateTime = (event) => {
    try {
      let day, month, year;
      const [hours, minutes] = event.endTime.match(/(\d+):(\d+)/).slice(1);

      if (event.endDate.includes("-")) {
        [year, month, day] = event.endDate.split("-");
      } else {
        [day, month, year] = event.endDate.split("/");
      }

      const date = new Date(
        Number(year),
        Number(month) - 1,
        Number(day),
        Number(hours),
        Number(minutes)
      );

      return date;
    } catch (error) {
      return new Date(0);
    }
  };

  const handleEditClick = (event) => {
    setEditingEvent(event);
    setOpenDropdown(null);
  };

  // Replace the existing handleDeleteEvent function with this new version
  const handleDeleteEvent = async (eventId) => {
    try {
      // Find the event to get its image URL
      const event = events.find((e) => e.id === eventId);
      if (!event) return;

      const userDocRef = doc(firestore, "users", currentUser.uid);
      const eventDocRef = doc(userDocRef, "events", eventId);

      // 1. Get hosts and attendees collections
      const hostsCollectionRef = collection(eventDocRef, "hosts");
      const attendeesCollectionRef = collection(eventDocRef, "attendees");

      // Fetch all hosts and attendees
      const hostsSnapshot = await getDocs(hostsCollectionRef);
      const attendeesSnapshot = await getDocs(attendeesCollectionRef);

      // Collect all user IDs that need cleanup, including the event owner
      const userIds = new Set([currentUser.uid]); // Add event owner's ID first
      hostsSnapshot.forEach((doc) => userIds.add(doc.id));
      attendeesSnapshot.forEach((doc) => userIds.add(doc.id));

      // 2. Delete related data for each user (including event owner)
      const deletePromises = Array.from(userIds).map(async (userId) => {
        const userRef = doc(firestore, "users", userId);

        // Delete from eventsAttending (if not the owner)
        if (userId !== currentUser.uid) {
          const attendingRef = collection(userRef, "eventsAttending");
          const attendingDoc = doc(attendingRef, eventId);
          try {
            await deleteDoc(attendingDoc);
          } catch (error) {}

          // Delete from eventsOrganising (if not the owner)
          const organisingRef = collection(userRef, "eventsOrganising");
          const organisingDoc = doc(organisingRef, eventId);
          try {
            await deleteDoc(organisingDoc);
          } catch (error) {}
        }

        // Delete related notifications for all users (including owner)
        const notificationsRef = collection(userRef, "notifications");
        const notificationsQuery = query(
          notificationsRef,
          where("eventId", "==", eventId)
        );

        try {
          const notificationsSnapshot = await getDocs(notificationsQuery);
          const notificationDeletions = notificationsSnapshot.docs.map((doc) =>
            deleteDoc(doc.ref)
          );
          await Promise.all(notificationDeletions);
        } catch (error) {}
      });

      // Rest of the function remains the same...
      const deleteHostsPromises = hostsSnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      const deleteAttendeesPromises = attendeesSnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );

      // Delete the event image if it exists and is not the default image
      if (event.eventImage && !event.eventImage.includes("startupImage.jpg")) {
        try {
          const storage = getStorage();
          const imageUrl = new URL(event.eventImage);
          const imagePath = decodeURIComponent(
            imageUrl.pathname.split("/o/")[1].split("?")[0]
          );
          const imageRef = ref(storage, imagePath);
          await deleteObject(imageRef);
        } catch (error) {}
      }

      // Execute all deletions in parallel
      await Promise.all([
        ...deletePromises,
        ...deleteHostsPromises,
        ...deleteAttendeesPromises,
      ]);

      // Finally delete the event document itself
      await deleteDoc(eventDocRef);

      // Update the local state
      const updatedEvents = events.filter((event) => event.id !== eventId);
      setEvents(updatedEvents);

      // Pass the deleted event's ID back to Profile.js
      onEventUpdated({
        id: eventId,
        deleted: true,
      });

      setOpenDropdown(null);

      // Close the modal when no events are left
      if (updatedEvents.length === 0) {
        onClose();
      }
    } catch (error) {}
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose();
    }
    setMouseDownOnBackdrop(false);
  };

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  if (editingEvent) {
    return (
      <EditSingleEventModal
        event={editingEvent}
        onClose={() => setEditingEvent(null)}
        onUpdate={handleEventUpdated}
        currentUser={currentUser}
      />
    );
  }

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Events ({events.length})
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className={classes.startupsCardAbout}>
          {loading ? (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          ) : events.length === 0 ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No events found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          ) : (
            filteredEvents.map((event) => (
              <div key={event.id} className={classes.startupContainer}>
                <div className={classes.startupContent}>
                  <div className={classes.startupImageContainer}>
                    {event.eventImage ? (
                      <img
                        src={event.eventImage}
                        alt={event.eventName}
                        className={classes.startupImage}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                    ) : (
                      <div className={classes.defaultImage}>
                        <img
                          src={defaultImage}
                          alt="Default event"
                          className={classes.startupImage}
                        />
                      </div>
                    )}
                  </div>
                  <div className={classes.startupInfo}>
                    <h3 className={classes.startupName}>
                      <div className={classes.nameWrapper}>
                        <span className={classes.nameText}>
                          {event.eventName}
                        </span>
                        <span className={classes.connectionType}>• Event</span>
                      </div>
                    </h3>

                    {event.tagline && (
                      <p className={classes.role}>{event.tagline}</p>
                    )}

                    {event.link && (
                      <a
                        href={event.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.userLink}
                      >
                        <span>{event.linkText || event.link}</span>
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </a>
                    )}
                  </div>
                  <div className={classes.buttonContainer}>
                    <div className={classes.iconContainer}>
                      <svg
                        className={`${classes.optionsIcon} ${
                          openDropdown === event.id
                            ? classes.optionsIconActive
                            : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={() => toggleDropdown(event.id)}
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                      {openDropdown === event.id && (
                        <div className={classes.dropdown}>
                          <div
                            className={`${classes.dropdownItem} ${classes.dropdownItemFirst}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditClick(event);
                            }}
                          >
                            <svg
                              class="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                              />
                            </svg>
                            <span>Edit</span>
                          </div>
                          <div
                            className={`${classes.dropdownItem} ${classes.dropdownItemLast}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteEvent(event.id);
                            }}
                          >
                            <svg
                              class="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                              />
                            </svg>
                            <span>Delete</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default EditEventModal;
