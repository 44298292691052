import React, { useState, useRef, useEffect } from "react";
import defaultImage from "../../assets/icons/eventImage.jpg";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firestore } from "../../firebase";
import classes from "./AddEventModal.module.css";
import imageCompression from "browser-image-compression";
import Cropper from "react-easy-crop";
import CustomDropdown from "../Dropdowns/CustomDropdown";
import { generateEventUrlSlug } from "../../utils/eventUrlSlugUtils";
import TimeDropdown from "../Dropdowns/TimeDropdown";
import TimezoneDropdown from "../Dropdowns/TimezoneDropdown";
import MonthDropdown from "../Dropdowns/MonthDropdown";
import DayDropdown from "../Dropdowns/DayDropdown";
import YearDropdown from "../Dropdowns/YearDropdown";

const config = {
  cUrl: "https://api.countrystatecity.in/v1",
  ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
};

const AddEventModal = ({ onClose, currentUser, onEventAdded }) => {
  const [eventName, setEventName] = useState("");
  const [loading, setLoading] = useState(false);

  const [tagline, setTagline] = useState("");
  const [isOnline, setIsOnline] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryInputValue, setCountryInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [link, setLink] = useState("");
  const [linkText, setLinkText] = useState("");
  const [eventImage, setEventImage] = useState(null);
  const [currentEventImageUrl, setCurrentEventImageUrl] = useState(null);
  const [error, setError] = useState("");
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countriesLoaded, setCountriesLoaded] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [startMonth, setStartMonth] = useState(null);
  const [startDay, setStartDay] = useState(null);
  const [startYear, setStartYear] = useState(null);

  const [endMonth, setEndMonth] = useState(null);
  const [endDay, setEndDay] = useState(null);
  const [endYear, setEndYear] = useState(null);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  // Convert dropdown values to DD/MM/YYYY format
  const formatDateFromDropdowns = (day, month, year) => {
    if (!day || !month || !year) return "";
    return `${String(day).padStart(2, "0")}/${String(month).padStart(
      2,
      "0"
    )}/${year}`;
  };
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [address, setAddress] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [timezone, setTimezone] = useState(null);

  const isLoading = isLoadingInitialData || isSubmitting;
  const formRef = useRef(null);
  const cityCache = {};

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setIsLoadingInitialData(true);
        const response = await fetch(`${config.cUrl}/countries`, {
          headers: { "X-CSCAPI-KEY": config.ckey },
        });
        const data = await response.json();
        setCountries(Array.isArray(data) ? data : []);
        setCountriesLoaded(true);
      } catch (error) {
        setCountries([]);
      } finally {
        setIsLoadingInitialData(false);
      }
    };

    loadInitialData();
  }, []);

  const loadCities = async () => {
    if (!selectedCountry) return;
    try {
      if (cityCache[selectedCountry.iso2]) {
        setCities(cityCache[selectedCountry.iso2]);
        return;
      }

      const response = await fetch(
        `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
        {
          headers: { "X-CSCAPI-KEY": config.ckey },
        }
      );
      const data = await response.json();
      cityCache[selectedCountry.iso2] = Array.isArray(data) ? data : [];
      setCities(cityCache[selectedCountry.iso2]);
    } catch (error) {
      setCities([]);
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      loadCities();
    }
  }, [selectedCountry]);

  const handleEventNameChange = (e) => {
    if (e.target.value.length <= 50) {
      setEventName(e.target.value);
    }
  };

  const handleTaglineChange = (e) => {
    if (e.target.value.length <= 250) {
      setTagline(e.target.value);
    }
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCityInputValue("");
    setCities([]);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
  };

  const validateUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleLinkChange = (e) => {
    const newLink = e.target.value;
    if (newLink.length <= 250) {
      setLink(newLink);
      setIsValidUrl(validateUrl(newLink));
      if (!newLink) {
        setLinkText("");
      }
    }
  };

  const handleLinkTextChange = (e) => {
    if (e.target.value.length <= 50) {
      setLinkText(e.target.value);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleEventImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 8,
          maxWidthOrHeight: 1000,
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onload = () => {
          setCurrentEventImageUrl(reader.result);
          setIsCropping(true);
        };
        reader.readAsDataURL(compressedFile);
        setEventImage(compressedFile);
      } catch (error) {
        setError("Failed to process the image. Please try again.");
      }
    }
  };

  const cropSize = { width: 192, height: 128 };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  };

  const areRequiredFieldsFilled = () => {
    if (isOnline) {
      return (
        eventName.trim() !== "" &&
        startDay &&
        startMonth &&
        startYear &&
        endDay &&
        endMonth &&
        endYear &&
        startTime &&
        endTime &&
        timezone
      );
    }
    return (
      eventName.trim() !== "" &&
      selectedCountry &&
      selectedCity &&
      startDay &&
      startMonth &&
      startYear &&
      endDay &&
      endMonth &&
      endYear &&
      startTime &&
      endTime &&
      timezone
    );
  };

  const validateDate = (dateStr) => {
    const [day, month, year] = dateStr.split("/").map((num) => parseInt(num));

    if (isNaN(day) || isNaN(month) || isNaN(year)) return false;
    if (month < 1 || month > 12) return false;
    if (day < 1 || day > 31) return false;

    if ([4, 6, 9, 11].includes(month) && day > 30) return false;
    if (month === 2) {
      const isLeapYear =
        (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
      if (day > (isLeapYear ? 29 : 28)) return false;
    }

    return true;
  };

  const formatDateInput = (value) => {
    const digits = value.replace(/\D/g, "");

    if (!digits) return "";

    let formattedDate = "";

    if (digits.length <= 2) {
      formattedDate = digits;
    } else if (digits.length <= 4) {
      formattedDate = `${digits.slice(0, 2)}/${digits.slice(2)}`;
    } else {
      formattedDate = `${digits.slice(0, 2)}/${digits.slice(
        2,
        4
      )}/${digits.slice(4, 8)}`;
    }

    return formattedDate;
  };

  const handleStartDateChange = (e) => {
    const formattedDate = formatDateInput(e.target.value);
    setStartDate(formattedDate);

    if (endDate) {
      const [startDay, startMonth, startYear] = formattedDate
        .split("/")
        .map((num) => parseInt(num));
      const [endDay, endMonth, endYear] = endDate
        .split("/")
        .map((num) => parseInt(num));

      if (
        new Date(startYear, startMonth - 1, startDay) >
        new Date(endYear, endMonth - 1, endDay)
      ) {
        setEndDate("");
        setEndTime("");
      }
    }
  };

  const handleEndDateChange = (endDay, endMonth, endYear) => {
    if (!startDay || !startMonth || !startYear) return;

    const startDate = new Date(startYear, startMonth - 1, startDay);
    const endDate = new Date(endYear, endMonth - 1, endDay);

    if (startDate > endDate) {
      setEndDay(null);
      setEndMonth(null);
      setEndYear(null);
      setEndTime("");
    }
  };

  const handleSaveClick = async () => {
    if (formRef.current) {
      try {
        const success = await handleSubmit(new Event("submit"));
        if (success && !error) {
          onClose(true);
        }
      } catch (err) {}
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!areRequiredFieldsFilled()) {
      setError("Please fill in all required fields.");
      return false;
    }

    const formattedStartDate = formatDateFromDropdowns(
      startDay,
      startMonth,
      startYear
    );
    const formattedEndDate = formatDateFromDropdowns(endDay, endMonth, endYear);

    setIsSubmitting(true);
    setError("");

    try {
      let eventImageUrl = "";

      if (eventImage && croppedAreaPixels) {
        const croppedImage = await getCroppedImg(
          currentEventImageUrl,
          croppedAreaPixels
        );
        const response = await fetch(croppedImage);
        const blob = await response.blob();

        const storage = getStorage();
        const tempEventId = `temp_${Date.now()}`;
        const timestamp = Date.now();
        const eventImageRef = ref(
          storage,
          `eventImages/${currentUser.uid}/${tempEventId}/initial/${timestamp}.jpg`
        );

        await uploadBytes(eventImageRef, blob);
        eventImageUrl = await getDownloadURL(eventImageRef);
      }

      const timestamp = serverTimestamp();
      const eventUrlSlug = await generateEventUrlSlug(eventName);

      const eventData = {
        eventName,
        tagline,
        isOnline,
        country: isOnline ? null : selectedCountry?.name,
        countryISO2: isOnline ? null : selectedCountry?.iso2,
        city: isOnline ? null : selectedCity?.name,
        address: isOnline ? null : address.trim() || null,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        startTime,
        endTime,
        timezone: timezone.name,
        timezoneOffset: timezone.offset,
        link,
        linkText: link ? linkText : "",
        eventImage: eventImageUrl,
        eventUrlSlug,
        followingCount: 0,
        createdAt: timestamp,
        ownerId: currentUser.uid,
        ownerData: {
          id: currentUser.uid,
          firstName: currentUser.firstName || "",
          lastName: currentUser.lastName || "",
          profileImage: currentUser.profileImage || "",
          urlSlug: currentUser.urlSlug || "",
        },
        attendeeCount: 0,
        hosts: {
          [currentUser.uid]: {
            status: "active",
            role: "Host",
            acceptedAt: timestamp,
            createdAt: timestamp,
          },
        },
      };

      const eventsRef = collection(
        firestore,
        `users/${currentUser.uid}/events`
      );
      const docRef = await addDoc(eventsRef, eventData);

      const hostsRef = collection(
        firestore,
        `users/${currentUser.uid}/events/${docRef.id}/hosts`
      );
      await addDoc(hostsRef, {
        status: "active",
        role: "Host",
        acceptedAt: timestamp,
        createdAt: timestamp,
      });

      if (onEventAdded) {
        onEventAdded({
          id: docRef.id,
          ...eventData,
          createdAt: new Date(),
        });
      }

      onClose(true);
      return true;
    } catch (error) {
      setError("Failed to add event. Please try again.");
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.modalBackdrop}>
      <div className={classes.modalContent}>
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Event
            <svg
              className={classes.closeIcon}
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>

        <form
          className={classes.interactions}
          onSubmit={handleSubmit}
          ref={formRef}
          style={{ opacity: loading ? 0.5 : 1 }}
        >
          <div style={{ opacity: loading ? 0.5 : 1 }}></div>

          <div className={classes.imageContainer}>
            <div className={classes.profileImage}>
              {currentEventImageUrl && isCropping ? (
                <div className={classes.cropContainer}>
                  <Cropper
                    image={currentEventImageUrl}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    showGrid={false}
                    cropSize={cropSize}
                    objectFit="cover"
                  />
                </div>
              ) : (
                <img src={currentEventImageUrl || defaultImage} alt="Event" />
              )}
            </div>
            <button
              type="button"
              className={classes.uploadButton}
              onClick={() => document.getElementById("eventImage").click()}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="gray"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M7.5 4.586A2 2 0 0 1 8.914 4h6.172a2 2 0 0 1 1.414.586L17.914 6H19a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h1.086L7.5 4.586ZM10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <input
            type="file"
            id="eventImage"
            accept="image/*"
            onChange={handleEventImageChange}
            className={classes.profileButton}
          />

          <label htmlFor="eventName" className={classes.label}>
            Name*
          </label>
          <input
            id="eventName"
            type="text"
            placeholder="Ex: YC Startup School Europe"
            className={classes.userInput}
            value={eventName}
            onChange={handleEventNameChange}
            disabled={isLoading}
          />
          {/* <div className={classes.charCount}>{eventName.length}/50</div> */}
          <div className={classes.border}></div>

          <label htmlFor="tagline" className={classes.label}>
            Tagline
          </label>
          <textarea
            id="tagline"
            value={tagline}
            onChange={handleTaglineChange}
            className={classes.textArea}
            placeholder="Ex: If you're a student or recent grad who has been thinking about starting a startup one day (or are in the very earliest stages of building one), we hope to meet you there!"
          />
          {/* <div className={classes.charCount}>{tagline.length}/250</div> */}
          <div className={classes.border}></div>

          <div className={classes.checkboxContainer}>
            <input
              type="checkbox"
              id="isOnline"
              className={classes.customCheckbox}
              checked={isOnline}
              onChange={(e) => setIsOnline(e.target.checked)}
            />
            <label htmlFor="isOnline">This event is hosted virtually</label>
          </div>

          {!isOnline && (
            <>
              <div className={classes.border}></div>

              <label htmlFor="country" className={classes.label}>
                Country*
              </label>
              <CustomDropdown
                id="country"
                options={countries}
                selectedValue={selectedCountry}
                onChange={handleCountryChange}
                inputValue={countryInputValue}
                setInputValue={setCountryInputValue}
                disabled={isLoading}
                placeholder="Ex: United Kingdom"
              />
              <label
                htmlFor="city"
                className={`${classes.label} ${
                  !selectedCountry || isLoading || !countriesLoaded
                    ? classes.disabled
                    : ""
                }`}
              >
                City*
              </label>
              <CustomDropdown
                id="city"
                options={cities}
                selectedValue={selectedCity}
                onChange={handleCityChange}
                inputValue={cityInputValue}
                setInputValue={setCityInputValue}
                disabled={!selectedCountry || isLoading}
                placeholder="Ex: London"
              />
            </>
          )}

          {!isOnline && (
            <>
              <label htmlFor="address" className={classes.label}>
                Address
              </label>
              <textarea
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className={classes.textArea}
                placeholder="Ex: Storey's Gate, London, SW1H 9NH"
                disabled={isLoading}
              />
            </>
          )}
          <div className={classes.border}></div>

          <TimezoneDropdown
            selectedTimezone={timezone}
            onTimezoneChange={setTimezone}
            disabled={isLoading}
          />
          <div className={classes.border}></div>

          <label htmlFor="startDate" className={classes.label}>
            Start date*
          </label>
          <div className={classes.dateContainer}>
            <MonthDropdown
              selectedMonth={startMonth}
              onMonthChange={setStartMonth}
              disabled={isLoading}
              maxMonth={startYear === currentYear ? currentMonth : 12}
              placeholder="Month"
              isEndDate={false}
              startYear={startYear}
              currentYear={currentYear}
              currentMonth={currentMonth}
            />
            <DayDropdown
              label=""
              selectedDay={startDay}
              onDayChange={setStartDay}
              disabled={isLoading}
              selectedMonth={startMonth}
              selectedYear={startYear}
              placeholder="Day"
            />
            <YearDropdown
              selectedYear={startYear}
              onYearChange={setStartYear}
              disabled={isLoading}
              maxYear={currentYear + 10} // Allow future dates for events
              placeholder="Year"
            />
          </div>

          <TimeDropdown
            selectedTime={startTime}
            onTimeChange={setStartTime}
            disabled={isLoading}
            placeholder="Time"
          />

          <div className={classes.border}></div>

          <label
            htmlFor="endDate"
            className={`${classes.label} ${
              !startDay || isLoading ? classes.disabled : ""
            }`}
          >
            End date*
          </label>
          <div className={classes.dateContainer}>
            <MonthDropdown
              selectedMonth={endMonth}
              onMonthChange={setEndMonth}
              disabled={isLoading || !startDay}
              maxMonth={endYear === currentYear ? currentMonth : 12}
              placeholder="Month"
              isEndDate={true}
              startYear={endYear}
              currentYear={currentYear}
              currentMonth={currentMonth}
            />
            <DayDropdown
              label=""
              selectedDay={endDay}
              onDayChange={setEndDay}
              disabled={isLoading || !startDay}
              selectedMonth={endMonth}
              selectedYear={endYear}
              placeholder="Day"
            />
            <YearDropdown
              selectedYear={endYear}
              onYearChange={setEndYear}
              disabled={isLoading || !startDay}
              maxYear={currentYear + 10}
              placeholder="Year"
            />
          </div>

          <TimeDropdown
            selectedTime={endTime}
            onTimeChange={setEndTime}
            disabled={isLoading || !startDay || !endDay}
            placeholder="Time"
            minTime={startDate === endDate ? startTime : undefined}
          />

          <div className={classes.border}></div>

          <label htmlFor="link" className={classes.label}>
            Link
          </label>
          <input
            id="link"
            type="url"
            placeholder="Ex: https://example.com"
            className={classes.userInput}
            value={link}
            onChange={handleLinkChange}
            disabled={isLoading}
          />
          {/* <div className={classes.charCount}>{link.length}/250</div> */}

          <label
            htmlFor="linkText"
            className={`${classes.linkTextLabel} ${
              !isValidUrl ? classes.disabledInput : ""
            }`}
          >
            Link text
          </label>
          <input
            id="linkText"
            type="text"
            placeholder="Ex: Register here"
            className={`${classes.userInput} ${
              !isValidUrl ? classes.disabledInput : ""
            }`}
            value={linkText}
            onChange={handleLinkTextChange}
            disabled={isLoading || !isValidUrl}
            style={{ opacity: isValidUrl ? 1 : 0.5 }}
          />
          <div
            className={`${classes.smallPrint} ${
              !isValidUrl ? classes.disabledInput : ""
            }`}
          >
            <p className={classes.custom}>
              Customize how your link will appear (optional)
            </p>
            {/* <span className={classes.charCount}>{linkText.length}/50</span> */}
          </div>
        </form>

        {error && <p className={classes.error}>{error}</p>}

        <div className={classes.cardFooter}>
          <div className={classes.smallPrint}>
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                clip-rule="evenodd"
              />
            </svg>
            *Required information
          </div>
          <button
            type="button"
            className={`${classes.button} ${
              isSubmitting ? classes.loading : ""
            } ${!areRequiredFieldsFilled() ? classes.disabled : ""}`}
            disabled={isSubmitting || !areRequiredFieldsFilled()}
            onClick={handleSaveClick}
          >
            {isSubmitting ? (
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEventModal;
