import React, { useState, useEffect, useMemo } from "react";
import { firestore } from "../../firebase";
import defaultImage from "../../assets/icons/profileImage.jpg";
import defaultStartupImage from "../../assets/images/startupImage.jpg";
import {
  collection,
  getDoc,
  doc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import classes from "./ReactionsModal.module.css";
import { useNavigate } from "react-router-dom";

const ReactionsModal = ({ onClose, postId, reactions, currentUserId }) => {
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      const userIds = Object.keys(reactions);
      const fetchedUsers = {};

      for (const userId of userIds) {
        try {
          let userData;

          if (userId.startsWith("startup_")) {
            const startupId = userId.split("_")[1];
            const usersSnapshot = await getDocs(collection(firestore, "users"));
            let startupDoc = null;
            let startupOwnerId = null;

            for (const userDoc of usersSnapshot.docs) {
              const potentialStartupDoc = await getDoc(
                doc(firestore, `users/${userDoc.id}/startups/${startupId}`)
              );
              if (potentialStartupDoc.exists()) {
                startupDoc = potentialStartupDoc;
                startupOwnerId = userDoc.id;
                break;
              }
            }

            if (startupDoc && startupDoc.exists()) {
              const startupData = startupDoc.data();

              // Get the reaction timestamp from startup's reactedPosts
              const reactedPostDoc = await getDoc(
                doc(
                  firestore,
                  `users/${startupOwnerId}/startups/${startupId}/reactedPosts/${postId}`
                )
              );

              userData = {
                ...startupData,
                id: userId,
                isStartup: true,
                firstName: startupData.startupName,
                lastName: "",
                profileImage: startupData.startupImage || defaultStartupImage,
                bio: startupData.bio || "",
                link: startupData.link || null,
                linkText: startupData.linkText || null,
                startupUrlSlug: startupData.startupUrlSlug,
                connectionType: "Startup",
                startupId: startupId,
                startupOwnerId: startupOwnerId,
                startupName: startupData.startupName,
                reactedAt: reactedPostDoc.exists()
                  ? reactedPostDoc.data().reactedAt
                  : null,
              };
            }
          } else {
            // Regular user reaction
            const userDoc = await getDoc(doc(firestore, "users", userId));
            if (userDoc.exists()) {
              userData = userDoc.data();
              const connectionType = await checkConnectionType(
                currentUserId,
                userId
              );
              userData.connectionType = connectionType;

              // Try regular reactedPosts first
              let reactedPostDoc = await getDoc(
                doc(firestore, `users/${userId}/reactedPosts/${postId}`)
              );

              // If not found, check reactedCommunityPosts
              if (!reactedPostDoc.exists()) {
                reactedPostDoc = await getDoc(
                  doc(
                    firestore,
                    `users/${userId}/reactedCommunityPosts/${postId}`
                  )
                );
              }

              userData.reactedAt = reactedPostDoc.exists()
                ? reactedPostDoc.data().reactedAt
                : null;
            }
          }

          if (userData) {
            fetchedUsers[userId] = userData;
          }
        } catch (error) {}
      }
      setUsers(fetchedUsers);
      setLoading(false);
    };

    fetchUsers();
  }, [reactions, currentUserId, postId]);

  useEffect(() => {
    const scrollY = window.scrollY;

    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
    };
  }, []);

  const emojiCounts = useMemo(() => {
    const counts = {};
    Object.values(reactions).forEach((emoji) => {
      counts[emoji] = (counts[emoji] || 0) + 1;
    });
    return counts;
  }, [reactions]);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const filteredReactions = useMemo(() => {
    return Object.entries(reactions).filter(([userId, emoji]) => {
      const user = users[userId];
      if (!user) return false;
      const searchName = user.isStartup
        ? user.startupName?.toLowerCase()
        : `${user.firstName} ${user.lastName}`.toLowerCase();
      return searchName.includes(searchTerm.toLowerCase());
    });
  }, [reactions, users, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNavigate = async (userId) => {
    onClose();
    try {
      if (userId.startsWith("startup_")) {
        // Get startup data from our fetched users
        const startupData = users[userId];
        if (startupData && startupData.startupUrlSlug) {
          navigate(`/startup/${startupData.startupUrlSlug}`);
        } else {
        }
      } else {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          navigate(`/${userData.urlSlug}`);
        }
      }
    } catch (error) {}
  };

  const sortedReactions = useMemo(() => {
    return Object.entries(reactions)
      .map(([userId, emoji]) => ({
        userId,
        emoji,
        ...users[userId],
      }))
      .sort((a, b) => {
        // Always put current user first
        if (a.userId === currentUserId) return -1;
        if (b.userId === currentUserId) return 1;

        // Get timestamps safely
        const getMillis = (timestamp) => {
          if (!timestamp) return 0;
          if (timestamp.toMillis) return timestamp.toMillis();
          if (timestamp instanceof Date) return timestamp.getTime();
          if (typeof timestamp === "number") return timestamp;
          return 0;
        };

        // Sort newest first
        return getMillis(b.reactedAt) - getMillis(a.reactedAt);
      });
  }, [reactions, users, currentUserId]);

  const formatTimeDifference = (timestamp) => {
    if (!timestamp) return "";

    const now = new Date();
    const reactionTime = timestamp.toDate();
    const diffInSeconds = Math.floor((now - reactionTime) / 1000);

    if (diffInSeconds < 60) return "now";
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) return `${diffInMinutes}m`;
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours}h`;
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) return `${diffInDays}d`;
    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) return `${diffInWeeks}w`;
    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) return `${diffInMonths}mo`;
    const diffInYears = Math.floor(diffInDays / 365);
    return `${diffInYears}y`;
  };

  const checkConnectionType = async (userId, connectionId) => {
    if (userId === connectionId) return "You";

    // First check if either user has blocked the other
    const currentUserBlockedRef = collection(
      firestore,
      `users/${userId}/blockedUsers`
    );
    const currentUserBlockedSnapshot = await getDocs(currentUserBlockedRef);

    // Check if current user has blocked the connection
    for (const doc of currentUserBlockedSnapshot.docs) {
      if (doc.data().userId === connectionId) {
        return "Blocked";
      }
    }

    // Check if connection has blocked the current user
    const connectionBlockedRef = collection(
      firestore,
      `users/${connectionId}/blockedUsers`
    );
    const connectionBlockedSnapshot = await getDocs(connectionBlockedRef);

    for (const doc of connectionBlockedSnapshot.docs) {
      if (doc.data().userId === userId) {
        return "Blocked";
      }
    }

    // If no blocks found, check regular connection status
    const userConnectionRef = doc(
      firestore,
      `users/${userId}/connections/${connectionId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    // Check for indirect connections
    const userConnectionsRef = collection(
      firestore,
      `users/${userId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${connectionId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  useEffect(() => {
    function detectSafari() {
      const isSafari =
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        /apple/i.test(navigator.vendor);
      document.documentElement.style.setProperty(
        "--emoji-font-size-modal",
        isSafari ? "1rem" : "1rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-top-modal",
        isSafari ? "1rem" : "1rem"
      );
    }

    detectSafari();
  }, []);

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Boosts ({Object.keys(reactions).length})
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>

        <div className={classes.reactionsList}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : sortedReactions.length > 0 ? (
            sortedReactions
              .filter((user) => {
                const searchName = user.isStartup
                  ? user.startupName?.toLowerCase()
                  : `${user.firstName} ${user.lastName}`.toLowerCase();
                return searchName.includes(searchTerm.toLowerCase());
              })
              .map(({ userId, emoji, ...user }) => (
                <div key={userId} className={classes.reactionItem}>
                  <div className={classes.userEmojiContainer}>
                    <img
                      onClick={() => handleNavigate(userId)}
                      src={
                        user.profileImage ||
                        (user.isStartup ? defaultStartupImage : defaultImage)
                      }
                      alt={
                        user.isStartup
                          ? user.startupName
                          : `${user.firstName} ${user.lastName}`
                      }
                      className={classes.profileImage}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = user.isStartup
                          ? defaultStartupImage
                          : defaultImage;
                      }}
                      style={{
                        borderRadius: user.isStartup ? 0 : "50%", // Add this line to set border radius
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div className={classes.userInfo}>
                    <h3 className={classes.name}>
                      <div className={classes.nameWrapper}>
                        <span
                          className={classes.nameText}
                          onClick={() => handleNavigate(userId)}
                        >
                          {user.isStartup
                            ? user.startupName
                            : `${user.firstName} ${user.lastName}`}
                        </span>
                        <span className={classes.connectionType}>
                          •{" "}
                          {user.isStartup
                            ? "Startup"
                            : userId === currentUserId
                            ? "You"
                            : user.connectionType || "Connection"}
                        </span>
                      </div>
                    </h3>
                    {user.bio && (
                      <p
                        className={`${classes.bio} ${
                          !user.bio && classes.noBio
                        }`}
                      >
                        {user.bio}
                      </p>
                    )}
                    {user.link && (
                      <a
                        href={user.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.userLink}
                      >
                        <span>{user.linkText || user.link}</span>
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </a>
                    )}
                  </div>
                </div>
              ))
          ) : (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No reactions found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReactionsModal;
