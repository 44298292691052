import React, { useState, useEffect } from "react";
import { firestore } from "../../firebase";
import defaultImage from "../../assets/icons/profileImage.jpg";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  writeBatch,
  deleteDoc,
} from "firebase/firestore";
import classes from "./EditFollowersModal.module.css";

const EditFollowersModal = ({
  onClose,
  startupData,
  currentUser,
  onFollowerRemoved,
}) => {
  const [loading, setLoading] = useState(true);
  const [followers, setFollowers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [removingUsers, setRemovingUsers] = useState(false);
  const [totalFollowers, setTotalFollowers] = useState(0);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    const scrollY = window.scrollY;
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";
    return () => {
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      window.scrollTo(0, scrollY);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const fetchFollowers = async () => {
      if (!startupData?.id || !startupData?.ownerId) return;

      try {
        setLoading(true);
        const followersRef = collection(
          firestore,
          `users/${startupData.ownerId}/startups/${startupData.id}/followers`
        );
        const followersSnapshot = await getDocs(followersRef);

        const followersData = await Promise.all(
          followersSnapshot.docs.map(async (followerDoc) => {
            const userDoc = await getDoc(
              doc(firestore, "users", followerDoc.id)
            );
            if (userDoc.exists()) {
              const userData = userDoc.data();
              const connectionType = await checkConnectionType(
                currentUser?.uid,
                followerDoc.id
              );

              // Structure the data to match what the parent component expects
              return {
                id: followerDoc.id,
                followedAt: followerDoc.data()?.followedAt || new Date(),
                connectionType,
                firstName: userData.firstName || "", // Keep these for local display
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || defaultImage,
                bio: userData.bio || "",
                urlSlug: userData.urlSlug || "",
                link: userData.link || null,
                linkText: userData.linkText || null,
                // Include userData object for parent component
                userData: {
                  firstName: userData.firstName || "",
                  lastName: userData.lastName || "",
                  profileImage: userData.profileImage || defaultImage,
                  bio: userData.bio || "",
                  link: userData.link || "",
                  linkText: userData.linkText || "",
                  urlSlug: userData.urlSlug || "",
                },
              };
            }
            return null;
          })
        );

        const validFollowers = followersData
          .filter(Boolean)
          .sort((a, b) => b.followedAt - a.followedAt);

        setFollowers(validFollowers);
        setTotalFollowers(validFollowers.length);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchFollowers();
  }, [startupData?.id, startupData?.ownerId, currentUser?.uid]);

  const checkConnectionType = async (currentUserId, targetUserId) => {
    if (!currentUserId || !targetUserId) return "Extended";
    if (currentUserId === targetUserId) return "You";

    const userConnectionRef = doc(
      firestore,
      `users/${currentUserId}/connections/${targetUserId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    const userConnectionsRef = collection(
      firestore,
      `users/${currentUserId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${targetUserId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredFollowers = followers.filter((follower) => {
    const fullName = `${follower.firstName} ${follower.lastName}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });

  const handleToggleUser = (userId) => {
    setSelectedUsers((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(userId)) {
        newSet.delete(userId);
      } else {
        newSet.add(userId);
      }
      return newSet;
    });
  };

  const handleRemoveFollowers = async () => {
    setRemovingUsers(true);
    try {
      const batch = writeBatch(firestore);

      for (const userId of selectedUsers) {
        const followerRef = doc(
          firestore,
          `users/${startupData.ownerId}/startups/${startupData.id}/followers/${userId}`
        );
        batch.delete(followerRef);

        const userFollowingRef = doc(
          firestore,
          `users/${userId}/startupsFollowing/${startupData.id}`
        );
        batch.delete(userFollowingRef);

        const followerNotificationsRef = collection(
          firestore,
          `users/${userId}/notifications`
        );
        const followerNotifQuery = query(
          followerNotificationsRef,
          where("startupId", "==", startupData.id),
          where("type", "in", [
            "startup_follow",
            "startup_follow_invite",
            "startup_follow_invite_response",
          ])
        );
        const followerNotifSnapshot = await getDocs(followerNotifQuery);
        followerNotifSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });
      }

      await batch.commit();

      // Keep all original properties when filtering remaining followers
      const remainingFollowers = followers
        .filter((follower) => !selectedUsers.has(follower.id))
        .map((follower) => ({
          id: follower.id,
          followedAt: follower.followedAt,
          connectionType: follower.connectionType,
          // Keep the original properties
          firstName: follower.firstName,
          lastName: follower.lastName,
          profileImage: follower.profileImage,
          bio: follower.bio,
          urlSlug: follower.urlSlug,
          link: follower.link,
          linkText: follower.linkText,
          // Also include the userData object for parent component
          userData: {
            firstName: follower.firstName,
            lastName: follower.lastName,
            profileImage: follower.profileImage,
            bio: follower.bio,
            link: follower.link,
            linkText: follower.linkText,
            urlSlug: follower.urlSlug,
          },
        }));

      setFollowers(remainingFollowers);
      setTotalFollowers(remainingFollowers.length);

      // Pass the properly formatted data back to parent
      onFollowerRemoved(remainingFollowers, selectedUsers.size);
      setSelectedUsers(new Set());

      if (remainingFollowers.length === 0) {
        onClose("success");
      }
    } catch (error) {
    } finally {
      setRemovingUsers(false);
    }
  };

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Followers ({followers.length})
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>

        <form className={classes.interactions}>
          <div className={classes.connectionsList}>
            {loading ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              </div>
            ) : filteredFollowers.length > 0 ? (
              filteredFollowers.map((follower) => (
                <div key={follower.id} className={classes.connectionItem}>
                  <img
                    src={follower.profileImage || defaultImage}
                    alt={`${follower.firstName} ${follower.lastName}`}
                    className={classes.profileImage}
                  />
                  <div className={classes.connectionInfo}>
                    <h3 className={classes.name}>
                      <div className={classes.nameWrapper}>
                        <span className={classes.nameText}>
                          {follower.firstName} {follower.lastName}
                        </span>
                        <span className={classes.connectionType}>
                          • {follower.connectionType}
                        </span>
                      </div>
                    </h3>
                    {follower.bio && (
                      <p className={classes.bio}>{follower.bio}</p>
                    )}
                    {follower.link && (
                      <div className={classes.userLinkContainer}>
                        <a
                          href={follower.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.userLink}
                        >
                          <span className={classes.userLinkText}>
                            {follower.linkText || follower.link}
                          </span>
                          <svg
                            className={classes.userLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                  </div>
                  <div className={classes.checkbox}>
                    <input
                      type="checkbox"
                      checked={selectedUsers.has(follower.id)}
                      onChange={() => handleToggleUser(follower.id)}
                    />
                    <div>
                      <svg
                        className={classes.checkmark}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4"
                          d="M5 11.917 9.724 16.5 19 7.5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.noConnections}></div>
            )}
          </div>
        </form>

        <div className={classes.cardFooter}>
          <button
            className={classes.button}
            disabled={selectedUsers.size === 0 || removingUsers}
            onClick={handleRemoveFollowers}
          >
            {removingUsers ? (
              <span
                className={`material-symbols-outlined ${classes.buttonIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Remove"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditFollowersModal;
