import React, { useEffect } from "react";
import { onMessage } from "firebase/messaging";
import { messaging } from "../firebase";

const NotificationHandler = () => {
  useEffect(() => {
    const handleForegroundMessage = async (payload) => {
      if (!("Notification" in window)) {
        return;
      }

      // Even if permission is granted, we'll request again to be sure
      const permission = await Notification.requestPermission();

      if (permission === "granted") {
        try {
          const notification = new Notification(payload.notification.title, {
            body: payload.notification.body,
            icon: "/favicon.png",
            tag: Date.now().toString(),
            requireInteraction: true,
          });

          notification.onclick = function () {
            window.focus();
            notification.close();
          };
        } catch (err) {
          // Fallback to service worker notification
          if (navigator.serviceWorker?.ready) {
            const registration = await navigator.serviceWorker.ready;
            await registration.showNotification(payload.notification.title, {
              body: payload.notification.body,
              icon: "/favicon.png",
              tag: Date.now().toString(),
              requireInteraction: true,
            });
          }
        }
      }
    };

    const unsubscribe = onMessage(messaging, handleForegroundMessage);
    return () => unsubscribe();
  }, []);

  return null;
};

export default NotificationHandler;
