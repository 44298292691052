import { firestore } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";

export async function generateUrlSlug(firstName, lastName) {
  if (!firstName || !lastName) {
    throw new Error("First name and last name are required");
  }

  const sanitizedFirst = firstName.toLowerCase().replace(/[^a-z0-9]/g, "");
  const sanitizedLast = lastName.toLowerCase().replace(/[^a-z0-9]/g, "");

  const baseSlug = `${sanitizedFirst}${sanitizedLast}`;
  let slug = baseSlug;
  let counter = 1;
  let maxAttempts = 10;

  try {
    while (maxAttempts > 0) {
      const q = query(
        collection(firestore, "users"),
        where("urlSlug", "==", slug)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return slug;
      }

      counter++;
      slug = `${baseSlug}${counter}`;
      maxAttempts--;
    }

    return `${baseSlug}${Date.now()}`;
  } catch (error) {
    throw new Error("Failed to generate URL slug");
  }
}

export async function updateUserUrlSlug(userId, firstName, lastName) {
  if (!userId) {
    throw new Error("User ID is required");
  }

  try {
    const userRef = doc(firestore, "users", userId);
    const userDoc = await getDoc(userRef);
    const newSlug = await generateUrlSlug(firstName, lastName);

    const updates = {
      firstName,
      lastName,
      urlSlug: newSlug,
      updatedAt: new Date().toISOString(),
    };

    if (!userDoc.exists()) {
      // Create new document if it doesn't exist
      await setDoc(userRef, {
        ...updates,
        createdAt: new Date().toISOString(),
      });
    } else {
      // Update existing document
      await updateDoc(userRef, updates);
    }

    return newSlug;
  } catch (error) {
    throw error;
  }
}
