import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import OnboardingNavbar from "../../../components/Navbars/OnboardingNavbar";
import classes from "./ForgotPassword.module.css";
import { Link } from "react-router-dom";

function ForgotPassword() {
  const { resetPassword } = useAuth();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [additionalMessage, setAdditionalMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [messagePopup, setMessagePopup] = useState(null);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.toLowerCase();
    setEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(emailValue));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isEmailValid) {
      setMessagePopup({
        message: "Please enter a valid email to reset your password.",
        isError: true,
      });
      return;
    }

    setLoading(true);
    try {
      await resetPassword(email);
      setMessagePopup({
        message: "We've sent you a reset link.",
        isError: false,
        // additionalMessage:
        //   "If you don't see the email in your inbox, check your spam folder. If it's not there, the email address may not be confirmed, or it may not match an existing Foundry account.",
      });
    } catch (error) {
      setMessagePopup({
        message: "Sorry, your email or password is incorrect.",
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const MessagePopup = ({ message, isError, additionalMessage, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          {isError ? (
            <svg
              className={classes.tickIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="red"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              className={classes.tickIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="orangered"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                clipRule="evenodd"
              />
            </svg>
          )}
          <p>{message}</p>
          {additionalMessage && (
            <p className={classes.additionalMessage}>{additionalMessage}</p>
          )}
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.page}>
      <OnboardingNavbar />
      <div className={classes.content}>
        {messagePopup && (
          <MessagePopup
            message={messagePopup.message}
            isError={messagePopup.isError}
            additionalMessage={messagePopup.additionalMessage}
            onClose={() => setMessagePopup(null)}
          />
        )}
        <div className={classes.formContainer}>
          <div className={classes.formContent}>
            <div className={classes.formAbout}>
              <p className={classes.primaryAbout}>Reset your password</p>
            </div>
            <form className={classes.interactions} onSubmit={handleSubmit}>
              <label htmlFor="email" className={classes.label}>
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Ex: joe@example.com"
                className={classes.email}
                value={email}
                onChange={handleEmailChange}
                disabled={loading}
              />
              <div className={classes.smallPrint}>
                <p>{message || "We'll send you a reset link to this email"}</p>
              </div>
              <button
                type="submit"
                className={`${classes.button} ${
                  loading ? classes.loading : ""
                }`}
                disabled={!isEmailValid || loading}
              >
                {loading ? (
                  <span
                    className={`material-symbols-outlined ${classes.loadingIcon}`}
                  >
                    progress_activity
                  </span>
                ) : (
                  "Send link"
                )}
              </button>

              {error && (
                <p className={classes.error}>
                  <span
                    className={`material-symbols-outlined ${classes.errorIcon}`}
                  >
                    error
                  </span>
                  {error}
                </p>
              )}
              {/* {additionalMessage && (
                <p className={classes.smallPrint}>{additionalMessage}</p>
              )} */}
              <div className={classes.backLink}></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
