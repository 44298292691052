import React, { useState } from "react";
import Nav from "../../components/Navbars/Nav";
import MobileNavbar from "../../components/Navbars/MobileNavbar";
import classes from "./UserAgreement.module.css";
import TermsAndConditions from "../../components/Cards/TermsAndConditions";
import userImage from "../../assets/icons/profileImage.jpg";
function UserAgreement() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);

  return (
    <div className={classes.page}>
      <Nav />
      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.card}>
            <div className={classes.cardTitle}>User Agreement</div>
            <div className={classes.cardAbout}>
              <div className={classes.skillContainer}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Effective January 18, 2025
              </div>
              <div className={classes.text}>
                Our mission is to connect the world’s startup community. Our
                services are designed to promote startup opportunities for our
                members by enabling you and millions of others to meet, exchange
                ideas, learn, and make decisions in a network of trusted
                relationships.
              </div>
            </div>
          </div>
          <div className={classes.card}>
            <div className={classes.cardTitle}>Introduction</div>
            <div className={classes.cardHeader}>1.1 Contract</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                When you use our Services you agree to all of these terms. Your
                use of our Services is also subject to our Privacy Policy, which
                covers how we collect, use, share, and store your personal
                information.
              </div>
              <div className={classes.text}>
                By creating a Foundry account or accessing or using our Services
                (described below), you are agreeing to enter into a legally
                binding contract with Foundry. If you do not agree to this
                contract (“Contract” or “User Agreement”), do not create an
                account or access or otherwise use any of our Services. If you
                wish to terminate this Contract at any time, you can do so by
                deactivating your account and no longer accessing or using our
                Services.
              </div>
              <div className={classes.cardHeader}>Services</div>
              <div className={classes.text}>
                This Contract applies to Foundry.bz, Foundry-branded apps, and
                other Foundry-related sites, apps, communications, and other
                services that state that they are offered under this Contract
                (“Services”), including the offsite collection of data for those
                Services, such as via our ads.
              </div>
              <div className={classes.cardHeader}>Foundry</div>
              <div className={classes.text}>
                You are entering into this Contract with Foundry Labs Ltd. (CN:
                15530153) (also referred to as "Foundry", “we” and “us”), who
                will be the controller of (or business responsible for) your
                personal data provided to, or collected by or for, or processed
                in connection with our Services.
                <br />
                <br />
                As a Visitor or Member of our Services, the collection, use, and
                sharing of your personal data is subject to our{" "}
                <a href="/privacy-policy" className={classes.link}>
                  Privacy Policy
                </a>
                , and updates.
                <br />
                <br />
                You acknowledge and have read our{" "}
                <a href="/privacy-policy" className={classes.link}>
                  Privacy Policy
                </a>
                .
              </div>
            </div>

            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>1.2 Members and Visitors</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                This Contract applies to Members and Visitors.
              </div>
              <div className={classes.text}>
                When you register and join the Foundry Services, you become a
                “Member”. If you have chosen not to register for our Services,
                you may access certain features as a “Visitor.”
              </div>
            </div>
            <div className={classes.noPostBorder}></div>

            <div className={classes.cardHeader}>1.3 Changes</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We may make changes to this Contract.
              </div>
              <div className={classes.text}>
                We may modify this Contract and our Privacy Policy from time to
                time. If required by applicable law or we make material changes
                to this Contract, we will provide you notice through our
                Services, or by other means, to provide you the opportunity to
                review the changes before they become effective. We agree that
                changes cannot be retroactive. If you object to any of these
                changes, you may deactivate your account. Your continued use of
                our Services after we publish or send a notice about our changes
                to these terms means that you are consenting to the updated
                terms as of their effective date.
              </div>
            </div>
          </div>
          {/* 2. Obligations */}
          <div className={classes.card}>
            <div className={classes.cardTitle}>2. Obligations</div>
            <div className={classes.cardHeader}>2.1 Service Eligibility</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Here are some promises that you make to us in this Contract:
              </div>
              <div className={classes.matchTally2}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                You’re eligible to enter into this Contract and you are at least
                our “Minimum Age.”
              </div>
              <div className={classes.text}>
                The Services are not for use by anyone under the age of 16.{" "}
                <br /> <br />
                To use the Services, you agree that: (1) you must be the
                "Minimum Age" (described below) or older; (2) you will only have
                one Foundry account, which must be in your real name; and (3)
                you are not already restricted by Foundry from using the
                Services. Creating an account with false information is a
                violation of our terms, including accounts registered on behalf
                of others or persons under the age of 16. <br /> <br /> “Minimum
                Age” means 16 years old. However, if law requires that you must
                be older in order for Foundry to lawfully provide the Services
                to you without parental consent (including using your personal
                data) then the Minimum Age is such older age.{" "}
              </div>
            </div>

            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>2.2 Your Account</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                You will keep your password a secret.
              </div>
              <div className={classes.matchTally2}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                You will not share your account with anyone else and will follow
                our policies and the law.
              </div>
              <div className={classes.text}>
                Members are account holders. You agree to: (1) protect against
                wrongful access to your account (e.g., use a strong password and
                keep it confidential); (2) not share or transfer your account or
                any part of it (e.g., sell or transfer the personal data of
                others by transferring your connections); and (3) follow the
                law, our list of Dos and Don’ts (below), and our{" "}
                <a href="/community-policies" className={classes.link}>
                  Community Policies
                </a>
                You are responsible for anything that happens through your
                account unless you deactivate it or report misuse.
                <br />
                <br />
                As between you and others, your account belongs to you.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>2.3 Payment</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                You’ll honor your payment obligations and you are okay with us
                storing your payment information. You understand that there may
                be fees and taxes that are added to our prices.
              </div>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Refunds are subject to our policy, and we may modify our prices
                prospectively.
              </div>

              <div className={classes.text}>
                If you buy any of our paid Services, you agree to pay us the
                applicable fees and taxes. Failure to pay these fees will result
                in the termination of your paid Services. Also, you agree that:
                <br />
                <br />
                <span className={classes.bold}>1. </span> Your purchase may be
                subject to foreign exchange fees or differences in prices based
                on location (e.g., exchange rates).
                <br />
                <br />
                <span className={classes.bold}>2. </span> We may store and
                continue billing your payment method (e.g., credit card), even
                after it has expired, to avoid interruptions in your paid
                Services and to use it to pay for other Services you may buy.
                You may update or change your payment method.
                <br />
                <br />
                <span className={classes.bold}>3. </span> If you purchase a
                subscription, your payment method automatically will be charged
                at the start of each subscription period for the fees and taxes
                applicable to that period. To avoid future charges, cancel
                before the renewal date.
                <br />
                <br />
                <span className={classes.bold}>4. </span>We may modify our
                prices effective prospectively upon reasonable notice to the
                extent allowed under the law.
                <br />
                <br />
                <span className={classes.bold}>5. </span>We may calculate taxes
                payable by you based on the billing information that you provide
                us.
                <br />
                <br />
                For startup owners, you can get a copy of payments sent to you
                through your Startup page by visiting the "Stripe Dashboard"
                under the "Supporters" section. For regular startup supporters,
                you can view your donation history in your settings under
                "Donations".
              </div>
            </div>

            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>2.4 Notices and Messages</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                You’re okay with us providing notices and messages to you
                through our websites, apps, and contact information. If your
                contact information is out of date, you may miss out on
                important notices.
              </div>

              <div className={classes.text}>
                You agree that we will provide notices and messages to you in
                the following ways: (1) within the Services or (2) sent to the
                contact information you provided us (e.g., email). You agree to
                keep your contact information up to date.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>2.5 Sharing</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                When you share information on our Services, others can see, copy
                and use that information.
              </div>

              <div className={classes.text}>
                Our Services allow sharing of information (including content) in
                many ways, such as through your profile (including startup,
                community, and event pages), posts, community posts, startup
                posts, links, donations, and messages. Depending on the feature
                and choices you make, information that you share may be seen by
                other Members, Visitors, or others (on or off of the Services).
                <br />
                <br />
                To the extent that laws allow this, we are not obligated to
                publish any content or other information on our Services and can
                remove it with or without notice.
              </div>
            </div>
          </div>
          {/* 3. Obligations */}
          <div className={classes.card}>
            <div className={classes.cardTitle}>3. Rights and Limits</div>
            <div className={classes.cardHeader}>
              3.1 Your license to Foundry
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                You own all of your original content that you provide to us, but
                you also grant us a non-exclusive license to it.
              </div>
              <div className={classes.matchTally2}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We’ll honor the choices you make about who gets to see your
                content, including how it can be used for ads.
              </div>
              <div className={classes.text}>
                As between you and Foundry, you own your original content that
                you submit or post to the Services.
                <br />
                <br />
                You grant Foundry and our affiliates the following non-exclusive
                license to the content and other information you provide (e.g.,
                share, post, upload, and/or otherwise submit) to our Services:
                <br />
                <br />
                A worldwide, transferable and sublicensable right to use, copy,
                modify, distribute, publicly perform and display, host, and
                process your content and other information without any further
                consent, notice and/or compensation to you or others. These
                rights are limited in the following ways:
                <br />
                <br />
                <span className={classes.bold}>1.</span> You can end this
                license for specific content by deleting such content from the
                Services, or generally by deactivating your account, except (a)
                to the extent you (1) shared it with others as part of the
                Services and they copied, re-shared it or stored it, (2) we had
                already sublicensed others prior to your content removal or
                deactivation of your account, or (3) we are required by law to
                retain or share it with others, and (b) for the reasonable time
                it takes to remove from backup and other systems.
                <br />
                <br />
                <span className={classes.bold}>2. </span>We will not include
                your content in advertisements for the products and services of
                third parties to others without your separate consent (including
                sponsored content). However, without compensation to you or
                others, ads may be served near your content and other
                information, and your social actions may be visible and included
                with ads, as noted in the Privacy Policy. If you use a Service
                feature, we may mention that with your name or photo to promote
                that feature within our Services.
                <br />
                <br />
                <span className={classes.bold}>3. </span> While we may edit and
                make format changes to your content (such as translating or
                transcribing it, modifying the size, layout or file type, and
                removing or adding labels or metadata), we will take steps to
                avoid materially modifying the meaning of your expression in
                content you share with others.
                <br />
                <br />
                <span className={classes.bold}>4. </span>Because you own your
                original content and we only have non-exclusive rights to it,
                you may choose to make it available to others, including under
                the terms of a{" "}
                <a
                  href="https://chooser-beta.creativecommons.org/"
                  className={classes.link}
                >
                  Creative Commons license.
                </a>
                <br />
              </div>
              <div className={classes.matchTally3}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                You and Foundry agree that if content includes personal data, it
                is subject to our Privacy Policy.
              </div>
              <div className={classes.text}>
                You and Foundry agree that we may access, store, process, and
                use any information (including content and/or personal data)
                that you provide in accordance with the terms of the{" "}
                <a href="/privacy-policy" className={classes.link}>
                  Privacy Policy{" "}
                </a>
                and your choices.
                <br />
                <br />
                By submitting suggestions or other feedback regarding our
                Services to Foundry, you agree that Foundry can use and share
                (but does not have to) such feedback for any purpose without
                compensation to you.
              </div>
              <div className={classes.matchTally3}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                You promise to only provide content and other information that
                you have the right to share and that your Foundry profile will
                be truthful.
              </div>
              <div className={classes.text}>
                You agree to only provide content and other information that
                does not violate the law or anyone’s rights (including
                intellectual property rights). You have choices about how much
                information to provide on your profile but also agree that the
                profile information you provide will be truthful. Foundry may be
                required by law to remove certain content and other information
                in certain countries.
              </div>
            </div>

            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>3.2 Service Availability</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We may change or limit the availability of some features, or end
                any Service.
              </div>

              <div className={classes.text}>
                We may change, suspend or discontinue any of our Services. We
                may also limit the availability of features, content and other
                information so that they are not available to all Visitors or
                Members (e.g., by country).
                <br />
                <br />
                We don’t promise to store or show (or keep showing) any
                information (including content) that you’ve shared. Foundry is
                not a storage service. You agree that we have no obligation to
                store, maintain or provide you a copy of any content or other
                information that you or others provide, except to the extent
                required by applicable law and as noted in our Privacy Policy.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>
              3.3 Other Content, Sites and Apps
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Your use of others’ content and information posted on our
                Services, is at your own risk.
              </div>
              <div className={classes.matchTally2}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Others may offer their own products and services through our
                Services, and we aren’t responsible for those third-party
                activities.
              </div>

              <div className={classes.text}>
                Others’ Content: By using the Services, you may encounter
                content or other information that might be inaccurate,
                incomplete, delayed, misleading, illegal, offensive, or
                otherwise harmful. You agree that we are not responsible for
                content or other information made available through or within
                the Services by others, including Members. While we manually
                review much of the content and other information presented in
                the Services, we cannot always prevent misuse of our Services,
                and you agree that we are not responsible for any such misuse.
                You also acknowledge the risk that others may share inaccurate
                or misleading information about you or your organization, and
                that you or your organization may be mistakenly associated with
                content about others.
                <br />
                <br />
                Others’ Products and Services: Foundry may help connect you to
                other Members who offer you opportunities (on behalf of
                themselves, their organizations, or others) such as offers to
                become a candidate for employment or other work or offers to
                purchase products or services. You acknowledge that Foundry does
                not perform these offered services, employ those who perform
                these services, or provide these offered products.
                <br />
                <br />
                You further acknowledge that Foundry does not supervise, direct,
                control, or monitor Members in the making of these offers, or in
                their providing you with work, delivering products or performing
                services, and you agree that (1) Foundry is not responsible for
                these offers, or performance or procurement of them, (2) Foundry
                does not endorse any particular Member’s offers, and (3) Foundry
                is not an agent or employment agency on behalf of any Member
                offering employment or other work, products or services. With
                respect to employment or other work, Foundry does not make
                employment or hiring decisions on behalf of Members offering
                opportunities and does not have such authority from Members or
                organizations using our products.
                <br />
                <br />
                Others’ Events: Similarly, Foundry may help you register for
                and/or attend events organized by Members and connect with other
                Members who are attendees at such events. You agree that (1)
                Foundry is not responsible for the conduct of any of the Members
                or other attendees at such events, (2) Foundry does not endorse
                any particular event listed on our Services, (3) Foundry does
                not review and/or vet any of these events or speakers, and (4)
                you will adhere to the terms and conditions that apply to such
                events.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>3.4 Limits</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We have the right to limit how you connect and interact on our
                Services.
              </div>

              <div className={classes.text}>
                Foundry reserves the right to limit your use of the Services,
                including the number of your connections and your ability to
                contact other Members. Foundry reserves the right to restrict,
                suspend, or terminate your account if you breach this Contract
                or the law or are misusing the Services (e.g., violating any of
                the Dos and Don’ts or{" "}
                <a href="/community-policies" className={classes.link}>
                  Community Policies
                </a>
                ).
                <br />
                <br />
                We can also remove any content or other information you shared
                if we believe it violates our{" "}
                <a href="/community-policies" className={classes.link}>
                  Community Policies
                </a>{" "}
                or Dos and Don’ts or otherwise violates this Contract.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>
              3.5 Intellectual Property Rights
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We’re providing you notice about our intellectual property
                rights.
              </div>

              <div className={classes.text}>
                Foundry reserves all of its intellectual property rights in the
                Services. Trademarks and logos used in connection with the
                Services are the trademarks of their respective owners. Foundry
                logos and other Foundry trademarks, service marks, graphics and
                logos used for our Services are trademarks or registered
                trademarks of Foundry.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>3.6 Recommendations</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We use data and other information about you to make and order
                relevant suggestions and to generate content for you and others.
              </div>

              <div className={classes.text}>
                Recommendations: We use the data and other information that you
                provide and that we have about Members and content on the
                Services to make recommendations for connections, content, ads,
                and features that may be useful to you. We use that data and
                other information to recommend and to present information to you
                in an order that may be more relevant for you. For example, that
                data and information may be used to recommend startups to you
                and you to startups and to organize content in your feed in
                order to optimize your experience and use of the Services.
                Keeping your profile accurate and up to date helps us to make
                these recommendations more accurate and relevant.
              </div>
            </div>
          </div>
          {/* 4. */}
          <div className={classes.card}>
            <div className={classes.cardTitle}>
              4. Disclaimer and Limit of Liability
            </div>
            <div className={classes.cardHeader}>4.1 No Warranty</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                This is our disclaimer of legal liability for the quality,
                safety, or reliability of our Services.
              </div>

              <div className={classes.text}>
                FOUNDRY AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY
                ABOUT THE SERVICES, INCLUDING ANY REPRESENTATION THAT THE
                SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND PROVIDE THE
                SERVICES (INCLUDING CONTENT AND INFORMATION) ON AN “AS IS” AND
                “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED UNDER
                APPLICABLE LAW, FOUNDRY AND ITS AFFILIATES DISCLAIM ANY IMPLIED
                OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE,
                ACCURACY OF DATA, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS
                FOR A PARTICULAR PURPOSE.
              </div>
            </div>

            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>4.2 Exclusion of Liability</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                These are the limits of legal liability we may have to you.
              </div>

              <div className={classes.text}>
                TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS FOUNDRY HAS
                ENTERED INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS
                CONTRACT), FOUNDRY, INCLUDING ITS AFFILIATES, WILL NOT BE LIABLE
                IN CONNECTION WITH THIS CONTRACT FOR LOST PROFITS OR LOST
                BUSINESS OPPORTUNITIES, REPUTATION (E.G., OFFENSIVE OR
                DEFAMATORY STATEMENTS), LOSS OF DATA (E.G., DOWN TIME OR LOSS,
                USE OF, OR CHANGES TO, YOUR INFORMATION OR CONTENT) OR ANY
                INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE
                DAMAGES.
                <br />
                <br />
                FOUNDRY AND ITS AFFILIATES WILL NOT BE LIABLE TO YOU IN
                CONNECTION WITH THIS CONTRACT FOR ANY AMOUNT THAT EXCEEDS (A)
                THE TOTAL FEES PAID OR PAYABLE BY YOU TO FOUNDRY FOR THE
                SERVICES DURING THE TERM OF THIS CONTRACT, IF ANY, OR (B) US
                $1000.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>
              4.3 Basis of the Bargain; Exclusions
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.text}>
                The limitations of liability in this Section 4 are part of the
                basis of the bargain between you and Foundry and shall apply to
                all claims of liability (e.g., warranty, tort, negligence,
                contract and law) even if Foundry or its affiliates has been
                told of the possibility of any such damage, and even if these
                remedies fail their essential purpose.
                <br />
                <br />
                THESE LIMITATIONS OF LIABILITY DO NOT APPLY TO LIABILITY FOR
                DEATH OR PERSONAL INJURY OR FOR FRAUD, GROSS NEGLIGENCE OR
                INTENTIONAL MISCONDUCT, OR IN CASES OF NEGLIGENCE, WHERE A
                MATERIAL OBLIGATION HAS BEEN BREACHED. A MATERIAL OBLIGATION
                BEING AN OBLIGATION WHICH FORMS A PREREQUISITE TO OUR DELIVERY
                OF SERVICES AND ON WHICH YOU MAY REASONABLY RELY, BUT ONLY TO
                THE EXTENT THAT THE DAMAGES WERE DIRECTLY CAUSED BY THE BREACH
                AND WERE FORESEEABLE UPON CONCLUSION OF THIS CONTRACT AND TO THE
                EXTENT THAT THEY ARE TYPICAL IN THE CONTEXT OF THIS CONTRACT.
              </div>
            </div>
          </div>
          {/* 5. */}
          <div className={classes.card}>
            <div className={classes.cardTitle}>5. Termination</div>

            <div className={classes.cardAbout}>
              <div className={classes.matchTally4}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We can each end this Contract, but some rights and obligations
                survive.
              </div>

              <div className={classes.text}>
                Both you and Foundry may terminate this Contract at any time
                with notice to the other. On termination, you lose the right to
                access or use the Services. The following shall survive
                termination:
                <br />
                <br />
                <span className={classes.bold}>1.</span> Our rights to use and
                disclose your feedback;
                <br />
                <br />
                <span className={classes.bold}>2.</span> Section 3 (subject to
                3.1.1);
                <br />
                <br />
                <span className={classes.bold}>3.</span> Sections 4, 6, 7, and
                8.2 of this Contract; and
                <br />
                <br />
                <span className={classes.bold}>3.</span> Any amounts owed by
                either party prior to termination remain owed after termination.
              </div>
            </div>
          </div>
          {/* 6. */}
          <div className={classes.card}>
            <div className={classes.cardTitle}>
              6. Governing Law and Dispute Resolution
            </div>

            <div className={classes.cardAbout}>
              <div className={classes.matchTally4}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                In the unlikely event we end up in a legal dispute, you and
                Foundry agree to resolve it in the courts of England and Wales,
                using English law. The courts of England and Wales shall have
                exclusive jurisdiction over any dispute arising out of or in
                connection with this agreement, including any disputes regarding
                its existence, validity, or termination.
              </div>

              <div className={classes.text}>
                You and Foundry agree that the laws of England and Wales,
                excluding its conflict of laws rules, shall exclusively govern
                any dispute relating to this Contract and/or the Services. You
                and Foundry both agree that all claims and disputes can be
                litigated only in the courts of England and Wales, and you and
                Foundry each agree to personal jurisdiction in those courts.
              </div>
            </div>
          </div>
          {/* 7. */}
          <div className={classes.card}>
            <div className={classes.cardTitle}>7. General Terms</div>

            <div className={classes.cardAbout}>
              <div className={classes.matchTally4}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Here are some important details about the Contract.
              </div>

              <div className={classes.text}>
                If a court with authority over this Contract finds any part of
                it unenforceable, you and we agree that the court should modify
                the terms to make that part enforceable while still achieving
                its intent. If the court cannot do that, you and we agree to ask
                the court to remove that unenforceable part and still enforce
                the rest of this Contract.
                <br />
                <br />
                This Contract (including additional terms that may be provided
                by us when you engage with a feature of the Services) is the
                only agreement between us regarding the Services and supersedes
                all prior agreements for the Services.
                <br />
                <br />
                If we don't act to enforce a breach of this Contract, that does
                not mean that Foundry has waived its right to enforce this
                Contract. You may not assign or transfer this Contract (or your
                membership or use of Services) to anyone without our consent.
                However, you agree that Foundry may assign this Contract to its
                affiliates or a party that buys it without your consent. There
                are no third-party beneficiaries to this Contract.
                <br />
                <br />
                You agree that the only way to provide us legal notice is at the
                address provided in Section 10.
              </div>
            </div>
          </div>
          {/* 8. */}
          <div className={classes.card}>
            <div className={classes.cardTitle}>8. Foundry “Dos and Don’ts”</div>

            <div className={classes.cardAbout}>
              <div className={classes.matchTally4}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Foundry is a community of professionals. This list of “Dos and
                Don’ts” along with our Community Policies limits what you can
                and cannot do on our Services, unless otherwise explicitly
                permitted by Foundry in a separate writing (e.g., through a
                research agreement).
              </div>
            </div>

            <div className={classes.cardHeader}>8.1. Dos</div>
            <div className={classes.cardAbout}>
              <div className={classes.text}>
                <span className={classes.bold}>You agree that you will:</span>
                <br />
                <br />
                <span className={classes.bold}>1. </span> Comply with all
                applicable laws, including, without limitation, privacy laws,
                intellectual property laws, anti-spam laws, export control laws,
                laws governing the content shared, and other applicable laws and
                regulatory requirements;
                <br />
                <br />
                <span className={classes.bold}>2. </span> Provide accurate
                contact and identity information to us and keep it updated;
                <br />
                <br />
                <span className={classes.bold}>3. </span> Use your real name on
                your profile; and
                <br />
                <br />
                <span className={classes.bold}>4. </span> Use the Services in a
                professional manner.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>8.2. Don’ts</div>
            <div className={classes.cardAbout}>
              <div className={classes.text}>
                <span className={classes.bold}>
                  You agree that you will not:
                </span>
                <br />
                <br />
                <span className={classes.bold}>1. </span> Create a false
                identity on Foundry, misrepresent your identity, create a Member
                profile for anyone other than yourself (a real person), or use
                or attempt to use another’s account (such as sharing log-in
                credentials or copying cookies);
                <br />
                <br />
                <span className={classes.bold}>2. </span> Develop, support or
                use software, devices, scripts, robots or any other means or
                processes (such as crawlers, browser plugins and add-ons or any
                other technology) to scrape or copy the Services, including
                profiles and other data from the Services;
                <br />
                <br />
                <span className={classes.bold}>3. </span> Override any security
                feature or bypass or circumvent any access controls or use
                limits of the Services (such as search results, profiles, or
                videos);
                <br />
                <br />
                <span className={classes.bold}>4. </span>
                Copy, use, display or distribute any information (including
                content) obtained from the Services, whether directly or through
                third parties (such as search tools or data aggregators or
                brokers), without the consent of the content owner (such as
                Foundry for content it owns);
                <br />
                <br />
                <span className={classes.bold}>5. </span>
                Disclose information that you do not have the consent to
                disclose (such as confidential information of others (including
                your employer);
                <br />
                <br />
                <span className={classes.bold}>6. </span>
                Violate the intellectual property rights of others, including
                copyrights, patents, trademarks, trade secrets or other
                proprietary rights. For example, do not copy or distribute
                (except through the available sharing functionality) the posts
                or other content of others without their permission, which they
                may give by posting under a Creative Commons license;
                <br />
                <br />
                <span className={classes.bold}>7. </span>
                Violate the intellectual property or other rights of Foundry,
                including, without limitation, (i) copying or distributing our
                videos or other materials, (ii) copying or distributing our
                technology, unless it is released under open source licenses; or
                (iii) using the word “Foundry” or our logos in any business
                name, email, or URL;
                <br />
                <br />
                <span className={classes.bold}>8. </span>
                Post (or otherwise share) anything that contains software
                viruses, worms, or any other harmful code;
                <br />
                <br />
                <span className={classes.bold}>9. </span>
                Reverse engineer, decompile, disassemble, decipher or otherwise
                attempt to derive the source code for the Services or any
                related technology that is not open source;
                <br />
                <br />
                <span className={classes.bold}>10. </span>
                Imply or state that you are affiliated with or endorsed by
                Foundry without our express consent;
                <br />
                <br />
                <span className={classes.bold}>11. </span>
                Rent, lease, loan, trade, sell/re-sell or otherwise monetize the
                Services or related data or access to the same, without
                Foundry's consent;
                <br />
                <br />
                <span className={classes.bold}>12. </span>
                Deep-link to our Services for any purpose other than to promote
                your profile or a Startup, Community, or Event on our Services,
                without Foundry's consent;
                <br />
                <br />
                <span className={classes.bold}>13. </span>
                Use bots or other unauthorized automated methods to access the
                Services, add or download contacts, send or redirect messages,
                create, comment on, boost, share, or re-share posts, or
                otherwise drive inauthentic engagement;
                <br />
                <br />
                <span className={classes.bold}>14. </span>
                Engage in “framing”, “mirroring”, or otherwise simulating the
                appearance or function of the Services;
                <br />
                <br />
                <span className={classes.bold}>15. </span>
                Overlay or otherwise modify the Services or their appearance
                (such as by inserting elements into the Services or removing,
                covering, or obscuring an advertisement included on the
                Services);
                <br />
                <br />
                <span className={classes.bold}>16. </span>
                Interfere with the operation of, or place an unreasonable load
                on, the Services (e.g., spam, denial of service attack, viruses,
                manipulating algorithms);
                <br />
                <br />
                <span className={classes.bold}>17. </span>
                Violate the{" "}
                <a href="/community-policies" className={classes.link}>
                  Community Policies
                </a>
                , certain third party terms where applicable, or any additional
                terms concerning a specific Service that are provided when you
                sign up for or start using such Service;
                <br />
                <br />
                <span className={classes.bold}>18. </span>
                Use our Services to do anything that is unlawful, misleading,
                discriminatory, or fraudulent; and/or
                <br />
                <br />
                <span className={classes.bold}>19. </span>
                Misuse our reporting or appeals process, including by submitting
                duplicative, fraudulent or unfounded reports, complaints or
                appeals.
              </div>
            </div>
          </div>
          {/* 9. */}
          <div className={classes.card}>
            <div className={classes.cardTitle}>
              9. Complaints Regarding Content
            </div>

            <div className={classes.cardAbout}>
              <div className={classes.matchTally4}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Contact information for complaints about content provided by our
                Members.
              </div>
            </div>

            <div className={classes.cardAbout}>
              <div className={classes.text}>
                We ask that you report content and other information that you
                believe violates your rights (including intellectual property
                rights), our{" "}
                <a href="/community-policies" className={classes.link}>
                  Community Policies
                </a>{" "}
                or otherwise violates this Contract or the law. To the extent we
                can under law, we may remove or restrict access to content,
                features, services, or information, including if we believe that
                it’s reasonably necessary to avoid harm to Foundry or others,
                violates the law or is reasonably necessary to prevent misuse of
                our Services. We reserve the right to take action against
                serious violations of this Contract, including by implementing
                account restrictions for significant violations.
                <br />
                <br />
                We respect the intellectual property rights of others. We
                require that information shared by Members be accurate and not
                in violation of the intellectual property rights or other rights
                of third parties.
              </div>
            </div>
          </div>
          {/* 10. */}
          <div className={classes.card}>
            <div className={classes.cardTitle}>10. How To Contact Us</div>

            <div className={classes.cardAbout}>
              <div className={classes.matchTally4}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Our Contact information.
              </div>
            </div>

            <div className={classes.cardAbout}>
              <div className={classes.text}>
                For all inquiries, you may contact us at{" "}
                <span className={classes.link}>hello@foundry.bz</span>.
              </div>
            </div>
          </div>
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default UserAgreement;
