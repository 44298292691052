import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import defaultImage from "../../assets/icons/profileImage.jpg";
import { firestore } from "../../firebase";
import AddTeamModal from "./AddTeamModal";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  writeBatch,
} from "firebase/firestore";
import classes from "./ConnectionsModal.module.css";

const ConnectionsModal = ({
  onClose,
  currentUserId,
  loggedInUserId,
  onInviteSent,
  isOwnProfile,
  showConnectionDate,
  onConnectionRemoved,
  isTeamSelection = false,
  isOrganiserSelection = false,
  isEditTeam = false,
  isEditOrganisers = false, // Add this line
  startupData,
  eventData,
  teamMembers,
  hosts,
  onTeamMemberRemoved,
  onConnectionClick,
}) => {
  const [showAddOrganiserModal, setShowAddOrganiserModal] = useState(false); // Add this line

  const [showAddTeamModal, setShowAddTeamModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const [pendingInvites, setPendingInvites] = useState(new Set());
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [openDropdown, setOpenDropdown] = useState(null);
  const [totalConnections, setTotalConnections] = useState(0);

  // Fetch pending invites when component mounts
  useEffect(() => {
    const fetchPendingInvites = async () => {
      if (!isTeamSelection || !startupData?.id || !startupData?.ownerId) return;

      try {
        const notificationsRef = collection(
          firestore,
          `users/${startupData.ownerId}/startups/${startupData.id}/team`
        );
        const q = query(notificationsRef, where("status", "==", "pending"));
        const snapshot = await getDocs(q);

        const pendingIds = new Set();
        snapshot.forEach((doc) => {
          pendingIds.add(doc.id);
        });
        setPendingInvites(pendingIds);
      } catch (error) {}
    };

    fetchPendingInvites();
  }, [isTeamSelection, startupData]);

  const title = isEditOrganisers
    ? "Edit Organisers"
    : isOrganiserSelection
    ? "Add Organisers"
    : isEditTeam
    ? "Edit Team"
    : isTeamSelection
    ? "Add Team"
    : `${totalConnections} ${
        totalConnections === 1 ? "Connection" : "Connections"
      }`;

  const handleRemoveInvite = async (userId) => {
    try {
      const batch = writeBatch(firestore);

      if (isOrganiserSelection) {
        // Handle organiser invite removal
        const hostRef = doc(
          firestore,
          `users/${eventData.ownerId}/events/${eventData.id}/hosts/${userId}`
        );
        batch.delete(hostRef);

        // Delete organiser notification
        const userNotificationsRef = collection(
          firestore,
          `users/${userId}/notifications`
        );
        const notifQuery = query(
          userNotificationsRef,
          where("type", "==", "organiser_invite"),
          where("eventId", "==", eventData.id)
        );
        const notifSnapshot = await getDocs(notifQuery);
        notifSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });
      } else if (isTeamSelection) {
        // Existing team invite removal logic
        const teamMemberRef = doc(
          firestore,
          `users/${startupData.ownerId}/startups/${startupData.id}/team/${userId}`
        );
        batch.delete(teamMemberRef);

        // Delete team notification
        const userNotificationsRef = collection(
          firestore,
          `users/${userId}/notifications`
        );
        const notifQuery = query(
          userNotificationsRef,
          where("type", "==", "team_invite"),
          where("startupId", "==", startupData.id)
        );
        const notifSnapshot = await getDocs(notifQuery);
        notifSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });
      }

      await batch.commit();

      // Update local state
      setPendingInvites((prev) => {
        const newSet = new Set(prev);
        newSet.delete(userId);
        return newSet;
      });

      setOpenDropdown(null);
    } catch (error) {}
  };

  const handleRemoveTeamMember = async (e, memberId) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const batch = writeBatch(firestore);

      // 1. Remove user from startup's team collection
      const teamMemberRef = doc(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/team/${memberId}`
      );
      batch.delete(teamMemberRef);

      // 2. Delete "joined team" notification from owner's notifications
      const ownerNotificationsRef = collection(
        firestore,
        `users/${startupData.ownerId}/notifications`
      );
      const ownerNotifQuery = query(
        ownerNotificationsRef,
        where("type", "==", "team_invite_accepted"),
        where("from", "==", memberId),
        where("startupId", "==", startupData.id)
      );
      const ownerNotifSnapshot = await getDocs(ownerNotifQuery);
      ownerNotifSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // 3. Delete "invite accepted/response" notification from member's notifications
      const memberNotificationsRef = collection(
        firestore,
        `users/${memberId}/notifications`
      );
      const memberNotifQuery = query(
        memberNotificationsRef,
        where("type", "==", "team_invite_response"),
        where("startupId", "==", startupData.id)
      );
      const memberNotifSnapshot = await getDocs(memberNotifQuery);
      memberNotifSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // 4. Execute all operations
      await batch.commit();

      // 5. Update local state
      setConnections((prev) => prev.filter((conn) => conn.id !== memberId));
      setOpenDropdown(null);

      // 6. Notify parent component
      if (onTeamMemberRemoved) {
        onTeamMemberRemoved();
      }
    } catch (error) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setInitialLoadComplete(false);

        // Don't fetch connections if we're in team selection or edit mode
        if (isTeamSelection || isEditTeam) {
          setLoading(false);
          setInitialLoadComplete(true);
          return;
        }

        const connectionsRef = collection(
          firestore,
          `users/${currentUserId}/connections`
        );
        const q = query(connectionsRef, where("status", "==", "Connected"));
        const querySnapshot = await getDocs(q);

        // Filter out dummyId before processing connections
        const validDocs = querySnapshot.docs.filter(
          (doc) => doc.id !== "dummyId"
        );

        const connectionsData = await Promise.all(
          validDocs.map(async (connectionDoc) => {
            const userDocRef = doc(firestore, "users", connectionDoc.id);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
              const userData = userDoc.data();
              const connectionType = await checkConnectionType(
                loggedInUserId,
                connectionDoc.id
              );

              return {
                id: connectionDoc.id,
                firstName: userData.firstName,
                lastName: userData.lastName,
                profileImage: userData.profileImage,
                bio: userData.bio,
                link: userData.link,
                linkText: userData.linkText,
                urlSlug: userData.urlSlug,
                connectionType,
                connectedAt: connectionDoc.data().connectedAt?.toDate
                  ? connectionDoc.data().connectedAt.toDate()
                  : new Date(connectionDoc.data().connectedAt || 0),
              };
            }
            return null;
          })
        );

        // Filter out null values and sort
        const filteredConnections = connectionsData
          .filter(Boolean)
          .sort((a, b) => {
            if (a.id === loggedInUserId) return -1;
            if (b.id === loggedInUserId) return 1;
            return b.connectedAt - a.connectedAt;
          });

        setConnections(filteredConnections);
        setTotalConnections(filteredConnections.length);
      } catch (error) {
      } finally {
        setLoading(false);
        setInitialLoadComplete(true);
      }
    };

    if (currentUserId) {
      fetchData();
    }
  }, [currentUserId, loggedInUserId, isTeamSelection, isEditTeam]);

  const renderOptions = (connection) => {
    // For organizer selection, only show uninvite dropdown if there's a pending invite
    if (isOrganiserSelection && pendingInvites?.has(connection.id)) {
      return (
        <div className={classes.optionsContainer}>
          <svg
            className={`${classes.optionsIcon} ${
              openDropdown === connection.id ? classes.optionsIconActive : ""
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 24 24"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpenDropdown(
                openDropdown === connection.id ? null : connection.id
              );
            }}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeWidth="3"
              d="M6 12h.01m6 0h.01m5.99 0h.01"
            />
          </svg>
          {openDropdown === connection.id && (
            <div className={classes.optionsDropdown}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleRemoveInvite(connection.id);
                }}
              >
                <svg
                  className={classes.removeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="gray"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm-2 9a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1Zm13-6a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Z"
                    clipRule="evenodd"
                  />
                </svg>
                Uninvite
              </button>
            </div>
          )}
        </div>
      );
    }

    // Don't show any options for regular organizer selection
    if (isOrganiserSelection) {
      return null;
    }

    // Existing team selection logic stays exactly the same
    if (isTeamSelection && pendingInvites.has(connection.id)) {
      return (
        <div className={classes.optionsContainer}>
          <svg
            className={`${classes.optionsIcon} ${
              openDropdown === connection.id ? classes.optionsIconActive : ""
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 24 24"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpenDropdown(
                openDropdown === connection.id ? null : connection.id
              );
            }}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeWidth="3"
              d="M6 12h.01m6 0h.01m5.99 0h.01"
            />
          </svg>
          {openDropdown === connection.id && (
            <div className={classes.optionsDropdown}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleRemoveInvite(connection.id);
                }}
              >
                <svg
                  className={classes.removeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="gray"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm-2 9a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1Zm13-6a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Z"
                    clipRule="evenodd"
                  />
                </svg>
                Uninvite
              </button>
            </div>
          )}
        </div>
      );
    } else if (isEditTeam) {
      return (
        <div className={classes.optionsContainer}>
          <svg
            className={`${classes.optionsIcon} ${
              openDropdown === connection.id ? classes.optionsIconActive : ""
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 24 24"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpenDropdown(
                openDropdown === connection.id ? null : connection.id
              );
            }}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeWidth="3"
              d="M6 12h.01m6 0h.01m5.99 0h.01"
            />
          </svg>
          {openDropdown === connection.id && (
            <div className={classes.optionsDropdown}>
              <button onClick={(e) => handleRemoveTeamMember(e, connection.id)}>
                <svg
                  className={classes.removeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="gray"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm-2 9a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1Zm13-6a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Z"
                    clipRule="evenodd"
                  />
                </svg>
                Remove
              </button>
            </div>
          )}
        </div>
      );
    } else if (
      !isTeamSelection &&
      isOwnProfile &&
      connection.id !== loggedInUserId
    ) {
      // Regular connection options
      return renderConnectionOptions(connection);
    }
    return null;
  };

  const handleAddTeamModalClose = (result) => {
    if (result === "success") {
      setPendingInvites((prev) => {
        const newSet = new Set(prev);
        newSet.add(selectedUser);
        return newSet;
      });
      onInviteSent();
    }
    setShowAddTeamModal(false);
  };

  const InvitePopup = ({ onClose }) => (
    <div className={classes.repostPopup}>
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="orangered"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
            clipRule="evenodd"
          />
        </svg>
        <p>Invite sent.</p>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation(); // Prevent event from bubbling up
          onClose();
        }}
        className={classes.repostCloseButton}
      >
        <svg
          className={classes.closeIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  // Separate handler for popup close
  const handlePopupClose = () => {
    setShowInvitePopup(false);
  };

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  const checkConnectionType = async (userId, connectionId) => {
    if (isOwnProfile || userId === connectionId) return "Direct";

    const userConnectionRef = doc(
      firestore,
      `users/${userId}/connections/${connectionId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    // Check for indirect connections
    const userConnectionsRef = collection(
      firestore,
      `users/${userId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${connectionId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  const renderConnectionOptions = (connection) => {
    if (isOwnProfile && connection.id !== loggedInUserId) {
      return (
        <div className={classes.optionsContainer}>
          <svg
            className={`${classes.optionsIcon} ${
              openDropdown === connection.id ? classes.optionsIconActive : ""
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 24 24"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpenDropdown(
                openDropdown === connection.id ? null : connection.id
              );
            }}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeWidth="3"
              d="M6 12h.01m6 0h.01m5.99 0h.01"
            />
          </svg>
          {openDropdown === connection.id && (
            <div className={classes.optionsDropdown}>
              <button onClick={(e) => handleRemoveConnection(e, connection.id)}>
                <svg
                  className={classes.removeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="gray"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm-2 9a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1Zm13-6a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Z"
                    clipRule="evenodd"
                  />
                </svg>
                Remove
              </button>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const handleRemoveConnection = async (e, connectionId) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const batch = writeBatch(firestore);

      // Delete the connection documents
      batch.delete(
        doc(firestore, `users/${loggedInUserId}/connections/${connectionId}`)
      );
      batch.delete(
        doc(firestore, `users/${connectionId}/connections/${loggedInUserId}`)
      );

      // Remove the "is now a connection" notification for both users
      const currentUserNotificationsRef = collection(
        firestore,
        `users/${loggedInUserId}/notifications`
      );
      const profileUserNotificationsRef = collection(
        firestore,
        `users/${connectionId}/notifications`
      );

      const [currentUserNotifications, profileUserNotifications] =
        await Promise.all([
          getDocs(
            query(
              currentUserNotificationsRef,
              where("type", "==", "connection_accepted"),
              where("from", "==", connectionId)
            )
          ),
          getDocs(
            query(
              profileUserNotificationsRef,
              where("type", "==", "connection_accepted"),
              where("from", "==", loggedInUserId)
            )
          ),
        ]);

      currentUserNotifications.forEach((doc) => batch.delete(doc.ref));
      profileUserNotifications.forEach((doc) => batch.delete(doc.ref));

      // Commit the batch
      await batch.commit();

      // Update local state
      setConnections((prevConnections) => {
        const updatedConnections = prevConnections.filter(
          (conn) => conn.id !== connectionId
        );
        // If this was the last connection, close the modal
        if (updatedConnections.length === 0) {
          onClose();
        }
        return updatedConnections;
      });

      setTotalConnections((prev) => {
        const newTotal = prev - 1;
        // Double-check if we should close the modal
        if (newTotal === 0) {
          onClose();
        }
        return newTotal;
      });

      setOpenDropdown(null);

      // Call the callback function to update the parent state
      if (onConnectionRemoved && typeof onConnectionRemoved === "function") {
        onConnectionRemoved();
      }
    } catch (error) {}
  };

  useEffect(() => {
    const fetchConnections = async () => {
      // Don't fetch connections if we're in team selection or edit mode
      if (isTeamSelection || isEditTeam) {
        return;
      }

      try {
        setLoading(true);
        setInitialLoadComplete(false);

        const connectionsRef = collection(
          firestore,
          `users/${currentUserId}/connections`
        );
        const q = query(connectionsRef, where("status", "==", "Connected"));
        const querySnapshot = await getDocs(q);

        // Filter out dummyId before processing connections
        const validDocs = querySnapshot.docs.filter(
          (doc) => doc.id !== "dummyId"
        );

        const connectionsData = await Promise.all(
          validDocs.map(async (connectionDoc) => {
            const userDocRef = doc(firestore, "users", connectionDoc.id);
            const userDoc = await getDoc(userDocRef);
            const connectionType = await checkConnectionType(
              loggedInUserId,
              connectionDoc.id
            );

            // Check if this connection is an active team member
            let isActiveTeamMember = false;
            if (startupData?.id && startupData?.ownerId) {
              const teamMemberRef = doc(
                firestore,
                `users/${startupData.ownerId}/startups/${startupData.id}/team/${connectionDoc.id}`
              );
              const teamMemberDoc = await getDoc(teamMemberRef);
              isActiveTeamMember =
                teamMemberDoc.exists() &&
                teamMemberDoc.data().status === "active";
            }

            // Only include if not an active team member
            if (!isActiveTeamMember) {
              return {
                id: connectionDoc.id,
                ...userDoc.data(),
                connectedAt: connectionDoc.data().connectedAt?.toDate
                  ? connectionDoc.data().connectedAt.toDate()
                  : new Date(connectionDoc.data().connectedAt || 0),
                connectionType,
              };
            }
            return null;
          })
        );

        // Filter out null values and sort
        const filteredConnections = connectionsData
          .filter(Boolean)
          .sort((a, b) => {
            if (a.id === loggedInUserId) return -1;
            if (b.id === loggedInUserId) return 1;
            return b.connectedAt - a.connectedAt;
          });

        setConnections(filteredConnections);
        setTotalConnections(filteredConnections.length);
      } catch (error) {
      } finally {
        setLoading(false);
        setInitialLoadComplete(true);
      }
    };

    fetchConnections();
  }, [
    currentUserId,
    loggedInUserId,
    isOwnProfile,
    startupData,
    isTeamSelection,
    isEditTeam,
  ]);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      // Don't scroll here, let Profile.js handle it
    };
  }, []);

  // In ConnectionsModal.js
  const handleUserClick = (userId) => {
    if (isOrganiserSelection && onConnectionClick) {
      onConnectionClick(userId);
    } else {
      handleNavigate(userId);
    }
  };

  const handleNavigate = async (userId) => {
    if (isOrganiserSelection) {
      onConnectionClick(userId);
    } else if (isTeamSelection) {
      setSelectedUser(userId);
      setShowAddTeamModal(true);
    } else {
      onClose();
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          navigate(`/${userData.urlSlug}`);
        }
      } catch (error) {}
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredConnections = useMemo(() => {
    return connections.filter((connection) => {
      const fullName =
        `${connection.firstName} ${connection.lastName}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    });
  }, [connections, searchTerm]);

  const formatConnectionDate = (connectedAt) => {
    const now = new Date();
    const diffTime = Math.abs(now - connectedAt);
    const diffSeconds = Math.floor(diffTime / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffWeeks = Math.floor(diffDays / 7);
    const diffMonths = Math.floor(diffDays / 30);
    const diffYears = Math.floor(diffDays / 365);

    if (diffSeconds < 60) return "now";
    if (diffMinutes < 60) return `${diffMinutes}m`;
    if (diffHours < 24) return `${diffHours}h`;
    if (diffDays < 7) return `${diffDays}d`;
    if (diffWeeks < 4) return `${diffWeeks}w`;
    if (diffMonths < 12) return `${diffMonths}mo`;
    return `${diffYears}y`;
  };

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            {isEditOrganisers
              ? "Edit Organisers"
              : isOrganiserSelection
              ? "Add Organisers"
              : isEditTeam
              ? "Edit Team"
              : isTeamSelection
              ? "Add Team"
              : `Connections (${totalConnections})`}
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
              // onTouchStart={handleCloseTouch}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>

        <form className={classes.interactions}>
          <div className={classes.connectionsList}>
            {loading ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              </div>
            ) : filteredConnections.length > 0 ? (
              filteredConnections.map((connection) => (
                <div
                  key={connection.id}
                  className={classes.connectionItem}
                  onClick={() => handleNavigate(connection.id)}
                >
                  <img
                    onClick={() => handleNavigate(connection.id)}
                    src={connection.profileImage || defaultImage}
                    alt={`${connection.firstName} ${connection.lastName}`}
                    className={classes.profileImage}
                  />
                  <div className={classes.connectionInfo}>
                    <h3 className={classes.name}>
                      <div className={classes.nameWrapper}>
                        <span
                          className={classes.nameText}
                          onClick={() => handleNavigate(connection.id)}
                        >
                          {connection.firstName} {connection.lastName}
                        </span>
                        <span className={classes.connectionType}>
                          •{" "}
                          {connection.id === loggedInUserId
                            ? "You"
                            : connection.connectionType}
                        </span>
                      </div>
                    </h3>
                    <p
                      className={`${classes.bio} ${
                        !connection.bio && classes.noBio
                      }`}
                    >
                      {connection.bio || connection.username}
                    </p>

                    {!isTeamSelection && !isEditTeam && (
                      <p className={classes.connectionDate}>
                        {formatConnectionDate(connection.connectedAt)}
                      </p>
                    )}
                  </div>
                  {renderOptions(connection)}
                </div>
              ))
            ) : (
              <div className={classes.noNotifications}>
                {/* <div className={classes.bold}>No connections found</div>
                <div className={classes.text}>
                  Try searching for like-minded people.
                </div>
                <Link to="/people" className={classes.homeButton}>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  People
                </Link> */}
              </div>
            )}
          </div>
        </form>
      </div>
      {showAddTeamModal && (
        <AddTeamModal
          onClose={handleAddTeamModalClose}
          userId={selectedUser}
          startupData={startupData}
          currentUser={loggedInUserId}
        />
      )}
      {showInvitePopup && <InvitePopup onClose={handlePopupClose} />}
    </div>
  );
};

export default ConnectionsModal;
