import React, { useState } from "react";
import Nav from "../components/Navbars/Nav";
import MobileNavbar from "../components/Navbars/MobileNavbar";
import classes from "./CommunityPolicies.module.css";
import TermsAndConditions from "../components/Cards/TermsAndConditions";
import userImage from "../assets/icons/profileImage.jpg";

function CommunityPolicies() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);

  return (
    <div className={classes.page}>
      <Nav />
      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.card}>
            <div className={classes.cardTitle}>
              We want Foundry to reflect the best version of the startup
              community
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally4}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Be safe
              </div>
            </div>
            <div className={classes.text}>
              Only bring safe conversations to Foundry.
            </div>

            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Be trustworthy
              </div>
            </div>
            <div className={classes.text}>
              Use your true identity and share information that is real and
              authentic.
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Be professional, yet scrappy
              </div>
            </div>
            <div className={classes.text}>
              We allow broad conversations about the world of startups, but keep
              it professional, yet scrappy.
            </div>
          </div>
          <div className={classes.card}>
            <div className={classes.cardTitle}>Community Policies</div>

            <div className={classes.cardAbout}>
              <div className={classes.text}>
                Thank you for using Foundry, where the world’s startup community
                gets together to find like-minded people, startups, communities
                (forums), and startup-related events. The content that you
                contribute should add to the Foundry community in a constructive
                manner. Additional information on what that means, is laid out
                below. Together we can make our community a place where everyone
                is able to make ideas happen, which, in turn, helps make the
                world a better place.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>

            <div className={classes.cardAbout}>
              <div className={classes.cardHeader}>
                Tell us if you see abusive content
              </div>
              <div className={classes.text}>
                If you see something you believe may violate our policies,
                whether in profiles, posts, messages, comments, or anywhere
                else, please report it to us. Combined with our manual defenses,
                these reports help us identify and prevent abuse. Users can
                report content by clicking on the three dots icon in the upper
                right-hand corner of the content itself on Foundry.
                Alternatively, you can contact us directly at{" "}
                <span className={classes.link}>hello@foundry.bz</span>. Please
                use the reporting tools responsibly and only for their intended
                purposes.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>

            <div className={classes.cardAbout}>
              <div className={classes.cardHeader}>
                Violating our community policies can result in action against
                your account or content
              </div>
              <div className={classes.text}>
                These policies apply to all members. Depending on the severity
                of violation, we may limit the visibility of certain content,
                label it, or remove it entirely. Repeated or egregious offenses
                will result in account restriction. If you believe action taken
                on your content or your account was in error, you can submit an
                appeal by contacting us at{" "}
                <span className={classes.link}>hello@foundry.bz</span>.
              </div>
            </div>
          </div>

          <div className={classes.card}>
            <div className={classes.cardTitle}>Be safe</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally4}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Only bring safe conversations to Foundry.
              </div>
              <div className={classes.text}>
                <span className={classes.bold}>
                  Do not post harassing content:{" "}
                </span>
                We don’t allow bullying or harassment. This includes targeted
                personal attacks, intimidation, shaming, disparagement, and
                abusive language directed at other members. Do not reveal
                others' personal or sensitive information (for example, doxing),
                or incite others to do the same. You may not engage in trolling
                or other repetitive negative content that disrupts other members
                or conversations on the platform.
                <br />
                <br />
                <span className={classes.bold}>
                  Do not threaten, incite, or promote violence:
                </span>{" "}
                We don’t allow threatening or inciting violence of any kind. We
                don’t allow individuals or groups that engage in or promote
                violence, property damage, or organized criminal activity. You
                may not use Foundry to express support for such individuals or
                groups or to otherwise glorify violence.
                <br />
                <br />
                <span className={classes.bold}>
                  Do not share material depicting the exploitation of children:
                </span>{" "}
                We have zero tolerance for content that depicts the sexual
                exploitation of children. Do not share, post, transmit, or
                solicit child exploitation material through or using our
                platform. Do not use Foundry in any way to facilitate,
                encourage, or engage in the abuse or exploitation of children.
                When we become aware of apparent child exploitation, we report
                it to the National Center for Missing and Exploited Children
                (NCMEC). Additionally, while we recognize that there may be
                instances where people share images of non-sexualized child
                nudity with good intentions, we generally remove these images
                due to the potential of misappropriation and abuse by others.
                <br />
                <br />
                <span className={classes.bold}>
                  Do not promote, sell or attempt to purchase illegal or
                  dangerous goods or services:
                </span>{" "}
                We don't allow content that facilitates the purchase of illegal
                or dangerous goods and/or services, prostitution, and escort
                services. We don't allow content that promotes or distributes
                fake educational and/or professional certifications, sale of
                scraped data, proxy test-taking, or instructions on creating
                forged official documents. You may not use Foundry to hold
                lotteries, contests, sweepstakes, or giveaways. Do not use
                Foundry to sensationalize or capitalize on tragic events for
                commercial purposes.
                <br />
                <br />
                <span className={classes.bold}>
                  Do not share content promoting dangerous organizations or
                  individuals:
                </span>{" "}
                We don't allow any terrorist organizations or violent extremist
                groups on our platform. And we don't allow any individuals who
                affiliate with such organizations or groups to have a Foundry
                profile. Content that depicts terrorist activity, that is
                intended to recruit for terrorist organizations, or that
                threatens, promotes, or supports terrorism in any manner is not
                tolerated.
              </div>
            </div>
          </div>
          <div className={classes.card}>
            <div className={classes.cardTitle}>Be trustworthy</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally4}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We require you to use your true identity on Foundry, provide
                accurate information about yourself, your startups, communities,
                or events, and only share information that is real and
                authentic.
              </div>
              <div className={classes.text}>
                <span className={classes.bold}>
                  Do not share false or misleading content:
                </span>{" "}
                Do not share content that is false, misleading, or intended to
                deceive. Do not share content to interfere with or improperly
                influence an election or other civic process. Do not share
                synthetic or manipulated media that depicts a person saying
                something they did not say or doing something they did not do
                without clearly disclosing the fake or altered nature of the
                material. Do not share content that directly contradicts
                guidance from leading global health organizations and public
                health authorities; including false information about the safety
                or efficacy of vaccines or medical treatments. Do not share
                content or endorse someone or something in exchange for personal
                benefit (including personal or family relationships, monetary
                payment, free products or services, or other value), unless you
                have included a clear and conspicuous notice of the personal
                benefit you receive.
                <br />
                <br />
                <span className={classes.bold}>
                  Do not create a fake profile or falsify information about
                  yourself:
                </span>{" "}
                We don’t allow fake profiles or entities. Do not post misleading
                or deceptive information about yourself, your business, your
                qualifications, work experience, affiliations, or achievements.
                Do not use an image of someone else, or any other image that is
                not your likeness, for your profile photo. Do not associate
                yourself on Foundry with a business or organization that you are
                not actually professionally associated with. Do not use or
                attempt to use another person's Foundry account or create a
                member profile for anyone other than yourself. And do not share
                your Foundry account with anyone else.
                <br />
                <br />
                <span className={classes.bold}>
                  Do not scam, defraud, deceive others:
                </span>{" "}
                Do not use Foundry to facilitate romance scams, promote pyramid
                schemes, or otherwise defraud members. Do not share malicious
                software that puts our members, platform, or services at risk.
                Phishing attempts are not tolerated.
              </div>
            </div>
          </div>
          <div className={classes.card}>
            <div className={classes.cardTitle}>
              Be professional, yet scrappy
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally4}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Foundry's mission is to connect the world's startup community.
                We allow broad conversations about the world of startups, but
                require professional, yet scrappy expression. To maintain a
                professional, yet scrappy platform, members should treat each
                other with respect and civility. Do not interact with others or
                share content in a way that's uncivil, inappropriate, or
                disrespectful. But take into consideration that content should
                be scrappy, real, and unpolished to emphasize the startup
                community's unique identity.
              </div>
              <div className={classes.text}>
                <span className={classes.bold}>Do not be hateful: </span> We
                don't allow content that attacks, denigrates, intimidates,
                dehumanizes, incites or threatens hatred, violence, prejudicial
                or discriminatory action against individuals or groups because
                of their actual or perceived race, ethnicity, national origin,
                caste, gender, gender identity, sexual orientation, religious
                affiliation, age, or disability status. Hate groups are not
                permitted on Foundry. Do not use racial, religious, or other
                slurs that incite or promote hatred, or any other content
                intended to create division. Do not post or share content that
                denies a well-documented historical event such as the Holocaust
                or slavery in the United States.
                <br />
                <br />
                <span className={classes.bold}>
                  Do not engage in sexual innuendos or unwanted advances:
                </span>{" "}
                We don't allow unwanted expressions of attraction, desire,
                requests for romantic relationship, marriage proposals, sexual
                advances or innuendo, or lewd remarks. Foundry is a startup
                networking platform, not a dating site. Do not use Foundry to
                pursue romantic connections, ask for romantic dates, or provide
                sexual commentary on someone's appearance or perceived
                attractiveness. Do not send unwanted advances in messages,
                posts, or comments or send sexually explicit images to anyone on
                the platform.
                <br />
                <br />
                <span className={classes.bold}>
                  Do not share harmful or shocking material:
                </span>{" "}
                We don't allow content that is excessively gruesome or shocking.
                This includes content that is sadistic or gratuitously graphic,
                such as the depiction of bodily injury, severe physical or
                sexual violence. We don't allow content, activities, or events
                that promote, organize, depict, or facilitate criminal activity.
                We also don't allow content depicting or promoting instructional
                weapon making, drug abuse, and threats of theft. Do not engage
                in or promote escort services, prostitution, exploitation of
                children, or human trafficking. Do not share content or
                activities that promote or encourage suicide or any type of
                self-injury, including self-mutilation and eating disorders. If
                you see signs that someone may be considering self-harm, please
                report it using our reporting tools and consider also reporting
                it to your local law enforcement authorities. You can also
                report it to us directly at{" "}
                <span className={classes.link}>hello@foundry.bz</span>. Do not
                share material depicting nudity or sexual activity.
                <br />
                <br />
                <span className={classes.bold}>
                  Do not spam members or the platform:
                </span>{" "}
                We don't allow untargeted, irrelevant, obviously unwanted,
                unauthorized, in appropriately commercial or promotional, or
                gratuitously repetitive messages or similar content. Do not use
                our Chat/Message feature to send promotional messages to people
                you don't know or to otherwise spam people. Please make the
                effort to create original, professional, relevant, and
                interesting content in order to gain engagement. Don't do things
                to artificially increase engagement with your content. Respond
                authentically to others’ content and don’t agree with others
                ahead of time to boost each other’s content.
              </div>
            </div>
          </div>
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default CommunityPolicies;
