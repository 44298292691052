import React, { useState } from "react";
import Nav from "../../../components/Navbars/Nav";

import classes from "./PrivacyPolicy.module.css";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";

function PrivacyPolicy() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);

  return (
    <div className={classes.page}>
      <Nav />
      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.card}>
            <div className={classes.cardTitle}>Privacy Policy</div>
            <div className={classes.cardAbout}>
              <div className={classes.skillContainer}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Effective January 18, 2025
              </div>
              <div className={classes.text}>
                Foundry's mission is to connect the world’s startup community.
                Central to this mission is our commitment to be transparent
                about the data we collect about you, how it is used and with
                whom it is shared.
                <br />
                <br />
                This Privacy Policy applies when you use our Services (described
                below). We offer our users choices about the data we collect,
                use and share as described in this Privacy Policy.
              </div>
            </div>
          </div>
          <div className={classes.card}>
            <div className={classes.cardTitle}>Introduction</div>

            <div className={classes.cardAbout}>
              <div className={classes.matchTally4}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We are a social network and online platform for the startup
                community. People use our Services to find and be found for
                startup opportunities, to connect with others and find
                information. Our Privacy Policy applies to any Member or Visitor
                to our Services.
              </div>
              <div className={classes.text}>
                Our registered users (“Members”) share their identities, engage
                with their network, exchange knowledge and startup insights,
                post and view relevant content, and find startup opportunities.
                Content and data on some of our Services is viewable to
                non-Members (“Visitors”).
              </div>
            </div>

            <div className={classes.cardHeader}>Services</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                This Privacy Policy applies to your use of our Services.
              </div>
              <div className={classes.text}>
                This Privacy Policy applies to Foundry.bz, Foundry-branded apps,
                and other Foundry-branded sites, apps, communications and
                services offered by Foundry (“Services”).
              </div>
            </div>

            <div className={classes.cardHeader}>
              Data Controllers and Contracting Parties
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.text}>
                Foundry Labs Ltd. (CN: 15530153) will be the controller of (or
                business responsible for) your personal data provided to, or
                collected by or for, or processed in connection with our
                Services.
                <br />
                <br />
                As a Visitor or Member of our Services, the collection, use and
                sharing of your personal data is subject to this Privacy Policy
                and other documents referenced in this Privacy Policy, as well
                as updates.
              </div>
            </div>

            <div className={classes.cardAbout}>
              <div className={classes.cardHeader}>Change</div>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Changes to the Privacy Policy apply to your use of our Services
                after the “effective date.”
              </div>
            </div>

            <div className={classes.text}>
              Foundry (“we” or “us”) can modify this Privacy Policy, and if we
              make material changes to it, we will provide notice through our
              Services, or by other means, to provide you the opportunity to
              review the changes before they become effective. If you object to
              any changes, you may deactivate your account.
              <br />
              <br />
              You acknowledge that your continued use of our Services after we
              publish or send a notice about our changes to this Privacy Policy
              means that the collection, use and sharing of your personal data
              is subject to the updated Privacy Policy, as of its effective
              date.
            </div>
          </div>

          <div className={classes.card}>
            <div className={classes.cardTitle}>1. Data We Collect</div>
            <div className={classes.cardHeader}>1.1 Data You Provide To Us</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                You provide data to create an account with us.
              </div>
            </div>

            <div className={classes.cardHeader}>Registration</div>
            <div className={classes.cardAbout}>
              <div className={classes.text}>
                To create an account you need to provide data including your
                name, age, email address, general location (e.g., city), and a
                password.
                {/* If you register for a premium Service, you will need
                to provide payment (e.g., credit card) and billing information. */}
              </div>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                You create your Foundry profile (a complete profile helps you
                get the most from our Services).
              </div>
            </div>

            <div className={classes.cardHeader}>Profile</div>
            <div className={classes.cardAbout}>
              <div className={classes.text}>
                You have choices about the information on your profile, such as
                your education, experience, skills, interests, photo, country,
                city, startups, communities, and events. You don’t have to
                provide additional information on your profile; however, profile
                information helps you to get more from our Services, including
                helping startup opportunities find you. It’s your choice whether
                to include sensitive information on your profile and to make
                that sensitive information public. Please do not post or add
                personal data to your profile that you would not want to be
                publicly available.
              </div>
            </div>

            <div className={classes.cardHeader}>Posting and Uploading</div>
            <div className={classes.cardAbout}>
              <div className={classes.text}>
                We collect personal data from you when you provide, post or
                upload it to our Services.
                <br />
                <br />
                You don’t have to post or upload personal data; though if you
                don’t, it may limit your ability to grow and engage with your
                network over our Services.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>1.2 Data From Others</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Others may post or write about you.
              </div>
            </div>
            <div className={classes.cardHeader}>Content</div>
            <div className={classes.cardAbout}>
              <div className={classes.text}>
                You and others may post content that includes information about
                you (as part of posts, comments, videos) on our Services.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>1.3 Service Use</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We log your visits and use of our Services.
              </div>
              <div className={classes.text}>
                We log usage data when you visit or otherwise use our Services.
                We use log-ins, cookies, device information and internet
                protocol (“IP”) addresses to identify you and log your use.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>1.4 Communications</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                If you communicate through our Services, we learn about that.
              </div>
              <div className={classes.text}>
                We collect information about you when you communicate with
                others through our Services (e.g., when you send, receive, or
                engage with messages, communities, events, or connection
                requests). This may include information that indicates who you
                are communicating with and when. We also manually support and
                protect our site. For example, we look for possible responses to
                messages and to manage or block content that violates our{" "}
                <a href="/user-agreement" className={classes.link}>
                  User Agreement
                </a>
                .
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>1.5 Other</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We are improving our Services, which means we get new data and
                create new ways to use data.
              </div>
              <div className={classes.text}>
                Our Services are dynamic, and we often introduce new features,
                which may require the collection of new information. If we
                collect materially different personal data or materially change
                how we collect, use or share your data, we will notify you and
                may also modify this Privacy Policy.
              </div>
            </div>
          </div>

          <div className={classes.card}>
            <div className={classes.cardTitle}>2. How We Use Your Data</div>

            <div className={classes.cardAbout}>
              <div className={classes.matchTally4}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We use your data to provide, support, personalize and develop
                our Services.
              </div>

              <div className={classes.text}>
                We may use your personal data to improve, develop, and provide
                products and Services.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardAbout}>
              <div className={classes.cardHeader}>2.1 Services</div>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Our Services help you connect with others, find and be found for
                startup opportunities, stay informed, and be more productive.
              </div>
              <div className={classes.text}>
                We use your data to authorize access to our Services.
              </div>
            </div>

            <div className={classes.cardAbout}>
              <div className={classes.cardHeader}>Stay Connected</div>
              <div className={classes.text}>
                Our Services allow you to stay in touch and up to date with
                contacts. To do so, you can “connect” with others who you
                choose, and who also wish to “connect” with you.
                <br />
                <br />
                We use data about you to help others find your profile, suggest
                connections for you and others and enable you to invite others
                to become a Member and connect with you. You opt-in to allow us
                to use your general location to others for certain tasks (e.g.
                to suggest other nearby Members).
                <br />
                <br />
                It is your choice whether to invite someone to our Services,
                send a connection request, or allow another Member to become
                your connection. When you invite someone to connect with you,
                your invitation will include your network and profile
                information (e.g., name, profile photo, region).
              </div>

              <div className={classes.cardHeader}>Stay Informed</div>
              <div className={classes.text}>
                Our Services allow you to stay informed about news, events and
                ideas regarding startup-related topics you care about, and from
                people you respect. We use the data we have about you (e.g.,
                data you provide, data we collect from your engagement with our
                Services and inferences we make from the data we have about
                you), to personalize our Services for you, such as by
                recommending or ranking relevant content and conversations on
                our Services. We also use your content, activity and other data,
                including your name and photo, to provide notices to your
                network and others. For example, we may notify others that you
                have updated your profile, posted content, used a feature, made
                new connections, etc.
              </div>
              <div className={classes.cardHeader}>Career</div>
              <div className={classes.text}>
                Our Services allow you to explore, seek out, and be found for,
                startup opportunities. Your profile can be found by those
                looking to hire (for a job or a specific task) or be hired by
                you. We will use your data to recommend startups and show you
                and others relevant startup contacts (e.g., who work at a
                startup, or location or have certain skills and connections).
                Keeping your profile accurate and up-to-date may help you better
                connect to others and to opportunities through our Services.
              </div>
              <div className={classes.cardHeader}>Productivity</div>
              <div className={classes.text}>
                Our Services allow you to collaborate with others and search for
                others to do business with. Our Services allow you to Share,
                Boost, and Comment on posts, Filter, Search, and Connect with
                people, Create, Join, or Support startups, Create, Join, or
                Moderator communities, Create, Join, or Host events, Create,
                Edit, and Share your profile.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>2.2 Communications</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We contact you and enable communications between Members.
              </div>

              <div className={classes.text}>
                We will contact you through email, notices posted on our
                websites or apps, messages to your Foundry inbox, and other ways
                through our Services, including push notifications. We will send
                you messages about the availability of our Services, security,
                or other service-related issues. We also send messages about how
                to use our Services, network updates, reminders, suggestions and
                promotional messages from us and our partners. You may change
                your communication preferences at any time by reaching out to us
                at <span className={classes.link}>hello@foundry.bz</span>.
                Please be aware that you cannot opt out of receiving service
                messages from us, including security and legal notices.
                <br />
                <br />
                We also enable communications between you and others through our
                Services, including for example invitations, communities and
                messages between connections.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>2.3 Advertising</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We serve you tailored ads both on and off our Services. You
                cannot opt-out of seeing ads.
              </div>

              <div className={classes.text}>
                We target (and measure the performance of) ads to Members,
                Visitors and others both on and off our Services directly or
                through a variety of partners, using the following data, whether
                separately or combined:
                <br />
                <br />
                <span className={classes.bold}> 1.</span> Data collected by
                advertising technologies on and off our Services;
                <br />
                <br />
                <span className={classes.bold}> 2.</span> Member-provided
                information;
                <br />
                <br />
                <span className={classes.bold}> 3.</span> Data from your use of
                our Services (e.g., search history, feed, content you read,
                who/what you follow or is following you, connections, community
                participation, page visits, videos you watch, clicking on an ad,
                etc.), including as described in Section 1.3;
                <br />
                <br />
                <span className={classes.bold}> 4.</span> Information from
                advertising partners, vendors and publishers; and
                <br />
                <br />
                <span className={classes.bold}> 5.</span> Information inferred
                from data described above (e.g., using taglines from a profile
                to infer industry, seniority, and compensation bracket; using
                graduation dates to infer age or using first names to infer
                gender; using your feed activity to infer your interests; or
                using device data to recognize you as a Member).
                <br />
                <br />
                We will show you ads called sponsored content which look similar
                to non-sponsored content, except that they are labeled as
                advertising (e.g., as “ad” or “sponsored”). If you take a social
                action (such as boost, comment or share) on these ads, your
                action is associated with your name and viewable by others,
                including the advertiser. For example, when you like a company
                we may include your name and photo when their sponsored content
                is shown.
              </div>
            </div>
            <div className={classes.cardHeader}>Info to Ad Providers</div>
            <div className={classes.cardAbout}>
              <div className={classes.text}>
                We do not share your personal data with any third-party
                advertisers or ad networks except for: (i) hashed IDs or device
                identifiers (to the extent they are personal data in some
                countries); (ii) with your separate permission (e.g., in a lead
                generation form) or (iii) data already visible to any users of
                the Services (e.g., profile). However, if you view or click on
                an ad on or off our Services, the ad provider will get a signal
                that someone visited the page that displayed the ad, and they
                may, through the use of mechanisms such as cookies, determine it
                is you. Advertising partners can associate personal data
                collected by the advertiser directly from you with hashed IDs or
                device identifiers received from us. We seek to contractually
                require such advertising partners to obtain your explicit,
                opt-in consent before doing so where legally required, and in
                such instances, we take steps to ensure that consent has been
                provided before processing data from them.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>2.4 Marketing</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We promote our Services to you and others.
              </div>
              <div className={classes.text}>
                In addition to advertising our Services, we use Members’ data
                and content for invitations and communications promoting
                membership and network growth, engagement and our Services, such
                as by showing your connections that you have used a feature on
                our Services.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>
              2.5 Developing Services and Research
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We develop our Services and conduct research
              </div>
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.cardHeader}>Service Development</div>
              <div className={classes.text}>
                We use data, including public feedback, to conduct research and
                development for our Services in order to provide you and others
                with a better, more intuitive and personalized experience, drive
                membership growth and engagement on our Services, and help
                connect the startup community to each other and to economic
                opportunity.
              </div>
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.cardHeader}>Other Research</div>
              <div className={classes.text}>
                We seek to create economic opportunity for Members of the
                world's startup community (e.g., founders, collaborators,
                investors, advisors, mentors) and to help them be more
                productive and successful. We use the personal data available to
                us to research social, economic and startup trends. In some
                cases, we work with trusted third parties to perform this
                research, under controls that are designed to protect your
                privacy. We may also make public data available to researchers
                to enable assessment of the safety and legal compliance of our
                Services. We publish or allow others to publish economic
                insights, presented as aggregated data rather than personal
                data.
              </div>
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.cardHeader}>Surveys</div>
              <div className={classes.text}>
                Polls and surveys are conducted by us and others through our
                Services. You are not obligated to respond to polls or surveys,
                and you have choices about the information you provide. You may
                opt-out of survey invitations.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>2.6 Customer Support</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We use data to help you and fix problems.
              </div>

              <div className={classes.text}>
                We use data (which can include your communications) to
                investigate, respond to and resolve complaints and for Service
                issues (e.g., bugs).
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>
              2.7 Insights That Do Not Identify You
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We use data to generate insights that do not identify you.
              </div>

              <div className={classes.text}>
                We use your data to perform analytics to produce and share
                insights that do not identify you. For example, we may use your
                data to generate statistics about our Members, their profession
                or industry, to calculate ad impressions served or clicked on,
                to show Members' information about engagement with a post or
                Startup, Community, or Event Pages, to publish visitor
                demographics for a Service or create demographic startup
                insights, or to understand usage of our services.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>
              2.8 Security and Investigations
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We use data for security, fraud prevention and investigations.
              </div>

              <div className={classes.text}>
                We and our Affiliates may use your data (including your
                communications) for security purposes or to prevent or
                investigate possible fraud or other violations of the law, our{" "}
                <span className={classes.link}>User Agreement</span> and/or
                attempts to harm our Members, Visitors, company, Affiliates, or
                others.
              </div>
            </div>
          </div>

          {/* 3. */}
          <div className={classes.card}>
            <div className={classes.cardTitle}>3. How We Share Information</div>

            <div className={classes.cardAbout}>
              <div className={classes.cardHeader2}>3.1 Our Services</div>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Any data that you include on your profile and any content you
                post or social action (e.g., boosts, follows, comments) you take
                on our Services will be seen by others.
              </div>
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.cardHeader}>Profile</div>
              <div className={classes.text}>
                Your profile is fully visible to all Members and customers of
                our Services.
              </div>
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.cardHeader}>
                Posts, Boosts, Follows, Comments, Messages
              </div>
              <div className={classes.text}>
                Our Services allow viewing and sharing information including
                through posts, boosts, follows and comments.
                <br />
                <br />
                <span className={classes.bold}> 1.</span> When you (or a startup
                you own) boosts, comments, or shares a post (e.g., an update,
                image, video or file) publicly it can be viewed by everyone and
                re-shared anywhere. Members, Visitors and others will be able to
                find and see your publicly-shared content, including your name
                (and photo if you have provided one).
                <br />
                <br />
                <span className={classes.bold}> 2.</span> In a community, posts
                are visible to other members of that community, according to
                whether they joined a public community, or were accepted by the
                community owner on a private community. For example, posts in
                communities are only visible to others in the community. Your
                membership in communities is public and part of your profile.
                <br />
                <br />
                <span className={classes.bold}> 3.</span> Any information you
                share through startup, community, or event pages on our Services
                will be viewable by anyone who views those pages' content.
                <br />
                <br />
                <span className={classes.bold}> 4.</span> When you connect with
                others, create, follow, support, or join the team of a startup,
                create, become a member or moderator of a community, and/or
                create, attend or host an event, you are visible to others and
                that “page owner” as an owner, connection, follower, team
                member, supporter, member, moderator, attendee, or host.
                <br />
                <br />
                <span className={classes.bold}> 5.</span> We let senders know
                when you act on their message.
                <br />
                <br />
                <span className={classes.bold}> 6.</span> When you boost or
                comment on another’s content (including ads), others will be
                able to view these “social actions” and associate it with you
                (e.g., your name, profile and photo if you provided it).
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>3.2 Service Providers</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We may use others to help us with our Services.
              </div>
              <div className={classes.text}>
                We use others to help us provide our Services (e.g.,
                maintenance, analysis, audit, payments, fraud detection,
                customer support, marketing and development). They will have
                access to your information (e.g., the contents of a customer
                support request) as reasonably necessary to perform these tasks
                on our behalf and are obligated not to disclose or use it for
                other purposes. If you purchase a Service from us, we may use a
                payments service provider who may separately collect information
                about you (e.g., for fraud prevention or to comply with legal
                obligations).
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>3.3 Legal Disclosures</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We may need to share your data when we believe it’s required by
                law or to help protect the rights and safety of you, us or
                others.
              </div>
              <div className={classes.text}>
                It is possible that we will need to disclose information about
                you when required by law, subpoena, or other legal process or if
                we have a good faith belief that disclosure is reasonably
                necessary to (1) investigate, prevent or take action regarding
                suspected or actual illegal activities or to assist government
                enforcement agencies; (2) enforce our agreements with you; (3)
                investigate and defend ourselves against any third-party claims
                or allegations; (4) protect the security or integrity of our
                Services or the products or services of our Affiliates (such as
                by sharing with companies facing similar threats); or (5)
                exercise or protect the rights and safety of Foundry, our
                Members, personnel or others. We attempt to notify Members about
                legal demands for their personal data when appropriate in our
                judgment, unless prohibited by law or court order or when the
                request is an emergency. We may dispute such demands when we
                believe, in our discretion, that the requests are overbroad,
                vague or lack proper authority, but we do not promise to
                challenge every demand.
              </div>
            </div>
          </div>
          {/* 4 */}
          <div className={classes.card}>
            <div className={classes.cardTitle}>
              4. Your Choices & Obligations
            </div>

            <div className={classes.cardAbout}>
              <div className={classes.cardHeader2}>4.1 Data Retention</div>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We keep most of your personal data for as long as your account
                is open.
              </div>
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.text}>
                We generally retain your personal data as long as you keep your
                account open or as needed to provide you Services. This includes
                data you or others provided to us and data generated or inferred
                from your use of our Services. Even if you only use our Services
                every few years, we will retain your information and keep your
                profile open, unless you deactivate your account. In some cases
                we choose to retain certain information (e.g., insights about
                Services use) in a depersonalized or aggregated form.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>
              4.2 Rights to Access and Control Your Personal Data
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                You can access or delete your personal data. You have many
                choices about how your data is collected, used and shared.
              </div>
              <div className={classes.text}>
                We provide many choices about the collection, use and sharing of
                your data, from deleting or correcting data you include in your
                profile and controlling the visibility of your posts to
                advertising opt-outs and communication controls. We offer you
                the ability to reach out to us at{" "}
                <span className={classes.link}>hello@foundry.bz</span> to
                control and manage the personal data we have about you.
                <br />
                <br />
                For personal data that we have about you, you can:
                <br />
                <br />
                <span className={classes.bold}> 1. Delete Data:</span> You can
                ask us to erase or delete all or some of your personal data
                (e.g., if it is no longer necessary to provide Services to you).
                <br />
                <br />
                <span className={classes.bold}>
                  2. Change or Correct Data:
                </span>{" "}
                You can edit some of your personal data through your account.
                You can also ask us to change, update or fix your data in
                certain cases, particularly if it’s inaccurate.
                <br />
                <br />
                <span className={classes.bold}>
                  3. Object to, or Limit or Restrict, Use of Data:
                </span>{" "}
                You can ask us to stop using all or some of your personal data
                (e.g., if we have no legal right to keep using it) or to limit
                our use of it (e.g., if your personal data is inaccurate or
                unlawfully held).
                <br />
                <br />
                <span className={classes.bold}>
                  4. Right to Access and/or Take Your Data:
                </span>{" "}
                You can ask us for a copy of your personal data and can ask for
                a copy of personal data you provided in machine readable form.
                <br />
                <br />
                You may contact us at{" "}
                <span className={classes.link}>hello@foundry.bz</span>, and we
                will consider your request in accordance with applicable laws.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>4.3 Account Closure</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We keep some of your data even after you deactivate your
                account.
              </div>
              <div className={classes.text}>
                If you choose to deactivate your Foundry account, your personal
                data will generally stop being visible to others on our Services
                instantly. We generally delete closed account information
                instantly, except as noted below.
                <br />
                <br />
                We attempt to retain your personal data (including chat history)
                if reasonably necessary to comply with our legal obligations
                (including law enforcement requests), meet regulatory
                requirements, resolve disputes, maintain security, prevent fraud
                and abuse (e.g., if we have restricted your account for breach
                of our{" "}
                <a href="community-policies" className={classes.link}>
                  Community Policies
                </a>
                ), enforce our{" "}
                <span className={classes.link}>User Agreement</span>, or fulfill
                your request to "unsubscribe" from further messages from us. We
                will retain de-personalized information after your account has
                been closed.
                <br />
                <br />
                Information you have shared with others (e.g., through Chats,
                updates or posts) will remain visible after you close your
                account or delete the information from your own profile or Chat
                history, and we do not control data that other Members have
                copied out of our Services. Content associated with deactivated
                accounts will show an unknown user as the source. Your profile
                may continue to be displayed in the services of others (e.g.,
                search tools) until they refresh their cache.
              </div>
            </div>
          </div>
          {/* 5. */}
          <div className={classes.card}>
            <div className={classes.cardTitle}>
              5. Other Important Information
            </div>

            <div className={classes.cardAbout}>
              <div className={classes.cardHeader2}>5.1. Security</div>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We monitor for and try to prevent security breaches. Please use
                the security features available through our Services.
              </div>
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.text}>
                We implement security safeguards designed to protect your data,
                such as HTTPS, and two-factor authentication. We regularly
                monitor our systems for possible vulnerabilities and attacks.
                However, we cannot warrant the security of any information that
                you send us. There is no guarantee that data may not be
                accessed, disclosed, altered, or destroyed by breach of any of
                our physical, technical, or managerial safeguards.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>
              5.2 Cross-Border Data Transfers
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We store and use your data outside your country.
              </div>
              <div className={classes.text}>
                We process data both inside and outside of the United Kingdom
                and rely on legally-provided mechanisms to lawfully transfer
                data across borders. Countries where we process data may have
                laws which are different from, and potentially not as protective
                as, the laws of your own country.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>
              5.3 Lawful Bases for Processing
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                We have lawful bases to collect, use and share data about you.
                You have choices about our use of your data. At any time, you
                can withdraw consent you have provided by going to settings and
                deactivating your account, or reaching out to us at
                hello@foundry.bz.
              </div>
              <div className={classes.text}>
                We will only collect and process personal data about you where
                we have lawful bases. Lawful bases include consent (where you
                have given consent), contract (where processing is necessary for
                the performance of a contract with you (e.g., to deliver the
                Foundry Services you have requested) and “legitimate interests.”
                <br />
                <br />
                Where we rely on your consent to process personal data, you have
                the right to withdraw or decline your consent at any time and
                where we rely on legitimate interests, you have the right to
                object. If you have any questions about the lawful bases upon
                which we collect and use your personal data, please contact us
                at <span className={classes.link}>hello@foundry.bz</span>
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>
              5.4 Direct Marketing and Do Not Track Signals
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Our statements regarding direct marketing.
              </div>
              <div className={classes.text}>
                We currently do not share personal data with third parties for
                their direct marketing purposes without your permission.
              </div>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.cardHeader}>5.5 Contact Information</div>
            <div className={classes.cardAbout}>
              <div className={classes.matchTally}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd"
                  />
                </svg>
                You can contact us or use other options to resolve any
                complaints.
              </div>
              <div className={classes.text}>
                If you have questions or complaints regarding this Policy,
                please reach us by email at{" "}
                <span className={classes.link}>hello@foundry.bz</span>. If
                contacting us does not resolve your complaint, you have more
                options. If this does not resolve your complaint, you may have
                more options under your country's laws.
              </div>
            </div>
            <div className={classes.cardAbout}>
              <div className={classes.cardHeader}>Consent</div>

              <div className={classes.text}>
                Where we process data based on consent, we will ask for your
                explicit consent. You may withdraw your consent at any time, but
                that will not affect the lawfulness of the processing of your
                personal data prior to such withdrawal. Where we rely on
                contract, we will ask that you agree to the processing of
                personal data that is necessary for entering into or performance
                of your contract with us. We will rely on legitimate interests
                as a basis for data processing where the processing of your data
                is not overridden by your interests or fundamental rights and
                freedoms.
              </div>
            </div>
          </div>
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
