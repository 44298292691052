import React, { useState, useEffect, useRef } from "react";
import { firestore } from "../../firebase";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  getDoc,
  addDoc,
  writeBatch,
  query,
  where,
} from "firebase/firestore";
import classes from "./AddTeamModal.module.css";

const AddTeamModal = ({ onClose, userId, startupData, currentUser }) => {
  const [role, setRole] = useState("");
  const [isCofounder, setIsCofounder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const formRef = useRef(null);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleRoleChange = (e) => {
    if (e.target.value.length <= 50) {
      setRole(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const batch = writeBatch(firestore);

      // First, clean up existing notifications for both the invitee and startup owner
      const cleanupPromises = [
        // Clean up invitee's notifications
        (async () => {
          const inviteeNotificationsRef = collection(
            firestore,
            `users/${userId}/notifications`
          );
          const inviteeNotificationsQuery = query(
            inviteeNotificationsRef,
            where("type", "in", ["team_invite", "team_invite_response"]),
            where("startupId", "==", startupData.id)
          );
          const inviteeSnapshot = await getDocs(inviteeNotificationsQuery);
          inviteeSnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });
        })(),

        // Clean up owner's notifications
        (async () => {
          const ownerNotificationsRef = collection(
            firestore,
            `users/${startupData.ownerId}/notifications`
          );
          const ownerNotificationsQuery = query(
            ownerNotificationsRef,
            where("type", "in", [
              "team_invite_accepted",
              "team_invite_declined",
            ]),
            where("startupId", "==", startupData.id),
            where("from", "==", userId)
          );
          const ownerSnapshot = await getDocs(ownerNotificationsQuery);
          ownerSnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });
        })(),
      ];

      // Wait for all cleanup operations to complete
      await Promise.all(cleanupPromises);

      // Delete any existing team member document if it exists
      const teamMemberRef = doc(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/team/${userId}`
      );
      const teamMemberDoc = await getDoc(teamMemberRef);
      if (teamMemberDoc.exists()) {
        batch.delete(teamMemberRef);
      }

      // Create new notification
      const notificationRef = doc(
        collection(firestore, `users/${userId}/notifications`)
      );
      const notificationData = {
        type: "team_invite",
        from: currentUser,
        startupId: startupData.id,
        startupName: startupData.startupName,
        startupOwnerId: startupData.ownerId,
        startupUrlSlug: startupData.startupUrlSlug,
        startupImage: startupData.startupImage,
        role: role.trim(),
        isCofounder,
        createdAt: new Date(),
        isNew: true,
      };
      batch.set(notificationRef, notificationData);

      // Create new team member document
      const teamMemberData = {
        role: role.trim(),
        isCofounder,
        status: "pending",
        createdAt: new Date(),
      };
      batch.set(teamMemberRef, teamMemberData);

      await batch.commit();

      // Tell parent component to show success popup and close this modal
      onClose("success");
    } catch (error) {
      setError("Failed to add team member. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.modalBackdrop} onClick={handleBackdropClick}>
      <div className={classes.modalContent} onClick={handleModalClick}>
        <div className={classes.cardHeader}>
          <div className={classes.goBackHeader}>
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 12h14M5 12l4-4m-4 4 4 4"
              />
            </svg>
            <span className={classes.goBack}>Go back</span>
          </div>
        </div>

        <form
          className={classes.interactions}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          {loading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : (
            <>
              {/* Here we need to show the image, name, connectionType, bio, and country of that user, so we know what user we're adding...*/}
              <label htmlFor="role" className={classes.label}>
                Role*
              </label>
              <input
                id="role"
                value={role}
                onChange={handleRoleChange}
                placeholder="Ex: Co-founder, CTO"
                className={classes.userInput}
              />
              <div className={classes.charCount}>{role.length}/50</div>
              {/* <label className={`${classes.label} ${classes.checkboxLabel}`}>
                <input
                  type="checkbox"
                  checked={isCofounder}
                  onChange={(e) => setIsCofounder(e.target.checked)}
                  className={classes.checkbox}
                />
                Is this a co-founder?
              </label> */}
              {/* Here we need to ask when they started working at the startup (month, year dropdowns, which will determine where they go in the hierarchy of order) */}
              {/* We also need to know if we're giving this user rights to edit the page, so they too become an owner...? */}
            </>
          )}
        </form>

        {error && <p className={classes.error}>{error}</p>}

        <div className={classes.cardFooter}>
          <button
            type="button"
            className={classes.button}
            disabled={loading || !role.trim()}
            onClick={() => {
              if (formRef.current) {
                formRef.current.dispatchEvent(
                  new Event("submit", { bubbles: true, cancelable: true })
                );
              }
            }}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTeamModal;
