import React from "react";
import classes from "./ActivityButtons.module.css";

const ActivityButtons = ({
  selectedActivity,
  onSelectionChange,
  activityCounts,
  showAllButtons = false,
}) => {
  const activities = [
    {
      name: "Posts",
      count: activityCounts.Posts,
      icon: (
        <svg
          className="w-6 h-6 text-gray-800 dark:text-white mr-2"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11.5c.07 0 .14-.007.207-.021.095.014.193.021.293.021h2a2 2 0 0 0 2-2V7a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2v11h-2V5a2 2 0 0 0-2-2H5Zm7 4a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm-6 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM7 6a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7Zm1 3V8h1v1H8Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Boosts",
      count: activityCounts.Boosts,
      icon: (
        <svg
          class="w-6 h-6 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            fill-rule="evenodd"
            d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z"
            clip-rule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Comments",
      count: activityCounts.Comments,
      icon: (
        <svg
          className="w-6 h-6 text-gray-800 dark:text-white mr-2"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-6.616l-2.88 2.592C8.537 20.461 7 19.776 7 18.477V17H5a2 2 0 0 1-2-2V6Zm4 2a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2H7Zm8 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Zm-8 3a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H7Zm5 0a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    // {
    //   name: "Reposts",
    //   count: activityCounts.Reposts,
    //   icon: (
    //     <svg
    //       class="w-6 h-6 text-gray-800 dark:text-white"
    //       aria-hidden="true"
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="16"
    //       height="16"
    //       fill="currentColor"
    //       viewBox="0 0 24 24"
    //     >
    //       <path
    //         fill-rule="evenodd"
    //         d="M14.516 6.743c-.41-.368-.443-1-.077-1.41a.99.99 0 0 1 1.405-.078l5.487 4.948.007.006A2.047 2.047 0 0 1 22 11.721a2.06 2.06 0 0 1-.662 1.51l-5.584 5.09a.99.99 0 0 1-1.404-.07 1.003 1.003 0 0 1 .068-1.412l5.578-5.082a.05.05 0 0 0 .015-.036.051.051 0 0 0-.015-.036l-5.48-4.942Zm-6.543 9.199v-.42a4.168 4.168 0 0 0-2.715 2.415c-.154.382-.44.695-.806.88a1.683 1.683 0 0 1-2.167-.571 1.705 1.705 0 0 1-.279-1.092V15.88c0-3.77 2.526-7.039 5.967-7.573V7.57a1.957 1.957 0 0 1 .993-1.838 1.931 1.931 0 0 1 2.153.184l5.08 4.248a.646.646 0 0 1 .012.011l.011.01a2.098 2.098 0 0 1 .703 1.57 2.108 2.108 0 0 1-.726 1.59l-5.08 4.25a1.933 1.933 0 0 1-2.929-.614 1.957 1.957 0 0 1-.217-1.04Z"
    //         clip-rule="evenodd"
    //       />
    //     </svg>
    //   ),
    // },
  ];

  const buttonsToShow = showAllButtons
    ? activities
    : activities.filter((activity) => activity.count > 0);

  // If no buttons would be shown, show the first one anyway
  const finalButtonsToShow =
    buttonsToShow.length > 0 ? buttonsToShow : [activities[0]];

  return (
    <div className={classes.activityButtons}>
      {finalButtonsToShow.map((activity) => (
        <button
          key={activity.name}
          className={`${classes.activityButton} ${
            selectedActivity === activity.name ? classes.activeButton : ""
          }`}
          onClick={() => onSelectionChange(activity.name)}
        >
          {activity.icon}
          <span>{activity.name}</span>
        </button>
      ))}
    </div>
  );
};

export default ActivityButtons;
