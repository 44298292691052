import { collection, getDocs } from "firebase/firestore";

export async function filterBlockedContent(
  firestore,
  currentUserId,
  postsToFilter
) {
  if (!postsToFilter?.length || !currentUserId) return postsToFilter;

  const ourBlockedSnapshot = await getDocs(
    collection(firestore, `users/${currentUserId}/blockedUsers`)
  );
  const ourBlockedIds = new Set(
    ourBlockedSnapshot.docs.map((doc) => doc.data().userId)
  );

  const uniqueOwnerIds = new Set();
  postsToFilter.forEach((post) => {
    const ownerId = post.user.isStartup
      ? post.user.startupOwnerId
      : post.user.id;
    uniqueOwnerIds.add(ownerId);
  });

  const blockedByMap = new Map();
  await Promise.all(
    Array.from(uniqueOwnerIds).map(async (ownerId) => {
      const theirBlockedSnapshot = await getDocs(
        collection(firestore, `users/${ownerId}/blockedUsers`)
      );
      const areWeBlocked = theirBlockedSnapshot.docs.some(
        (doc) => doc.data().userId === currentUserId
      );
      if (areWeBlocked) blockedByMap.set(ownerId, true);
    })
  );

  return postsToFilter.filter((post) => {
    const ownerId = post.user.isStartup
      ? post.user.startupOwnerId
      : post.user.id;
    return !ourBlockedIds.has(ownerId) && !blockedByMap.has(ownerId);
  });
}
