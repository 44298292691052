import React, { useState, useRef, useEffect } from "react";
import classes from "./TagsDropdown.module.css";

const TagsDropdown = ({
  label,
  selectedTags,
  onTagsChange,
  onInputChange,
  disabled,
  placeholder,
  customClassName,
}) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    setInputValue(selectedTags.map((tag) => tag.name).join(", "));
  }, [selectedTags]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onInputChange(newValue);
  };

  // In TagsDropdown.js
  const handleInputBlur = () => {
    // Convert input to tags array, even if empty
    const values = inputValue
      ? inputValue
          .split(",")
          .map((s) => s.trim())
          .filter((s) => s)
          .map((name) => ({ name, id: name }))
      : [];

    // Always call onTagsChange, even with empty array
    onTagsChange(values);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [inputValue]);

  return (
    <div className={classes.customDropdown}>
      <label className={classes.label}>{label}</label>
      <div className={classes.dropdownContainer}>
        <textarea
          ref={inputRef}
          className={`${classes.dropdownHeader} ${classes.textArea} ${
            disabled ? classes.disabled : ""
          } ${customClassName}`}
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default TagsDropdown;
