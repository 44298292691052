import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import { initializeAnalytics } from "./analytics";
import NotificationHandler from "./components/NotificationHandler";
import CommunityPolicies from "./Community Policies/CommunityPolicies";
import PrivacyPolicy from "./pages/Main/PrivacyPolicy/PrivacyPolicy";
import AgeVerification from "./pages/Onboarding/SignUp/AgeVerification";
import Splash from "./pages/Main/Splash/Splash";
import UserAgreement from "./pages/UserAgreement/UserAgreement";
import Event from "./pages/Main/Events/Event";
import Feed from "./pages/Main/Feed/Feed";
import Events from "./pages/Main/Events/Events";
import Home from "./pages/Home/Home";
import Careers from "./pages/Careers/Careers";
import SignUp from "./pages/Onboarding/SignUp/SignUp";
import InitialProfileSetup from "./pages/Onboarding/SignUp/InitialProfileSetup";
import InitialLocationSetup from "./pages/Onboarding/SignUp/InitialLocationSetup";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import PrivateRoute from "./routes/PrivateRoute";
import Profile from "./pages/Main/Profile/Profile";
import { firestore } from "./firebase";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import "./App.css";
import StartupsAndCommunities from "./pages/StartupsAndCommunities/StartupsAndCommunities";
import LogIn from "./pages/Onboarding/LogIn/LogIn";
import ForgotPassword from "./pages/Onboarding/LogIn/ForgotPassword";

import Notifications from "./pages/Main/Notifications/Notifications";
import Chats from "./pages/Main/Chats/Chats";
import People from "./pages/Main/People/People";
import Settings from "./pages/Main/Settings/Settings";
import UserProfile from "./pages/Main/Profile/UserProfile";
import { NotificationProvider } from "./contexts/NotificationContext";
import WaitlistCount from "./WaitlistCount";
import SinglePost from "./pages/Posts/SinglePost";

import AllPosts from "./pages/Posts/AllPosts";

import { UploadProvider } from "./contexts/UploadContext";
import SingleReaction from "./pages/Reactions/SingleReaction";
import AllReactions from "./pages/Reactions/AllReactions";
import SingleComment from "./pages/Comments/SingleComment";
import AllComments from "./pages/Comments/AllComments";
import Startup from "./pages/Main/Startup/Startup";
import Community from "./pages/Main/Communities/Community";
import { FCMProvider } from "./contexts/FCMContext";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {}

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const useUserProfile = (uid) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (uid) {
      const fetchProfile = async () => {
        try {
          const userDocRef = doc(firestore, "users", uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            setProfile(userDocSnap.data());
          } else {
            setProfile(null);
          }
        } catch (error) {
        } finally {
          setLoading(false);
        }
      };
      fetchProfile();
    } else {
      setLoading(false);
    }
  }, [uid]);

  return { profile, loading };
};

const ProfileRouter = () => {
  const { currentUser } = useAuth();
  const { profile, loading } = useUserProfile(currentUser?.uid);
  const location = useLocation();
  const urlSlug = location.pathname.substring(1);

  if (loading) return null;

  if (!currentUser) {
    return <Navigate to="/signup" />;
  }

  if (
    !profile ||
    !profile.firstName ||
    !profile.lastName ||
    !profile.country ||
    !profile.city
  ) {
    return <Navigate to="/onboarding" />;
  }

  // Remove NotificationWrapper here too
  return profile.urlSlug === urlSlug ? <Profile /> : <UserProfile />;
};

const RedirectBasedOnProfile = () => {
  const { currentUser } = useAuth();
  const { profile, loading } = useUserProfile(currentUser?.uid);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (profile) {
      if (!profile.firstName || !profile.lastName) {
        setCurrentStep(1);
      } else if (!profile.birthDate) {
        setCurrentStep(2);
      } else if (!profile.country || !profile.city) {
        setCurrentStep(3);
      }
    }
  }, [profile]);

  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  if (loading) return null;

  // Check for authentication
  if (!currentUser) {
    return <Navigate to="/signup" />;
  }

  // Check for email verification
  if (!currentUser.emailVerified) {
    return <Navigate to="/signup" />;
  }

  // If profile is complete, redirect to profile page
  if (
    profile &&
    profile.firstName &&
    profile.lastName &&
    profile.birthDate &&
    profile.country &&
    profile.city
  ) {
    return <Navigate to={`/${profile.urlSlug}`} />;
  }

  // If profile exists and email is verified, show appropriate onboarding step
  switch (currentStep) {
    case 1:
      return <InitialProfileSetup onNext={handleNextStep} />;
    case 2:
      return <AgeVerification onNext={handleNextStep} />;
    case 3:
      return <InitialLocationSetup />;
    default:
      return <InitialProfileSetup onNext={handleNextStep} />;
  }
};

// const NotificationWrapper = ({ children }) => (
//   <NotificationProvider>{children}</NotificationProvider>
// );

// Modify the communityPost route in App.js to include the community URL slug
const CommunityPostRedirect = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const findCommunityForPost = async () => {
      try {
        // Find the community that contains this post
        const usersRef = collection(firestore, "users");
        const querySnapshot = await getDocs(usersRef);

        for (const userDoc of querySnapshot.docs) {
          const communitiesRef = collection(
            firestore,
            `users/${userDoc.id}/communities`
          );
          const communitiesSnapshot = await getDocs(communitiesRef);

          for (const communityDoc of communitiesSnapshot.docs) {
            const postsRef = collection(
              firestore,
              `users/${userDoc.id}/communities/${communityDoc.id}/posts`
            );
            const postDoc = await getDoc(doc(postsRef, postId));

            if (postDoc.exists()) {
              const communityData = communityDoc.data();
              // Navigate to the community page with the post ID in state
              navigate(`/community/${communityData.communityUrlSlug}`, {
                state: { highlightPostId: postId },
              });
              return;
            }
          }
        }
        // If post not found, navigate to home
        navigate("/home");
      } catch (error) {
        navigate("/home");
      } finally {
        setLoading(false);
      }
    };

    findCommunityForPost();
  }, [postId, navigate]);

  return null;
};

function App() {
  const [showSplash, setShowSplash] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    initializeAnalytics();
  }, []);

  // useEffect(() => {
  //   const checkMobile = () => {
  //     setIsMobile(window.matchMedia("(max-width: 990px)").matches);
  //   };

  //   checkMobile();
  //   window.addEventListener("resize", checkMobile);

  //   return () => window.removeEventListener("resize", checkMobile);
  // }, []);

  // useEffect(() => {
  //   if (location.pathname === "/" && isMobile) {
  //     setShowSplash(true);
  //   } else {
  //     setShowSplash(false);
  //   }
  // }, [location, isMobile]);

  return (
    // <AuthProvider>
    <UploadProvider>
      <NotificationProvider>
        <FCMProvider>
          <NotificationHandler />
          <div className="App">
            <ScrollToTop />
            <Routes>
              <Route path="/secret" element={<Home />} />
              <Route path="/" element={<Splash />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<LogIn />} />
              <Route path="/reset-password" element={<ForgotPassword />} />
              <Route path="/user-agreement" element={<UserAgreement />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/community-policies"
                element={<CommunityPolicies />}
              />

              <Route path="/onboarding" element={<PrivateRoute />}>
                <Route index element={<RedirectBasedOnProfile />} />
              </Route>

              <Route path="/home" element={<PrivateRoute />}>
                <Route
                  path="/home"
                  element={
                    <ErrorBoundary>
                      <Feed />
                    </ErrorBoundary>
                  }
                />
              </Route>
              <Route path="/notifications" element={<PrivateRoute />}>
                <Route path="/notifications" element={<Notifications />} />
              </Route>
              <Route path="/chats" element={<PrivateRoute />}>
                <Route path="/chats" element={<Chats />} />
              </Route>
              <Route path="/people" element={<PrivateRoute />}>
                <Route path="/people" element={<People />} />
              </Route>
              <Route path="/events" element={<PrivateRoute />}>
                <Route path="/events" element={<Events />} />
              </Route>
              <Route path="/startupsandcommunities" element={<PrivateRoute />}>
                <Route
                  path="/startupsandcommunities"
                  element={<StartupsAndCommunities />}
                />
              </Route>

              <Route path="/settings" element={<PrivateRoute />}>
                <Route path="/settings" element={<Settings />} />
              </Route>
              <Route path="/post/:postId" element={<PrivateRoute />}>
                <Route path="/post/:postId" element={<SinglePost />} />
              </Route>
              <Route path="/communityPost/:postId" element={<PrivateRoute />}>
                <Route
                  path="/communityPost/:postId"
                  element={<CommunityPostRedirect />}
                />
              </Route>
              <Route path="/all-posts/:urlSlug" element={<PrivateRoute />}>
                <Route path="/all-posts/:urlSlug" element={<AllPosts />} />
              </Route>
              <Route path="/all-posts/:type/:id" element={<PrivateRoute />}>
                <Route path="/all-posts/:type/:id" element={<AllPosts />} />
              </Route>

              <Route path="/reaction/:reactionId" element={<PrivateRoute />}>
                <Route
                  path="/reaction/:reactionId"
                  element={<SingleReaction />}
                />
              </Route>
              <Route path="/all-reactions/:urlSlug" element={<PrivateRoute />}>
                <Route
                  path="/all-reactions/:urlSlug"
                  element={<AllReactions />}
                />
              </Route>
              <Route path="/all-reactions/:type/:id" element={<PrivateRoute />}>
                <Route
                  path="/all-reactions/:type/:id"
                  element={<AllReactions />}
                />
              </Route>
              <Route path="/comment/:postId" element={<PrivateRoute />}>
                <Route path="/comment/:postId" element={<SingleComment />} />
              </Route>
              <Route path="/all-comments/:urlSlug" element={<PrivateRoute />}>
                <Route
                  path="/all-comments/:urlSlug"
                  element={<AllComments />}
                />
              </Route>
              <Route path="/all-comments/:type/:id" element={<PrivateRoute />}>
                <Route
                  path="/all-comments/:type/:id"
                  element={<AllComments />}
                />
              </Route>
              <Route path="/waitlist1000" element={<WaitlistCount />}>
                <Route path="/waitlist1000" element={<WaitlistCount />} />
              </Route>
              <Route path="/startup/:startupUrlSlug" element={<PrivateRoute />}>
                <Route path="/startup/:startupUrlSlug" element={<Startup />} />
              </Route>
              <Route
                path="/community/:communityUrlSlug"
                element={<PrivateRoute />}
              >
                <Route
                  path="/community/:communityUrlSlug"
                  element={<Community />}
                />
              </Route>
              <Route path="/event/:eventUrlSlug" element={<PrivateRoute />}>
                <Route path="/event/:eventUrlSlug" element={<Event />} />
              </Route>
              <Route path="/:urlSlug" element={<PrivateRoute />}>
                <Route path="/:urlSlug" element={<ProfileRouter />} />
              </Route>
            </Routes>
          </div>
        </FCMProvider>
      </NotificationProvider>
    </UploadProvider>
    // </AuthProvider>
  );
}

export default App;
