import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  getDoc,
  writeBatch,
  query,
  where,
} from "firebase/firestore";
import monthsList from "../../utils/monthsList";
import { firestore } from "../../firebase";
import classes from "./EditStartupsModal.module.css";
import defaultImage from "../../assets/images/startupImage.jpg";
import { getStorage, ref, deleteObject } from "firebase/storage";
import EditSingleStartupModal from "./EditSingleStartupModal";

const EditStartupsModal = ({ onClose, currentUser, onStartupUpdated }) => {
  const [startups, setStartups] = useState([]);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingStartup, setEditingStartup] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredStartups = startups.filter((startup) => {
    return startup.startupName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  useEffect(() => {
    // Replace this section in EditStartupsModal.js useEffect
    const fetchStartups = async () => {
      setLoading(true);
      try {
        // Only fetch owned startups
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const startupsCollectionRef = collection(userDocRef, "startups");
        const startupsSnapshot = await getDocs(startupsCollectionRef);
        const ownedStartups = startupsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          membershipType: "owner",
        }));

        // Create a new array and sort it exactly like in Profile.js
        const startupsList = [...ownedStartups].sort((a, b) => {
          // Convert month strings to numbers for comparison
          const aMonth = monthsList.indexOf(a.monthFounded);
          const bMonth = monthsList.indexOf(b.monthFounded);

          // Create dates for comparison
          const aDate = new Date(a.yearFounded, aMonth);
          const bDate = new Date(b.yearFounded, bMonth);

          // Compare dates first
          if (aDate.getTime() !== bDate.getTime()) {
            return bDate.getTime() - aDate.getTime(); // Newest first
          }

          // If dates are the same, compare creation timestamps
          return (
            b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime()
          );
        });

        setStartups(startupsList);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchStartups();
  }, [currentUser.uid]);

  const handleLeaveStartup = async (startup) => {
    try {
      const batch = writeBatch(firestore);

      const teamMemberRef = doc(
        firestore,
        `users/${startup.ownerId}/startups/${startup.id}/team/${currentUser.uid}`
      );
      batch.delete(teamMemberRef);

      const memberNotificationsRef = collection(
        firestore,
        `users/${currentUser.uid}/notifications`
      );
      const memberNotifQuery = query(
        memberNotificationsRef,
        where("type", "in", ["team_invite", "team_invite_response"]),
        where("startupId", "==", startup.id)
      );
      const memberNotifSnapshot = await getDocs(memberNotifQuery);
      memberNotifSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      const ownerNotificationsRef = collection(
        firestore,
        `users/${startup.ownerId}/notifications`
      );
      const ownerNotifQuery = query(
        ownerNotificationsRef,
        where("type", "in", ["team_invite_accepted", "team_invite_declined"]),
        where("from", "==", currentUser.uid),
        where("startupId", "==", startup.id)
      );
      const ownerNotifSnapshot = await getDocs(ownerNotifQuery);
      ownerNotifSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();

      const updatedStartups = startups.filter(
        (s) => !(s.id === startup.id && s.membershipType === "member")
      );
      setStartups(updatedStartups);
      onStartupUpdated(updatedStartups);
      setOpenDropdown(null);

      if (updatedStartups.length === 0) {
        onClose();
      }
    } catch (error) {}
  };

  const toggleDropdown = (startupId) => {
    setOpenDropdown(openDropdown === startupId ? null : startupId);
  };

  const handleEditClick = (startup) => {
    setEditingStartup(startup);
    setOpenDropdown(null);
  };

  const handleEditClose = () => {
    setEditingStartup(null);
  };

  // In EditStartupsModal.js, modify handleStartupUpdate:
  const handleStartupUpdate = (updatedStartup) => {
    // Find the existing startup to get its createdAt
    const existingStartup = startups.find((s) => s.id === updatedStartup.id);

    // Create updated startup with preserved fields
    const fullUpdatedStartup = {
      ...updatedStartup,
      membershipType: existingStartup.membershipType,
      createdAt: existingStartup.createdAt, // Preserve the createdAt timestamp
    };

    // Update local state
    const updatedStartups = startups.map((startup) =>
      startup.id === updatedStartup.id ? fullUpdatedStartup : startup
    );
    setStartups(updatedStartups);

    // Pass the full updated startup to Profile.js
    onStartupUpdated(fullUpdatedStartup);
  };

  const handleDeleteStartup = async (startupId) => {
    try {
      const startup = startups.find((s) => s.id === startupId);
      if (!startup) return;

      const userDocRef = doc(firestore, "users", currentUser.uid);
      const startupDocRef = doc(userDocRef, "startups", startupId);

      // 1. Get all subcollections
      const communityPostsRef = collection(startupDocRef, "communityPosts");
      const postsRef = collection(startupDocRef, "posts");
      const reactedPostsRef = collection(startupDocRef, "reactedPosts");
      const commentedPostsRef = collection(startupDocRef, "commentedPosts");
      const followersRef = collection(startupDocRef, "followers");
      const donationsRef = collection(startupDocRef, "donations");
      const teamRef = collection(startupDocRef, "team");

      // Get all documents from each subcollection
      const [
        communityPostsSnapshot,
        postsSnapshot,
        reactedPostsSnapshot,
        commentedPostsSnapshot,
        followersSnapshot,
        donationsSnapshot,
        teamSnapshot,
      ] = await Promise.all([
        getDocs(communityPostsRef),
        getDocs(postsRef),
        getDocs(reactedPostsRef),
        getDocs(commentedPostsRef),
        getDocs(followersRef),
        getDocs(donationsRef),
        getDocs(teamRef),
      ]);

      // 2. Handle community posts cleanup
      const communityPostsCleanupPromises = communityPostsSnapshot.docs.map(
        async (postDoc) => {
          const postData = postDoc.data();
          if (postData.communityId) {
            // Delete the post from the community's posts collection
            const communityRef = doc(
              firestore,
              "communities",
              postData.communityId
            );
            const communityPostRef = doc(
              collection(communityRef, "posts"),
              postDoc.id
            );
            try {
              // Get and delete comments first
              const commentsRef = collection(communityPostRef, "comments");
              const commentsSnapshot = await getDocs(commentsRef);
              await Promise.all(
                commentsSnapshot.docs.map((commentDoc) =>
                  deleteDoc(commentDoc.ref)
                )
              );
              // Then delete the post
              await deleteDoc(communityPostRef);
            } catch (error) {}
          }
          await deleteDoc(postDoc.ref);
        }
      );

      // 3. Handle regular posts cleanup
      const postsCleanupPromises = postsSnapshot.docs.map(async (postDoc) => {
        // Delete comments first
        const commentsRef = collection(postDoc.ref, "comments");
        const commentsSnapshot = await getDocs(commentsRef);
        await Promise.all(
          commentsSnapshot.docs.map((commentDoc) => deleteDoc(commentDoc.ref))
        );
        // Then delete the post
        await deleteDoc(postDoc.ref);
      });

      // 4. Handle followers cleanup
      const followersCleanupPromises = followersSnapshot.docs.map(
        async (followerDoc) => {
          const followerId = followerDoc.id;
          const followerRef = doc(firestore, "users", followerId);

          // Delete from startupsFollowing
          try {
            const followingRef = doc(
              collection(followerRef, "startupsFollowing"),
              startupId
            );
            await deleteDoc(followingRef);
          } catch (error) {}

          await deleteDoc(followerDoc.ref);
        }
      );

      // 5. Handle team cleanup
      const teamCleanupPromises = teamSnapshot.docs.map(async (teamDoc) => {
        const teamMemberId = teamDoc.id;
        const teamMemberRef = doc(firestore, "users", teamMemberId);

        // Delete from team member's team collection
        try {
          const memberTeamRef = doc(
            collection(teamMemberRef, "team"),
            startupId
          );
          await deleteDoc(memberTeamRef);
        } catch (error) {}

        await deleteDoc(teamDoc.ref);
      });

      // 6. Handle donations cleanup
      const donationsCleanupPromises = donationsSnapshot.docs.map(
        async (donationDoc) => {
          const donorId = donationDoc.data().userId;
          if (donorId) {
            const donorRef = doc(firestore, "users", donorId);
            try {
              const donationRef = doc(
                collection(donorRef, "donations"),
                donationDoc.id
              );
              await deleteDoc(donationRef);
            } catch (error) {}
          }
          await deleteDoc(donationDoc.ref);
        }
      );

      // 7. Delete notifications for all involved users
      const allUserIds = new Set([
        currentUser.uid,
        ...followersSnapshot.docs.map((doc) => doc.id),
        ...teamSnapshot.docs.map((doc) => doc.id),
        ...donationsSnapshot.docs
          .map((doc) => doc.data().userId)
          .filter(Boolean),
      ]);

      const notificationsCleanupPromises = Array.from(allUserIds).map(
        async (userId) => {
          const userNotificationsRef = collection(
            doc(firestore, "users", userId),
            "notifications"
          );
          const notificationsQuery = query(
            userNotificationsRef,
            where("startupId", "==", startupId)
          );

          try {
            const notificationsSnapshot = await getDocs(notificationsQuery);
            await Promise.all(
              notificationsSnapshot.docs.map((doc) => deleteDoc(doc.ref))
            );
          } catch (error) {}
        }
      );

      // 8. Delete the startup image if it exists
      if (
        startup.startupImage &&
        !startup.startupImage.includes("startupImage.jpg")
      ) {
        try {
          const storage = getStorage();
          const imageUrl = new URL(startup.startupImage);
          const imagePath = decodeURIComponent(
            imageUrl.pathname.split("/o/")[1].split("?")[0]
          );
          const imageRef = ref(storage, imagePath);
          await deleteObject(imageRef);
        } catch (error) {}
      }

      // 9. Execute all deletions in parallel
      await Promise.all([
        ...communityPostsCleanupPromises,
        ...postsCleanupPromises,
        ...reactedPostsSnapshot.docs.map((doc) => deleteDoc(doc.ref)),
        ...commentedPostsSnapshot.docs.map((doc) => deleteDoc(doc.ref)),
        ...followersCleanupPromises,
        ...teamCleanupPromises,
        ...donationsCleanupPromises,
        ...notificationsCleanupPromises,
      ]);

      // 10. Finally delete the startup document itself
      await deleteDoc(startupDocRef);

      // 11. Update local state
      const updatedStartups = startups.filter((s) => s.id !== startupId);
      setStartups(updatedStartups);

      onStartupUpdated({
        id: startupId,
        deleted: true,
      });

      setOpenDropdown(null);

      if (updatedStartups.length === 0) {
        onClose();
      }
    } catch (error) {}
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose();
    }
    setMouseDownOnBackdrop(false);
  };

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  if (editingStartup) {
    return (
      <EditSingleStartupModal
        startup={editingStartup}
        onClose={handleEditClose}
        onUpdate={handleStartupUpdate}
        currentUser={currentUser}
      />
    );
  }

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Startups ({startups.length})
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className={classes.startupsCardAbout}>
          {loading ? (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          ) : startups.length === 0 ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No startups found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          ) : (
            filteredStartups.map((startup) => (
              <div key={startup.id} className={classes.startupContainer}>
                <div className={classes.startupContent}>
                  <div className={classes.startupImageContainer}>
                    {startup.startupImage ? (
                      <img
                        src={startup.startupImage}
                        alt={startup.startupName}
                        className={classes.startupImage}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                    ) : (
                      <div className={classes.defaultImage}>
                        <img
                          src={defaultImage}
                          alt="Default startup"
                          className={classes.startupImage}
                        />
                      </div>
                    )}
                  </div>
                  <div className={classes.startupInfo}>
                    <h3 className={classes.startupName}>
                      <div className={classes.nameWrapper}>
                        <span className={classes.nameText}>
                          {startup.startupName}
                        </span>
                        <span className={classes.connectionType}>
                          • Startup
                        </span>
                      </div>
                    </h3>

                    {startup.bio && (
                      <p className={classes.bio}>{startup.bio}</p>
                    )}
                    {startup.link && (
                      <a
                        href={startup.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.userLink}
                      >
                        <span>{startup.linkText || startup.link}</span>
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </a>
                    )}
                  </div>

                  <div className={classes.buttonContainer}>
                    <div className={classes.iconContainer}>
                      {" "}
                      {/* Remove the onClick handler here */}
                      <svg
                        className={`${classes.optionsIcon} ${
                          openDropdown === startup.id
                            ? classes.optionsIconActive
                            : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={() => toggleDropdown(startup.id)} // Simplify this to just the toggle
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                      {openDropdown === startup.id && (
                        <div className={classes.dropdown}>
                          {startup.membershipType === "owner" ? (
                            <>
                              <div
                                className={`${classes.dropdownItem} ${classes.dropdownItemFirst}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditClick(startup);
                                }}
                              >
                                <svg
                                  class="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                                  />
                                </svg>

                                <span>Edit</span>
                              </div>
                              <div
                                className={`${classes.dropdownItem} ${classes.dropdownItemLast}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteStartup(startup.id);
                                }}
                              >
                                <svg
                                  class="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                                  />
                                </svg>
                                <span>Delete</span>
                              </div>
                            </>
                          ) : (
                            <div
                              className={`${classes.dropdownItem} ${classes.dropdownItemSingle}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleLeaveStartup(startup);
                              }}
                            >
                              <svg
                                className="w-6 h-6 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="gray"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm-2 9a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1Zm13-6a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              Leave startup
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default EditStartupsModal;
