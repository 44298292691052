import { useState } from "react";
import classes from "./TermsAndConditions.module.css";

const TermsAndConditions = ({}) => {
  const [shareButtonText, setShareButtonText] = useState("Share");

  const handleShare = () => {
    navigator.clipboard
      .writeText("foundry.bz")
      .then(() => {
        setShareButtonText("Copied");
        setTimeout(() => {
          setShareButtonText("Share");
        }, 5000);
      })
      .catch((err) => {});
  };

  return (
    <div className={classes.card}>
      <div className={classes.alphaContent}>
        <div className={classes.message}>Foundry is in Beta</div>
        <div className={classes.emailMessage}>
          Please reach out to us at{" "}
          <span className={classes.email}>hello@foundry.bz</span> if you have
          any feedback.
        </div>
        <div className={classes.betaContent}>
          <div className={classes.message}>Spread the word</div>
          <div className={classes.emailMessage}>
            Let's connect the startup community!{" "}
            <div className={classes.shareButtons}>
              <div
                className={`${
                  shareButtonText === "Copied"
                    ? classes.following
                    : classes.button
                }`}
                onClick={handleShare}
              >
                {shareButtonText === "Copied" ? (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                  </svg>
                )}
                {shareButtonText}
              </div>

              <a
                href="https://www.producthunt.com/posts/foundry-4?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-foundry&#0045;4"
                target="_blank"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=816084&theme=neutral&t=1737751965186"
                  alt="Foundry - Connecting&#0032;the&#0032;world&#0039;s&#0032;startup&#0032;community&#0044;&#0032;all&#0045;in&#0045;one&#0032;place&#0046; | Product Hunt"
                  className={classes.productHunt}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.cardContent}>
        <a href="/">About</a>
        <span className={classes.dot}>•</span>
        <a href="/user-agreement">User Agreement</a>
        <span className={classes.dot}>•</span>
        <a href="privacy-policy">Privacy Policy</a>
        <span className={classes.dot}>•</span>
        <a href="/community-policies">More</a>
        <span className={classes.dot}>•</span>
        Foundry Labs Ltd. © 2025
      </div>
    </div>
  );
};

export default TermsAndConditions;
