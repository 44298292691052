import React, { useState } from "react";
import { firestore } from "./firebase";
import { collection, getDocs } from "firebase/firestore";

function WaitlistExport() {
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState(null);

  const downloadCSV = async () => {
    setDownloading(true);
    setError(null);

    try {
      // Get all documents from the waitlist collection
      const querySnapshot = await getDocs(collection(firestore, "waitlist"));

      // Extract email addresses and convert to CSV format
      const emails = querySnapshot.docs.map((doc) => doc.data().email);
      const csvContent = "email\n" + emails.join("\n");

      // Create blob and download link
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "waitlist.csv");

      // Trigger download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setDownloading(false);
    } catch (e) {
      setError("Failed to export waitlist data: " + e.message);
      setDownloading(false);
    }
  };

  return (
    <div className="p-4">
      <button
        onClick={downloadCSV}
        disabled={downloading}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-400"
      >
        {downloading ? "Downloading..." : "Export Waitlist to CSV"}
      </button>
      {error && <div className="text-red-500 mt-2">{error}</div>}
    </div>
  );
}

export default WaitlistExport;
