import React, { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import defaultImage from "../../../assets/icons/profileImage.jpg";
import eventImage from "../../../assets/icons/eventImage.jpg";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./Event.module.css";
import MutualAttendeesModal from "../../../components/Modals/MutualAttendeesModal";
import greyPostImage from "../../../assets/icons/greyPostImage.jpg";
import greyProfileImage from "../../../assets/icons/greyProfileImage.jpg";
import { firestore } from "../../../firebase";
import EditOrganisersModal from "../../../components/Modals/EditOrganisersModal";
import { getTopThreeEmojis } from "../../../utils/emojiUtils";
import ConnectionsModal from "../../../components/Modals/ConnectionsModal";
import EditSingleEventModal from "../../../components/Modals/EditSingleEventModal";
import TagsModal from "../../../components/Modals/TagsModal";
import IntroModal from "../../../components/Modals/IntroModal";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import ActivityButtons from "../../../components/Buttons/ActivityButtons";
import AddOrganisersModal from "../../../components/Modals/AddOrganisersModal";
import AddAttendeeModal from "../../../components/Modals/AddAttendeeModal";
import {
  formatEventTime,
  getUserTimezone,
  formatEventTimeRange,
  convertTime,
} from "../../../utils/timeUtils";

import {
  doc,
  getDoc,
  collection,
  writeBatch,
  getDocs,
  query,
  updateDoc,
  where,
  orderBy,
  increment,
  limit,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../firebase";
import EditAttendeesModal from "../../../components/Modals/EditAttendeesModal";

const Event = () => {
  const [isBlocked, setIsBlocked] = useState(false);
  const [hasUserBlockedThem, setHasUserBlockedThem] = useState(false);
  const [loadingStates, setLoadingStates] = useState({
    eventData: true,
    hosts: true,
    attendees: true,
    connections: true,
    attendanceStatus: true,
    activities: true,
    eventStatus: true, // Add this new loading state
  });
  const [connectionsCount, setConnectionsCount] = useState(0);
  const [mutualAttendeesCount, setMutualAttendeesCount] = useState(0);
  const [showMutualAttendeesModal, setShowMutualAttendeesModal] =
    useState(false);
  const [
    mutualAttendeesModalScrollPosition,
    setMutualAttendeesModalScrollPosition,
  ] = useState(0);
  const [isMutualAttendeesModalOpen, setIsMutualAttendeesModalOpen] =
    useState(false);

  const isLoading = Object.values(loadingStates).some(
    (state) => state === true
  );
  const [showDropdown, setShowDropdown] = useState(false);

  const [isEventClosed, setIsEventClosed] = useState(false);

  const [userTimezone, setUserTimezone] = useState(getUserTimezone());
  const [
    editOrganiserModalScrollPosition,
    setEditOrganiserModalScrollPosition,
  ] = useState(0);
  const [isEditOrganiserModalOpen, setIsEditOrganiserModalOpen] =
    useState(false);
  const optionsButtonRef = useRef(null);
  const dropdownRef = useRef(null);
  const [addAttendeeModalScrollPosition, setAddAttendeeModalScrollPosition] =
    useState(0);
  const [isAddAttendeeModalOpen, setIsAddAttendeeModalOpen] = useState(false);
  const [shareButtonText, setShareButtonText] = useState("Share");
  const [hasAvailableConnections, setHasAvailableConnections] = useState(false);
  const [showEditAttendeeModal, setShowEditAttendeeModal] = useState(false);
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [introModalScrollPosition, setIntroModalScrollPosition] = useState(0);
  const [isIntroModalOpen, setIsIntroModalOpen] = useState(false);
  const { eventUrlSlug } = useParams();
  const navigate = useNavigate();
  const [editAttendeeModalScrollPosition, setEditAttendeeModalScrollPosition] =
    useState(0);
  const [isEditAttendeeModalOpen, setIsEditAttendeeModalOpen] = useState(false);
  const [showAllKeywords, setShowAllKeywords] = useState(false);
  const [eventPosts, setEventPosts] = useState([]);
  const [eventReactions, setEventReactions] = useState([]);
  const [eventMessages, setEventMessages] = useState([]);
  const [eventReposts, setEventReposts] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [eventData, setEventData] = useState({
    eventName: "",
    bio: "",
    city: "",
    country: "",
    link: "",
    linkText: "",
    eventImage: "",
    intro: "",
    introHtml: "",
    tags: [],
    isOnline: false,
  });

  const formatEventDate = (dateStr) => {
    if (!dateStr) return "";
    const [day, month, year] = dateStr.split("/");

    // Convert month number to month name
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = months[parseInt(month) - 1];

    // Keep the day as is (with leading zero if present)
    return `${monthName} ${day}, ${year}`;
  };

  const [showAddAttendeeModal, setShowAddAttendeeModal] = useState(false);
  const [currentModalType, setCurrentModalType] = useState(null);
  const [tagsModalScrollPosition, setTagsModalScrollPosition] = useState(0);
  const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);
  const [
    editSingleEventModalScrollPosition,
    setEditSingleEventModalScrollPosition,
  ] = useState(0);
  const [isEditSingleEventModalOpen, setIsEditSingleEventModalOpen] =
    useState(false);
  const [addOrganiserModalScrollPosition, setAddOrganiserModalScrollPosition] =
    useState(0);
  const [isAddOrganiserModalOpen, setIsAddOrganiserModalOpen] = useState(false);

  const [
    hasAvailableAttendeesConnections,
    setHasAvailableAttendeesConnections,
  ] = useState(false);
  const [reportMessage, setReportMessage] = useState(null);
  const [inviteCount, setInviteCount] = useState(1);
  const [removeCount, setRemoveCount] = useState(1);
  const [showAddOrganiserModal, setShowAddOrganiserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [pendingInvites, setPendingInvites] = useState(new Set());
  const [attendees, setAttendees] = useState([]);
  const [showAllAttendees, setShowAllAttendees] = useState(false);
  const [displayedAttendees, setDisplayedAttendees] = useState(3);
  const [hosts, setHosts] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);
  const [isOwner, setIsOwner] = useState(false);
  const [eventNotFound, setEventNotFound] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [showEditSingleEventModal, setShowEditSingleEventModal] =
    useState(false);
  const [showEditOrganiserModal, setShowEditOrganiserModal] = useState(false);
  const [visibleHosts, setVisibleHosts] = useState(3);
  const [visibleAttendees, setVisibleAttendees] = useState(3);
  const [visibleTags, setVisibleTags] = useState(9);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showAllTags, setShowAllTags] = useState(false);
  const [showFullIntro, setShowFullIntro] = useState(false);
  const [displayedHosts, setDisplayedHosts] = useState(3);
  const [attendeesCount, setAttendeesCount] = useState(0);
  const [attendeeStatus, setAttendeeStatus] = useState("Attend");
  const [activityType, setActivityType] = useState("Posts");
  const [activityCounts, setActivityCounts] = useState({
    posts: 0,
    reactions: 0,
    reposts: 0,
    messages: 0,
  });

  // Modal states

  useEffect(() => {}, [isEventClosed]);

  const [showInvitePopup, setShowInvitePopup] = useState(false);

  const calculateMutualAttendees = async () => {
    if (!currentUser?.uid || !eventData.id || !eventData.ownerId) return;

    try {
      const currentUserConnectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const currentUserConnectionsSnapshot = await getDocs(
        query(currentUserConnectionsRef, where("status", "==", "Connected"))
      );

      const attendeesRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/attendees`
      );
      const attendeesSnapshot = await getDocs(attendeesRef);

      // Filter out dummyId from connections set
      const currentUserConnections = new Set(
        currentUserConnectionsSnapshot.docs
          .filter((doc) => doc.id !== "dummyId")
          .map((doc) => doc.id)
      );

      // Create set of attendee IDs
      const eventAttendees = new Set(
        attendeesSnapshot.docs.map((doc) => doc.id)
      );

      // Find mutual attendees (intersection of sets)
      const mutualCount = [...currentUserConnections].filter((id) =>
        eventAttendees.has(id)
      ).length;

      setMutualAttendeesCount(mutualCount);
    } catch (error) {}
  };

  // Add this effect to calculate mutual attendees when attendees change
  useEffect(() => {
    if (currentUser?.uid && eventData.id) {
      calculateMutualAttendees();
    }
  }, [currentUser?.uid, eventData.id, attendees]);

  // Add this toggle function for the modal
  const toggleMutualAttendeesModal = () => {
    if (!showMutualAttendeesModal) {
      setMutualAttendeesModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, mutualAttendeesModalScrollPosition);
      }, 0);
    }
    setShowMutualAttendeesModal(!showMutualAttendeesModal);
    setIsMutualAttendeesModalOpen(!isMutualAttendeesModalOpen);
  };

  const fetchConnectionsCount = async () => {
    if (!currentUser?.uid) return;

    try {
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const q = query(connectionsRef, where("status", "==", "Connected"));
      const snapshot = await getDocs(q);

      // Count only valid connections (exclude dummyId)
      const validConnections = snapshot.docs.filter(
        (doc) => doc.id !== "dummyId"
      ).length;
      setConnectionsCount(validConnections);
    } catch (error) {
      setConnectionsCount(0);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target) &&
          optionsButtonRef.current &&
          !optionsButtonRef.current.contains(event.target)
        ) {
          setShowDropdown(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showDropdown]);

  useEffect(() => {
    if (showDropdown) {
      const positionDropdown = () => {
        if (optionsButtonRef.current && dropdownRef.current) {
          const buttonRect = optionsButtonRef.current.getBoundingClientRect();
          const dropdownRect = dropdownRef.current.getBoundingClientRect();

          dropdownRef.current.style.top = `${
            buttonRect.bottom + window.scrollY
          }px`;
          dropdownRef.current.style.right = `${
            window.innerWidth - buttonRect.right
          }px`;
        }
      };

      positionDropdown();
      window.addEventListener("resize", positionDropdown);
      window.addEventListener("scroll", positionDropdown);

      return () => {
        window.removeEventListener("resize", positionDropdown);
        window.removeEventListener("scroll", positionDropdown);
      };
    }
  }, [showDropdown]);

  // Add this useEffect to fetch the connections count when the user changes
  useEffect(() => {
    if (currentUser?.uid) {
      fetchConnectionsCount();
    }
  }, [currentUser?.uid]);

  const toggleShowAllKeywords = () => setShowAllKeywords(true);

  const handleReport = () => {
    setShowDropdown(false);
    setReportMessage({
      text: "Report submitted.",
    });
  };

  const checkIfBlocked = async (currentUserId, eventOwnerId) => {
    if (!currentUserId || !eventOwnerId) return;

    try {
      // Check if current user has blocked the event owner
      const currentUserBlockedRef = collection(
        firestore,
        `users/${currentUserId}/blockedUsers`
      );
      const currentUserBlockedQuery = query(
        currentUserBlockedRef,
        where("userId", "==", eventOwnerId)
      );
      const currentUserBlockedSnap = await getDocs(currentUserBlockedQuery);

      // Check if event owner has blocked the current user
      const ownerBlockedRef = collection(
        firestore,
        `users/${eventOwnerId}/blockedUsers`
      );
      const ownerBlockedQuery = query(
        ownerBlockedRef,
        where("userId", "==", currentUserId)
      );
      const ownerBlockedSnap = await getDocs(ownerBlockedQuery);

      setHasUserBlockedThem(currentUserBlockedSnap.size > 0);
      setIsBlocked(
        currentUserBlockedSnap.size > 0 || ownerBlockedSnap.size > 0
      );
    } catch (error) {}
  };

  useEffect(() => {
    if (currentUser?.uid && eventData?.ownerId) {
      checkIfBlocked(currentUser.uid, eventData.ownerId);
    }
  }, [currentUser?.uid, eventData?.ownerId]);

  const handleAttendeeRemoved = (count) => {
    setRemoveCount(count);
    setShowRemovePopup(true);
    fetchAttendees(); // Re-fetch attendees list
  };

  const toggleAddAttendeeModal = () => {
    if (!showAddAttendeeModal) {
      setAddAttendeeModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addAttendeeModalScrollPosition);
      }, 0);
    }
    setShowAddAttendeeModal(!showAddAttendeeModal);
    setIsAddAttendeeModalOpen(!isAddAttendeeModalOpen);
  };

  const toggleEditAttendeeModal = () => {
    if (!showEditAttendeeModal) {
      setEditAttendeeModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editAttendeeModalScrollPosition);
      }, 0);
    }
    setShowEditAttendeeModal(!showEditAttendeeModal);
    setIsEditAttendeeModalOpen(!isEditAttendeeModalOpen);
  };

  const toggleAddOrganiserModal = () => {
    if (!showAddOrganiserModal) {
      setAddOrganiserModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, addOrganiserModalScrollPosition);
      }, 0);
    }
    setShowAddOrganiserModal(!showAddOrganiserModal);
    setIsAddOrganiserModalOpen(!isAddOrganiserModalOpen);
  };

  const toggleEditOrganiserModal = () => {
    if (!showEditOrganiserModal) {
      setEditOrganiserModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editOrganiserModalScrollPosition);
      }, 0);
    }
    setShowEditOrganiserModal(!showEditOrganiserModal);
    setIsEditOrganiserModalOpen(!isEditOrganiserModalOpen);
  };

  const checkAvailableAttendeesConnections = async () => {
    if (!currentUser?.uid || !eventData.id || !eventData.ownerId) return;

    // Check if current user is owner or active organizer
    const isActiveHost = hosts.some(
      (host) => host.id === currentUser?.uid && host.status === "active"
    );
    if (!isOwner && !isActiveHost) return;

    try {
      // Get all user's connections (excluding dummyId)
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const q = query(connectionsRef, where("status", "==", "Connected"));
      const querySnapshot = await getDocs(q);
      const validConnections = querySnapshot.docs.filter(
        (doc) => doc.id !== "dummyId"
      );

      // Get only ACTIVE hosts
      const hostsRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/hosts`
      );
      const hostsQuery = query(hostsRef, where("status", "==", "active"));
      const hostsSnapshot = await getDocs(hostsQuery);
      const activeHostIds = new Set(hostsSnapshot.docs.map((doc) => doc.id));

      // Get all current attendees
      const attendeesRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/attendees`
      );
      const attendeesSnapshot = await getDocs(attendeesRef);
      const attendeeIds = new Set(attendeesSnapshot.docs.map((doc) => doc.id));

      // Create a set of excluded IDs (active hosts and attendees)
      const excludedIds = new Set([
        ...activeHostIds,
        ...attendeeIds,
        eventData.ownerId,
      ]);

      // Check if there are any valid connections that aren't active hosts or attendees
      const hasAvailable = validConnections.some(
        (doc) => !excludedIds.has(doc.id)
      );

      setHasAvailableAttendeesConnections(hasAvailable);
    } catch (error) {}
  };

  useEffect(() => {
    if (currentUser?.uid && eventData.id && eventData.ownerId) {
      checkAvailableAttendeesConnections();
    }
  }, [currentUser?.uid, eventData.id, eventData.ownerId, hosts]);

  const checkAvailableConnections = useCallback(async () => {
    if (!currentUser?.uid || !eventData.id || !eventData.ownerId) return;

    try {
      // Get all user's connections (excluding dummyId)
      const connectionsRef = collection(
        firestore,
        `users/${currentUser.uid}/connections`
      );
      const q = query(connectionsRef, where("status", "==", "Connected"));
      const querySnapshot = await getDocs(q);
      const validConnections = querySnapshot.docs.filter(
        (doc) => doc.id !== "dummyId"
      );

      // Get all hosts (both active and pending)
      const hostsRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/hosts`
      );
      const hostsSnapshot = await getDocs(hostsRef);
      const hostIds = new Set(hostsSnapshot.docs.map((doc) => doc.id));

      // Check if there are any valid connections that aren't hosts
      const hasAvailable = validConnections.some((doc) => !hostIds.has(doc.id));

      setHasAvailableConnections(hasAvailable);
    } catch (error) {}
  }, [currentUser?.uid, eventData.id, eventData.ownerId]);

  const checkConnectionType = async (currentUserId, targetUserId) => {
    if (!currentUserId || !targetUserId) return "Extended";
    if (currentUserId === targetUserId) return "You";

    const currentUserBlockedRef = collection(
      firestore,
      `users/${currentUserId}/blockedUsers`
    );
    const currentUserBlockedQuery = query(
      currentUserBlockedRef,
      where("userId", "==", targetUserId)
    );
    const currentUserBlockedSnap = await getDocs(currentUserBlockedQuery);

    const targetUserBlockedRef = collection(
      firestore,
      `users/${targetUserId}/blockedUsers`
    );
    const targetUserBlockedQuery = query(
      targetUserBlockedRef,
      where("userId", "==", currentUserId)
    );
    const targetUserBlockedSnap = await getDocs(targetUserBlockedQuery);

    if (currentUserBlockedSnap.size > 0 || targetUserBlockedSnap.size > 0) {
      return "Blocked";
    }

    const userConnectionRef = doc(
      firestore,
      `users/${currentUserId}/connections/${targetUserId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    const userConnectionsRef = collection(
      firestore,
      `users/${currentUserId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${targetUserId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  // Make sure this useEffect dependency array includes checkAvailableConnections
  useEffect(() => {
    checkAvailableConnections();
  }, [checkAvailableConnections]);

  useEffect(() => {
    let mounted = true;

    const checkConnections = async () => {
      if (mounted) {
        await checkAvailableConnections();
      }
    };

    checkConnections();

    return () => {
      mounted = false;
    };
  }, [checkAvailableConnections]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchPendingInvites = async () => {
      if (!eventData?.id || !eventData?.ownerId) return;

      try {
        const hostsRef = collection(
          firestore,
          `users/${eventData.ownerId}/events/${eventData.id}/hosts`
        );
        const q = query(hostsRef, where("status", "==", "pending"));
        const snapshot = await getDocs(q);

        const pendingIds = new Set();
        snapshot.forEach((doc) => {
          pendingIds.add(doc.id);
        });
        setPendingInvites(pendingIds);
      } catch (error) {}
    };

    fetchPendingInvites();
  }, [eventData?.id, eventData?.ownerId]);

  // Add this handler for the InvitePopup
  const handleInvitePopupClose = () => {
    setShowInvitePopup(false);
  };

  // Add this new handler function
  const handleRemoveInvite = async (userId) => {
    try {
      const batch = writeBatch(firestore);

      // Delete the host document
      const hostRef = doc(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/hosts/${userId}`
      );
      batch.delete(hostRef);

      // Delete the notification sent to the user
      const userNotificationsRef = collection(
        firestore,
        `users/${userId}/notifications`
      );
      const notifQuery = query(
        userNotificationsRef,
        where("type", "==", "organiser_invite"),
        where("eventId", "==", eventData.id)
      );
      const notifSnapshot = await getDocs(notifQuery);
      notifSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();

      // Update local state
      setPendingInvites((prev) => {
        const newSet = new Set(prev);
        newSet.delete(userId);
        return newSet;
      });

      setOpenDropdown(null);
    } catch (error) {}
  };

  const [openDropdown, setOpenDropdown] = useState(null);

  // Add this function with your other utility functions
  const updateLoadingState = (key, value) => {
    setLoadingStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // Add this function with your other handlers
  const onInviteSent = () => {
    setShowInvitePopup(true);
  };

  const handleRemoveHost = async (e, hostId) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const batch = writeBatch(firestore);

      // Remove user from event's hosts collection
      const hostRef = doc(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/hosts/${hostId}`
      );
      batch.delete(hostRef);

      // Delete "joined as organiser" notification from owner's notifications
      const ownerNotificationsRef = collection(
        firestore,
        `users/${eventData.ownerId}/notifications`
      );
      const ownerNotifQuery = query(
        ownerNotificationsRef,
        where("type", "==", "organiser_invite_accepted"),
        where("from", "==", hostId),
        where("eventId", "==", eventData.id)
      );
      const ownerNotifSnapshot = await getDocs(ownerNotifQuery);
      ownerNotifSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Delete "invite accepted/response" notification from host's notifications
      const hostNotificationsRef = collection(
        firestore,
        `users/${hostId}/notifications`
      );
      const hostNotifQuery = query(
        hostNotificationsRef,
        where("type", "==", "organiser_invite_response"),
        where("eventId", "==", eventData.id)
      );
      const hostNotifSnapshot = await getDocs(hostNotifQuery);
      hostNotifSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();

      // Update local state
      setHosts((prev) => prev.filter((host) => host.id !== hostId));
      setOpenDropdown(null);

      // Fetch updated hosts list
      fetchHosts();
    } catch (error) {}
  };

  const handleShare = useCallback(() => {
    const eventUrl = `${window.location.origin}/event/${eventUrlSlug}`;
    navigator.clipboard.writeText(eventUrl).then(() => {
      setShareButtonText("Copied");
      setTimeout(() => {
        setShareButtonText("Share");
      }, 5000);
    });
  }, [eventUrlSlug]);

  const fetchAttendees = async () => {
    if (!eventData.id || !eventData.ownerId) return;

    try {
      const attendeesRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/attendees`
      );
      const attendeesQuery = query(attendeesRef, orderBy("attendedAt", "desc"));
      const attendeesSnapshot = await getDocs(attendeesQuery);

      const attendeesData = await Promise.all(
        attendeesSnapshot.docs.map(async (attendeeDoc) => {
          const userUUID = attendeeDoc.id;
          const attendeeData = attendeeDoc.data();

          const userDoc = await getDoc(doc(firestore, "users", userUUID));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            let connectionType = "Extended";
            if (currentUser) {
              connectionType = await checkConnectionType(
                currentUser.uid,
                userUUID
              );
            }

            return {
              id: attendeeDoc.id,
              userUUID: userUUID,
              attendedAt: attendeeData.attendedAt,
              connectionType: connectionType,
              userData: {
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || "",
                bio: userData.bio || "",
                link: userData.link || "",
                linkText: userData.linkText || "",
                city: userData.city || "",
                country: userData.country || "",
                urlSlug: userData.urlSlug || "",
              },
            };
          }
          return null;
        })
      );

      const validAttendees = attendeesData
        .filter((attendee) => attendee !== null)
        .sort((a, b) => b.attendedAt - a.attendedAt);

      setAttendees(validAttendees);
      setAttendeesCount(validAttendees.length);
    } catch (error) {}
  };

  // Add this useEffect to fetch attendees when event data is loaded
  useEffect(() => {
    if (eventData.id && eventData.ownerId) {
      fetchAttendees();
    }
  }, [eventData.id, eventData.ownerId]);

  const [loadingPosts, setLoadingPosts] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);

  const [loadingReactions, setLoadingReactions] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [loadingReposts, setLoadingReposts] = useState(false);

  const handleAddOrganiserModalClose = (result) => {
    if (result === "success") {
      setPendingInvites((prev) => {
        const newSet = new Set(prev);
        newSet.add(selectedUser);
        return newSet;
      });
      onInviteSent();
    }
    setShowAddOrganiserModal(false);
    setSelectedUser(null);
  };

  // In the fetchHosts function, simply treat owner like any other user
  const fetchHosts = async () => {
    if (!eventData.id || !eventData.ownerId) return;

    updateLoadingState("hosts", true);
    try {
      const hostsRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/hosts`
      );
      const hostsQuery = query(hostsRef, where("status", "==", "active"));
      const hostsSnapshot = await getDocs(hostsQuery);

      // Get owner first with correct connection type
      const ownerDoc = await getDoc(doc(firestore, "users", eventData.ownerId));
      const ownerData = ownerDoc.exists() ? ownerDoc.data() : null;

      let hosts = [];
      if (ownerData) {
        const ownerConnectionType = currentUser
          ? await checkConnectionType(currentUser.uid, eventData.ownerId)
          : "Extended";

        hosts.push({
          id: eventData.ownerId,
          userUUID: eventData.ownerId,
          connectionType: ownerConnectionType,
          status: "active", // Add status for owner
          isOwner: true,
          userData: {
            firstName: ownerData.firstName || "",
            lastName: ownerData.lastName || "",
            profileImage: ownerData.profileImage || "",
            bio: ownerData.bio || "",
            link: ownerData.link || "",
            linkText: ownerData.linkText || "",
            urlSlug: ownerData.urlSlug || "",
          },
        });
      }

      // Then get other active hosts
      const otherHosts = await Promise.all(
        hostsSnapshot.docs.map(async (hostDoc) => {
          const userUUID = hostDoc.id;
          if (userUUID === eventData.ownerId) return null;

          const userDoc = await getDoc(doc(firestore, "users", userUUID));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const connectionType = currentUser
              ? await checkConnectionType(currentUser.uid, userUUID)
              : "Extended";

            return {
              id: hostDoc.id,
              userUUID: userUUID,
              connectionType: connectionType,
              status: hostDoc.data().status, // Include host status
              userData: {
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || "",
                bio: userData.bio || "",
                link: userData.link || "",
                linkText: userData.linkText || "",
                urlSlug: userData.urlSlug || "",
              },
            };
          }
          return null;
        })
      );

      hosts = [...hosts, ...otherHosts.filter((host) => host !== null)];
      setHosts(hosts);
    } catch (error) {
    } finally {
      updateLoadingState("hosts", false);
    }
  };

  // Update useEffect to fetch hosts when event data is loaded
  useEffect(() => {
    if (eventData.id && eventData.ownerId) {
      fetchHosts();
    }
  }, [eventData.id, eventData.ownerId]);

  useEffect(() => {
    let mounted = true;

    const initializeApp = async () => {
      try {
        if (!eventUrlSlug) return;

        // Reset all loading states
        if (mounted) {
          setLoadingStates({
            eventData: true,
            hosts: true,
            attendees: true,
            connections: true,
            attendanceStatus: true,
            activities: true,
            eventStatus: true,
          });
        }

        const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
          if (!mounted) return;

          if (user) {
            setCurrentUser(user);
            await fetchUserData(user.uid);
          } else {
            setCurrentUser(null);
            setIsOwner(false);
          }

          // First fetch event data and determine closed status
          if (mounted) {
            await fetchEventData(eventUrlSlug);
          }

          // Then load the rest of the data in parallel
          if (mounted) {
            await Promise.all([
              fetchHosts().then(
                () => mounted && updateLoadingState("hosts", false)
              ),
              fetchAttendees().then(
                () => mounted && updateLoadingState("attendees", false)
              ),
              checkAvailableConnections().then(
                () => mounted && updateLoadingState("connections", false)
              ),
              checkAttendanceStatus().then(
                () => mounted && updateLoadingState("attendanceStatus", false)
              ),
              fetchActivityData().then(
                () => mounted && updateLoadingState("activities", false)
              ),
            ]);
          }
        });

        return () => {
          mounted = false;
          unsubscribeAuth();
        };
      } catch (error) {
        if (mounted) {
          setEventNotFound(true);
          // Reset all loading states on error
          setLoadingStates({
            eventData: false,
            hosts: false,
            attendees: false,
            connections: false,
            attendanceStatus: false,
            activities: false,
            eventStatus: false,
          });
        }
      }
    };

    initializeApp();

    return () => {
      mounted = false;
    };
  }, [eventUrlSlug]);

  const fetchActivityData = async () => {
    if (!eventData.id || !eventData.ownerId) return;

    try {
      await Promise.all([
        fetchEventPosts(),
        fetchEventReactions(),
        fetchEventMessages(),
        fetchEventReposts(),
      ]);
    } catch (error) {}
  };

  // Add this new effect to monitor ownership changes
  useEffect(() => {}, [isOwner, currentUser?.uid, eventData?.ownerId]);

  // Add this after the userTimezone state declaration at the top of the component
  useEffect(() => {}, [
    eventData.startTime,
    eventData.endTime,
    eventData.startDate,
    eventData.timezone,
    userTimezone,
  ]);

  const checkEventStatus = useCallback(() => {
    if (!eventData.endDate || !eventData.endTime || !eventData.timezone) {
      setIsEventClosed(false);
      return false;
    }

    try {
      // Convert the event's end time to the user's timezone
      const userTimezone = getUserTimezone();
      const localEndTime = convertTime(
        eventData.endTime,
        eventData.endDate,
        eventData.timezone,
        userTimezone
      );

      // Parse the local end time and handle AM/PM
      const timeMatch = localEndTime.match(/(\d+):(\d+)\s*(AM|PM)/i);
      if (!timeMatch) {
        setIsEventClosed(false);
        return false;
      }

      let [_, hours, minutes, period] = timeMatch;
      hours = parseInt(hours);
      minutes = parseInt(minutes);

      // Convert to 24-hour format
      if (period.toUpperCase() === "PM" && hours !== 12) {
        hours += 12;
      } else if (period.toUpperCase() === "AM" && hours === 12) {
        hours = 0;
      }

      const [day, month, year] = eventData.endDate
        .split("/")
        .map((num) => parseInt(num));

      // Create date object in user's local timezone with exact time
      const eventEndDate = new Date(year, month - 1, day, hours, minutes);
      const now = new Date();

      const isClosed = now > eventEndDate;
      setIsEventClosed(isClosed);
      return isClosed;
    } catch (error) {
      setIsEventClosed(false);
      return false;
    }
  }, [eventData.endDate, eventData.endTime, eventData.timezone]);

  // In Event.js, add this effect
  useEffect(() => {
    const updateEventStatus = async () => {
      if (!eventData.id || !eventData.ownerId) return;

      const isClosed = checkEventStatus();

      if (isClosed && !eventData.isClosed) {
        try {
          const eventRef = doc(
            firestore,
            `users/${eventData.ownerId}/events/${eventData.id}`
          );
          await updateDoc(eventRef, {
            isClosed: true,
            closedAt: new Date(),
          });

          setEventData((prev) => ({
            ...prev,
            isClosed: true,
            closedAt: new Date(),
          }));
        } catch (error) {}
      }
    };

    updateEventStatus();
  }, [eventData.id, eventData.ownerId, checkEventStatus]);

  // In fetchEventData function, modify the connection type check to be consistent:
  const fetchEventData = async (urlSlug) => {
    try {
      updateLoadingState("eventData", true);
      updateLoadingState("eventStatus", true);

      const usersRef = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersRef);
      let eventFound = false;

      for (const userDoc of usersSnapshot.docs) {
        const eventsRef = collection(firestore, `users/${userDoc.id}/events`);
        const eventsQuery = query(
          eventsRef,
          where("eventUrlSlug", "==", urlSlug)
        );
        const eventsSnapshot = await getDocs(eventsQuery);

        if (!eventsSnapshot.empty) {
          eventFound = true;
          const eventDoc = eventsSnapshot.docs[0];
          const data = eventDoc.data();

          const ownerDoc = await getDoc(doc(firestore, "users", userDoc.id));
          const ownerData = ownerDoc.data();

          let ownerConnectionType = "Extended";
          if (currentUser) {
            ownerConnectionType = await checkConnectionType(
              currentUser.uid,
              userDoc.id
            );
          }

          const isCurrentUserOwner = currentUser?.uid === userDoc.id;
          setIsOwner(isCurrentUserOwner);

          const isClosed = checkEventStatus();
          setIsEventClosed(isClosed);

          const eventDataObj = {
            id: eventDoc.id,
            ownerId: userDoc.id,
            eventName: data.eventName || "",
            tagline: data.tagline || "",
            link: data.link || "",
            linkText: data.linkText || "",
            eventImage: data.eventImage || "",
            intro: data.intro || "",
            introHtml: data.introHtml || "",
            tags: data.tags || [],
            isOnline: data.isOnline || false,
            city: data.city || "",
            country: data.country || "",
            address: data.address || "",
            startDate: data.startDate || "",
            endDate: data.endDate || "",
            startTime: data.startTime || "",
            endTime: data.endTime || "",
            timezone: data.timezone || "",
            eventUrlSlug: data.eventUrlSlug || "",
            isClosed: isClosed,
            closedAt: isClosed ? new Date() : null,
            ownerData: {
              id: userDoc.id,
              firstName: ownerData.firstName || "",
              lastName: ownerData.lastName || "",
              profileImage: ownerData.profileImage || "",
              bio: ownerData.bio || "",
              link: ownerData.link || "",
              linkText: ownerData.linkText || "",
              city: ownerData.city || "",
              country: ownerData.country || "",
              urlSlug: ownerData.urlSlug || "",
              connectionType: ownerConnectionType,
            },
          };

          setEventData(eventDataObj);

          if (isClosed && !data.isClosed) {
            const eventRef = doc(
              firestore,
              `users/${userDoc.id}/events/${eventDoc.id}`
            );
            await updateDoc(eventRef, {
              isClosed: true,
              closedAt: new Date(),
            });
          }

          await Promise.all([
            fetchHosts(),
            fetchAttendees(),
            checkAvailableConnections(),
            checkAvailableAttendeesConnections(),
            checkAttendanceStatus(),
          ]);

          break;
        }
      }

      if (!eventFound) {
        setEventNotFound(true);
      }
    } catch (error) {
      setEventNotFound(true);
      throw error;
    } finally {
      updateLoadingState("eventData", false);
      updateLoadingState("eventStatus", false);
    }
  };

  const BadgesCard = ({
    isOwner,
    isProfileComplete,
    hostsCount = 0,
    attendeesCount = 0,
    tagsCount = 0,
    classes,
  }) => {
    const [displayedBadges, setDisplayedBadges] = useState(9);

    const allBadges = [
      {
        id: "complete-curator",
        name: "Complete Curator",
        earned: isProfileComplete,
      },
      {
        id: "master-orchestrator",
        name: "Master Orchestrator",
        earned: hostsCount >= 3,
      },
      {
        id: "packed-house",
        name: "Packed House",
        earned: attendeesCount >= 50,
      },
      {
        id: "maximum-exposure",
        name: "Maximum Exposure",
        earned: tagsCount >= 25,
      },
    ];

    const earnedBadges = allBadges.filter((badge) => badge.earned);
    const hasBadges = earnedBadges.length > 0;

    // If not owner and no badges, return null (don't show card)
    if (!isOwner && !hasBadges) {
      return null;
    }

    // For visitors, only show earned badges
    const badgesToShow = isOwner ? allBadges : earnedBadges;

    return (
      <div className={classes.card}>
        <div
          className={`${classes.cardTitle} ${
            !hasBadges ? classes.emptyCardTitle : ""
          }`}
        >
          Badges
          <a
            href="/badges"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.userLink}
          >
            <svg
              style={{ cursor: "pointer" }}
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </a>
        </div>

        <div className={classes.badges}>
          {isOwner && (
            <div className={classes.badgesText}>
              Complete challenges and contribute to the event to earn badges.
              Each badge tells a story of your event's success.
            </div>
          )}

          <div className={classes.badgesContainer}>
            {badgesToShow.slice(0, displayedBadges).map((badge) =>
              badge.earned ? (
                <div key={badge.id} className={classes.skillContainer}>
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    style={{ color: "orangered" }}
                  >
                    <path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
                    <path
                      fillRule="evenodd"
                      d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                      clipRule="evenodd"
                    />
                    <path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z" />
                  </svg>
                  <span
                    className={classes.skillText}
                    style={{ color: "orangered", fontWeight: "900" }}
                  >
                    {badge.name}
                  </span>
                </div>
              ) : (
                isOwner && (
                  <div key={badge.id} className={classes.emptySkillContainer}>
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      style={{ color: "gray" }}
                    >
                      <path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
                      <path
                        fillRule="evenodd"
                        d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                        clipRule="evenodd"
                      />
                      <path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z" />
                    </svg>
                    <span className={classes.skillText}>{badge.name}</span>
                  </div>
                )
              )
            )}
          </div>
        </div>

        {badgesToShow.length > 9 && (
          <div
            className={classes.showAllExperiences}
            onClick={() => {
              if (displayedBadges >= badgesToShow.length) {
                setDisplayedBadges(9);
              } else {
                const additionalItems = displayedBadges * 2;
                setDisplayedBadges(
                  Math.min(
                    displayedBadges + additionalItems,
                    badgesToShow.length
                  )
                );
              }
            }}
          >
            {displayedBadges >= badgesToShow.length ? "Show less" : "Show more"}
            {displayedBadges >= badgesToShow.length ? (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m5 15 7-7 7 7"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 9-7 7-7-7"
                />
              </svg>
            )}
          </div>
        )}
      </div>
    );
  };

  // Add this function in your Event.js:
  const renderBadgesCard = () => {
    const badgeProps = {
      isOwner,
      isProfileComplete:
        !!eventData.intro &&
        !!eventData.eventImage &&
        !eventData.eventImage.includes("eventImage.jpg") &&
        !eventData.eventImage.includes("eventImage.jpeg") &&
        eventData.tags?.length > 0,
      hostsCount: hosts.length + 1, // +1 to include owner
      attendeesCount: attendeesCount,
      tagsCount: eventData.tags?.length || 0,
      classes: classes,
    };

    return <BadgesCard {...badgeProps} />;
  };

  const EventChecklist = ({ eventData, isOwner, classes }) => {
    const [isChecklistOpen, setIsChecklistOpen] = useState(true);
    const [completionStatus, setCompletionStatus] = useState({
      profilePicture: false,
      about: false,
      tags: false,
    });

    useEffect(() => {
      const hasCustomProfileImage =
        eventData.eventImage &&
        !eventData.eventImage.includes("eventImage.jpg") &&
        !eventData.eventImage.includes("eventImage.jpeg");

      setCompletionStatus({
        profilePicture: hasCustomProfileImage,
        about: !!eventData.intro,
        tags: Array.isArray(eventData.tags) && eventData.tags.length > 0,
      });
    }, [eventData]);

    const CompletedIcon = () => (
      <svg
        className={classes.checkIcon}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="orangered"
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
          clipRule="evenodd"
        />
      </svg>
    );

    const IncompleteIcon = () => (
      <svg
        className={classes.checkIcon}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="orangered"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
    );

    const checklistItems = [
      { id: "profilePicture", text: "Add a logo" },
      { id: "about", text: "Write an about" },
      { id: "tags", text: "Add your tags" },
    ];

    const isProfileComplete = Object.values(completionStatus).every(
      (status) => status === true
    );

    if (!isProfileComplete) {
      return (
        <div className={classes.card}>
          <div
            className={`${classes.checklist} ${
              isChecklistOpen ? classes.openOpen : ""
            }`}
            onClick={() => setIsChecklistOpen(!isChecklistOpen)}
            style={{ cursor: "pointer" }}
          >
            Checklist
            <div>
              {isChecklistOpen ? (
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m16 14-4-4-4 4"
                  />
                </svg>
              ) : (
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m8 10 4 4 4-4"
                  />
                </svg>
              )}
            </div>
          </div>

          <div
            className={`${classes.checklistContent} ${
              isChecklistOpen ? classes.openOpen : ""
            }`}
          >
            <div
              style={{
                color: "gray",
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              Get started by taking these essential steps:
            </div>

            <div
              style={{
                backgroundColor: "rgba(255, 69, 0, 0.1)",
                padding: "1rem",
                borderRadius: "0.5rem",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <div style={{ textAlign: "left" }}>
                {checklistItems.map((item, index) => (
                  <div
                    key={item.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom:
                        index !== checklistItems.length - 1 ? "0.5rem" : 0,
                    }}
                  >
                    {completionStatus[item.id] ? (
                      <CompletedIcon />
                    ) : (
                      <IncompleteIcon />
                    )}
                    <span
                      style={{
                        color: "orangered",
                        fontWeight: "900",
                      }}
                    >
                      {item.text}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const LoadingSpinner = () => (
    <div className={classes.page}>
      {isMobileView ? (
        <MobileNavbar currentUser={currentUser} />
      ) : (
        <MainNavbar currentUser={currentUser} />
      )}
      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.loadingIndicator}>
            <span
              className={`${classes.loadingIcon} material-symbols-outlined`}
            >
              progress_activity
            </span>
          </div>
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );

  // Add this effect to monitor ownership changes and auth state
  useEffect(() => {
    const handleAuthStateChange = async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUser({
            ...user,
            ...userData,
          });
        }
        // Check if the current user is the event owner
        if (eventData?.ownerId) {
          setIsOwner(user.uid === eventData.ownerId);
        }
      } else {
        setCurrentUser(null);
        setIsOwner(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);
    return () => unsubscribe();
  }, [eventData?.ownerId]);

  // Helper function to check if user should see owner controls
  const shouldShowContent = (contentType) => {
    const isOrganizer = hosts.some((host) => host.id === currentUser?.uid);

    switch (contentType) {
      case "editButtons":
        return isOwner;
      case "intro":
        return isOwner || (!isOwner && eventData.introHtml);
      case "tags":
        return (
          isOwner || (!isOwner && eventData.tags && eventData.tags.length > 0)
        );
      case "hosts":
        return isOwner || hosts.length > 0;
      case "attendees":
        return isOwner || isOrganizer || attendees.length > 0;
      default:
        return false;
    }
  };

  const fetchUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setCurrentUser((prev) => ({
          ...prev,
          ...userData,
        }));
      }
    } catch (error) {}
  };

  const handleEventUpdated = async (updatedEvent) => {
    try {
      const batch = writeBatch(firestore);

      // Update main event document
      const eventRef = doc(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}`
      );
      batch.update(eventRef, updatedEvent);

      // Get all attendees
      const attendeesSnapshot = await getDocs(
        collection(
          firestore,
          `users/${eventData.ownerId}/events/${eventData.id}/attendees`
        )
      );

      // Update each attendee's eventsAttending record
      attendeesSnapshot.docs.forEach((attendeeDoc) => {
        const attendeeEventRef = doc(
          firestore,
          `users/${attendeeDoc.id}/eventsAttending/${eventData.id}`
        );

        batch.update(attendeeEventRef, {
          endDate: updatedEvent.endDate,
          endTime: updatedEvent.endTime,
          timezone: updatedEvent.timezone,
          eventName: updatedEvent.eventName,
          eventImage: updatedEvent.eventImage,
          eventUrlSlug: updatedEvent.eventUrlSlug,
          isOnline: updatedEvent.isOnline,
          city: updatedEvent.city,
          country: updatedEvent.country,
        });
      });

      await batch.commit();

      setEventData((prev) => ({
        ...prev,
        ...updatedEvent,
      }));
    } catch (error) {}
  };

  const toggleTagsModal = () => {
    if (!showTagsModal) {
      // Store current scroll position when opening modal
      setTagsModalScrollPosition(window.scrollY);
    } else {
      // Restore scroll position when closing modal
      setTimeout(() => {
        window.scrollTo(0, tagsModalScrollPosition);
      }, 0);
    }
    setShowTagsModal(!showTagsModal);
    setIsTagsModalOpen(!isTagsModalOpen);
  };

  const toggleEditSingleEventModal = () => {
    if (!showEditSingleEventModal) {
      setEditSingleEventModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editSingleEventModalScrollPosition);
      }, 0);
    }
    setShowEditSingleEventModal(!showEditSingleEventModal);
    setIsEditSingleEventModalOpen(!isEditSingleEventModalOpen);
  };

  const toggleIntroModal = () => {
    if (!showIntroModal) {
      // Store current scroll position when opening modal
      setIntroModalScrollPosition(window.scrollY);
    } else {
      // Restore scroll position when closing modal
      setTimeout(() => {
        window.scrollTo(0, introModalScrollPosition);
      }, 0);
    }
    setShowIntroModal(!showIntroModal);
    setIsIntroModalOpen(!isIntroModalOpen);
  };

  const renderActivityCard = () => {
    const hasAnyActivity =
      activityCounts.posts > 0 ||
      activityCounts.reactions > 0 ||
      activityCounts.reposts > 0 ||
      activityCounts.messages > 0;

    // If no activity and not owner, don't show card at all
    if (!hasAnyActivity && !isOwner) {
      return null;
    }

    // Update activityCounts to match the case used in ActivityButtons component
    const formattedActivityCounts = {
      Posts: activityCounts.posts,
      Reactions: activityCounts.reactions,
      Messages: activityCounts.messages,
      Reposts: activityCounts.reposts,
    };

    return (
      <div className={classes.activityCard}>
        <div
          className={`${classes.activityCardTitle} ${
            hasAnyActivity ? "" : classes.emptyCardTitle
          }`}
        >
          Activity
        </div>

        <ActivityButtons
          selectedActivity={activityType}
          onSelectionChange={handleActivityChange}
          activityCounts={formattedActivityCounts}
          // Only show buttons with activity if not owner
          showAllButtons={isOwner}
        />

        {activityType === "Posts" && renderPosts()}
        {activityType === "Reactions" && renderReactions()}
        {activityType === "Comments" && renderComments()}
        {activityType === "Reposts" && renderReposts()}
      </div>
    );
  };

  useEffect(() => {
    if (activityCounts) {
      // If owner, default to Posts
      if (isOwner) {
        setActivityType("Posts");
        return;
      }

      // For non-owners, find first activity type with content
      const activityTypes = ["Posts", "Reactions", "Messages", "Reposts"];
      const firstActiveType = activityTypes.find(
        (type) => activityCounts[type.toLowerCase()] > 0
      );

      if (firstActiveType) {
        setActivityType(firstActiveType);
      }
    }
  }, [activityCounts, isOwner]);

  const handleActivityChange = (selectedActivity) => {
    setActivityType(selectedActivity);
    if (currentUser?.uid) {
      const activityTypeMap = {
        Posts: fetchEventPosts,
        Reactions: fetchEventReactions,
        Messages: fetchEventMessages,
        Reposts: fetchEventReposts,
      };

      const fetchFunction = activityTypeMap[selectedActivity];
      if (fetchFunction) {
        fetchFunction();
      }
    }
  };

  const renderPosts = () => {
    if (loadingPosts) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <div className={classes.posts}>
        <div>
          <p className={classes.defaultTextBold}>You haven't posted yet</p>
          <p className={classes.defaultText}>
            Posts you create will be displayed here.
          </p>
          <div className={classes.noPostBorder}></div>
          <div className={classes.postHeader}>
            <span className={classes.postAuthor}>You</span>
            <span className={classes.postMessage}> posted this </span>
            <span className={classes.dot}>•</span>
            <span>now</span>
          </div>
          <div className={classes.noPost}>
            <div className={classes.postContent}>
              <img
                src={greyPostImage}
                alt="Default startup"
                className={classes.noPostImage}
              />
              <p className={classes.noTextPreview}>Post</p>
            </div>
          </div>
          <div className={classes.noPostInteractions}>
            <div>100 reactions</div>
            <div className={classes.noRightInteractions}>
              <div>50 messages</div>
              <span className={classes.dot}>•</span>
              <div>25 reposts</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderComments = () => {
    if (loadingComments) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <div className={classes.posts}>
        <div>
          <p className={classes.defaultTextBold}>You haven't commented yet</p>
          <p className={classes.defaultText}>
            Posts you comment on will be displayed here.
          </p>
          <div className={classes.noPostBorder}></div>
          <div className={classes.postHeader}>
            <span className={classes.postAuthor}>You</span>
            <span className={classes.postMessage}> commented on this </span>
            <span className={classes.dot}>•</span>
            <span>now</span>
          </div>
          <div className={classes.noPost}>
            <div className={classes.postContent}>
              <img
                src={greyPostImage}
                alt="Default startup"
                className={classes.noPostImage}
              />
              <p className={classes.noTextPreview}>Post</p>
            </div>
          </div>
          <div className={classes.noPostInteractions}>
            <div>100 reactions</div>
            <div className={classes.noRightInteractions}>
              <div>50 messages</div>
              <span className={classes.dot}>•</span>
              <div>25 reposts</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderReactions = () => {
    if (loadingReactions) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <div className={classes.posts}>
        <div>
          <p className={classes.defaultTextBold}>You haven't reacted yet</p>
          <p className={classes.defaultText}>
            Posts you react to will be displayed here.
          </p>
          <div className={classes.noPostBorder}></div>
          <div className={classes.postHeader}>
            <span className={classes.postAuthor}>You</span>
            <span className={classes.postMessage}>
              {" "}
              reacted <span className={classes.textEmoji}>🐘</span> to this{" "}
            </span>
            <span className={classes.dot}>•</span>
            <span>now</span>
          </div>
          <div className={classes.noPost}>
            <div className={classes.postContent}>
              <img
                src={greyPostImage}
                alt="Default startup"
                className={classes.noPostImage}
              />
              <p className={classes.noTextPreview}>Post</p>
            </div>
          </div>
          <div className={classes.noPostInteractions}>
            <div>100 reactions</div>
            <div className={classes.noRightInteractions}>
              <div>50 messages</div>
              <span className={classes.dot}>•</span>
              <div>25 reposts</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderReposts = () => {
    if (loadingReposts) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <div className={classes.posts}>
        <div>
          <p className={classes.defaultTextBold}>You haven't reposted yet</p>
          <p className={classes.defaultText}>
            Posts you share will be displayed here.
          </p>
          <div className={classes.noPostBorder}></div>
          <div className={classes.postHeader}>
            <span className={classes.postAuthor}>You</span>
            <span className={classes.postMessage}> reposted this </span>
            <span className={classes.dot}>•</span>
            <span>now</span>
          </div>
          <div className={classes.noPost}>
            <div className={classes.postContent}>
              <img
                src={greyPostImage}
                alt="Default startup"
                className={classes.noPostImage}
              />
              <p className={classes.noTextPreview}>Post</p>
            </div>
          </div>
          <div className={classes.noPostInteractions}>
            <div>100 reactions</div>
            <div className={classes.noRightInteractions}>
              <div>50 messages</div>
              <span className={classes.dot}>•</span>
              <div>25 reposts</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const NoContentCard = ({ onRefresh }) => (
    <div className={classes.noNotifications}>
      <div className={classes.bold}>Page not found</div>
      <div className={classes.text}>Please check your internet connection.</div>
      <div className={classes.homeButton} onClick={onRefresh}>
        <svg
          className="w-6 h-6 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
          />
        </svg>
        Refresh
      </div>
    </div>
  );

  const handleAttend = async () => {
    if (!currentUser || !eventData.id || !eventData.ownerId) return;

    const batch = writeBatch(firestore);
    const isOrganiser = hosts.some(
      (host) => host.id === currentUser?.uid && host.status === "active"
    );

    // Different behavior if we're currently an organiser
    if (isOrganiser) {
      try {
        const hostRef = doc(
          firestore,
          `users/${eventData.ownerId}/events/${eventData.id}/hosts/${currentUser.uid}`
        );
        const eventOrganisingRef = doc(
          firestore,
          `users/${currentUser.uid}/eventsOrganising/${eventData.id}`
        );

        batch.delete(hostRef);
        batch.delete(eventOrganisingRef);

        await batch.commit();

        // Update local state
        setHosts((prevHosts) =>
          prevHosts.filter((host) => host.id !== currentUser.uid)
        );
        setAttendeeStatus("Attend"); // Show "Attend" button after removing organiser status

        // Refresh available connections check
        checkAvailableConnections();
        return;
      } catch (error) {
        return;
      }
    }

    // Regular attendee logic continues from here...
    const attendeeRef = doc(
      firestore,
      `users/${eventData.ownerId}/events/${eventData.id}/attendees/${currentUser.uid}`
    );
    const userEventRef = doc(
      firestore,
      `users/${currentUser.uid}/eventsAttending/${eventData.id}`
    );
    const eventRef = doc(
      firestore,
      `users/${eventData.ownerId}/events/${eventData.id}`
    );
    const userRef = doc(firestore, "users", currentUser.uid);

    try {
      if (attendeeStatus === "Attend") {
        batch.set(attendeeRef, {
          attendedAt: new Date(),
        });

        batch.set(userEventRef, {
          eventId: eventData.id,
          eventOwnerId: eventData.ownerId,
          attendedAt: new Date(),
          endDate: eventData.endDate,
          endTime: eventData.endTime,
          timezone: eventData.timezone,
          eventName: eventData.eventName,
          eventImage: eventData.eventImage,
          eventUrlSlug: eventData.eventUrlSlug,
          isOnline: eventData.isOnline,
          city: eventData.city,
          country: eventData.country,
        });

        batch.update(eventRef, {
          attendeeCount: increment(1),
        });

        batch.update(userRef, {
          attendingCount: increment(1),
        });

        const notificationRef = doc(
          collection(firestore, `users/${eventData.ownerId}/notifications`)
        );
        batch.set(notificationRef, {
          type: "event_attend",
          from: currentUser.uid,
          fromUserName: `${currentUser.firstName} ${currentUser.lastName}`,
          fromUserImage: currentUser.profileImage || defaultImage,
          eventId: eventData.id,
          eventName: eventData.eventName,
          eventUrlSlug: eventData.eventUrlSlug,
          eventImage: eventData.eventImage,
          eventOwnerId: eventData.ownerId,
          createdAt: new Date(),
          isNew: true,
          message: isEventClosed ? "attended this." : "is now an attendee.",
        });

        await batch.commit();
        setAttendeeStatus("Attending");
        setAttendeesCount((prev) => prev + 1);
        fetchAttendees();
      } else {
        batch.delete(attendeeRef);
        batch.delete(userEventRef);

        batch.update(eventRef, {
          attendeeCount: increment(-1),
        });

        batch.update(userRef, {
          attendingCount: increment(-1),
        });

        const notificationsRef = collection(
          firestore,
          `users/${eventData.ownerId}/notifications`
        );
        const q = query(
          notificationsRef,
          where("type", "==", "event_attend"),
          where("from", "==", currentUser.uid),
          where("eventId", "==", eventData.id)
        );

        const notificationsSnapshot = await getDocs(q);
        notificationsSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit();
        setAttendeeStatus("Attend");
        setAttendeesCount((prev) => Math.max(0, prev - 1));
        fetchAttendees();
      }
    } catch (error) {}
  };

  const fetchEventPosts = async () => {
    if (!eventData.id || !eventData.ownerId) return;

    try {
      const postsRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/posts`
      );
      const postsQuery = query(
        postsRef,
        orderBy("createdAt", "desc"),
        limit(20)
      );
      const postsSnapshot = await getDocs(postsQuery);

      const postsData = postsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
      }));

      setEventPosts(postsData);
    } catch (error) {}
  };

  const checkAttendanceStatus = useCallback(async () => {
    if (!currentUser || !eventData.id || !eventData.ownerId) return;

    try {
      const attendeeRef = doc(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/attendees/${currentUser.uid}`
      );
      const attendeeDoc = await getDoc(attendeeRef);

      if (attendeeDoc.exists()) {
        setAttendeeStatus("Attending");
      } else {
        setAttendeeStatus("Attend");
      }
    } catch (error) {
      setAttendeeStatus("Attend");
    }
  }, [currentUser, eventData.id, eventData.ownerId]);

  const RemovePopup = ({ onClose, count = 1, type = "organiser" }) => (
    <div className={classes.repostPopup}>
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="orangered"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
            clipRule="evenodd"
          />
        </svg>
        <p>
          {count > 1
            ? `${count} ${type}s removed.`
            : `${type.charAt(0).toUpperCase() + type.slice(1)} removed.`}
        </p>
      </div>
      <div onClick={onClose} className={classes.repostCloseButton}>
        <svg
          className={classes.repostCloseIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  const InvitePopup = ({ onClose, isModalOpen, count = 1 }) => (
    <div
      className={`${classes.repostPopup} ${
        isModalOpen ? classes.modalOpen : classes.modalClosed
      }`}
    >
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="orangered"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
            clipRule="evenodd"
          />
        </svg>
        <p>{count > 1 ? "Invites sent." : "Invite sent."}</p>
      </div>
      <div onClick={onClose} className={classes.repostCloseButton}>
        <svg
          className={classes.repostCloseIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  // Add useEffect to check attendance status when component mounts
  useEffect(() => {
    if (currentUser && eventData.id) {
      checkAttendanceStatus();
    }
  }, [currentUser, eventData.id, checkAttendanceStatus]);

  const fetchEventReactions = async () => {
    if (!eventData.id || !eventData.ownerId) return;

    try {
      const reactionsRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/reactions`
      );
      const reactionsQuery = query(
        reactionsRef,
        orderBy("reactedAt", "desc"),
        limit(20)
      );
      const reactionsSnapshot = await getDocs(reactionsQuery);

      const reactionsData = reactionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        reactedAt: doc.data().reactedAt?.toDate() || new Date(),
      }));

      setEventReactions(reactionsData);
    } catch (error) {}
  };

  const fetchEventMessages = async () => {
    if (!eventData.id || !eventData.ownerId) return;

    try {
      const messagesRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/messages`
      );
      const messagesQuery = query(
        messagesRef,
        orderBy("createdAt", "desc"),
        limit(20)
      );
      const messagesSnapshot = await getDocs(messagesQuery);

      const messagesData = messagesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
      }));

      setEventMessages(messagesData);
    } catch (error) {}
  };

  const fetchEventReposts = async () => {
    if (!eventData.id || !eventData.ownerId) return;

    try {
      const repostsRef = collection(
        firestore,
        `users/${eventData.ownerId}/events/${eventData.id}/reposts`
      );
      const repostsQuery = query(
        repostsRef,
        orderBy("repostedAt", "desc"),
        limit(20)
      );
      const repostsSnapshot = await getDocs(repostsQuery);

      const repostsData = repostsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        repostedAt: doc.data().repostedAt?.toDate() || new Date(),
      }));

      setEventReposts(repostsData);
    } catch (error) {}
  };

  const renderIntroText = () => {
    const introText = eventData.introHtml || "No description available.";
    const maxLength = 250;

    if (introText.length <= maxLength || showFullIntro) {
      return {
        text: <span dangerouslySetInnerHTML={{ __html: introText }} />,
        showMore: false,
      };
    } else {
      const truncatedText = introText.slice(0, maxLength).trim();
      return {
        text: <span dangerouslySetInnerHTML={{ __html: truncatedText }} />,
        showMore: true,
      };
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (eventNotFound) {
    return (
      <div className={classes.page}>
        {isMobileView ? (
          <MobileNavbar currentUser={currentUser} />
        ) : (
          <MainNavbar currentUser={currentUser} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <NoContentCard onRefresh={() => window.location.reload()} />
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  const formattedActivityCounts = {
    Posts: activityCounts.posts,
    Reactions: activityCounts.reactions,
    Messages: activityCounts.messages,
    Reposts: activityCounts.reposts,
  };

  const formatTimeWithTimezone = (time, timezone) => {
    if (!time || !timezone) return time;
    return `${time} (${timezone.replace(/_/g, " ")})`;
  };

  const renderActionButtons = () => {
    if (isEventClosed) {
      return (
        <div className={classes.buttons}>
          <button className={classes.following}>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                clipRule="evenodd"
              />
            </svg>
            Closed
          </button>
          <div
            className={`${
              shareButtonText === "Copied" ? classes.following : classes.button
            }`}
            onClick={handleShare}
          >
            {shareButtonText === "Copied" ? (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
              </svg>
            )}
            {shareButtonText}
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className={`${classes.page} ${
        isTagsModalOpen ||
        isIntroModalOpen ||
        isEditSingleEventModalOpen ||
        isAddOrganiserModalOpen ||
        isEditOrganiserModalOpen ||
        isAddAttendeeModalOpen ||
        isEditAttendeeModalOpen ||
        isMutualAttendeesModalOpen
          ? classes.noScroll
          : ""
      }`}
      style={
        isTagsModalOpen
          ? { top: `-${tagsModalScrollPosition}px` }
          : isIntroModalOpen
          ? { top: `-${introModalScrollPosition}px` }
          : isEditSingleEventModalOpen
          ? { top: `-${editSingleEventModalScrollPosition}px` }
          : isAddOrganiserModalOpen
          ? { top: `-${addOrganiserModalScrollPosition}px` }
          : isEditOrganiserModalOpen
          ? { top: `-${editOrganiserModalScrollPosition}px` }
          : isAddAttendeeModalOpen
          ? { top: `-${addAttendeeModalScrollPosition}px` }
          : isEditAttendeeModalOpen
          ? { top: `-${editAttendeeModalScrollPosition}px` }
          : isMutualAttendeesModalOpen
          ? { top: `-${mutualAttendeesModalScrollPosition}px` }
          : {}
      }
    >
      {isMobileView ? (
        <MobileNavbar currentUser={currentUser} />
      ) : (
        <MainNavbar currentUser={currentUser} />
      )}
      {showMutualAttendeesModal && (
        <MutualAttendeesModal
          onClose={() => {
            setShowMutualAttendeesModal(false);
            setIsMutualAttendeesModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, mutualAttendeesModalScrollPosition);
            }, 0);
          }}
          currentUserId={eventData.ownerId}
          loggedInUserId={currentUser?.uid}
          eventId={eventData.id} // Add this line
        />
      )}
      {reportMessage && (
        <div className={classes.repostPopup}>
          <div className={classes.repostPopupContent}>
            <svg
              className={classes.tickIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="orangered"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                clipRule="evenodd"
              />
            </svg>
            <p>{reportMessage.text}</p>
          </div>
          <div className={classes.repostCloseButton}>
            <svg
              className={classes.repostCloseIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
              viewBox="0 0 24 24"
              onClick={() => setReportMessage(null)}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
      )}
      {showEditSingleEventModal && (
        <EditSingleEventModal
          event={eventData}
          onClose={toggleEditSingleEventModal}
          onUpdate={handleEventUpdated} // This should be the function name you're using
          currentUser={currentUser}
          isFromEventPage={true}
        />
      )}
      {showEditAttendeeModal && (
        <EditAttendeesModal
          onClose={async (result) => {
            if (result === "success") {
              await fetchAttendees();
            }
            toggleEditAttendeeModal(); // Use the new toggle function
          }}
          eventData={eventData}
          currentUser={currentUser}
          onAttendeeRemoved={(count) => {
            setRemoveCount(count);
            setCurrentModalType("attendee");
            setShowRemovePopup(true);
          }}
        />
      )}
      {showAddAttendeeModal && (
        <AddAttendeeModal
          onClose={async (result) => {
            if (result === "success") {
              await fetchAttendees();
              await checkAvailableAttendeesConnections();
            }
            toggleAddAttendeeModal(); // Use the new toggle function
          }}
          eventData={eventData}
          currentUser={currentUser}
          onInviteSent={(count) => {
            setInviteCount(count);
            setShowInvitePopup(true);
          }}
        />
      )}

      {showIntroModal && (
        <IntroModal
          onClose={toggleIntroModal}
          currentUser={currentUser}
          isEvent={true}
          eventData={eventData}
          eventOwnerId={eventData.ownerId}
          onProfileUpdate={(updatedData) => {
            setEventData((prev) => ({
              ...prev,
              intro: updatedData.intro,
              introHtml: updatedData.introHtml,
            }));
            toggleIntroModal();
          }}
        />
      )}

      {showTagsModal && (
        <TagsModal
          onClose={() => {
            toggleTagsModal();
            setTimeout(() => {
              window.scrollTo(0, tagsModalScrollPosition);
            }, 0);
          }}
          currentUser={currentUser}
          onTagsUpdate={handleEventUpdated}
          eventId={eventData.id}
          eventOwnerId={eventData.ownerId}
        />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.profileCard}>
            <div className={classes.profileHeader}>
              <div
                className={classes.profileImage}
                onClick={isOwner ? toggleEditSingleEventModal : undefined}
                style={{ cursor: isOwner ? "pointer" : "default" }}
              >
                <img src={eventData.eventImage || eventImage} alt="Event" />
              </div>
              {shouldShowContent("editButtons") ? (
                <div onClick={toggleEditSingleEventModal}>
                  <svg
                    className={classes.closeIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                    />
                  </svg>
                </div>
              ) : (
                <div
                  onClick={() => setShowDropdown(!showDropdown)}
                  className={classes.headerButton}
                  ref={optionsButtonRef}
                >
                  <div
                    className={`${classes.headerIcon} ${
                      showDropdown ? classes.headerIconActive : ""
                    }`}
                  >
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="gray"
                        strokeLinecap="round"
                        strokeWidth="3"
                        d="M6 12h.01m6 0h.01m5.99 0h.01"
                      />
                    </svg>
                  </div>
                  {showDropdown && (
                    <div ref={dropdownRef} className={classes.headerDropdown}>
                      <button
                        className={classes.headerButton}
                        onClick={handleReport}
                      >
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                        Report
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className={classes.profileTitle}>
              {eventData.eventName}{" "}
              <span className={classes.connectionType}>• Event</span>
            </div>

            {/* Only render userDetails div if there's tagline or link */}
            {(eventData.tagline || eventData.link) && (
              <div className={classes.userDetails}>
                {eventData.tagline && (
                  <div className={classes.bio}>{eventData.tagline}</div>
                )}
                {eventData.link && (
                  <div className={classes.link}>
                    <a
                      href={eventData.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {eventData.linkText || eventData.link}
                    </a>
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="orangeRed"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                      />
                    </svg>
                  </div>
                )}
              </div>
            )}

            <div className={classes.buttons}>
              {!isBlocked ? (
                <>
                  {!isOwner && (
                    <button
                      className={
                        hosts.some(
                          (host) =>
                            host.id === currentUser?.uid &&
                            host.status === "active"
                        )
                          ? classes.following
                          : attendeeStatus === "Attending"
                          ? classes.following
                          : classes.headlineButton
                      }
                      onClick={handleAttend}
                    >
                      {hosts.some(
                        (host) =>
                          host.id === currentUser?.uid &&
                          host.status === "active"
                      ) ? (
                        <>
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Organiser
                        </>
                      ) : (
                        <>
                          {attendeeStatus === "Attending" ||
                          attendeeStatus === "Attended" ? (
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : (
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                                clipRule="evenodd"
                              />
                              <path
                                fillRule="evenodd"
                                d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                          {isEventClosed
                            ? attendeeStatus === "Attending"
                              ? "Attended"
                              : "Attended"
                            : attendeeStatus}
                        </>
                      )}
                    </button>
                  )}

                  {shouldShowContent("editButtons") && !eventData.tagline && (
                    <div
                      className={classes.headlineButton}
                      onClick={toggleEditSingleEventModal}
                    >
                      <svg
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 12h14m-7 7V5"
                        />
                      </svg>
                      Add a tagline
                    </div>
                  )}

                  {shouldShowContent("editButtons") && !eventData.link && (
                    <div
                      className={classes.button}
                      onClick={toggleEditSingleEventModal}
                    >
                      <svg
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 12h14m-7 7V5"
                        />
                      </svg>
                      Add a link
                    </div>
                  )}
                </>
              ) : null}

              {/* Share button - always shown */}
              <div
                className={`${
                  shareButtonText === "Copied"
                    ? classes.following
                    : classes.button
                }`}
                onClick={handleShare}
              >
                {shareButtonText === "Copied" ? (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                  </svg>
                )}
                {shareButtonText}
              </div>
            </div>
          </div>
          {isOwner && (
            <EventChecklist
              eventData={eventData}
              isOwner={isOwner}
              classes={classes}
            />
          )}
          {!isBlocked && (
            <>
              <div className={classes.card}>
                <div className={classes.cardTitle}>
                  About
                  {shouldShowContent("editButtons") && (
                    <div onClick={toggleIntroModal}>
                      <svg
                        className={classes.closeIcon}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                        />
                      </svg>
                    </div>
                  )}
                </div>

                <div className={classes.introText}>
                  {eventData.introHtml ? (
                    <pre>
                      {renderIntroText().text}
                      {renderIntroText().showMore && (
                        <span
                          className={classes.moreButton}
                          onClick={() => setShowFullIntro(true)}
                        >
                          ...more
                        </span>
                      )}
                    </pre>
                  ) : (
                    isOwner && (
                      <span className={classes.emptyIntroText}>
                        Tell people about your event. What can attendees expect?
                        Share the details that will get people excited to
                        attend.
                      </span>
                    )
                  )}
                </div>

                {/* Only show border if there's intro text OR if user is owner */}
                {(eventData.introHtml || isOwner) && (
                  <div className={classes.noPostBorder}></div>
                )}

                {/* Rest of the About section */}
                <div className={classes.cardAbout}>
                  {isEventClosed && (
                    <div className={classes.eventPassedContainer}>
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className={classes.skillText}>
                        This event has already happened
                      </span>
                    </div>
                  )}
                  {mutualAttendeesCount > 0 && (
                    <div
                      className={classes.skillContainer}
                      onClick={toggleMutualAttendeesModal}
                      style={{ cursor: "pointer" }}
                    >
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className={classes.skillText}>
                        {mutualAttendeesCount} Mutual{" "}
                        {mutualAttendeesCount === 1 ? "Attendee" : "Attendees"}
                      </span>
                    </div>
                  )}
                  {eventData.isOnline ? (
                    <div className={classes.skillContainer}>
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7Zm2 9.387 4.684 1.562A1 1 0 0 0 22 17V7a1 1 0 0 0-1.316-.949L16 7.613v8.774Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className={classes.skillText}>Online</span>
                    </div>
                  ) : (
                    <>
                      {eventData.address && (
                        <div className={classes.skillContainer}>
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5 9a7 7 0 1 1 8 6.93V21a1 1 0 1 1-2 0v-5.07A7.001 7.001 0 0 1 5 9Zm5.94-1.06A1.5 1.5 0 0 1 12 7.5a1 1 0 1 0 0-2A3.5 3.5 0 0 0 8.5 9a1 1 0 0 0 2 0c0-.398.158-.78.44-1.06Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className={classes.skillText}>
                            {eventData.address}
                          </span>
                        </div>
                      )}
                      {eventData.city && eventData.country && (
                        <div className={classes.skillContainer}>
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span className={classes.skillText}>
                            {eventData.city}, {eventData.country}
                          </span>
                        </div>
                      )}
                    </>
                  )}

                  {eventData.startDate && eventData.startTime && (
                    <div className={classes.skillContainer}>
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 5.05h1a2 2 0 0 1 2 2v2H3v-2a2 2 0 0 1 2-2h1v-1a1 1 0 1 1 2 0v1h3v-1a1 1 0 1 1 2 0v1h3v-1a1 1 0 1 1 2 0v1Zm-15 6v8a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-8H3ZM11 18a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1a1 1 0 1 0-2 0v1h-1a1 1 0 1 0 0 2h1v1Z"
                          clipRule="evenodd"
                        />
                      </svg>

                      <span className={classes.skillText}>
                        {formatEventDate(eventData.startDate)}
                        {eventData.startTime &&
                          eventData.timezone &&
                          `, ${formatEventTime(
                            eventData.startTime,
                            eventData.startDate,
                            eventData.timezone,
                            userTimezone,
                            eventData.startDate === eventData.endDate
                              ? eventData.endTime
                              : null
                          )}`}
                      </span>
                    </div>
                  )}

                  {/* Only show end date/time if the dates are different */}
                  {eventData.endDate &&
                    eventData.startDate !== eventData.endDate && (
                      <div className={classes.skillContainer}>
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6 5V4a1 1 0 1 1 2 0v1h3V4a1 1 0 1 1 2 0v1h3V4a1 1 0 1 1 2 0v1h1a2 2 0 0 1 2 2v2H3V7a2 2 0 0 1 2-2h1ZM3 19v-8h18v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm5-6a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H8Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span className={classes.skillText}>
                          {formatEventDate(eventData.endDate)}
                          {eventData.endTime &&
                            `, ${formatEventTime(
                              eventData.endTime,
                              eventData.endDate,
                              eventData.timezone,
                              userTimezone
                            )}`}
                        </span>
                      </div>
                    )}
                  <div className={classes.skillContainer}>
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
                      />
                    </svg>
                    <span className={classes.skillText}>
                      foundry.bz/event/{eventData.eventUrlSlug}
                    </span>
                  </div>
                </div>
              </div>

              {/* {renderActivityCard()} */}
              <div className={classes.card}>
                <div className={classes.cardTitle}>
                  Hosts
                  {isOwner && (
                    <div className={classes.iconContainer}>
                      {hasAvailableConnections && (
                        <div onClick={toggleAddOrganiserModal}>
                          <svg
                            className={classes.addIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="gray"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 12h14m-7 7V5"
                            />
                          </svg>
                        </div>
                      )}
                      {/* Only show edit icon if there are hosts besides the owner */}
                      {hosts.length > 1 && (
                        <div onClick={() => toggleEditOrganiserModal()}>
                          <svg
                            className={classes.experienceIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="gray"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {showEditOrganiserModal && (
                  <EditOrganisersModal
                    onClose={async (result) => {
                      if (result === "success") {
                        await fetchHosts();
                        await checkAvailableConnections();
                      }
                      toggleEditOrganiserModal(); // Use the new toggle function
                    }}
                    eventData={eventData}
                    currentUser={currentUser}
                    onOrganiserRemoved={async (count) => {
                      setRemoveCount(count);
                      setCurrentModalType("host");
                      setShowRemovePopup(true);
                      await checkAvailableConnections();
                    }}
                  />
                )}
                {showRemovePopup && currentModalType === "host" && (
                  <RemovePopup
                    onClose={() => {
                      setShowRemovePopup(false);
                    }}
                    count={removeCount}
                    type="host"
                  />
                )}
                {showRemovePopup && currentModalType === "attendee" && (
                  <RemovePopup
                    onClose={() => {
                      setShowRemovePopup(false);
                    }}
                    count={removeCount}
                    type="attendee"
                  />
                )}

                {hosts.slice(0, visibleHosts).map((host) => (
                  <div className={classes.userContainer}>
                    <img
                      src={host.userData?.profileImage || defaultImage}
                      alt={`${host.userData?.firstName} ${host.userData?.lastName}`}
                      className={classes.userImage}
                      onClick={() => navigate(`/${host.userData?.urlSlug}`)}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = defaultImage;
                      }}
                    />
                    <div className={classes.userInfo}>
                      <div className={classes.userNameContainer}>
                        <span
                          className={classes.userName}
                          onClick={() => navigate(`/${host.userData?.urlSlug}`)}
                        >
                          {host.userData?.firstName} {host.userData?.lastName}
                        </span>
                        <span className={classes.userConnectionType}>
                          • {host.connectionType}
                        </span>
                      </div>
                      {host.userData?.bio && (
                        <div className={classes.userBio}>
                          {host.userData.bio}
                        </div>
                      )}
                      {host.userData?.link && (
                        <a
                          href={host.userData.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.userLink}
                        >
                          <span>
                            {host.userData.linkText || host.userData.link}
                          </span>
                          <svg
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      )}
                      {host.isOwner && (
                        <div className={classes.userBadge}>
                          <svg
                            class="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12 2c-.791 0-1.55.314-2.11.874l-.893.893a.985.985 0 0 1-.696.288H7.04A2.984 2.984 0 0 0 4.055 7.04v1.262a.986.986 0 0 1-.288.696l-.893.893a2.984 2.984 0 0 0 0 4.22l.893.893a.985.985 0 0 1 .288.696v1.262a2.984 2.984 0 0 0 2.984 2.984h1.262c.261 0 .512.104.696.288l.893.893a2.984 2.984 0 0 0 4.22 0l.893-.893a.985.985 0 0 1 .696-.288h1.262a2.984 2.984 0 0 0 2.984-2.984V15.7c0-.261.104-.512.288-.696l.893-.893a2.984 2.984 0 0 0 0-4.22l-.893-.893a.985.985 0 0 1-.288-.696V7.04a2.984 2.984 0 0 0-2.984-2.984h-1.262a.985.985 0 0 1-.696-.288l-.893-.893A2.984 2.984 0 0 0 12 2Zm3.683 7.73a1 1 0 1 0-1.414-1.413l-4.253 4.253-1.277-1.277a1 1 0 0 0-1.415 1.414l1.985 1.984a1 1 0 0 0 1.414 0l4.96-4.96Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          Owner
                        </div>
                      )}
                    </div>
                  </div>
                ))}

                {/* Show More/Less button */}
                {hosts.length > 3 && (
                  <div
                    className={classes.showAllExperiences}
                    onClick={() => {
                      if (visibleHosts >= hosts.length) {
                        setVisibleHosts(3);
                      } else {
                        setVisibleHosts(
                          Math.min(
                            visibleHosts + visibleHosts * 2,
                            hosts.length
                          )
                        );
                      }
                    }}
                  >
                    {visibleHosts >= hosts.length ? "Show less" : "Show more"}
                    {visibleHosts >= hosts.length ? (
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m5 15 7-7 7 7"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 9-7 7-7-7"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </div>
              {showAddOrganiserModal && (
                <AddOrganisersModal
                  onClose={toggleAddOrganiserModal}
                  eventData={eventData}
                  currentUser={currentUser}
                  onInviteSent={(count) => {
                    // Change prop name to match AddAttendeeModal
                    setInviteCount(count);
                    setShowInvitePopup(true);
                  }}
                />
              )}
              {showInvitePopup && (
                <InvitePopup
                  onClose={handleInvitePopupClose}
                  isModalOpen={showAddOrganiserModal}
                  count={inviteCount} // Pass the count
                />
              )}
              {(attendees.length > 0 ||
                isOwner ||
                hosts.some((host) => host.id === currentUser?.uid)) && (
                <div className={classes.card}>
                  <div
                    className={`${classes.cardTitle} ${
                      attendees.length === 0 ? classes.emptyCardTitle : ""
                    }`}
                  >
                    Attendees
                    {(isOwner ||
                      hosts.some(
                        (host) =>
                          host.id === currentUser?.uid &&
                          host.status === "active"
                      )) && (
                      <div className={classes.iconContainer}>
                        {hasAvailableAttendeesConnections && (
                          <div onClick={toggleAddAttendeeModal}>
                            <svg
                              className={classes.addIcon}
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="36"
                              height="36"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 12h14m-7 7V5"
                              />
                            </svg>
                          </div>
                        )}
                        {attendees.length > 0 && (
                          <div onClick={toggleEditAttendeeModal}>
                            <svg
                              className={classes.experienceIcon}
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {attendees.length > 0 ? (
                    <>
                      {attendees
                        .slice(0, displayedAttendees)
                        .map((attendee) => (
                          <div
                            key={attendee.id}
                            className={classes.userContainer}
                          >
                            <img
                              src={
                                attendee.userData?.profileImage || defaultImage
                              }
                              alt={`${attendee.userData?.firstName} ${attendee.userData?.lastName}`}
                              className={classes.userImage}
                              onClick={() =>
                                navigate(`/${attendee.userData?.urlSlug}`)
                              }
                              style={{ cursor: "pointer" }}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = defaultImage;
                              }}
                            />
                            <div className={classes.userInfo}>
                              <div className={classes.userNameContainer}>
                                <div
                                  className={classes.userName}
                                  onClick={() =>
                                    navigate(`/${attendee.userData?.urlSlug}`)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {attendee.userData?.firstName}{" "}
                                  {attendee.userData?.lastName}
                                </div>
                                <span className={classes.userConnectionType}>
                                  • {attendee.connectionType}
                                </span>
                              </div>
                              {attendee.userData?.bio && (
                                <div className={classes.userBio}>
                                  {attendee.userData.bio}
                                </div>
                              )}
                              {attendee.userData?.link && (
                                <a
                                  href={attendee.userData.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={classes.userLink}
                                >
                                  <span>
                                    {attendee.userData.linkText ||
                                      attendee.userData.link}
                                  </span>
                                  <svg
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      stroke="orangeRed"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                    />
                                  </svg>
                                </a>
                              )}
                            </div>
                          </div>
                        ))}
                      {attendees.length > 3 && (
                        <div
                          className={classes.showAllExperiences}
                          onClick={() => {
                            if (displayedAttendees >= attendees.length) {
                              setDisplayedAttendees(3);
                            } else {
                              setDisplayedAttendees(
                                Math.min(
                                  displayedAttendees + displayedAttendees * 2,
                                  attendees.length
                                )
                              );
                            }
                          }}
                        >
                          {displayedAttendees >= attendees.length
                            ? "Show less"
                            : "Show more"}
                          {displayedAttendees >= attendees.length ? (
                            <svg
                              className="w-6 h-6"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m5 15 7-7 7 7"
                              />
                            </svg>
                          ) : (
                            <svg
                              className="w-6 h-6"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m19 9-7 7-7-7"
                              />
                            </svg>
                          )}
                        </div>
                      )}
                    </>
                  ) : isOwner ||
                    hosts.some((host) => host.id === currentUser?.uid) ? (
                    <>
                      <div>
                        <p className={classes.defaultText}>
                          {connectionsCount === 0
                            ? "You can only invite your connections to become attendees of this event. Connect with like-minded people in the startup community and build meaningful relationships."
                            : "Get more people involved! Invite your connections to attend this event and help create meaningful connections."}
                        </p>
                      </div>
                      {connectionsCount === 0 && (
                        <div className={classes.people}>
                          <div
                            className={classes.button}
                            style={{ width: "fit-content" }}
                            onClick={() => navigate("/people")}
                          >
                            <svg
                              className="w-6 h-6"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                                clipRule="evenodd"
                              />
                            </svg>
                            People
                          </div>
                        </div>
                      )}
                      <div className={classes.noPostBorder}></div>
                      <div className={classes.userContainer}>
                        <img
                          src={greyProfileImage}
                          alt="Preview attendee"
                          className={classes.userImage}
                        />
                        <div className={classes.userInfo}>
                          <div className={classes.userNameContainer}>
                            <div className={classes.noStartupRole}>
                              Username
                            </div>
                            <span className={classes.userConnectionType}>
                              • Direct
                            </span>
                          </div>
                          <div className={classes.noStartupName}>Headline</div>
                          <div className={classes.noUserLink}>
                            Link
                            <svg
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              )}
            </>
          )}
          {!isBlocked && renderBadgesCard()}
        </div>
        <div className={classes.rightCards}>
          {!isBlocked && (
            <>
              {shouldShowContent("tags") && (
                <div className={classes.card}>
                  <div
                    className={`${classes.cardTitle} ${
                      !eventData.tags || eventData.tags.length === 0
                        ? classes.emptyCardTitle
                        : ""
                    }`}
                  >
                    Tags
                    {shouldShowContent("editButtons") && (
                      <div onClick={toggleTagsModal}>
                        <svg
                          className={classes.closeIcon}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="gray"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div className={classes.cardAbout}>
                    {eventData.tags && eventData.tags.length > 0 ? (
                      <>
                        {eventData.tags
                          .slice(0, visibleTags)
                          .map((tag, index) => (
                            <div key={index} className={classes.skillContainer}>
                              <svg
                                className="w-6 h-6 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                              </svg>
                              <span className={classes.skillText}>{tag}</span>
                            </div>
                          ))}
                      </>
                    ) : (
                      isOwner && (
                        <div className={classes.emptyContainer}>
                          <div className={classes.tagDefaultText}>
                            Add tags to help people find and understand your
                            event.
                          </div>
                          <div className={classes.defaultContainer}>
                            <div className={classes.emptySkillContainer}>
                              <svg
                                className="w-6 h-6 text-gray-800 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M18.045 3.007 12.31 3a1.965 1.965 0 0 0-1.4.585l-7.33 7.394a2 2 0 0 0 0 2.805l6.573 6.631a1.957 1.957 0 0 0 1.4.585 1.965 1.965 0 0 0 1.4-.585l7.409-7.477A2 2 0 0 0 21 11.479v-5.5a2.972 2.972 0 0 0-2.955-2.972Zm-2.452 6.438a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                              </svg>
                              <span className={classes.skillText}>Tag</span>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  {eventData.tags && eventData.tags.length > 9 && (
                    <div
                      className={classes.showAllExperiences}
                      onClick={() => {
                        if (visibleTags >= eventData.tags.length) {
                          setVisibleTags(9);
                        } else {
                          setVisibleTags(
                            Math.min(
                              visibleTags + visibleTags * 2,
                              eventData.tags.length
                            )
                          );
                        }
                      }}
                    >
                      {visibleTags >= eventData.tags.length
                        ? "Show less"
                        : "Show more"}
                      {visibleTags >= eventData.tags.length ? (
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m5 15 7-7 7 7"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 9-7 7-7-7"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
};

export default Event;
