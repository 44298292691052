import MainNavbar from "../../../components/Navbars/MainNavbar";
import defaultImage from "../../../assets/icons/profileImage.jpg";
import { useChat } from "../../../contexts/ChatContext";
import ChatModal from "../../../components/Modals/ChatModal";
import startupImage from "../../../assets/images/startupImage.jpg";
import FollowModal from "../../../components/Modals/FollowModal";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./UserProfile.module.css";
import { isEventClosed } from "../../../utils/timeUtils";
import greyEventImage from "../../../assets/icons/greyEventImage.jpg";
import EventButtons from "../../../components/Buttons/EventButtons";
import eventImage from "../../../assets/icons/eventImage.jpg";
import CommunityButtons from "../../../components/Buttons/CommunityButtons";
import communityImage from "../../../assets/icons/communityImage.jpg";
import profileImage from "../../../assets/icons/profileImage.jpg";
import { useLocation, useParams, Link } from "react-router-dom";
import StartupButtons from "../../../components/Buttons/StartupButtons";
import ActivityButtons from "../../../components/Buttons/ActivityButtons";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import RepostsModal from "../../../components/Modals/RepostsModal";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  collection,
  addDoc,
  query,
  where,
  serverTimestamp,
  getDocs,
  writeBatch,
  orderBy,
  limit,
  onSnapshot,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { firestore } from "../../../firebase";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import ActivityDropdown from "../../../components/Dropdowns/ActivityDropdown";
import monthsList from "../../../utils/monthsList";
import ConnectionsModal from "../../../components/Modals/ConnectionsModal";
import MutualConnectionsModal from "../../../components/Modals/MutualConnectionsModal";
import { useNavigate } from "react-router-dom";
import ReactionsModal from "../../../components/Modals/ReactionsModal";
import { getTopThreeEmojis } from "../../../utils/emojiUtils";

function UserProfile() {
  const [visibleSkills, setVisibleSkills] = useState(9);
  const [visibleInterests, setVisibleInterests] = useState(9);
  const [visibleExperiences, setVisibleExperiences] = useState(3);
  const [visibleEducation, setVisibleEducation] = useState(3);
  const [eventCounts, setEventCounts] = useState({
    Owner: 0,
    Organising: 0,
    Attending: 0,
    Attended: 0,
  });
  const [isBlocked, setIsBlocked] = useState(false);
  const [actionMessage, setActionMessage] = useState(null);
  const [showChatModal, setShowChatModal] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [userProfiles, setUserProfiles] = useState({});
  const { markChatAsRead } = useChat();
  const [eventType, setEventType] = useState(null);
  const [loadingEventType, setLoadingEventType] = useState(false);
  const [displayedEventCount, setDisplayedEventCount] = useState(3);
  const [events, setEvents] = useState([]);
  const [eventSearchTerm, setEventSearchTerm] = useState("");
  const [isLoadingEvents, setIsLoadingEvents] = useState(true);
  const [communities, setCommunities] = useState([]);
  const [communityCounts, setCommunityCounts] = useState({
    Owner: 0,
    Organiser: 0, // Changed from Moderator to match database
    Member: 0,
  });

  const [hasUserBlockedThem, setHasUserBlockedThem] = useState(false); // Current user has blocked target
  const [communityType, setCommunityType] = useState(null);
  const [loadingCommunityType, setLoadingCommunityType] = useState(false);
  const [displayedCommunities, setDisplayedCommunities] = useState(3);
  const [communitySearchTerm, setCommunitySearchTerm] = useState("");
  const [startupCounts, setStartupCounts] = useState({
    Owner: 0,
    TeamMember: 0,
    Supported: 0,
    Following: 0,
  });
  const [startupType, setStartupType] = useState(null);
  const [loadingStartupType, setLoadingStartupType] = useState(false);
  const [startups, setStartups] = useState([]);
  const [displayedStartups, setDisplayedStartups] = useState(3);
  const [startupSearchTerm, setStartupSearchTerm] = useState("");
  const [followingCount, setFollowingCount] = useState(0);
  const [showFollowModal, setShowFollowModal] = useState(false);
  const [followModalScrollPosition, setFollowModalScrollPosition] = useState(0);
  const [isFollowModalOpen, setIsFollowModalOpen] = useState(false);
  const [showAllStartups, setShowAllStartups] = useState(false);
  const [loadingStartups, setLoadingStartups] = useState(false);
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const location = useLocation();
  const { userId } = useParams();
  const [commentedPosts, setCommentedPosts] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [displayedComments, setDisplayedComments] = useState(3);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(location.state?.userData || {});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [currentUserImage, setCurrentUserImage] = useState(profileImage);
  const [connectionStatus, setConnectionStatus] = useState("Connect");
  const [currentUser, setCurrentUser] = useState(null);
  const [connectionCount, setConnectionCount] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [hasReceivedRequest, setHasReceivedRequest] = useState(false);
  const [connectionType, setConnectionType] = useState("Extended");
  const [showConnectionsModal, setShowConnectionsModal] = useState(false);
  const { urlSlug } = useParams();
  const [posts, setPosts] = useState([]);
  const [displayedPosts, setDisplayedPosts] = useState(3);
  const [activityType, setActivityType] = useState("Posts");
  const [education, setEducation] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [reactions, setReactions] = useState([]);
  const [displayedReactions, setDisplayedReactions] = useState(3);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [loadingReactions, setLoadingReactions] = useState(false);
  const [loadingReposts, setLoadingReposts] = useState(false);
  const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);
  const [reposts, setReposts] = useState([]);
  const [displayedReposts, setDisplayedReposts] = useState(3);
  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [activePostReposts, setActivePostReposts] = useState(null);
  const [shareButtonText, setShareButtonText] = useState("Share");
  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);
  const [showFullIntro, setShowFullIntro] = useState(false);
  const [showAllSkills, setShowAllSkills] = useState(false);
  const [showAllInterests, setShowAllInterests] = useState(false);
  const [showAllExperiences, setShowAllExperiences] = useState(false);
  const [showAllEducation, setShowAllEducation] = useState(false);

  const toggleShowAllSkills = () => setShowAllSkills(true);
  const toggleShowAllInterests = () => setShowAllInterests(true);
  const toggleShowAllExperiences = () => setShowAllExperiences(true);
  const toggleShowAllEducation = () => setShowAllEducation(true);

  const [modalScrollPosition, setModalScrollPosition] = useState(0);
  const [skillsModalScrollPosition, setSkillsModalScrollPosition] = useState(0);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [connectionsModalScrollPosition, setConnectionsModalScrollPosition] =
    useState(0);
  const [isConnectionsModalOpen, setIsConnectionsModalOpen] = useState(false);
  const [
    mutualConnectionsModalScrollPosition,
    setMutualConnectionsModalScrollPosition,
  ] = useState(0);
  const [isMutualConnectionsModalOpen, setIsMutualConnectionsModalOpen] =
    useState(false);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const [connections, setConnections] = useState([]);
  const [displayedConnections, setDisplayedConnections] = useState(3);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoadingConnections, setIsLoadingConnections] = useState(true);

  const handleEventTypeChange = (type) => {
    setLoadingEventType(true);
    setEventType(type);
    setTimeout(() => {
      setLoadingEventType(false);
    }, 300);
  };

  const hasEventContent = useMemo(() => {
    return Object.values(eventCounts).some((count) => count > 0);
  }, [eventCounts]);

  // Update the filteredEvents useMemo in UserProfile.js to match Profile.js:

  const filteredEvents = useMemo(() => {
    if (!events || !Array.isArray(events)) {
      return [];
    }

    const parseEventDateTime = (event) => {
      try {
        let day, month, year;
        const [hours, minutes] = event.endTime.match(/(\d+):(\d+)/).slice(1);

        if (event.endDate.includes("-")) {
          [year, month, day] = event.endDate.split("-");
        } else {
          [day, month, year] = event.endDate.split("/");
        }

        const date = new Date(
          Number(year),
          Number(month) - 1,
          Number(day),
          Number(hours),
          Number(minutes)
        );

        return date;
      } catch (error) {
        return new Date(0);
      }
    };

    const isEventInPast = (event) => {
      if (!event.endDate || !event.endTime || !event.timezone) return false;
      return isEventClosed({
        endDate: event.endDate,
        endTime: event.endTime,
        timezone: event.timezone,
      });
    };

    return events
      .filter((event) => {
        const eventClosed = isEventInPast(event);

        switch (eventType) {
          case "Owner":
            return event.membershipType === "owner";
          case "Organiser":
            return event.membershipType === "Organiser";
          case "Attending":
            return event.membershipType === "attending" && !eventClosed;
          case "Attended":
            return event.membershipType === "attending" && eventClosed;
          default:
            return false;
        }
      })
      .sort((a, b) => {
        if (eventType === "Attending") {
          const aDate = parseEventDateTime(a);
          const bDate = parseEventDateTime(b);
          return aDate.getTime() - bDate.getTime(); // Nearest first
        } else if (eventType === "Attended") {
          const aDate = parseEventDateTime(a);
          const bDate = parseEventDateTime(b);
          return bDate.getTime() - aDate.getTime(); // Most recent first
        } else if (["Owner", "Organiser"].includes(eventType)) {
          const now = new Date();
          const aDate = parseEventDateTime(a);
          const bDate = parseEventDateTime(b);
          const aInFuture = aDate > now;
          const bInFuture = bDate > now;

          // If events are in different groups (future vs past), sort by group first
          if (aInFuture !== bInFuture) {
            return aInFuture ? -1 : 1; // Future events come first
          }

          // Within the same group, sort by date
          if (aInFuture) {
            // For future events, sort by nearest first
            return aDate.getTime() - bDate.getTime();
          } else {
            // For past events, sort by most recent first
            return bDate.getTime() - aDate.getTime();
          }
        }

        return 0;
      });
  }, [events, eventType]);

  const handleCommunityTypeChange = (type) => {
    setLoadingCommunityType(true);
    setCommunityType(type);
    setTimeout(() => {
      setLoadingCommunityType(false);
    }, 300);
  };

  const hasCommunityContent = useMemo(() => {
    return Object.values(communityCounts).some((count) => count > 0);
  }, [communityCounts]);

  const filteredCommunities = useMemo(() => {
    if (!communities) return [];

    const filtered = communities.filter((community) => {
      switch (communityType) {
        case "Owner":
          return community.membershipType === "owner";
        case "Moderator":
          return community.membershipType === "moderator";
        case "Member":
          return community.membershipType === "member";
        default:
          return false;
      }
    });

    return filtered.sort((a, b) => {
      if (communityType === "Owner") {
        const aTime = a.createdAt?.toDate?.() || new Date(a.createdAt);
        const bTime = b.createdAt?.toDate?.() || new Date(b.createdAt);
        return bTime - aTime;
      } else if (communityType === "Moderator") {
        const aTime = a.acceptedAt?.toDate?.() || new Date(a.acceptedAt);
        const bTime = b.acceptedAt?.toDate?.() || new Date(b.acceptedAt);
        return bTime - aTime;
      } else if (communityType === "Member") {
        const aTime = a.joinedAt?.toDate?.() || new Date(a.joinedAt);
        const bTime = b.joinedAt?.toDate?.() || new Date(b.joinedAt);
        return bTime - aTime;
      }
      return 0;
    });
  }, [communities, communityType]);

  const handleStartupTypeChange = (type) => {
    setLoadingStartupType(true);
    setStartupType(type);
    setTimeout(() => {
      setLoadingStartupType(false);
    }, 300);
  };

  const filteredStartups = useMemo(() => {
    if (!startups) return [];

    const filtered = startups.filter((startup) => {
      switch (startupType) {
        case "Owner":
          return startup.membershipType === "owner";
        case "TeamMember":
          return startup.membershipType === "member";
        case "Supported":
          return startup.supported;
        case "Following":
          return startup.following;
        default:
          return false;
      }
    });

    if (startupType === "Supported") {
      const uniqueStartups = filtered.reduce((acc, current) => {
        const existing = acc.find((item) => item.id === current.id);

        if (!existing) {
          acc.push(current);
        } else {
          const currentDonationDate =
            current.supportedAt?.toDate?.() || new Date(current.supportedAt);
          const existingDonationDate =
            existing.supportedAt?.toDate?.() || new Date(existing.supportedAt);

          if (currentDonationDate > existingDonationDate) {
            const index = acc.findIndex((item) => item.id === current.id);
            acc[index] = current;
          }
        }

        return acc;
      }, []);

      return uniqueStartups.sort((a, b) => {
        const aDonationDate =
          a.supportedAt?.toDate?.() || new Date(a.supportedAt);
        const bDonationDate =
          b.supportedAt?.toDate?.() || new Date(b.supportedAt);
        return bDonationDate - aDonationDate;
      });
    }

    return filtered.sort((a, b) => {
      if (startupType === "Following") {
        const aTime = a.followedAt?.toDate?.() || new Date(a.followedAt);
        const bTime = b.followedAt?.toDate?.() || new Date(b.followedAt);
        return bTime - aTime;
      } else if (startupType === "TeamMember") {
        const aTime = a.acceptedAt?.toDate?.() || new Date(a.acceptedAt);
        const bTime = b.acceptedAt?.toDate?.() || new Date(b.acceptedAt);
        return bTime - aTime;
      } else if (startupType === "Owner") {
        const aMonth = monthsList.indexOf(a.monthFounded);
        const bMonth = monthsList.indexOf(b.monthFounded);
        const aDate = new Date(a.yearFounded, aMonth);
        const bDate = new Date(b.yearFounded, bMonth);

        if (aDate.getTime() !== bDate.getTime()) {
          return bDate.getTime() - aDate.getTime();
        }
        return b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime();
      }
      return 0;
    });
  }, [startups, startupType]);

  const hasStartupContent = useMemo(() => {
    return Object.values(startupCounts).some((count) => count > 0);
  }, [startupCounts]);

  // Add the search handler function
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Add the filtered connections memo
  const filteredConnections = useMemo(() => {
    return connections.filter((connection) => {
      if (!connection?.userData?.firstName || !connection?.userData?.lastName) {
        return false;
      }
      const fullName =
        `${connection.userData.firstName} ${connection.userData.lastName}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    });
  }, [connections, searchTerm]);

  const checkConnectionType = useCallback(
    async (currentUserId, targetUserId) => {
      if (!currentUserId || !targetUserId) return "Extended";
      if (currentUserId === targetUserId) return "You";

      try {
        // Check for direct connection
        const userConnectionRef = doc(
          firestore,
          `users/${currentUserId}/connections/${targetUserId}`
        );
        const userConnectionDoc = await getDoc(userConnectionRef);

        if (
          userConnectionDoc.exists() &&
          userConnectionDoc.data().status === "Connected"
        ) {
          return "Direct";
        }

        // Check for indirect connection through mutual connections
        const currentUserConnectionsRef = collection(
          firestore,
          `users/${currentUserId}/connections`
        );
        const currentUserConnections = await getDocs(
          query(currentUserConnectionsRef, where("status", "==", "Connected"))
        );

        // Check each of current user's connections to see if they're connected to the target user
        for (const conn of currentUserConnections.docs) {
          const indirectConnectionRef = doc(
            firestore,
            `users/${conn.id}/connections/${targetUserId}`
          );
          const indirectConnectionDoc = await getDoc(indirectConnectionRef);

          if (
            indirectConnectionDoc.exists() &&
            indirectConnectionDoc.data().status === "Connected"
          ) {
            return "Indirect";
          }
        }

        return "Extended";
      } catch (error) {
        return "Extended";
      }
    },
    []
  );

  const fetchConnections = useCallback(async () => {
    // Get the profile owner's ID either from location state or userData
    const profileOwnerId = location.state?.userData?.objectID || userData.id;

    if (!profileOwnerId) {
      setIsLoadingConnections(false);
      return;
    }

    try {
      const connectionsRef = collection(
        firestore,
        `users/${profileOwnerId}/connections`
      );
      const connectionsQuery = query(
        connectionsRef,
        where("status", "==", "Connected")
      );
      const connectionsSnapshot = await getDocs(connectionsQuery);

      const connectionsData = await Promise.all(
        connectionsSnapshot.docs.map(async (connectionDoc) => {
          const connectionData = connectionDoc.data();
          if (connectionData.status !== "Connected") {
            return null;
          }

          const userDocRef = doc(firestore, "users", connectionDoc.id);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            const connectionType = await checkConnectionType(
              currentUser?.uid,
              connectionDoc.id
            );

            // Check for reverse connection
            const reverseConnectionRef = doc(
              firestore,
              `users/${connectionDoc.id}/connections/${profileOwnerId}`
            );
            const reverseConnectionDoc = await getDoc(reverseConnectionRef);

            if (
              !reverseConnectionDoc.exists() ||
              reverseConnectionDoc.data().status !== "Connected"
            ) {
              return null;
            }

            return {
              id: connectionDoc.id,
              userData: {
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || "",
                bio: userData.bio || "",
                link: userData.link || "",
                linkText: userData.linkText || "",
                urlSlug: userData.urlSlug || "",
              },
              connectionType,
              connectedAt: connectionData.connectedAt?.toDate() || new Date(0),
            };
          }
          return null;
        })
      );

      const validConnections = connectionsData
        .filter(Boolean)
        .sort((a, b) => b.connectedAt - a.connectedAt);

      setConnections(validConnections);
      setConnectionCount(validConnections.length);
    } catch (error) {
      setConnections([]);
    } finally {
      setIsLoadingConnections(false);
    }
  }, [
    location.state?.userData?.objectID,
    userData.id,
    currentUser?.uid,
    checkConnectionType,
  ]);

  // Update the useEffect to fetch connections when the profile data is loaded
  useEffect(() => {
    if (userData.id || location.state?.userData?.objectID) {
      fetchConnections();
    }
  }, [userData.id, location.state?.userData?.objectID, fetchConnections]);

  const [activityCounts, setActivityCounts] = useState({
    posts: 0,
    reactions: 0,
    comments: 0,
    reposts: 0,
  });

  const handleShare = useCallback(() => {
    const profileUrl = `${window.location.origin}/${userData.urlSlug}`;
    navigator.clipboard.writeText(profileUrl).then(() => {
      setShareButtonText("Copied");
      setTimeout(() => {
        setShareButtonText("Share");
      }, 5000);
    });
  }, [userData.urlSlug]);

  const getOriginalPostRef = async (originalPosterId, postId) => {
    try {
      // For startup posts (originalPosterId starts with "startup_")
      if (originalPosterId.startsWith("startup_")) {
        const startupId = originalPosterId.replace("startup_", "");

        // Find the startup owner
        const usersRef = collection(firestore, "users");
        const usersSnapshot = await getDocs(usersRef);

        for (const userDoc of usersSnapshot.docs) {
          const startupRef = doc(
            firestore,
            `users/${userDoc.id}/startups/${startupId}`
          );
          const startupSnap = await getDoc(startupRef);

          if (startupSnap.exists()) {
            // Found the startup, get its post
            const postRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/posts/${postId}`
            );
            const postSnap = await getDoc(postRef);

            if (postSnap.exists()) {
              return postSnap;
            }
          }
        }
      } else {
        // For user posts (check both personal posts and startup posts)

        // First check user's startups
        const startupsRef = collection(
          firestore,
          `users/${originalPosterId}/startups`
        );
        const startupsSnapshot = await getDocs(startupsRef);

        // Check each startup's posts
        for (const startupDoc of startupsSnapshot.docs) {
          const postRef = doc(
            firestore,
            `users/${originalPosterId}/startups/${startupDoc.id}/posts/${postId}`
          );
          const postSnap = await getDoc(postRef);

          if (postSnap.exists()) {
            return postSnap;
          }
        }

        // If not found in startups, check user's personal posts
        const userPostRef = doc(
          firestore,
          `users/${originalPosterId}/posts/${postId}`
        );
        const userPostSnap = await getDoc(userPostRef);

        if (userPostSnap.exists()) {
          return userPostSnap;
        }
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  const fetchReactions = async () => {
    if (!userData.id) return;
    setLoadingReactions(true);
    try {
      const reactionsRef = collection(
        firestore,
        `users/${userData.id}/reactedPosts`
      );
      const reactionsQuery = query(
        reactionsRef,
        orderBy("reactedAt", "desc"),
        limit(20)
      );
      const reactionsSnapshot = await getDocs(reactionsQuery);

      const reactionsData = await Promise.all(
        reactionsSnapshot.docs.map(async (docSnapshot) => {
          const reactionData = docSnapshot.data();
          const originalPostSnap = await getOriginalPostRef(
            reactionData.originalPosterId,
            reactionData.postId
          );

          if (!originalPostSnap) return null;

          const originalPostData = originalPostSnap.data();
          return {
            id: docSnapshot.id,
            ...reactionData,
            originalPost: {
              id: reactionData.postId,
              ...originalPostData,
              postPreview: {
                mediaType: originalPostData.content?.type || null,
                mediaUrl: originalPostData.content?.url || null,
                text: originalPostData.text || "",
                thumbnail: originalPostData.content?.thumbnail || null,
              },
            },
            reactedAt: reactionData.reactedAt.toDate(),
            emoji: reactionData.emoji,
          };
        })
      );

      const validReactions = reactionsData.filter(
        (reaction) => reaction !== null
      );
      setReactions(validReactions);
    } catch (error) {
    } finally {
      setLoadingReactions(false);
    }
  };

  // Apply the same pattern to fetchReposts and fetchComments
  const fetchReposts = async () => {
    if (!userData.id) return;
    setLoadingReposts(true);
    try {
      const repostsRef = collection(firestore, `users/${userData.id}/reposts`);
      const repostsQuery = query(
        repostsRef,
        orderBy("repostedAt", "desc"),
        limit(20)
      );
      const repostsSnapshot = await getDocs(repostsQuery);

      const repostsData = await Promise.all(
        repostsSnapshot.docs.map(async (docSnapshot) => {
          const repostData = docSnapshot.data();
          const originalPostSnap = await getOriginalPostRef(
            repostData.originalPosterId,
            repostData.postId
          );

          if (!originalPostSnap) return null;

          const originalPostData = originalPostSnap.data();
          return {
            id: docSnapshot.id,
            ...repostData,
            originalPost: {
              id: repostData.postId,
              ...originalPostData,
              postPreview: {
                mediaType: originalPostData.content?.type || null,
                mediaUrl: originalPostData.content?.url || null,
                text: originalPostData.text || "",
                thumbnail: originalPostData.content?.thumbnail || null,
              },
            },
            repostedAt: repostData.repostedAt.toDate(),
          };
        })
      );

      const validReposts = repostsData.filter((repost) => repost !== null);
      setReposts(validReposts);
    } catch (error) {
    } finally {
      setLoadingReposts(false);
    }
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const sortedStartups = useMemo(() => {
    if (!startups) return [];
    return [...startups].sort((a, b) => {
      // Convert month strings to numbers for comparison
      const aMonth = monthsList.indexOf(a.monthFounded);
      const bMonth = monthsList.indexOf(b.monthFounded);

      // Create dates for comparison
      const aDate = new Date(a.yearFounded, aMonth);
      const bDate = new Date(b.yearFounded, bMonth);

      // Compare dates first
      if (aDate.getTime() !== bDate.getTime()) {
        return bDate.getTime() - aDate.getTime(); // Newest first
      }

      // If dates are the same, compare creation timestamps
      return b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime();
    });
  }, [startups]);

  const toggleShowAllStartups = () => {
    setShowAllStartups(true);
  };

  const MAX_LENGTH = 125; // Same as in Profile component

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const renderTruncatedText = (text, id, htmlContent = null) => {
    if (!text) return null;

    const isExpanded = expandedDescriptions[id];

    if (text.length <= MAX_LENGTH) {
      return htmlContent ? (
        <p
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
          onClick={handleLinkClick}
        />
      ) : (
        <p className={classes.description}>{text}</p>
      );
    }

    if (isExpanded) {
      return htmlContent ? (
        <p
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: text }}
          onClick={handleLinkClick}
        />
      ) : (
        <p className={classes.description}>{text}</p>
      );
    }

    const truncatedText = text.slice(0, MAX_LENGTH).trim();
    return (
      <p className={classes.description}>
        {htmlContent ? (
          <span
            dangerouslySetInnerHTML={{ __html: truncatedText }}
            onClick={handleLinkClick}
          />
        ) : (
          truncatedText
        )}
        <span
          className={classes.moreButton}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleDescription(id);
          }}
        >
          ...more
        </span>
      </p>
    );
  };

  const handleReport = () => {
    setShowDropdown(false);
    setActionMessage({
      text: "Report submitted.",
      isReport: true,
    });
  };

  const handleBlock = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser || !userData.id) return;

    try {
      const batch = writeBatch(firestore);

      // 1. Handle existing connection if any
      if (connectionStatus === "Connected" || connectionStatus === "Pending") {
        const connectionRef = doc(
          firestore,
          `users/${currentUser.uid}/connections/${userData.id}`
        );
        const recipientConnectionRef = doc(
          firestore,
          `users/${userData.id}/connections/${currentUser.uid}`
        );

        batch.delete(connectionRef);
        batch.delete(recipientConnectionRef);

        // Delete connection notifications
        const currentUserNotificationsRef = collection(
          firestore,
          `users/${currentUser.uid}/notifications`
        );
        const profileUserNotificationsRef = collection(
          firestore,
          `users/${userData.id}/notifications`
        );

        const [currentUserNotifications, profileUserNotifications] =
          await Promise.all([
            getDocs(
              query(
                currentUserNotificationsRef,
                where("from", "in", [userData.id, currentUser.uid]),
                where("type", "in", [
                  "connection_request",
                  "connection_accepted",
                ])
              )
            ),
            getDocs(
              query(
                profileUserNotificationsRef,
                where("from", "in", [userData.id, currentUser.uid]),
                where("type", "in", [
                  "connection_request",
                  "connection_accepted",
                ])
              )
            ),
          ]);

        currentUserNotifications.forEach((doc) => batch.delete(doc.ref));
        profileUserNotifications.forEach((doc) => batch.delete(doc.ref));

        if (connectionStatus === "Connected") {
          setConnectionCount((prevCount) => prevCount - 1);
        }
      }

      // 2. Handle startups
      // Get all startups for both users
      const [currentUserStartups, targetUserStartups] = await Promise.all([
        getDocs(collection(firestore, `users/${currentUser.uid}/startups`)),
        getDocs(collection(firestore, `users/${userData.id}/startups`)),
      ]);

      // Remove followers and team members for current user's startups
      for (const startup of currentUserStartups.docs) {
        const followerRef = doc(
          firestore,
          `users/${currentUser.uid}/startups/${startup.id}/followers/${userData.id}`
        );
        const teamRef = doc(
          firestore,
          `users/${currentUser.uid}/startups/${startup.id}/team/${userData.id}`
        );
        batch.delete(followerRef);
        batch.delete(teamRef);
      }

      // Remove followers and team members for target user's startups
      for (const startup of targetUserStartups.docs) {
        const followerRef = doc(
          firestore,
          `users/${userData.id}/startups/${startup.id}/followers/${currentUser.uid}`
        );
        const teamRef = doc(
          firestore,
          `users/${userData.id}/startups/${startup.id}/team/${currentUser.uid}`
        );
        batch.delete(followerRef);
        batch.delete(teamRef);
      }

      // 3. Handle communities
      // Get all communities for both users
      const [currentUserCommunities, targetUserCommunities] = await Promise.all(
        [
          getDocs(
            collection(firestore, `users/${currentUser.uid}/communities`)
          ),
          getDocs(collection(firestore, `users/${userData.id}/communities`)),
        ]
      );

      // Remove moderators and members for current user's communities
      for (const community of currentUserCommunities.docs) {
        const moderatorRef = doc(
          firestore,
          `users/${currentUser.uid}/communities/${community.id}/moderators/${userData.id}`
        );
        const memberRef = doc(
          firestore,
          `users/${currentUser.uid}/communities/${community.id}/members/${userData.id}`
        );
        batch.delete(moderatorRef);
        batch.delete(memberRef);
      }

      // Remove moderators and members for target user's communities
      for (const community of targetUserCommunities.docs) {
        const moderatorRef = doc(
          firestore,
          `users/${userData.id}/communities/${community.id}/moderators/${currentUser.uid}`
        );
        const memberRef = doc(
          firestore,
          `users/${userData.id}/communities/${community.id}/members/${currentUser.uid}`
        );
        batch.delete(moderatorRef);
        batch.delete(memberRef);
      }

      // 4. Handle events
      // Get all events for both users
      const [currentUserEvents, targetUserEvents] = await Promise.all([
        getDocs(collection(firestore, `users/${currentUser.uid}/events`)),
        getDocs(collection(firestore, `users/${userData.id}/events`)),
      ]);

      // Remove hosts and attendees for current user's events
      for (const event of currentUserEvents.docs) {
        const hostRef = doc(
          firestore,
          `users/${currentUser.uid}/events/${event.id}/hosts/${userData.id}`
        );
        const attendeeRef = doc(
          firestore,
          `users/${currentUser.uid}/events/${event.id}/attendees/${userData.id}`
        );
        batch.delete(hostRef);
        batch.delete(attendeeRef);
      }

      // Remove hosts and attendees for target user's events
      for (const event of targetUserEvents.docs) {
        const hostRef = doc(
          firestore,
          `users/${userData.id}/events/${event.id}/hosts/${currentUser.uid}`
        );
        const attendeeRef = doc(
          firestore,
          `users/${userData.id}/events/${event.id}/attendees/${currentUser.uid}`
        );
        batch.delete(hostRef);
        batch.delete(attendeeRef);
      }

      // 5. Create the block
      const blockedUsersRef = collection(
        firestore,
        `users/${currentUser.uid}/blockedUsers`
      );
      const newBlockRef = doc(blockedUsersRef);
      batch.set(newBlockRef, {
        userId: userData.id,
        blockedAt: serverTimestamp(),
      });

      // 6. Commit all changes
      await batch.commit();

      // 7. Update UI states
      setHasUserBlockedThem(true);
      setIsBlocked(true);
      setConnectionStatus("Connect");
      setConnectionType("Blocked");
      setShowDropdown(false);
      setHasReceivedRequest(false);

      setActionMessage({
        text: (
          <>
            User blocked.{" "}
            <Link to="/settings" className={classes.viewBlockedLink}>
              Blocked users
            </Link>
          </>
        ),
        isBlock: true,
      });
    } catch (error) {}
  };

  const handleUnblock = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser || !userData.id) return;

    try {
      const targetUserBlockedRef = collection(
        firestore,
        `users/${userData.id}/blockedUsers`
      );
      const targetUserBlockedQuery = query(
        targetUserBlockedRef,
        where("userId", "==", currentUser.uid)
      );
      const targetUserBlockedSnap = await getDocs(targetUserBlockedQuery);
      const isBlockedByOtherUser = targetUserBlockedSnap.size > 0;

      const blockedUsersRef = collection(
        firestore,
        `users/${currentUser.uid}/blockedUsers`
      );
      const q = query(blockedUsersRef, where("userId", "==", userData.id));
      const querySnapshot = await getDocs(q);

      const batch = writeBatch(firestore);
      querySnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();

      setHasUserBlockedThem(false);
      setIsBlocked(isBlockedByOtherUser);
      setShowDropdown(false);

      if (!isBlockedByOtherUser) {
        const newConnectionType = await checkConnectionType(
          currentUser.uid,
          userData.id
        );
        setConnectionType(newConnectionType);

        await fetchAllData(userData.id, {
          setIsLoading,
          setIsLoadingConnections,
          setUserData,
          setExperiences,
          setEducation,
          fetchPosts,
          fetchComments,
          fetchReactions,
          fetchReposts,
          fetchActivityCounts,
          fetchConnections,
          setHasAttemptedLoad,
          setStartups,
          setStartupCounts,
          setStartupType,
          setCommunities,
          setCommunityCounts,
          setCommunityType,
          setLoadingCommunityType,
          setEvents,
          setEventCounts,
          setEventType,
          setLoadingEventType,
        });
      }

      setActionMessage({
        text: "User unblocked.",
        isBlock: false,
      });
    } catch (error) {}
  };

  const fetchComments = useCallback(async () => {
    if (!userData.id) return;
    setLoadingComments(true);
    try {
      const commentedPostsRef = collection(
        firestore,
        `users/${userData.id}/commentedPosts`
      );
      const commentedPostsQuery = query(
        commentedPostsRef,
        orderBy("lastCommentedAt", "desc"),
        limit(20)
      );
      const commentedPostsSnapshot = await getDocs(commentedPostsQuery);

      const commentedPostsData = await Promise.all(
        commentedPostsSnapshot.docs.map(async (docSnapshot) => {
          const data = docSnapshot.data();
          const originalPostSnap = await getOriginalPostRef(
            data.originalPosterId,
            data.postId
          );

          if (!originalPostSnap) return null;

          const postData = originalPostSnap.data();
          return {
            id: docSnapshot.id,
            ...data,
            originalPost: {
              id: data.postId,
              ...postData,
              postPreview: {
                mediaType: postData.content?.type || null,
                mediaUrl: postData.content?.url || null,
                text: postData.text || "",
                thumbnail: postData.content?.thumbnail || null,
              },
            },
            lastCommentedAt: data.lastCommentedAt.toDate(),
          };
        })
      );

      const validCommentedPosts = commentedPostsData.filter(
        (post) => post !== null
      );
      setCommentedPosts(validCommentedPosts);
    } catch (error) {
    } finally {
      setLoadingComments(false);
    }
  }, [userData.id]);

  const renderComments = () => {
    return (
      <>
        <div className={classes.posts}>
          {commentedPosts.length === 0 ? (
            <p className={classes.defaultText}>
              <span className={classes.userName}>{userData.firstName}</span> has
              no commented posts.
            </p>
          ) : (
            <>
              {commentedPosts
                .slice(0, displayedComments)
                .map((commentedPost, index) => (
                  <React.Fragment key={commentedPost.id}>
                    {index !== 0 && <div className={classes.postBorder}></div>}
                    <div>
                      <div className={classes.postHeader}>
                        <span className={classes.postAuthor}>
                          {userData.firstName} {userData.lastName}
                        </span>
                        <span className={classes.postMessage}>
                          {" "}
                          commented on this{" "}
                        </span>
                        <span className={classes.dot}>•</span>
                        <span>
                          {formatTimestamp(commentedPost.lastCommentedAt)}
                        </span>
                      </div>
                      <div
                        className={`${classes.post} ${
                          !commentedPost.originalPost.postPreview.mediaType &&
                          commentedPost.originalPost.postPreview.text
                            ? classes.textOnlyPost
                            : ""
                        }`}
                        onClick={() =>
                          handlePostClick(commentedPost.originalPost.id, true)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className={classes.postContent}>
                          {commentedPost.originalPost?.postPreview?.mediaType &&
                            renderFilePreview(
                              commentedPost.originalPost.postPreview
                            )}
                          {commentedPost.originalPost?.postPreview?.text && (
                            <p className={classes.textPreview}>
                              {truncateText(
                                commentedPost.originalPost.postPreview.text,
                                3
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {((commentedPost.originalPost?.reactions &&
                      Object.keys(commentedPost.originalPost.reactions).length >
                        0) ||
                      (commentedPost.originalPost?.reposts &&
                        Object.keys(commentedPost.originalPost.reposts).length >
                          0) ||
                      (commentedPost.originalPost?.commentCount &&
                        commentedPost.originalPost.commentCount > 0)) && (
                      <div className={classes.postInteractions}>
                        {commentedPost.originalPost?.reactions &&
                          Object.keys(commentedPost.originalPost.reactions)
                            .length > 0 && (
                            <ReactionsSummary
                              reactions={commentedPost.originalPost.reactions}
                              post={commentedPost.originalPost}
                              onClick={() =>
                                handleReactionsClick(
                                  commentedPost.originalPost.id,
                                  commentedPost.originalPost.reactions
                                )
                              }
                            />
                          )}
                        <div className={classes.rightInteractions}>
                          <PostInteractionCount
                            postId={commentedPost.originalPost?.id}
                            reposts={commentedPost.originalPost?.reposts}
                            commentCount={
                              commentedPost.originalPost?.commentCount
                            }
                            onClick={handleRepostsClick}
                            onCommentClick={() =>
                              handlePostClick(commentedPost.originalPost?.id)
                            }
                          />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </>
          )}
          {commentedPosts.length > 3 &&
            displayedComments < commentedPosts.length && (
              <div
                className={classes.showAllPosts}
                onClick={() => navigate(`/all-comments/${userData.urlSlug}`)}
              >
                Show all comments
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 12H5m14 0-4 4m4-4-4-4"
                  />
                </svg>
              </div>
            )}
        </div>
      </>
    );
  };

  // Add these functions
  const fetchPostsCount = useCallback(async (userId) => {
    const postsRef = collection(firestore, `users/${userId}/posts`);
    const snapshot = await getDocs(query(postsRef, limit(1)));
    return snapshot.size;
  }, []);

  const fetchReactionsCount = useCallback(async (userId) => {
    const reactionsRef = collection(firestore, `users/${userId}/reactedPosts`);
    const snapshot = await getDocs(query(reactionsRef, limit(1)));
    return snapshot.size;
  }, []);

  const fetchRepostsCount = useCallback(async (userId) => {
    const repostsRef = collection(firestore, `users/${userId}/reposts`);
    const snapshot = await getDocs(query(repostsRef, limit(1)));
    return snapshot.size;
  }, []);

  const fetchCommentsCount = useCallback(async (userId) => {
    const commentsRef = collection(firestore, `users/${userId}/commentedPosts`);
    const snapshot = await getDocs(query(commentsRef, limit(1)));
    return snapshot.size;
  }, []);

  const renderIntroText = () => {
    const introText = userData.introHtml || "You have no intro.";
    const maxLength = 250; // Adjust this value to set the desired text limit

    if (introText.length <= maxLength || showFullIntro) {
      return { text: introText, showMore: false };
    } else {
      const truncatedText = introText.slice(0, maxLength).trim();
      return { text: truncatedText, showMore: true };
    }
  };

  const storage = getStorage();

  const navigate = useNavigate();
  const optionsButtonRef = useRef(null);
  const dropdownRef = useRef(null);

  const positionDropdown = () => {
    if (optionsButtonRef.current && dropdownRef.current) {
      const buttonRect = optionsButtonRef.current.getBoundingClientRect();
      const dropdownRect = dropdownRef.current.getBoundingClientRect();

      // Position the dropdown below the button
      dropdownRef.current.style.top = `${buttonRect.bottom + window.scrollY}px`;
      dropdownRef.current.style.right = `${
        window.innerWidth - buttonRect.right
      }px`;
    }
  };

  // In UserProfile.js
  const handlePostClick = (postId, isComment = false) => {
    let navigationPath;
    let state = {
      fromUserProfile: true,
      userData: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        profileImage: userData.profileImage,
        urlSlug: userData.urlSlug, // Add this line
        id: userData.id, // Add this line too
      },
    };

    switch (activityType) {
      case "Comments":
        navigationPath = `/comment/${postId}`;
        break;
      case "Boosts":
        navigationPath = `/reaction/${postId}`;
        state.reactionEmoji = reactions.find(
          (r) => r.originalPost.id === postId
        )?.emoji;
        break;
      case "Reposts":
        navigationPath = `/repost/${postId}`;
        break;
      default: // "Posts" or any other case
        navigationPath = `/post/${postId}`;
    }

    navigate(navigationPath, { state });
  };

  const fetchEducation = async (userId) => {
    const educationRef = collection(firestore, `users/${userId}/education`);
    const educationSnapshot = await getDocs(educationRef);
    const educationData = educationSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setEducation(educationData);
  };

  const fetchExperiences = async (userId) => {
    const experiencesRef = collection(firestore, `users/${userId}/experiences`);
    const experiencesSnapshot = await getDocs(experiencesRef);
    const experiencesData = experiencesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setExperiences(experiencesData);
  };

  const handleRepostsClick = (postId, reposts) => {
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({ postId, reposts });
    setShowRepostsModal(true);
    setIsRepostsModalOpen(true);
  };

  const handleReactionsClick = (postId, reactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  const handleCloseReactionsModal = () => {
    setShowReactionsModal(false);
    setIsReactionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, reactionsModalScrollPosition);
    }, 0);
  };

  const handleCloseRepostsModal = () => {
    setShowRepostsModal(false);
    setIsRepostsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, repostsModalScrollPosition);
    }, 0);
  };

  const handleCloseConnectionsModal = () => {
    setShowConnectionsModal(false);
    setIsConnectionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, connectionsModalScrollPosition);
    }, 0);
  };

  const handleCloseMutualConnectionsModal = () => {
    setShowMutualConnectionsModal(false);
    setIsMutualConnectionsModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, mutualConnectionsModalScrollPosition);
    }, 0);
  };

  const [mutualConnections, setMutualConnections] = useState([]);
  const [showMutualConnectionsModal, setShowMutualConnectionsModal] =
    useState(false);

  const OptionsDropdown = () => {
    return (
      <div className={classes.optionsDropdown}>
        <button className={classes.optionButton} onClick={handleReport}>
          <svg
            className="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          Report
        </button>
        <button
          className={classes.optionButton}
          onClick={hasUserBlockedThem ? handleUnblock : handleBlock}
        >
          {hasUserBlockedThem ? (
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          ) : (
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          )}
          {hasUserBlockedThem ? "Unblock" : "Block"}
        </button>
      </div>
    );
  };

  const checkIfBlocked = async (currentUserId, profileUserId) => {
    if (!currentUserId || !profileUserId) return;

    try {
      // Check if current user has blocked the target user
      const currentUserBlockedRef = collection(
        firestore,
        `users/${currentUserId}/blockedUsers`
      );
      const currentUserBlockedQuery = query(
        currentUserBlockedRef,
        where("userId", "==", profileUserId)
      );
      const currentUserBlockedSnap = await getDocs(currentUserBlockedQuery);

      // Check if target user has blocked the current user
      const targetUserBlockedRef = collection(
        firestore,
        `users/${profileUserId}/blockedUsers`
      );
      const targetUserBlockedQuery = query(
        targetUserBlockedRef,
        where("userId", "==", currentUserId)
      );
      const targetUserBlockedSnap = await getDocs(targetUserBlockedQuery);

      // Set both states
      setHasUserBlockedThem(currentUserBlockedSnap.size > 0);
      setIsBlocked(
        currentUserBlockedSnap.size > 0 || targetUserBlockedSnap.size > 0
      );
    } catch (error) {}
  };

  const fetchPosts = useCallback(async () => {
    if (!userData.id) return;
    setLoadingPosts(true);
    try {
      const postsRef = collection(firestore, `users/${userData.id}/posts`);
      const postsSnapshot = await getDocs(
        query(postsRef, orderBy("createdAt", "desc"), limit(20))
      );

      const postsData = await Promise.all(
        postsSnapshot.docs.map(async (doc) => {
          const data = doc.data();
          let mediaUrl = null;

          if (data.content && data.content.type && data.content.url) {
            try {
              const storageRef = ref(storage, data.content.url);
              mediaUrl = await getDownloadURL(storageRef);
            } catch (error) {}
          }

          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt?.toDate() || new Date(),
            postPreview: {
              mediaType: data.content?.type || null,
              mediaUrl: mediaUrl,
              text: data.text,
              thumbnail: data.content?.thumbnail || null, // Add this line
            },
            reactions: data.reactions || {},
            reposts: data.reposts || {}, // Add this line
            commentCount: data.commentCount || 0,
          };
        })
      );

      postsData.sort((a, b) => b.createdAt - a.createdAt);
      setPosts(postsData);
    } finally {
      setLoadingPosts(false);
    }
  }, [userData.id, firestore, storage]);

  const PostInteractionCount = ({
    postId,
    reposts,
    commentCount,
    onClick,
    onCommentClick,
  }) => {
    const repostCount = Object.keys(reposts || {}).length;

    // If there are no interactions at all, return null instead of rendering anything
    if (repostCount === 0 && (!commentCount || commentCount === 0)) {
      return null;
    }

    return (
      <>
        {commentCount > 0 && (
          <span>
            {commentCount} comment{commentCount !== 1 ? "s" : ""}
          </span>
        )}
      </>
    );
  };

  // useEffect(() => {
  //   if (userData.id && activityType === "Posts") {
  //     fetchPosts();
  //   }
  // }, [userData.id, activityType, fetchPosts]);

  const handleActivityChange = (selectedActivity) => {
    // Set loading state immediately when activity changes
    switch (selectedActivity) {
      case "Posts":
        setLoadingPosts(true);
        break;
      case "Boosts":
        setLoadingReactions(true);
        break;
      case "Comments":
        setLoadingComments(true);
        break;
    }
    setActivityType(selectedActivity);
  };

  const truncateText = (text, lines) => {
    const words = text.split(" ");
    const truncated = words.slice(0, lines * 10).join(" ");
    return truncated.length < text.length ? `${truncated}...` : truncated;
  };

  const formatTimestamp = (date) => {
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) return "now";
    if (minutes < 60) return `${minutes}m`;
    if (hours < 24) return `${hours}h`;
    if (days < 7) return `${days}d`;
    if (weeks < 4) return `${weeks}w`;
    if (months < 12) return `${months}mo`;
    return `${years}y`;
  };

  const renderFilePreview = (postPreview) => {
    if (postPreview.mediaType === "image") {
      // Handle single image
      if (typeof postPreview.mediaUrl === "string") {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl} alt="Post preview" />
          </div>
        );
      }
      // Handle multiple images
      else if (
        Array.isArray(postPreview.mediaUrl) &&
        postPreview.mediaUrl.length > 0
      ) {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl[0]} alt="Post preview" />
            {postPreview.mediaUrl.length > 1 && (
              <div className={classes.multipleImagesIndicator}>
                +{postPreview.mediaUrl.length - 1}
              </div>
            )}
          </div>
        );
      }
    }

    if (postPreview.mediaType === "video") {
      return (
        <div className={classes.mediaPreview}>
          <video
            src={postPreview.mediaUrl}
            preload="metadata"
            style={{ display: "none" }} // Hide the video element completely
            muted
            playsInline
            onLoadedMetadata={(e) => {
              const video = e.target;
              const canvas = document.createElement("canvas");
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              video.currentTime = 0.1; // Set to 0.1s to avoid black frame

              video.addEventListener(
                "seeked",
                () => {
                  try {
                    // Draw the video frame to canvas
                    canvas
                      .getContext("2d")
                      .drawImage(video, 0, 0, canvas.width, canvas.height);

                    // Create and insert the static image
                    const img = new Image();
                    img.src = canvas.toDataURL();
                    img.style.width = "100%";
                    img.style.height = "100%";
                    img.style.objectFit = "cover";

                    // Find the container and insert the image
                    const container = video.parentNode;
                    container.insertBefore(img, container.firstChild);

                    // Cleanup
                    video.remove();
                  } catch (err) {
                    // If canvas operations fail, show the first frame of video as fallback
                    video.style.display = "block";
                    video.controls = false;
                    video.autoPlay = false;
                    video.style.pointerEvents = "none";
                  }
                },
                { once: true }
              );
            }}
          />
          <div className={classes.playButton}></div>
        </div>
      );
    }

    if (postPreview.mediaType === "pdf" || postPreview.fileName) {
      return (
        <div className={classes.filePreview}>
          <svg
            className="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      );
    }

    return null;
  };

  const fetchConnectionCount = async (userId) => {
    try {
      const connectionsRef = collection(
        firestore,
        `users/${userId}/connections`
      );
      const q = query(connectionsRef, where("status", "==", "Connected"));
      const querySnapshot = await getDocs(q);
      return querySnapshot.size;
    } catch (error) {
      return 0;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const userImage = userData.profileImage || profileImage;

  useEffect(() => {
    const fetchCurrentUserData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = await getDoc(doc(firestore, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setCurrentUserImage(userData.profileImage || profileImage);
          }
        } catch (error) {}
      }
    };

    fetchCurrentUserData();
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        if (userData.id) {
          checkConnectionStatus(user.uid, userData.id);
          fetchMutualConnections(user.uid, userData.id);
        }
      }
    });

    return () => unsubscribe();
  }, [userData.id]);

  const checkConnectionStatus = async (currentUserId, profileUserId) => {
    if (currentUserId === profileUserId) {
      setConnectionType("You");
      setConnectionStatus("Self");
      return;
    }

    const connectionRef = doc(
      firestore,
      `users/${currentUserId}/connections/${profileUserId}`
    );

    try {
      const connectionDoc = await getDoc(connectionRef);

      if (connectionDoc.exists()) {
        const status = connectionDoc.data().status;
        setConnectionStatus(status);
        setHasReceivedRequest(status === "Received");
        if (status === "Connected") {
          setConnectionType("Direct");
        } else {
          await checkIndirectConnection(currentUserId, profileUserId);
        }
      } else {
        setConnectionStatus("Connect");
        setHasReceivedRequest(false);
        await checkIndirectConnection(currentUserId, profileUserId);
      }
    } catch (error) {
      setConnectionStatus("Connect");
      setHasReceivedRequest(false);
      setConnectionType("Extended");
    }
  };

  const checkIndirectConnection = async (currentUserId, profileUserId) => {
    const currentUserConnections = await getDocs(
      collection(firestore, `users/${currentUserId}/connections`)
    );

    for (const conn of currentUserConnections.docs) {
      if (conn.data().status === "Connected") {
        const indirectConnectionRef = doc(
          firestore,
          `users/${conn.id}/connections/${profileUserId}`
        );
        const indirectConnectionDoc = await getDoc(indirectConnectionRef);
        if (
          indirectConnectionDoc.exists() &&
          indirectConnectionDoc.data().status === "Connected"
        ) {
          setConnectionType("Indirect");
          return;
        }
      }
    }

    setConnectionType("Extended");
  };

  const handleConnect = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser || !userData.id) {
      return;
    }

    const connectionRef = doc(
      firestore,
      `users/${currentUser.uid}/connections/${userData.id}`
    );
    const recipientConnectionRef = doc(
      firestore,
      `users/${userData.id}/connections/${currentUser.uid}`
    );

    try {
      if (connectionStatus === "Connect") {
        const batch = writeBatch(firestore);

        // Set connection status to Pending for current user
        batch.set(connectionRef, { status: "Pending" });

        // Set connection status to Received for recipient
        batch.set(recipientConnectionRef, { status: "Received" });

        // Delete any existing connection request notifications
        const notificationsRef = collection(
          firestore,
          `users/${userData.id}/notifications`
        );
        const existingRequestsQuery = query(
          notificationsRef,
          where("type", "==", "connection_request"),
          where("from", "==", currentUser.uid)
        );
        const existingRequestsSnapshot = await getDocs(existingRequestsQuery);

        existingRequestsSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        // Create new connection request notification
        const newNotificationRef = doc(notificationsRef);
        batch.set(newNotificationRef, {
          type: "connection_request",
          from: currentUser.uid,
          fromUserName: `${currentUser.displayName}`,
          fromUserImage: currentUser.photoURL || null,
          createdAt: new Date(),
          isNew: true,
        });

        await batch.commit();
        setConnectionStatus("Pending");
      } else if (hasReceivedRequest) {
        const batch = writeBatch(firestore);

        // Update connection status
        const now = new Date();
        batch.update(connectionRef, { status: "Connected", connectedAt: now });
        batch.update(recipientConnectionRef, {
          status: "Connected",
          connectedAt: now,
        });

        // Create notification for current user (accepting the request)
        const currentUserNotificationRef = collection(
          firestore,
          `users/${currentUser.uid}/notifications`
        );
        const newCurrentUserNotificationRef = doc(currentUserNotificationRef);
        batch.set(newCurrentUserNotificationRef, {
          type: "connection_accepted",
          message: `${userData.firstName} ${userData.lastName} is now a connection.`,
          fromUserName: `${userData.firstName} ${userData.lastName}`,
          fromUserImage: userData.profileImage,
          from: userData.id,
          createdAt: new Date(),
          isNew: true,
        });

        // Create notification for the other user
        const otherUserNotificationRef = collection(
          firestore,
          `users/${userData.id}/notifications`
        );
        const newOtherUserNotificationRef = doc(otherUserNotificationRef);
        batch.set(newOtherUserNotificationRef, {
          type: "connection_accepted",
          message: `${
            currentUser.displayName || "A user"
          } is now a connection.`,
          fromUserName: currentUser.displayName || "A user",
          fromUserImage: currentUser.photoURL || null,
          from: currentUser.uid,
          createdAt: new Date(),
          isNew: true,
        });

        // Delete the original connection request notification
        const requestNotificationsRef = collection(
          firestore,
          `users/${currentUser.uid}/notifications`
        );
        const q = query(
          requestNotificationsRef,
          where("type", "==", "connection_request"),
          where("from", "==", userData.id)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit();

        setConnectionStatus("Connected");
        setConnectionType("Direct");
        setHasReceivedRequest(false);
        setConnectionCount((prevCount) => prevCount + 1);
      }
    } catch (error) {}
  };

  const formatJoinDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    return `${month} ${year}`;
  };

  const handleRemoveConnection = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser || !userData.id) {
      return;
    }

    const connectionRef = doc(
      firestore,
      `users/${currentUser.uid}/connections/${userData.id}`
    );
    const recipientConnectionRef = doc(
      firestore,
      `users/${userData.id}/connections/${currentUser.uid}`
    );

    try {
      const batch = writeBatch(firestore);

      // Delete the connection documents
      batch.delete(connectionRef);
      batch.delete(recipientConnectionRef);

      // Remove the "is now a connection" notification for both users
      const currentUserNotificationsRef = collection(
        firestore,
        `users/${currentUser.uid}/notifications`
      );
      const profileUserNotificationsRef = collection(
        firestore,
        `users/${userData.id}/notifications`
      );

      const [currentUserNotifications, profileUserNotifications] =
        await Promise.all([
          getDocs(
            query(
              currentUserNotificationsRef,
              where("type", "==", "connection_accepted"),
              where("from", "==", userData.id)
            )
          ),
          getDocs(
            query(
              profileUserNotificationsRef,
              where("type", "==", "connection_accepted"),
              where("from", "==", currentUser.uid)
            )
          ),
        ]);

      currentUserNotifications.forEach((doc) => batch.delete(doc.ref));
      profileUserNotifications.forEach((doc) => batch.delete(doc.ref));

      // Commit the batch
      await batch.commit();

      setConnectionStatus("Connect");
      setConnectionType("Extended");
      setConnectionCount((prevCount) => prevCount - 1);

      // Force a re-check of the connection status
      await checkConnectionStatus(currentUser.uid, userData.id);
    } catch (error) {}
  };

  const handleRemoveRequest = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) return;

    const connectionRef = doc(
      firestore,
      `users/${currentUser.uid}/connections/${userData.id}`
    );
    const recipientConnectionRef = doc(
      firestore,
      `users/${userData.id}/connections/${currentUser.uid}`
    );

    try {
      // Use a batch write to ensure all operations succeed or fail together
      const batch = writeBatch(firestore);

      batch.delete(connectionRef);
      batch.delete(recipientConnectionRef);

      // Delete notifications for both users
      const currentUserNotificationsRef = collection(
        firestore,
        `users/${currentUser.uid}/notifications`
      );
      const profileUserNotificationsRef = collection(
        firestore,
        `users/${userData.id}/notifications`
      );

      const [currentUserNotifications, profileUserNotifications] =
        await Promise.all([
          getDocs(
            query(
              currentUserNotificationsRef,
              where("from", "in", [userData.id, currentUser.uid]),
              where("type", "in", ["connection_request", "connection_accepted"])
            )
          ),
          getDocs(
            query(
              profileUserNotificationsRef,
              where("from", "in", [userData.id, currentUser.uid]),
              where("type", "in", ["connection_request", "connection_accepted"])
            )
          ),
        ]);

      currentUserNotifications.forEach((doc) => {
        batch.delete(doc.ref);
      });

      profileUserNotifications.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Commit the batch
      await batch.commit();

      // Update local state
      setConnectionStatus("Connect");
      setHasReceivedRequest(false);
      setConnectionType("Extended");

      // Force a re-check of the connection status
      await checkConnectionStatus(currentUser.uid, userData.id);
    } catch (error) {}
  };

  const handleModalClose = () => {
    setShowConnectionsModal(false);
  };

  const handleNavigate = (clickedUserId) => {
    setShowConnectionsModal(false);
    if (clickedUserId === currentUser?.uid) {
      // If the clicked user is the logged-in user, navigate to /profile
      window.location.href = "/profile";
    } else {
      // Otherwise, navigate to the user's profile
      window.location.href = `/user/${clickedUserId}`;
    }
  };

  const fetchMutualConnections = async (currentUserId, profileUserId) => {
    if (!currentUserId || !profileUserId) return;

    try {
      const currentUserConnectionsRef = collection(
        firestore,
        `users/${currentUserId}/connections`
      );
      const profileUserConnectionsRef = collection(
        firestore,
        `users/${profileUserId}/connections`
      );

      const currentUserConnectionsQuery = query(
        currentUserConnectionsRef,
        where("status", "==", "Connected")
      );
      const profileUserConnectionsQuery = query(
        profileUserConnectionsRef,
        where("status", "==", "Connected")
      );

      const [currentUserConnectionsSnapshot, profileUserConnectionsSnapshot] =
        await Promise.all([
          getDocs(currentUserConnectionsQuery),
          getDocs(profileUserConnectionsQuery),
        ]);

      const currentUserConnections = new Set(
        currentUserConnectionsSnapshot.docs.map((doc) => doc.id)
      );
      const profileUserConnections = new Set(
        profileUserConnectionsSnapshot.docs.map((doc) => doc.id)
      );

      const mutualConnectionIds = [...currentUserConnections].filter((id) =>
        profileUserConnections.has(id)
      );

      const mutualConnectionsData = await Promise.all(
        mutualConnectionIds.map(async (userId) => {
          const userDoc = await getDoc(doc(firestore, "users", userId));
          const userData = userDoc.exists() ? userDoc.data() : null;

          if (!userData) return null;

          return {
            id: userId,
            ...userData,
            profileImage: userData.profileImage || defaultImage,
          };
        })
      );

      // Filter out any null values from failed lookups
      setMutualConnections(mutualConnectionsData.filter(Boolean));
    } catch (error) {
      setMutualConnections([]);
    }
  };

  const getRandomMutualConnections = (connections, count) => {
    if (!connections || connections.length === 0) return [];
    const shuffled = [...connections].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, Math.min(count, connections.length));
  };

  useEffect(() => {
    if (currentUser && userData.id) {
      checkConnectionStatus(currentUser.uid, userData.id);
    }
  }, [currentUser, userData.id]);

  const ReactionsSummary = ({ reactions, onClick, post }) => {
    const reactionCount = Object.keys(reactions).length;
    const topEmojis = useMemo(() => getTopThreeEmojis(reactions), [reactions]);

    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
      const checkSafari = () => {
        const isSafari =
          /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
          /apple/i.test(navigator.vendor);
        setIsSafari(isSafari);
        document.documentElement.style.setProperty(
          "--emoji-font-size",
          isSafari ? "0.75rem" : "0.875rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-margin-left",
          isSafari ? "-0.25rem" : "-0.25rem"
        );
        document.documentElement.style.setProperty(
          "--count-margin-left",
          isSafari ? "0.25rem" : "0.25rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-text-size",
          isSafari ? "0.75rem" : "0.875rem"
        );

        document.documentElement.style.setProperty(
          "--emoji-margin-bottom",
          isSafari ? "0rem" : "0rem"
        );
        document.documentElement.style.setProperty(
          "--emoji-margin-top",
          isSafari ? "0rem" : "0rem"
        );
      };

      checkSafari();
    }, []);

    return (
      <div>
        {reactionCount} boost{reactionCount !== 1 ? "s" : ""}
        {(post?.commentCount > 0 ||
          Object.keys(post?.reposts || {}).length > 0) && (
          <span className={classes.dot}>•</span>
        )}
      </div>
    );
  };

  const RepostCount = ({ postId, reposts, onClick }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0) return null;

    return (
      <div
        className={classes.repostCount}
        onClick={() => onClick(postId, reposts)}
      >
        <span>{repostCount}</span>
        <span> repost{repostCount !== 1 ? "s" : ""}</span>
      </div>
    );
  };

  // First, remove/consolidate the multiple useEffects into a single one for activity

  // Remove these useEffects:
  // useEffect(() => {
  //   if (userData.id && activityType === "Posts") {
  //     fetchPosts();
  //   }
  // }, [userData.id, activityType, fetchPosts]);

  // useEffect(() => {
  //   if (!userData.id) return;
  //   const fetchData = async () => {
  //     setLoadingReactions(true);
  //     try {
  //       if (activityType === "Reactions") {
  //         await fetchReactions();
  //       } else if (activityType === "Comments") {
  //         await fetchComments();
  //       } else if (activityType === "Reposts") {
  //         await fetchReposts();
  //       } else if (activityType === "Posts") {
  //         await fetchPosts();
  //       }
  //     } finally {
  //       setLoadingReactions(false);
  //     }
  //   };
  //   fetchData();
  // }, [userData.id, activityType]);

  const [loadingStates, setLoadingStates] = useState({
    initial: true,
    activityContent: false,
  });

  // Add this helper function to manage loading states
  const updateLoadingState = (key, value) => {
    setLoadingStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (!userData.id || !activityType) return;

    const fetchActivityData = async () => {
      updateLoadingState("activityContent", true);

      try {
        switch (activityType) {
          case "Posts":
            await fetchPosts();
            break;
          case "Boosts":
            await fetchReactions();
            break;
          case "Comments":
            await fetchComments();
            break;
          case "Reposts":
            await fetchReposts();
            break;
        }
      } finally {
        updateLoadingState("activityContent", false);
      }
    };

    fetchActivityData();
  }, [userData.id, activityType]);

  const renderReposts = () => {
    return (
      <>
        <div className={classes.posts}>
          {reposts.length === 0 ? (
            <p className={classes.defaultText}>
              <span className={classes.userName}>{userData.firstName}</span> has
              no reposts.
            </p>
          ) : (
            <>
              {reposts.slice(0, displayedReposts).map((repost, index) => (
                <React.Fragment key={repost.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>
                        {userData.firstName} {userData.lastName}
                      </span>
                      <span className={classes.postMessage}>reposted this</span>
                      <span className={classes.dot}>•</span>
                      <span className={classes.postTimestamp}>
                        {formatTimestamp(repost.repostedAt)}
                      </span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !repost.originalPost.postPreview.mediaType &&
                        repost.originalPost.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() => handleRepostClick(repost.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {repost.originalPost.postPreview.mediaType &&
                          renderFilePreview(repost.originalPost.postPreview)}
                        {repost.originalPost.postPreview.text && (
                          <p className={classes.textPreview}>
                            {truncateText(
                              repost.originalPost.postPreview.text,
                              3
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {((repost.originalPost.reactions &&
                    Object.keys(repost.originalPost.reactions).length > 0) ||
                    (repost.originalPost.reposts &&
                      Object.keys(repost.originalPost.reposts).length > 0) ||
                    (repost.originalPost.commentCount &&
                      repost.originalPost.commentCount > 0)) && (
                    <div className={classes.postInteractions}>
                      {repost.originalPost.reactions &&
                        Object.keys(repost.originalPost.reactions).length >
                          0 && (
                          <ReactionsSummary
                            reactions={repost.originalPost.reactions}
                            post={repost.originalPost}
                            onClick={() =>
                              handleReactionsClick(
                                repost.originalPost.id,
                                repost.originalPost.reactions
                              )
                            }
                          />
                        )}
                      <PostInteractionCount
                        postId={repost.originalPost.id}
                        reposts={repost.originalPost.reposts}
                        commentCount={repost.originalPost.commentCount}
                        onClick={handleRepostsClick}
                        onCommentClick={() =>
                          handlePostClick(repost.originalPost.id)
                        }
                      />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
          {reposts.length > 3 && displayedReposts < reposts.length && (
            <div
              className={classes.showAllPosts}
              onClick={() => navigate(`/all-reposts/${userData.urlSlug}`)}
            >
              Show all reposts
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 12H5m14 0-4 4m4-4-4-4"
                />
              </svg>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderReactions = () => {
    return (
      <>
        <div className={classes.posts}>
          {reactions.length === 0 ? (
            <p className={classes.defaultText}>
              <span className={classes.userName}>{userData.firstName}</span> has
              no reactions.
            </p>
          ) : (
            <>
              {reactions.slice(0, displayedReactions).map((reaction, index) => (
                <React.Fragment key={reaction.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>
                        {userData.firstName} {userData.lastName}
                      </span>
                      <span className={classes.postMessage}> boosted this</span>
                      <span className={classes.dot}>•</span>
                      <span>{formatTimestamp(reaction.reactedAt)}</span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !reaction.originalPost.postPreview.mediaType &&
                        reaction.originalPost.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() => handleReactionClick(reaction.id, reaction)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {reaction.originalPost?.postPreview?.mediaType &&
                          renderFilePreview(reaction.originalPost.postPreview)}
                        {reaction.originalPost?.postPreview?.text && (
                          <p className={classes.textPreview}>
                            {truncateText(
                              reaction.originalPost.postPreview.text,
                              3
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {((reaction.originalPost?.reactions &&
                    Object.keys(reaction.originalPost.reactions).length > 0) ||
                    (reaction.originalPost?.reposts &&
                      Object.keys(reaction.originalPost.reposts).length > 0) ||
                    (reaction.originalPost?.commentCount &&
                      reaction.originalPost.commentCount > 0)) && (
                    <div className={classes.postInteractions}>
                      {reaction.originalPost?.reactions &&
                        Object.keys(reaction.originalPost.reactions).length >
                          0 && (
                          <ReactionsSummary
                            reactions={reaction.originalPost.reactions}
                            post={reaction.originalPost}
                            onClick={() =>
                              handleReactionsClick(
                                reaction.originalPost.id,
                                reaction.originalPost.reactions
                              )
                            }
                          />
                        )}
                      <div className={classes.rightInteractions}>
                        <PostInteractionCount
                          postId={reaction.originalPost?.id}
                          reposts={reaction.originalPost?.reposts}
                          commentCount={reaction.originalPost?.commentCount}
                          onClick={handleRepostsClick}
                          onCommentClick={() =>
                            handlePostClick(reaction.originalPost?.id)
                          }
                        />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
          {reactions.length > 3 && displayedReactions < reactions.length && (
            <div
              className={classes.showAllPosts}
              onClick={() => navigate(`/all-reactions/${userData.urlSlug}`)}
            >
              Show all boosts
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 12H5m14 0-4 4m4-4-4-4"
                />
              </svg>
            </div>
          )}
        </div>
      </>
    );
  };

  const handleReactionClick = (reactionId, reaction) => {
    navigate(`/reaction/${reactionId}`, {
      state: {
        fromUserProfile: true,
        userData: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          profileImage: userData.profileImage,
        },
        reactionEmoji: reaction?.emoji || "👍", // Provide a default emoji if not available
      },
    });
  };

  const handleRepostClick = (repostId) => {
    navigate(`/repost/${repostId}`, {
      state: {
        fromUserProfile: true,
        userData: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          profileImage: userData.profileImage || profileImage,
        },
      },
    });
  };

  const handleMoreClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowFullIntro(true);
  };

  useEffect(() => {
    if (showDropdown) {
      positionDropdown();
      window.addEventListener("resize", positionDropdown);
      window.addEventListener("scroll", positionDropdown);
    }
    return () => {
      window.removeEventListener("resize", positionDropdown);
      window.removeEventListener("scroll", positionDropdown);
    };
  }, [showDropdown]);

  const sortedExperiences = useMemo(() => {
    if (!experiences) return [];
    return [...experiences].sort((a, b) => {
      const aEndDate = a.isCurrentlyWorking
        ? Date.now()
        : new Date(`${a.endMonth} 1, ${a.endYear}`).getTime();
      const bEndDate = b.isCurrentlyWorking
        ? Date.now()
        : new Date(`${b.endMonth} 1, ${b.endYear}`).getTime();

      if (aEndDate !== bEndDate) {
        return bEndDate - aEndDate; // Most recent end date first
      }

      const aStartDate = new Date(
        `${a.startMonth} 1, ${a.startYear}`
      ).getTime();
      const bStartDate = new Date(
        `${b.startMonth} 1, ${b.startYear}`
      ).getTime();

      if (aStartDate !== bStartDate) {
        return bStartDate - aStartDate; // Most recent start date first
      }

      // If both start and end dates are the same, use dateAdded
      return (b.dateAdded?.toMillis() || 0) - (a.dateAdded?.toMillis() || 0);
    });
  }, [experiences]);

  const sortedEducation = useMemo(() => {
    if (!education) return [];
    return [...education].sort((a, b) => {
      const now = new Date();
      const currentYear = now.getFullYear();
      const currentMonth = monthsList.indexOf(
        now.toLocaleString("default", { month: "long" })
      );

      const getEndDate = (edu) => {
        if (edu.isCurrentlyStudying) {
          return new Date(currentYear, currentMonth);
        }
        return new Date(`${edu.endMonth} 1, ${edu.endYear}`);
      };

      const aEnd = getEndDate(a);
      const bEnd = getEndDate(b);

      if (aEnd > bEnd) return -1;
      if (aEnd < bEnd) return 1;

      const aStart = new Date(`${a.startMonth} 1, ${a.startYear}`);
      const bStart = new Date(`${b.startMonth} 1, ${b.startYear}`);

      if (aStart > bStart) return -1;
      if (aStart < bStart) return 1;

      return new Date(b.dateAdded) - new Date(a.dateAdded);
    });
  }, [education]);

  const renderIntroCard = () => {
    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>About</div>
        {userData.introHtml && (
          <>
            <div className={classes.introText}>
              <pre>
                <span
                  dangerouslySetInnerHTML={{ __html: renderIntroText().text }}
                />
                {renderIntroText().showMore && (
                  <span
                    className={classes.moreButton}
                    onClick={handleMoreClick}
                  >
                    ...more
                  </span>
                )}
              </pre>
            </div>
            <div className={classes.noPostBorder}></div>
          </>
        )}
        <div className={classes.about}>
          {mutualConnections.length > 0 && (
            <div
              className={classes.clickableContainer}
              onClick={toggleMutualConnectionsModal}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                  clipRule="evenodd"
                />
              </svg>
              <span className={classes.skillText}>
                {mutualConnections.length} Mutual{" "}
                {mutualConnections.length === 1 ? "Connection" : "Connections"}
              </span>
            </div>
          )}
          {userData.city && userData.country && (
            <div className={classes.skillContainer}>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z"
                  clipRule="evenodd"
                />
              </svg>
              <span
                className={classes.skillText}
              >{`${userData.city}, ${userData.country}`}</span>
            </div>
          )}
          {userData.createdAt && (
            <div className={classes.skillContainer}>
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                  clip-rule="evenodd"
                />
              </svg>

              <span className={classes.skillText}>
                Joined {formatJoinDate(userData.createdAt)}
              </span>
            </div>
          )}
          {userData.urlSlug && (
            <div className={classes.skillContainer}>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
                />
              </svg>
              <span className={classes.skillText}>
                {`foundry.bz/${userData.urlSlug}`}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderConnectionsCard = () => {
    if (!connections || connections.length === 0) return null;

    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>Connections</div>

        {isLoadingConnections ? (
          <div className={classes.loadingIndicator}>
            <span
              className={`${classes.loadingIcon} material-symbols-outlined`}
            >
              progress_activity
            </span>
          </div>
        ) : (
          <>
            {connections.length === 0 ? (
              <p className={classes.defaultText}>No connections found.</p>
            ) : (
              <>
                {connections
                  .slice(0, displayedConnections)
                  .map((connection) => (
                    <div key={connection.id} className={classes.userContainer}>
                      <img
                        src={connection.userData?.profileImage || defaultImage}
                        alt={`${connection.userData?.firstName} ${connection.userData?.lastName}`}
                        className={classes.userImage}
                        onClick={() =>
                          navigate(`/${connection.userData?.urlSlug}`)
                        }
                        style={{ cursor: "pointer" }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                      <div className={classes.userInfo}>
                        <div className={classes.userNameContainer}>
                          <div
                            className={classes.userName}
                            onClick={() =>
                              navigate(`/${connection.userData?.urlSlug}`)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {connection.userData?.firstName}{" "}
                            {connection.userData?.lastName}
                          </div>
                          <span className={classes.userConnectionType}>
                            •{" "}
                            {connection.id === currentUser?.uid
                              ? "You"
                              : connection.connectionType}
                          </span>
                        </div>
                        {connection.userData?.bio && (
                          <div className={classes.userBio}>
                            {connection.userData.bio}
                          </div>
                        )}
                        {connection.userData?.link && (
                          <a
                            href={connection.userData.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.userLink}
                          >
                            <span>
                              {connection.userData.linkText ||
                                connection.userData.link}
                            </span>
                            <svg
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                              />
                            </svg>
                          </a>
                        )}
                      </div>
                    </div>
                  ))}

                {connections.length > 3 && (
                  <div
                    className={classes.showAllExperiences}
                    onClick={() => {
                      setDisplayedConnections(
                        displayedConnections >= connections.length
                          ? 3
                          : Math.min(
                              displayedConnections + displayedConnections * 2,
                              connections.length
                            )
                      );
                    }}
                  >
                    {displayedConnections >= connections.length
                      ? "Show less"
                      : "Show more"}
                    {displayedConnections >= connections.length ? (
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m5 15 7-7 7 7"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 9-7 7-7-7"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const fetchStartupData = async (userId) => {
    try {
      const batch = [];

      const startupsSnapshot = await getDocs(
        collection(firestore, `users/${userId}/startups`)
      );
      const startupsData = startupsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        membershipType: "owner",
      }));
      batch.push(...startupsData);

      const allUsersSnapshot = await getDocs(collection(firestore, "users"));
      const teamMemberPromises = allUsersSnapshot.docs.map(async (userDoc) => {
        const startupRef = collection(
          firestore,
          `users/${userDoc.id}/startups`
        );
        const startupDocs = await getDocs(startupRef);

        const startupTeamPromises = startupDocs.docs.map(async (startupDoc) => {
          const teamRef = doc(
            firestore,
            `users/${userDoc.id}/startups/${startupDoc.id}/team/${userId}`
          );
          const teamDoc = await getDoc(teamRef);

          if (teamDoc.exists() && teamDoc.data().status === "active") {
            return {
              id: startupDoc.id,
              ownerId: userDoc.id,
              ...startupDoc.data(),
              membershipType: "member",
              role: teamDoc.data().role,
              acceptedAt: teamDoc.data().acceptedAt,
            };
          }
          return null;
        });

        const resolvedStartups = await Promise.all(startupTeamPromises);
        return resolvedStartups.filter((startup) => startup !== null);
      });

      const donationsRef = collection(firestore, `users/${userId}/donations`);
      const donationsSnapshot = await getDocs(donationsRef);
      const supportedPromises = donationsSnapshot.docs.map(
        async (donationDoc) => {
          const donationData = donationDoc.data();
          if (donationData.status !== "completed") return null;

          const startupRef = doc(
            firestore,
            `users/${donationData.startupOwnerId}/startups/${donationData.startupId}`
          );
          const startupDoc = await getDoc(startupRef);

          if (startupDoc.exists()) {
            return {
              id: startupDoc.id,
              ...startupDoc.data(),
              supported: true,
              supportedAt: donationData.createdAt,
              ownerId: donationData.startupOwnerId,
            };
          }
          return null;
        }
      );

      const followedStartupsRef = collection(
        firestore,
        `users/${userId}/startupsFollowing`
      );
      const followedSnapshot = await getDocs(followedStartupsRef);
      const followedPromises = followedSnapshot.docs.map(async (followDoc) => {
        const startupRef = doc(
          firestore,
          `users/${followDoc.data().ownerId}/startups/${followDoc.id}`
        );
        const startupDoc = await getDoc(startupRef);

        if (startupDoc.exists()) {
          return {
            id: startupDoc.id,
            ...startupDoc.data(),
            following: true,
            followedAt: followDoc.data().followedAt,
            ownerId: followDoc.data().ownerId,
          };
        }
        return null;
      });

      const teamMemberStartups = (await Promise.all(teamMemberPromises)).flat();
      const supportedStartups = (await Promise.all(supportedPromises)).filter(
        Boolean
      );
      const followedStartups = (await Promise.all(followedPromises)).filter(
        Boolean
      );

      batch.push(
        ...teamMemberStartups,
        ...supportedStartups,
        ...followedStartups
      );

      const counts = {
        Owner: batch.filter((s) => s.membershipType === "owner").length,
        TeamMember: batch.filter((s) => s.membershipType === "member").length,
        Supported: batch.filter((s) => s.supported).length,
        Following: batch.filter((s) => s.following).length,
      };

      if (!startupType) {
        const firstType = Object.keys(counts).find((type) => counts[type] > 0);
        if (firstType) {
          setStartupType(firstType);
        }
      }

      setStartupCounts(counts);
      setStartups(batch);
    } catch (error) {}
  };

  const StartupSection = ({ startup }) => {
    const navigate = useNavigate();

    const handleStartupClick = () => {
      if (startup.startupUrlSlug) {
        navigate(`/startup/${startup.startupUrlSlug}`);
      }
    };

    const handleLinkClick = (e) => {
      e.stopPropagation();
      if (e.target.tagName === "A") {
        e.preventDefault();
        window.open(e.target.href, "_blank", "noopener,noreferrer");
      }
    };

    return (
      <div key={startup.id} className={classes.userContainer}>
        <img
          src={startup.startupImage || startupImage}
          alt={startup.startupName}
          className={classes.startupImage}
          onClick={handleStartupClick}
          style={{ cursor: "pointer" }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = startupImage;
          }}
        />
        <div className={classes.userInfo}>
          <div className={classes.userNameContainer}>
            <div
              className={classes.userName}
              onClick={handleStartupClick}
              style={{ cursor: "pointer" }}
            >
              {startup.startupName}
            </div>
            <span className={classes.userConnectionType}>• Startup</span>
          </div>
          {startup.bio && <div className={classes.userBio}>{startup.bio}</div>}
          {startup.link && (
            <a
              href={startup.link}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.userLink}
              onClick={handleLinkClick}
            >
              <span>{startup.linkText || startup.link}</span>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                />
              </svg>
            </a>
          )}
        </div>
      </div>
    );
  };

  // Add this component definition in UserProfile.js before the main UserProfile component
  const CommunitySection = ({ community }) => {
    const navigate = useNavigate();

    const handleCommunityClick = () => {
      if (community.communityUrlSlug) {
        navigate(`/community/${community.communityUrlSlug}`);
      }
    };

    const handleLinkClick = (e) => {
      e.stopPropagation();
      if (e.target.tagName === "A") {
        e.preventDefault();
        window.open(e.target.href, "_blank", "noopener,noreferrer");
      }
    };

    return (
      <div key={community.id} className={classes.userContainer}>
        <img
          src={community.communityImage || communityImage}
          alt={community.communityName}
          className={classes.communityImage}
          onClick={handleCommunityClick}
          style={{ cursor: "pointer" }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = communityImage;
          }}
        />
        <div className={classes.userInfo}>
          <div className={classes.userNameContainer}>
            <div
              className={classes.userName}
              onClick={handleCommunityClick}
              style={{ cursor: "pointer" }}
            >
              {community.communityName}
            </div>
            <span className={classes.userConnectionType}>• Community</span>
          </div>
          {community.tagline && (
            <div className={classes.userBio}>{community.tagline}</div>
          )}
          {community.link && (
            <a
              href={community.link}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.userLink}
              onClick={handleLinkClick}
            >
              <span>{community.linkText || community.link}</span>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                />
              </svg>
            </a>
          )}
        </div>
      </div>
    );
  };

  const renderCommunitiesCard = () => {
    if (!hasCommunityContent) return null;

    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>Communities</div>

        <CommunityButtons
          selectedCommunityType={communityType}
          onSelectionChange={handleCommunityTypeChange}
          communityCounts={communityCounts}
          showAllButtons={false}
        />

        {loadingCommunityType ? (
          <div className={classes.loadingIndicator}>
            <span
              className={`${classes.loadingIcon} material-symbols-outlined`}
            >
              progress_activity
            </span>
          </div>
        ) : (
          <>
            <div>
              {filteredCommunities
                .slice(0, displayedCommunities)
                .map((community) => (
                  <CommunitySection key={community.id} community={community} />
                ))}

              {filteredCommunities.length > 3 && (
                <div
                  className={classes.showAllExperiences}
                  onClick={() => {
                    setDisplayedCommunities(
                      displayedCommunities >= filteredCommunities.length
                        ? 3
                        : Math.min(
                            displayedCommunities + displayedCommunities * 2,
                            filteredCommunities.length
                          )
                    );
                  }}
                >
                  {displayedCommunities >= filteredCommunities.length
                    ? "Show less"
                    : "Show more"}
                  {displayedCommunities >= filteredCommunities.length ? (
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m5 15 7-7 7 7"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 9-7 7-7-7"
                      />
                    </svg>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const renderEventsCard = () => {
    if (!hasEventContent) return null;

    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>Events</div>

        <EventButtons
          selectedEventType={eventType}
          onSelectionChange={handleEventTypeChange}
          eventCounts={eventCounts}
          showAllButtons={false}
        />

        {loadingEventType ? (
          <div className={classes.loadingIndicator}>
            <span
              className={`${classes.loadingIcon} material-symbols-outlined`}
            >
              progress_activity
            </span>
          </div>
        ) : (
          <>
            <div>
              {filteredEvents.slice(0, displayedEventCount).map((event) => (
                <div key={event.id} className={classes.userContainer}>
                  <img
                    src={event.eventImage || eventImage}
                    alt={event.eventName}
                    className={classes.eventImage}
                    onClick={() => navigate(`/event/${event.eventUrlSlug}`)}
                    style={{ cursor: "pointer" }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = greyEventImage;
                    }}
                  />
                  <div className={classes.userInfo}>
                    <div className={classes.userNameContainer}>
                      <div
                        className={classes.userName}
                        onClick={() => navigate(`/event/${event.eventUrlSlug}`)}
                        style={{ cursor: "pointer" }}
                      >
                        {event.eventName}
                      </div>
                      <span className={classes.userConnectionType}>
                        • Event
                      </span>
                    </div>
                    {event.tagline && (
                      <div className={classes.userBio}>{event.tagline}</div>
                    )}
                    {event.link && (
                      <a
                        href={event.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.userLink}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (e.target.tagName === "A") {
                            e.preventDefault();
                            window.open(
                              e.target.href,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }
                        }}
                      >
                        <span>{event.linkText || event.link}</span>
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </a>
                    )}
                  </div>
                </div>
              ))}

              {filteredEvents.length > 3 && (
                <div
                  className={classes.showAllExperiences}
                  onClick={() => {
                    setDisplayedEventCount(
                      displayedEventCount >= filteredEvents.length
                        ? 3
                        : Math.min(
                            displayedEventCount + displayedEventCount * 2,
                            filteredEvents.length
                          )
                    );
                  }}
                >
                  {displayedEventCount >= filteredEvents.length
                    ? "Show less"
                    : "Show more"}
                  {displayedEventCount >= filteredEvents.length ? (
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m5 15 7-7 7 7"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 9-7 7-7-7"
                      />
                    </svg>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const renderStartupsCard = () => {
    if (!hasStartupContent) return null;

    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>Startups</div>

        <StartupButtons
          selectedStartupType={startupType}
          onSelectionChange={handleStartupTypeChange}
          startupCounts={startupCounts}
          showAllButtons={false}
        />

        {loadingStartupType ? (
          <div className={classes.loadingIndicator}>
            <span
              className={`${classes.loadingIcon} material-symbols-outlined`}
            >
              progress_activity
            </span>
          </div>
        ) : (
          <>
            <div>
              {filteredStartups.slice(0, displayedStartups).map((startup) => (
                <StartupSection key={startup.id} startup={startup} />
              ))}

              {filteredStartups.length > 3 && (
                <div
                  className={classes.showAllExperiences}
                  onClick={() => {
                    setDisplayedStartups(
                      displayedStartups >= filteredStartups.length
                        ? 3
                        : Math.min(
                            displayedStartups + displayedStartups * 2,
                            filteredStartups.length
                          )
                    );
                  }}
                >
                  {displayedStartups >= filteredStartups.length
                    ? "Show less"
                    : "Show more"}
                  {displayedStartups >= filteredStartups.length ? (
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m5 15 7-7 7 7"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 9-7 7-7-7"
                      />
                    </svg>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const toggleMutualConnectionsModal = () => {
    if (!isMutualConnectionsModalOpen) {
      setMutualConnectionsModalScrollPosition(window.scrollY);
      setIsMutualConnectionsModalOpen(true);
      setShowMutualConnectionsModal(true);
    } else {
      handleCloseMutualConnectionsModal();
    }
  };

  const toggleReactionsModal = (postId, reactions) => {
    if (!showReactionsModal) {
      setReactionsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, reactionsModalScrollPosition);
      }, 0);
    }
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal(!showReactionsModal);
    setIsReactionsModalOpen(!isReactionsModalOpen);
  };

  const toggleRepostsModal = (postId, reposts) => {
    if (!showRepostsModal) {
      setRepostsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, repostsModalScrollPosition);
      }, 0);
    }
    setActivePostReposts({ postId, reposts });
    setShowRepostsModal(!showRepostsModal);
    setIsRepostsModalOpen(!isRepostsModalOpen);
  };

  const renderSkillsCard = () => {
    if (!userData.skills || userData.skills.length === 0) return null;
    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>Skills</div>
        <div className={classes.about}>
          {userData.skills && userData.skills.length > 0 ? (
            userData.skills.slice(0, visibleSkills).map((skill, index) => (
              <div key={index} className={classes.skillContainer}>
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="orangeRed"
                  viewBox="0 0 24 24"
                >
                  <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                </svg>
                <span className={classes.skillText}>{skill}</span>
              </div>
            ))
          ) : (
            <>
              <span className={classes.userName}>{userData.firstName}</span> has
              no skills listed.
            </>
          )}
        </div>
        {userData.skills && userData.skills.length > 9 && (
          <div
            className={classes.showAllExperiences}
            onClick={() => {
              setVisibleSkills(
                visibleSkills >= userData.skills.length
                  ? 9
                  : Math.min(
                      visibleSkills + visibleSkills * 2,
                      userData.skills.length
                    )
              );
            }}
          >
            {visibleSkills >= userData.skills.length
              ? "Show less"
              : "Show more"}
            {visibleSkills >= userData.skills.length ? (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m5 15 7-7 7 7"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 9-7 7-7-7"
                />
              </svg>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderInterestsCard = () => {
    if (!userData.interests || userData.interests.length === 0) return null;
    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>Interests</div>
        <div className={classes.about}>
          {userData.interests && userData.interests.length > 0 ? (
            userData.interests
              .slice(0, visibleInterests)
              .map((interest, index) => (
                <div key={index} className={classes.skillContainer}>
                  <svg
                    className="w-6 h-6 text-gray"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="orangeRed"
                    viewBox="0 0 24 24"
                  >
                    <path d="m12.75 20.66 6.184-7.098c2.677-2.884 2.559-6.506.754-8.705-.898-1.095-2.206-1.816-3.72-1.855-1.293-.034-2.652.43-3.963 1.442-1.315-1.012-2.678-1.476-3.973-1.442-1.515.04-2.825.76-3.724 1.855-1.806 2.201-1.915 5.823.772 8.706l6.183 7.097c.19.216.46.34.743.34a.985.985 0 0 0 .743-.34Z" />
                  </svg>
                  <span className={classes.skillText}>{interest}</span>
                </div>
              ))
          ) : (
            <>
              <span className={classes.userName}>{userData.firstName}</span> has
              no Interests.
            </>
          )}
        </div>
        {userData.interests && userData.interests.length > 9 && (
          <div
            className={classes.showAllExperiences}
            onClick={() => {
              setVisibleInterests(
                visibleInterests >= userData.interests.length
                  ? 9
                  : Math.min(
                      visibleInterests + visibleInterests * 2,
                      userData.interests.length
                    )
              );
            }}
          >
            {visibleInterests >= userData.interests.length
              ? "Show less"
              : "Show more"}
            {visibleInterests >= userData.interests.length ? (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m5 15 7-7 7 7"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 9-7 7-7-7"
                />
              </svg>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderExperiencesCard = () => {
    if (!experiences || experiences.length === 0) return null;
    return (
      <div className={classes.experiencesCard}>
        <div className={classes.experiencesCardTitle}>Experience</div>
        <div className={classes.experiencesCardAbout}>
          {sortedExperiences.slice(0, visibleExperiences).map((exp) => (
            <div key={exp.id} className={classes.cardAbout}>
              <p className={classes.skillContainer}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2v14a1 1 0 1 1 0 2H5a1 1 0 1 1 0-2V5a1 1 0 0 1-1-1Zm5 2a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H9Zm5 0a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1h-1Zm-5 4a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1H9Zm5 0a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-1Zm-3 4a2 2 0 0 0-2 2v3h2v-3h2v3h2v-3a2 2 0 0 0-2-2h-2Z"
                    clip-rule="evenodd"
                  />
                </svg>

                <span className={classes.skillText}>{exp.organisation}</span>
              </p>
              <p className={classes.skillContainer}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 2a3 3 0 0 0-3 3v1H5a3 3 0 0 0-3 3v2.382l1.447.723.005.003.027.013.12.056c.108.05.272.123.486.212.429.177 1.056.416 1.834.655C7.481 13.524 9.63 14 12 14c2.372 0 4.52-.475 6.08-.956.78-.24 1.406-.478 1.835-.655a14.028 14.028 0 0 0 .606-.268l.027-.013.005-.002L22 11.381V9a3 3 0 0 0-3-3h-2V5a3 3 0 0 0-3-3h-4Zm5 4V5a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1h6Zm6.447 7.894.553-.276V19a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-5.382l.553.276.002.002.004.002.013.006.041.02.151.07c.13.06.318.144.557.242.478.198 1.163.46 2.01.72C7.019 15.476 9.37 16 12 16c2.628 0 4.98-.525 6.67-1.044a22.95 22.95 0 0 0 2.01-.72 15.994 15.994 0 0 0 .707-.312l.041-.02.013-.006.004-.002.001-.001-.431-.866.432.865ZM12 10a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className={classes.skillText}>{exp.role}</span>
              </p>

              <p className={classes.skillContainer}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                    clip-rule="evenodd"
                  />
                </svg>

                <span className={classes.skillText}>
                  {exp.startMonth} {exp.startYear} -{" "}
                  {exp.isCurrentlyWorking
                    ? "Present"
                    : `${exp.endMonth} ${exp.endYear}`}
                </span>
              </p>
              {renderTruncatedText(
                exp.description,
                `exp-${exp.id}`,
                exp.descriptionHtml
              )}
            </div>
          ))}
        </div>
        {sortedExperiences.length > 3 && (
          <div
            className={classes.showAllExperiences}
            onClick={() => {
              setVisibleExperiences(
                visibleExperiences >= sortedExperiences.length
                  ? 3
                  : Math.min(
                      visibleExperiences + visibleExperiences * 2,
                      sortedExperiences.length
                    )
              );
            }}
          >
            {visibleExperiences >= sortedExperiences.length
              ? "Show less"
              : "Show more"}
            {visibleExperiences >= sortedExperiences.length ? (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m5 15 7-7 7 7"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 9-7 7-7-7"
                />
              </svg>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderEducationCard = () => {
    if (!education || education.length === 0) return null;
    return (
      <div className={classes.educationCard}>
        <div className={classes.educationCardTitle}>Education</div>
        <div className={classes.educationCardAbout}>
          {sortedEducation.slice(0, visibleEducation).map((edu) => (
            <div key={edu.id} className={classes.cardAbout}>
              <p className={classes.skillContainer}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="m6 10.5237-2.27075.6386C3.29797 11.2836 3 11.677 3 12.125V20c0 .5523.44772 1 1 1h2V10.5237Zm12 0 2.2707.6386c.4313.1213.7293.5147.7293.9627V20c0 .5523-.4477 1-1 1h-2V10.5237Z" />
                  <path
                    fill-rule="evenodd"
                    d="M12.5547 3.16795c-.3359-.22393-.7735-.22393-1.1094 0l-6.00002 4c-.45952.30635-.5837.92722-.27735 1.38675.30636.45953.92723.5837 1.38675.27735L8 7.86853V21h8V7.86853l1.4453.96352c.0143.00957.0289.01873.0435.02746.1597.09514.3364.14076.5112.1406.3228-.0003.6395-.15664.832-.44541.3064-.45953.1822-1.0804-.2773-1.38675l-6-4ZM10 12c0-.5523.4477-1 1-1h2c.5523 0 1 .4477 1 1s-.4477 1-1 1h-2c-.5523 0-1-.4477-1-1Zm1-4c-.5523 0-1 .44772-1 1s.4477 1 1 1h2c.5523 0 1-.44772 1-1s-.4477-1-1-1h-2Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className={classes.skillText}>{edu.school}</span>
              </p>

              <p className={classes.skillContainer}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12.4472 4.10557c-.2815-.14076-.6129-.14076-.8944 0L2.76981 8.49706l9.21949 4.39024L21 8.38195l-8.5528-4.27638Z" />
                  <path d="M5 17.2222v-5.448l6.5701 3.1286c.278.1325.6016.1293.8771-.0084L19 11.618v5.6042c0 .2857-.1229.5583-.3364.7481l-.0025.0022-.0041.0036-.0103.009-.0119.0101-.0181.0152c-.024.02-.0562.0462-.0965.0776-.0807.0627-.1942.1465-.3405.2441-.2926.195-.7171.4455-1.2736.6928C15.7905 19.5208 14.1527 20 12 20c-2.15265 0-3.79045-.4792-4.90614-.9751-.5565-.2473-.98098-.4978-1.27356-.6928-.14631-.0976-.2598-.1814-.34049-.2441-.04036-.0314-.07254-.0576-.09656-.0776-.01201-.01-.02198-.0185-.02991-.0253l-.01038-.009-.00404-.0036-.00174-.0015-.0008-.0007s-.00004 0 .00978-.0112l-.00009-.0012-.01043.0117C5.12215 17.7799 5 17.5079 5 17.2222Zm-3-6.8765 2 .9523V17c0 .5523-.44772 1-1 1s-1-.4477-1-1v-6.6543Z" />
                </svg>
                <span className={classes.skillText}>{edu.degree}</span>
              </p>

              <p className={classes.skillContainer}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h12a1 1 0 1 0 0-2h-2v-2h2a1 1 0 0 0 1-1V4a2 2 0 0 0-2-2h-8v16h5v2H7a1 1 0 1 1 0-2h1V2H6Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className={classes.skillText}>{edu.subject}</span>
              </p>

              {edu.grade && (
                <p className={classes.skillContainer}>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12.4472 2.10557c-.2815-.14076-.6129-.14076-.8944 0L5.90482 4.92956l.37762.11119c.01131.00333.02257.00687.03376.0106L12 6.94594l5.6808-1.89361.3927-.13363-5.6263-2.81313ZM5 10V6.74803l.70053.20628L7 7.38747V10c0 .5523-.44772 1-1 1s-1-.4477-1-1Zm3-1c0-.42413.06601-.83285.18832-1.21643l3.49538 1.16514c.2053.06842.4272.06842.6325 0l3.4955-1.16514C15.934 8.16715 16 8.57587 16 9c0 2.2091-1.7909 4-4 4-2.20914 0-4-1.7909-4-4Z" />
                    <path d="M14.2996 13.2767c.2332-.2289.5636-.3294.8847-.2692C17.379 13.4191 19 15.4884 19 17.6488v2.1525c0 1.2289-1.0315 2.1428-2.2 2.1428H7.2c-1.16849 0-2.2-.9139-2.2-2.1428v-2.1525c0-2.1409 1.59079-4.1893 3.75163-4.6288.32214-.0655.65589.0315.89274.2595l2.34883 2.2606 2.3064-2.2634Z" />
                  </svg>

                  <span className={classes.skillText}>{edu.grade}</span>
                </p>
              )}
              <p className={classes.skillContainer}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className={classes.skillText}>
                  {edu.startMonth} {edu.startYear} -{" "}
                  {edu.isCurrentlyStudying
                    ? "Present"
                    : `${edu.endMonth} ${edu.endYear}`}
                </span>
              </p>
              {renderTruncatedText(
                edu.description,
                `edu-${edu.id}`,
                edu.descriptionHtml
              )}
            </div>
          ))}
        </div>
        {education.length > 3 && (
          <div
            className={classes.showAllExperiences}
            onClick={() => {
              setVisibleEducation(
                visibleEducation >= sortedEducation.length
                  ? 3
                  : Math.min(
                      visibleEducation + visibleEducation * 2,
                      sortedEducation.length
                    )
              );
            }}
          >
            {visibleEducation >= sortedEducation.length
              ? "Show less"
              : "Show more"}
            {visibleEducation >= sortedEducation.length ? (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m5 15 7-7 7 7"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 9-7 7-7-7"
                />
              </svg>
            )}
          </div>
        )}
      </div>
    );
  };

  const fetchActivityCounts = useCallback(async () => {
    if (!userData.id) return;
    try {
      const userId = userData.id;
      const postsCount = await fetchPostsCount(userId);
      const reactionsCount = await fetchReactionsCount(userId);
      const repostsCount = await fetchRepostsCount(userId);
      const commentsCount = await fetchCommentsCount(userId);

      setActivityCounts({
        Posts: postsCount,
        Boosts: reactionsCount,
        Reposts: repostsCount,
        Comments: commentsCount,
      });

      // If no activity type is set yet, set it to the first non-zero count
      if (!activityType) {
        const firstActivityType = Object.entries({
          Posts: postsCount,
          Boosts: reactionsCount,
          Reposts: repostsCount,
          Comments: commentsCount,
        }).find(([_, count]) => count > 0)?.[0];

        if (firstActivityType) {
          setActivityType(firstActivityType);
        }
      }
    } catch (error) {}
  }, [userData.id, activityType]);

  // Add this useEffect
  useEffect(() => {
    fetchActivityCounts();
  }, [fetchActivityCounts]);

  // Add this useEffect right after where you fetch activity counts
  // Add an initialization flag
  const [hasInitializedActivity, setHasInitializedActivity] = useState(false);

  // Modify the initial activity type useEffect
  useEffect(() => {
    if (activityCounts && !hasInitializedActivity) {
      const activityTypes = ["Posts", "Boosts", "Comments", "Reposts"];
      const firstActiveType = activityTypes.find(
        (type) => activityCounts[type] > 0
      );

      if (firstActiveType) {
        // Keep all other loading states true, only change activity type
        setActivityType(firstActiveType);
        setHasInitializedActivity(true);
      }
    }
  }, [activityCounts, hasInitializedActivity]);

  const renderActivityCard = () => {
    const totalActivityCount = Object.values(activityCounts).reduce(
      (sum, count) => sum + count,
      0
    );

    // For user profiles, we only hide the card if there's no activity
    if (totalActivityCount === 0) {
      return null;
    }

    return (
      <div className={classes.activityCard}>
        <div className={classes.activityCardTitle}>Activity</div>
        <ActivityButtons
          selectedActivity={activityType}
          onSelectionChange={handleActivityChange}
          activityCounts={activityCounts}
          showAllButtons={false}
        />
        {renderActivityContent()}
      </div>
    );
  };

  const renderActivityContent = () => {
    if (loadingStates.activityContent) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    switch (activityType) {
      case "Posts":
        return posts.length > 0 ? (
          renderPosts()
        ) : (
          <p className={classes.white}></p>
        );
      case "Boosts":
        return reactions.length > 0 ? (
          renderReactions()
        ) : (
          <p className={classes.white}></p>
        );
      case "Comments":
        return commentedPosts.length > 0 ? (
          renderComments()
        ) : (
          <p className={classes.white}></p>
        );
      case "Reposts":
        return reposts.length > 0 ? (
          renderReposts()
        ) : (
          <p className={classes.white}></p>
        );
      default:
        return null;
    }
  };

  const renderPosts = () => {
    return (
      <>
        <div className={classes.posts}>
          {posts.length === 0 ? (
            <p className={classes.defaultText}>
              <span className={classes.userName}></span>
            </p>
          ) : (
            <>
              {posts.slice(0, displayedPosts).map((post, index) => (
                <React.Fragment key={post.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>
                        {userData.firstName} {userData.lastName}
                      </span>
                      <div className={classes.postMetadata}>
                        <span className={classes.postMessage}>posted this</span>
                        <span className={classes.dot}>•</span>
                        <span className={classes.postTimestamp}>
                          {formatTimestamp(post.createdAt)}
                        </span>
                      </div>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !post.postPreview.mediaType && post.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() => handlePostClick(post.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {post.postPreview &&
                          post.postPreview.mediaType &&
                          renderFilePreview(post.postPreview)}
                        {post.postPreview && post.postPreview.text && (
                          <p className={classes.textPreview}>
                            {truncateText(post.postPreview.text, 3)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* First check if there are ANY interactions before rendering the container */}
                  {(post.reactions && Object.keys(post.reactions).length > 0) ||
                  (post.reposts && Object.keys(post.reposts).length > 0) ||
                  post.commentCount > 0 ? (
                    <div className={classes.postInteractions}>
                      {/* Then conditionally render individual elements */}
                      {post.reactions &&
                        Object.keys(post.reactions).length > 0 && (
                          <ReactionsSummary
                            reactions={post.reactions}
                            post={post}
                            onClick={() =>
                              handleReactionsClick(post.id, post.reactions)
                            }
                          />
                        )}
                      {(Object.keys(post.reposts || {}).length > 0 ||
                        post.commentCount > 0) && (
                        <div className={classes.rightInteractions}>
                          <PostInteractionCount
                            postId={post.id}
                            reposts={post.reposts}
                            commentCount={post.commentCount}
                            onClick={handleRepostsClick}
                            onCommentClick={() => handlePostClick(post.id)}
                          />
                        </div>
                      )}
                    </div>
                  ) : null}
                </React.Fragment>
              ))}
            </>
          )}
          {posts.length > 3 && displayedPosts < posts.length && (
            <div
              className={classes.showAllPosts}
              onClick={() => navigate(`/all-posts/${userData.urlSlug}`)}
            >
              Show all posts
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 12H5m14 0-4 4m4-4-4-4"
                />
              </svg>
            </div>
          )}
        </div>
      </>
    );
  };

  const fetchFollowingCount = useCallback(async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setFollowingCount(userData.followingCount || 0);
      }
    } catch (error) {
      setFollowingCount(0);
    }
  }, []);

  const fetchAllData = async (userId, callbacks) => {
    const {
      setIsLoading,
      setIsLoadingConnections,
      setUserData,
      setExperiences,
      setEducation,
      fetchPosts,
      fetchComments,
      fetchReactions,
      fetchReposts,
      fetchActivityCounts,
      fetchConnections,
      setHasAttemptedLoad,
      setStartups,
      setStartupCounts,
      setStartupType,
      setCommunities,
      setCommunityCounts,
      setCommunityType,
      setLoadingCommunityType,
    } = callbacks;

    try {
      setIsLoading(true);
      setIsLoadingConnections(true);

      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const data = userDoc.data();

        setUserData((prevData) => ({
          ...prevData,
          id: userId,
          ...data,
        }));

        const [experiencesSnapshot, educationSnapshot] = await Promise.all([
          getDocs(collection(firestore, "users", userId, "experiences")),
          getDocs(collection(firestore, "users", userId, "education")),
        ]);

        const experiences = experiencesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          dateAdded: doc.data().dateAdded,
        }));

        const education = educationSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setExperiences(experiences || []);
        setEducation(education || []);

        try {
          const batch = [];
          const startupsSnapshot = await getDocs(
            collection(firestore, `users/${userId}/startups`)
          );
          const startupsData = startupsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            membershipType: "owner",
          }));
          batch.push(...startupsData);

          const allUsersSnapshot = await getDocs(
            collection(firestore, "users")
          );
          const teamMemberPromises = allUsersSnapshot.docs.map(
            async (userDoc) => {
              const startupRef = collection(
                firestore,
                `users/${userDoc.id}/startups`
              );
              const startupDocs = await getDocs(startupRef);

              const startupTeamPromises = startupDocs.docs.map(
                async (startupDoc) => {
                  const teamRef = doc(
                    firestore,
                    `users/${userDoc.id}/startups/${startupDoc.id}/team/${userId}`
                  );
                  const teamDoc = await getDoc(teamRef);

                  if (teamDoc.exists() && teamDoc.data().status === "active") {
                    return {
                      id: startupDoc.id,
                      ownerId: userDoc.id,
                      ...startupDoc.data(),
                      membershipType: "member",
                      role: teamDoc.data().role,
                      acceptedAt: teamDoc.data().acceptedAt,
                    };
                  }
                  return null;
                }
              );

              const resolvedStartups = await Promise.all(startupTeamPromises);
              return resolvedStartups.filter((startup) => startup !== null);
            }
          );

          const donationsRef = collection(
            firestore,
            `users/${userId}/donations`
          );
          const donationsSnapshot = await getDocs(donationsRef);
          const supportedPromises = donationsSnapshot.docs.map(
            async (donationDoc) => {
              const donationData = donationDoc.data();
              if (donationData.status !== "completed") return null;

              const startupRef = doc(
                firestore,
                `users/${donationData.startupOwnerId}/startups/${donationData.startupId}`
              );
              const startupDoc = await getDoc(startupRef);

              if (startupDoc.exists()) {
                return {
                  id: startupDoc.id,
                  ...startupDoc.data(),
                  supported: true,
                  supportedAt: donationData.createdAt,
                  ownerId: donationData.startupOwnerId,
                };
              }
              return null;
            }
          );

          const followedStartupsRef = collection(
            firestore,
            `users/${userId}/startupsFollowing`
          );
          const followedSnapshot = await getDocs(followedStartupsRef);
          const followedPromises = followedSnapshot.docs.map(
            async (followDoc) => {
              const startupRef = doc(
                firestore,
                `users/${followDoc.data().ownerId}/startups/${followDoc.id}`
              );
              const startupDoc = await getDoc(startupRef);

              if (startupDoc.exists()) {
                return {
                  id: startupDoc.id,
                  ...startupDoc.data(),
                  following: true,
                  followedAt: followDoc.data().followedAt,
                  ownerId: followDoc.data().ownerId,
                };
              }
              return null;
            }
          );

          const teamMemberStartups = (
            await Promise.all(teamMemberPromises)
          ).flat();
          const supportedStartups = (
            await Promise.all(supportedPromises)
          ).filter(Boolean);
          const followedStartups = (await Promise.all(followedPromises)).filter(
            Boolean
          );

          batch.push(
            ...teamMemberStartups,
            ...supportedStartups,
            ...followedStartups
          );

          // In fetchAllData, where startup data is processed:
          // In the fetchAllData function, where we process startups:
          const startupCounts = {
            Owner: batch.filter((s) => s.membershipType === "owner").length,
            TeamMember: batch.filter((s) => s.membershipType === "member")
              .length,
            Supported: batch.filter((s) => s.supported).length,
            Following: batch.filter((s) => s.following).length,
          };

          setStartups(batch);
          setStartupCounts(startupCounts);

          // Critical part - follow exact same pattern as communities
          const firstStartupType = Object.keys(startupCounts).find(
            (type) => startupCounts[type] > 0
          );

          if (firstStartupType) {
            setStartupType(firstStartupType);
            // Make sure loading state is triggered
            setLoadingStartupType(true);
            setTimeout(() => {
              setLoadingStartupType(false);
            }, 300);
          }
        } catch (error) {}

        try {
          const userCommunitiesRef = collection(
            firestore,
            `users/${userId}/communities`
          );
          const memberOfRef = collection(
            firestore,
            `users/${userId}/communitiesMemberOf`
          );
          const moderatingRef = collection(
            firestore,
            `users/${userId}/communitiesModerating`
          );

          const [
            userCommunitiesSnapshot,
            memberOfSnapshot,
            moderatingSnapshot,
          ] = await Promise.all([
            getDocs(userCommunitiesRef),
            getDocs(memberOfRef),
            getDocs(moderatingRef),
          ]);

          const ownedCommunitiesData = userCommunitiesSnapshot.docs.map(
            (doc) => ({
              id: doc.id,
              ...doc.data(),
              membershipType: "owner",
              createdAt: doc.data().createdAt,
            })
          );

          const memberCommunitiesPromises = memberOfSnapshot.docs.map(
            async (memberDoc) => {
              const memberData = memberDoc.data();
              const usersRef = collection(firestore, "users");
              const usersSnapshot = await getDocs(usersRef);

              for (const userDoc of usersSnapshot.docs) {
                const communityRef = doc(
                  firestore,
                  `users/${userDoc.id}/communities/${memberDoc.id}`
                );
                const communityDoc = await getDoc(communityRef);

                if (communityDoc.exists()) {
                  return {
                    id: memberDoc.id,
                    ownerId: userDoc.id,
                    ...communityDoc.data(),
                    membershipType: "member",
                    joinedAt: memberData.joinedAt || memberData.createdAt,
                  };
                }
              }
              return null;
            }
          );

          const moderatorCommunitiesPromises = moderatingSnapshot.docs.map(
            async (moderatorDoc) => {
              const moderatorData = moderatorDoc.data();
              const communityRef = doc(
                firestore,
                `users/${moderatorData.communityOwnerId}/communities/${moderatorDoc.id}`
              );
              const communityDoc = await getDoc(communityRef);

              if (communityDoc.exists()) {
                return {
                  id: moderatorDoc.id,
                  ownerId: moderatorData.communityOwnerId,
                  ...communityDoc.data(),
                  membershipType: "moderator",
                  acceptedAt: moderatorData.acceptedAt,
                };
              }
              return null;
            }
          );

          const [memberCommunitiesData, moderatorCommunitiesData] =
            await Promise.all([
              Promise.all(memberCommunitiesPromises),
              Promise.all(moderatorCommunitiesPromises),
            ]);

          const allCommunitiesData = [
            ...ownedCommunitiesData,
            ...memberCommunitiesData.filter(Boolean),
            ...moderatorCommunitiesData.filter(Boolean),
          ];

          const communityCountsData = {
            Owner: allCommunitiesData.filter(
              (c) => c.membershipType === "owner"
            ).length,
            Organiser: allCommunitiesData.filter(
              (c) => c.membershipType === "moderator"
            ).length,
            Member: allCommunitiesData.filter(
              (c) => c.membershipType === "member"
            ).length,
          };

          setCommunities(allCommunitiesData);
          setCommunityCounts(communityCountsData);

          const firstCommunityType = Object.keys(communityCountsData).find(
            (type) => communityCountsData[type] > 0
          );

          if (firstCommunityType) {
            const displayType =
              firstCommunityType === "Organiser"
                ? "Moderator"
                : firstCommunityType;
            setCommunityType(displayType);
            setLoadingCommunityType(true);
            setTimeout(() => {
              setLoadingCommunityType(false);
            }, 300);
          }
        } catch (error) {}

        try {
          const userEventsRef = collection(firestore, `users/${userId}/events`);
          const eventsOrganisingRef = collection(
            firestore,
            `users/${userId}/eventsOrganising`
          );
          const attendingEventsRef = collection(
            firestore,
            `users/${userId}/eventsAttending`
          );

          const [
            ownedEventsSnapshot,
            eventsOrganisingSnapshot,
            attendingEventsSnapshot,
          ] = await Promise.all([
            getDocs(userEventsRef),
            getDocs(eventsOrganisingRef),
            getDocs(attendingEventsRef),
          ]);

          const ownedEvents = ownedEventsSnapshot.docs.map((docSnapshot) => ({
            id: docSnapshot.id,
            ...docSnapshot.data(),
            membershipType: "owner",
          }));

          const organiserEventsPromises = eventsOrganisingSnapshot.docs.map(
            async (docSnapshot) => {
              const eventData = docSnapshot.data();
              if (!eventData.eventOwnerId) return null;

              const eventRef = doc(
                firestore,
                `users/${eventData.eventOwnerId}/events/${docSnapshot.id}`
              );
              const eventSnapshot = await getDoc(eventRef);

              if (eventSnapshot.exists()) {
                return {
                  id: docSnapshot.id,
                  ...eventSnapshot.data(),
                  membershipType: "Organiser", // Changed from "Organising" to "Organiser"
                  eventOwnerId: eventData.eventOwnerId,
                  acceptedAt: eventData.acceptedAt,
                };
              }
              return null;
            }
          );

          const organiserEvents = (
            await Promise.all(organiserEventsPromises)
          ).filter(Boolean);

          const attendingEventsPromises = attendingEventsSnapshot.docs.map(
            async (docSnapshot) => {
              const eventData = docSnapshot.data();
              const eventRef = doc(
                firestore,
                `users/${eventData.eventOwnerId}/events/${docSnapshot.id}`
              );
              const eventSnapshot = await getDoc(eventRef);

              if (eventSnapshot.exists()) {
                return {
                  id: docSnapshot.id,
                  ...eventSnapshot.data(),
                  membershipType: "attending",
                  endDate: eventData.endDate,
                  endTime: eventData.endTime,
                  timezone: eventData.timezone,
                };
              }
              return null;
            }
          );

          const attendingEvents = (
            await Promise.all(attendingEventsPromises)
          ).filter(Boolean);
          const allEvents = [
            ...ownedEvents,
            ...organiserEvents,
            ...attendingEvents,
          ];

          const eventCountsData = {
            Owner: ownedEvents.length,
            Organiser: organiserEvents.length, // Changed from Organising to Organiser
            Attending: attendingEvents.filter(
              (event) =>
                !isEventClosed({
                  endDate: event.endDate,
                  endTime: event.endTime,
                  timezone: event.timezone,
                })
            ).length,
            Attended: attendingEvents.filter((event) =>
              isEventClosed({
                endDate: event.endDate,
                endTime: event.endTime,
                timezone: event.timezone,
              })
            ).length,
          };

          setEvents(allEvents);
          setEventCounts(eventCountsData);

          if (!eventType) {
            const firstEventType = Object.keys(eventCountsData).find(
              (type) => eventCountsData[type] > 0
            );
            if (firstEventType) {
              setEventType(firstEventType);
              setLoadingEventType(true);
              setTimeout(() => {
                setLoadingEventType(false);
              }, 300);
            }
          }
        } catch (error) {}

        await Promise.all([
          fetchPosts(),
          fetchComments(),
          fetchReactions(),
          fetchReposts(),
          fetchActivityCounts(),
          fetchConnections(),
        ]);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      setIsLoadingConnections(false);
      setHasAttemptedLoad(true);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (!location.state?.userData && urlSlug) {
        try {
          setIsLoading(true);
          setHasAttemptedLoad(false);

          const q = query(
            collection(firestore, "users"),
            where("urlSlug", "==", urlSlug)
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const auth = getAuth();
            const currentUser = auth.currentUser;

            if (currentUser) {
              const currentUserBlockedRef = collection(
                firestore,
                `users/${currentUser.uid}/blockedUsers`
              );
              const targetUserBlockedRef = collection(
                firestore,
                `users/${userDoc.id}/blockedUsers`
              );

              const [currentBlocksTarget, targetBlocksCurrent] =
                await Promise.all([
                  getDocs(
                    query(
                      currentUserBlockedRef,
                      where("userId", "==", userDoc.id)
                    )
                  ),
                  getDocs(
                    query(
                      targetUserBlockedRef,
                      where("userId", "==", currentUser.uid)
                    )
                  ),
                ]);

              setHasUserBlockedThem(currentBlocksTarget.size > 0);
              setIsBlocked(
                currentBlocksTarget.size > 0 || targetBlocksCurrent.size > 0
              );
            }

            const userData = userDoc.data();
            const basicUserData = {
              id: userDoc.id,
              firstName: userData.firstName,
              lastName: userData.lastName,
              profileImage: userData.profileImage,
              urlSlug: userData.urlSlug,
              createdAt: userData.createdAt,
              city: userData.city,
              country: userData.country,
            };

            setUserData(basicUserData);

            if (!isBlocked) {
              await fetchAllData(userDoc.id, {
                setIsLoading,
                setIsLoadingConnections,
                setUserData,
                setExperiences,
                setEducation,
                fetchPosts,
                fetchComments,
                fetchReactions,
                fetchReposts,
                fetchActivityCounts,
                fetchConnections,
                setHasAttemptedLoad,
                setStartups,
                setStartupCounts,
                setStartupType,
                setCommunities,
                setCommunityCounts,
                setCommunityType,
                setLoadingCommunityType,
                setEvents,
                setEventCounts,
                setEventType,
                setLoadingEventType,
              });
            }
          }
        } catch (error) {
        } finally {
          setIsLoading(false);
          setHasAttemptedLoad(true);
        }
      } else if (location.state?.userData?.objectID) {
        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (currentUser) {
          const currentUserBlockedRef = collection(
            firestore,
            `users/${currentUser.uid}/blockedUsers`
          );
          const targetUserBlockedRef = collection(
            firestore,
            `users/${location.state.userData.objectID}/blockedUsers`
          );

          const [currentBlocksTarget, targetBlocksCurrent] = await Promise.all([
            getDocs(
              query(
                currentUserBlockedRef,
                where("userId", "==", location.state.userData.objectID)
              )
            ),
            getDocs(
              query(
                targetUserBlockedRef,
                where("userId", "==", currentUser.uid)
              )
            ),
          ]);

          setHasUserBlockedThem(currentBlocksTarget.size > 0);
          setIsBlocked(
            currentBlocksTarget.size > 0 || targetBlocksCurrent.size > 0
          );
        }

        const userDoc = await getDoc(
          doc(firestore, "users", location.state.userData.objectID)
        );
        const userData = userDoc.data();
        const basicUserData = {
          id: location.state.userData.objectID,
          firstName: userData.firstName,
          lastName: userData.lastName,
          profileImage: userData.profileImage,
          urlSlug: userData.urlSlug,
          createdAt: userData.createdAt,
          city: userData.city,
          country: userData.country,
        };

        setUserData(basicUserData);

        if (!isBlocked) {
          await fetchAllData(location.state.userData.objectID, {
            setIsLoading,
            setIsLoadingConnections,
            setUserData,
            setExperiences,
            setEducation,
            fetchPosts,
            fetchComments,
            fetchReactions,
            fetchReposts,
            fetchActivityCounts,
            fetchConnections,
            setHasAttemptedLoad,
            setStartups,
            setStartupCounts,
            setStartupType,
            setCommunities,
            setCommunityCounts,
            setCommunityType,
            setLoadingCommunityType,
            setEvents,
            setEventCounts,
            setEventType,
            setLoadingEventType,
          });
        }
      }
    };

    fetchUserData();
  }, [urlSlug, location.state]);

  const NoContentCard = ({ onRefresh }) => (
    <div className={classes.noNotifications}>
      <div className={classes.bold}>Page not found</div>
      <div className={classes.text}>Please check your internet connection.</div>
      <div className={classes.homeButton} onClick={onRefresh}>
        <svg
          className="w-6 h-6 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
          />
        </svg>
        Refresh
      </div>
    </div>
  );

  useEffect(() => {
    if (!selectedChat?.id || !currentUser) return;

    const messagesRef = collection(
      firestore,
      `chats/${selectedChat.id}/messages`
    );
    const q = query(messagesRef, orderBy("createdAt", "desc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messagesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messagesData);

      // Fetch user profiles for message senders if needed
      messagesData.forEach((message) => {
        if (!userProfiles[message.senderId]) {
          fetchUserProfile(message.senderId);
        }
      });
    });

    return () => {
      unsubscribe();
    };
  }, [selectedChat?.id, currentUser]);

  // Add user profile fetching function
  const fetchUserProfile = async (userId) => {
    if (!userId || userProfiles[userId]) return;

    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserProfiles((prev) => ({
          ...prev,
          [userId]: {
            id: userId,
            name: `${userData.firstName} ${userData.lastName}`,
            image: userData.profileImage || defaultImage,
          },
        }));
      }
    } catch (error) {}
  };

  // Update the message click handler
  const handleMessageClick = async () => {
    const chatId = [currentUser.uid, userData.id].sort().join("_");
    const chatData = {
      id: chatId,
      otherUser: {
        id: userData.id,
        name: `${userData.firstName} ${userData.lastName}`,
        image: userData.profileImage || defaultImage,
        bio: userData.bio || "",
        link: userData.link || "",
        linkText: userData.linkText || "",
        connectionType: connectionType,
      },
      participants: [currentUser.uid, userData.id],
    };

    try {
      const chatRef = doc(firestore, `chats/${chatId}`);
      const chatDoc = await getDoc(chatRef);

      if (!chatDoc.exists()) {
        await setDoc(chatRef, {
          participants: [currentUser.uid, userData.id],
          createdAt: serverTimestamp(),
          hasMessages: false,
          lastMessageTime: serverTimestamp(),
        });
      }

      // Mark this specific chat as read when opened via ChatModal
      await markChatAsRead(chatId);

      setSelectedChat(chatData);
      setShowChatModal(true);
    } catch (error) {}
  };

  // Add close handler
  const handleCloseChatModal = () => {
    setShowChatModal(false);
    setSelectedChat(null);
    setMessages([]);
  };

  // Add message sent handler
  const handleMessageSent = async () => {
    if (!selectedChat?.id) return;

    try {
      const chatRef = doc(firestore, `chats/${selectedChat.id}`);
      await updateDoc(chatRef, {
        hasMessages: true,
      });
    } catch (error) {}
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        // Fetch current user's profile image
        getDoc(doc(firestore, "users", user.uid)).then((userDoc) => {
          if (userDoc.exists()) {
            setCurrentUserImage(userDoc.data().profileImage || defaultImage);
          }
        });
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 648);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const BadgesCard = ({
    isProfileComplete = false,
    postsCount = 0,
    boostsCount = 0,
    commentsCount = 0,
    connectionsCount = 0,
    hasStartup = false,
    startupTeamCount = 0,
    startupFollowingCount = 0,
    startupSupportedCount = 0,
    communityLeadCount = 0,
    communitiesModeratingCount = 0,
    communitiesMemberCount = 0,
    eventsCreatedCount = 0,
    eventsHostedCount = 0,
    eventsAttendedCount = 0,
    skillsCount = 0,
    interestsCount = 0,
    experiencesCount = 0,
  }) => {
    const [visibleBadges, setVisibleBadges] = useState(9);

    const allBadges = [
      {
        id: "complete-curator",
        name: "Complete Curator",
        earned: isProfileComplete,
      },
      {
        id: "top-contributor",
        name: "Top Contributor",
        earned: postsCount >= 250,
      },
      {
        id: "growth-hacker",
        name: "Growth Hacker",
        earned: boostsCount >= 500,
      },
      {
        id: "conversation-starter",
        name: "Conversation Starter",
        earned: commentsCount >= 500,
      },
      {
        id: "social-butterfly",
        name: "Social Butterfly",
        earned: connectionsCount >= 500,
      },
      {
        id: "first-mover",
        name: "First Mover",
        earned: hasStartup,
      },
      {
        id: "team-player",
        name: "Team Player",
        earned: startupTeamCount >= 5,
      },
      {
        id: "dream-backer",
        name: "Dream Backer",
        earned: startupSupportedCount >= 25,
      },
      {
        id: "venture-pilgrim",
        name: "Venture Pilgrim",
        earned: startupFollowingCount >= 25,
      },
      {
        id: "voice-champion",
        name: "Voice Champion",
        earned: communityLeadCount >= 5,
      },
      {
        id: "peace-keeper",
        name: "Peace Keeper",
        earned: communitiesModeratingCount >= 5,
      },
      {
        id: "wisdom-cultivator",
        name: "Wisdom Cultivator",
        earned: communitiesMemberCount >= 10,
      },
      {
        id: "scene-maker",
        name: "Scene Maker",
        earned: eventsCreatedCount >= 10,
      },
      {
        id: "moment-orchestrator",
        name: "Moment Orchestrator",
        earned: eventsHostedCount >= 10,
      },
      {
        id: "dedicated-explorer",
        name: "Dedicated Explorer",
        earned: eventsAttendedCount >= 25,
      },
      {
        id: "grand-master",
        name: "Grand Master",
        earned: skillsCount >= 50,
      },
      {
        id: "deep-seeker",
        name: "Deep Seeker",
        earned: interestsCount >= 25,
      },
      {
        id: "journey-sage",
        name: "Journey Sage",
        earned: experiencesCount >= 15,
      },
    ];

    // Filter out unearned badges
    const earnedBadges = allBadges.filter((badge) => badge.earned);

    // If no badges are earned, return null (don't show the card)
    if (earnedBadges.length === 0) {
      return null;
    }

    return (
      <div className={classes.card}>
        <div className={classes.cardTitle}>Badges</div>
        <div className={classes.badges}>
          <div className={classes.badgesContainer}>
            {earnedBadges.slice(0, visibleBadges).map((badge) => (
              <div key={badge.id} className={classes.skillContainer}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
                  <path
                    fill-rule="evenodd"
                    d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                    clip-rule="evenodd"
                  />
                  <path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z" />
                </svg>

                <span
                  className={classes.skillText}
                  style={{ color: "orangered", fontWeight: "900" }}
                >
                  {badge.name}
                </span>
              </div>
            ))}
          </div>
        </div>

        {earnedBadges.length > 9 && (
          <div
            className={classes.showAllExperiences}
            onClick={() => {
              setVisibleBadges(
                visibleBadges >= earnedBadges.length
                  ? 9
                  : Math.min(
                      visibleBadges + visibleBadges * 2,
                      earnedBadges.length
                    )
              );
            }}
          >
            {visibleBadges >= earnedBadges.length ? "Show less" : "Show more"}
            {visibleBadges >= earnedBadges.length ? (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m5 15 7-7 7 7"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 9-7 7-7-7"
                />
              </svg>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderBadgesCard = () => {
    if (isBlocked) return null;

    // Calculate profile completion
    const isProfileComplete = !!(
      userData.profileImage &&
      !userData.profileImage.includes("defaultImage.jpg") &&
      !userData.profileImage.includes("default.jpg") &&
      userData.intro &&
      userData.skills?.length > 0 &&
      userData.interests?.length > 0 &&
      experiences?.length > 0 &&
      education?.length > 0
    );

    return (
      <BadgesCard
        isProfileComplete={isProfileComplete}
        postsCount={activityCounts.Posts || 0}
        boostsCount={activityCounts.Boosts || 0}
        commentsCount={activityCounts.Comments || 0}
        connectionsCount={connectionCount}
        hasStartup={startupCounts.Owner > 0}
        startupTeamCount={startupCounts.TeamMember}
        startupFollowingCount={startupCounts.Following}
        startupSupportedCount={startupCounts.Supported}
        communityLeadCount={communityCounts.Owner}
        communitiesModeratingCount={communityCounts.Moderator}
        communitiesMemberCount={communityCounts.Member}
        eventsCreatedCount={eventCounts.Owner}
        eventsHostedCount={eventCounts.Organiser}
        eventsAttendedCount={eventCounts.Attended}
        skillsCount={userData.skills?.length || 0}
        interestsCount={userData.interests?.length || 0}
        experiencesCount={experiences?.length || 0}
      />
    );
  };

  if (isLoading) {
    return (
      <div className={classes.page}>
        {isMobile ? (
          <MobileNavbar userImage={currentUserImage} />
        ) : (
          <MainNavbar userImage={currentUserImage} />
        )}

        <div className={classes.content}>
          <div className={classes.centreCards}>
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  if (hasTimedOut) {
    return (
      <div className={classes.page}>
        {isMobile ? (
          <MobileNavbar userImage={currentUserImage} />
        ) : (
          <MainNavbar userImage={currentUserImage} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <NoContentCard onRefresh={() => window.location.reload()} />
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  const toggleFollowModal = () => {
    if (!showFollowModal) {
      setFollowModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, followModalScrollPosition);
      }, 0);
    }
    setShowFollowModal(!showFollowModal);
    setIsFollowModalOpen(!isFollowModalOpen);
  };

  return (
    <div
      className={`${classes.page} ${
        isConnectionsModalOpen ||
        isMutualConnectionsModalOpen ||
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isFollowModalOpen // Add this line
          ? classes.noScroll
          : ""
      }`}
      style={
        isConnectionsModalOpen
          ? { top: `-${connectionsModalScrollPosition}px` }
          : isMutualConnectionsModalOpen
          ? { top: `-${mutualConnectionsModalScrollPosition}px` }
          : isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isFollowModalOpen // Add this line
          ? { top: `-${followModalScrollPosition}px` } // Add this line
          : {}
      }
    >
      {isMobile ? (
        <MobileNavbar userImage={currentUserImage} />
      ) : (
        <MainNavbar userImage={currentUserImage} />
      )}
      {actionMessage && (
        <div className={classes.repostPopup}>
          <div className={classes.repostPopupContent}>
            <svg
              className={classes.tickIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="orangered"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                clipRule="evenodd"
              />
            </svg>
            <p>{actionMessage.text}</p>
          </div>
          <div className={classes.repostCloseButton}>
            <svg
              className={classes.repostCloseIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
              viewBox="0 0 24 24"
              onClick={() => setActionMessage(null)}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
      )}
      {showChatModal && selectedChat && (
        <ChatModal
          onClose={handleCloseChatModal}
          selectedChat={selectedChat}
          currentUser={currentUser}
          messages={messages}
          userProfiles={userProfiles}
          onMessageSent={handleMessageSent}
        />
      )}
      <div className={classes.content}>
        {showConnectionsModal && (
          <ConnectionsModal
            onClose={handleCloseConnectionsModal}
            currentUserId={userData.id}
            loggedInUserId={currentUser?.uid}
            isOwnProfile={false}
            showConnectionDate={false}
          />
        )}
        {showFollowModal && (
          <FollowModal
            onClose={() => {
              toggleFollowModal();
            }}
            currentUserId={userData.id}
            onUnfollowed={() => {
              fetchFollowingCount(userData.id);
              toggleFollowModal();
            }}
            showDropdown={false}
          />
        )}
        {showMutualConnectionsModal && (
          <MutualConnectionsModal
            onClose={handleCloseMutualConnectionsModal}
            currentUserId={userData.id}
            loggedInUserId={currentUser?.uid}
          />
        )}
        {showReactionsModal && activePostReactions && (
          <ReactionsModal
            onClose={handleCloseReactionsModal}
            postId={activePostReactions.postId}
            reactions={activePostReactions.reactions}
            currentUserId={currentUser.uid}
          />
        )}
        {showRepostsModal && activePostReposts && (
          <RepostsModal
            onClose={handleCloseRepostsModal}
            postId={activePostReposts.postId}
            reposts={activePostReposts.reposts}
            currentUserId={currentUser.uid}
          />
        )}

        <div className={classes.centreCards}>
          {isLoading ? (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          ) : hasAttemptedLoad &&
            (!userData || Object.keys(userData).length === 0) ? (
            <NoContentCard onRefresh={() => window.location.reload()} />
          ) : (
            <>
              {/* Existing profile card and other content */}
              <div className={classes.profileCard}>
                <div className={classes.profileHeader}>
                  <div className={classes.profileImage}>
                    <img src={userImage || defaultImage} alt="Profile" />
                  </div>
                  <div
                    onClick={() => setShowDropdown(!showDropdown)}
                    className={classes.optionsButton}
                    ref={optionsButtonRef}
                  >
                    <div
                      className={`${classes.closeIcon} ${
                        showDropdown ? classes.closeIconActive : ""
                      }`}
                    >
                      <svg
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                {showDropdown && (
                  <div ref={dropdownRef} className={classes.optionsDropdown}>
                    <OptionsDropdown />
                  </div>
                )}

                <div className={classes.profileTitle}>
                  {userData.firstName} {userData.lastName}{" "}
                  {connectionType !== "You" && (
                    <span className={classes.connectionType}>
                      • {isBlocked ? "Blocked" : connectionType}
                    </span>
                  )}
                </div>

                {(userData.bio || userData.link) && (
                  <div className={classes.userDetails}>
                    {userData.bio && (
                      <div className={classes.bio}>{userData.bio}</div>
                    )}
                    {userData.link && (
                      <div className={classes.link}>
                        <a
                          href={userData.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {userData.linkText || userData.link}
                        </a>
                        <svg
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="orangeRed"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                )}

                <div className={classes.buttons}>
                  {!isBlocked && (
                    <>
                      <button
                        className={
                          connectionStatus === "Connected"
                            ? classes.following
                            : connectionStatus === "Pending"
                            ? classes.following
                            : hasReceivedRequest
                            ? classes.headlineButton
                            : classes.headlineButton
                        }
                        onClick={
                          connectionStatus === "Connected"
                            ? handleRemoveConnection
                            : connectionStatus === "Pending"
                            ? handleRemoveRequest
                            : handleConnect
                        }
                      >
                        <span>
                          {connectionStatus === "Connected" ? (
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : connectionStatus === "Pending" ? (
                            <svg
                              className="w-6 h-6"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : hasReceivedRequest ? (
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                                clipRule="evenodd"
                              />
                              <path
                                fillRule="evenodd"
                                d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : (
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                                clipRule="evenodd"
                              />
                              <path
                                fillRule="evenodd"
                                d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                        </span>
                        {connectionStatus === "Connected"
                          ? "Connected"
                          : connectionStatus === "Pending"
                          ? "Pending"
                          : hasReceivedRequest
                          ? "Accept"
                          : "Connect"}
                      </button>
                      <button
                        className={`${classes.button} ${
                          connectionType === "Direct"
                            ? classes.directMessageButton
                            : ""
                        }`}
                        onClick={handleMessageClick}
                      >
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-6.616l-2.88 2.592C8.537 20.461 7 19.776 7 18.477V17H5a2 2 0 0 1-2-2V6Zm4 2a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2H7Zm8 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Zm-8 3a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H7Zm5 0a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Message
                      </button>
                    </>
                  )}
                  <div
                    className={`${
                      shareButtonText === "Copied"
                        ? classes.following
                        : classes.button
                    }`}
                    onClick={handleShare}
                  >
                    {shareButtonText === "Copied" ? (
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                          clipRule="evenodd"
                        />
                        <path
                          fillRule="evenodd"
                          d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                      </svg>
                    )}
                    {shareButtonText}
                  </div>
                </div>
              </div>

              {!isBlocked && (
                <>
                  {renderIntroCard()}
                  {renderActivityCard()}
                  {renderConnectionsCard()}
                  {renderStartupsCard()}
                  {renderCommunitiesCard()}
                  {renderEventsCard()}
                  {renderBadgesCard()}
                  {renderSkillsCard()}
                  {renderInterestsCard()}
                  {renderExperiencesCard()}
                  {renderEducationCard()}
                </>
              )}
            </>
          )}
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
