import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase"; // Make sure to import firestore
import classes from "./ProfileSidebar.module.css";
import defaultImage from "../../assets/icons/profileImage.jpg";

function ProfileSidebar({ isOpen, onClose, userData: initialUserData }) {
  const { signOut, currentUser } = useAuth();
  const navigate = useNavigate();
  const scrollPositionRef = useRef(0);
  const [userData, setUserData] = useState(initialUserData);

  useEffect(() => {
    const fetchLatestUserData = async () => {
      if (currentUser && isOpen) {
        try {
          const userDocRef = doc(firestore, "users", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const latestUserData = userDocSnap.data();
            setUserData(latestUserData);
          }
        } catch (error) {}
      }
    };

    fetchLatestUserData();
  }, [currentUser, isOpen]);

  useEffect(() => {
    const handleWheel = (e) => {
      if (isOpen) {
        e.preventDefault();
      }
    };

    const handleTouchMove = (e) => {
      if (isOpen) {
        e.preventDefault();
      }
    };

    if (isOpen) {
      scrollPositionRef.current = window.pageYOffset;
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
      document.body.style.top = `-${scrollPositionRef.current}px`;

      window.addEventListener("wheel", handleWheel, { passive: false });
      window.addEventListener("touchmove", handleTouchMove, { passive: false });
    } else {
      document.body.style.overflow = "";
      document.body.style.height = "";
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, scrollPositionRef.current);

      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("touchmove", handleTouchMove);
    }

    return () => {
      document.body.style.overflow = "";
      document.body.style.height = "";
      document.body.style.position = "";
      document.body.style.top = "";
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, [isOpen]);

  const handleSignOut = async () => {
    try {
      await signOut();
      onClose();
      navigate("/login");
    } catch (error) {}
  };

  const handleProfileClick = () => {
    if (userData?.urlSlug) {
      onClose();
      navigate(`/${userData.urlSlug}`);
    }
  };

  const handleSettingsClick = () => {
    onClose();
    navigate("/settings");
  };

  if (!isOpen) return null;

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.interactions}>
          <div className={classes.profileInfo}>
            <img
              src={userData.profileImage || defaultImage}
              alt="Profile"
              className={classes.profileImage}
              onClick={handleProfileClick}
            />
            <div className={classes.userInfoContainer}>
              <h2 className={classes.userName} onClick={handleProfileClick}>
                {userData.firstName} {userData.lastName}
              </h2>
              <Link
                to={`/${userData.urlSlug}`}
                className={classes.viewProfile}
                onClick={onClose}
              >
                View profile
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.cardFooter}>
          {/* <button onClick={handleSignOut} className={classes.logOutButton}>
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M16 12H4m12 0-4 4m4-4-4-4m3-4h2a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-2"
              />
            </svg>
            Log out
          </button> */}
          <button
            className={classes.logOutButton}
            onClick={handleSettingsClick}
          >
            <svg
              className="w-6 h-6 text-gray"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 13v-2a1 1 0 0 0-1-1h-.757l-.707-1.707.535-.536a1 1 0 0 0 0-1.414l-1.414-1.414a1 1 0 0 0-1.414 0l-.536.535L14 4.757V4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v.757l-1.707.707-.536-.535a1 1 0 0 0-1.414 0L4.929 6.343a1 1 0 0 0 0 1.414l.536.536L4.757 10H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h.757l.707 1.707-.535.536a1 1 0 0 0 0 1.414l1.414 1.414a1 1 0 0 0 1.414 0l.536-.535 1.707.707V20a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-.757l1.707-.708.536.536a1 1 0 0 0 1.414 0l1.414-1.414a1 1 0 0 0 0-1.414l-.535-.536.707-1.707H20a1 1 0 0 0 1-1Z"
              />
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
              />
            </svg>
            Settings
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfileSidebar;
