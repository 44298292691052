import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { app } from "./firebase";

const analytics = getAnalytics(app);

export const userAnalytics = {
  trackUserActivity: () => {
    logEvent(analytics, "user_engagement", {
      activity_type: "active_user",
      period_type: ["daily", "weekly", "monthly"],
      timestamp: new Date().toISOString(),
    });
  },

  startSession: () => {
    const startTime = new Date();
    sessionStorage.setItem("session_start", startTime.toISOString());
    logEvent(analytics, "session_start", {
      timestamp: startTime.toISOString(),
    });
  },

  endSession: () => {
    const sessionStart = sessionStorage.getItem("session_start");
    if (sessionStart) {
      const startTime = new Date(sessionStart);
      const endTime = new Date();
      const durationInSeconds = (endTime - startTime) / 1000;

      logEvent(analytics, "session_end", {
        duration_seconds: durationInSeconds,
        timestamp: endTime.toISOString(),
      });
    }
  },
};

export const initializeAnalytics = () => {
  userAnalytics.trackUserActivity();
  userAnalytics.startSession();

  window.addEventListener("beforeunload", () => {
    userAnalytics.endSession();
  });
};
