import React, { useState, useEffect } from "react";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./EditExperienceModal.module.css";
import { Link } from "react-router-dom";

const EditExperienceModal = ({
  onClose,
  currentUser,
  onExperienceUpdated,
  onEditSingle,
}) => {
  const [experiences, setExperiences] = useState([]);
  const [hoverStates, setHoverStates] = useState({});
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredExperiences = experiences.filter((experience) => {
    return (
      experience.organisation
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      experience.role.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const toggleDropdown = (experienceId) => {
    setOpenDropdown(openDropdown === experienceId ? null : experienceId);
  };

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const fetchExperiences = async () => {
      setLoading(true);
      try {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const experiencesCollectionRef = collection(userDocRef, "experiences");
        const experiencesSnapshot = await getDocs(experiencesCollectionRef);
        let experiencesList = experiencesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        experiencesList.sort((a, b) => {
          const aEndDate = a.isCurrentlyWorking
            ? Date.now()
            : new Date(`${a.endMonth} 1, ${a.endYear}`).getTime();
          const bEndDate = b.isCurrentlyWorking
            ? Date.now()
            : new Date(`${b.endMonth} 1, ${b.endYear}`).getTime();

          if (aEndDate !== bEndDate) {
            return bEndDate - aEndDate; // Most recent end date first
          }

          const aStartDate = new Date(
            `${a.startMonth} 1, ${a.startYear}`
          ).getTime();
          const bStartDate = new Date(
            `${b.startMonth} 1, ${b.startYear}`
          ).getTime();

          if (aStartDate !== bStartDate) {
            return bStartDate - aStartDate; // Most recent start date first
          }

          // If both start and end dates are the same, use dateAdded
          return (
            (b.dateAdded?.toMillis() || 0) - (a.dateAdded?.toMillis() || 0)
          );
        });

        setExperiences(experiencesList);
        setHoverStates(
          experiencesList.reduce(
            (acc, exp) => ({
              ...acc,
              [exp.id]: { edit: false, delete: false },
            }),
            {}
          )
        );
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchExperiences();
  }, [currentUser.uid]);

  const handleEditClick = (experience) => {
    onEditSingle(experience);
  };

  const handleDeleteExperience = async (experienceId) => {
    try {
      const userDocRef = doc(firestore, "users", currentUser.uid);
      const experienceDocRef = doc(userDocRef, "experiences", experienceId);
      await deleteDoc(experienceDocRef);
      const updatedExperiences = experiences.filter(
        (exp) => exp.id !== experienceId
      );
      setExperiences(updatedExperiences);
      onExperienceUpdated({ experiences: updatedExperiences });
      setOpenDropdown(null); // Close the dropdown after deletion

      // Close the modal if this was the last experience
      if (updatedExperiences.length === 0) {
        onClose();
      }
    } catch (error) {}
  };

  const handleMouseEnter = (id, type) => {
    setHoverStates((prev) => ({
      ...prev,
      [id]: { ...prev[id], [type]: true },
    }));
  };

  const handleMouseLeave = (id, type) => {
    setHoverStates((prev) => ({
      ...prev,
      [id]: { ...prev[id], [type]: false },
    }));
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      // Don't scroll here, let Profile.js handle it
    };
  }, []);

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose();
    }
    setMouseDownOnBackdrop(false);
  };

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Experiences ({experiences.length})
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className={classes.experiencesCardAbout}>
          {loading ? (
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          ) : experiences.length === 0 ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No experience found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          ) : (
            filteredExperiences.map((experience) => (
              <div key={experience.id} className={classes.experienceContainer}>
                <div className={classes.experienceContent}>
                  <div className={classes.experienceInfo}>
                    <h3 className={classes.organisation}>
                      {experience.organisation}
                    </h3>
                    <p className={classes.role}>{experience.role}</p>

                    <p className={classes.dates}>{`${experience.startMonth} ${
                      experience.startYear
                    } - ${
                      experience.isCurrentlyWorking
                        ? "Current"
                        : `${experience.endMonth} ${experience.endYear}`
                    }`}</p>
                    {experience.description && (
                      <p className={classes.description}>
                        {experience.description}
                      </p>
                    )}
                  </div>
                  <div className={classes.buttonContainer}>
                    <div className={classes.iconContainer}>
                      <svg
                        className={`${classes.optionsIcon} ${
                          openDropdown === experience.id
                            ? classes.optionsIconActive
                            : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={() => toggleDropdown(experience.id)}
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                      {openDropdown === experience.id && (
                        <div className={classes.dropdown}>
                          <div
                            className={`${classes.dropdownItem} ${classes.dropdownItemFirst}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditClick(experience);
                            }}
                          >
                            <svg
                              class="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                              />
                            </svg>

                            <span>Edit</span>
                          </div>
                          <div
                            className={`${classes.dropdownItem} ${classes.dropdownItemLast}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteExperience(experience.id);
                            }}
                          >
                            <svg
                              class="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                              />
                            </svg>

                            <span>Delete</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default EditExperienceModal;
