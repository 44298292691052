import Nav from "../../../components/Navbars/Nav";
import classes from "./Splash.module.css";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";

function Splash() {
  return (
    <div className={classes.page}>
      <Nav />

      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.profileCard}>
            <div className={classes.profileTitle}>
              {/* The startup community, at your fingertips. */}
              {/* Connecting the world's startup community, all-in-one place. */}
              Welcome to Foundry! 👋
            </div>
            <div className={classes.bio}>
              Foundry is the social platform designed to connect the world's
              startup community, all-in-one place—a digital San Francisco.
            </div>
            <div className={classes.buttons}>
              {/* What are you waiting for? */}
              <a href="/signup" className={classes.headlineButton}>
                Join Foundry now
              </a>
            </div>
            <div className={classes.noPostBorder}></div>
            <div className={classes.images}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/nelson.jpeg?alt=media&token=d862ad9d-2b8f-43db-b398-40e731b7088c"
                className={classes.smallImage}
                loading="lazy"
                alt=""
              ></img>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/teachone.jpg?alt=media&token=0616b756-099c-4f8b-8f12-9e43bedbdc02"
                className={classes.smallImage2}
                loading="lazy"
                alt=""
              ></img>
              <div className={classes.smallPrint}>
                <span className={classes.bold}>500+</span> founders (inc. YC
                alumni), collaborators, investors, advisors, mentors, startups,
                communities, and events.
              </div>
            </div>
          </div>

          <div className={classes.profileCard}>
            <div className={classes.header}>
              <div className={classes.profileTitle}>
                The startup community, at your fingertips
              </div>
              {/* Want to showcase your work? Build your network? Share your
              expertise?  */}
              Create your profile and connect with founders, collaborators,
              investors, advisors, and mentors from around the world.
            </div>
            <div className={classes.profileHeader}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/henry.jpeg?alt=media&token=eacef45b-1e46-4dd8-b247-660d925ee117"
                loading="lazy"
                alt=""
                className={classes.profileImage}
              ></img>

              <div className={classes.optionsButton}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeWidth="3"
                    d="M6 12h.01m6 0h.01m5.99 0h.01"
                  />
                </svg>
              </div>
            </div>

            <div className={classes.profileTitle}>
              Henry Diep
              <span className={classes.connectionType}>• Indirect</span>
            </div>
            <div className={classes.userDetails}>
              <div className={classes.userBio}>
                CEO @ DearFlow (Techstars '24) | Building AI executive assistant
                that gives you back 1 hour every day ✨
              </div>
              <div className={classes.link}>
                https://www.dearflow.ai/
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="orangeRed"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                  />
                </svg>
              </div>
            </div>

            <div className={classes.buttons}>
              <button className={classes.connect}>
                <span>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                Connect
              </button>
              <button className={classes.button}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-6.616l-2.88 2.592C8.537 20.461 7 19.776 7 18.477V17H5a2 2 0 0 1-2-2V6Zm4 2a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2H7Zm8 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Zm-8 3a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H7Zm5 0a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Message
              </button>
              <div className={classes.button}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                </svg>
                Share
              </div>
            </div>
          </div>
          <div className={classes.profileCard}>
            <div className={classes.header}>
              <div className={classes.profileTitle}>
                {/* Startups of any type, any size, anywhere */}
                Where startups start, grow, and thrive
              </div>
              {/* Want to start a startup? Looking for a co-founder? Need
              investment? */}
              Build your following, assemble your team, and raise money with
              support from the community.
            </div>
            <div className={classes.profileHeader}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/ganddee.png?alt=media&token=722da9b3-e931-4e3f-9e83-5f70dd3204c2"
                loading="lazy"
                alt=""
                className={classes.startupImage}
              ></img>

              <div className={classes.optionsButton}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeWidth="3"
                    d="M6 12h.01m6 0h.01m5.99 0h.01"
                  />
                </svg>
              </div>
            </div>

            <div className={classes.profileTitle}>
              Ganddee
              <span className={classes.connectionType}>• Startup</span>
            </div>
            <div className={classes.userDetails}>
              <div className={classes.userBio}>
                The app to find second-hand shops, carboot sales and pop-ups.
              </div>
              <div className={classes.link}>
                Download the app
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="orangeRed"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                  />
                </svg>
              </div>
            </div>

            <div className={classes.buttons}>
              <button className={classes.connect}>
                <span>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                Follow
              </button>
              <button className={classes.button}>
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M7 6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-2v-4a3 3 0 0 0-3-3H7V6Z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M2 11a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7Zm7.5 1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z"
                    clipRule="evenodd"
                  />
                  <path d="M10.5 14.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
                </svg>
                Support
              </button>
              <div className={classes.button}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                </svg>
                Share
              </div>
            </div>
          </div>
          <div className={classes.profileCard}>
            <div className={classes.header}>
              <div className={classes.profileTitle}>
                Join the conversation, shape the future
              </div>
              {/* Need startup advice? Want to share your journey?  */}
              Join communities that match your interests, or create your own.
              Start meaningful discussions with people who get it.
            </div>
            <div className={classes.profileHeader}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/torouise.png?alt=media&token=5132897b-1a03-4909-a228-8bfb533a5e52"
                loading="lazy"
                alt=""
                className={classes.communityImage}
              ></img>

              <div className={classes.optionsButton}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeWidth="3"
                    d="M6 12h.01m6 0h.01m5.99 0h.01"
                  />
                </svg>
              </div>
            </div>

            <div className={classes.profileTitle}>
              Tortoise Valley
              <span className={classes.connectionType}>• Community</span>
            </div>
            <div className={classes.userDetails}>
              <div className={classes.userBio}>
                A cozy corner for those who believe great startups are like fine
                wines and take time to mature. Here you'll find proud tortoises
                sharing their journeys of building startups that prioritize life
                balance over hypergrowth.
              </div>
              {/* <div className={classes.link}>
                Join the waitlist
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="orangeRed"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                  />
                </svg>
              </div> */}
            </div>

            <div className={classes.buttons}>
              <button className={classes.connect}>
                <span>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                Join
              </button>

              <div className={classes.button}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                </svg>
                Share
              </div>
            </div>
          </div>
          <div className={classes.profileCard}>
            <div className={classes.header}>
              <div className={classes.profileTitle}>
                Digital meets physical, and beyond
              </div>
              {/* Want to organize a meetup? Launch a hackathon? Host a conference? */}
              Create and promote your events, or discover what's happening
              around you.
            </div>
            <div className={classes.profileHeader}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/cyberjam.png?alt=media&token=316817d2-e47a-46c7-9c30-970f427f3341"
                loading="lazy"
                alt=""
                className={classes.eventImage}
              ></img>

              <div className={classes.optionsButton}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeWidth="3"
                    d="M6 12h.01m6 0h.01m5.99 0h.01"
                  />
                </svg>
              </div>
            </div>

            <div className={classes.profileTitle}>
              Cyberjam
              <span className={classes.connectionType}>• Event</span>
            </div>
            <div className={classes.userDetails}>
              <div className={classes.userBio}>
                Hack, collaborate, and build solutions that make a positive
                impact on our digital future.
              </div>
              <div className={classes.link}>
                Register here
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="orangeRed"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                  />
                </svg>
              </div>
            </div>

            <div className={classes.buttons}>
              <button className={classes.connect}>
                <span>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                      clip-rule="evenodd"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                Attend
              </button>
              <div className={classes.button}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                </svg>
                Share
              </div>
            </div>
          </div>
          <div className={classes.profileCard}>
            <div className={classes.header}>
              <div className={classes.profileTitle}>
                The real startup journey, unfiltered
              </div>
              {/* Want the real startup journey? Not just the highlight reel? */}
              Scrappy iterations, failed experiments, unexpected wins, and
              everything in between. Post updates, boost others, and start
              conversations.
            </div>

            <div className={classes.postHeader}>
              <div className={classes.postUserInfo}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/aibek.jpeg?alt=media&token=8d038fc4-a19b-4ecf-a262-63a376131356"
                  loading="lazy"
                  alt=""
                  className={classes.postImage}
                />
                <div className={classes.postUserDetails}>
                  <div className={classes.userNameContainer}>
                    <span className={classes.userName}>Aibek Yegemberdin</span>
                    <span className={classes.userConnectionType}>• Direct</span>
                  </div>
                  <p className={classes.postBio}>
                    Co-Founder of Superflex 🦾 | Figma → Code in Seconds
                  </p>

                  <div className={classes.userLinkContainer}>
                    <span className={classes.userLinkText}>
                      Code 10X faster 🚀
                    </span>
                    <svg
                      className={classes.userLinkIcon}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="orangered"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                      />
                    </svg>
                  </div>

                  <div className={classes.timeAndEditDetails}>
                    <p className={classes.time}>1h</p>
                  </div>
                </div>
              </div>

              <div className={classes.postOptions}>
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeWidth="3"
                    d="M6 12h.01m6 0h.01m5.99 0h.01"
                  />
                </svg>
              </div>
            </div>

            <div className={classes.postTextContainer}>
              <div
                className={`${classes.postText} ${classes.preserveFormatting}`}
              />
              Yesterday, I hosted a Founders event in Palo Alto, and was very
              glad at how everything turned out 🥳 I didn't want to have an
              event where you speed through talking to 15 people, just to forget
              everyone and have shallow conversations
              <button className={classes.showMoreButton}>...more</button>
            </div>
            <div className={classes.postCard}>
              <div className={classes.imageCarousel}>
                <div style={{ position: "relative" }}>
                  <div className={classes.imageCounter}>
                    <div className={classes.imageCounterInner}>1/5</div>
                  </div>

                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/1724447553292.jpeg?alt=media&token=db60e9b9-f6e0-40b1-baca-fc37457088ad"
                    loading="lazy"
                    alt=""
                    className={classes.postContentImage}
                  />

                  {/* Navigation buttons - only show if there are multiple images */}

                  <div className={classes.navigationButtons}>
                    <button className={classes.navButton}>
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m14 8-4 4 4 4"
                        />
                      </svg>
                    </button>
                    <button className={classes.navButton}>
                      <svg
                        class="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m10 16 4-4-4-4"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.postInteractionsInfo}>
              See who boosted
              <svg
                className={classes.interactionInfo}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 20V7m0 13-4-4m4 4 4-4m4-12v13m0-13 4 4m-4-4-4 4"
                />
              </svg>
            </div>
            <div className={classes.postInteractions}>
              <div className={classes.interactionToggle}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/IMG_4606.jpg?alt=media&token=2bfecb07-ff33-4060-969e-e52f6746e862"
                    loading="lazy"
                    alt=""
                    style={{
                      borderRadius: "50%",
                    }}
                  />

                  <svg
                    style={{
                      marginLeft: "-0rem",
                      marginRight: "-0.375rem",
                    }}
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m8 10 4 4 4-4"
                    />
                  </svg>
                </div>
              </div>
              <div className={classes.activeButton}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Boost (97)
              </div>
              <div className={classes.interactionButton}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-6.616l-2.88 2.592C8.537 20.461 7 19.776 7 18.477V17H5a2 2 0 0 1-2-2V6Zm4 2a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2H7Zm8 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Zm-8 3a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H7Zm5 0a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Comment (21)
              </div>
              <div className={classes.interactionButton}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                </svg>
                Share
              </div>
            </div>
          </div>
          <div className={classes.card}>
            <div className={classes.profileTitle}>
              Take Foundry anywhere, anytime
            </div>
            Want the mobile app experience? Simply add Foundry to your home
            screen from your browser (works on both iPhone and Android) to use
            it just like a native app. No download required.
          </div>

          <video
            autoPlay
            loop
            muted
            playsInline
            className={classes.video}
            loading="lazy"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/foundry-2024.appspot.com/o/Push-notification-(iOS)-%5Bremix%5D%20(2).mp4?alt=media&token=158b1e1f-7527-4fc2-bb4d-be4acfa2a3e2"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default Splash;
