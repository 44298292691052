import React, { createContext, useState, useContext, useEffect } from "react";
import { firestore, auth } from "../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  doc,
  updateDoc,
  writeBatch,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [unreadChatsCount, setUnreadChatsCount] = useState(0);
  const [unreadMessageMap, setUnreadMessageMap] = useState(new Map());

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const chatsRef = collection(firestore, "chats");
        const q = query(
          chatsRef,
          where("participants", "array-contains", user.uid),
          orderBy("lastMessageTime", "desc")
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
          let count = 0;
          const newUnreadMap = new Map();

          snapshot.docs.forEach((doc) => {
            const chatData = doc.data();
            const lastMessageSenderId = chatData.lastMessage?.senderId;
            const userUnreadCount = chatData.unreadCount?.[user.uid] || 0;

            // Only consider it unread if:
            // 1. The last message is from someone else
            // 2. We have unread messages
            // 3. We're not actively viewing the chat
            if (
              lastMessageSenderId &&
              lastMessageSenderId !== user.uid &&
              userUnreadCount > 0
            ) {
              count++;
              newUnreadMap.set(doc.id, true);
            }
          });

          setUnreadChatsCount(count);
          setUnreadMessageMap(newUnreadMap);
        });

        return () => unsubscribe();
      }
    });

    return () => unsubscribeAuth();
  }, []);

  const markAllChatsRead = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const batch = writeBatch(firestore);
    const chatsRef = collection(firestore, "chats");
    const q = query(
      chatsRef,
      where("participants", "array-contains", user.uid)
    );
    const snapshot = await getDocs(q);

    snapshot.docs.forEach((doc) => {
      batch.update(doc.ref, {
        [`unreadCount.${user.uid}`]: 0,
      });
    });

    await batch.commit();
  };

  const markChatAsRead = async (chatId) => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      const chatRef = doc(firestore, `chats/${chatId}`);
      await updateDoc(chatRef, {
        [`unreadCount.${user.uid}`]: 0,
      });

      // Update local state immediately for smoother UX
      setUnreadMessageMap((prev) => {
        const newMap = new Map(prev);
        newMap.delete(chatId);
        return newMap;
      });
      setUnreadChatsCount((prev) => Math.max(0, prev - 1));
    } catch (error) {}
  };

  const isChatUnread = (chatId) => {
    return unreadMessageMap.get(chatId) || false;
  };

  const value = {
    unreadChatsCount,
    isChatUnread,
    markAllChatsRead,
    markChatAsRead,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};
