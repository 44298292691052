import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { firestore, auth } from "../../firebase";
import defaultStartupImage from "../../assets/images/startupImage.jpg";
import TermsAndConditions from "../../components/Cards/TermsAndConditions";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  deleteField,
  deleteDoc,
  setDoc,
  writeBatch,
  serverTimestamp,
  addDoc,
  orderBy,
  increment,
} from "firebase/firestore";
import StartupInteractionModal from "../../components/Modals/StartupInteractionModal";
import StartupInteractionToggle from "../../components/Dropdowns/StartupInteractionToggle";
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";
import MainNavbar from "../../components/Navbars/MainNavbar";
import MobileNavbar from "../../components/Navbars/MobileNavbar";
import classes from "./SinglePost.module.css";
import profileImage from "../../assets/icons/profileImage.jpg";
import { convertUrlsToLinks } from "../../utils/textUtils";
import EmojiPicker from "../../components/Dropdowns/EmojiPicker";
import ReactionsModal from "../../components/Modals/ReactionsModal";
import RepostsModal from "../../components/Modals/RepostsModal";
import { useNavigate, Link } from "react-router-dom";
import PostModal from "../../components/Modals/PostModal";

const MemoizedToggle = React.memo(
  ({ currentUser, selectedStartup, onModalOpen, onModalClose, ...props }) => (
    <StartupInteractionToggle
      currentUser={currentUser}
      selectedStartup={selectedStartup}
      onModalOpen={onModalOpen}
      onModalClose={onModalClose}
      imgProps={{ "data-is-startup": !!selectedStartup }}
      {...props}
    />
  )
);

function SinglePost() {
  const [showStartupModal, setShowStartupModal] = useState(false);
  const [activeStartupToggle, setActiveStartupToggle] = useState(null); // Add this with your other state variables
  const [deletingCommentMessage, setDeletingCommentMessage] = useState(null);
  const [deletingPostMessage, setDeletingPostMessage] = useState(null);
  const [reportMessage, setReportMessage] = useState(null);
  const [emojiPickerScrollPosition, setEmojiPickerScrollPosition] = useState(0);
  const [
    commentEmojiPickerScrollPosition,
    setCommentEmojiPickerScrollPosition,
  ] = useState(0);
  const [userStartups, setUserStartups] = useState([]);
  const { postId } = useParams();
  const [startupModalScrollPosition, setStartupModalScrollPosition] =
    useState(0);
  const [isStartupModalOpen, setIsStartupModalOpen] = useState(false);
  const [shareStates, setShareStates] = useState({});
  const [selectedStartup, setSelectedStartup] = useState(null);
  const [post, setPost] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [expandedPost, setExpandedPost] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [videoVolume, setVideoVolume] = useState(false);
  const videoRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  const [currentUserProfileImage, setCurrentUserProfileImage] = useState(null);
  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [repostMessage, setRepostMessage] = useState(null);
  const [fromReactions, setFromReactions] = useState(false);
  const [reactionEmoji, setReactionEmoji] = useState(null);
  const location = useLocation();
  const [errorPopup, setErrorPopup] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");
  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
  const editTextareaRef = useRef(null);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);
  const [activePostReposts, setActivePostReposts] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [postModalScrollPosition, setPostModalScrollPosition] = useState(0);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [isEditingComplete, setIsEditingComplete] = useState(false);

  // New state variables for comments
  const [comments, setComments] = useState({});
  const [newComment, setNewComment] = useState("");
  const [replyComments, setReplyComments] = useState({});
  const [activeCommentId, setActiveCommentId] = useState(null);
  const [visibleComments, setVisibleComments] = useState(3);
  const [visibleReplies, setVisibleReplies] = useState({});
  const [expandedComments, setExpandedComments] = useState({});
  const [expandedReplies, setExpandedReplies] = useState({});
  const [showCommentEmojiPicker, setShowCommentEmojiPicker] = useState({});
  const [activeCommentForReaction, setActiveCommentForReaction] =
    useState(null);
  const [openCommentDropdown, setOpenCommentDropdown] = useState(null);

  const commentInputRef = useRef(null);
  const replyInputRef = useRef({});

  useEffect(() => {
    const fetchUserStartups = async () => {
      if (!currentUser?.uid) return;
      try {
        const startupsRef = collection(
          firestore,
          `users/${currentUser.uid}/startups`
        );
        const startupsSnapshot = await getDocs(startupsRef);
        const startupsData = startupsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserStartups(startupsData);
      } catch (error) {}
    };

    fetchUserStartups();
  }, [currentUser?.uid]);

  const handleShare = useCallback((postId) => {
    const postUrl = `${window.location.origin}/post/${postId}`;
    navigator.clipboard.writeText(postUrl).then(() => {
      setShareStates((prev) => ({ ...prev, [postId]: "Copied" }));
      setTimeout(() => {
        setShareStates((prev) => ({ ...prev, [postId]: null }));
      }, 5000);
    });
  }, []);

  const checkConnectionType = async (userId, connectionId) => {
    if (userId === connectionId) return "You";

    const userConnectionRef = doc(
      firestore,
      `users/${userId}/connections/${connectionId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    // Check for indirect connections
    const userConnectionsRef = collection(
      firestore,
      `users/${userId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${connectionId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const getUserStartups = async (userId) => {
    try {
      const startupsRef = collection(firestore, `users/${userId}/startups`);
      const startupsSnapshot = await getDocs(startupsRef);
      return startupsSnapshot.docs.map((doc) => doc.id);
    } catch (error) {
      return [];
    }
  };

  // Helper function to check if a user or their startups are blocked
  const isUserOrStartupsBlocked = async (currentUserId, otherUserId) => {
    try {
      // Get all startups for both users
      const [currentUserStartups, otherUserStartups] = await Promise.all([
        getUserStartups(currentUserId),
        getUserStartups(otherUserId),
      ]);

      // Check current user's blocked users
      const currentUserBlockedRef = collection(
        firestore,
        `users/${currentUserId}/blockedUsers`
      );
      const currentUserBlockedSnapshot = await getDocs(currentUserBlockedRef);
      const currentUserBlockedIds = new Set(
        currentUserBlockedSnapshot.docs.map((doc) => doc.data().userId)
      );

      // Check if other user or their startups are blocked by current user
      if (currentUserBlockedIds.has(otherUserId)) return true;
      for (const startupId of otherUserStartups) {
        if (currentUserBlockedIds.has(`startup_${startupId}`)) return true;
      }

      // Check other user's blocked users
      const otherUserBlockedRef = collection(
        firestore,
        `users/${otherUserId}/blockedUsers`
      );
      const otherUserBlockedSnapshot = await getDocs(otherUserBlockedRef);
      const otherUserBlockedIds = new Set(
        otherUserBlockedSnapshot.docs.map((doc) => doc.data().userId)
      );

      // Check if current user or their startups are blocked by other user
      if (otherUserBlockedIds.has(currentUserId)) return true;
      for (const startupId of currentUserStartups) {
        if (otherUserBlockedIds.has(`startup_${startupId}`)) return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  const fetchComments = useCallback(async () => {
    if (!post || !post.user || !post.id) {
      return;
    }

    try {
      // Use the correct path based on whether it's a startup post
      const basePath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${post.user.id}`;

      const commentsRef = collection(
        firestore,
        `${basePath}/posts/${post.id}/comments`
      );
      const q = query(commentsRef, orderBy("createdAt", "asc"));
      const snapshot = await getDocs(q);

      const fetchedComments = await Promise.all(
        snapshot.docs.map(async (docSnapshot) => {
          const commentData = docSnapshot.data();

          // Handle startup comments
          if (commentData.isStartup) {
            // Check if startup owner is blocked
            const isBlocked = await isUserOrStartupsBlocked(
              currentUser.uid,
              commentData.startupOwnerId
            );
            if (isBlocked) return null;

            // Get startup info
            const startupRef = doc(
              firestore,
              `users/${commentData.startupOwnerId}/startups/${commentData.startupId}`
            );
            const startupSnapshot = await getDoc(startupRef);
            const startupData = startupSnapshot.exists()
              ? startupSnapshot.data()
              : null;

            if (!startupData) {
              return null;
            }

            return {
              id: docSnapshot.id,
              ...commentData,
              userName: startupData.startupName,
              userImage: startupData.startupImage || profileImage,
              connectionType: "Startup",
              bio: startupData.bio || "",
              link: startupData.link || null,
              linkText: startupData.linkText || null,
              postId: post.id,
              postUserId: post.user.id,
              rootCommentId: commentData.rootCommentId,
              isRootComment: commentData.isRootComment,
              startupUrlSlug: startupData.startupUrlSlug,
              isStartup: true,
            };
          } else {
            // Check if regular user is blocked
            const isBlocked = await isUserOrStartupsBlocked(
              currentUser.uid,
              commentData.userId
            );
            if (isBlocked) return null;

            // For regular users
            const userDoc = await getDoc(
              doc(firestore, "users", commentData.userId)
            );
            if (!userDoc.exists()) {
              return null;
            }

            const userData = userDoc.data();
            let connectionType = "Unknown";
            try {
              connectionType = await checkConnectionType(
                currentUser.uid,
                commentData.userId
              );
            } catch (error) {}

            return {
              id: docSnapshot.id,
              ...commentData,
              userName: `${userData.firstName} ${userData.lastName}`,
              userImage: userData.profileImage || profileImage,
              connectionType,
              bio: userData.bio || "",
              link: userData.link || null,
              linkText: userData.linkText || null,
              postId: post.id,
              postUserId: post.user.id,
              rootCommentId: commentData.rootCommentId,
              isRootComment: commentData.isRootComment,
              urlSlug: userData.urlSlug,
              isStartup: false,
            };
          }
        })
      );

      // Filter out null values (failed or blocked comments) and sort properly
      const validComments = fetchedComments
        .filter((comment) => comment !== null)
        .sort((a, b) => {
          // Keep root comments and their replies together
          if (a.rootCommentId !== b.rootCommentId) {
            return a.createdAt - b.createdAt;
          }
          // Within the same thread, sort by creation time
          return a.createdAt - b.createdAt;
        });

      setComments((prevComments) => ({
        ...prevComments,
        [post.id]: validComments,
      }));
    } catch (error) {
      setErrorPopup({
        message: "Error loading comments.",
        isError: true,
      });
    }
  }, [post, currentUser, checkConnectionType]);

  useEffect(() => {}, [comments]);

  // useEffect(() => {
  //   if (post && currentUser) {
  //     fetchComments();
  //   }
  // }, [post, currentUser, fetchComments]);

  useEffect(() => {
    if (post && currentUser) {
      fetchComments();
    }
  }, [post, currentUser]);

  const handleOpenEmojiPicker = () => {
    const currentEmoji = selectedStartup
      ? post.reactions?.[`startup_${selectedStartup.id}`]
      : post.reactions?.[currentUser?.uid];

    if (currentEmoji) {
      // If there's already an emoji, remove it
      handleEmojiSelect(currentEmoji);
    } else {
      // Otherwise show emoji picker
      setEmojiPickerScrollPosition(window.scrollY);
      setActiveEmojiPicker("post");
    }
  };

  const hasInteractions = (post) => {
    return (
      (post.reactions && Object.keys(post.reactions).length > 0) ||
      (post.reposts && Object.keys(post.reposts).length > 0)
    );
  };

  const getCommentsPath = (post) => {
    if (post.user.isStartup) {
      return `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${post.id}/comments`;
    }
    return `users/${post.user.id}/posts/${post.id}/comments`;
  };

  const handleAddComment = async (
    postId,
    commentText,
    parentCommentId = null
  ) => {
    if (!currentUser || !post) return;

    if (!commentText.trim()) return;

    try {
      const commenterInfo = getCommenterInfo(currentUser, selectedStartup);
      const processedCommentText = convertUrlsToLinks(commentText);

      // Get the correct path for the post's comments
      const commentsPath = getCommentsPath(post);
      const commentsRef = collection(firestore, commentsPath);

      // Get reference to the post
      const postRef = getPostRef(post);
      const postSnap = await getDoc(postRef);

      if (!postSnap.exists()) {
        throw new Error("Post not found");
      }

      const postData = postSnap.data();
      const rootCommentId = parentCommentId
        ? await getRootCommentId(post.user.id, post.id, parentCommentId)
        : null;

      const now = new Date();
      const commentData = {
        text: processedCommentText,
        userId: commenterInfo.id,
        userName: commenterInfo.name,
        userImage: commenterInfo.image,
        createdAt: now,
        parentCommentId: parentCommentId,
        rootCommentId: rootCommentId,
        isRootComment: !parentCommentId,
        bio: commenterInfo.bio,
        connectionType: commenterInfo.connectionType,
        link: commenterInfo.link,
        linkText: commenterInfo.linkText,
        postUserId: post.user.id,
        isStartup: commenterInfo.isStartup,
        ...(commenterInfo.isStartup && {
          startupId: commenterInfo.startupId,
          startupOwnerId: commenterInfo.ownerId,
        }),
      };

      const newCommentRef = await addDoc(commentsRef, {
        ...commentData,
        createdAt: serverTimestamp(),
      });

      await updateDoc(postRef, {
        commentCount: increment(1),
        [`comments.${commenterInfo.id}`]: serverTimestamp(),
        originalDirectCommentTime: serverTimestamp(),
      });

      // Update commentedPosts collection for the commenter (user or startup)
      const commenterPath = commenterInfo.isStartup
        ? `users/${currentUser.uid}/startups/${commenterInfo.startupId}`
        : `users/${currentUser.uid}`;

      const commentedPostRef = doc(
        firestore,
        `${commenterPath}/commentedPosts/${post.id}`
      );
      const commentedPostDoc = await getDoc(commentedPostRef);

      const newCommentData = {
        commentId: newCommentRef.id,
        commentedAt: now,
        commentText: processedCommentText,
        originalPosterId: post.user.startupOwnerId || post.user.id,
        isStartup: commenterInfo.isStartup,
      };

      if (commentedPostDoc.exists()) {
        await updateDoc(commentedPostRef, {
          comments: {
            ...(commentedPostDoc.data().comments || {}),
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: now,
        });
      } else {
        await setDoc(commentedPostRef, {
          originalPosterId: post.user.startupOwnerId || post.user.id,
          postId: post.id,
          comments: {
            [newCommentRef.id]: newCommentData,
          },
          lastCommentedAt: now,
        });
      }

      const batch = writeBatch(firestore);

      // Notify post owner about new comment (if not commenting on own post)
      const shouldNotifyPostOwner =
        !parentCommentId && // Is a root comment
        // For startup posts
        ((post.user.isStartup &&
          // Don't notify if commenter is the startup owner
          currentUser.uid !== post.user.startupOwnerId &&
          // Don't notify if commenter is a startup owned by the post owner
          (!commenterInfo.isStartup ||
            commenterInfo.ownerId !== post.user.startupOwnerId)) ||
          // For regular posts
          (!post.user.isStartup &&
            // Don't notify if commenter is the post owner
            currentUser.uid !== post.user.id &&
            // Don't notify if commenter is a startup owned by the post owner
            (!commenterInfo.isStartup ||
              commenterInfo.ownerId !== post.user.id)));

      if (shouldNotifyPostOwner) {
        const postOwnerNotificationRef = doc(
          firestore,
          `users/${post.user.startupOwnerId || post.user.id}/notifications`,
          `comment_${newCommentRef.id}`
        );

        const notificationData = {
          type: "comment",
          postId: post.id,
          commentId: newCommentRef.id,
          commenterId: commenterInfo.id,
          commenterName: commenterInfo.name,
          commenterImage: commenterInfo.image,
          commentText: commentText,
          createdAt: serverTimestamp(),
          isNew: true,
          postPreview: {
            text: postData.text || "",
            mediaType: postData.content?.type || null,
            mediaUrl: postData.content?.url || null,
            fileName: postData.content?.fileName || null,
          },
          ...(commenterInfo.isStartup && {
            isStartup: true,
            startupId: selectedStartup.id,
            startupOwnerId: currentUser.uid,
            startupImage: selectedStartup.startupImage,
          }),
        };

        batch.set(postOwnerNotificationRef, notificationData);
      }

      // Handle reply notifications
      if (parentCommentId) {
        const parentCommentRef = doc(commentsRef, parentCommentId);
        const parentCommentSnap = await getDoc(parentCommentRef);
        const parentCommentData = parentCommentSnap.exists()
          ? parentCommentSnap.data()
          : null;

        const shouldNotifyParentCommenter =
          parentCommentData &&
          // For startup parent comments
          ((parentCommentData.isStartup &&
            // Don't notify if replier is the startup owner
            currentUser.uid !== parentCommentData.startupOwnerId &&
            // Don't notify if replier is a startup owned by the parent comment owner
            (!commenterInfo.isStartup ||
              commenterInfo.ownerId !== parentCommentData.startupOwnerId)) ||
            // For regular parent comments
            (!parentCommentData.isStartup &&
              // Don't notify if replier is the comment owner
              currentUser.uid !== parentCommentData.userId &&
              // Don't notify if replier is a startup owned by the comment owner
              (!commenterInfo.isStartup ||
                commenterInfo.ownerId !== parentCommentData.userId)));
        if (shouldNotifyParentCommenter) {
          const replyNotificationRef = doc(
            firestore,
            `users/${
              parentCommentData.isStartup
                ? parentCommentData.startupOwnerId
                : parentCommentData.userId
            }/notifications`,
            `reply_${newCommentRef.id}`
          );

          const replyNotificationData = {
            type: "reply",
            postId: post.id,
            commentId: newCommentRef.id,
            parentCommentId: parentCommentId,
            replierId: commenterInfo.id,
            replierName: commenterInfo.name,
            replierImage: commenterInfo.image,
            replyText: commentText,
            createdAt: serverTimestamp(),
            isNew: true,
            parentCommentPreview: {
              text: parentCommentData.text || "",
            },
            postPreview: {
              text: postData.text || "",
              mediaType: postData.content?.type || null,
              mediaUrl: postData.content?.url || null,
              fileName: postData.content?.fileName || null,
            },
            ...(commenterInfo.isStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          batch.set(replyNotificationRef, replyNotificationData);
        }
      }

      await batch.commit();

      // Update local state
      const newCommentObject = {
        id: newCommentRef.id,
        ...commentData,
        postId: post.id,
        postUserId: post.user.id,
      };

      setComments((prevComments) => ({
        ...prevComments,
        [post.id]: [...(prevComments[post.id] || []), newCommentObject],
      }));

      setPost((prevPost) => ({
        ...prevPost,
        commentCount: (prevPost.commentCount || 0) + 1,
        comments: {
          ...(prevPost.comments || {}),
          [commenterInfo.id]: now,
        },
        originalDirectCommentTime: now,
      }));

      // Reset input states
      if (parentCommentId) {
        setReplyComments((prev) => ({ ...prev, [parentCommentId]: "" }));
      } else {
        setNewComment("");
        if (commentInputRef.current) {
          commentInputRef.current.style.height = "auto";
        }
      }
      setActiveCommentId(null);
    } catch (error) {
      setErrorPopup({
        message:
          error.message === "Post not found"
            ? "This post has been deleted."
            : "An error occurred while adding the comment.",
        isError: true,
      });
    }
  };

  const getRootCommentId = async (postUserId, postId, commentId) => {
    try {
      // Use the correct path based on whether the post is from a startup
      const basePath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${postUserId}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${postId}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }
      const commentData = commentSnap.data();

      if (commentData.isRootComment || !commentData.parentCommentId) {
        return commentId;
      }

      return getRootCommentId(postUserId, postId, commentData.parentCommentId);
    } catch (error) {
      throw error;
    }
  };

  const handleCommentInputChange = useCallback((e) => {
    const textarea = e.target;
    setNewComment(textarea.value);
  }, []);

  const handleReplyInputChange = (e, replyId) => {
    const textarea = e.target;
    setReplyComments((prev) => ({
      ...prev,
      [replyId]: textarea.value,
    }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleReplyButton = useCallback((commentId) => {
    setActiveCommentId((prevActiveCommentId) =>
      prevActiveCommentId === commentId ? null : commentId
    );
    setReplyComments((prev) => ({ ...prev, [commentId]: "" }));

    // Add a slight delay before focusing
    setTimeout(() => {
      if (replyInputRef.current[commentId]) {
        const inputElement = replyInputRef.current[commentId];
        inputElement.focus();
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        if (isIOS) {
          forceKeyboardOpen(inputElement);
        }
      }
    }, 100);
  }, []);

  const forceKeyboardOpen = (inputElement) => {
    if (inputElement) {
      // Attempt to force open the keyboard
      inputElement.focus();
      inputElement.click();
      inputElement.setSelectionRange(0, 0);
      // Small delay to ensure the above actions have time to trigger the keyboard
      setTimeout(() => {
        inputElement.setSelectionRange(
          inputElement.value.length,
          inputElement.value.length
        );
      }, 50);
    }
  };

  // Add this state
  const [isIOS, setIsIOS] = useState(false);

  // Add this useEffect
  useEffect(() => {
    // Detect if the device is running iOS
    const isIOSDevice =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(isIOSDevice);
  }, []);

  const handleLoadMoreComments = () => {
    setVisibleComments((prev) => {
      const increment = prev === 3 ? 6 : (prev - 3) * 2;
      return prev + increment;
    });
  };

  const handleViewMoreReplies = (rootCommentId) => {
    setVisibleReplies((prev) => {
      const currentVisible = prev[rootCommentId] || 0;
      const increment = currentVisible === 0 ? 3 : currentVisible * 2;
      return {
        ...prev,
        [rootCommentId]: currentVisible + increment,
      };
    });
  };

  const toggleComments = () => {
    setShowComments(!showComments);
    if (!comments[post.id]) {
      fetchComments();
    }
    // Reset visibility when toggling comments
    setVisibleComments(3);
  };

  const renderPostDropdown = () => {
    // Check if current user owns the post (either directly or via startup ownership)
    const isOwnPost = post.user.isStartup
      ? post.user.startupOwnerId === currentUser.uid
      : post.user.id === currentUser.uid;

    return (
      <div className={classes.optionsDropdown}>
        {isOwnPost ? (
          <>
            <button onClick={handleEditPost} className={classes.editPost}>
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                />
              </svg>
              Edit
            </button>
            <button onClick={handleDeletePost} className={classes.deletePost}>
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                />
              </svg>
              Delete
            </button>
          </>
        ) : (
          <button onClick={handleReportPost} className={classes.reportPost}>
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            Report
          </button>
        )}
      </div>
    );
  };

  const toggleCommentExpansion = (commentId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const toggleReplyExpansion = (replyId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [replyId]: !prev[replyId],
    }));
  };

  const handleCommentReactionClick = (commentId) => {
    // Directly handle the thumbs up emoji
    handleCommentEmojiSelect(commentId, "👍");
  };

  const handleCommentEmojiSelect = async (commentId, emoji) => {
    if (!currentUser || !post) return;

    try {
      const basePath = getCommentsPath(post);
      const commentRef = doc(firestore, basePath, commentId);
      const commentSnap = await getDoc(commentRef);

      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();
      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;
      let updatedReactions = { ...(commentData.reactions || {}) };

      if (updatedReactions[reactorId] === emoji) {
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });
        delete updatedReactions[reactorId];

        const notificationRef = doc(
          firestore,
          `users/${
            commentData.isStartup
              ? commentData.startupOwnerId
              : commentData.userId
          }/notifications`,
          `comment_reaction_${commentId}_${reactorId}`
        );
        await deleteDoc(notificationRef);
      } else {
        await updateDoc(commentRef, {
          [`reactions.${reactorId}`]: emoji,
        });
        updatedReactions[reactorId] = emoji;

        const shouldNotify =
          (selectedStartup &&
            !(
              commentData.userId === currentUser.uid ||
              (commentData.isStartup &&
                commentData.startupOwnerId === currentUser.uid)
            )) ||
          (!selectedStartup &&
            (commentData.isStartup
              ? commentData.startupOwnerId !== currentUser.uid
              : commentData.userId !== currentUser.uid));

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${
              commentData.isStartup
                ? commentData.startupOwnerId
                : commentData.userId
            }/notifications`,
            `comment_reaction_${commentId}_${reactorId}`
          );

          const notificationData = {
            type: "comment_reaction",
            postId: post.id,
            commentId: commentId,
            reactorId: reactorId,
            reactorName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reactorImage: selectedStartup
              ? selectedStartup.startupImage
              : currentUserProfileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            commentText: commentData.text,
            isReply: !commentData.isRootComment,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }

      setComments((prevComments) => ({
        ...prevComments,
        [post.id]: prevComments[post.id].map((comment) => {
          if (comment.id === commentId) {
            return { ...comment, reactions: updatedReactions };
          }
          if (
            !comment.isRootComment &&
            comment.rootCommentId === commentData.rootCommentId
          ) {
            if (comment.id === commentId) {
              return { ...comment, reactions: updatedReactions };
            }
          }
          return comment;
        }),
      }));
    } catch (error) {
      setErrorPopup({
        message:
          error.message === "Comment not found"
            ? "This comment has been deleted."
            : "An error occurred while updating the reaction.",
        isError: true,
      });
    }
  };

  const toggleCommentDropdown = (commentId) => {
    setOpenCommentDropdown(
      openCommentDropdown === commentId ? null : commentId
    );
  };

  const handleDeleteComment = async (commentId) => {
    if (!currentUser) {
      return;
    }

    setDeletingCommentMessage({
      text: "Deleting comment...",
      isLoading: true,
    });

    try {
      if (!post) {
        setErrorPopup({
          message: "The post containing this comment has been deleted.",
          isError: true,
        });
        return;
      }

      // Get the correct base path based on whether it's a startup post
      const basePath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${post.user.id}`;

      const commentsRef = collection(
        firestore,
        `${basePath}/posts/${post.id}/comments`
      );
      const commentToDeleteRef = doc(commentsRef, commentId);
      const commentSnap = await getDoc(commentToDeleteRef);

      if (!commentSnap.exists()) {
        setErrorPopup({
          message: "This comment has already been deleted.",
          isError: true,
        });
        return;
      }

      const commentData = commentSnap.data();
      let canDelete = false;

      if (commentData.userId.startsWith("startup_")) {
        const startupId = commentData.userId.replace("startup_", "");
        canDelete =
          commentData.startupOwnerId === currentUser.uid ||
          currentUser.uid ===
            (post.user.isStartup ? post.user.startupOwnerId : post.user.id);
      } else {
        canDelete =
          currentUser.uid === commentData.userId ||
          currentUser.uid ===
            (post.user.isStartup ? post.user.startupOwnerId : post.user.id);
      }

      if (!canDelete) {
        setErrorPopup({
          message: "You don't have permission to delete this comment.",
          isError: true,
        });
        return;
      }

      let deletedCount = 0;
      const deletedCommentIds = new Set();
      const affectedUserIds = new Set();
      const affectedStartupIds = new Set();

      const deleteStartupNotifications = async (commentData, commentRef) => {
        try {
          if (commentData.userId.startsWith("startup_")) {
            const startupId = commentData.userId.replace("startup_", "");

            const replyNotificationsQuery = query(
              collection(
                firestore,
                `users/${commentData.startupOwnerId}/notifications`
              ),
              where("parentCommentId", "==", commentRef.id)
            );

            const replyNotificationsSnapshot = await getDocs(
              replyNotificationsQuery
            );
            const deletePromises = replyNotificationsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            );
            await Promise.all(deletePromises);

            const commentNotificationRef = doc(
              firestore,
              `users/${commentData.startupOwnerId}/notifications`,
              `comment_${commentRef.id}`
            );
            await deleteDoc(commentNotificationRef);
          }

          if (commentData.parentCommentId) {
            const parentCommentRef = doc(
              commentsRef,
              commentData.parentCommentId
            );
            const parentCommentSnap = await getDoc(parentCommentRef);

            if (parentCommentSnap.exists()) {
              const parentCommentData = parentCommentSnap.data();
              if (parentCommentData.userId.startsWith("startup_")) {
                const replyNotificationRef = doc(
                  firestore,
                  `users/${parentCommentData.startupOwnerId}/notifications`,
                  `reply_${commentRef.id}`
                );
                await deleteDoc(replyNotificationRef);
              }
            }
          }
        } catch (error) {}
      };

      const deleteCommentsAndReplies = async (commentRef) => {
        const commentSnapshot = await getDoc(commentRef);
        if (!commentSnapshot.exists()) return;

        const commentData = commentSnapshot.data();
        deletedCount++;
        deletedCommentIds.add(commentRef.id);

        if (commentData.userId.startsWith("startup_")) {
          const startupId = commentData.userId.replace("startup_", "");
          affectedStartupIds.add(startupId);
        } else {
          affectedUserIds.add(commentData.userId);
        }

        await deleteStartupNotifications(commentData, commentRef);

        // Use the correct post owner ID for notification deletion
        const postOwnerId = post.user.isStartup
          ? post.user.startupOwnerId
          : post.user.id;
        if (commentData.userId !== postOwnerId) {
          await deleteDoc(
            doc(
              firestore,
              `users/${postOwnerId}/notifications/comment_${commentRef.id}`
            )
          );
        }

        if (commentData.parentCommentId) {
          const parentCommentRef = doc(
            commentsRef,
            commentData.parentCommentId
          );
          const parentCommentSnap = await getDoc(parentCommentRef);
          if (parentCommentSnap.exists()) {
            const parentCommentData = parentCommentSnap.data();
            if (
              parentCommentData.userId !== commentData.userId &&
              !parentCommentData.userId.startsWith("startup_")
            ) {
              await deleteDoc(
                doc(
                  firestore,
                  `users/${parentCommentData.userId}/notifications/reply_${commentRef.id}`
                )
              );
            }
          }
        }

        const reactionPromises = [];
        if (commentData.isStartup) {
          const startupOwnerReactionsQuery = query(
            collection(
              firestore,
              `users/${commentData.startupOwnerId}/notifications`
            ),
            where("type", "==", "comment_reaction"),
            where("commentId", "==", commentRef.id)
          );
          const startupOwnerReactionsSnapshot = await getDocs(
            startupOwnerReactionsQuery
          );
          reactionPromises.push(
            ...startupOwnerReactionsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            )
          );
        }

        // Delete reaction notifications
        const usersSnapshot = await getDocs(collection(firestore, "users"));
        for (const userDoc of usersSnapshot.docs) {
          // Handle user reactions
          const userReactionNotificationsQuery = query(
            collection(firestore, `users/${userDoc.id}/notifications`),
            where("type", "==", "comment_reaction"),
            where("commentId", "==", commentRef.id)
          );
          const userReactionNotificationsSnapshot = await getDocs(
            userReactionNotificationsQuery
          );
          reactionPromises.push(
            ...userReactionNotificationsSnapshot.docs.map((doc) =>
              deleteDoc(doc.ref)
            )
          );

          // Handle startup reactions
          const userStartupsSnapshot = await getDocs(
            collection(firestore, `users/${userDoc.id}/startups`)
          );
          for (const startupDoc of userStartupsSnapshot.docs) {
            const startupId = startupDoc.id;
            const startupReactionNotificationsQuery = query(
              collection(firestore, `users/${userDoc.id}/notifications`),
              where("type", "==", "comment_reaction"),
              where("commentId", "==", commentRef.id),
              where("reactorId", "==", `startup_${startupId}`)
            );
            const startupReactionNotificationsSnapshot = await getDocs(
              startupReactionNotificationsQuery
            );
            reactionPromises.push(
              ...startupReactionNotificationsSnapshot.docs.map((doc) =>
                deleteDoc(doc.ref)
              )
            );
          }
        }
        await Promise.all(reactionPromises);

        // Delete replies
        const repliesQuery = query(
          commentsRef,
          where("parentCommentId", "==", commentRef.id)
        );
        const repliesSnapshot = await getDocs(repliesQuery);
        await Promise.all(
          repliesSnapshot.docs.map((doc) => deleteCommentsAndReplies(doc.ref))
        );

        await deleteDoc(commentRef);
      };

      await deleteCommentsAndReplies(commentToDeleteRef);

      const batch = writeBatch(firestore);

      // Use the correct path for updating the post
      const postRef = doc(firestore, `${basePath}/posts/${post.id}`);

      batch.update(postRef, {
        commentCount: increment(-deletedCount),
      });

      for (const userId of affectedUserIds) {
        batch.update(postRef, {
          [`comments.${userId}`]: deleteField(),
        });
      }
      for (const startupId of affectedStartupIds) {
        batch.update(postRef, {
          [`comments.startup_${startupId}`]: deleteField(),
        });
      }

      // Update commentedPosts for users
      for (const userId of affectedUserIds) {
        const commentedPostRef = doc(
          firestore,
          `users/${userId}/commentedPosts/${post.id}`
        );
        const commentedPostSnap = await getDoc(commentedPostRef);

        if (commentedPostSnap.exists()) {
          const commentedPostData = commentedPostSnap.data();
          const updatedComments = { ...commentedPostData.comments };

          deletedCommentIds.forEach((commentId) => {
            delete updatedComments[commentId];
          });

          if (Object.keys(updatedComments).length === 0) {
            batch.delete(commentedPostRef);
          } else {
            batch.update(commentedPostRef, { comments: updatedComments });
          }
        }
      }

      // Update commentedPosts for startups
      for (const startupId of affectedStartupIds) {
        const usersSnapshot = await getDocs(collection(firestore, "users"));

        for (const userDoc of usersSnapshot.docs) {
          try {
            const startupCommentedPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/commentedPosts/${post.id}`
            );
            const startupCommentedPostSnap = await getDoc(
              startupCommentedPostRef
            );

            if (startupCommentedPostSnap.exists()) {
              const startupCommentedPostData = startupCommentedPostSnap.data();
              const updatedComments = { ...startupCommentedPostData.comments };

              deletedCommentIds.forEach((commentId) => {
                delete updatedComments[commentId];
              });

              if (Object.keys(updatedComments).length === 0) {
                batch.delete(startupCommentedPostRef);
              } else {
                batch.update(startupCommentedPostRef, {
                  comments: updatedComments,
                });
              }
            }
          } catch (error) {}
        }
      }

      await batch.commit();

      // Update local state
      setComments((prevComments) => ({
        ...prevComments,
        [post.id]: prevComments[post.id].filter(
          (c) =>
            !deletedCommentIds.has(c.id) &&
            !deletedCommentIds.has(c.rootCommentId)
        ),
      }));

      setPost((prevPost) => {
        const updatedPost = {
          ...prevPost,
          commentCount: Math.max(
            (prevPost.commentCount || 0) - deletedCount,
            0
          ),
        };
        const updatedComments = { ...updatedPost.comments };
        affectedUserIds.forEach((userId) => {
          delete updatedComments[userId];
        });
        affectedStartupIds.forEach((startupId) => {
          delete updatedComments[`startup_${startupId}`];
        });
        updatedPost.comments = updatedComments;
        return updatedPost;
      });

      setDeletingCommentMessage({
        text: "Comment deleted",
        isLoading: false,
      });

      setOpenCommentDropdown(null);
    } catch (error) {
      setErrorPopup({
        message: "An error occurred while deleting the comment.",
        isError: true,
      });
    } finally {
      // If there was an error, clear the "Deleting..." message
      if (deletingCommentMessage?.isLoading) {
        setDeletingCommentMessage(null);
      }
    }
  };

  const renderCommentText = (comment) => {
    if (!comment || typeof comment.text !== "string") return null;

    if (editingCommentId === comment.id) {
      return (
        <div>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.replyInput}
          />
          {renderEditButtons(comment.id, false)}
        </div>
      );
    }

    const lines = comment.text.split("\n");
    const isLongComment = lines.length > 3 || comment.text.length > 125; // Changed from 149 to 125

    const displayText =
      isLongComment && !expandedComments[comment.id]
        ? comment.text.slice(0, 125) // Changed from 149 to 125
        : comment.text;

    return (
      <div className={classes.commentTextContainer}>
        <div
          className={`${classes.commentText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongComment && !expandedComments[comment.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleCommentExpansion(comment.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const handleEditTextareaFocus = (e) => {
    const textarea = e.target;
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const handleEditTextareaChange = (e) => {
    const textarea = e.target;
    setEditCommentText(textarea.value);
    adjustTextareaHeight(textarea);
  };

  useEffect(() => {
    if (editingCommentId && editTextareaRef.current) {
      adjustTextareaHeight(editTextareaRef.current);
    }
  }, [editingCommentId]);

  const renderReplyText = (reply) => {
    if (!reply || typeof reply.text !== "string") return null;

    if (editingCommentId === reply.id) {
      return (
        <div className={classes.editCommentSection}>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.editReplyInput}
          />
          {renderEditButtons(reply.id, true)}
        </div>
      );
    }

    const isLongReply = reply.text.length > 125;
    const displayText =
      isLongReply && !expandedReplies[reply.id]
        ? `${reply.text.slice(0, 125)}` // Truncate here
        : reply.text;

    return (
      <div className={classes.replyTextContainer}>
        <div
          className={`${classes.replyText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongReply && !expandedReplies[reply.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleReplyExpansion(reply.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const handleTouchStart = (e) => {
    const textarea = e.target;
    textarea.focus();
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const openMobileKeyboard = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      if (typeof inputElement.scrollIntoView === "function") {
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      // Try to force the keyboard to open
      if (typeof inputElement.click === "function") {
        inputElement.click();
      }
      // For iOS devices
      if (typeof inputElement.setSelectionRange === "function") {
        const length = inputElement.value.length;
        inputElement.setSelectionRange(length, length);
      }
    }
  };

  const getTopThreeEmojis = (reactions) => {
    if (!reactions) return [];
    const emojiCounts = Object.values(reactions).reduce((acc, emoji) => {
      acc[emoji] = (acc[emoji] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(emojiCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([emoji]) => emoji);
  };

  const CommentInput = React.memo(
    ({
      postId,
      onSubmit,
      currentUser,
      selectedStartup,
      currentUserProfileImage,
      handleNavigate,
    }) => {
      const [inputValue, setInputValue] = useState("");
      const textareaRef = useRef(null);

      const handleImageClick = () => {
        if (selectedStartup) {
          handleNavigate(`startup/${selectedStartup.startupUrlSlug}`);
        } else {
          handleNavigate(currentUser.urlSlug); // Remove the conditional check
        }
      };

      const handleChange = (e) => {
        const textarea = e.target;
        setInputValue(textarea.value);
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      };

      const handleSubmit = () => {
        if (inputValue.trim()) {
          onSubmit(postId, inputValue);
          setInputValue("");
          if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
          }
        }
      };

      return (
        <div className={classes.commentInputSection}>
          <div className={classes.commentInputWrapper}>
            <div>
              <img
                src={
                  selectedStartup
                    ? selectedStartup.startupImage || defaultStartupImage
                    : currentUserProfileImage || profileImage
                }
                alt={
                  selectedStartup ? selectedStartup.startupName : "Your profile"
                }
                className={classes.commentUserImage}
                onClick={handleImageClick}
                style={{
                  borderRadius: selectedStartup ? 0 : "50%",
                  cursor: "pointer",
                }}
              />
            </div>
            <textarea
              ref={textareaRef}
              value={inputValue}
              onChange={handleChange}
              placeholder="Add a comment..."
              className={classes.commentInput}
            />
          </div>
          <div
            onClick={handleSubmit}
            className={`${classes.addCommentButton} ${
              !inputValue.trim() ? classes.disabled : ""
            }`}
          >
            Comment
          </div>
        </div>
      );
    }
  );

  const CommentInputSection = React.memo(
    ({
      currentUser,
      selectedStartup,
      newComment,
      onCommentChange,
      onSubmit,
    }) => {
      const commentInputRef = useRef(null);

      const handleTextareaChange = (e) => {
        const textarea = e.target;
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
        onCommentChange(e);
      };

      const handleImageClick = () => {
        handleNavigate(
          selectedStartup
            ? `startup/${selectedStartup.startupUrlSlug}`
            : currentUser.id
        );
      };

      return (
        <div className={classes.commentInputSection}>
          <div className={classes.commentInputWrapper}>
            <div>
              <img
                src={
                  selectedStartup
                    ? selectedStartup.startupImage || defaultStartupImage
                    : currentUserProfileImage || profileImage
                }
                alt={
                  selectedStartup ? selectedStartup.startupName : "Your profile"
                }
                className={classes.commentUserImage}
                onClick={handleImageClick}
                style={{
                  borderRadius: selectedStartup ? 0 : "50%",
                  cursor: "pointer",
                }}
              />
            </div>
            <textarea
              ref={commentInputRef}
              value={newComment}
              onChange={handleTextareaChange}
              placeholder="Add a comment..."
              className={classes.commentInput}
            />
          </div>
          <div
            onClick={onSubmit}
            className={`${classes.addCommentButton} ${
              !newComment?.trim() ? classes.disabled : ""
            }`}
          >
            Comment
          </div>
        </div>
      );
    }
  );

  const renderCommentHeader = (comment) => {
    const isStartupComment = comment.isStartup;
    const profileUrl = isStartupComment
      ? `/startup/${comment.startupUrlSlug}`
      : `/${comment.userUrlSlug}`;

    return (
      <div className={classes.commentHeader}>
        <div className={classes.commentUserInfo}>
          <img
            src={comment.userImage || profileImage}
            alt={comment.userName}
            className={classes.commentUserImage}
            onClick={() =>
              handleNavigate(
                comment.isStartup
                  ? `startup/${comment.startupUrlSlug}`
                  : comment.urlSlug || comment.userId
              )
            }
            style={{
              borderRadius: comment.isStartup ? 0 : "50%",
              cursor: "pointer",
            }}
          />
          <div className={classes.commentUserDetails}>
            <div className={classes.commentUserNameContainer}>
              <span
                className={classes.commentUserName}
                onClick={() =>
                  handleNavigate(
                    comment.isStartup
                      ? `startup/${comment.startupUrlSlug}`
                      : comment.urlSlug || comment.userId
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {comment.userName && !comment.userName.includes("undefined")
                  ? comment.userName
                  : ""}
              </span>
              <span className={classes.commentConnectionType}>
                • {isStartupComment ? "Startup" : comment.connectionType}
              </span>
            </div>
            {comment.bio && (
              <p className={classes.commentUserBio}>{comment.bio}</p>
            )}
            {comment.link && (
              <div className={classes.commentUserLinkContainer}>
                <a
                  href={comment.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                  className={classes.commentUserLink}
                >
                  <span className={classes.commentUserLinkText}>
                    {comment.linkText || comment.link}
                  </span>
                  <svg
                    className={classes.commentUserLinkIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="orangered"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </a>
              </div>
            )}
            <div className={classes.commentTimeAndEditDetails}>
              <p className={classes.commentTimestamp}>
                {formatTimestamp(comment.createdAt)}
              </p>
              {comment.edited && (
                <div className={classes.commentEditDot}>
                  •<span className={classes.commentEditText}>Edited</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderReplyHeader = (reply) => {
    const isStartupReply = reply.isStartup;
    const profileUrl = isStartupReply
      ? `/startup/${reply.startupUrlSlug}`
      : `/${reply.userUrlSlug}`;

    return (
      <div className={classes.replyHeader}>
        <div className={classes.replyUserInfo}>
          <img
            src={reply.userImage || profileImage}
            alt={reply.userName}
            className={classes.replyUserImage}
            onClick={() =>
              handleNavigate(
                reply.isStartup
                  ? `startup/${reply.startupUrlSlug}`
                  : reply.urlSlug || reply.userId
              )
            }
            style={{
              borderRadius: reply.isStartup ? 0 : "50%",
              cursor: "pointer",
            }}
          />
          <div className={classes.replyUserDetails}>
            <div className={classes.replyUserNameContainer}>
              <span
                className={classes.replyUserName}
                onClick={() =>
                  handleNavigate(
                    reply.isStartup
                      ? `startup/${reply.startupUrlSlug}`
                      : reply.urlSlug || reply.userId
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {reply.userName && !reply.userName.includes("undefined")
                  ? reply.userName
                  : ""}
              </span>
              <span className={classes.replyConnectionType}>
                • {isStartupReply ? "Startup" : reply.connectionType}
              </span>
            </div>
            {reply.bio && <p className={classes.replyUserBio}>{reply.bio}</p>}
            {reply.link && (
              <div className={classes.replyUserLinkContainer}>
                <a
                  href={reply.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                  className={classes.replyUserLink}
                >
                  <span className={classes.replyUserLinkText}>
                    {reply.linkText || reply.link}
                  </span>
                  <svg
                    className={classes.replyUserLinkIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="orangered"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </a>
              </div>
            )}
            <div className={classes.commentTimeAndEditDetails}>
              <p className={classes.commentTimestamp}>
                {formatTimestamp(reply.createdAt)}
              </p>
              {reply.edited && (
                <div className={classes.commentEditDot}>
                  •<span className={classes.commentEditText}>Edited</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Update the comment ownership check in renderCommentOptions

  const renderCommentOptions = (comment) => {
    // First check if this is a startup comment and if the current user is properly authorized
    const isOwnComment = comment.isStartup
      ? comment.startupOwnerId === currentUser.uid // User owns the startup that made the comment
      : comment.userId === currentUser.uid; // User's own comment

    const isPostOwner = post.user.isStartup
      ? post.user.startupOwnerId === currentUser.uid // User owns the startup that made the post
      : post.user.id === currentUser.uid; // User's own post

    return (
      <div className={classes.commentOptionsDropdown}>
        {isOwnComment && (
          <button
            className={classes.editCommentButton}
            onClick={() =>
              handleEditComment(comment.id, !comment.isRootComment)
            }
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
              />
            </svg>
            Edit
          </button>
        )}

        {(isOwnComment || isPostOwner) && !isOwnComment && (
          // Show both Report and Delete for post owner on others' comments
          <>
            <button
              className={classes.reportCommentButton}
              onClick={() => handleReportComment(comment.id)}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              Report
            </button>
            <button
              className={classes.deleteCommentButton}
              onClick={() => handleDeleteComment(comment.id)}
            >
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
                />
              </svg>
              Delete
            </button>
          </>
        )}

        {isOwnComment && (
          <button
            className={classes.deleteCommentButton}
            onClick={() => handleDeleteComment(comment.id)}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
              />
            </svg>
            Delete
          </button>
        )}

        {!isOwnComment && !isPostOwner && (
          <button
            className={classes.singleReportButton}
            onClick={() => handleReportComment(comment.id)}
          >
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            Report
          </button>
        )}
      </div>
    );
  };

  const renderComments = () => {
    if (!post || !comments[post.id]) return null;

    const postComments = comments[post.id];
    const rootComments = postComments.filter(
      (comment) => comment.isRootComment
    );
    const visibleRootComments = rootComments.slice(0, visibleComments);

    return (
      <>
        {visibleRootComments.map((comment) => {
          const replyCount = postComments.filter(
            (reply) =>
              reply.rootCommentId === comment.id && !reply.isRootComment
          ).length;

          const topThreeEmojis = getTopThreeEmojis(comment.reactions || {});
          const totalReactions = Object.keys(comment.reactions || {}).length;

          return (
            <div key={comment.id} className={classes.commentItem}>
              <div className={classes.commentHeader}>
                <div className={classes.commentUserInfo}>
                  <img
                    src={comment.userImage || profileImage}
                    alt={comment.userName}
                    className={classes.commentUserImage}
                    onClick={() =>
                      handleNavigate(
                        comment.isStartup
                          ? `startup/${comment.startupUrlSlug}`
                          : comment.urlSlug || comment.userId
                      )
                    }
                    style={{
                      borderRadius: comment.isStartup ? 0 : "50%",
                      cursor: "pointer",
                    }}
                  />
                  <div className={classes.commentUserDetails}>
                    <div className={classes.commentUserNameContainer}>
                      <span
                        className={classes.commentUserName}
                        onClick={() =>
                          handleNavigate(
                            comment.isStartup
                              ? `startup/${comment.startupUrlSlug}`
                              : comment.urlSlug || comment.userId
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {comment.userName &&
                        !comment.userName.includes("undefined")
                          ? comment.userName
                          : ""}
                      </span>
                      <span className={classes.commentConnectionType}>
                        • {comment.connectionType}
                      </span>
                    </div>
                    {comment.bio && (
                      <p className={classes.commentUserBio}>{comment.bio}</p>
                    )}
                    {comment.link && (
                      <div className={classes.commentUserLinkContainer}>
                        <a
                          href={comment.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.commentUserLink}
                        >
                          <span className={classes.commentUserLinkText}>
                            {comment.linkText || comment.link}
                          </span>
                          <svg
                            className={classes.commentUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(comment.createdAt)}
                      </p>
                      {comment.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.commentOptions}>
                  <svg
                    className={`${classes.commentOptionsIcon} ${
                      openCommentDropdown === comment.id
                        ? classes.commentOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(comment.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === comment.id &&
                    renderCommentOptions(comment)}
                </div>
              </div>
              <div className={classes.commentContent} onClick={handleLinkClick}>
                {renderCommentText(comment)}
              </div>
              {editingCommentId !== comment.id && (
                <div className={classes.commentActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(comment.id)}
                  >
                    Boost {totalReactions > 0 && `(${totalReactions})`}
                  </div>

                  <div className={classes.dot}> • </div>
                  <button
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(comment.id)}
                  >
                    <span className={classes.replyText}>
                      Reply {replyCount > 0 && `(${replyCount})`}
                    </span>
                  </button>
                </div>
              )}
              {activeEmojiPicker === comment.id && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(comment.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {renderReplies(comment.id)}
              {activeCommentId === comment.id && (
                <div className={classes.replyCommentInputSection}>
                  <div className={classes.replyInputWrapper}>
                    <div>
                      <img
                        src={
                          selectedStartup
                            ? selectedStartup.startupImage ||
                              defaultStartupImage
                            : currentUserProfileImage || profileImage
                        }
                        alt={
                          selectedStartup
                            ? selectedStartup.startupName
                            : "Your profile"
                        }
                        className={classes.replyUserImage}
                        onClick={() =>
                          handleNavigate(
                            selectedStartup
                              ? `startup/${selectedStartup.startupUrlSlug}`
                              : currentUser.urlSlug
                          )
                        }
                        style={{
                          borderRadius: selectedStartup ? 0 : "50%",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <textarea
                      ref={(el) => (replyInputRef.current[comment.id] = el)}
                      value={replyComments[comment.id] || ""}
                      onChange={(e) => handleReplyInputChange(e, comment.id)}
                      placeholder="Add a reply..."
                      className={classes.replyInput}
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleAddComment(
                        post.id,
                        replyComments[comment.id],
                        comment.id
                      )
                    }
                    className={`${classes.addReplyButton} ${
                      !replyComments[comment.id]?.trim() ? classes.disabled : ""
                    }`}
                    disabled={!replyComments[comment.id]?.trim()}
                  >
                    Reply
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {rootComments.length > visibleComments && (
          <div
            className={classes.loadMoreCommentsButton}
            onClick={handleLoadMoreComments}
          >
            Show more comments ({rootComments.length - visibleComments})
          </div>
        )}
      </>
    );
  };

  const renderReplies = (rootCommentId) => {
    if (!post || !comments[post.id]) return null;

    const replies = comments[post.id].filter(
      (comment) =>
        comment.rootCommentId === rootCommentId && !comment.isRootComment
    );
    const visibleRepliesCount = visibleReplies[rootCommentId] || 0;
    const visibleRepliesList = replies.slice(0, visibleRepliesCount);

    return (
      <div className={classes.repliesContainer}>
        {visibleRepliesList.map((reply) => {
          const topThreeEmojis = getTopThreeEmojis(reply.reactions || {});
          const totalReactions = Object.keys(reply.reactions || {}).length;

          return (
            <div key={reply.id} className={classes.replyItem}>
              <div className={classes.replyHeader}>
                <div className={classes.replyUserInfo}>
                  <img
                    src={reply.userImage || profileImage}
                    alt={reply.userName}
                    className={classes.replyUserImage}
                    onClick={() =>
                      handleNavigate(
                        reply.isStartup
                          ? `startup/${reply.startupUrlSlug}`
                          : reply.urlSlug || reply.userId
                      )
                    }
                    style={{
                      borderRadius: reply.isStartup ? 0 : "50%",
                      cursor: "pointer",
                    }}
                  />
                  <div className={classes.replyUserDetails}>
                    <div className={classes.replyUserNameContainer}>
                      <span
                        className={classes.replyUserName}
                        onClick={() =>
                          handleNavigate(
                            reply.isStartup
                              ? `startup/${reply.startupUrlSlug}`
                              : reply.urlSlug || reply.userId
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {reply.userName && !reply.userName.includes("undefined")
                          ? reply.userName
                          : ""}
                      </span>
                      <span className={classes.replyConnectionType}>
                        • {reply.connectionType}
                      </span>
                    </div>
                    {reply.bio && (
                      <p className={classes.replyUserBio}>{reply.bio}</p>
                    )}
                    {reply.link && (
                      <div className={classes.replyUserLinkContainer}>
                        <a
                          href={reply.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.replyUserLink}
                        >
                          <span className={classes.replyUserLinkText}>
                            {reply.linkText || reply.link}
                          </span>
                          <svg
                            className={classes.replyUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(reply.createdAt)}
                      </p>
                      {reply.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.replyOptions}>
                  <svg
                    className={`${classes.replyOptionsIcon} ${
                      openCommentDropdown === reply.id
                        ? classes.replyOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(reply.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === reply.id &&
                    renderCommentOptions(reply)}
                </div>
              </div>
              <div className={classes.replyContent} onClick={handleLinkClick}>
                {renderReplyText(reply)}
              </div>
              {editingCommentId !== reply.id && (
                <div className={classes.replyActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(reply.id)}
                  >
                    Boost {totalReactions > 0 && `(${totalReactions})`}
                  </div>
                  <div className={classes.dot}> • </div>
                  <button
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(reply.id)}
                  >
                    <span className={classes.replyText}>
                      Reply{" "}
                      {comments[post.id]?.filter(
                        (c) => c.parentCommentId === reply.id
                      ).length > 0 &&
                        `(${
                          comments[post.id].filter(
                            (c) => c.parentCommentId === reply.id
                          ).length
                        })`}
                    </span>
                  </button>
                </div>
              )}
              {activeEmojiPicker === reply.id && (
                <EmojiPicker
                  onEmojiClick={(emoji) =>
                    handleCommentEmojiSelect(reply.id, emoji)
                  }
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, commentEmojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
            </div>
          );
        })}
        {replies.length > visibleRepliesCount && (
          <div
            className={classes.viewMoreRepliesButton}
            onClick={() => handleViewMoreReplies(rootCommentId)}
          >
            {visibleRepliesCount === 0
              ? `See replies (${replies.length})`
              : `See more replies (${replies.length - visibleRepliesCount})`}
          </div>
        )}
        {activeCommentId &&
          replies.some((reply) => reply.id === activeCommentId) && (
            <div className={classes.replyToReplyCommentInputSection}>
              <div className={classes.replyInputWrapper}>
                <div>
                  <img
                    src={
                      selectedStartup
                        ? selectedStartup.startupImage || defaultStartupImage
                        : currentUserProfileImage || profileImage
                    }
                    alt={
                      selectedStartup
                        ? selectedStartup.startupName
                        : "Your profile"
                    }
                    className={classes.replyUserImage}
                    onClick={() =>
                      handleNavigate(
                        selectedStartup
                          ? `startup/${selectedStartup.startupUrlSlug}`
                          : currentUser.urlSlug
                      )
                    }
                    style={{
                      borderRadius: selectedStartup ? 0 : "50%",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <textarea
                  ref={(el) => (replyInputRef.current[activeCommentId] = el)}
                  value={replyComments[activeCommentId] || ""}
                  onChange={(e) => handleReplyInputChange(e, activeCommentId)}
                  placeholder="Add a reply..."
                  className={classes.replyInput}
                />
              </div>
              <div
                onClick={() =>
                  handleAddComment(
                    post.id,
                    replyComments[activeCommentId],
                    activeCommentId
                  )
                }
                className={`${classes.addReplyButton} ${
                  !replyComments[activeCommentId]?.trim()
                    ? classes.disabled
                    : ""
                }`}
                disabled={!replyComments[activeCommentId]?.trim()}
              >
                Reply
              </div>
            </div>
          )}
      </div>
    );
  };

  useEffect(() => {
    const checkSafari = () => {
      const isSafari =
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        /apple/i.test(navigator.vendor);
      setIsSafari(isSafari);
      document.documentElement.style.setProperty(
        "--emoji-font-size",
        isSafari ? "0.75rem" : "0.875rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-left",
        isSafari ? "-0.25rem" : "-0.25rem"
      );
      document.documentElement.style.setProperty(
        "--count-margin-left",
        isSafari ? "0.25rem" : "0.25rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-text-size",
        isSafari ? "0.75rem" : "0.875rem"
      );

      document.documentElement.style.setProperty(
        "--emoji-margin-bottom",
        isSafari ? "0rem" : "0rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-top",
        isSafari ? "0rem" : "0rem"
      );
    };

    checkSafari();
  }, []);

  const RepostPopup = ({ message, onClose, postId, isRepost, isLoading }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          {isLoading ? (
            // Clock icon for "Deleting..." state
            <svg
              className={classes.tickIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="orangered"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            // Regular checkmark icon for other states
            <svg
              className={classes.tickIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="orangered"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                clipRule="evenodd"
              />
            </svg>
          )}
          <p>{message}</p>
          {isRepost && (
            <Link
              to={`/repost/${postId}`}
              state={{ fromRepostPopup: true }}
              className={classes.viewRepostLink}
            >
              View repost
            </Link>
          )}
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const PostedByMessage = ({
    post,
    currentUser,
    fromReactions,
    reactionEmoji,
  }) => {
    if (fromReactions) {
      return (
        <div className={classes.reactionHeader}>
          <img
            src={post.user.profileImage || profileImage}
            alt="Profile"
            className={classes.reactionImage}
            onClick={() =>
              handleNavigate(
                post.user.isStartup
                  ? `startup/${post.user.startupUrlSlug}`
                  : post.user.urlSlug
              )
            }
            style={{
              borderRadius: post.user.isStartup ? 0 : "50%",
              cursor: "pointer",
            }}
          />
          <div className={classes.reactionTextWrapper}>
            <span className={classes.reactionText}>
              <span
                className={classes.reactionUsername}
                onClick={() =>
                  handleNavigate(
                    post.user.isStartup
                      ? `startup/${post.user.startupUrlSlug}`
                      : post.user.urlSlug
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {post.user.id === currentUser?.uid
                  ? "You"
                  : `${post.user.firstName} ${post.user.lastName}`}
              </span>{" "}
              posted this
            </span>
          </div>
        </div>
      );
    }

    // Handle startup posts
    if (post.user.isStartup) {
      const isStartupOwner = post.user.startupOwnerId === currentUser?.uid;
      return (
        <div className={classes.reactionHeader}>
          <img
            src={post.user.profileImage || profileImage}
            alt="Profile"
            className={classes.reactionImage}
            onClick={() =>
              handleNavigate(
                post.user.isStartup
                  ? `startup/${post.user.startupUrlSlug}`
                  : post.user.urlSlug
              )
            }
            style={{
              borderRadius: post.user.isStartup ? 0 : "50%",
              cursor: "pointer",
            }}
          />
          <div className={classes.reactionTextWrapper}>
            <span className={classes.reactionText}>
              <span
                className={classes.reactionUsername}
                onClick={() =>
                  handleNavigate(
                    post.user.isStartup
                      ? `startup/${post.user.startupUrlSlug}`
                      : post.user.urlSlug
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {post.user.id === currentUser?.uid
                  ? "You"
                  : `${post.user.firstName} ${post.user.lastName}`}
              </span>{" "}
              posted this
            </span>
          </div>
        </div>
      );
    }

    // Handle regular user posts
    if (post.user.id === currentUser?.uid) {
      return (
        <div className={classes.reactionHeader}>
          <img
            src={post.user.profileImage || profileImage}
            alt="Profile"
            className={classes.reactionImage}
            onClick={() =>
              handleNavigate(
                post.user.isStartup
                  ? `startup/${post.user.startupUrlSlug}`
                  : post.user.urlSlug
              )
            }
            style={{
              borderRadius: post.user.isStartup ? 0 : "50%",
              cursor: "pointer",
            }}
          />
          <div className={classes.reactionTextWrapper}>
            <span className={classes.reactionText}>
              <span
                className={classes.reactionUsername}
                onClick={() =>
                  handleNavigate(
                    post.user.isStartup
                      ? `startup/${post.user.startupUrlSlug}`
                      : post.user.urlSlug
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {post.user.id === currentUser?.uid
                  ? "You"
                  : `${post.user.firstName} ${post.user.lastName}`}
              </span>{" "}
              posted this
            </span>
          </div>
        </div>
      );
    }

    return null;
  };

  const getPostRef = (post) => {
    if (post.user.isStartup) {
      return doc(
        firestore,
        `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${post.id}`
      );
    }
    return doc(firestore, `users/${post.user.id}/posts/${post.id}`);
  };

  const handleRepost = async () => {
    if (!currentUser || !post) return;

    const postId = post.id;
    const reposterInfo = getCommenterInfo(currentUser, selectedStartup);

    try {
      const postRef = getPostRef(post);
      const repostsPath = reposterInfo.isStartup
        ? `users/${currentUser.uid}/startups/${reposterInfo.startupId}/reposts`
        : `users/${currentUser.uid}/reposts`;
      const repostedPostRef = doc(firestore, repostsPath, postId);

      let updatedReposts = { ...(post.reposts || {}) };
      const now = serverTimestamp();

      if (updatedReposts[reposterInfo.id]) {
        await updateDoc(postRef, {
          [`reposts.${reposterInfo.id}`]: deleteField(),
        });
        delete updatedReposts[reposterInfo.id];
        await deleteDoc(repostedPostRef);

        const notificationRef = doc(
          firestore,
          `users/${post.user.startupOwnerId || post.user.id}/notifications`,
          `repost_${postId}_${reposterInfo.id}`
        );
        await deleteDoc(notificationRef);

        setRepostMessage({
          text: "Repost removed.",
          postId,
          isRepost: false,
        });
      } else {
        await updateDoc(postRef, {
          [`reposts.${reposterInfo.id}`]: now,
        });
        updatedReposts[reposterInfo.id] = now;

        await setDoc(repostedPostRef, {
          originalPosterId: post.user.startupOwnerId || post.user.id,
          postId: postId,
          repostedAt: now,
          originalDirectRepostTime: post.originalDirectRepostTime || now,
        });

        // Don't notify if it's effectively the same person
        const shouldNotify = post.user.isStartup
          ? // For startup posts, notify if:
            // - current user is not the startup owner OR
            // - reacting as a startup that is not the post owner
            post.user.startupOwnerId !== currentUser.uid ||
            (selectedStartup && post.user.startupId !== selectedStartup.id)
          : // For regular user posts, notify if:
            // - not reacting to own post AND
            // - not reacting as a startup owned by post owner
            post.user.id !== currentUser.uid &&
            (!selectedStartup ||
              post.user.id !== selectedStartup.startupOwnerId);

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${post.user.startupOwnerId || post.user.id}/notifications`,
            `repost_${postId}_${reposterInfo.id}`
          );

          const notificationData = {
            type: "repost",
            postId: postId,
            reposterId: reposterInfo.id,
            reposterName: reposterInfo.name,
            reposterImage: reposterInfo.image,
            createdAt: now,
            isNew: true,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
            ...(reposterInfo.isStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }

        setRepostMessage({
          text: "Repost successful.",
          postId,
          isRepost: true,
        });
      }

      setPost((prevPost) => ({
        ...prevPost,
        reposts: updatedReposts,
      }));
    } catch (error) {
      setRepostMessage({
        text: `Error: ${error.message}`,
        postId,
        isRepost: false,
      });
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUser({
            ...user,
            firstName: userData.firstName,
            lastName: userData.lastName,
            profileImage: userData.profileImage || profileImage,
            urlSlug: userData.urlSlug, // Add this line
          });
          setCurrentUserProfileImage(userData.profileImage || profileImage);
        } else {
          setCurrentUser(user);
          setCurrentUserProfileImage(profileImage);
        }
      } else {
        setCurrentUser(null);
        setCurrentUserProfileImage(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const checkIfUsersBlocked = async (currentUserId, otherUserId) => {
    // Check if current user has blocked the other user
    const currentUserBlockedRef = collection(
      firestore,
      `users/${currentUserId}/blockedUsers`
    );
    const currentUserBlockedSnapshot = await getDocs(currentUserBlockedRef);

    for (const doc of currentUserBlockedSnapshot.docs) {
      if (doc.data().userId === otherUserId) {
        return true;
      }
    }

    // Check if other user has blocked current user
    const otherUserBlockedRef = collection(
      firestore,
      `users/${otherUserId}/blockedUsers`
    );
    const otherUserBlockedSnapshot = await getDocs(otherUserBlockedRef);

    for (const doc of otherUserBlockedSnapshot.docs) {
      if (doc.data().userId === currentUserId) {
        return true;
      }
    }

    return false;
  };

  const fetchPost = async () => {
    try {
      setLoading(true);
      const usersRef = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersRef);

      let postData = null;
      let userData = null;
      let isStartupPost = false;
      let startupOwnerId = null;

      for (const userDoc of usersSnapshot.docs) {
        const userId = userDoc.id;

        // First check regular posts
        const postRef = doc(firestore, "users", userId, "posts", postId);
        let postSnapshot = await getDoc(postRef);

        // If not found in regular posts, check startup posts
        if (!postSnapshot.exists()) {
          const startupsRef = collection(firestore, `users/${userId}/startups`);
          const startupsSnapshot = await getDocs(startupsRef);

          for (const startupDoc of startupsSnapshot.docs) {
            const startupPostRef = doc(
              firestore,
              `users/${userId}/startups/${startupDoc.id}/posts/${postId}`
            );
            postSnapshot = await getDoc(startupPostRef);

            if (postSnapshot.exists()) {
              isStartupPost = true;
              startupOwnerId = userId;

              // Check if either user has blocked the other
              const isBlocked = await checkIfUsersBlocked(
                currentUser.uid,
                startupOwnerId
              );
              if (isBlocked) {
                setPost(null);
                setLoading(false);
                setHasAttemptedLoad(true);
                return;
              }

              const startupData = startupDoc.data();
              userData = {
                id: `startup_${startupDoc.id}`,
                firstName: startupData.startupName,
                lastName: "",
                profileImage: startupData.startupImage,
                bio: startupData.bio || "",
                link: startupData.link || null,
                linkText: startupData.linkText || null,
                isStartup: true,
                startupId: startupDoc.id,
                startupOwnerId: userId,
                startupUrlSlug: startupData.startupUrlSlug,
              };
              break;
            }
          }
        } else {
          // Check if either user has blocked the other for regular posts
          const isBlocked = await checkIfUsersBlocked(currentUser.uid, userId);
          if (isBlocked) {
            setPost(null);
            setLoading(false);
            setHasAttemptedLoad(true);
            return;
          }

          // Regular user post
          const docData = userDoc.data();
          userData = {
            id: userId,
            firstName: docData.firstName,
            lastName: docData.lastName,
            profileImage: docData.profileImage || profileImage,
            bio: docData.bio || "",
            link: docData.link || null,
            linkText: docData.linkText || null,
            isStartup: false,
            urlSlug: docData.urlSlug,
          };
        }

        if (postSnapshot.exists()) {
          postData = postSnapshot.data();
          break;
        }
      }

      if (postData && userData) {
        const connectionType = isStartupPost
          ? "Startup"
          : await checkConnectionType(currentUser.uid, userData.id);

        setPost({
          ...postData,
          id: postId,
          user: {
            ...userData,
            connectionType: connectionType,
            urlSlug: userData.urlSlug,
            startupUrlSlug: userData.startupUrlSlug,
          },
          reactions: postData.reactions || {},
          reposts: postData.reposts || {},
        });
      } else {
        setPost(null);
      }
    } catch (error) {
      setPost(null);
    } finally {
      setLoading(false);
      setHasAttemptedLoad(true);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchPost();
    }
  }, [postId, currentUser, location.state]);

  const handleRefresh = () => {
    setHasAttemptedLoad(false);
    setLoading(true);
    fetchPost();
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const handlePlayPause = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video
          .play()
          .then(() => setIsPlaying(true))
          .catch((error) => {
            console.log("Play was prevented:", error);
            setIsPlaying(false);
          });
      } else {
        video.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleVolumeToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (videoRef.current) {
      setVideoVolume(!videoVolume);
      videoRef.current.muted = videoVolume;
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const handleTimeUpdate = () => {
      if (video && video.duration) {
        setTimeLeft(video.duration - video.currentTime);
      }
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (video) {
          if (entry.isIntersecting) {
            video
              .play()
              .then(() => setIsPlaying(true))
              .catch((error) => {
                console.log("Autoplay was prevented");
                setIsPlaying(false);
              });
          } else {
            video.pause();
            setIsPlaying(false);
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    // Add timeupdate event listener
    video.addEventListener("timeupdate", handleTimeUpdate);
    observer.observe(video);

    // Start playing when mounted
    if (video.readyState >= 2) {
      video
        .play()
        .then(() => setIsPlaying(true))
        .catch((error) => console.log("Autoplay was prevented"));
    }

    return () => {
      video.removeEventListener("timeupdate", handleTimeUpdate);
      observer.disconnect();
    };
  }, []);

  const formatTimestamp = (timestamp) => {
    if (!timestamp || typeof timestamp.toDate !== "function") return "";
    const now = new Date();
    const postDate = timestamp.toDate();
    const diffInSeconds = Math.floor((now - postDate) / 1000);

    if (diffInSeconds < 60) return "now";
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)}d`;
    if (diffInSeconds < 2592000)
      return `${Math.floor(diffInSeconds / 604800)}w`;
    if (diffInSeconds < 31536000)
      return `${Math.floor(diffInSeconds / 2592000)}mo`;
    return `${Math.floor(diffInSeconds / 31536000)}y`;
  };

  const handleNavigate = async (target) => {
    if (!target) return;

    // Handle startup URLs directly
    if (target.startsWith("startup/")) {
      navigate(`/${target}`);
      return;
    }

    // If it looks like a URL slug (doesn't include any firebase ID characteristics), navigate directly
    if (!target.includes("/") && target.length < 30) {
      navigate(`/${target}`);
      return;
    }

    // Otherwise treat it as a user ID and look up their slug
    try {
      const userDoc = await getDoc(doc(firestore, "users", target));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        navigate(`/${userData.urlSlug}`);
      } else {
        setErrorPopup({
          message: "User not found.",
          isError: true,
        });
      }
    } catch (error) {
      setErrorPopup({
        message: "Error loading user profile.",
        isError: true,
      });
    }
  };

  const renderPostText = (post) => {
    if (!post.text) return null;

    const lines = post.text.split("\n");
    const isLongPost = lines.length > 3 || post.text.length > 149;
    const hasNoInteractionsAndNoMedia =
      !post.content &&
      (!post.reactions || Object.keys(post.reactions).length === 0) &&
      (!post.reposts || Object.keys(post.reposts).length === 0) &&
      (!post.commentCount || post.commentCount === 0);

    // Check if the post has a PDF attachment
    const hasPdfAttachment = post.content && post.content.type === "pdf";

    const displayText = expandedPost
      ? post.text
      : lines.slice(0, 3).join("\n").slice(0, 149);

    return (
      <div className={classes.postTextContainer}>
        <span
          className={`${classes.postText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{
            __html: convertUrlsToLinks(displayText) + (expandedPost ? "" : ""),
          }}
          onClick={handleLinkClick}
        />
        {!expandedPost && isLongPost && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              setExpandedPost(true);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? post.content.url.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = (totalImages, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const PDFPreview = React.memo(
    ({ content, hasInteractions, classes }) => {
      const getFileName = React.useCallback(() => {
        if (!content?.fileName) return "Unnamed PDF";
        const rawFileName = Array.isArray(content.fileName)
          ? content.fileName[0] || "Unnamed PDF"
          : content.fileName;
        return rawFileName.replace(/\.pdf$/i, "");
      }, [content?.fileName]);

      if (!content || !content.url) return null;

      return (
        <div className={classes.postContentWrapper}>
          <a
            href={content.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`${classes.pdfPreview} ${
              !hasInteractions ? classes.pdfPreviewNoInteractions : ""
            }`}
          >
            <div className={classes.pdfBox}>
              <div className={classes.fileContainer}>
                <div className={classes.fileAbout}>
                  <div className={classes.fileName}>{getFileName()}</div>
                </div>
                <div className={classes.open}>
                  Open PDF
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>
      );
    },
    (prevProps, nextProps) => {
      return (
        prevProps.content?.url === nextProps.content?.url &&
        prevProps.content?.fileName === nextProps.content?.fileName &&
        prevProps.hasInteractions === nextProps.hasInteractions
      );
    }
  );

  const renderPostContent = (post) => {
    if (!post || !post.content) return null;

    switch (post.content.type) {
      case "image":
        return (
          <div className={classes.postContentWrapper}>
            <div className={classes.imageCarousel}>
              {Array.isArray(post.content.url) ? (
                <div style={{ position: "relative" }}>
                  {post.content.url.length > 1 && (
                    <div className={classes.imageCounter}>
                      <div className={classes.imageCounterInner}>
                        {`${currentImageIndex + 1}/${post.content.url.length}`}
                      </div>
                    </div>
                  )}
                  <img
                    src={post.content.url[currentImageIndex]}
                    alt=""
                    className={classes.postContentImage}
                    loading="eager"
                    decoding="sync"
                  />
                  {post.content.url.length > 1 && (
                    <div className={classes.navigationButtons}>
                      <button
                        className={classes.navButton}
                        onClick={handlePrevImage}
                        aria-label="Previous image"
                      >
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m14 8-4 4 4 4"
                          />
                        </svg>
                      </button>
                      <button
                        className={classes.navButton}
                        onClick={(e) =>
                          handleNextImage(post.content.url.length, e)
                        }
                        aria-label="Next image"
                      >
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m10 16 4-4-4-4"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <img
                  src={post.content.url}
                  alt=""
                  className={classes.postContentImage}
                />
              )}
            </div>
          </div>
        );
      // First, update the video element in renderPostContent:
      case "video":
        return (
          <div className={classes.videoContainer}>
            <div className={classes.videoTimeCounter}>
              <div className={classes.videoTimeCounterInner}>
                {timeLeft > 0 ? formatTime(timeLeft) : "0:00"}
              </div>
            </div>
            <video
              ref={videoRef}
              className={classes.postContentVideo}
              playsInline
              muted={!videoVolume}
              loop
              controls={false}
              preload="auto"
              poster={post.content.thumbnail || ""}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              onLoadedData={(e) => setTimeLeft(e.target.duration)}
              onTimeUpdate={(e) =>
                setTimeLeft(e.target.duration - e.target.currentTime)
              }
            >
              <source src={post.content.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className={classes.videoControlsLeft}>
              <button
                type="button"
                className={classes.volumeControl}
                onClick={handlePlayPause}
              >
                {isPlaying ? (
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="white"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H8Zm7 0a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="white"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.6 5.2A1 1 0 0 0 7 6v12a1 1 0 0 0 1.6.8l8-6a1 1 0 0 0 0-1.6l-8-6Z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            </div>
            <div className={classes.videoControlsRight}>
              <button
                type="button"
                className={classes.volumeControl}
                onClick={handleVolumeToggle}
              >
                {videoVolume ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="24px"
                    height="24px"
                  >
                    <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="24px"
                    height="24px"
                  >
                    <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        );
      case "pdf":
        return post.content ? (
          <PDFPreview
            content={post.content}
            classes={classes}
            hasInteractions={true}
          />
        ) : null;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!videoRef.current) return;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (videoRef.current) {
          if (entry.isIntersecting) {
            videoRef.current
              .play()
              .catch((error) => console.log("Autoplay was prevented"));
          } else {
            videoRef.current.pause();
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    observer.observe(videoRef.current);

    return () => {
      observer.disconnect();
    };
  }, [post]);

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const handleEditComment = (commentId, isReply = false) => {
    if (!post || !comments[post.id]) {
      return;
    }

    const postComments = comments[post.id];
    const commentToEdit = postComments.find((c) => c.id === commentId);

    if (commentToEdit) {
      setEditingCommentId(commentId);
      const strippedText = stripHtmlTags(commentToEdit.text);
      setEditCommentText(strippedText);
      setOpenCommentDropdown(null);

      setTimeout(() => {
        if (editTextareaRef.current) {
          editTextareaRef.current.focus();
          adjustTextareaHeight(editTextareaRef.current);
          const length = editTextareaRef.current.value.length;
          editTextareaRef.current.setSelectionRange(length, length);
        }
      }, 0);
    } else {
    }
  };

  const handleSaveCommentChanges = async (commentId, isReply = false) => {
    if (!currentUser || !post) return;

    const trimmedText = editCommentText.trim();
    if (trimmedText === "") {
      setErrorPopup({
        message: "Comment cannot be empty.",
        isError: true,
      });
      return;
    }

    try {
      // Get the correct path based on whether it's a startup post
      const basePath = post.user.isStartup
        ? `users/${post.user.startupOwnerId}/startups/${post.user.startupId}`
        : `users/${post.user.id}`;

      const commentRef = doc(
        firestore,
        `${basePath}/posts/${post.id}/comments/${commentId}`
      );

      // Fetch the comment to verify ownership
      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();

      // Verify ownership
      let canEdit = false;
      if (commentData.isStartup) {
        canEdit = commentData.startupOwnerId === currentUser.uid;
      } else {
        canEdit = commentData.userId === currentUser.uid;
      }

      if (!canEdit) {
        throw new Error("Not authorized to edit this comment");
      }

      // Update the comment
      const processedText = convertUrlsToLinks(trimmedText);
      await updateDoc(commentRef, {
        text: processedText,
        edited: true,
      });

      // Update local state
      setComments((prevComments) => ({
        ...prevComments,
        [post.id]: prevComments[post.id].map((comment) =>
          comment.id === commentId
            ? { ...comment, text: processedText, edited: true }
            : comment
        ),
      }));

      setEditingCommentId(null);
      setEditCommentText("");
    } catch (error) {
      setErrorPopup({
        message:
          error.message === "Comment not found"
            ? "This comment has been deleted."
            : error.message === "Not authorized to edit this comment"
            ? "You don't have permission to edit this comment."
            : "An error occurred while updating the comment.",
        isError: true,
      });
    }
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditCommentText("");
  };

  const getNotificationFields = (baseFields, selectedStartup = null) => {
    if (selectedStartup) {
      return {
        ...baseFields,
        isStartup: true,
        startupId: selectedStartup.id,
        startupOwnerId: currentUser.uid,
        startupName: selectedStartup.startupName,
        startupImage: selectedStartup.startupImage,
      };
    }
    return {
      ...baseFields,
      isStartup: false,
    };
  };

  const handleInteraction = (e, type) => {
    // Prevent default behavior for all interactions
    e.preventDefault();
    e.stopPropagation();

    const target = e.target;
    const isStartupToggle = target.closest(".interactionToggle");

    if (!isStartupToggle) {
      switch (type) {
        case "boost":
          handleEmojiSelect("👍");
          break;
        case "comment":
          toggleComments(); // Changed from toggleCommentInput to toggleComments
          break;
        case "share":
          handleShare(post.id);
          break;
      }
    }
  };

  const handleEmojiSelect = async (emoji) => {
    if (!currentUser) return;

    try {
      const batch = writeBatch(firestore);
      const path = selectedStartup
        ? `users/${currentUser.uid}/startups/${selectedStartup.id}`
        : `users/${currentUser.uid}`;

      const postRef = getPostRef(post);
      const reactorId = selectedStartup
        ? `startup_${selectedStartup.id}`
        : currentUser.uid;

      let updatedReactions = { ...(post.reactions || {}) };

      if (updatedReactions[reactorId] === emoji) {
        await updateDoc(postRef, {
          [`reactions.${reactorId}`]: deleteField(),
        });
        delete updatedReactions[reactorId];

        const reactedPostRef = doc(
          firestore,
          `${path}/reactedPosts/${post.id}`
        );
        await deleteDoc(reactedPostRef);

        if (post.user.id !== currentUser.uid) {
          const notificationRef = doc(
            firestore,
            `users/${
              post.user.startupOwnerId || post.user.id
            }/notifications/reaction_${post.id}_${reactorId}`
          );
          await deleteDoc(notificationRef);
        }
      } else {
        await updateDoc(postRef, {
          [`reactions.${reactorId}`]: emoji,
        });
        updatedReactions[reactorId] = emoji;

        const reactedPostRef = doc(
          firestore,
          `${path}/reactedPosts/${post.id}`
        );
        await setDoc(reactedPostRef, {
          originalPosterId: post.user.startupOwnerId || post.user.id,
          postId: post.id,
          reactedAt: serverTimestamp(),
          emoji: emoji,
        });

        // Don't notify if it's effectively the same person
        const shouldNotify = post.user.isStartup
          ? // For startup posts, notify if:
            // - current user is not the startup owner OR
            // - reacting as a startup that is not the post owner
            post.user.startupOwnerId !== currentUser.uid ||
            (selectedStartup && post.user.startupId !== selectedStartup.id)
          : // For regular user posts, notify if:
            // - not reacting to own post AND
            // - not reacting as a startup owned by post owner
            post.user.id !== currentUser.uid &&
            (!selectedStartup ||
              post.user.id !== selectedStartup.startupOwnerId);

        if (shouldNotify) {
          const notificationRef = doc(
            firestore,
            `users/${
              post.user.startupOwnerId || post.user.id
            }/notifications/reaction_${post.id}_${reactorId}`
          );

          const notificationData = {
            type: "reaction",
            postId: post.id,
            reactorId: reactorId,
            reactorName: selectedStartup
              ? selectedStartup.startupName
              : `${currentUser.firstName} ${currentUser.lastName}`,
            reactorImage: selectedStartup
              ? selectedStartup.startupImage
              : currentUserProfileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
            ...(selectedStartup && {
              isStartup: true,
              startupId: selectedStartup.id,
              startupOwnerId: currentUser.uid,
              startupImage: selectedStartup.startupImage,
            }),
          };

          await setDoc(notificationRef, notificationData);
        }
      }

      setPost((prevPost) => ({
        ...prevPost,
        reactions: updatedReactions,
      }));

      setActiveEmojiPicker(null);
    } catch (error) {}
  };

  const getCommenterInfo = (currentUser, selectedStartup) => {
    if (selectedStartup) {
      return {
        id: `startup_${selectedStartup.id}`,
        name: selectedStartup.startupName,
        image: selectedStartup.startupImage,
        bio: selectedStartup.bio || "",
        link: selectedStartup.link || null,
        linkText: selectedStartup.linkText || null,
        connectionType: "Startup",
        isStartup: true,
        startupId: selectedStartup.id,
        ownerId: currentUser.uid,
      };
    }

    return {
      id: currentUser.uid,
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      image: currentUser.profileImage,
      bio: currentUser.bio || "",
      link: currentUser.link || null,
      linkText: currentUser.linkText || null,
      connectionType: "You",
      isStartup: false,
    };
  };

  const renderEditButtons = (commentId, isReply = false) => {
    // Find the original text from the comments state
    const originalText =
      comments[post.id]?.find((c) => c.id === commentId)?.text || "";
    const strippedOriginalText = stripHtmlTags(originalText).trim();
    const currentEditText = editCommentText.trim();

    // Button should be disabled if:
    // 1. Current text is empty OR
    // 2. Current text is the same as original text
    const isDisabled =
      !currentEditText || currentEditText === strippedOriginalText;

    return (
      <div className={classes.editCommentButtons}>
        <div
          className={`${classes.saveChangesButton} ${
            isDisabled ? classes.disabledButton : ""
          }`}
          onClick={() => {
            if (!isDisabled) {
              handleSaveCommentChanges(commentId, isReply);
            }
          }}
        >
          Save changes
        </div>
        <div className={classes.dot}> • </div>
        <div className={classes.cancelEditButton} onClick={handleCancelEdit}>
          Cancel
        </div>
      </div>
    );
  };

  const handleReactionsClick = () => {
    if (!post || !post.reactions) return;
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({
      postId: post.id,
      reactions: post.reactions,
    });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  const handleRepostsClick = () => {
    if (!post || !post.reposts) return;
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({
      postId: post.id,
      reposts: post.reposts,
    });
    setShowRepostsModal(true);
    setIsRepostsModalOpen(true);
  };

  const handleDeletePost = async () => {
    if (!currentUser) {
      return;
    }

    // Show "Deleting..." message immediately
    setDeletingPostMessage({
      text: "Deleting post...",
      isLoading: true,
    });

    try {
      // Verify post exists and user has permission to delete
      const targetPost = post;
      if (!targetPost) {
        setErrorPopup({
          message: "This post has been deleted or is no longer available.",
          isError: true,
        });
        return;
      }

      // Get the correct post reference based on whether it's a startup post
      const postRef = targetPost.user.isStartup
        ? doc(
            firestore,
            `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}/posts/${targetPost.id}`
          )
        : doc(firestore, `users/${targetPost.user.id}/posts/${targetPost.id}`);

      const postSnap = await getDoc(postRef);
      if (!postSnap.exists()) {
        throw new Error("Post not found");
      }

      const postData = postSnap.data();

      // Verify permission to delete
      const canDelete = targetPost.user.isStartup
        ? targetPost.user.startupOwnerId === currentUser.uid
        : targetPost.user.id === currentUser.uid;

      if (!canDelete) {
        setErrorPopup({
          message: "You don't have permission to delete this post.",
          isError: true,
        });
        return;
      }

      let batch = writeBatch(firestore);
      let operationCount = 0;

      const commitBatchIfNeeded = async () => {
        if (operationCount >= 450) {
          await batch.commit();
          batch = writeBatch(firestore);
          operationCount = 0;
        }
      };

      const affectedUserIds = new Set();
      const affectedStartupIds = new Set();

      // Get comments and collect affected users/startups
      const commentsRef = collection(
        firestore,
        targetPost.user.isStartup
          ? `users/${targetPost.user.startupOwnerId}/startups/${targetPost.user.startupId}/posts/${targetPost.id}/comments`
          : `users/${targetPost.user.id}/posts/${targetPost.id}/comments`
      );
      const commentsSnapshot = await getDocs(commentsRef);

      commentsSnapshot.docs.forEach((doc) => {
        const commentData = doc.data();
        if (commentData.isStartup) {
          affectedStartupIds.add(commentData.startupId);
        } else {
          affectedUserIds.add(commentData.userId);
        }
      });

      // Clean up comments in commentedPosts for regular users
      for (const userId of affectedUserIds) {
        try {
          const commentedPostRef = doc(
            firestore,
            `users/${userId}/commentedPosts/${targetPost.id}`
          );
          const commentedPostSnap = await getDoc(commentedPostRef);
          if (commentedPostSnap.exists()) {
            batch.delete(commentedPostRef);
            operationCount++;
            await commitBatchIfNeeded();
          }
        } catch (error) {}
      }

      // Clean up comments in commentedPosts for startups
      for (const startupId of affectedStartupIds) {
        const usersSnapshot = await getDocs(collection(firestore, "users"));
        for (const userDoc of usersSnapshot.docs) {
          try {
            const startupCommentedPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/commentedPosts/${targetPost.id}`
            );
            const startupCommentedPostSnap = await getDoc(
              startupCommentedPostRef
            );
            if (startupCommentedPostSnap.exists()) {
              batch.delete(startupCommentedPostRef);
              operationCount++;
              await commitBatchIfNeeded();
            }
          } catch (error) {}
        }
      }

      // Clean up all notifications
      const usersSnapshot = await getDocs(collection(firestore, "users"));
      for (const userDoc of usersSnapshot.docs) {
        try {
          // Delete all notifications related to this post
          const notificationsQuery = query(
            collection(firestore, `users/${userDoc.id}/notifications`),
            where("postId", "==", targetPost.id)
          );
          const notificationsSnapshot = await getDocs(notificationsQuery);
          const notificationDeletePromises = notificationsSnapshot.docs.map(
            (notificationDoc) => {
              batch.delete(notificationDoc.ref);
              operationCount++;
              return commitBatchIfNeeded();
            }
          );
          await Promise.all(notificationDeletePromises);

          // Clean up startup notifications
          const userStartupsSnapshot = await getDocs(
            collection(firestore, `users/${userDoc.id}/startups`)
          );
          for (const startupDoc of userStartupsSnapshot.docs) {
            const startupNotificationsQuery = query(
              collection(firestore, `users/${userDoc.id}/notifications`),
              where("postId", "==", targetPost.id)
            );
            const startupNotificationsSnapshot = await getDocs(
              startupNotificationsQuery
            );
            const startupNotificationDeletePromises =
              startupNotificationsSnapshot.docs.map((doc) => {
                batch.delete(doc.ref);
                operationCount++;
                return commitBatchIfNeeded();
              });
            await Promise.all(startupNotificationDeletePromises);
          }
        } catch (error) {}
      }

      // Process reactions
      if (postData.reactions) {
        for (const userId of Object.keys(postData.reactions)) {
          try {
            if (userId.startsWith("startup_")) {
              const startupId = userId.replace("startup_", "");
              const usersSnapshot = await getDocs(
                collection(firestore, "users")
              );

              for (const userDoc of usersSnapshot.docs) {
                const startupReactedPostRef = doc(
                  firestore,
                  `users/${userDoc.id}/startups/${startupId}/reactedPosts/${targetPost.id}`
                );
                batch.delete(startupReactedPostRef);
                operationCount++;
                await commitBatchIfNeeded();
              }
            } else {
              const userReactedPostRef = doc(
                firestore,
                `users/${userId}/reactedPosts/${targetPost.id}`
              );
              batch.delete(userReactedPostRef);
              operationCount++;
              await commitBatchIfNeeded();
            }
          } catch (error) {}
        }
      }

      // Process reposts
      if (postData.reposts) {
        for (const userId of Object.keys(postData.reposts)) {
          try {
            if (userId.startsWith("startup_")) {
              const startupId = userId.replace("startup_", "");
              const usersSnapshot = await getDocs(
                collection(firestore, "users")
              );

              for (const userDoc of usersSnapshot.docs) {
                const startupRepostRef = doc(
                  firestore,
                  `users/${userDoc.id}/startups/${startupId}/reposts/${targetPost.id}`
                );
                batch.delete(startupRepostRef);
                operationCount++;
                await commitBatchIfNeeded();
              }
            } else {
              const userRepostRef = doc(
                firestore,
                `users/${userId}/reposts/${targetPost.id}`
              );
              batch.delete(userRepostRef);
              operationCount++;
              await commitBatchIfNeeded();
            }
          } catch (error) {}
        }
      }

      // Delete all comments
      const commentDeletePromises = commentsSnapshot.docs.map((commentDoc) =>
        deleteDoc(commentDoc.ref)
      );
      await Promise.all(commentDeletePromises);

      // Clean up media content
      if (postData.content && postData.content.type) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `postContent/${targetPost.user.id}/${targetPost.id}`
        );

        try {
          if (
            postData.content.type === "image" &&
            Array.isArray(postData.content.url)
          ) {
            for (const imageUrl of postData.content.url) {
              const imageRef = ref(storage, imageUrl);
              await deleteObject(imageRef);
            }
          } else if (
            ["image", "video", "pdf"].includes(postData.content.type)
          ) {
            const fileRef = ref(storage, postData.content.url);
            await deleteObject(fileRef);

            if (
              postData.content.type === "video" &&
              postData.content.thumbnail
            ) {
              const thumbnailRef = ref(storage, postData.content.thumbnail);
              await deleteObject(thumbnailRef);
            }
          }

          const folderContents = await listAll(storageRef);
          await Promise.all(
            folderContents.items.map((item) => deleteObject(item))
          );
        } catch (error) {}
      }

      // Delete the post itself
      batch.delete(postRef);
      operationCount++;

      // Commit final batch
      await batch.commit();

      // Navigate based on where the post came from
      if (targetPost.user.isStartup) {
        // For startup posts, get the startup's data to find the correct urlSlug
        const startupDoc = await getDoc(
          doc(
            firestore,
            `users/${currentUser.uid}/startups/${targetPost.user.startupId}`
          )
        );

        if (startupDoc.exists()) {
          const startupData = startupDoc.data();
          navigate(`/startup/${startupData.startupUrlSlug}`);
        } else {
          navigate("/");
        }
      } else {
        // For regular user posts, use the existing logic
        const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          navigate(`/${userData.urlSlug}`);
        } else {
          navigate("/");
        }
      }

      // Show succes

      // Show success message
      setDeletingPostMessage({
        text: "Post deleted",
        isLoading: false,
      });
    } catch (error) {
      setErrorPopup({
        message: "Failed to delete post.",
        isError: true,
      });
    } finally {
      // If there was an error, clear the "Deleting..." message
      if (deletingPostMessage?.isLoading) {
        setDeletingPostMessage(null);
      }
    }
  };

  // Helper function to delete post media
  const deletePostMedia = async (content) => {
    const storage = getStorage();
    const storageRef = ref(storage, `postContent/${post.user.id}/${post.id}`);

    try {
      if (content.type === "image" && Array.isArray(content.url)) {
        for (const imageUrl of content.url) {
          const imageRef = ref(storage, imageUrl);
          await deleteObject(imageRef);
        }
      } else if (["image", "video", "pdf"].includes(content.type)) {
        const fileRef = ref(storage, content.url);
        await deleteObject(fileRef);
      }

      // Clean up any remaining files in the folder
      const folderContents = await listAll(storageRef);
      await Promise.all(folderContents.items.map((item) => deleteObject(item)));
    } catch (error) {}
  };

  const deleteAssociatedNotifications = async (postId) => {
    const batch = writeBatch(firestore);
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersRef);

    for (const userDoc of usersSnapshot.docs) {
      const notificationsRef = collection(userDoc.ref, "notifications");
      const q = query(notificationsRef, where("postId", "==", postId));
      const notificationsSnapshot = await getDocs(q);

      notificationsSnapshot.forEach((notificationDoc) => {
        batch.delete(notificationDoc.ref);
      });
    }

    await batch.commit();
  };

  const handleReportPost = () => {
    setShowDropdown(false);
    setReportMessage({
      text: "Report submitted.",
    });
  };

  const handleReportComment = (commentId) => {
    setOpenCommentDropdown(null);
    setReportMessage({
      text: "Report submitted.",
    });
  };

  const RepostCount = ({ reposts, onClick }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0) return null;

    return (
      <div className={classes.repostCount} onClick={onClick}>
        <span>{repostCount}</span>
        <span> repost{repostCount !== 1 ? "s" : ""}</span>
      </div>
    );
  };

  const handleEditPost = () => {
    setPostModalScrollPosition(window.scrollY);
    setShowEditModal(true);
    setIsPostModalOpen(true);
  };

  const handleEditComplete = async (updatedText, isEdited) => {
    try {
      // Get the correct path based on whether it's a startup post
      const postRef = post.user.isStartup
        ? doc(
            firestore,
            `users/${post.user.startupOwnerId}/startups/${post.user.startupId}/posts/${post.id}`
          )
        : doc(firestore, `users/${post.user.id}/posts/${post.id}`);

      const updateData = {
        text: updatedText,
        isEdited: isEdited,
      };

      await updateDoc(postRef, updateData);

      // Update local state
      setPost((prevPost) => ({
        ...prevPost,
        text: updatedText,
        isEdited: isEdited,
      }));

      setIsEditingComplete(true);
      setShowEditModal(false);
      setIsPostModalOpen(false);
    } catch (error) {
      setErrorPopup({
        message: "Error updating post.",
        isError: true,
      });
    }
  };

  useEffect(() => {
    if (isEditingComplete) {
      window.scrollTo(0, postModalScrollPosition);
      setIsEditingComplete(false);
    }
  }, [isEditingComplete, postModalScrollPosition]);

  const handleStartupSelect = useCallback((startup) => {
    setSelectedStartup(startup);
  }, []);

  const handleModalOpen = useCallback((toggleId) => {
    setStartupModalScrollPosition(window.scrollY);
    setShowStartupModal(true);
    setActiveStartupToggle(toggleId);
  }, []);

  const handleModalClose = useCallback(() => {
    setShowStartupModal(false);
    setTimeout(() => {
      window.scrollTo(0, startupModalScrollPosition);
    }, 0);
  }, [startupModalScrollPosition]);

  const getMediaClass = (post) => {
    if (!post || !post.content) return "";
    if (post.content.type === "video") return classes.hasVideo;
    if (post.content.type === "image") {
      return Array.isArray(post.content.url)
        ? classes.hasMultipleImages
        : classes.hasImage;
    }
    return "";
  };

  return (
    <div
      className={`${classes.page} ${
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isPostModalOpen ||
        showStartupModal ||
        activeEmojiPicker === "post" ||
        activeEmojiPicker !== null
          ? classes.noScroll
          : ""
      }`}
      style={
        isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isPostModalOpen
          ? { top: `-${postModalScrollPosition}px` }
          : showStartupModal
          ? { top: `-${startupModalScrollPosition}px` }
          : activeEmojiPicker === "post"
          ? { top: `-${emojiPickerScrollPosition}px` }
          : activeEmojiPicker !== null
          ? { top: `-${commentEmojiPickerScrollPosition}px` }
          : {}
      }
    >
      {isMobile ? (
        <MobileNavbar userImage={currentUserProfileImage} />
      ) : (
        <MainNavbar userImage={currentUserProfileImage} />
      )}
      {showStartupModal && (
        <StartupInteractionModal
          onClose={() => {
            setShowStartupModal(false);
            handleModalClose();
            setActiveStartupToggle(null);
          }}
          currentUser={currentUser}
          onStartupSelect={(startup) => {
            handleStartupSelect(startup);
            setShowStartupModal(false);
            handleModalClose();
          }}
          selectedStartup={selectedStartup}
          currentUserProfileImage={currentUserProfileImage}
        />
      )}
      {/* Add these alongside your other popups */}
      {deletingPostMessage && (
        <RepostPopup
          message={deletingPostMessage.text}
          onClose={() => setDeletingPostMessage(null)}
          isLoading={deletingPostMessage.isLoading}
        />
      )}

      {deletingCommentMessage && (
        <RepostPopup
          message={deletingCommentMessage.text}
          onClose={() => setDeletingCommentMessage(null)}
          isLoading={deletingCommentMessage.isLoading}
        />
      )}

      {reportMessage && (
        <RepostPopup
          message={reportMessage.text}
          onClose={() => setReportMessage(null)}
        />
      )}

      {showEditModal && (
        <PostModal
          onClose={() => {
            setShowEditModal(false);
            setIsPostModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, postModalScrollPosition);
            }, 0);
          }}
          currentUser={currentUser}
          initialPost={post}
          onEditComplete={handleEditComplete}
        />
      )}

      {repostMessage && (
        <RepostPopup
          message={repostMessage.text}
          postId={repostMessage.postId}
          isRepost={repostMessage.isRepost}
          onClose={() => setRepostMessage(null)}
        />
      )}
      {errorPopup && (
        <ErrorPopup
          message={errorPopup.message}
          onClose={() => setErrorPopup(null)}
        />
      )}
      <div className={classes.content}>
        <div className={classes.centreCards}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : post ? (
            <div className={`${classes.postCard} ${getMediaClass(post)}`}>
              <div className={classes.reactionHeader}>
                <img
                  src={post.user.profileImage || profileImage}
                  alt="Profile"
                  className={classes.reactionImage}
                  onClick={() =>
                    handleNavigate(
                      post.user.isStartup
                        ? `startup/${post.user.startupUrlSlug}`
                        : post.user.urlSlug
                    )
                  }
                  style={{
                    borderRadius: post.user.isStartup ? 0 : "50%",
                    cursor: "pointer",
                  }}
                />
                <div className={classes.reactionTextWrapper}>
                  <span className={classes.reactionText}>
                    <span
                      className={classes.reactionUsername}
                      onClick={() =>
                        handleNavigate(
                          post.user.isStartup
                            ? `startup/${post.user.startupUrlSlug}`
                            : post.user.urlSlug
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {post.user.id === currentUser?.uid
                        ? "You"
                        : `${post.user.firstName} ${post.user.lastName}`}
                    </span>{" "}
                    posted this
                  </span>
                </div>
              </div>

              <div className={classes.postHeader}>
                <div className={classes.userInfo}>
                  <img
                    className={classes.postImage}
                    src={post.user.profileImage || profileImage}
                    alt=""
                    onClick={() =>
                      handleNavigate(
                        post.user.isStartup
                          ? `startup/${post.user.startupUrlSlug}`
                          : post.user.urlSlug
                      )
                    }
                    style={{
                      borderRadius: post.user.isStartup ? 0 : "50%",
                      cursor: "pointer",
                    }}
                  />
                  <div className={classes.userDetails}>
                    <div className={classes.userNameContainer}>
                      <p
                        className={classes.userName}
                        onClick={() =>
                          handleNavigate(
                            post.user.isStartup
                              ? `startup/${post.user.startupUrlSlug}`
                              : post.user.urlSlug
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <span className={classes.nameText}>
                          {`${post.user.firstName} ${post.user.lastName}`}
                        </span>
                      </p>
                      {post.user.connectionType && (
                        <span className={classes.connectionType}>
                          • {post.user.connectionType}
                        </span>
                      )}
                    </div>
                    {post.user.bio && (
                      <p className={classes.bio}>{post.user.bio}</p>
                    )}
                    {post.user.link && (
                      <div className={classes.userLinkContainer}>
                        <a
                          href={post.user.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.userLink}
                        >
                          <span className={classes.userLinkText}>
                            {post.user.linkText || post.user.link}
                          </span>
                          <svg
                            className={classes.userLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.timeAndEditDetails}>
                      {post.createdAt && (
                        <p className={classes.time}>
                          {formatTimestamp(post.createdAt)}
                        </p>
                      )}

                      {post.isEdited && (
                        <div className={classes.editDot}>
                          •
                          <span className={classes.editedPostText}>Edited</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.postOptions}>
                  <svg
                    className={`${classes.optionsIcon} ${
                      showDropdown ? classes.optionsIconActive : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDropdown(!showDropdown);
                    }}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {showDropdown && renderPostDropdown()}
                </div>
              </div>
              {post.text && renderPostText(post)}
              {renderPostContent(post)}
              {post.reactions && Object.keys(post.reactions).length > 0 && (
                <div
                  className={classes.postInteractionsInfo}
                  onClick={() => {
                    if (post && post.reactions) {
                      setReactionsModalScrollPosition(window.scrollY);
                      setActivePostReactions({
                        postId: post.id,
                        reactions: post.reactions,
                      });
                      setShowReactionsModal(true);
                      setIsReactionsModalOpen(true);
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  See who boosted{" "}
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8 20V7m0 13-4-4m4 4 4-4m4-12v13m0-13 4 4m-4-4-4 4"
                    />
                  </svg>
                </div>
              )}
              <div className={classes.postInteractions}>
                <MemoizedToggle
                  currentUser={currentUser}
                  onStartupSelect={handleStartupSelect}
                  selectedStartup={selectedStartup}
                  currentUserProfileImage={currentUserProfileImage}
                  onModalOpen={() => handleModalOpen("top")}
                  onModalClose={handleModalClose}
                  className={classes.interactionToggle}
                  userStartups={userStartups}
                  toggleId="top"
                />

                <button
                  type="button"
                  className={`${classes.interactionButton} ${
                    (selectedStartup &&
                      post.reactions?.[`startup_${selectedStartup.id}`]) ||
                    (!selectedStartup && post.reactions?.[currentUser?.uid])
                      ? classes.activeInteraction
                      : ""
                  }`}
                  onClick={(e) => handleInteraction(e, "boost")}
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 4a1 1 0 0 0-.822 1.57L6.632 12l-4.454 6.43A1 1 0 0 0 3 20h13.153a1 1 0 0 0 .822-.43l4.847-7a1 1 0 0 0 0-1.14l-4.847-7a1 1 0 0 0-.822-.43H3Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Boost{" "}
                  {post.reactions &&
                    Object.keys(post.reactions).length > 0 &&
                    `(${Object.keys(post.reactions).length})`}
                </button>

                <button
                  className={`${classes.interactionButton} ${
                    (selectedStartup &&
                      post.comments?.[`startup_${selectedStartup.id}`]) ||
                    (!selectedStartup && post.comments?.[currentUser?.uid])
                      ? classes.activeInteraction
                      : ""
                  }`}
                  onClick={(e) => handleInteraction(e, "comment")}
                >
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-6.616l-2.88 2.592C8.537 20.461 7 19.776 7 18.477V17H5a2 2 0 0 1-2-2V6Zm4 2a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2H7Zm8 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Zm-8 3a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H7Zm5 0a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Comment {post.commentCount > 0 && `(${post.commentCount})`}
                </button>

                <button
                  className={classes.interactionButton}
                  onClick={(e) => handleInteraction(e, "share")}
                >
                  {shareStates[post.id] === "Copied" ? (
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                        clipRule="evenodd"
                      />
                      <path
                        fillRule="evenodd"
                        d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5.027 10.9a8.729 8.729 0 0 1 6.422-3.62v-1.2A2.061 2.061 0 0 1 12.61 4.2a1.986 1.986 0 0 1 2.104.23l5.491 4.308a2.11 2.11 0 0 1 .588 2.566 2.109 2.109 0 0 1-.588.734l-5.489 4.308a1.983 1.983 0 0 1-2.104.228 2.065 2.065 0 0 1-1.16-1.876v-.942c-5.33 1.284-6.212 5.251-6.25 5.441a1 1 0 0 1-.923.806h-.06a1.003 1.003 0 0 1-.955-.7A10.221 10.221 0 0 1 5.027 10.9Z" />
                    </svg>
                  )}
                  {shareStates[post.id] || "Share"}
                </button>
              </div>
              {activeEmojiPicker === "post" && (
                <EmojiPicker
                  onEmojiClick={handleEmojiSelect}
                  onClose={() => {
                    setActiveEmojiPicker(null);
                    setTimeout(() => {
                      window.scrollTo(0, emojiPickerScrollPosition);
                    }, 0);
                  }}
                />
              )}
              {showComments && (
                <div className={classes.commentsSection}>
                  <CommentInput
                    postId={post.id}
                    onSubmit={handleAddComment}
                    currentUser={currentUser}
                    selectedStartup={selectedStartup}
                    currentUserProfileImage={currentUserProfileImage}
                    handleNavigate={handleNavigate} // Pass the handleNavigate function
                  />
                  <div className={classes.commentsList}>{renderComments()}</div>
                </div>
              )}
            </div>
          ) : hasAttemptedLoad ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No post found</div>
              <div className={classes.text}>
                Please check your internet connection.
              </div>
              <div
                className={classes.homeButton}
                onClick={() => window.location.reload()}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                  />
                </svg>
                Refresh
              </div>
            </div>
          ) : null}
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
      {showReactionsModal && activePostReactions && (
        <ReactionsModal
          onClose={() => {
            setShowReactionsModal(false);
            setIsReactionsModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, reactionsModalScrollPosition);
            }, 0);
          }}
          postId={activePostReactions.postId}
          reactions={activePostReactions.reactions || {}}
          currentUserId={currentUser?.uid}
        />
      )}
      {showRepostsModal && activePostReposts && (
        <RepostsModal
          onClose={() => {
            setShowRepostsModal(false);
            setIsRepostsModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, repostsModalScrollPosition);
            }, 0);
          }}
          postId={activePostReposts.postId}
          reposts={activePostReposts.reposts || {}}
          currentUserId={currentUser?.uid}
        />
      )}
    </div>
  );
}

export default SinglePost;
