import React, { useState, useEffect, useRef } from "react";
import { doc, collection, addDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import classes from "./AddExperienceModal.module.css";
import CompanyDropdown from "../Dropdowns/CompanyDropdown";
import RoleDropdown from "../Dropdowns/RoleDropdown";
import MonthDropdown from "../Dropdowns/MonthDropdown";
import YearDropdown from "../Dropdowns/YearDropdown";
import monthsList from "../../utils/monthsList";
import { convertUrlsToLinks } from "../../utils/textUtils";

const AddExperienceModal = ({ onClose, currentUser, onExperienceAdded }) => {
  const [description, setDescription] = useState("");
  const [descriptionCharCount, setDescriptionCharCount] = useState(0);
  const [company, setCompany] = useState({ object: null, inputValue: "" });
  const [role, setRole] = useState({ object: null, inputValue: "" });
  const [startMonth, setStartMonth] = useState(null);
  const [startYear, setStartYear] = useState("");
  const [endMonth, setEndMonth] = useState(null);
  const [endYear, setEndYear] = useState("");
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const formRef = useRef(null);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [companyCharCount, setCompanyCharCount] = useState(0);
  const [roleCharCount, setRoleCharCount] = useState(0);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const isAllFieldsFilled =
      company.inputValue.trim() !== "" &&
      role.inputValue.trim() !== "" &&
      startMonth !== null &&
      startYear !== "" &&
      (isCurrentlyWorking || (endMonth !== null && endYear !== ""));

    setIsFormValid(isAllFieldsFilled);
    setIsChanged(isAllFieldsFilled);
  }, [
    company.inputValue,
    role.inputValue,
    startMonth,
    startYear,
    endMonth,
    endYear,
    isCurrentlyWorking,
  ]);

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 1000) {
      setDescription(inputValue);
      setDescriptionCharCount(inputValue.length);
      setIsChanged(true);
    }
  };

  const handleCloseTouch = (e, isSave = false) => {
    e.preventDefault();
    onClose(isSave);
  };

  const handleCompanyChange = (newCompany) => {
    setCompany({
      object: newCompany,
      inputValue: newCompany ? newCompany.name : "",
    });
  };

  const handleRoleChange = (newRole) => {
    setRole({
      object: newRole,
      inputValue: newRole ? newRole.name : "",
    });
  };

  const handleStartMonthChange = (newMonth) => {
    setStartMonth(newMonth);
    setIsChanged(true);
    if (parseInt(startYear) === currentYear && newMonth > currentMonth) {
      setStartMonth(null);
    }
    if (parseInt(startYear) === parseInt(endYear) && endMonth < newMonth) {
      setEndMonth(null);
    }
  };

  const handleStartYearChange = (newYear) => {
    setStartYear(newYear);
    if (endYear && parseInt(endYear) < parseInt(newYear)) {
      setEndYear("");
      setEndMonth(null);
    } else if (
      parseInt(endYear) === parseInt(newYear) &&
      endMonth < startMonth
    ) {
      setEndMonth(null);
    }
    if (parseInt(newYear) === currentYear && startMonth > currentMonth) {
      setStartMonth(null);
    }
    // Reset end month if start year is current year and end month is beyond current month
    if (parseInt(newYear) === currentYear && endMonth > currentMonth) {
      setEndMonth(null);
    }
  };

  const handleCurrentYearSelected = () => {
    if (startMonth > currentMonth) {
      setStartMonth(null);
    }
  };

  const handleEndMonthChange = (newMonth) => {
    if (
      (parseInt(endYear) === currentYear ||
        parseInt(startYear) === currentYear) &&
      newMonth > currentMonth
    ) {
      setEndMonth(null);
    } else if (
      parseInt(endYear) === parseInt(startYear) &&
      newMonth < startMonth
    ) {
      setEndMonth(null);
    } else {
      setEndMonth(newMonth);
    }
    setIsChanged(true);
  };

  const handleEndYearChange = (newYear) => {
    setEndYear(newYear);
    if (parseInt(newYear) === parseInt(startYear) && endMonth < startMonth) {
      setEndMonth(null);
    } else if (parseInt(newYear) === currentYear && endMonth > currentMonth) {
      setEndMonth(null);
    }
    setIsChanged(true);
  };

  const handleEndCurrentYearSelected = () => {
    if (endMonth > currentMonth) {
      setEndMonth(null);
    }
  };

  const handleCurrentlyWorkingChange = (e) => {
    setIsCurrentlyWorking(e.target.checked);
    if (e.target.checked) {
      setEndMonth(null);
      setEndYear("");
    }
    setIsChanged(true);
  };

  const handleCompanyInputChange = (inputValue) => {
    if (inputValue.length <= 50) {
      setCompany((prev) => ({ ...prev, inputValue }));
      setCompanyCharCount(inputValue.length);
    }
  };

  const handleRoleInputChange = (inputValue) => {
    if (inputValue.length <= 50) {
      setRole((prev) => ({ ...prev, inputValue }));
      setRoleCharCount(inputValue.length);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setError("Please fill in all required fields.");
      return;
    }
    setLoading(true);
    setError("");

    try {
      const userDocRef = doc(firestore, "users", currentUser.uid);
      const experiencesCollectionRef = collection(userDocRef, "experiences");

      // Create descriptionHtml using convertUrlsToLinks if description exists
      const trimmedDescription = description.trim();
      const newExperience = {
        organisation: company.inputValue.trim(),
        role: role.inputValue.trim(),
        description: trimmedDescription || null,
        descriptionHtml: trimmedDescription
          ? convertUrlsToLinks(trimmedDescription)
          : null,
        startMonth: monthsList[startMonth - 1],
        startYear: Number(startYear),
        endMonth: isCurrentlyWorking ? null : monthsList[endMonth - 1],
        endYear: isCurrentlyWorking ? null : Number(endYear),
        isCurrentlyWorking,
      };

      const docRef = await addDoc(experiencesCollectionRef, newExperience);

      onExperienceAdded({ id: docRef.id, ...newExperience });
    } catch (error) {
      setError("Failed to add experience. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true })
      );
      // Only close the modal if there are no errors and the form is valid
      if (!error && isFormValid) {
        onClose(true);
      }
    }
  };

  const handleInputChange = () => {
    setIsChanged(true);
  };

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose(false); // Pass false for regular close
    }
    setMouseDownOnBackdrop(false);
  };

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      // Don't scroll here, let Profile.js handle it
    };
  }, []);

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Experience
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
        <form
          className={classes.interactions}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <label className={classes.label}>Organization*</label>
          <CompanyDropdown
            selectedCompany={company.object}
            onCompanyChange={handleCompanyChange}
            onInputChange={handleCompanyInputChange}
            inputValue={company.inputValue}
            disabled={loading}
            placeholder="Ex: Monzo"
            maxLength={50}
          />
          {/* <div className={classes.charCount}>{companyCharCount}/50</div> */}
          <div className={classes.border}></div>

          <label className={classes.roleLabel}>Role*</label>
          <RoleDropdown
            selectedRole={role.object}
            onRoleChange={handleRoleChange}
            onInputChange={handleRoleInputChange}
            inputValue={role.inputValue}
            disabled={loading}
            placeholder="Ex: Junior Developer"
            maxLength={50}
          />
          {/* <div className={classes.charCount}>{roleCharCount}/50</div> */}
          <div className={classes.border}></div>

          <div className={classes.checkboxContainer}>
            <input
              type="checkbox"
              id="currentlyWorking"
              className={classes.customCheckbox}
              checked={isCurrentlyWorking}
              onChange={handleCurrentlyWorkingChange}
              disabled={loading}
            />
            <label htmlFor="currentlyWorking">I'm currently working here</label>
          </div>
          <div className={classes.border}></div>

          {/* New Description input */}
          {/* <label className={classes.label}>Description</label>
          <textarea
            className={classes.textArea}
            value={description}
            onChange={handleDescriptionChange}
            disabled={loading}
            placeholder="Share your achievements, responsibilities, and the impact you made in this role."
            maxLength={500}
          /> */}
          {/* <div className={classes.charCount}>{descriptionCharCount}/500</div> */}
          {/* <div className={classes.border}></div> */}

          <label className={classes.label}>Start date*</label>
          <div className={classes.date}>
            <MonthDropdown
              selectedMonth={startMonth}
              onMonthChange={handleStartMonthChange}
              onInputChange={handleInputChange}
              disabled={loading}
              placeholder="Month"
              maxMonth={parseInt(startYear) === currentYear ? currentMonth : 12}
            />
            <YearDropdown
              selectedYear={startYear}
              onYearChange={handleStartYearChange}
              onInputChange={handleInputChange}
              disabled={loading}
              placeholder="Year"
              onCurrentYearSelected={handleCurrentYearSelected}
            />
          </div>

          {!isCurrentlyWorking && (
            <>
              <div className={classes.dateBorder}></div>

              <label className={classes.label}>End date*</label>
              <div className={classes.date}>
                <MonthDropdown
                  selectedMonth={endMonth}
                  onMonthChange={handleEndMonthChange}
                  onInputChange={handleInputChange}
                  disabled={loading}
                  placeholder="Month"
                  maxMonth={
                    parseInt(endYear) === currentYear ||
                    parseInt(startYear) === currentYear
                      ? currentMonth
                      : 12
                  }
                  minMonth={
                    parseInt(endYear) === parseInt(startYear) ? startMonth : 1
                  }
                  isEndDate={true}
                  startYear={startYear}
                  endYear={endYear}
                  currentYear={currentYear}
                  currentMonth={currentMonth}
                />
                <YearDropdown
                  selectedYear={endYear}
                  onYearChange={handleEndYearChange}
                  disabled={loading}
                  minYear={startYear ? parseInt(startYear) : undefined}
                  onCurrentYearSelected={handleEndCurrentYearSelected}
                  placeholder="Year"
                />
              </div>
            </>
          )}
        </form>

        {error && <p className={classes.error}>{error}</p>}

        <div className={classes.cardFooter}>
          <div className={classes.smallPrint}>
            <svg
              class="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                clip-rule="evenodd"
              />
            </svg>
            *Required information
          </div>
          <button
            type="button"
            className={`${classes.button} ${loading ? classes.loading : ""}`}
            disabled={!isFormValid || loading}
            onClick={() => {
              handleSaveButtonClick();
              // Only close the modal if there are no errors and the form is valid
              if (!error && isFormValid) {
                onClose(true);
              }
            }}
          >
            {loading ? (
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddExperienceModal;
