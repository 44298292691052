import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { firestore } from "../../firebase";
import defaultImage from "../../assets/icons/profileImage.jpg";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  writeBatch,
  increment,
} from "firebase/firestore";
import classes from "./EditAttendeesModal.module.css"; // Reuse same CSS

const EditAttendeesModal = ({
  onClose,
  eventData,
  currentUser,
  onAttendeeRemoved,
}) => {
  const [loading, setLoading] = useState(true);
  const [attendees, setAttendees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [removingUsers, setRemovingUsers] = useState(false);
  const [totalAttendees, setTotalAttendees] = useState(0);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const checkConnectionType = async (currentUserId, targetUserId) => {
    if (!currentUserId || !targetUserId) return "Extended";
    if (currentUserId === targetUserId) return "You";

    const userConnectionRef = doc(
      firestore,
      `users/${currentUserId}/connections/${targetUserId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    const userConnectionsRef = collection(
      firestore,
      `users/${currentUserId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${targetUserId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      window.scrollTo(0, scrollY);
    };
  }, []);

  // Add CSS class to prevent body scrolling
  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const fetchAttendees = async () => {
      try {
        setLoading(true);
        const attendeesRef = collection(
          firestore,
          `users/${eventData.ownerId}/events/${eventData.id}/attendees`
        );
        const querySnapshot = await getDocs(attendeesRef);

        const attendeesData = await Promise.all(
          querySnapshot.docs.map(async (attendeeDoc) => {
            const userDoc = await getDoc(
              doc(firestore, "users", attendeeDoc.id)
            );

            if (userDoc.exists()) {
              const userData = userDoc.data();
              const connectionType = await checkConnectionType(
                currentUser.uid,
                attendeeDoc.id
              );

              return {
                id: attendeeDoc.id,
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || "",
                bio: userData.bio || "",
                link: userData.link || "",
                linkText: userData.linkText || "",
                urlSlug: userData.urlSlug || "",
                connectionType,
                attendedAt:
                  attendeeDoc.data().attendedAt?.toDate() || new Date(0),
              };
            }
            return null;
          })
        );

        const filteredAttendees = attendeesData.filter(Boolean);
        setAttendees(filteredAttendees);
        setTotalAttendees(filteredAttendees.length);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchAttendees();
  }, [eventData.id, eventData.ownerId]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredAttendees = useMemo(() => {
    return attendees.filter((attendee) => {
      const fullName =
        `${attendee.firstName} ${attendee.lastName}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    });
  }, [attendees, searchTerm]);

  const handleToggleUser = (userId) => {
    setSelectedUsers((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(userId)) {
        newSet.delete(userId);
      } else {
        newSet.add(userId);
      }
      return newSet;
    });
  };

  const handleRemoveAttendees = async () => {
    setRemovingUsers(true);
    try {
      const batch = writeBatch(firestore);

      for (const userId of selectedUsers) {
        // Remove attendee document
        const attendeeRef = doc(
          firestore,
          `users/${eventData.ownerId}/events/${eventData.id}/attendees/${userId}`
        );
        batch.delete(attendeeRef);
      }

      await batch.commit();

      // Update local state
      const updatedAttendees = attendees.filter(
        (attendee) => !selectedUsers.has(attendee.id)
      );
      setAttendees(updatedAttendees);
      setTotalAttendees((prev) => prev - selectedUsers.size);
      onAttendeeRemoved(selectedUsers.size);

      // Close modal if no attendees left, otherwise just clear selection
      if (updatedAttendees.length === 0) {
        onClose("success");
      } else {
        setSelectedUsers(new Set());
      }
    } catch (error) {
    } finally {
      setRemovingUsers(false);
    }
  };

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Attendees ({attendees.length})
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>

        <form className={classes.interactions}>
          <div className={classes.connectionsList}>
            {loading ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              </div>
            ) : filteredAttendees.length > 0 ? (
              filteredAttendees.map((attendee) => (
                <div key={attendee.id} className={classes.connectionItem}>
                  <img
                    src={attendee.profileImage || defaultImage}
                    alt={`${attendee.firstName} ${attendee.lastName}`}
                    className={classes.profileImage}
                  />
                  <div className={classes.connectionInfo}>
                    <h3 className={classes.name}>
                      <div className={classes.nameWrapper}>
                        <span className={classes.nameText}>
                          {attendee.firstName} {attendee.lastName}
                        </span>
                        <span className={classes.connectionType}>
                          • {attendee.connectionType}
                        </span>
                      </div>
                    </h3>
                    <p
                      className={`${classes.bio} ${
                        !attendee.bio && classes.noBio
                      }`}
                    >
                      {attendee.bio || attendee.username}
                    </p>
                    {attendee.link && (
                      <div className={classes.userLinkContainer}>
                        <a
                          href={attendee.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.userLink}
                        >
                          <span className={classes.userLinkText}>
                            {attendee.linkText || attendee.link}
                          </span>
                          <svg
                            className={classes.userLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                  </div>
                  <div className={classes.checkbox}>
                    <input
                      type="checkbox"
                      checked={selectedUsers.has(attendee.id)}
                      onChange={() => handleToggleUser(attendee.id)}
                    />
                    <div>
                      <svg
                        className={classes.checkmark}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4"
                          d="M5 11.917 9.724 16.5 19 7.5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.noConnections}></div>
            )}
          </div>
        </form>

        <div className={classes.cardFooter}>
          <button
            className={classes.button}
            disabled={selectedUsers.size === 0 || removingUsers}
            onClick={handleRemoveAttendees}
          >
            {removingUsers ? (
              <span
                className={`material-symbols-outlined ${classes.buttonIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Remove"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAttendeesModal;
