import React, { useState, useRef, useEffect } from "react";
import classes from "./DayDropdown.module.css";

const getDaysInMonth = (month, year) => {
  if (!month || !year) return 31;
  return new Date(year, month, 0).getDate();
};

const daysList = Array.from({ length: 31 }, (_, i) =>
  (i + 1).toString().padStart(2, "0")
);

const DayDropdown = ({
  label,
  selectedDay,
  onDayChange,
  disabled,
  selectedMonth,
  selectedYear,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [inputValue, setInputValue] = useState("");
  const dropdownRef = useRef(null);
  const listRef = useRef(null);

  const maxDays = getDaysInMonth(selectedMonth, selectedYear);
  const filteredDays = daysList.slice(0, maxDays);

  useEffect(() => {
    setInputValue(selectedDay ? selectedDay.toString().padStart(2, "0") : "");
  }, [selectedDay]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
        setHighlightedIndex(-1);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (isOpen && listRef.current && highlightedIndex >= 0) {
      const highlightedElement = listRef.current.children[highlightedIndex];
      if (highlightedElement) {
        highlightedElement.scrollIntoView({
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [isOpen, highlightedIndex]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setIsOpen(true);
  };

  const handleSelect = (day) => {
    onDayChange(parseInt(day));
    setInputValue(day);
    setIsOpen(false);
    setHighlightedIndex(-1);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) {
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        setIsOpen(true);
      }
      return;
    }

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          Math.min(prevIndex + 1, filteredOptions.length - 1)
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === -1
            ? filteredOptions.length - 1
            : Math.max(prevIndex - 1, 0)
        );
        break;
      case "Enter":
        e.preventDefault();
        if (highlightedIndex >= 0) {
          handleSelect(filteredOptions[highlightedIndex]);
        }
        break;
      case "Escape":
        setIsOpen(false);
        setHighlightedIndex(-1);
        break;
      default:
        break;
    }
  };

  const filteredOptions = filteredDays.filter((day) =>
    day.toString().startsWith(inputValue)
  );

  return (
    <div className={classes.customDropdown} ref={dropdownRef}>
      <label className={`${classes.label} ${disabled ? classes.disabled : ""}`}>
        {label}
      </label>
      <div className={classes.dropdownContainer}>
        <div className={classes.inputWrapper}>
          <input
            type="text"
            className={`${classes.dropdownHeader} ${
              disabled ? classes.disabled : ""
            } ${isOpen ? classes.active : ""}`}
            placeholder={placeholder}
            value={inputValue}
            onChange={handleInputChange}
            onClick={() => setIsOpen(!isOpen)}
            onKeyDown={handleKeyDown}
            disabled={disabled}
          />
          <svg
            className={`${classes.dropdownIcon} ${
              disabled ? classes.disabled : ""
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m8 15 4 4 4-4m0-6-4-4-4 4"
            />
          </svg>
        </div>

        {isOpen && !disabled && (
          <div className={classes.dropdownListContainer}>
            <ul className={classes.dropdownList} ref={listRef}>
              {filteredOptions.map((day, index) => (
                <li
                  key={day}
                  className={`${classes.dropdownListItem} ${
                    index === highlightedIndex ? classes.highlightedItem : ""
                  }`}
                  onClick={() => handleSelect(day)}
                  onMouseEnter={() => setHighlightedIndex(index)}
                >
                  {day}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default DayDropdown;
