import React, { useState, useEffect } from "react";
import { firestore } from "../../firebase";
import defaultImage from "../../assets/icons/profileImage.jpg";
import {
  collection,
  query,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  writeBatch,
  arrayUnion,
  serverTimestamp,
  orderBy,
  where,
} from "firebase/firestore";
import classes from "./EditChatsModal.module.css";

const EditChatsModal = ({
  onClose,
  currentUser,
  chats: initialChats,
  onChatsRemoved,
}) => {
  const [loading, setLoading] = useState(true);
  const [chats, setChats] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedChats, setSelectedChats] = useState(new Set());
  const [removingChats, setRemovingChats] = useState(false);
  const [totalChats, setTotalChats] = useState(0);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    const scrollY = window.scrollY;
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";
    return () => {
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      window.scrollTo(0, scrollY);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const loadChats = async () => {
      if (!currentUser?.uid || !initialChats?.length) return;

      try {
        setLoading(true);
        const chatsData = await Promise.all(
          initialChats.map(async (chat) => {
            // Fetch latest messages to check visibility
            const messagesRef = collection(
              firestore,
              `chats/${chat.id}/messages`
            );
            const messagesSnapshot = await getDocs(
              query(messagesRef, orderBy("createdAt", "desc"))
            );

            const hasVisibleMessages = messagesSnapshot.docs.some((doc) => {
              const messageData = doc.data();
              return !messageData.hiddenFor?.includes(currentUser.uid);
            });

            if (!hasVisibleMessages) return null;

            return {
              id: chat.id,
              otherUser: chat.otherUser,
              lastMessage: chat.lastMessage,
              lastMessageTime: chat.lastMessageTime,
              unreadCount: chat.unreadCount || 0,
            };
          })
        );

        const validChats = chatsData.filter(Boolean);
        setChats(validChats);
        setTotalChats(validChats.length);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    loadChats();
  }, [currentUser?.uid, initialChats]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredChats = chats.filter((chat) => {
    const userName = chat.otherUser.name.toLowerCase();
    return userName.includes(searchTerm.toLowerCase());
  });

  const handleToggleChat = (chatId) => {
    setSelectedChats((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(chatId)) {
        newSet.delete(chatId);
      } else {
        newSet.add(chatId);
      }
      return newSet;
    });
  };

  const handleRemoveChats = async () => {
    setRemovingChats(true);
    try {
      const batch = writeBatch(firestore);

      for (const chatId of selectedChats) {
        const chatRef = doc(firestore, `chats/${chatId}`);
        const chatDoc = await getDoc(chatRef);

        if (!chatDoc.exists()) continue;

        const chatData = chatDoc.data();
        const deletedBy = chatData.deletedBy || [];

        if (
          deletedBy.includes(
            chatData.participants.find((id) => id !== currentUser.uid)
          )
        ) {
          // If other user has already deleted the chat, delete everything
          const messagesRef = collection(chatRef, "messages");
          const messagesSnapshot = await getDocs(messagesRef);
          messagesSnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });
          batch.delete(chatRef);
        } else {
          // Mark as deleted for current user only
          batch.update(chatRef, {
            deletedBy: arrayUnion(currentUser.uid),
            [`deletedAt.${currentUser.uid}`]: serverTimestamp(),
          });
        }
      }

      await batch.commit();

      // Update local state
      const remainingChats = chats.filter(
        (chat) => !selectedChats.has(chat.id)
      );
      setChats(remainingChats);
      setTotalChats(remainingChats.length);

      // Notify parent component
      onChatsRemoved(remainingChats, selectedChats.size);
      setSelectedChats(new Set());

      if (remainingChats.length === 0) {
        onClose("success");
      }
    } catch (error) {
    } finally {
      setRemovingChats(false);
    }
  };

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Chats ({chats.length})
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={(e) => {
                e.stopPropagation();
                onClose(false);
              }}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>

        <form className={classes.interactions}>
          <div className={classes.connectionsList}>
            {loading ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              </div>
            ) : filteredChats.length > 0 ? (
              filteredChats.map((chat) => (
                <div key={chat.id} className={classes.connectionItem}>
                  <img
                    src={chat.otherUser.image || defaultImage}
                    alt={chat.otherUser.name}
                    className={classes.profileImage}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultImage;
                    }}
                  />
                  <div className={classes.connectionInfo}>
                    <h3 className={classes.name}>
                      <div className={classes.nameWrapper}>
                        <span className={classes.nameText}>
                          {chat.otherUser.name}
                        </span>
                        <span className={classes.connectionType}>
                          • {chat.otherUser.connectionType}
                        </span>
                      </div>
                    </h3>
                    {chat.otherUser.bio && (
                      <p className={classes.bio}>{chat.otherUser.bio}</p>
                    )}
                    {chat.otherUser.link && (
                      <div className={classes.userLinkContainer}>
                        <a
                          href={chat.otherUser.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.userLink}
                        >
                          <span className={classes.userLinkText}>
                            {chat.otherUser.linkText || chat.otherUser.link}
                          </span>
                          <svg
                            className={classes.userLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                  </div>
                  <div className={classes.checkbox}>
                    <input
                      type="checkbox"
                      checked={selectedChats.has(chat.id)}
                      onChange={() => handleToggleChat(chat.id)}
                    />
                    <div>
                      <svg
                        className={classes.checkmark}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="4"
                          d="M5 11.917 9.724 16.5 19 7.5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={classes.noConnections}></div>
            )}
          </div>
        </form>

        <div className={classes.cardFooter}>
          <button
            className={classes.button}
            disabled={selectedChats.size === 0 || removingChats}
            onClick={handleRemoveChats}
          >
            {removingChats ? (
              <span
                className={`material-symbols-outlined ${classes.buttonIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Remove"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditChatsModal;
