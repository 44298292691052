import React, { useEffect, useState, useCallback } from "react";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./Notifications.module.css";
import { firestore, auth } from "../../../firebase";
import defaultEventImage from "../../../assets/icons/eventImage.jpg";
import defaultCommunityImage from "../../../assets/icons/communityImage.jpg";

import defaultUserImage from "../../../assets/icons/profileImage.jpg";
import defaultStartupImage from "../../../assets/images/startupImage.jpg";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
  setDoc,
  writeBatch,
  getDocs,
  increment,
  addDoc,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import profileImage from "../../../assets/icons/profileImage.jpg";
import NotificationDropdown from "./NotificationDropdown";
import { useNavigate, Link } from "react-router-dom";
import { useNotification } from "../../../contexts/NotificationContext";

function Notifications() {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    profileImage: "",
  });
  const [startupData, setStartupData] = useState({});
  const [postData, setPostData] = useState({}); // Add this near other state declarations
  const { state: notificationState, dispatch } = useNotification();
  const [localNewStatus, setLocalNewStatus] = useState({});
  const [deletedNotifications, setDeletedNotifications] = useState(new Set());
  const [currentUser, setCurrentUser] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);

  const [localNotifications, setLocalNotifications] = useState([]);
  const [displayedCount, setDisplayedCount] = useState(18);
  const [communityData, setCommunityData] = useState({});

  const handleShowMore = () => {
    if (displayedCount >= localNotifications.length) {
      // Reset to initial count of 18
      setDisplayedCount(18);
    } else {
      // Add 18 more notifications to display
      setDisplayedCount((prev) => prev + 18);
    }
  };

  const fetchStartupData = async (startupOwnerId, startupId) => {
    try {
      // First try to find the startup in the owner's collection
      let startupDoc = await getDoc(
        doc(firestore, `users/${startupOwnerId}/startups`, startupId)
      );

      // If not found in owner's collection, search in all users' collections
      if (!startupDoc.exists()) {
        const usersRef = collection(firestore, "users");
        const querySnapshot = await getDocs(usersRef);

        for (const userDoc of querySnapshot.docs) {
          const potentialStartupDoc = await getDoc(
            doc(firestore, `users/${userDoc.id}/startups`, startupId)
          );
          if (potentialStartupDoc.exists()) {
            startupDoc = potentialStartupDoc;
            break;
          }
        }
      }

      if (startupDoc.exists()) {
        const data = startupDoc.data();
        return {
          name: data.startupName,
          image: data.startupImage || defaultStartupImage,
          urlSlug: data.startupUrlSlug,
        };
      }

      return {
        name: "Unknown",
        image: defaultStartupImage,
        urlSlug: startupId,
      };
    } catch (error) {
      return {
        name: "Unknown",
        image: defaultStartupImage,
        urlSlug: startupId,
      };
    }
  };

  const handleStartupFollowInvite = async (notificationId, action) => {
    try {
      const batch = writeBatch(firestore);

      const notificationRef = doc(
        firestore,
        `users/${currentUser.uid}/notifications/${notificationId}`
      );
      const notificationDoc = await getDoc(notificationRef);
      const notificationData = notificationDoc.data();

      if (!notificationDoc.exists()) {
        throw new Error("Notification not found");
      }

      // Get current user data for the owner's notification
      const currentUserDoc = await getDoc(
        doc(firestore, "users", currentUser.uid)
      );
      const currentUserData = currentUserDoc.data();
      const userFullName = `${currentUserData.firstName} ${currentUserData.lastName}`;

      if (action === "accept") {
        // Add user as follower
        const followerRef = doc(
          firestore,
          `users/${notificationData.startupOwnerId}/startups/${notificationData.startupId}/followers/${currentUser.uid}`
        );
        batch.set(followerRef, {
          followedAt: new Date(),
        });

        // Add startup to user's following list
        const followingRef = doc(
          firestore,
          `users/${currentUser.uid}/startupsFollowing/${notificationData.startupId}`
        );
        batch.set(followingRef, {
          startupId: notificationData.startupId,
          startupOwnerId: notificationData.startupOwnerId,
          startupName: notificationData.startupName,
          startupUrlSlug: notificationData.startupUrlSlug,
          followedAt: new Date(),
        });

        // Create notification for startup owner
        const ownerNotificationRef = doc(
          collection(
            firestore,
            `users/${notificationData.startupOwnerId}/notifications`
          )
        );
        batch.set(ownerNotificationRef, {
          type: "startup_follow_invite_accepted",
          from: currentUser.uid,
          fromUserName: userFullName,
          fromUserImage: currentUserData.profileImage || defaultUserImage,
          startupId: notificationData.startupId,
          startupName: notificationData.startupName,
          startupUrlSlug: notificationData.startupUrlSlug,
          startupImage: notificationData.startupImage,
          startupOwnerId: notificationData.startupOwnerId,
          message: `${userFullName} is now a follower.`,
          createdAt: new Date(),
          isNew: true,
        });
      }

      // Update the original notification with proper response type and message
      batch.update(notificationRef, {
        type: "startup_follow_invite_response",
        startupImage: notificationData.startupImage || defaultStartupImage,
        message:
          action === "accept"
            ? `${notificationData.startupName}${
                notificationData.startupName.endsWith("s") ? "'" : "'s"
              } invite accepted.`
            : `${notificationData.startupName}${
                notificationData.startupName.endsWith("s") ? "'" : "'s"
              } invite declined.`,
        updatedAt: new Date(),
      });

      await batch.commit();

      // Update local notifications state
      setLocalNotifications((prev) =>
        prev
          .map((notif) =>
            notif.id === notificationId
              ? {
                  ...notif,
                  type: "startup_follow_invite_response",
                  message:
                    action === "accept"
                      ? `${notificationData.startupName}${
                          notificationData.startupName.endsWith("s")
                            ? "'"
                            : "'s"
                        } invite accepted.`
                      : `${notificationData.startupName}${
                          notificationData.startupName.endsWith("s")
                            ? "'"
                            : "'s"
                        } invite declined.`,
                  updatedAt: new Date(),
                }
              : notif
          )
          .sort(
            (a, b) =>
              (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
          )
      );
    } catch (error) {}
  };

  const handleMemberInvite = async (notificationId, action) => {
    try {
      const batch = writeBatch(firestore);

      const notificationRef = doc(
        firestore,
        `users/${currentUser.uid}/notifications/${notificationId}`
      );
      const notificationDoc = await getDoc(notificationRef);
      const notificationData = notificationDoc.data();

      if (!notificationDoc.exists()) {
        throw new Error("Notification not found");
      }

      const currentUserDoc = await getDoc(
        doc(firestore, "users", currentUser.uid)
      );
      const currentUserData = currentUserDoc.data();
      const userFullName = `${currentUserData.firstName} ${currentUserData.lastName}`;

      const memberRef = doc(
        firestore,
        `users/${notificationData.communityOwnerId}/communities/${notificationData.communityId}/members/${currentUser.uid}`
      );

      if (action === "accept") {
        const ownerNotificationsRef = collection(
          firestore,
          `users/${notificationData.communityOwnerId}/notifications`
        );
        const joinRequestQuery = query(
          ownerNotificationsRef,
          where("type", "==", "join_request"),
          where("from", "==", currentUser.uid),
          where("communityId", "==", notificationData.communityId)
        );
        const joinRequestSnapshot = await getDocs(joinRequestQuery);
        joinRequestSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        batch.set(memberRef, {
          status: "Member",
          joinedAt: new Date(),
        });

        const userCommunityRef = doc(
          firestore,
          `users/${currentUser.uid}/communitiesMemberOf/${notificationData.communityId}`
        );

        batch.set(userCommunityRef, {
          communityId: notificationData.communityId,
          communityName: notificationData.communityName,
          communityImage: notificationData.communityImage,
          communityUrlSlug: notificationData.communityUrlSlug,
          ownerId: notificationData.communityOwnerId,
          joinedAt: new Date(),
          status: "Member",
        });

        const ownerNotificationRef = doc(
          collection(
            firestore,
            `users/${notificationData.communityOwnerId}/notifications`
          )
        );

        batch.set(ownerNotificationRef, {
          type: "member_invite_accepted",
          from: currentUser.uid,
          communityId: notificationData.communityId,
          communityName: notificationData.communityName,
          communityUrlSlug: notificationData.communityUrlSlug,
          communityImage: notificationData.communityImage,
          fromUserName: userFullName,
          createdAt: new Date(),
          isNew: true,
        });

        batch.update(notificationRef, {
          type: "member_invite_response",
          message: `${notificationData.communityName}${
            notificationData.communityName.endsWith("s") ? "'" : "'s"
          } invite accepted.`,
          updatedAt: new Date(),
        });
      } else {
        batch.delete(memberRef);

        batch.update(notificationRef, {
          type: "member_invite_response",
          message: `${notificationData.communityName}${
            notificationData.communityName.endsWith("s") ? "'" : "'s"
          } invite declined.`,
          updatedAt: new Date(),
        });
      }

      await batch.commit();

      setNotifications((prev) =>
        prev
          .map((notif) =>
            notif.id === notificationId
              ? {
                  ...notif,
                  type: "member_invite_response",
                  message:
                    action === "accept"
                      ? `${notificationData.communityName}${
                          notificationData.communityName.endsWith("s")
                            ? "'"
                            : "'s"
                        } invite accepted.`
                      : `${notificationData.communityName}${
                          notificationData.communityName.endsWith("s")
                            ? "'"
                            : "'s"
                        } invite declined.`,
                  updatedAt: new Date(),
                }
              : notif
          )
          .sort(
            (a, b) =>
              (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
          )
      );
    } catch (error) {}
  };

  const handleModeratorInvite = async (notificationId, action) => {
    try {
      const batch = writeBatch(firestore);

      const notificationRef = doc(
        firestore,
        `users/${currentUser.uid}/notifications/${notificationId}`
      );
      const notificationDoc = await getDoc(notificationRef);
      const notificationData = notificationDoc.data();

      if (!notificationDoc.exists()) {
        throw new Error("Notification not found");
      }

      const currentUserDoc = await getDoc(
        doc(firestore, "users", currentUser.uid)
      );
      const currentUserData = currentUserDoc.data();
      const userFullName = `${currentUserData.firstName} ${currentUserData.lastName}`;

      const moderatorRef = doc(
        firestore,
        `users/${notificationData.communityOwnerId}/communities/${notificationData.communityId}/moderators/${currentUser.uid}`
      );

      if (action === "accept") {
        const memberRef = doc(
          firestore,
          `users/${notificationData.communityOwnerId}/communities/${notificationData.communityId}/members/${currentUser.uid}`
        );
        const memberDoc = await getDoc(memberRef);

        if (memberDoc.exists()) {
          batch.delete(memberRef);

          const userCommunityRef = doc(
            firestore,
            `users/${currentUser.uid}/communitiesMemberOf/${notificationData.communityId}`
          );
          batch.delete(userCommunityRef);
        }

        const ownerNotificationsRef = collection(
          firestore,
          `users/${notificationData.communityOwnerId}/notifications`
        );
        const joinRequestQuery = query(
          ownerNotificationsRef,
          where("type", "==", "join_request"),
          where("from", "==", currentUser.uid),
          where("communityId", "==", notificationData.communityId)
        );
        const joinRequestSnapshot = await getDocs(joinRequestQuery);
        joinRequestSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        const moderatorData = {
          status: "active",
          acceptedAt: new Date(),
        };

        batch.set(moderatorRef, moderatorData);

        const communityModeratingRef = doc(
          firestore,
          `users/${currentUser.uid}/communitiesModerating/${notificationData.communityId}`
        );

        batch.set(communityModeratingRef, {
          communityOwnerId: notificationData.communityOwnerId,
          status: "active",
          acceptedAt: new Date(),
        });

        const ownerNotificationRef = doc(
          collection(
            firestore,
            `users/${notificationData.communityOwnerId}/notifications`
          )
        );

        batch.set(ownerNotificationRef, {
          type: "moderator_invite_accepted",
          from: currentUser.uid,
          communityId: notificationData.communityId,
          communityName: notificationData.communityName,
          communityUrlSlug: notificationData.communityUrlSlug,
          communityImage: notificationData.communityImage,
          fromUserName: userFullName,
          isStartup: true,
          startupImage: notificationData.communityImage,
          createdAt: new Date(),
          isNew: true,
        });

        batch.update(notificationRef, {
          type: "moderator_invite_response",
          message: `${notificationData.communityName}${
            notificationData.communityName.endsWith("s") ? "'" : "'s"
          } invite accepted.`,
          updatedAt: new Date(),
        });
      } else {
        batch.delete(moderatorRef);

        batch.update(notificationRef, {
          type: "moderator_invite_response",
          message: `${notificationData.communityName}${
            notificationData.communityName.endsWith("s") ? "'" : "'s"
          } request declined.`,
          updatedAt: new Date(),
        });
      }

      await batch.commit();

      setNotifications((prev) =>
        prev
          .map((notif) =>
            notif.id === notificationId
              ? {
                  ...notif,
                  type: "moderator_invite_response",
                  message:
                    action === "accept"
                      ? `${notificationData.communityName}${
                          notificationData.communityName.endsWith("s")
                            ? "'"
                            : "'s"
                        } invite accepted.`
                      : `${notificationData.communityName}${
                          notificationData.communityName.endsWith("s")
                            ? "'"
                            : "'s"
                        } request declined.`,
                  updatedAt: new Date(),
                }
              : notif
          )
          .sort(
            (a, b) =>
              (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
          )
      );
    } catch (error) {}
  };

  const handleEventClick = async (eventOwnerId, eventId) => {
    try {
      // First try to find the event in the owner's collection
      let eventDoc = await getDoc(
        doc(firestore, `users/${eventOwnerId}/events`, eventId)
      );

      // If not found in owner's collection, search in all users' collections
      if (!eventDoc.exists()) {
        const usersRef = collection(firestore, "users");
        const querySnapshot = await getDocs(usersRef);

        for (const userDoc of querySnapshot.docs) {
          const potentialEventDoc = await getDoc(
            doc(firestore, `users/${userDoc.id}/events`, eventId)
          );
          if (potentialEventDoc.exists()) {
            eventDoc = potentialEventDoc;
            break;
          }
        }
      }

      if (eventDoc.exists()) {
        const eventData = eventDoc.data();
        if (eventData.eventUrlSlug) {
          navigate(`/event/${eventData.eventUrlSlug}`);
        } else {
          // If no URL slug exists, try using the event ID as fallback
          navigate(`/event/${eventId}`);
        }
      } else {
      }
    } catch (error) {}
  };

  const handleEventInvite = async (notificationId, action) => {
    try {
      const batch = writeBatch(firestore);

      const notificationRef = doc(
        firestore,
        `users/${currentUser.uid}/notifications/${notificationId}`
      );
      const notificationDoc = await getDoc(notificationRef);
      const notificationData = notificationDoc.data();

      if (!notificationDoc.exists()) {
        throw new Error("Notification not found");
      }

      // Get the current user's data for the notification
      const currentUserDoc = await getDoc(
        doc(firestore, "users", currentUser.uid)
      );
      const currentUserData = currentUserDoc.data();
      const userFullName = `${currentUserData.firstName} ${currentUserData.lastName}`;

      if (action === "accept") {
        // Add user as attendee
        const attendeeRef = doc(
          firestore,
          `users/${notificationData.eventOwnerId}/events/${notificationData.eventId}/attendees/${currentUser.uid}`
        );

        batch.set(attendeeRef, {
          attendedAt: new Date(),
        });

        // Update event's attendee count
        const eventRef = doc(
          firestore,
          `users/${notificationData.eventOwnerId}/events/${notificationData.eventId}`
        );
        batch.update(eventRef, {
          attendeeCount: increment(1),
        });

        // Update user's attending count
        const userRef = doc(firestore, "users", currentUser.uid);
        batch.update(userRef, {
          attendingCount: increment(1),
        });

        // Create notification for event owner with user data included
        const ownerNotificationRef = doc(
          collection(
            firestore,
            `users/${notificationData.eventOwnerId}/notifications`
          )
        );
        batch.set(ownerNotificationRef, {
          type: "event_invite_accepted",
          from: currentUser.uid,
          fromUserName: userFullName,
          eventId: notificationData.eventId,
          eventName: notificationData.eventName,
          eventUrlSlug: notificationData.eventUrlSlug,
          eventImage: notificationData.eventImage,
          createdAt: new Date(),
          isNew: true,
          isStartup: true,
          startupImage: notificationData.eventImage,
        });
      }

      // Update the current user's notification
      batch.update(notificationRef, {
        type: "event_invite_response",
        message:
          action === "accept"
            ? `${notificationData.eventName}${
                notificationData.eventName.endsWith("s") ? "'" : "'s"
              } invite accepted.`
            : `${notificationData.eventName}${
                notificationData.eventName.endsWith("s") ? "'" : "'s"
              } request declined.`,
        updatedAt: new Date(),
      });

      await batch.commit();

      // Update local state
      setNotifications((prev) =>
        prev
          .map((notif) =>
            notif.id === notificationId
              ? {
                  ...notif,
                  type: "event_invite_response",
                  message:
                    action === "accept"
                      ? `${notificationData.eventName}${
                          notificationData.eventName.endsWith("s") ? "'" : "'s"
                        } invite accepted.`
                      : `${notificationData.eventName}${
                          notificationData.eventName.endsWith("s") ? "'" : "'s"
                        } invite declined.`,
                  updatedAt: new Date(),
                }
              : notif
          )
          .sort(
            (a, b) =>
              (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
          )
      );
    } catch (error) {}
  };

  const handleStartupClick = async (startupOwnerId, startupId) => {
    try {
      // First try to find the startup in the owner's collection
      let startupDoc = await getDoc(
        doc(firestore, `users/${startupOwnerId}/startups`, startupId)
      );

      // If not found in owner's collection, search in all users' collections
      if (!startupDoc.exists()) {
        const usersRef = collection(firestore, "users");
        const querySnapshot = await getDocs(usersRef);

        for (const userDoc of querySnapshot.docs) {
          const potentialStartupDoc = await getDoc(
            doc(firestore, `users/${userDoc.id}/startups`, startupId)
          );
          if (potentialStartupDoc.exists()) {
            startupDoc = potentialStartupDoc;
            break;
          }
        }
      }

      if (startupDoc.exists()) {
        const startupData = startupDoc.data();
        if (startupData.startupUrlSlug) {
          navigate(`/startup/${startupData.startupUrlSlug}`);
        } else {
          // If no URL slug exists, try using the startup ID as fallback
          navigate(`/startup/${startupId}`);
        }
      } else {
      }
    } catch (error) {}
  };

  const { notification } = useNotification();

  const handleHomeClick = () => {
    navigate("/home");
  };

  // Replace the existing handlePostPreviewClick in Notifications.js with this:

  const handlePostPreviewClick = async (postId) => {
    try {
      const notification = localNotifications.find((n) => n.postId === postId);
      if (!notification) {
        throw new Error("Notification not found");
      }

      // Get the community data from the notification if it exists
      const communityId = notification.communityId;
      const communityOwnerId = notification.communityOwnerId;

      if (communityId && communityOwnerId) {
        // This is a community post
        try {
          // Get the community document to find its URL slug
          const communityRef = doc(
            firestore,
            `users/${communityOwnerId}/communities/${communityId}`
          );
          const communityDoc = await getDoc(communityRef);

          if (communityDoc.exists()) {
            const communityData = communityDoc.data();
            // Navigate to the community with the post ID in state
            navigate(`/community/${communityData.communityUrlSlug}`, {
              state: { highlightPostId: postId },
            });
            return;
          }
        } catch (error) {}
      }

      // If not a community post or community not found, try other locations...
      const postOwnerId =
        notification.originalPosterId || notification.from || currentUser.uid;

      // Check regular posts
      let postDoc = await getDoc(
        doc(firestore, `users/${postOwnerId}/posts`, postId)
      );

      if (!postDoc.exists()) {
        // If not found in regular posts, check community posts
        postDoc = await getDoc(
          doc(firestore, `users/${postOwnerId}/communityPosts`, postId)
        );
      }

      // If found in either location, navigate appropriately
      if (postDoc.exists()) {
        navigate(`/post/${postId}`);
      } else {
        navigate(`/post/${postId}`); // Fallback to regular post route
      }
    } catch (error) {
      navigate(`/post/${postId}`); // Fallback to regular post route
    }
  };

  // Currency symbol mapping with format examples for each currency
  const CURRENCY_SYMBOLS = {
    // Oceania
    AUD: { symbol: "A$", example: "A$100.00" }, // Australia
    NZD: { symbol: "NZ$", example: "NZ$100.00" }, // New Zealand

    // North America
    USD: { symbol: "$", example: "$100.00" }, // United States
    CAD: { symbol: "C$", example: "C$100.00" }, // Canada
    MXN: { symbol: "Mex$", example: "Mex$100.00" }, // Mexico

    // Europe
    GBP: { symbol: "£", example: "£100.00" }, // United Kingdom
    EUR: { symbol: "€", example: "€100.00" }, // Euro countries
    CHF: { symbol: "CHF", example: "CHF 100.00" }, // Switzerland
    NOK: { symbol: "kr", example: "kr 100.00" }, // Norway
    SEK: { symbol: "kr", example: "100,00 kr" }, // Sweden
    DKK: { symbol: "kr.", example: "100,00 kr." }, // Denmark
    PLN: { symbol: "zł", example: "100,00 zł" }, // Poland
    CZK: { symbol: "Kč", example: "100,00 Kč" }, // Czech Republic
    RON: { symbol: "lei", example: "100,00 lei" }, // Romania
    BGN: { symbol: "лв", example: "100,00 лв" }, // Bulgaria
    HRK: { symbol: "kn", example: "100,00 kn" }, // Croatia
    HUF: { symbol: "Ft", example: "100 Ft" }, // Hungary

    // Asia
    JPY: { symbol: "¥", example: "¥100" }, // Japan
    SGD: { symbol: "S$", example: "S$100.00" }, // Singapore
    HKD: { symbol: "HK$", example: "HK$100.00" }, // Hong Kong
    THB: { symbol: "฿", example: "฿100.00" }, // Thailand
    MYR: { symbol: "RM", example: "RM100.00" }, // Malaysia
    IDR: { symbol: "Rp", example: "Rp 100" }, // Indonesia
    INR: { symbol: "₹", example: "₹100.00" }, // India
    AED: { symbol: "د.إ", example: "100.00 د.إ" }, // UAE

    // Africa
    ZAR: { symbol: "R", example: "R100.00" }, // South Africa
    NGN: { symbol: "₦", example: "₦100.00" }, // Nigeria
    GHS: { symbol: "GH₵", example: "GH₵100.00" }, // Ghana
    KES: { symbol: "KSh", example: "KSh 100.00" }, // Kenya

    // South America
    BRL: { symbol: "R$", example: "R$100,00" }, // Brazil
  };

  // Function to get currency symbol
  const getCurrencySymbol = (currencyCode) => {
    if (!currencyCode) return "$"; // Default to USD symbol

    const upperCaseCode = currencyCode.toUpperCase();
    return CURRENCY_SYMBOLS[upperCaseCode]?.symbol || currencyCode;
  };

  // Function to format currency amount
  const formatCurrency = (amount, currencyCode = "USD") => {
    const upperCaseCode = currencyCode.toUpperCase();
    const symbol = CURRENCY_SYMBOLS[upperCaseCode]?.symbol || upperCaseCode;

    // Handle special cases for currencies with different decimal places
    if (upperCaseCode === "JPY") {
      return `${symbol}${Math.round(amount)}`; // JPY doesn't use decimal places
    }

    // Default formatting with 2 decimal places
    const formattedAmount = amount.toFixed(2);

    // Handle currencies with symbol after the amount
    const symbolAfter = [
      "kr",
      "kr.",
      "zł",
      "Kč",
      "lei",
      "лв",
      "kn",
      "Ft",
      "د.إ",
    ];
    if (symbolAfter.includes(symbol)) {
      return `${formattedAmount} ${symbol}`;
    }

    // Default format with symbol before amount
    return `${symbol}${formattedAmount}`;
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    let date;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (typeof timestamp.toDate === "function") {
      date = timestamp.toDate();
    } else {
      return "";
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) {
      return "now";
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes}m`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours}h`;
    } else if (diffInSeconds < 604800) {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days}d`;
    } else if (diffInSeconds < 2592000) {
      const weeks = Math.floor(diffInSeconds / 604800);
      return `${weeks}w`;
    } else if (diffInSeconds < 31536000) {
      const months = Math.floor(diffInSeconds / 2592000);
      return `${months}mo`;
    } else {
      const years = Math.floor(diffInSeconds / 31536000);
      return `${years}yr`;
    }
  };

  const handleUserClick = async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        navigate(`/${userData.urlSlug}`);
      } else {
      }
    } catch (error) {}
  };

  const fetchUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const fullName = `${userData.firstName} ${userData.lastName}`;
        return {
          name: fullName,
          profileImage: userData.profileImage || profileImage,
        };
      }
      return { name: "Unknown", profileImage: profileImage };
    } catch (error) {
      return { name: "Unknown", profileImage: profileImage };
    }
  };

  // New function to filter out duplicate connection requests
  const filterDuplicateRequests = (notifications) => {
    const connectionRequests = new Map();

    return notifications.filter((notification) => {
      if (notification.type === "connection_request") {
        if (
          !connectionRequests.has(notification.from) ||
          notification.createdAt >
            connectionRequests.get(notification.from).createdAt
        ) {
          connectionRequests.set(notification.from, notification);
          return true;
        }
        return false;
      }
      return true;
    });
  };

  const fetchPostData = async (postOwnerId, postId) => {
    try {
      // First check regular posts
      let postDoc = await getDoc(
        doc(firestore, `users/${postOwnerId}/posts`, postId)
      );

      // If not found in regular posts, check communityPosts collection
      if (!postDoc.exists()) {
        postDoc = await getDoc(
          doc(firestore, `users/${postOwnerId}/communityPosts`, postId)
        );
      }

      // If still not found, search through all users' communities
      if (!postDoc.exists()) {
        const usersRef = collection(firestore, "users");
        const querySnapshot = await getDocs(usersRef);

        for (const userDoc of querySnapshot.docs) {
          // Check user's communities
          const communitiesRef = collection(
            firestore,
            `users/${userDoc.id}/communities`
          );
          const communitiesSnapshot = await getDocs(communitiesRef);

          for (const communityDoc of communitiesSnapshot.docs) {
            // Check community posts
            const communityPostRef = doc(
              firestore,
              `users/${userDoc.id}/communities/${communityDoc.id}/posts/${postId}`
            );
            const communityPostDoc = await getDoc(communityPostRef);

            if (communityPostDoc.exists()) {
              postDoc = communityPostDoc;
              break;
            }
          }
          if (postDoc.exists()) break;
        }
      }

      // If still not found, check startups' posts and community posts
      if (!postDoc.exists()) {
        const usersRef = collection(firestore, "users");
        const querySnapshot = await getDocs(usersRef);

        for (const userDoc of querySnapshot.docs) {
          // Check startups
          const startupsRef = collection(
            firestore,
            `users/${userDoc.id}/startups`
          );
          const startupsSnapshot = await getDocs(startupsRef);

          for (const startupDoc of startupsSnapshot.docs) {
            // Check startup's posts
            const startupPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupDoc.id}/posts/${postId}`
            );
            const startupPostDoc = await getDoc(startupPostRef);

            if (startupPostDoc.exists()) {
              postDoc = startupPostDoc;
              break;
            }

            // Check startup's community posts
            const startupCommunityPostRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupDoc.id}/communityPosts/${postId}`
            );
            const startupCommunityPostDoc = await getDoc(
              startupCommunityPostRef
            );

            if (startupCommunityPostDoc.exists()) {
              postDoc = startupCommunityPostDoc;
              break;
            }
          }
          if (postDoc.exists()) break;
        }
      }

      if (postDoc.exists()) {
        const postData = postDoc.data();
        return {
          reactionCount: postData.reactions
            ? Object.keys(postData.reactions).length
            : postData.communityReactions
            ? Object.keys(postData.communityReactions).length
            : 0,
          commentCount: postData.commentCount || 0,
          postPreview: {
            mediaType: postData.content?.type || null,
            mediaUrl: postData.content?.url || null,
            text: postData.text || "",
            thumbnail: postData.content?.thumbnail || null,
            fileName: postData.content?.fileName || null,
          },
        };
      }

      return { reactionCount: 0, commentCount: 0, postPreview: {} };
    } catch (error) {
      return { reactionCount: 0, commentCount: 0, postPreview: {} };
    }
  };

  const handleCommunityClick = async (communityId) => {
    try {
      // First get all users since we don't know which user owns the community
      const usersRef = collection(firestore, "users");
      const querySnapshot = await getDocs(usersRef);

      for (const userDoc of querySnapshot.docs) {
        // Check each user's communities
        const communityRef = doc(
          firestore,
          `users/${userDoc.id}/communities/${communityId}`
        );
        const communityDoc = await getDoc(communityRef);

        if (communityDoc.exists()) {
          const communityData = communityDoc.data();
          if (communityData.communityUrlSlug) {
            navigate(`/community/${communityData.communityUrlSlug}`);
            return;
          }
        }
      }
    } catch (error) {}
  };

  const handleConnectionRequest = async (
    notificationId,
    fromUserId,
    action
  ) => {
    const currentUserRef = doc(firestore, `users/${currentUser.uid}`);
    const fromUserRef = doc(firestore, `users/${fromUserId}`);
    const currentUserConnectionRef = doc(
      firestore,
      `users/${currentUser.uid}/connections/${fromUserId}`
    );
    const fromUserConnectionRef = doc(
      firestore,
      `users/${fromUserId}/connections/${currentUser.uid}`
    );

    try {
      const fromUserData = await fetchUserData(fromUserId);
      const currentUserData = await fetchUserData(currentUser.uid);

      if (action === "accept") {
        await updateDoc(currentUserConnectionRef, {
          status: "Connected",
          connectedAt: new Date(),
        });
        await updateDoc(fromUserConnectionRef, {
          status: "Connected",
          connectedAt: new Date(),
        });
        // Update the current user's notification
        const notificationRef = doc(
          firestore,
          `users/${currentUser.uid}/notifications/${notificationId}`
        );
        const updatedNotification = {
          type: "connection_accepted",
          message: `${fromUserData.name} is now a connection.`,
          fromUserName: fromUserData.name,
          fromUserImage: fromUserData.profileImage,
          from: fromUserId, // Add this line
          updatedAt: new Date(),
        };
        await updateDoc(notificationRef, updatedNotification);

        // Create a new notification for the other user
        const newNotificationRef = doc(
          collection(firestore, `users/${fromUserId}/notifications`)
        );
        const newNotification = {
          type: "connection_accepted",
          message: `${currentUserData.name} is now a connection.`,
          fromUserName: currentUserData.name,
          fromUserImage: currentUserData.profileImage,
          from: currentUser.uid,
          createdAt: new Date(),
          isNew: true, // Add this line to mark the notification as new
        };
        await setDoc(newNotificationRef, newNotification);
      } else if (action === "ignore") {
        // Update the current user's notification
        const notificationRef = doc(
          firestore,
          `users/${currentUser.uid}/notifications/${notificationId}`
        );
        const updatedNotification = {
          type: "connection_declined",
          message: `You declined ${fromUserData.name}'s request.`,
          fromUserName: fromUserData.name,
          fromUserImage: fromUserData.profileImage,
          from: fromUserId, // Add this line
          updatedAt: new Date(),
        };
        await updateDoc(notificationRef, updatedNotification);

        // Remove the connection documents
        await deleteDoc(currentUserConnectionRef);
        await deleteDoc(fromUserConnectionRef);
      }

      setNotifications((prev) =>
        prev
          .map((notif) => {
            if (notif.id === notificationId) {
              return {
                ...notif,
                type:
                  action === "accept"
                    ? "connection_accepted"
                    : "connection_declined",
                message:
                  action === "accept"
                    ? `${fromUserData.name} is now a connection.`
                    : `You declined ${fromUserData.name}'s request.`,
                fromUserName: fromUserData.name,
                fromUserImage: fromUserData.profileImage,
                updatedAt: new Date(),
              };
            }
            return notif;
          })
          .sort(
            (a, b) =>
              (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
          )
      );
    } catch (error) {}
  };

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 648);
  }, []); // No dependencies needed as it only uses window

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    let unsubscribe = () => {};

    const fetchNotifications = async () => {
      if (!currentUser) {
        setIsLoading(false);
        setHasLoadedOnce(true);
        return;
      }

      setIsLoading(true);
      const notificationsRef = collection(
        firestore,
        `users/${currentUser.uid}/notifications`
      );

      try {
        unsubscribe = onSnapshot(notificationsRef, async (snapshot) => {
          const notificationsPromises = snapshot.docs.map(async (doc) => {
            if (!doc.exists()) {
              return null;
            }
            const data = doc.data();

            const validTypes = [
              "welcome",
              "connection_request",
              "connection_accepted",
              "connection_declined",
              "reaction",
              "repost",
              "comment",
              "reply",
              "comment_reaction",
              "startup_follow",
              "startup_follow_invite",
              "startup_follow_invite_accepted",
              "startup_follow_invite_declined",
              "startup_follow_invite_response",
              "team_invite",
              "team_invite_accepted",
              "team_invite_declined",
              "team_invite_response",
              "donation_received",
              "join_request",
              "join_accepted",
              "member_added",
              "join_declined",
              "event_attend",
              "organiser_invite",
              "organiser_invite_response",
              "organiser_invite_accepted",
              "event_invite",
              "event_invite_accepted",
              "event_invite_declined",
              "event_invite_response",
              "moderator_invite",
              "moderator_invite_response",
              "moderator_invite_accepted",
              "member_invite",
              "member_invite_response",
              "member_invite_accepted",
            ];

            if (!validTypes.includes(data.type)) {
              return null;
            }

            let userData = {
              name: "Unknown",
              profileImage: profileImage,
            };
            let startupInfo = null;

            if (
              data.from ||
              data.reactorId ||
              data.reposterId ||
              data.commenterId ||
              data.replierId
            ) {
              const userId =
                data.from ||
                data.reactorId ||
                data.reposterId ||
                data.commenterId ||
                data.replierId;
              userData = await fetchUserData(userId);
            }

            if (
              (data.type === "member_invite_accepted" ||
                data.type === "member_added") &&
              !data.communityImage
            ) {
              const communityImage = await fetchCommunityImage(
                data.communityOwnerId,
                data.communityId
              );
              data.communityImage = communityImage;
            }

            if (
              [
                "startup_follow",
                "team_invite",
                "team_invite_accepted",
                "team_invite_declined",
                "team_invite_response",
              ].includes(data.type)
            ) {
              startupInfo = await fetchStartupData(
                data.startupOwnerId,
                data.startupId
              );
              setStartupData((prev) => ({
                ...prev,
                [`${data.startupOwnerId}-${data.startupId}`]: startupInfo,
              }));
            }

            return {
              id: doc.id,
              ...data,
              fromUserName: userData.name,
              fromUserImage: userData.profileImage,
              startupName: startupInfo?.name || data.startupName,
              startupImage:
                startupInfo?.image || data.startupImage || defaultStartupImage,
              startupUrlSlug: startupInfo?.urlSlug || data.startupUrlSlug,
              reactionCount: data.reactionCount || 0,
              commentCount: data.commentCount || 0,
              postPreview: data.postPreview || {},
              commentText: data.commentText || "",
              replyText: data.replyText || "",
              parentCommentPreview: data.parentCommentPreview || {},
              createdAt: data.createdAt?.toDate() || new Date(),
              updatedAt:
                data.updatedAt?.toDate() ||
                data.createdAt?.toDate() ||
                new Date(),
              isNew: data.isNew !== false,
            };
          });

          const newNotifications = (
            await Promise.all(notificationsPromises)
          ).filter(Boolean);
          const filteredNotifications =
            filterDuplicateRequests(newNotifications);
          const sortedNotifications = filteredNotifications.sort(
            (a, b) =>
              (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
          );

          setLocalNotifications(sortedNotifications);
          setLocalNewStatus((prev) => {
            const newStatus = { ...prev };
            sortedNotifications.forEach((notif) => {
              if (notif.isNew && !(notif.id in newStatus)) {
                newStatus[notif.id] = true;
              }
            });
            return newStatus;
          });

          setIsLoading(false);
          setHasLoadedOnce(true);
        });
      } catch (error) {}
    };

    fetchNotifications();
    return () => unsubscribe();
  }, [currentUser]);

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      } catch (error) {}
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        fetchUserData(user.uid);
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  // In Notifications.js, update the handleTeamInvite function:

  const handleTeamInvite = async (notificationId, action) => {
    try {
      const batch = writeBatch(firestore);

      const notificationRef = doc(
        firestore,
        `users/${currentUser.uid}/notifications/${notificationId}`
      );
      const notificationDoc = await getDoc(notificationRef);
      const notificationData = notificationDoc.data();

      if (!notificationDoc.exists()) {
        throw new Error("Notification not found");
      }

      // References to the team member documents
      const startupTeamRef = doc(
        firestore,
        `users/${notificationData.startupOwnerId}/startups/${notificationData.startupId}/team/${currentUser.uid}`
      );
      const userTeamRef = doc(
        firestore,
        `users/${currentUser.uid}/team/${notificationData.startupId}`
      );

      if (action === "accept") {
        // Removed role field from these objects
        const teamMemberData = {
          userId: currentUser.uid,
          status: "active",
          acceptedAt: new Date(),
          updatedAt: new Date(),
        };

        const userTeamData = {
          startupId: notificationData.startupId,
          startupOwnerId: notificationData.startupOwnerId,
          startupName: notificationData.startupName,
          startupUrlSlug: notificationData.startupUrlSlug,
          status: "active",
          acceptedAt: new Date(),
          updatedAt: new Date(),
        };

        batch.set(startupTeamRef, teamMemberData);
        batch.set(userTeamRef, userTeamData);

        // Create notification for startup owner
        const ownerNotificationRef = doc(
          collection(
            firestore,
            `users/${notificationData.startupOwnerId}/notifications`
          )
        );

        batch.set(ownerNotificationRef, {
          type: "team_invite_accepted",
          from: currentUser.uid,
          startupId: notificationData.startupId,
          startupName: notificationData.startupName,
          startupUrlSlug: notificationData.startupUrlSlug,
          startupImage: notificationData.startupImage,
          createdAt: new Date(),
          isNew: true,
        });
      } else {
        // If declined, delete the team member documents
        batch.delete(startupTeamRef);
        batch.delete(userTeamRef);
      }

      // Update the current user's notification
      batch.update(notificationRef, {
        type: "team_invite_response",
        message:
          action === "accept"
            ? `${notificationData.startupName}${
                notificationData.startupName.endsWith("s") ? "'" : "'s"
              } invite accepted.`
            : `${notificationData.startupName}${
                notificationData.startupName.endsWith("s") ? "'" : "'s"
              } invite declined.`,
        updatedAt: new Date(),
      });

      await batch.commit();

      // Update local state
      setLocalNotifications((prev) =>
        prev
          .map((notif) =>
            notif.id === notificationId
              ? {
                  ...notif,
                  type: "team_invite_response",
                  message:
                    action === "accept"
                      ? `${notificationData.startupName}${
                          notificationData.startupName.endsWith("s")
                            ? "'"
                            : "'s"
                        } invite accepted.`
                      : `${notificationData.startupName}${
                          notificationData.startupName.endsWith("s")
                            ? "'"
                            : "'s"
                        } invite declined.`,
                  updatedAt: new Date(),
                }
              : notif
          )
          .sort(
            (a, b) =>
              (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
          )
      );
    } catch (error) {
      throw error;
    }
  };

  // Add this near the top of Notifications.js

  const renderNotificationContent = (notification) => {
    const getNotificationImage = () => {
      // Special cases where we always want to show event image regardless of user/startup context
      if (
        ["organiser_invite_accepted", "event_invite_accepted"].includes(
          notification.type
        )
      ) {
        return notification.eventImage || defaultEventImage;
      }

      // Rest of your existing logic for other notification types
      if (
        [
          "team_invite",
          "team_invite_accepted",
          "team_invite_response",
          "startup_follow",
          "donation_received",
          "event_attend",
          "join_request",
          "join_accepted",
          "member_added",
          "join_declined",
          "organiser_invite",
          "organiser_invite_response",
          "event_invite",
          "event_invite_response",
          "moderator_invite",
          "moderator_invite_accepted",
          "moderator_invite_response",
        ].includes(notification.type) ||
        notification.isStartup
      ) {
        return (
          notification.communityImage ||
          notification.eventImage ||
          notification.startupImage ||
          notification.reactorImage ||
          defaultStartupImage
        );
      }

      return notification.fromUserImage || defaultUserImage;
    };

    const getUserName = () => {
      if (
        notification.isStartup ||
        notification.type === "moderator_invite" ||
        notification.type === "moderator_invite_response" ||
        notification.type === "organiser_invite" ||
        notification.type === "organiser_invite_response"
      ) {
        // Add organiser notification types
        return (
          notification.eventName || // Add this first to prioritize event name
          notification.startupName ||
          notification.reactorName ||
          notification.reposterName ||
          notification.commenterName ||
          notification.replierName ||
          "Unknown" ||
          notification.communityName ||
          "Unknown"
        );
      }
      return (
        notification.fromUserName ||
        notification.reactorName ||
        notification.reposterName ||
        notification.commenterName ||
        notification.replierName ||
        "Unknown"
      );
    };

    const handleOrganiserInvite = async (notificationId, action) => {
      try {
        const batch = writeBatch(firestore);

        const notificationRef = doc(
          firestore,
          `users/${currentUser.uid}/notifications/${notificationId}`
        );
        const notificationDoc = await getDoc(notificationRef);
        const notificationData = notificationDoc.data();

        if (!notificationDoc.exists()) {
          throw new Error("Notification not found");
        }

        const hostRef = doc(
          firestore,
          `users/${notificationData.eventOwnerId}/events/${notificationData.eventId}/hosts/${currentUser.uid}`
        );

        if (action === "accept") {
          const attendeeRef = doc(
            firestore,
            `users/${notificationData.eventOwnerId}/events/${notificationData.eventId}/attendees/${currentUser.uid}`
          );
          const attendeeDoc = await getDoc(attendeeRef);

          if (attendeeDoc.exists()) {
            batch.delete(attendeeRef);
            const userEventRef = doc(
              firestore,
              `users/${currentUser.uid}/eventsAttending/${notificationData.eventId}`
            );
            batch.delete(userEventRef);

            const eventRef = doc(
              firestore,
              `users/${notificationData.eventOwnerId}/events/${notificationData.eventId}`
            );
            batch.update(eventRef, {
              attendeeCount: increment(-1),
            });

            const userRef = doc(firestore, "users", currentUser.uid);
            batch.update(userRef, {
              attendingCount: increment(-1),
            });
          }

          const hostData = {
            userId: currentUser.uid,
            status: "active",
            acceptedAt: new Date(),
            updatedAt: new Date(),
          };

          batch.set(hostRef, hostData);

          const eventOrganisingRef = doc(
            firestore,
            `users/${currentUser.uid}/eventsOrganising/${notificationData.eventId}`
          );
          batch.set(eventOrganisingRef, {
            eventOwnerId: notificationData.eventOwnerId,
            status: "active",
            acceptedAt: new Date(),
            updatedAt: new Date(),
          });

          const ownerNotificationRef = doc(
            collection(
              firestore,
              `users/${notificationData.eventOwnerId}/notifications`
            )
          );
          batch.set(ownerNotificationRef, {
            type: "organiser_invite_accepted",
            from: currentUser.uid,
            eventId: notificationData.eventId,
            eventName: notificationData.eventName,
            eventUrlSlug: notificationData.eventUrlSlug,
            eventImage: notificationData.eventImage,
            fromUserName: `${currentUser.firstName} ${currentUser.lastName}`,
            fromUserImage: currentUser.profileImage || defaultUserImage,
            isStartup: true,
            startupImage: notificationData.eventImage,
            createdAt: new Date(),
            isNew: true,
          });

          batch.update(notificationRef, {
            type: "organiser_invite_response",
            message: `${notificationData.eventName}${
              notificationData.eventName.endsWith("s") ? "'" : "'s"
            } invite accepted.`,
            updatedAt: new Date(),
          });
        } else {
          batch.delete(hostRef);
          batch.update(notificationRef, {
            type: "organiser_invite_response",
            message: `${notificationData.eventName}${
              notificationData.eventName.endsWith("s") ? "'" : "'s"
            } request declined.`,
            updatedAt: new Date(),
          });
        }

        await batch.commit();
      } catch (error) {}
    };

    const handleJoinRequest = async (notificationId, fromUserId, action) => {
      try {
        const batch = writeBatch(firestore);

        const notificationRef = doc(
          firestore,
          `users/${currentUser.uid}/notifications/${notificationId}`
        );
        const notificationDoc = await getDoc(notificationRef);

        if (!notificationDoc.exists()) {
          throw new Error("Notification not found");
        }

        const notificationData = notificationDoc.data();

        // Fetch the complete community data first
        const communityRef = doc(
          firestore,
          `users/${currentUser.uid}/communities/${notificationData.communityId}`
        );
        const communityDoc = await getDoc(communityRef);
        const communityData = communityDoc.exists() ? communityDoc.data() : {};

        const fromUserDoc = await getDoc(doc(firestore, "users", fromUserId));
        const fromUserData = fromUserDoc.data();

        const memberRef = doc(
          firestore,
          `users/${currentUser.uid}/communities/${notificationData.communityId}/members/${fromUserId}`
        );

        if (action === "accept") {
          batch.update(memberRef, {
            status: "Member",
            joinedAt: new Date(),
          });

          const userCommunityRef = doc(
            firestore,
            `users/${fromUserId}/communitiesMemberOf/${notificationData.communityId}`
          );

          batch.set(userCommunityRef, {
            communityId: notificationData.communityId,
            communityName: notificationData.communityName,
            communityImage: notificationData.communityImage,
            communityUrlSlug: notificationData.communityUrlSlug,
            ownerId: currentUser.uid,
            joinedAt: new Date(),
            status: "Member",
            bio: communityData.bio || "",
            link: communityData.link || "",
            linkText: communityData.linkText || "",
            tagline: communityData.tagline || "",
            industry: communityData.industry || "",
          });
          const userNotificationRef = doc(
            collection(firestore, `users/${fromUserId}/notifications`)
          );

          batch.set(userNotificationRef, {
            type: "join_accepted",
            communityId: notificationData.communityId,
            communityName: notificationData.communityName,
            communityImage: notificationData.communityImage,
            communityUrlSlug: notificationData.communityUrlSlug,
            isStartup: true,
            startupImage: notificationData.communityImage,
            startupName: notificationData.communityName,
            message: `${notificationData.communityName}${
              notificationData.communityName.endsWith("s") ? "'" : "'s"
            } request accepted.`,
            createdAt: new Date(),
            isNew: true,
          });

          batch.update(notificationRef, {
            type: "member_added",
            fromUserName: `${fromUserData.firstName} ${fromUserData.lastName}`,
            message: `${fromUserData.firstName} ${fromUserData.lastName} is now a member.`,
            updatedAt: new Date(),
          });
        } else {
          batch.delete(memberRef);

          const userCommunityRef = doc(
            firestore,
            `users/${fromUserId}/communitiesMemberOf/${notificationData.communityId}`
          );
          batch.delete(userCommunityRef);

          batch.update(notificationRef, {
            type: "join_declined",
            fromUserName: `${fromUserData.firstName} ${fromUserData.lastName}`,
            message: `${fromUserData.firstName} ${fromUserData.lastName}'s request declined.`,
            updatedAt: new Date(),
          });
        }

        await batch.commit();
      } catch (error) {}
    };

    const DonationNotification = ({ notification, handleUserClick }) => {
      return (
        <div className={classes.notificationText}>
          <div className={classes.notificationMessage}>
            <p>
              <span
                className={`${classes.userName} ${classes.clickable}`}
                onClick={() => handleUserClick(notification.from)}
              >
                {getUserName()}
              </span>
              <span className={classes.messageText}> is now a supporter.</span>
            </p>
          </div>
          <div className={classes.notificationTimestamp}>
            {formatTimestamp(notification.createdAt)}
          </div>
          <div className={classes.skillContainer}>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M4 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H4Zm0 6h16v6H4v-6Z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M5 14a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Zm5 0a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1Z"
                clipRule="evenodd"
              />
            </svg>
            {new Intl.NumberFormat(undefined, {
              style: "currency",
              currency: notification.currency || "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
              .format(notification.amount)
              .replace(/^([^\d\s]+)\s+/, "$1")}
          </div>
          {notification.message && (
            <div
              className={`${classes.postPreview}`}
              onClick={() =>
                navigate(`/startup/${notification.startupUrlSlug}`)
              }
            >
              <div className={`${classes.post} ${classes.textOnlyPost}`}>
                <div className={classes.postContent}>
                  <p className={classes.textPreview}>{notification.message}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    };

    const renderTeamNotification = (notification, type) => {
      return (
        <div className={classes.notificationText}>
          <div className={classes.notificationMessage}>
            <p>
              {type === "invite_accepted" && (
                <>
                  <span
                    className={`${classes.userName} ${classes.clickable}`}
                    onClick={() => handleUserClick(notification.from)}
                  >
                    {getUserName()}
                  </span>
                  <span className={classes.messageText}>
                    {" "}
                    is now a team member.
                  </span>
                  {/* <span
                    className={`${classes.startupName} ${classes.clickable}`}
                    onClick={() =>
                      handleStartupClick(
                        notification.startupOwnerId,
                        notification.startupId
                      )
                    }
                  >
                    {notification.startupName}
                  </span> */}
                </>
              )}
              {type === "invite_response" && (
                <>
                  <span
                    className={`${classes.startupName} ${classes.clickable}`}
                    onClick={() =>
                      handleStartupClick(
                        notification.startupOwnerId,
                        notification.startupId
                      )
                    }
                  >
                    {notification.startupName}
                  </span>
                  <span className={classes.messageText}>
                    {notification.message.includes("accepted")
                      ? "'s invite accepted."
                      : "'s invite declined."}
                  </span>
                </>
              )}
              {type === "invite" && (
                <>
                  <span
                    className={`${classes.startupName} ${classes.clickable}`}
                    onClick={() =>
                      handleStartupClick(
                        notification.startupOwnerId,
                        notification.startupId
                      )
                    }
                  >
                    {notification.startupName}
                  </span>
                  <span className={classes.messageText}>
                    {" "}
                    wants you to join the team.
                  </span>
                </>
              )}
            </p>
          </div>
          <div className={classes.notificationTimestamp}>
            {formatTimestamp(notification.createdAt)}
          </div>
          {type === "invite" && (
            <div className={classes.interactions}>
              <button
                onClick={() => handleTeamInvite(notification.id, "accept")}
                className={classes.button}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Accept
              </button>
              <button
                onClick={() => handleTeamInvite(notification.id, "decline")}
                className={classes.ignoreButton}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm5.757-1a1 1 0 1 0 0 2h8.486a1 1 0 1 0 0-2H7.757Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Decline
              </button>
            </div>
          )}
        </div>
      );
    };

    // • Join startup
    // communities • Share your journey and milestones • Discover
    // exciting events • Follow startups you're interested in Get
    // started by completing your profile and joining some
    // communities!`,
    switch (notification.type) {
      // In the renderNotificationContent switch statement in Notifications.js
      case "welcome":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p
                className={classes.welcomeTitle}
                onClick={() => navigate(`/startup/foundry`)}
              >
                {notification.title}
              </p>
              <div className={classes.notificationTimestamp}>
                {formatTimestamp(notification.createdAt)}
              </div>
              <div className={classes.welcomeMessage}>
                We're excited to have you join the community. Here's what you
                can do:
              </div>
              <div className={classes.cardAbout}>
                <div className={classes.matchTally}>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Share, Boost, and Comment on Posts
                </div>

                <div className={classes.matchTally}>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H6Zm7.25-2.095c.478-.86.75-1.85.75-2.905a5.973 5.973 0 0 0-.75-2.906 4 4 0 1 1 0 5.811ZM15.466 20c.34-.588.535-1.271.535-2v-1a5.978 5.978 0 0 0-1.528-4H18a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2h-4.535Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Filter, Search, and Connect with People
                </div>
                <div className={classes.matchTally}>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.535 7.677c.313-.98.687-2.023.926-2.677H17.46c.253.63.646 1.64.977 2.61.166.487.312.953.416 1.347.11.42.148.675.148.779 0 .18-.032.355-.09.515-.06.161-.144.3-.243.412-.1.111-.21.192-.324.245a.809.809 0 0 1-.686 0 1.004 1.004 0 0 1-.324-.245c-.1-.112-.183-.25-.242-.412a1.473 1.473 0 0 1-.091-.515 1 1 0 1 0-2 0 1.4 1.4 0 0 1-.333.927.896.896 0 0 1-.667.323.896.896 0 0 1-.667-.323A1.401 1.401 0 0 1 13 9.736a1 1 0 1 0-2 0 1.4 1.4 0 0 1-.333.927.896.896 0 0 1-.667.323.896.896 0 0 1-.667-.323A1.4 1.4 0 0 1 9 9.74v-.008a1 1 0 0 0-2 .003v.008a1.504 1.504 0 0 1-.18.712 1.22 1.22 0 0 1-.146.209l-.007.007a1.01 1.01 0 0 1-.325.248.82.82 0 0 1-.316.08.973.973 0 0 1-.563-.256 1.224 1.224 0 0 1-.102-.103A1.518 1.518 0 0 1 5 9.724v-.006a2.543 2.543 0 0 1 .029-.207c.024-.132.06-.296.11-.49.098-.385.237-.85.395-1.344ZM4 12.112a3.521 3.521 0 0 1-1-2.376c0-.349.098-.8.202-1.208.112-.441.264-.95.428-1.46.327-1.024.715-2.104.958-2.767A1.985 1.985 0 0 1 6.456 3h11.01c.803 0 1.539.481 1.844 1.243.258.641.67 1.697 1.019 2.72a22.3 22.3 0 0 1 .457 1.487c.114.433.214.903.214 1.286 0 .412-.072.821-.214 1.207A3.288 3.288 0 0 1 20 12.16V19a2 2 0 0 1-2 2h-6a1 1 0 0 1-1-1v-4H8v4a1 1 0 0 1-1 1H6a2 2 0 0 1-2-2v-6.888ZM13 15a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Create, Join, or Support Startups
                </div>
                <div className={classes.matchTally}>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 6a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm-1.5 8a4 4 0 0 0-4 4 2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-3Zm6.82-3.096a5.51 5.51 0 0 0-2.797-6.293 3.5 3.5 0 1 1 2.796 6.292ZM19.5 18h.5a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-1.1a5.503 5.503 0 0 1-.471.762A5.998 5.998 0 0 1 19.5 18ZM4 7.5a3.5 3.5 0 0 1 5.477-2.889 5.5 5.5 0 0 0-2.796 6.293A3.501 3.501 0 0 1 4 7.5ZM7.1 12H6a4 4 0 0 0-4 4 2 2 0 0 0 2 2h.5a5.998 5.998 0 0 1 3.071-5.238A5.505 5.505 0 0 1 7.1 12Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Create, Join, or Moderate Communities
                </div>
                <div className={classes.matchTally}>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Create, Join, or Host Events
                </div>
                <div className={classes.matchTally}>
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 20a7.966 7.966 0 0 1-5.002-1.756l.002.001v-.683c0-1.794 1.492-3.25 3.333-3.25h3.334c1.84 0 3.333 1.456 3.333 3.25v.683A7.966 7.966 0 0 1 12 20ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10c0 5.5-4.44 9.963-9.932 10h-.138C6.438 21.962 2 17.5 2 12Zm10-5c-1.84 0-3.333 1.455-3.333 3.25S10.159 13.5 12 13.5c1.84 0 3.333-1.455 3.333-3.25S13.841 7 12 7Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Create, Edit, and Share your Profile
                </div>
              </div>
            </div>
          </div>
        );
      case "team_invite_response":
        return renderTeamNotification(notification, "invite_response");
      case "team_invite_accepted":
        return renderTeamNotification(notification, "invite_accepted");
      case "team_invite":
        return renderTeamNotification(notification, "invite");
      case "startup_follow_invite":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() =>
                    handleStartupClick(
                      notification.startupOwnerId,
                      notification.startupId
                    )
                  }
                >
                  {notification.startupName}
                </span>
                <span className={classes.messageText}>
                  {" "}
                  wants you to follow them.
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div className={classes.interactions}>
              <button
                onClick={() =>
                  handleStartupFollowInvite(notification.id, "accept")
                }
                className={classes.button}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Accept
              </button>
              <button
                onClick={() =>
                  handleStartupFollowInvite(notification.id, "decline")
                }
                className={classes.ignoreButton}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm5.757-1a1 1 0 1 0 0 2h8.486a1 1 0 1 0 0-2H7.757Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Decline
              </button>
            </div>
          </div>
        );
      // In the switch statement inside renderNotificationContent:
      case "startup_follow_invite_response":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() =>
                    handleStartupClick(
                      notification.startupOwnerId,
                      notification.startupId
                    )
                  }
                >
                  {notification.startupName}
                  {notification.startupName.endsWith("s") ? "'" : "'s"}
                </span>
                <span className={classes.messageText}>
                  {notification.message.includes("accepted")
                    ? " invite accepted."
                    : " invite declined."}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );

      case "startup_follow_invite_accepted":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {notification.fromUserName}
                </span>{" "}
                <span className={classes.messageText}> is now a follower.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "member_invite":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() => handleCommunityClick(notification.communityId)}
                >
                  {notification.communityName}
                </span>
                <span className={classes.messageText}> wants you to join.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div className={classes.interactions}>
              <button
                onClick={() => handleMemberInvite(notification.id, "accept")}
                className={classes.button}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Accept
              </button>
              <button
                onClick={() => handleMemberInvite(notification.id, "decline")}
                className={classes.ignoreButton}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm5.757-1a1 1 0 1 0 0 2h8.486a1 1 0 1 0 0-2H7.757Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Decline
              </button>
            </div>
          </div>
        );

      case "member_invite_response":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() => handleCommunityClick(notification.communityId)}
                >
                  {notification.communityName}
                  {notification.communityName.endsWith("s") ? "'" : "'s"}
                </span>
                <span className={classes.messageText}>
                  {notification.message.includes("accepted")
                    ? " invite accepted."
                    : " invite declined."}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );

      case "member_invite_accepted":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {notification.fromUserName}
                </span>{" "}
                <span className={classes.messageText}> is now a member.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "moderator_invite":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() => handleCommunityClick(notification.communityId)}
                >
                  {notification.communityName}
                </span>
                <span className={classes.messageText}>
                  {" "}
                  wants you to join as a moderator.
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div className={classes.interactions}>
              <button
                onClick={() => handleModeratorInvite(notification.id, "accept")}
                className={classes.button}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Accept
              </button>
              <button
                onClick={() =>
                  handleModeratorInvite(notification.id, "decline")
                }
                className={classes.ignoreButton}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm5.757-1a1 1 0 1 0 0 2h8.486a1 1 0 1 0 0-2H7.757Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Decline
              </button>
            </div>
          </div>
        );

      case "moderator_invite_response":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() => handleCommunityClick(notification.communityId)}
                >
                  {notification.communityName}
                  {notification.communityName.endsWith("s") ? "'" : "'s"}
                </span>
                <span className={classes.messageText}>
                  {notification.message.includes("accepted")
                    ? " invite accepted."
                    : " invite declined."}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );

      case "moderator_invite_accepted":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {notification.fromUserName}
                </span>
                <span className={classes.messageText}>
                  {" "}
                  is now a moderator.
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "organiser_invite":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() =>
                    handleEventClick(
                      notification.eventOwnerId,
                      notification.eventId
                    )
                  }
                >
                  {notification.eventName}
                </span>
                <span className={classes.messageText}>
                  {" "}
                  wants you to join as an organiser.
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div className={classes.interactions}>
              <button
                onClick={() => handleOrganiserInvite(notification.id, "accept")}
                className={classes.button}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Accept
              </button>
              <button
                onClick={() =>
                  handleOrganiserInvite(notification.id, "decline")
                }
                className={classes.ignoreButton}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm5.757-1a1 1 0 1 0 0 2h8.486a1 1 0 1 0 0-2H7.757Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Decline
              </button>
            </div>
          </div>
        );

      case "organiser_invite_response":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() =>
                    handleEventClick(
                      notification.eventOwnerId,
                      notification.eventId
                    )
                  }
                >
                  {notification.eventName}
                  {notification.eventName.endsWith("s") ? "'" : "'s"}
                </span>
                <span className={classes.messageText}>
                  {notification.message.includes("accepted")
                    ? " invite accepted."
                    : " invite declined."}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );

      case "organiser_invite_accepted":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {notification.fromUserName}
                </span>
                <span className={classes.messageText}>
                  {" "}
                  is now an organiser.
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "startup_follow":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {getUserName()}
                </span>{" "}
                <span className={classes.messageText}>is now a follower.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "event_invite":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() =>
                    handleEventClick(
                      notification.eventOwnerId,
                      notification.eventId
                    )
                  }
                >
                  {notification.eventName}
                </span>
                <span className={classes.messageText}>
                  {" "}
                  wants you to attend.
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div className={classes.interactions}>
              <button
                onClick={() => handleEventInvite(notification.id, "accept")}
                className={classes.button}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Accept
              </button>
              <button
                onClick={() => handleEventInvite(notification.id, "decline")}
                className={classes.ignoreButton}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm5.757-1a1 1 0 1 0 0 2h8.486a1 1 0 1 0 0-2H7.757Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Decline
              </button>
            </div>
          </div>
        );

      case "event_invite_response":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() =>
                    handleEventClick(
                      notification.eventOwnerId,
                      notification.eventId
                    )
                  }
                >
                  {notification.eventName}
                  {notification.eventName.endsWith("s") ? "'" : "'s"}
                </span>
                <span className={classes.messageText}>
                  {notification.message.includes("accepted")
                    ? " invite accepted."
                    : " invite declined."}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );

      case "event_invite_accepted":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {notification.fromUserName}
                </span>
                <span className={classes.messageText}>
                  {" "}
                  is now an attendee.
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "event_attend":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {getUserName()}
                </span>{" "}
                <span className={classes.messageText}>
                  {notification.message || "is now an attendee"}{" "}
                  {/* Add fallback for existing notifications */}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "join_request":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {notification.fromUserName}
                </span>
                <span className={classes.messageText}> wants to join.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div className={classes.interactions}>
              <button
                onClick={() =>
                  handleJoinRequest(
                    notification.id,
                    notification.from,
                    "accept"
                  )
                }
                className={classes.button}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Accept
              </button>
              <button
                onClick={() =>
                  handleJoinRequest(
                    notification.id,
                    notification.from,
                    "decline"
                  )
                }
                className={classes.ignoreButton}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm5.757-1a1 1 0 1 0 0 2h8.486a1 1 0 1 0 0-2H7.757Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Decline
              </button>
            </div>
          </div>
        );

      case "join_accepted":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.startupName} ${classes.clickable}`}
                  onClick={() => handleCommunityClick(notification.communityId)}
                >
                  {notification.communityName}
                  {notification.communityName.endsWith("s") ? "'" : "'s"}
                </span>
                <span className={classes.messageText}> request accepted.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );

      case "member_added":
      case "join_declined":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {notification.fromUserName}
                </span>{" "}
                <span className={classes.messageText}>
                  {notification.type === "member_added"
                    ? " is now a member."
                    : "'s request declined."}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );

      case "connection_request":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {getUserName()}
                </span>{" "}
                <span className={classes.messageText}> wants to connect.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div className={classes.interactions}>
              <button
                onClick={() =>
                  handleConnectionRequest(
                    notification.id,
                    notification.from,
                    "accept"
                  )
                }
                className={classes.button}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Accept
              </button>
              <button
                onClick={() =>
                  handleConnectionRequest(
                    notification.id,
                    notification.from,
                    "ignore"
                  )
                }
                className={classes.ignoreButton}
              >
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm5.757-1a1 1 0 1 0 0 2h8.486a1 1 0 1 0 0-2H7.757Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Decline
              </button>
            </div>
          </div>
        );

      case "connection_accepted":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {getUserName()}
                </span>{" "}
                <span className={classes.messageText}>
                  is now a connection.
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );
      case "connection_declined":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => handleUserClick(notification.from)}
                >
                  {notification.fromUserName}
                  {notification.fromUserName.endsWith("s") ? "'" : "'s"}
                </span>
                <span className={classes.messageText}> request declined.</span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
          </div>
        );

      case "donation_received":
        return (
          <DonationNotification
            notification={notification}
            handleUserClick={handleUserClick}
          />
        );
      case "comment":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => {
                    if (notification.isStartup) {
                      handleStartupClick(
                        notification.startupOwnerId,
                        notification.startupId
                      );
                    } else {
                      handleUserClick(
                        notification.from || notification.commenterId
                      );
                    }
                  }}
                >
                  {getUserName()}
                </span>{" "}
                <span className={classes.messageText}>
                  {notification.isConsolidated
                    ? `and ${notification.otherCount} other${
                        notification.otherCount === 1 ? "" : "s"
                      } commented on this.`
                    : "commented on this."}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div
              className={classes.postPreview}
              onClick={() => handlePostPreviewClick(notification.postId)}
            >
              <div className={classes.commentPost}>
                <div className={classes.commentPreview}>
                  {notification.commentText}
                </div>
              </div>
              <div className={classes.postContent}>
                {notification.postPreview?.mediaType &&
                  renderFilePreview(notification.postPreview)}
                {notification.postPreview?.text && (
                  <p className={classes.textPreview}>
                    {notification.postPreview.text}
                  </p>
                )}
              </div>
            </div>
          </div>
        );
      case "reply":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => {
                    if (notification.isStartup) {
                      handleStartupClick(
                        notification.startupOwnerId,
                        notification.startupId
                      );
                    } else {
                      handleUserClick(
                        notification.from || notification.commenterId
                      );
                    }
                  }}
                >
                  {getUserName()}
                </span>{" "}
                <span className={classes.messageText}>
                  {notification.isConsolidated
                    ? `and ${notification.otherCount} other${
                        notification.otherCount === 1 ? "" : "s"
                      } replied to this.`
                    : "replied to this."}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div
              className={classes.postPreview}
              onClick={() => handlePostPreviewClick(notification.postId)}
            >
              <div className={classes.commentPost}>
                <div className={classes.commentPreview}>
                  {notification.replyText}
                </div>
              </div>

              {notification.parentCommentPreview && (
                <div className={classes.commentReply}>
                  <div className={classes.parentCommentPreview}>
                    {notification.parentCommentPreview.text}
                  </div>
                </div>
              )}

              <div className={classes.postContent}>
                {notification.postPreview?.mediaType &&
                  renderFilePreview(notification.postPreview)}
                {notification.postPreview?.text && (
                  <p className={classes.textPreview}>
                    {notification.postPreview.text}
                  </p>
                )}
              </div>
            </div>
          </div>
        );
      case "comment_reaction":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => {
                    if (notification.isStartup) {
                      handleStartupClick(
                        notification.startupOwnerId,
                        notification.startupId
                      );
                    } else {
                      handleUserClick(
                        notification.from || notification.reactorId
                      );
                    }
                  }}
                >
                  {getUserName()}
                </span>{" "}
                <span className={classes.messageText}>
                  {notification.isConsolidated
                    ? `and ${notification.otherCount} other${
                        notification.otherCount === 1 ? "" : "s"
                      } boosted this`
                    : "boosted this"}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div
              className={classes.postPreview}
              onClick={() => handlePostPreviewClick(notification.postId)}
            >
              <div className={classes.commentPost}>
                <div className={classes.commentPreview}>
                  {notification.commentText}
                </div>
              </div>
              <div className={classes.postContent}>
                {notification.postPreview?.mediaType &&
                  renderFilePreview(notification.postPreview)}
                {notification.postPreview?.text && (
                  <p className={classes.textPreview}>
                    {notification.postPreview.text}
                  </p>
                )}
              </div>
            </div>
          </div>
        );

      case "reaction":
        return (
          <div className={classes.notificationText}>
            <div className={classes.notificationMessage}>
              <p>
                <span
                  className={`${classes.userName} ${classes.clickable}`}
                  onClick={() => {
                    if (notification.isStartup) {
                      handleStartupClick(
                        notification.startupOwnerId,
                        notification.startupId
                      );
                    } else {
                      handleUserClick(
                        notification.from || notification.reactorId
                      );
                    }
                  }}
                >
                  {getUserName()}
                </span>{" "}
                <span className={classes.messageText}>
                  {notification.isConsolidated
                    ? `and ${notification.otherCount} other${
                        notification.otherCount === 1 ? "" : "s"
                      } boosted this.`
                    : "boosted this."}
                </span>
              </p>
            </div>
            <div className={classes.notificationTimestamp}>
              {formatTimestamp(notification.createdAt)}
            </div>
            <div
              className={classes.postPreview}
              onClick={() => handlePostPreviewClick(notification.postId)}
            >
              <div
                className={`${classes.post} ${
                  !notification.postPreview?.mediaType &&
                  notification.postPreview?.text
                    ? classes.textOnlyPost
                    : ""
                }`}
              >
                <div className={classes.postContent}>
                  {notification.postPreview?.mediaType &&
                    renderFilePreview(notification.postPreview)}
                  {notification.postPreview?.text && (
                    <p className={classes.textPreview}>
                      {notification.postPreview.text}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const consolidateNotifications = (notifications) => {
    // Group notifications by a more specific key depending on the notification type
    const grouped = notifications.reduce((acc, notification) => {
      let key;

      // Handle different notification types
      switch (notification.type) {
        case "comment":
          // Group comments by post ID since they're direct comments on the post
          key = `${notification.postId}-${notification.type}`;
          break;
        case "reply":
          // Group replies by the parent comment ID they're replying to
          key = `${notification.postId}-${notification.type}-${notification.parentCommentId}`;
          break;
        case "comment_reaction":
          // Reactions to comments should be grouped by the specific comment
          key = `${notification.postId}-${notification.type}-${notification.commentId}`;
          break;
        case "reaction":
          // Post reactions can be grouped by post
          key = `${notification.postId}-${notification.type}`;
          break;
        default:
          // For all other notifications, use the notification ID to keep them separate
          key = notification.id;
      }

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(notification);
      return acc;
    }, {});

    // Convert groups back to notifications array, consolidating when needed
    return Object.values(grouped)
      .map((group) => {
        if (group.length === 1) {
          return group[0];
        }

        // Use the most recent notification as the base
        const latest = group.sort(
          (a, b) => (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
        )[0];

        // Return consolidated notification with count of others
        return {
          ...latest,
          isConsolidated: true,
          otherCount: group.length - 1,
        };
      })
      .sort(
        (a, b) => (b.updatedAt || b.createdAt) - (a.updatedAt || a.createdAt)
      );
  };

  const renderFilePreview = (postPreview) => {
    if (postPreview.mediaType === "image") {
      // Handle single image
      if (typeof postPreview.mediaUrl === "string") {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl} alt="Post preview" />
          </div>
        );
      }
      // Handle multiple images
      else if (
        Array.isArray(postPreview.mediaUrl) &&
        postPreview.mediaUrl.length > 0
      ) {
        return (
          <div className={classes.mediaPreview}>
            <img src={postPreview.mediaUrl[0]} alt="Post preview" />
            {postPreview.mediaUrl.length > 1 && (
              <div className={classes.multipleImagesIndicator}>
                +{postPreview.mediaUrl.length - 1}
              </div>
            )}
          </div>
        );
      }
    }

    if (postPreview.mediaType === "video") {
      return (
        <div className={classes.mediaPreview}>
          <video
            src={postPreview.mediaUrl}
            preload="metadata"
            style={{ display: "none" }} // Hide the video element completely
            muted
            playsInline
            onLoadedMetadata={(e) => {
              const video = e.target;
              const canvas = document.createElement("canvas");
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              video.currentTime = 0.1; // Set to 0.1s to avoid black frame

              video.addEventListener(
                "seeked",
                () => {
                  try {
                    // Draw the video frame to canvas
                    canvas
                      .getContext("2d")
                      .drawImage(video, 0, 0, canvas.width, canvas.height);

                    // Create and insert the static image
                    const img = new Image();
                    img.src = canvas.toDataURL();
                    img.style.width = "100%";
                    img.style.height = "100%";
                    img.style.objectFit = "cover";

                    // Find the container and insert the image
                    const container = video.parentNode;
                    container.insertBefore(img, container.firstChild);

                    // Cleanup
                    video.remove();
                  } catch (err) {
                    // If canvas operations fail, show the first frame of video as fallback
                    video.style.display = "block";
                    video.controls = false;
                    video.autoPlay = false;
                    video.style.pointerEvents = "none";
                  }
                },
                { once: true }
              );
            }}
          />
          <div className={classes.playButton}></div>
        </div>
      );
    }

    if (postPreview.mediaType === "pdf" || postPreview.fileName) {
      return (
        <div className={classes.filePreview}>
          <svg
            className="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      );
    }

    return null;
  };

  const userImage = userData.profileImage || profileImage;

  const deleteNotification = async (notificationId) => {
    try {
      const notificationRef = doc(
        firestore,
        `users/${currentUser.uid}/notifications/${notificationId}`
      );

      // Check if the document exists before attempting to delete
      const docSnap = await getDoc(notificationRef);
      if (!docSnap.exists()) {
      } else {
        // Attempt to delete the document only if it exists
        await deleteDoc(notificationRef);
      }

      // Update local state
      setLocalNotifications((prev) =>
        prev.filter((notif) => notif.id !== notificationId)
      );

      // Update localNewStatus
      setLocalNewStatus((prev) => {
        const newStatus = { ...prev };
        delete newStatus[notificationId];
        return newStatus;
      });

      // Add to deletedNotifications set
      setDeletedNotifications((prev) => new Set(prev).add(notificationId));
    } catch (error) {}
  };

  const fetchCommunityImage = async (communityOwnerId, communityId) => {
    try {
      const communityRef = doc(
        firestore,
        `users/${communityOwnerId}/communities/${communityId}`
      );
      const communityDoc = await getDoc(communityRef);

      if (communityDoc.exists()) {
        const communityData = communityDoc.data();
        return communityData.communityImage || defaultCommunityImage;
      }

      return defaultStartupImage;
    } catch (error) {
      return defaultStartupImage;
    }
  };

  const markNotificationsAsOld = useCallback(async () => {
    if (!currentUser || !localNotifications.length) return;

    const batch = writeBatch(firestore);
    let updatedCount = 0;

    for (const notification of localNotifications) {
      if (
        localNewStatus[notification.id] &&
        !deletedNotifications.has(notification.id)
      ) {
        const notificationRef = doc(
          firestore,
          `users/${currentUser.uid}/notifications/${notification.id}`
        );
        try {
          const docSnap = await getDoc(notificationRef);
          if (docSnap.exists()) {
            batch.update(notificationRef, { isNew: false });
            updatedCount++;
          }
        } catch (error) {}
      }
    }

    if (updatedCount > 0) {
      try {
        await batch.commit();
        dispatch({ type: "RESET_COUNT" });
      } catch (error) {}
    }

    setDeletedNotifications(new Set());
  }, [
    currentUser,
    localNotifications,
    localNewStatus,
    deletedNotifications,
    dispatch,
  ]);

  useEffect(() => {
    return () => {
      if (currentUser) {
        markNotificationsAsOld();
      }
    };
  }, [currentUser, markNotificationsAsOld]);

  return (
    <div className={classes.page}>
      {isMobile ? (
        <MobileNavbar userImage={userData.profileImage || profileImage} />
      ) : (
        <MainNavbar userImage={userData.profileImage || profileImage} />
      )}
      <div className={classes.content}>
        <div className={classes.centreCards}>
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <div className={classes.loadingIndicator}>
                <span
                  className={`${classes.loadingIcon} material-symbols-outlined`}
                >
                  progress_activity
                </span>
              </div>
            </div>
          ) : hasLoadedOnce && localNotifications.length > 0 ? (
            <div className={classes.notifications}>
              {consolidateNotifications(localNotifications)
                .slice(0, displayedCount)
                .map((notification) => (
                  <div
                    key={notification.id}
                    className={`${classes.notification} ${
                      notification.type === "connection_accepted" ||
                      notification.type === "connection_declined"
                        ? classes.singleLineNotification
                        : ""
                    }`}
                  >
                    <div className={classes.notificationContent}>
                      <div
                        className={
                          notification.type === "event_invite" ||
                          notification.type === "event_invite_response" ||
                          notification.type === "organiser_invite" ||
                          notification.type === "organiser_invite_response"
                            ? classes.eventInviteImageContainer
                            : classes.profileImageContainer
                        }
                      >
                        {notification.type === "donation_received" ||
                        notification.type === "startup_follow" ||
                        notification.type ===
                          "startup_follow_invite_accepted" ||
                        notification.type === "team_invite_accepted" ||
                        notification.type === "member_invite_accepted" ||
                        notification.type === "member_added" ||
                        notification.type === "moderator_invite_accepted" ||
                        notification.type === "event_attend" ||
                        notification.type === "event_invite_accepted" ||
                        notification.type === "organiser_invite_accepted" ? (
                          <div
                            className={
                              notification.type === "event_attend" ||
                              notification.type === "event_invite_accepted" ||
                              notification.type === "organiser_invite_accepted"
                                ? classes.eventImagesContainer
                                : classes.donationImagesContainer
                            }
                          >
                            <img
                              src={
                                notification.fromUserImage || defaultUserImage
                              }
                              alt={notification.fromUserName}
                              className={classes.donorImage}
                              onClick={() => handleUserClick(notification.from)}
                            />
                            <img
                              src={
                                notification.type ===
                                  "member_invite_accepted" ||
                                notification.type === "member_added" ||
                                notification.type ===
                                  "moderator_invite_accepted"
                                  ? notification.communityImage ||
                                    defaultCommunityImage
                                  : notification.type === "event_attend" ||
                                    notification.type ===
                                      "event_invite_accepted" ||
                                    notification.type ===
                                      "organiser_invite_accepted"
                                  ? notification.eventImage || defaultEventImage
                                  : notification.startupImage ||
                                    defaultStartupImage
                              }
                              alt={
                                notification.type ===
                                  "member_invite_accepted" ||
                                notification.type === "member_added" ||
                                notification.type ===
                                  "moderator_invite_accepted"
                                  ? notification.communityName
                                  : notification.type === "event_attend" ||
                                    notification.type ===
                                      "event_invite_accepted" ||
                                    notification.type ===
                                      "organiser_invite_accepted"
                                  ? notification.eventName
                                  : notification.startupName
                              }
                              onClick={() => {
                                if (
                                  notification.type ===
                                    "member_invite_accepted" ||
                                  notification.type === "member_added" ||
                                  notification.type ===
                                    "moderator_invite_accepted"
                                ) {
                                  handleCommunityClick(
                                    notification.communityId
                                  );
                                } else if (
                                  notification.type === "event_attend" ||
                                  notification.type ===
                                    "event_invite_accepted" ||
                                  notification.type ===
                                    "organiser_invite_accepted"
                                ) {
                                  handleEventClick(
                                    notification.eventOwnerId,
                                    notification.eventId
                                  );
                                } else if (
                                  notification.type === "startup_follow" ||
                                  notification.type ===
                                    "startup_follow_invite_accepted" ||
                                  notification.type === "team_invite_accepted"
                                ) {
                                  handleStartupClick(
                                    notification.startupOwnerId,
                                    notification.startupId
                                  );
                                } else {
                                  navigate(
                                    `/startup/${notification.startupUrlSlug}`
                                  );
                                }
                              }}
                              className={`${classes.donationStartupImage} ${
                                notification.type ===
                                  "member_invite_accepted" ||
                                notification.type === "member_added" ||
                                notification.type ===
                                  "moderator_invite_accepted"
                                  ? classes.communityImage
                                  : notification.type === "event_attend" ||
                                    notification.type ===
                                      "event_invite_accepted" ||
                                    notification.type ===
                                      "organiser_invite_accepted"
                                  ? classes.eventImage
                                  : ""
                              }`}
                            />
                          </div>
                        ) : (
                          <img
                            src={
                              notification.type === "moderator_invite" ||
                              notification.type === "member_invite" ||
                              notification.type ===
                                "moderator_invite_response" ||
                              notification.type === "member_invite_response" ||
                              notification.type === "event_invite" ||
                              notification.type === "event_invite_response" ||
                              notification.type === "organiser_invite" ||
                              notification.type ===
                                "organiser_invite_response" ||
                              notification.type === "startup_follow_invite"
                                ? notification.type.includes("event") ||
                                  notification.type.includes("organiser")
                                  ? notification.eventImage || defaultEventImage
                                  : notification.type.includes("startup")
                                  ? notification.startupImage ||
                                    defaultStartupImage // Add this condition
                                  : notification.communityImage ||
                                    defaultCommunityImage
                                : notification.type.includes("team") ||
                                  notification.type ===
                                    "team_invite_response" ||
                                  notification.type ===
                                    "startup_follow_invite_response" || // Add this condition
                                  notification.type === "event_attend" ||
                                  notification.type === "donation_received" ||
                                  notification.isStartup
                                ? notification.startupImage ||
                                  defaultStartupImage // Prioritize startupImage
                                : notification.fromUserImage || defaultUserImage
                            }
                            alt={
                              notification.type === "moderator_invite" ||
                              notification.type === "member_invite" ||
                              notification.type ===
                                "moderator_invite_response" ||
                              notification.type === "member_invite_response"
                                ? notification.communityName
                                : notification.type === "event_invite" ||
                                  notification.type ===
                                    "event_invite_response" ||
                                  notification.type === "organiser_invite" ||
                                  notification.type ===
                                    "organiser_invite_response"
                                ? notification.eventName
                                : notification.type ===
                                    "startup_follow_invite" ||
                                  notification.type ===
                                    "startup_follow_invite_response" // Add this line
                                ? notification.startupName
                                : notification.type.includes("team") ||
                                  notification.type === "event_attend" ||
                                  notification.type === "donation_received" ||
                                  notification.isStartup
                                ? notification.eventName ||
                                  notification.startupName ||
                                  notification.reactorName
                                : notification.fromUserName
                            }
                            className={
                              notification.type === "moderator_invite" ||
                              notification.type === "member_invite" ||
                              notification.type ===
                                "moderator_invite_response" ||
                              notification.type === "member_invite_response"
                                ? `${classes.startupImage} ${classes.communityImage}`
                                : notification.type === "event_invite" ||
                                  notification.type ===
                                    "event_invite_response" ||
                                  notification.type === "organiser_invite" ||
                                  notification.type ===
                                    "organiser_invite_response"
                                ? `${classes.startupImage} ${classes.eventImage}`
                                : notification.type.includes("team") ||
                                  notification.type.includes("moderator") ||
                                  notification.type ===
                                    "startup_follow_invite_response" || // Add this condition
                                  notification.type === "event_attend" ||
                                  notification.type === "donation_received" ||
                                  notification.type ===
                                    "startup_follow_invite" ||
                                  notification.isStartup
                                ? classes.startupImage
                                : classes.profileImage
                            }
                            onClick={() =>
                              notification.type === "welcome"
                                ? navigate("/startup/foundry")
                                : notification.type === "moderator_invite" ||
                                  notification.type === "member_invite" ||
                                  notification.type ===
                                    "moderator_invite_response" ||
                                  notification.type === "member_invite_response"
                                ? handleCommunityClick(notification.communityId)
                                : notification.type === "event_invite" ||
                                  notification.type ===
                                    "event_invite_response" ||
                                  notification.type === "organiser_invite" ||
                                  notification.type ===
                                    "organiser_invite_response"
                                ? handleEventClick(
                                    notification.eventOwnerId,
                                    notification.eventId
                                  )
                                : notification.type === "startup_follow_invite"
                                ? handleStartupClick(
                                    notification.startupOwnerId,
                                    notification.startupId
                                  )
                                : notification.type.includes("team") ||
                                  notification.type === "event_attend" ||
                                  notification.type === "donation_received" ||
                                  notification.isStartup
                                ? notification.type === "event_attend"
                                  ? handleEventClick(
                                      notification.eventOwnerId,
                                      notification.eventId
                                    )
                                  : handleStartupClick(
                                      notification.startupOwnerId ||
                                        notification.reactorId?.split("_")[1],
                                      notification.startupId
                                    )
                                : handleUserClick(
                                    notification.from || notification.reactorId
                                  )
                            }
                          />
                        )}
                        {localNewStatus[notification.id] && (
                          <div
                            className={
                              notification.type.includes("team") ||
                              notification.type.includes("moderator") ||
                              notification.type === "startup_follow" ||
                              notification.type === "event_attend" ||
                              notification.type === "member_added" ||
                              notification.type === "member_invite_accepted" ||
                              notification.isStartup
                                ? classes.startupNotificationIndicator
                                : classes.newNotificationIndicator
                            }
                          ></div>
                        )}
                      </div>
                      {renderNotificationContent(notification)}
                      <NotificationDropdown
                        onDelete={() => deleteNotification(notification.id)}
                      />
                    </div>
                  </div>
                ))}
            </div>
          ) : hasLoadedOnce ? (
            <div className={classes.noNotifications}>
              <div className={classes.bold}>No notifications found</div>
              <div className={classes.text}>Try checking Home for updates.</div>
              <Link to="/home" className={classes.homeButton}>
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z"
                    clip-rule="evenodd"
                  />
                </svg>
                Home
              </Link>
            </div>
          ) : null}
          {displayedCount <
            consolidateNotifications(localNotifications).length && (
            <button className={classes.miscButton} onClick={handleShowMore}>
              {displayedCount >= localNotifications.length ? (
                <>
                  Show less
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m5 15 7-7 7 7"
                    />
                  </svg>
                </>
              ) : (
                <>
                  Show more
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 9-7 7-7-7"
                    />
                  </svg>
                </>
              )}
            </button>
          )}
        </div>
        <div className={classes.rightCards}>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

export default Notifications;
